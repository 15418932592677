import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Typography } from 'antd';
import { colors } from '../../../styles/colors';

const OtherPleaseSpecify = () => {
  const { t } = useTranslation();
  return (
    <Row>
      <Typography.Text style={{ marginRight: 8 }}>{t('questionCreateOptionOther')}</Typography.Text>
      <Row style={{ borderBottom: `1px dashed ${colors.disabled}`, width: 190 }}>
        <Typography.Text style={{ color: colors.disabled }}>{t('questionCreateOptionPleaseSpecify')}</Typography.Text>
      </Row>
    </Row>
  );
};

export default OtherPleaseSpecify;
