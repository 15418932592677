import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Tooltip, Tag, Typography } from 'antd';
import {
  FireFilled,
  LikeFilled,
  SafetyCertificateFilled,
  SmileFilled,
  ToolFilled,
  CarryOutFilled,
  BulbFilled,
  MedicineBoxFilled,
  StarFilled,
  UserAddOutlined,
} from '@ant-design/icons';
import { colors } from '../../styles/colors';
import { workerTags } from '../../constants';

const WorkerTag = ({ type, t, daysWorked, positionDaysWorked, skillScore, positionName, rating, companyName }) => {
  switch (type) {
    case workerTags.COVID_VACCINATED:
      return (
        <div style={{ padding: '1px 8px' }}>
          <MedicineBoxFilled style={{ color: colors.functionalSuccess, marginRight: 4 }} />
          <Typography.Text strong style={{ color: colors.functionalSuccess }}>
            {t('workerTagCovidVaccinated')}
          </Typography.Text>
        </div>
      );
    case workerTags.RECOMMENDED:
      return (
        <Tooltip title={t('recommendedToHire')}>
          <Tag color={colors.tagBlue} style={{ padding: '1px 8px', marginRight: 0 }}>
            <LikeFilled style={{ color: colors.infoHighlightBlue, marginRight: 8 }} />
            <Typography.Text style={{ color: colors.infoHighlightBlue }}>{t('recommended')}</Typography.Text>
          </Tag>
        </Tooltip>
      );
    case workerTags.NEW_JOINER:
      return (
        <Tooltip title={t('workerTagNewJoinerTooltip')}>
          <Tag color={colors.tagPurple} style={{ padding: '1px 8px', marginRight: 0 }}>
            <FireFilled style={{ color: colors.functionalApproved, marginRight: 8 }} />
            <Typography.Text style={{ color: colors.functionalApproved }}>{t('justJoined')}</Typography.Text>
          </Tag>
        </Tooltip>
      );
    case workerTags.PAST_WORKER:
      return (
        <Tooltip title={t('hasWorkedAtCompany', { companyName })}>
          <Tag color={colors.tagGreen} style={{ padding: '1px 8px', marginRight: 0 }}>
            <SmileFilled style={{ color: colors.functionalSuccess }} />
            <Typography.Text style={{ color: colors.functionalSuccess }}>{t('formerStaff')}</Typography.Text>
          </Tag>
        </Tooltip>
      );
    case workerTags.EXPERIENCED:
      return (
        <Tooltip title={t('thisApplicantHasWorkedXDays', { positionDaysWorked })}>
          <Tag color={colors.tagGreen} style={{ padding: '1px 8px', marginRight: 0 }}>
            <ToolFilled style={{ color: colors.functionalSuccess }} />
            <Typography.Text style={{ color: colors.functionalSuccess }}>{t('experienced')}</Typography.Text>
          </Tag>
        </Tooltip>
      );
    case workerTags.DAYS_WORKED:
      return (
        <Tooltip title={t('totalDaysWorkedWorkmateTooltip')}>
          <CarryOutFilled style={{ color: colors.functionalSuccess, marginRight: 4 }} />
          <Typography.Text strong style={{ color: colors.functionalSuccess }}>
            {t('daysWorkedTag', { daysWorked })}
          </Typography.Text>
        </Tooltip>
      );
    case workerTags.VERIFIED:
      return (
        <Tooltip title={t('screenedAndVerified')}>
          <Tag color={colors.tagBlue} style={{ padding: '1px 8px', marginRight: 0 }}>
            <SafetyCertificateFilled style={{ color: colors.functionalLink }} />
            <Typography.Text style={{ color: colors.functionalLink }}>{t('Verified')}</Typography.Text>
          </Tag>
        </Tooltip>
      );
    case workerTags.SKILL_SCORE:
      return (
        <Tooltip title={t('scoreOnTheSkillTest', { skillScore, positionName })}>
          <Tag color={colors.tagGreen} style={{ padding: '1px 8px', marginRight: 0 }}>
            <BulbFilled style={{ color: colors.functionalSuccess }} />
            <Typography.Text style={{ color: colors.functionalSuccess }}>
              <Trans i18nKey="skillScorePercentage" values={{ skillScore }} />
            </Typography.Text>
          </Tag>
        </Tooltip>
      );
    case workerTags.RATING:
      return (
        <Tooltip title={t('supervisorRatingTooltip')}>
          {/* <Tag color={colors.tagGreen} style={{ padding: '1px 8px', marginRight: 0 }}> */}
          <StarFilled style={{ color: colors.functionalSuccess, marginRight: 4 }} />
          <Typography.Text strong style={{ color: colors.functionalSuccess }}>
            {parseFloat(rating).toFixed(1)}
          </Typography.Text>
          {/* </Tag> */}
        </Tooltip>
      );
    case workerTags.JOB_INVITATION:
      return (
        <Tooltip title={t('workerTagJobInvitationTooltip')}>
          <Tag color={colors.tagYellow} style={{ padding: '1px 8px', marginRight: 0 }}>
            <UserAddOutlined style={{ color: colors.functionalWarning, marginRight: 8 }} />
            <Typography.Text style={{ color: colors.functionalWarning }}>{t('jobInvitation')}</Typography.Text>
          </Tag>
        </Tooltip>
      );
    default:
      return (
        <Tag style={{ padding: '1px 8px', marginRight: 0 }}>
          <Typography.Text>{type}</Typography.Text>
        </Tag>
      );
  }
};

export default withTranslation()(WorkerTag);
