import React from 'react';
import { connect } from 'react-redux';
import { Typography, Button, Row, Col, Spin } from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { checkAccess } from '../../../shared/access/Access';
import { permissions } from '../../../shared/access/accessConfig';
import { clientTypes, roles } from '../../../constants';
import { colors } from '../../../styles/colors';
import approveAllAttendances from '../utils/attendances';

const ApproveTimesheetsSection = ({ t, loading, timesheetsToApproveCount, isAdmin, isSelfServe, handleApproveAll }) => {
  const hasTimesheetsToApprove = timesheetsToApproveCount > 0;
  const today = moment();
  const nonSelfServeDescription = !isAdmin ? t('adminCanApproveTimesheets') : '';

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
      <Row type="flex" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Col style={{ flex: 0.7 }}>
          <Row>
            {hasTimesheetsToApprove ? (
              <>
                <Row>
                  {isAdmin ? (
                    <Typography.Text style={{ fontWeight: 600, fontSize: 18, lineHeight: 2 }}>
                      {t('youHave')}{' '}
                      <span style={{ color: colors.workmateGreen, fontSize: 18 }}>
                        {loading ? <Spin data-testid="stats-card-spinner" /> : timesheetsToApproveCount}
                      </span>{' '}
                      {t('timesheetsReadyForApproval')}
                    </Typography.Text>
                  ) : (
                    <Typography.Text style={{ fontWeight: 600, fontSize: 18, lineHeight: 2 }}>
                      <span style={{ color: colors.workmateGreen, fontSize: 18 }}>
                        {loading ? <Spin data-testid="stats-card-spinner" /> : timesheetsToApproveCount}
                      </span>{' '}
                      {t('timesheetsReadyForApprovalByAdmin')}
                    </Typography.Text>
                  )}
                </Row>
                <Row>
                  <Typography.Text>
                    {isAdmin ? t('approveAllTimesheets') : t('allConfirmedTimesheetsNotYetApproved')}
                  </Typography.Text>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Typography.Text style={{ fontWeight: 600, fontSize: 18, lineHeight: 2 }}>
                    {t('noTimesheetsRequireApproval')}
                  </Typography.Text>
                </Row>
                <Row>
                  <Typography.Text>
                    {`${t('payrollWillBeCalculatedOnApprovedTimesheets')} ${
                      isSelfServe
                        ? t('autoApproveTimesheetsAt5PM', {
                            date: t('onDate', { date: today.day(1 + 7).format('D MMMM') }),
                          })
                        : nonSelfServeDescription
                    }`}
                  </Typography.Text>
                </Row>
              </>
            )}
          </Row>
        </Col>
        <Col style={{ display: 'flex', flex: 0.3, paddingRight: 12, alignItems: 'center' }}>
          {checkAccess(permissions.attendanceApproveButton) && (
            <Button
              loading={loading}
              disabled={!hasTimesheetsToApprove}
              style={{
                height: 37,
                fontWeight: 600,
                fontSize: 14,
                color: colors.white,
                backgroundColor: hasTimesheetsToApprove ? colors.workmateGreen : colors.grey,
                borderColor: hasTimesheetsToApprove ? colors.workmateGreen : colors.grey,
                paddingTop: 7,
                paddingBottom: 7,
                paddingLeft: 27,
                paddingRight: 27,
                marginLeft: 'auto',
              }}
              onClick={() => {
                approveAllAttendances({ t, timesheetsToApproveCount, handleApproveAll });
              }}
            >
              {t('Approve all')}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => {
  const { role, type } = state.user;
  const isAdmin = role === roles.ADMIN;
  const isSelfServe = type === clientTypes.SELF_SERVE;
  return {
    isAdmin,
    isSelfServe,
  };
};

export default connect(mapStateToProps)(withTranslation()(ApproveTimesheetsSection));
