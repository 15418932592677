import { startCase, toLower, camelCase } from 'lodash';

const makeFriendly = uncleanStatus => {
  if (!uncleanStatus) {
    return undefined;
  }
  const status = uncleanStatus.replace('_', ' ');
  return status.charAt(0).toUpperCase() + status.slice(1);
};

const toTitleCase = string => {
  return startCase(toLower(string));
};

const humanizeText = string => {
  return startCase(string.replace('_', ' '));
};

function capitalizeFirstLetter(string) {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
}

const capitalizeWord = word => {
  return word && word.charAt(0).toUpperCase() + word.slice(1);
};

const toSentence = words => {
  return words.charAt(0).toUpperCase() + words.slice(1).toLowerCase();
};

const toCamelCase = string => {
  return camelCase(string);
};

export default {
  makeFriendly,
  toTitleCase,
  humanizeText,
  capitalizeWord,
  capitalizeFirstLetter,
  toSentence,
  toCamelCase,
};
