import React, { useState } from 'react';
import { Modal, Typography, Row, Col, Input } from 'antd';

const { Text } = Typography;
const { TextArea } = Input;

const SaveAsJobTemplateModal = ({ t, isVisible, handleClose, handleOk, handleCustomButtonClick, cancelText }) => {
  const [templateName, setTemplateName] = useState('');

  return (
    <Modal
      visible={isVisible}
      title={
        <Text style={{ fontSize: 20 }} strong>
          {t('saveJobPostAsATemplate')}
        </Text>
      }
      okText={t('saveAndPostJob')}
      okType="v2-primary"
      onOk={() => handleOk(templateName)}
      okButtonProps={{
        disabled: !templateName,
      }}
      onCancel={handleClose}
      cancelText={cancelText}
      cancelButtonProps={{
        onClick: () => handleCustomButtonClick(),
      }}
    >
      <Row>
        <Col>
          <Text>{t('saveJobPostForFutureTemplates')}</Text>
        </Col>
        <Col style={{ marginTop: 20 }}>
          <Text strong>{t('nameTemplate')}</Text>
        </Col>
        <Col span={24}>
          <TextArea
            style={{ marginTop: 8 }}
            autoSize={{ maxRows: 1 }}
            showCount
            maxLength={50}
            placeholder={t('templateNamePlaceholder')}
            onChange={e => setTemplateName(e.target.value)}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default SaveAsJobTemplateModal;
