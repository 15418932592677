// State of the app entity, holds information like country, positions... etc.
import positionApi from '../services/positionApi';
import staffRequestApi from '../services/staffRequestApi';
import timesheetApi from '../services/timesheetApi';
import clientApi from '../services/clientApi';
import leaveEntriesApi from '../services/leaveEntriesApi';

const UPDATE = 'GLOBAL_UPDATE_ACTION';

const globalInitialState = {
  isLoading: false,
  positions: [],
  potentialMatches: undefined,
  error: '',
  isOnWebView: false,
  locationId: undefined,
  voidReasons: [],
  leaveEntryTypes: [],
  leaveEntryVoidReasons: [],
};

export default (state = globalInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE:
      return Object.assign({}, state, { ...payload });
    default:
      return state;
  }
};

export const globalUpdateAction = payload => {
  return { type: UPDATE, payload };
};

// Heavy-Weight logic
export const fetchPositions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(globalUpdateAction({ isLoading: true }));
      const { country } = getState().user;
      const positions = await positionApi.fetchPositions({ country: country.id });
      dispatch(globalUpdateAction({ isLoading: false, positions }));
    } catch (error) {
      dispatch(globalUpdateAction({ isLoading: false, error: error.message }));
    }
  };
};

export const fetchPotentialMatches = filters => {
  return async dispatch => {
    try {
      // Parsing data from API
      dispatch(globalUpdateAction({ isLoading: true }));
      const data = await staffRequestApi.fetchPotentialMatches(filters);
      // Update redux store with parsed data
      dispatch(
        globalUpdateAction({
          potentialMatches: {
            count: data.count,
            list: data.results,
            locationId: filters.location,
            positionId: filters.position,
          },
          isLoading: false,
        }),
      );
    } catch (error) {
      dispatch(globalUpdateAction({ isLoading: false, error: error.message }));
    }
  };
};

export const fetchVoidReasons = () => {
  return async dispatch => {
    try {
      dispatch(globalUpdateAction({ isLoading: true }));
      const data = await timesheetApi.fetchVoidReasons();
      dispatch(
        globalUpdateAction({
          voidReasons: data,
          isLoading: false,
        }),
      );
    } catch (error) {
      dispatch(globalUpdateAction({ isLoading: false, error: error.message }));
    }
  };
};

export const fetchLeaveEntryVoidReasons = () => {
  return async dispatch => {
    try {
      dispatch(globalUpdateAction({ isLoading: true }));
      const data = await leaveEntriesApi.fetchVoidReasons();
      dispatch(
        globalUpdateAction({
          leaveEntryVoidReasons: data,
          isLoading: false,
        }),
      );
    } catch (error) {
      dispatch(globalUpdateAction({ isLoading: false, error: error.message }));
    }
  };
};

export const fetchLeaveEntryTypes = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(globalUpdateAction({ isLoading: true }));
      const { clientId } = getState().user;
      const response = await clientApi.fetchLeaveEntryTypes(clientId);

      dispatch(globalUpdateAction({ isLoading: false, leaveEntryTypes: response.results }));
    } catch (error) {
      dispatch(globalUpdateAction({ isLoading: false, error: error.message }));
    }
  };
};
