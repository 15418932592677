import { endpoints } from '../config';
import base from './base';

const fetchCountries = async () => {
  const response = await base.api.get(`${endpoints.countries}/`);
  return response;
};

const fetchCities = async (id, params) => {
  const response = await base.api.get(`${endpoints.countries}/${id}/cities/`, params);
  return response;
};

const fetchAreas = async (countryId, cityId, params) => {
  const response = await base.api.get(`${endpoints.countries}/${countryId}/cities/${cityId}/areas/`, params);
  return response;
};

export default {
  fetchCountries,
  fetchCities,
  fetchAreas,
};
