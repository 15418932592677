import { endpoints } from '../config';
import base from './base';

const fetchSchedules = async params => {
  const response = await base.api.get(`${endpoints.schedules}/`, { params });
  return response;
};

const createSchedule = async params => {
  const response = await base.api.post(`${endpoints.schedules}/`, params);
  return response;
};

const editSchedule = async schedule => {
  const response = await base.api.put(`${endpoints.schedules}/${schedule.id}/`, schedule);
  return response;
};

const bulkEditShifts = async (scheduleId, shiftId, params) => {
  const response = await base.api.post(`${endpoints.schedules}/${scheduleId}/bulk-edit-shifts/`, {
    shift_id: shiftId,
    ...params,
  });
  return response;
};

export default {
  fetchSchedules,
  createSchedule,
  bulkEditShifts,
  editSchedule,
};
