import React from 'react';
import { withTranslation } from 'react-i18next';
import { CheckCircleTwoTone, InfoCircleTwoTone } from '@ant-design/icons';
import { Row, Col, Typography } from 'antd';

import { colors } from '../../../../styles/colors';

const { Text } = Typography;

const CompletedInvoiceDisplay = ({ inReview, t }) => {
  let message = t(
    'Your account is approved for monthly invoicing payments. Contact us if you would like to switch to credit card payments.',
  );
  let InfoIcon = <CheckCircleTwoTone twoToneColor={colors.brandGreen} style={{ fontSize: '20px' }} />;

  if (inReview) {
    message = t(
      'Your account is being reviewed for monthly invoicing payments. Contact us if you would like to switch to credit card payments.',
    );
    InfoIcon = <InfoCircleTwoTone style={{ fontSize: '20px' }} />;
  }
  return (
    <Row type="flex">
      <Col span={2} style={{ paddingTop: '4px' }}>
        {InfoIcon}
      </Col>
      <Col span={22}>
        <Row>
          <Text strong>{t('Monthly invoicing')}</Text>
        </Row>
        <Row>{message}</Row>
      </Col>
    </Row>
  );
};

export default withTranslation()(CompletedInvoiceDisplay);
