import React from 'react';
import { withTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { Row, Col, Button, Tooltip } from 'antd';
import { colors } from '../../../../styles/colors';
import { checkAccess } from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';
import { timesheetEntryPaymentStatus, timesheetEntryStatuses } from '../../../../constants';
import { checkUnconfirmedClockOutEntry } from '../../utils/status';

const noEditStatuses = [timesheetEntryStatuses.APPROVED, timesheetEntryStatuses.VOID];

export default withTranslation()(
  ({ t, loading, timesheetEntry, handleEdit, handleVoid, handleApprove, handleConfirm }) => {
    return (
      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
        {!noEditStatuses.includes(timesheetEntry.status) && (
          <Col>
            {timesheetEntry?.paymentStatus === timesheetEntryPaymentStatus.PAID ? (
              <Tooltip title={t('paidAttendanceDetailTooltip')}>
                <Button
                  loading={loading}
                  icon={<EditOutlined />}
                  disabled
                  style={{
                    backgroundColor: colors.white,
                    borderRadius: 2,
                  }}
                  onClick={handleEdit}
                />
              </Tooltip>
            ) : (
              <Tooltip title={t('edit')}>
                <Button
                  loading={loading}
                  icon={<EditOutlined />}
                  style={{
                    backgroundColor: colors.white,
                    borderRadius: 2,
                  }}
                  onClick={handleEdit}
                />
              </Tooltip>
            )}
          </Col>
        )}
        {/* TODO remove once disputed no longer in use */}
        {timesheetEntry.status === timesheetEntryStatuses.DISPUTED && (
          <>
            <Button
              loading={loading}
              style={{
                marginLeft: 8,
                color: colors.functionalError,
                backgroundColor: colors.white,
              }}
              onClick={handleVoid}
            >
              {t('void')}
            </Button>
          </>
        )}
        {timesheetEntry.status === timesheetEntryStatuses.CLOCKED_IN && (
          <Col>
            <Button
              loading={loading}
              style={{
                marginLeft: 8,
                color: colors.functionalError,
                backgroundColor: colors.white,
              }}
              onClick={handleVoid}
            >
              {t('void')}
            </Button>
          </Col>
        )}
        {timesheetEntry.status === timesheetEntryStatuses.CLOCKED_OUT && (
          <>
            <Col>
              <Button
                loading={loading}
                style={{
                  marginLeft: 8,
                  color: colors.functionalError,
                  backgroundColor: colors.white,
                }}
                onClick={handleVoid}
              >
                {t('void')}
              </Button>
            </Col>
            {checkUnconfirmedClockOutEntry(timesheetEntry.supervisor_confirmed, timesheetEntry.status) && (
              <Col>
                <Button
                  loading={loading}
                  style={{
                    backgroundColor: colors.white,
                    marginLeft: 8,
                  }}
                  onClick={handleConfirm}
                >
                  {t('confirm')}
                </Button>
              </Col>
            )}
            <Col>
              {checkAccess(permissions.attendanceApproveButton) && (
                <Button
                  loading={loading}
                  style={{
                    fontWeight: 600,
                    color: colors.white,
                    backgroundColor: colors.workmateGreen,
                    marginLeft: 8,
                  }}
                  onClick={handleApprove}
                >
                  {t('approve')}
                </Button>
              )}
            </Col>
          </>
        )}
        {timesheetEntry.status === timesheetEntryStatuses.APPROVED &&
          timesheetEntry.paymentStatus !== timesheetEntryPaymentStatus.PAID && (
            <>
              <Col>
                {checkAccess(permissions.attendanceApproveButton) && (
                  <Button
                    loading={loading}
                    style={{
                      marginLeft: 8,
                      color: colors.functionalError,
                      backgroundColor: colors.white,
                    }}
                    onClick={handleVoid}
                  >
                    {t('void')}
                  </Button>
                )}
              </Col>
            </>
          )}
      </Row>
    );
  },
);
