/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import { CloseOutlined, ExclamationCircleOutlined, ThunderboltFilled } from '@ant-design/icons';
import { message, Typography, Row, Col, Modal, Tooltip, Button } from 'antd';
import * as Sentry from '@sentry/browser';
import { Link, withRouter } from 'react-router-dom';
import { isEmpty, toLower } from 'lodash';
import queryString from 'query-string';
import staffRequestApi from '../../services/staffRequestApi';
import applicationApi from '../../services/applicationApi';
import staffRequestUtils from '../../utilities/staffRequestUtils';
import getDeepLink from '../../utilities/getDeepLink';
import routes from '../../routes';
import { fetchChannels } from '../../redux/chat';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import ConfirmModals from '../../shared/components/ConfirmModals';
import StaffRequestHighlight from '../../shared/components/StaffRequestHighlight';
import StatusTag from '../../shared/components/StatusTag';
import PopoverCopyText from '../../shared/components/PopoverCopyText';
import DropdownButtons from '../../shared/components/DropdownButtons';
import SearchDropdownFilter from './components/SearchDropdownFilter';
import ReviewingStaffRequest from './components/ReviewingStaffRequest';
import FunnelTabs from './components/FunnelTabs';
import DownloadFormResponsesLink from './components/DownloadFormResponsesLink';
import {
  staffRequestStatuses,
  staffRequestTabs,
  errorDetails,
  employmentStatuses,
  offerStatuses,
  inactiveStatusOptions,
  PARTNER_ID,
  audiences,
  channelStatuses,
  employmentTypes,
} from '../../constants';
import { PAGE_SIZE } from '../../containers/Chat/constants';
import { colors } from '../../styles/colors';
import workerUtils from '../../utilities/workerUtils';
import employmentApi from '../../services/employmentApi';
import BackButton from '../../shared/components/BackButton';
import VipWorkersOnlyTooltip from './components/VipWorkersOnlyTooltip';
// import AutoHireButton from './components/AutoHireButton';

const { Text, Title } = Typography;
const REFRESH_DURATION_IN_MINS = 5;

const InvalidSrIdPrompt = ({ t, onClick }) => {
  return (
    <section
      style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}
    >
      <Row justify="center" style={{ marginBottom: '26px' }}>
        <Col>
          <ExclamationCircleOutlined style={{ fontSize: '28px', color: colors.functionalLink }} />
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Title level={4} style={{ marginBottom: '8px' }}>
          {t('invalidSrIdPromptTitle')}
        </Title>
      </Row>
      <Row type="flex" justify="center" style={{ textAlign: 'center' }}>
        <Text>{t('invalidSrIdPromptDescription')}</Text>
      </Row>
      <Row type="flex" justify="center" style={{ marginTop: '32px' }}>
        <Button type="v2-primary" onClick={onClick}>
          {t('backToJobList')}
        </Button>
      </Row>
    </section>
  );
};
class StaffRequestDetailView extends React.Component {
  state = {
    currentActiveTabKey: staffRequestTabs.APPLIED,
    staffRequest: null,
    openDescriptionModal: false,
    funnelTabCounts: null,
    valid_sr_id: true,
    selectedWorker: null,
    partnerIdFromParams: null,
    applicationRejectReasons: [],
  };

  async componentDidMount() {
    message.destroy();
    const { match } = this.props;
    const staffRequestId = this.props.match.params.id;
    try {
      const [staffRequest, funnelTabCounts] = await Promise.all([
        staffRequestApi.fetchStaffRequestById(staffRequestId),
        applicationApi.getHiringStatistics({
          staff_request_id: staffRequestId,
        }),
      ]);

      const pageParams = queryString.parse(this.props.location.search);

      if (PARTNER_ID in pageParams) {
        this.setState({ partnerIdFromParams: parseInt(pageParams[PARTNER_ID], 10) });
      }

      let currentActiveTabKey = staffRequest?.client_interview_required
        ? staffRequestTabs.INTERVIEW
        : staffRequestTabs.WAITLISTED;

      // Set active tab to URL's tab
      if (match.params.tab && match.params.tab !== ':tab') {
        currentActiveTabKey = match.params.tab;
        this.setState({ currentActiveTabKey: match.params.tab });
      }

      this.setState({ staffRequest, funnelTabCounts, currentActiveTabKey });
      this.props.history.push(
        routes.staffRequestDetail.replace(':id', staffRequestId).replace(':tab', currentActiveTabKey),
      );
    } catch (error) {
      if (error?.response?.data?.detail === errorDetails.SR_ID_NOT_FOUND) {
        this.setState({ valid_sr_id: false });
      } else {
        Sentry.captureException(error);
      }
    }

    //auto refresh timer
    this.interval = setInterval(() => this.checkUpdate(), REFRESH_DURATION_IN_MINS * 60000);

    //chat updates
    this.updateChat({ location: this.props?.location });

    // application rejection reasons
    this.fetchApplicationRejectReasons();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.updateChat();
  }

  fetchApplicationRejectReasons = async () => {
    try {
      const results = await applicationApi.getRejectReasons();
      this.setState({ applicationRejectReasons: results });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  updateChat = (options = {}) => {
    const { getChannels, isChatVisible, chatActiveSearchKeyword } = this.props;
    if (isChatVisible && isEmpty(chatActiveSearchKeyword)) {
      getChannels(
        {
          audience: audiences.SELECTED_APPLICANTS,
          status: channelStatuses.ACTIVE,
          page: 1,
          pageSize: PAGE_SIZE,
          ordering: '-last_message_timestamp',
        },
        null,
        options,
      );
    }
  };

  updateStatusCounts = async () => {
    const staffRequestId = this.props.match.params.id;
    const funnelTabCounts = await applicationApi.getHiringStatistics({
      staff_request_id: staffRequestId,
    });

    this.setState({ funnelTabCounts });
  };

  updateUrl = selectedTab => {
    const { staffRequest } = this.state;
    this.props.history.push(routes.staffRequestDetail.replace(':id', staffRequest.id).replace(':tab', selectedTab));
    this.setState({ currentActiveTabKey: selectedTab });
  };

  getDropdownButtons = () => {
    const { t } = this.props;
    const { id, title, status, employment_type: employmentType } = this.state.staffRequest;
    const shouldDisableEditSRButton =
      [staffRequestStatuses.CANCELLED, staffRequestStatuses.ENDED].includes(status) ||
      employmentType === employmentTypes.GIG.value;
    const shouldDisableDuplicateButton = employmentType === employmentTypes.GIG.value;

    const buttons = [
      {
        title: t('View'),
        onClick: () => {
          this.setState({ openDescriptionModal: true });
        },
      },
      {
        title: t('Edit'),
        disabled: shouldDisableEditSRButton,
        onClick: () => {
          this.goToEditPage();
        },
      },
      {
        title: t('Duplicate'),
        disabled: shouldDisableDuplicateButton,
        onClick: () => {
          this.goToCreationPage(id);
        },
      },
      {
        title: t('cancel'),
        style: { color: '#FF5251' },
        onClick: () => {
          ConfirmModals.cancelSr(title, id, t, () => this.onCancelSr(id));
        },
        disabled: status === staffRequestStatuses.CANCELLED,
      },
    ];
    return buttons;
  };

  goToEditPage = () => {
    const { staffRequest } = this.state;
    this.props.history.push(routes.editStaffRequest.replace(':id', staffRequest.id));
  };

  goToCreationPage = id => {
    this.props.history.push({ pathname: routes.createStaffRequest, state: { replicateId: id } });
  };

  onCancelSr = async staffRequestId => {
    const { t } = this.props;
    const cancelledSr = await staffRequestApi.cancelStaffRequest(staffRequestId);
    if (cancelledSr) {
      message.warning(`${t('staff request')} #${cancelledSr.id} ${t('has been cancelled')}`);
      this.setState({ staffRequest: cancelledSr });
    }
  };

  onSubmitSr = async staffRequestId => {
    const { t } = this.props;
    const submittedSr = await staffRequestApi.submitStaffRequest(staffRequestId);
    if (submittedSr) {
      message.success(`${t('staff request')} #${submittedSr.id} ${t('has been submitted for review')}`);
      this.setState({ staffRequest: submittedSr });
    }
  };

  handleMoveTab = async application => {
    const { staffRequest } = this.state;
    const { clientId } = this.props;
    const staffRequestId = this.props.match.params.id;
    const partnerWithStatisticsAndExperiences = await workerUtils.appendStatisticsAndExperienceToApplications([
      application,
    ]);
    const applicationHistoryCountResponse = await applicationApi.applicationHistoryCount({
      partner_ids: application.partner.id,
      client_id: clientId,
      staff_request_id: staffRequestId,
    });
    const appendedApplication = workerUtils.appendApplicationsCount(
      partnerWithStatisticsAndExperiences,
      applicationHistoryCountResponse.results,
    );
    let tab = '';

    if (Object.values(employmentStatuses).includes(application?.status)) {
      tab = staffRequestTabs.EMPLOYEES;
    } else if (
      Object.keys(inactiveStatusOptions)
        .map(option => toLower(option))
        .includes(application?.status)
    ) {
      tab = staffRequestTabs.INACTIVE;
    } else {
      tab = application.to;
    }

    // NOTE: not using the updateUrl() to avoid the setState re-rendering twice
    this.setState({ selectedWorker: appendedApplication, currentActiveTabKey: tab }, () => {
      this.props.history.push(routes.staffRequestDetail.replace(':id', staffRequest.id).replace(':tab', tab));
    });
  };

  handleClearInput = () => {
    this.setState({ selectedWorker: [] });
  };

  handleUpdateSelectedWorker = async worker => {
    let { status } = worker;
    if (status === staffRequestTabs.APPLIED) {
      if (worker.shortlisted) {
        status = staffRequestTabs.WAITLISTED;
      } else if (worker.interviewed) {
        status = staffRequestTabs.INTERVIEW;
      }
    } else if (status === offerStatuses.PENDING_CONTRACT) {
      status = staffRequestTabs.OFFERS;
    }

    this.setState({
      selectedWorker: [{ ...worker, to: status }],
    });
  };

  checkUpdate = async () => {
    const staffRequestId = this.props.match.params.id;
    const { t } = this.props;
    const newEmployments = await employmentApi.getNewActiveEmployments({
      duration_in_minutes: REFRESH_DURATION_IN_MINS,
      staff_request_id: staffRequestId,
    });
    if (newEmployments?.count > 0) {
      message.destroy();
      message.success({
        content: (
          <Row>
            <Col>{t('newWorkersAcceptedJobOffer')}</Col>
            <Col>
              <Button
                type="link"
                onClick={() => {
                  this.updateUrl(staffRequestTabs.EMPLOYEES);
                  window.location.reload(false);
                }}
              >
                <Text style={{ color: colors.functionalLink, textDecorationLine: 'underline' }}>
                  {t('View').toUpperCase()}
                </Text>
              </Button>
            </Col>
            <Col style={{ width: '13px' }}>
              <CloseOutlined
                style={{ fontSize: '13px', color: colors.secondaryText, marginTop: '-2px' }}
                onClick={() => message.destroy()}
              />
            </Col>
          </Row>
        ),
        className: 'refresh-notif',
        duration: 0,
      });
    }
  };

  render() {
    const {
      currentActiveTabKey,
      funnelTabCounts,
      staffRequest,
      openDescriptionModal,
      valid_sr_id,
      selectedWorker,
      partnerIdFromParams,
      applicationRejectReasons,
    } = this.state;
    const { t, user } = this.props;
    if (valid_sr_id && (!staffRequest || !funnelTabCounts)) return <LoadingSpinner />;
    if (!valid_sr_id) return <InvalidSrIdPrompt t={t} onClick={() => this.props.history.push(routes.staffRequests)} />;
    const {
      id,
      title,
      description,
      highlightedData,
      status,
      footNoteText,
      paused,
    } = staffRequestUtils.cleanSRListData([{ ...staffRequest, t, user }])[0];
    const showDeepLink = [staffRequestStatuses.POSTED, staffRequestStatuses.IN_PROGRESS].includes(staffRequest.status);
    const isAutohire = staffRequest.auto_hire;
    // const showAutoHireButton = staffRequest.interview_type !== interviewType.NO_INTERVIEW.value;

    if (staffRequest.form_id) {
      highlightedData.push({
        iconType: 'file-text',
        value: staffRequest.form_name,
        links: [
          {
            component: (
              <Link
                style={{ textDecoration: 'underline', display: 'block' }}
                to={routes.formPreview.replace(':id', staffRequest.form_id)}
              >
                {t('preview')}
              </Link>
            ),
          },
          {
            component: (
              <DownloadFormResponsesLink
                staffRequestId={staffRequest.id}
                totalResponses={staffRequest?.form_submissions_count}
              />
            ),
          },
        ],
      });
    }

    return (
      <>
        <Row type="flex" gutter={50}>
          <Col span={24}>
            <section style={{ marginBottom: '32px' }}>
              <BackButton title={t('backToJobs')} onClick={() => this.props.history.push(routes.staffRequests)} />
              <Row>
                <Col span={20}>
                  <StaffRequestHighlight
                    id={id}
                    title={title}
                    description={description}
                    highlightedData={highlightedData}
                    titleIcon={
                      <>
                        {isAutohire && (
                          <Tooltip title={t('autoHireOn')}>
                            <ThunderboltFilled
                              style={{
                                color: colors.functionalSuccess,
                                fontSize: 24,
                                marginRight: 4,
                              }}
                            />
                          </Tooltip>
                        )}
                        {staffRequest?.vip_workers_only && (
                          <VipWorkersOnlyTooltip
                            staffRequest={staffRequest}
                            iconProps={{ width: 20, height: 20 }}
                            tooltipProps={{ align: { offset: [10, -5] } }}
                          />
                        )}
                      </>
                    }
                    footNote={
                      <>
                        <StatusTag status={status} paused={paused} style={{ marginRight: '8px' }} />
                        <Text type="secondary" style={{ fontSize: '12px' }}>
                          {footNoteText}
                        </Text>
                      </>
                    }
                  />
                </Col>
                <Col span={4}>
                  <Row type="flex" justify="end">
                    {showDeepLink && (
                      <PopoverCopyText
                        t={t}
                        text={getDeepLink(
                          id,
                          staffRequest.client.country.code,
                          staffRequest.location.address.area.city.name.toLowerCase(),
                        )}
                        title="Share worker app deep link"
                      />
                    )}
                    <DropdownButtons width="128px" buttons={this.getDropdownButtons()} />
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col span={8}>
                  <SearchDropdownFilter
                    staffRequest={staffRequest}
                    handleResultClick={this.handleMoveTab}
                    showAll={this.handleClearInput}
                    selected={selectedWorker || []}
                  />
                </Col>
              </Row>
            </section>

            <section>
              {staffRequest.status === staffRequestStatuses.PENDING_REVIEW ? (
                <ReviewingStaffRequest />
              ) : (
                <FunnelTabs
                  staffRequest={staffRequest}
                  funnelTabCounts={funnelTabCounts}
                  onChangeTab={this.updateUrl}
                  onUpdateStatusCounts={this.updateStatusCounts}
                  currentActiveTabKey={currentActiveTabKey}
                  selectedWorker={selectedWorker || []}
                  handleMoveTab={this.handleMoveTab}
                  onUpdateParentSelectedWorker={this.handleUpdateSelectedWorker}
                  partnerIdFromParams={partnerIdFromParams}
                  applicationRejectReasons={applicationRejectReasons}
                />
              )}
            </section>
          </Col>
        </Row>
        <Modal
          centered
          title={title}
          visible={openDescriptionModal}
          onCancel={() => this.setState({ openDescriptionModal: false })}
          footer={null}
          style={{ whiteSpace: 'pre-line' }}
        >
          {description}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  clientId: state.user.clientId,
  clientPaymentMethod: state.paymentMethod,
  isChatVisible: state.chat.visible,
  chatActiveSearchKeyword: state.chat.activeSearchKeyword,
});

const mapDispatchToProps = dispatch => ({
  getChannels: (searchParams, setTotalUnreadCount, options) => {
    dispatch(fetchChannels(searchParams, setTotalUnreadCount, options));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StaffRequestDetailView));
