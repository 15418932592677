import React from 'react';
import { Typography } from 'antd';
import { withTranslation, Trans } from 'react-i18next';
import moment from 'moment-timezone';
import { employmentStatuses } from '../../../constants';
import { colors } from '../../../styles/colors';

const { Text } = Typography;

const EmploymentStatus = ({
  status,
  createdDate,
  startDate,
  endDate,
  t,
  firstDayMessageSentDate,
  firstDayMessageSeenDate,
  firstDayMessage,
}) => {
  const formattedStatus = t(status);
  let date = startDate;
  if (status === employmentStatuses.ACTIVE) {
    let activity = {
      activityStatus: t('accepted'),
      activityTime: moment(createdDate).toNow(true),
    };
    if (firstDayMessage) {
      const sortedActivities = [
        {
          activityStatus: t('accepted'),
          activityTime: moment(createdDate).toNow(true),
          date: moment(createdDate),
        },
        {
          activityStatus: t('firstDayMessageSent'),
          activityTime: moment(firstDayMessageSentDate).toNow(true),
          date: moment(firstDayMessageSentDate).diff(moment()) < 0 ? moment(firstDayMessageSentDate) : moment(0),
        },
        {
          activityStatus: t('firstDayMessageSeen'),
          activityTime: moment(firstDayMessageSeenDate).toNow(true),
          date: firstDayMessageSeenDate ? moment(firstDayMessageSeenDate) : moment(0),
        },
      ].sort((a, b) => b.date.valueOf() - a.date.valueOf());

      // eslint-disable-next-line prefer-destructuring
      activity = sortedActivities[0];
    }

    return (
      <>
        <Text style={{ color: colors.black }}>{activity.activityStatus}</Text>{' '}
        <Text type="secondary">
          {activity.activityTime} {t('ago')}
        </Text>
      </>
    );
  }
  if (status === employmentStatuses.CANCELLED || status === employmentStatuses.ENDED) {
    date = endDate;
    return (
      <Trans i18nKey="employmentStatusDate">
        <Text style={{ color: colors.functionalError }}>{{ status: formattedStatus }}</Text> {{ date }}
      </Trans>
    );
  }
  // Default
  return (
    <Trans i18nKey="employmentStatusDate">
      <Text style={{ color: colors.primaryText }}>{{ status: formattedStatus }}</Text> {{ date }}
    </Trans>
  );
};

export default withTranslation()(EmploymentStatus);
