import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tag, Typography } from 'antd';
import { colors } from '../../../styles/colors';

export default ({ description }) => {
  return (
    <div>
      <Tag
        color={colors.tagYellow}
        style={{
          borderRadius: 2,
          borderColor: colors.yellow,
          padding: 10,
          width: '100%',
          marginTop: 24,
          display: 'flex',
        }}
      >
        <ExclamationCircleOutlined style={{ color: colors.functionalWarning, fontSize: 16 }} />
        <div style={{ marginLeft: 8 }}>
          <Typography.Text style={{ color: colors.primary, fontSize: 14, textWrap: 'wrap' }}>
            {description}
          </Typography.Text>
        </div>
      </Tag>
    </div>
  );
};
