import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Typography, Row } from 'antd';

const { Title, Paragraph, Text } = Typography;

const StaffRequestHighlight = ({ titleLinkTo, title, highlightedData, footNote, id, titleIcon, t }) => {
  return (
    <>
      <Text strong type="secondary">{`${t('job').toUpperCase()} #${id}`}</Text>

      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
        {titleIcon && <div style={{ marginRight: 16 }}>{titleIcon}</div>}
        <Title strong level={2} style={{ margin: 0, paddingBottom: '4px', wordWrap: 'break-word' }}>
          <Link to={titleLinkTo || '#'} style={{ color: 'inherit' }} className="underline-on-hover">
            {title}
          </Link>
        </Title>
      </div>

      {/* Rows of icons and value that is highlighted */}
      {highlightedData && highlightedData.length > 0 && (
        <section style={{ margin: '14px 0 16px 0' }}>
          {highlightedData.map((highlight, index) => (
            <Row key={index} style={{ marginBottom: 4 }}>
              <Paragraph style={{ marginBottom: 0, marginRight: 4 }}>
                <LegacyIcon type={highlight.iconType} style={{ color: '#BFBFBF', marginRight: '8px' }} />
                {highlight.value}
              </Paragraph>
              {highlight.links &&
                highlight.links.map((link, linkIndex) => (
                  <Row key={linkIndex} style={{ margin: '0 4px' }}>
                    <span style={{ marginRight: 4 }}>·</span>
                    {link.component}
                  </Row>
                ))}
            </Row>
          ))}
        </section>
      )}
      <section>{footNote}</section>
    </>
  );
};

export default withTranslation()(StaffRequestHighlight);
