import base from './base';
import { endpoints, readonlyEndpoints } from '../config';

const create = async params => {
  const response = await base.api.post(`${endpoints.employments}/`, params);
  return response;
};

const list = async params => {
  const response = await base.api.get(`${endpoints.employments}/`, { params });
  return response;
};

const employmentsList = async params => {
  const response = await base.api.get(`${readonlyEndpoints.employments}/chat-members-list/`, { params });
  return response;
};

const getVerifiedExperience = async params => {
  const response = await base.api.get(`${endpoints.employments}/verified-experience/`, { params });
  return response;
};

const update = async (employmentId, params) => {
  const response = await base.api.patch(`${endpoints.employments}/${employmentId}/`, params);
  return response;
};

const cancel = async (employmentId, reason, notes) => {
  const response = await base.api.post(`${endpoints.employments}/${employmentId}/cancel/`, {
    cancellation_reason: reason,
    notes,
  });
  return response;
};

const bulkCancel = async payload => {
  const response = await base.api.post(`${endpoints.employments}/bulk-cancel/`, payload);
  return response;
};

const markNoShow = async employmentId => {
  const response = await base.api.post(`${endpoints.employments}/${employmentId}/no-show/`);
  return response;
};

const getStatistics = async params => {
  const response = await base.api.get(`${readonlyEndpoints.employments}/statistics/`, { params });
  return response;
};

const removeInvite = async employmentId => {
  const response = await base.api.post(`${endpoints.employments}/${employmentId}/remove-invite/`);
  return response;
};

const getPositions = async params => {
  const response = await base.api.get(`${endpoints.employments}/positions/`, { params });
  return response;
};

const getRoles = async params => {
  const response = await base.api.get(`${endpoints.employments}/roles/`, { params });
  return response;
};

const getNewActiveEmployments = async params => {
  const response = await base.api.get(`${readonlyEndpoints.employments}/get-new-active-employments-count/`, { params });
  return response;
};

export default {
  create,
  list,
  employmentsList,
  update,
  cancel,
  bulkCancel,
  markNoShow,
  getStatistics,
  removeInvite,
  getPositions,
  getRoles,
  getVerifiedExperience,
  getNewActiveEmployments,
};
