/* eslint-disable no-use-before-define */
import moment from 'moment';
import { isEmpty } from 'lodash';
import partnerApi from '../services/partnerApi';
import employmentApi from '../services/employmentApi';
import applicationApi from '../services/applicationApi';
import fetchAll from './apiUtils';

import textUtils from './textUtils';

import {
  employmentStatuses,
  userStatuses,
  EARTH_RADIUS_IN_KM,
  WORKER_APPLICATION_QUALIFIED_THRESHOLD,
  applicationStatuses,
  staffRequestTabs,
} from '../constants';

// V1 folders
import experience_avatar from '../assets/images/experience_avatar.png';
import education_avatar from '../assets/images/education_avatar.png';
import certificate_avatar from '../assets/images/certificate_avatar.png';

// Skill Test Badges
import adminDataEntryBadge from '../assets/images/badge-admin-data-entry.png';
import cookBadge from '../assets/images/badge-cook.png';
import customerServiceAgentBadge from '../assets/images/badge-customer-service.png';
import driverBadge from '../assets/images/badge-driver.png';
import kitchenCrewBadge from '../assets/images/badge-kitchen-crew.png';
import riderBadge from '../assets/images/badge-rider.png';
import serverBadge from '../assets/images/badge-server.png';
import warehouseWorkerBadge from '../assets/images/badge-warehouse-worker.png';

const SKILL_QUIZ_PASSING_SCORE = 7;
const SKILL_TEST_BADGES = {
  admin_data_entry: adminDataEntryBadge,
  cook: cookBadge,
  customer_service_agent: customerServiceAgentBadge,
  driver: driverBadge,
  kitchen_crew: kitchenCrewBadge,
  rider: riderBadge,
  server: serverBadge,
  warehouse_worker: warehouseWorkerBadge,
};

const getWorkerName = partner => {
  return partner.last_name ? `${partner.first_name} ${partner.last_name}` : partner.first_name;
};

const cleanProfile = (partner, t) => {
  return {
    id: partner.id,
    imgSrc: partner.image,
    status: partner.status,
    gender: partner.gender,
    createdDate: partner.created_date,
    approvalDate: partner.approval_date,
    idNumber: partner.identification_number,
    approvedPositions: partner.approved_positions,
    skillQuizScores: partner.skill_quiz_submissions,
    experiences: getExperiences(partner.experiences, t),
    name: getWorkerName(partner),
    age: partner.date_of_birth && `${moment().diff(partner.date_of_birth, 'years')}`,
    birthday: moment(partner.date_of_birth).format('DD MMM YYYY'),
    location: {
      address: partner.address && partner.address.street_1 ? `${partner.address.street_1}` : t('notProvided'),
      latitude: partner.address && partner.address.latitude ? partner.address.latitude : undefined,
      longitude: partner.address && partner.address.longitude ? partner.address.longitude : undefined,
    },
    phone: partner.mobile || t('notProvided'),
    email: partner.email || t('notProvided'),
    lastActive: partner.last_opened_app
      ? `${t('Last active')} ${moment(partner.last_opened_app).fromNow()}`
      : t('notProvided'),
    daysWorked: partner.statistics.days_worked,
    ratings: partner.statistics.total_ratings,
    noShows: partner.statistics.no_shows,
    rejectionReason: partner.rejection_reason ? textUtils.makeFriendly(partner.rejection_reason) : 'N/A',
    verifiedDate: getVerifiedDate(partner.status, partner.approval_date, t),
  };
};

const getVerifiedDate = (status, approvalDate, t) => {
  if (status !== userStatuses.ACTIVE) {
    return t('Not verified');
  }
  if (approvalDate) {
    return `${t('Since')} ${moment(approvalDate).format('MMM YYYY')}`;
  }
  return t('Verified');
};

// Used to clean worker data nested inside a parent offer object
const cleanWorkerInOffer = (ratingForThisSr, partner, offerStatus, appliedDate, shortlisted, offerId, t) => {
  return {
    offerId,
    shortlisted,
    appliedDate,
    ratingForThisSr,
    status: offerStatus,
    id: partner.id,
    imgSrc: partner.image,
    name: getWorkerName(partner),
    age: partner.date_of_birth && `${moment().diff(partner.date_of_birth, 'years')}`,
    gender: textUtils.makeFriendly(partner.gender),
    idNumber: partner.identification_number,
    birthday: moment(partner.date_of_birth).format('DD MMM YYYY'),
    location: partner.address && partner.address.province ? `${partner.address.province}` : t('notProvided'),
    phone: partner.mobile || t('notProvided'),
    email: partner.email || t('notProvided'),
    lastActive: partner.last_opened_app
      ? `${t('active')} ${moment(partner.last_opened_app).fromNow()}`
      : t('notProvided'),
    daysWorked: partner.statistics && partner.statistics.days_worked,
    ratings: partner.statistics && partner.statistics.total_ratings,
    noShows: partner.statistics && partner.statistics.no_shows,
    rejectionReason: partner.rejection_reason ? textUtils.makeFriendly(partner.rejection_reason) : 'N/A',
    verifiedDate: partner.approval_date
      ? `${t('Since')} ${moment(partner.approval_date).format('MMM YYYY')}`
      : t('Not verified'),
    experiences: getExperiences(partner.experiences, t),
  };
};

// ~~~~~~~~~~~~~~~~~ Helper functions to get worker details ~~~~~~~~~~~~~~~~~
const getExperiences = (experiences, t) => {
  if (!experiences || experiences.length === 0) {
    return [];
  }

  return experiences
    .sort((first, second) => {
      let firstStartDate;
      let secondStartDate;
      let order;
      if (first.start_year && first.start_month && second.start_year && second.start_month) {
        // moment counts months like indexes e.g. jan = 0
        firstStartDate = moment()
          .month(first.start_month - 1)
          .year(first.start_year);
        secondStartDate = moment()
          .month(second.start_month - 1)
          .year(second.start_year);
      }
      if (!first.start_month || !second.start_month) {
        firstStartDate = moment().year(first.start_year);
        secondStartDate = moment().year(second.start_year);
      }
      if (!first.start_year || !second.start_year) {
        firstStartDate = moment().month(first.start_month - 1);
        secondStartDate = moment().month(second.start_month - 1);
      }
      if (firstStartDate.isSameOrBefore(secondStartDate)) {
        // sort expects a number to reorder e.g. if order > 0 :. first is before second
        order = 1;
      }
      if (!firstStartDate.isSameOrBefore(secondStartDate)) {
        // if order < 0 :. first after second
        order = -1;
      }
      if ((!first.start_month && !first.start_year) || (!second.start_year && !second.start_month)) {
        // if order = 0 :. equal
        order = 0;
      }
      return order;
    })
    .slice(0, 3)
    .map(experience => {
      const { start_month, start_year, end_month, end_year } = experience;
      const startDate = moment(`${start_month}/01/${start_year}`).format('MMM YYYY');
      let endDate = moment(`${end_month}/01/${end_year}`).format('MMM YYYY');
      // Some experiences are missing end date. We should replace this with an empty string
      if (endDate === 'Invalid date') {
        endDate = '';
      }
      return {
        position: t(experience.position.name),
        companyName: experience.company_name,
        period: `${startDate} – ${endDate}`,
      };
    });
};

const getWorkInterests = partner => {
  return [
    {
      title: "I'm approved to work as",
      value: partner.approved_positions && partner.approved_positions.map(position => position.name).join(', '),
    },
    {
      title: "I'm interested to work as",
      value: partner.preferred_positions && partner.preferred_positions.map(position => position.name).join(', '),
    },
    {
      title: "I'm interested to work at",
      value: partner.preferred_job_areas && partner.preferred_job_areas.map(area => area.name).join(', '),
    },
  ];
};

const getWorkDetails = (partner, t) => {
  const englishLevel = {
    1: 'Low',
    2: 'Average',
    3: 'Good',
  };

  return [
    {
      title: 'English level',
      value: partner.english_proficiency ? t(englishLevel[partner.english_proficiency]) : '',
    },
  ];
};

const getWorkerIdentification = (partner, t, shouldHideDetails) => {
  return [
    {
      title: t('Social security ID'),
      value: partner.social_security_id,
    },
    {
      title: t('ID card number'),
      value: partner.identification_number,
      hide: shouldHideDetails,
    },
    {
      title: t('ID card photo'),
      value: partner.id_card,
      isImage: true,
      hide: shouldHideDetails,
    },
  ];
};

const getWorkerBankDetails = (partner, t, shouldHideDetails) => {
  return [
    { title: t('Tax ID'), value: partner.tax_id },
    {
      title: t('Bank'),
      value:
        partner.bank_account &&
        partner.bank_account.bank &&
        `${partner.bank_account.bank.name} - ${partner.bank_account.bank.bic}`,
      hide: shouldHideDetails,
    },
    {
      title: t('Account name'),
      value: partner.bank_account && partner.bank_account.full_name,
      hide: shouldHideDetails,
    },
    {
      title: t('Account number'),
      value: partner.bank_account && partner.bank_account.number,
      hide: shouldHideDetails,
    },
  ];
};

const getWorkerAccountDetails = (partner, t) => {
  return [{ title: t('Account ID'), value: partner.id }];
};

// Each of the detail cards are used for the worker profile page to display summarized worker information
const getDetailCards = (partner, t) => {
  const workInterests = getWorkInterests(partner);
  const workDetails = getWorkDetails(partner, t);
  return [
    { title: 'WORK INTERESTS', bodyData: workInterests },
    { title: 'WORK DETAILS', bodyData: workDetails },
  ];
};

// ~~~~~~~~~~~~~~~~~ Helper functions to get worker experiences ~~~~~~~~~~~~~~~~~
const getCertificates = partner => {
  if (!partner || !partner.certificates) {
    return [];
  }
  return partner.certificates.map(certi => {
    return {
      id: certi.id,
      title: certi.name,
      subTitle: certi.institution,
      period: certi.year,
      imgSrc: certificate_avatar,
    };
  });
};

const getEducations = (partner, t) => {
  if (!partner || !partner.educations) {
    return [];
  }
  return partner.educations.map(edu => {
    return {
      id: edu.id,
      title: edu.school,
      subTitle: `${t(textUtils.makeFriendly(edu.degree))} - ${edu.field_of_study}`,
      period: edu.graduation_year,
      imgSrc: education_avatar,
    };
  });
};

const filterRelevantExperiences = position => {
  return experience => {
    if (position) {
      if (experience.position.id === position.id) {
        return experience;
      }
      return false;
    }
    return experience;
  };
};

const sortExperiences = (firstExperience, secondExperience) => {
  if (
    firstExperience.start_year &&
    firstExperience.start_month &&
    secondExperience.start_year &&
    secondExperience.start_month &&
    firstExperience.end_year &&
    firstExperience.end_month &&
    secondExperience.end_year &&
    secondExperience.end_month
  ) {
    const firstStartDate = moment({ year: firstExperience.start_year, month: firstExperience.start_month });
    const secondStartDate = moment({ year: secondExperience.start_year, month: secondExperience.start_month });
    const firstEndDate = moment({ year: firstExperience.end_year, month: firstExperience.end_month });
    const secondEndDate = moment({ year: secondExperience.end_year, month: secondExperience.end_month });

    if (firstEndDate.isBefore(secondEndDate)) {
      return 1;
    }
    if (firstEndDate.isAfter(secondEndDate)) {
      return -1;
    }
    if (firstStartDate.isBefore(secondStartDate)) {
      // Don't pair with firstEndDate.isBefore(secondEndDate)
      return 1;
    }
    if (firstStartDate.isAfter(secondStartDate)) {
      // Don't pair with firstEndDate.isAfter(secondEndDate)
      return -1;
    }
  }
  return 0;
};

const formatExperienceDuration = months => {
  if (months > 12) {
    const monthsCount = months % 12;
    const yearsCount = Math.floor(months / 12);
    return `${yearsCount} year${yearsCount > 1 ? 's' : ''}, ${monthsCount} month${monthsCount > 1 ? 's' : ''}`;
  }
  return `${months} month${months > 1 ? 's' : ''}`;
};

const getOtherExperiences = (partner, t, position = null) => {
  if (!partner || !partner.experiences) {
    return [];
  }

  const experiences = partner.experiences
    .filter(filterRelevantExperiences(position))
    .sort(sortExperiences)
    .map(experience => ({
      id: experience.id,
      workmateExperience: false,
      title: t(experience.position.name),
      subTitle: experience.company_name,
      period: `${moment(experience.start_month, 'MM').format('MMM')} ${experience.start_year} - ${moment(
        experience.end_month,
        'MM',
      ).format('MMM')} ${experience.end_year} · ${formatExperienceDuration(experience.months)}`,
      imgSrc: experience_avatar,
      description: experience.description,
    }));

  if (position) {
    return [experiences, partner.experiences.length - experiences.length];
  }

  return experiences;
};

const getWorkmateExperiences = (employments, t, position = null) => {
  if (!employments) {
    return [];
  }

  const experiences = employments
    .filter(filterRelevantExperiences(position))
    .sort(sortExperiences)
    .map(employment => {
      const validDate = employment.start_date || employment.end_date;
      const validPeriod = employment.start_date && employment.end_date;
      const range_start = employment.start_date ? moment(employment.start_date).format('DD MMM YYYY') : '-';
      const range_end = employment.end_date ? moment(employment.end_date).format('DD MMM YYYY') : '-';
      const monthPeriod = validPeriod ? moment(range_end).diff(moment(range_start), 'months') : '-';
      const range = validDate ? `${range_start} - ${range_end}` : t('Date not specified');
      const rangeDescription = validPeriod ? formatExperienceDuration(monthPeriod) : '-';

      return {
        id: employment.id,
        workmateExperience: true,
        title: t(employment.position.name),
        subTitle: employment.client.name,
        period: `${range} · ${rangeDescription}`,
        imgSrc: employment.client.logo,
      };
    });

  if (position) {
    return [experiences, employments.length - experiences.length];
  }

  return experiences;
};

// Each of the experience cards are used for the worker profile page to display work related experiences
// worker profile view
const getExperienceAndEducation = (partner, employedStaffRequests, t) => {
  const workmateExperiences = getWorkmateExperiences(employedStaffRequests, t);
  const otherWorkExperiences = getOtherExperiences(partner, t);
  const educations = getEducations(partner, t);
  const certificates = getCertificates(partner);
  return [
    { header: 'Workmate experience', dataList: workmateExperiences },
    { header: 'Other work experience', dataList: otherWorkExperiences },
    { header: 'Education', dataList: educations },
    { header: 'Certificates', dataList: certificates },
  ];
};

// worker hiring profile view
const getWorkerExperience = (partner, employments, t, position = null) => {
  const workmateExperiences = getWorkmateExperiences(employments, t, position);
  const otherWorkExperiences = getOtherExperiences(partner, t, position);

  if (position) {
    return [
      { header: 'Workmate experience', dataList: workmateExperiences[0], otherExperienceCount: workmateExperiences[1] },
      {
        header: 'Other work experience',
        dataList: otherWorkExperiences[0],
        otherExperienceCount: otherWorkExperiences[1],
      },
    ];
  }

  return [
    { header: 'Workmate experience', dataList: workmateExperiences },
    { header: 'Other work experience', dataList: otherWorkExperiences },
  ];
};

const getWorkerEducation = (partner, t) => {
  const educations = getEducations(partner, t);
  return { header: 'Education', dataList: educations };
};

const getSkillQuizScores = (skillQuizStatistics, applicationPositionCode) => {
  if (!skillQuizStatistics) return;
  const successfulSkillQuizzes = skillQuizStatistics
    .filter(quiz => quiz.average_score >= SKILL_QUIZ_PASSING_SCORE)
    .sort((a, b) => {
      // sort by position names alphabetically
      if (a.code < b.code) return -1;
      if (a.code > b.code) return 1;
      return 0;
    });

  const skillTestScores = [];
  successfulSkillQuizzes.forEach(quiz => {
    // place application position's skill quiz first
    if (applicationPositionCode && applicationPositionCode === quiz.position_code) {
      skillTestScores.unshift({
        position: textUtils.makeFriendly(quiz.position_code).toUpperCase(),
        score: `${convertSkillQuizScoreToPercentageValue(parseInt(quiz.average_score, 10))}%`,
        iconSrc: SKILL_TEST_BADGES[quiz.position_code],
      });
    } else {
      skillTestScores.push({
        position: textUtils.makeFriendly(quiz.position_code).toUpperCase(),
        score: `${convertSkillQuizScoreToPercentageValue(parseInt(quiz.average_score, 10))}%`,
        iconSrc: SKILL_TEST_BADGES[quiz.position_code],
      });
    }
  });

  return skillTestScores;
};

const getApplicationPositionSkillQuizScore = (positionCode, skillQuizStatistics) => {
  const positionQuiz = skillQuizStatistics.filter(quiz => quiz?.position_code === positionCode)[0];
  if (!positionQuiz) return;
  return `${convertSkillQuizScoreToPercentageValue(parseInt(positionQuiz.average_score, 10))}%`;
};

const convertSkillQuizScoreToPercentageValue = score => {
  return (score / 10) * 100;
};

const getDistanceBetween = (address1, address2) => {
  if (!isEmpty(address1) && !isEmpty(address2)) {
    const { latitude: lat1, longitude: lng1 } = address1;
    const { latitude: lat2, longitude: lng2 } = address2;
    const dLat = degree2rad(lat2 - lat1);
    const dLng = degree2rad(lng2 - lng1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degree2rad(lat1)) * Math.cos(degree2rad(lat2)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = EARTH_RADIUS_IN_KM * c; // Distance in km

    return Math.round((distance + Number.EPSILON) * 100) / 100; // Round to 2 decimals
  }
  return null;
};

const degree2rad = degree => degree * (Math.PI / 180);

const isNewWorker = daysWorked => !daysWorked || daysWorked === 0;
// the recommended logic is two things: reliability score and recommended flag for application record
const isRecommendedWorker = (reliabilityScore, recommended) =>
  reliabilityScore >= WORKER_APPLICATION_QUALIFIED_THRESHOLD || recommended;

// Used in appendStatisticsAndExperienceToApplications
const getPartnerStatistics = async partnerIds => {
  return (await partnerApi.getStatistics({ partner: partnerIds })).results;
};

// Used in appendStatisticsAndExperienceToApplications
const getWorkmateEndedEmployments = async partnerIds => {
  const partnersEndedEmployments = await fetchAll(employmentApi.getVerifiedExperience, {
    partner: partnerIds,
    status: employmentStatuses.ENDED,
    ordering: '-end_date',
  });
  return partnersEndedEmployments;
};

const appendStatisticsAndExperienceToApplications = async applications => {
  if (applications.length < 1) {
    return applications;
  }

  const partnerIds = applications.map(({ partner }) => partner.id).join();
  const [partnerStatistics = [], workmateExperiences = []] = await Promise.all([
    getPartnerStatistics(partnerIds),
    getWorkmateEndedEmployments(partnerIds),
  ]);

  return applications.map(application => ({
    ...application,
    partnerStatistics: { ...partnerStatistics.find(stat => stat.partner === application.partner.id) },
    workmateExperiences: [
      ...workmateExperiences.filter(experience => experience.partner.id === application.partner.id),
    ],
  }));
};

const appendApplicationsCount = (applications, counts) => {
  return applications.map(application => {
    const item = counts.find(_item => _item.partner_id === application.partner.id);
    return {
      ...application,
      inProgressApplicationsCount: item ? item.in_progress_applications_count : 0,
      allApplicationsCount: item ? item.all_applications_count : 0,
    };
  });
};

const getWorkerClientHistory = (clientId, workerExperiences = []) => {
  return workerExperiences.find(experience => experience.client.id === clientId);
};

const getPositionDaysWorked = (experiences = [], positionId) => {
  let daysWorked = 0;

  experiences.forEach(experience => {
    if (experience.position.id === positionId) {
      const startDate = moment(experience.start_date);
      const endDate = moment(experience.end_date);

      daysWorked += parseInt(endDate.diff(startDate, 'days', false), 10) || 0;
    }
  });

  return daysWorked;
};

const searchApplicationEmploymentByName = async (value, staffRequestId) => {
  const applications = await applicationApi.list({
    search: value,
    staff_request: staffRequestId,
  });

  const employments = await employmentApi.list({
    search: value,
    staff_request: staffRequestId,
  });

  return [
    ...applications.results.filter(application => application.status !== applicationStatuses.HIRED),
    ...employments.results,
  ];
};

const getApplicationTab = async application => {
  // returns what funnel tab an application belongs to
  const { status, shortlisted, interviewed } = application;
  let applicationTab;
  if (
    [
      employmentStatuses.ACTIVE,
      employmentStatuses.ENDED,
      employmentStatuses.CANCELLED,
      applicationStatuses.HIRED,
    ].includes(status)
  ) {
    // active, ended, cancelled are employment records, which belong to ACCEPTED tab
    // application statuses with hired status also belongs to ACCEPTED tab
    applicationTab = staffRequestTabs.EMPLOYEES;
  } else if ([applicationStatuses.REJECTED, applicationStatuses.WITHDRAWN].includes(status)) {
    applicationTab = staffRequestTabs.INACTIVE;
  } else if (status === applicationStatuses.PENDING_CONTRACT) {
    applicationTab = staffRequestTabs.OFFERS;
  } else if (status === applicationStatuses.APPLIED) {
    applicationTab = staffRequestTabs.APPLIED;
    if (shortlisted) {
      applicationTab = staffRequestTabs.WAITLISTED;
    } else if (interviewed) {
      applicationTab = staffRequestTabs.INTERVIEW;
    }
  }

  return applicationTab;
};

export default {
  getWorkerName,
  cleanProfile,
  cleanWorkerInOffer,
  getDetailCards,
  getExperiences,
  getExperienceAndEducation,
  getSkillQuizScores,
  getApplicationPositionSkillQuizScore,
  getWorkerExperience,
  getWorkerEducation,
  getWorkInterests,
  getWorkDetails,
  getWorkerIdentification,
  getWorkerBankDetails,
  getWorkerAccountDetails,
  getVerifiedDate,
  getDistanceBetween,
  isNewWorker,
  isRecommendedWorker,
  appendStatisticsAndExperienceToApplications,
  appendApplicationsCount,
  getWorkerClientHistory,
  getPositionDaysWorked,
  searchApplicationEmploymentByName,
  getApplicationTab,
};
