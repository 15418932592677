// eslint-disable-next-line import/prefer-default-export
export const UPDATE_DELAY = 2000; // milliseconds

export const twilioConnectionStates = {
  CONNECTED: 'connected',
  CONNECTING: 'connecting',
  DISCONNECTED: 'disconnected',
  DISCONNECTING: 'disconnecting',
  DENIED: 'denied',
};

export const channelTypes = {
  BROADCAST: 'broadcast',
  PRIVATE: 'private',
};

export const PAGE_SIZE = 20;
