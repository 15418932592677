import React from 'react';
import { Popover, Row, Col, Empty, Input } from 'antd';
import { withTranslation } from 'react-i18next';
import { isEmpty, isEqual } from 'lodash';

import workerUtils from '../../../utilities/workerUtils';

import SearchResult from './SearchResult';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';

class SearchDropdownFilter extends React.Component {
  state = {
    isSearchPopoverVisible: false,
    isSearchLoading: false,
    searchValue: '',
    searchResult: [],
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.selected[0], prevProps.selected[0]) && this.props.selected[0]?.partner) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ searchValue: workerUtils.getWorkerName(this.props.selected[0].partner) });
    }
  }

  handleInputChange = value => {
    const stateOptions = { searchValue: value, showSearchedList: false };
    const { showAll } = this.props;
    if (isEmpty(value)) {
      stateOptions.isSearchPopoverVisible = false;
      showAll();
    }

    this.setState(stateOptions);
  };

  handleInputSearch = async value => {
    this.setState({
      isSearchPopoverVisible: true,
      isSearchLoading: true,
      searchResult: [],
    });

    if (!isEmpty(value)) {
      const { staffRequest } = this.props;
      const results = await workerUtils.searchApplicationEmploymentByName(value, staffRequest.id);
      this.setState({
        searchResult: results,
      });
    }

    this.setState({ isSearchLoading: false });
  };

  handleClick = worker => {
    const { handleResultClick } = this.props;
    this.setState(
      {
        isSearchPopoverVisible: false,
        isSearchLoading: false,
        searchValue: workerUtils.getWorkerName(worker.partner),
      },
      () => handleResultClick(worker),
    );
  };

  render() {
    const { isSearchPopoverVisible, isSearchLoading, searchResult, searchValue } = this.state;
    const { t } = this.props;

    return (
      <Popover
        visible={isSearchPopoverVisible}
        placement="bottom"
        overlayClassName="ant-popover-wrapper ant-popover-wrapper-search"
        overlayStyle={{
          minWidth: 410,
          maxWidth: 410,
        }}
        content={
          <Row type="flex" justify="space-between">
            <Col span={24}>
              {isSearchLoading && (
                <div className="ant-popover-search-spinner">
                  <LoadingSpinner width="50px" />
                </div>
              )}

              {!isSearchLoading && searchResult.length === 0 ? (
                <Empty description={t('noResultsFound')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                searchResult.map((worker, index) => {
                  return <SearchResult key={index} worker={worker} handleClick={this.handleClick} />;
                })
              )}
            </Col>
          </Row>
        }
      >
        <Input.Search
          allowClear
          placeholder={t('searchByWorkmate')}
          onSearch={value => this.handleInputSearch(value)}
          onChange={e => this.handleInputChange(e.target.value)}
          value={searchValue}
        />
      </Popover>
    );
  }
}

export default withTranslation()(SearchDropdownFilter);
