/* eslint no-unused-vars : "off" */
import React from 'react';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import {
  CheckCircleFilled,
  ProfileOutlined,
  SafetyCertificateOutlined,
  MessageFilled,
  EyeFilled,
} from '@ant-design/icons';
import { Row, Checkbox, Col, Avatar, Card, Typography, Tooltip } from 'antd';
import { withTranslation, Trans } from 'react-i18next';

import WorkerRatingButtons from '../../../shared/components/WorkerRatingButtons';
import WorkerTag from '../../../shared/components/WorkerTag';
import SparkTag from '../../../shared/components/SparkTag';
import workerUtils from '../../../utilities/workerUtils';
import routes from '../../../routes';
import { workerTags, workerCovidCertificationStatuses, partnerSourceTypes } from '../../../constants';
import { colors } from '../../../styles/colors';
import EmploymentStatus from './EmploymentStatus';
import textUtils from '../../../utilities/textUtils';
import ProgressBar from '../../../shared/components/ProgressBar';
import { LONG_DATE } from '../../../styles/dates';

const { Paragraph, Text, Title } = Typography;

const getFirstDayMessageSentTooltipTitle = (firstDayMessageSentDate, t) => {
  if (moment(firstDayMessageSentDate).diff(moment()) > 0) {
    return `${t('autoSendFirstDayMessage')} ${moment(firstDayMessageSentDate).toNow(true)}`;
  }
  return firstDayMessageSentDate
    ? `${t('firstDayMessageSent')} ${moment(firstDayMessageSentDate).toNow(true)} ${t('ago')}`
    : null;
};

const EmploymentListItem = ({
  employment,
  showCheckbox,
  checked,
  onCheck,
  onEndEmployment,
  onWorkerRatingGiven,
  onChat,
  index,
  t,
  user,
  positionId,
}) => {
  const worker = employment.partner;
  const workerStatistics = employment.partnerStatistics;
  const { workmateExperiences } = employment;
  const daysWorked = workerStatistics && workerStatistics.total_days_worked;
  const rating = workerStatistics && workerStatistics.avg_rating_from_client;
  const age = worker.date_of_birth && `${moment().diff(worker.date_of_birth, 'years')}`;
  const workerName = workerUtils.getWorkerName(worker);
  const isVaccinated = worker.covid_certification_status === workerCovidCertificationStatuses.VERIFIED;
  // Partners through employments are already active partners

  const employmentLocation = employment.location.name;
  const distanceBetween = workerUtils.getDistanceBetween(employment?.location?.address, worker?.address);

  const skillQuizPosition = employment.position;
  const skillQuizStatistics =
    workerStatistics && workerStatistics.skill_quiz_statistics && JSON.parse(workerStatistics.skill_quiz_statistics);
  let skillQuizScore;
  if (skillQuizStatistics) {
    skillQuizScore = workerUtils.getApplicationPositionSkillQuizScore(skillQuizPosition.code, skillQuizStatistics);
  }

  const { position } = employment;

  const [workmateExperience = [], otherWorkExperience = []] = workerUtils.getWorkerExperience(
    worker,
    workmateExperiences,
    t,
    position,
  );
  const workerExperience = [...(workmateExperience.dataList || []), ...(otherWorkExperience.dataList || [])].slice(
    0,
    3,
  );
  const otherExperienceCount = workmateExperience.otherExperienceCount + otherWorkExperience.otherExperienceCount;
  const clientExperience = workerUtils.getWorkerClientHistory(user.clientId, workmateExperiences);
  const positionDaysWorked = workerUtils.getPositionDaysWorked(workmateExperiences, positionId);

  const isDirectJobInvite = employment?.source === partnerSourceTypes.DIRECT_JOB_INVITE;
  const isAutohired = employment?.auto_hired;
  const isRecommended = employment.recommended;

  const workerTagInfo = [
    { type: workerTags.NEW_JOINER, isActive: !clientExperience && workerUtils.isNewWorker(daysWorked) },
    { type: workerTags.RECOMMENDED, isActive: isRecommended },
    { type: workerTags.PAST_WORKER, isActive: clientExperience },
    { type: workerTags.JOB_INVITATION, isActive: isDirectJobInvite },
    { type: workerTags.SKILL_SCORE, isActive: skillQuizScore },
  ];

  const workerExperienceInfo = [
    { type: workerTags.RATING, isActive: rating },
    { type: workerTags.DAYS_WORKED, isActive: daysWorked },
    { type: workerTags.COVID_VACCINATED, isActive: isVaccinated },
  ];

  const firstDayMessage = employment.staff_request?.first_day_message;

  return (
    <Card
      bordered={false}
      style={{ backgroundColor: 'inherit', borderBottom: '1px solid #00000017' }}
      bodyStyle={{ padding: 0 }}
    >
      <Row type="flex" style={{ paddingTop: 32, paddingBottom: 24 }}>
        <Col span={3}>
          <Row>
            {showCheckbox && (
              <Col style={{ marginRight: '10px' }}>
                <Checkbox
                  checked={checked}
                  onChange={onCheck}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  value={employment}
                />
              </Col>
            )}
            <Col>
              <Avatar shape="square" size={120} src={worker.image} className="v2-avatar-wrapper" />
              {isAutohired && <SparkTag />}
            </Col>
          </Row>
        </Col>
        <Col span={13} style={{ paddingLeft: 12 }}>
          <Row type="flex" align="middle" style={{ marginBottom: 4 }}>
            <Paragraph ellipsis={{ row: 1 }} style={{ marginBottom: 0 }}>
              <Title strong level={4} style={{ marginBottom: 0, marginRight: 12, display: 'inline-block' }}>
                <Link
                  style={{ color: 'inherit' }}
                  to={`${routes.partners}/${worker.id}`}
                  className="underline-on-hover"
                >
                  {workerName}
                </Link>
              </Title>
            </Paragraph>
            {workerExperienceInfo
              .filter(({ isActive }) => isActive)
              .map(({ type }) => (
                <span key={type} style={{ marginRight: 12 }}>
                  <WorkerTag
                    type={type}
                    daysWorked={daysWorked}
                    positionDaysWorked={positionDaysWorked}
                    rating={rating}
                    companyName={employment.client?.name}
                  />
                </span>
              ))}
          </Row>

          {/* Age & location */}
          <Row style={{ paddingBottom: 8 }}>
            {workerTagInfo
              .filter(({ isActive }) => isActive)
              .map(({ type }) => (
                <span key={type} style={{ marginRight: 12 }}>
                  <WorkerTag
                    type={type}
                    daysWorked={daysWorked}
                    skillScore={skillQuizScore}
                    positionName={skillQuizPosition.name}
                    companyName={employment.client?.name}
                  />
                </span>
              ))}
          </Row>
          <Row>
            <Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: '4px' }}>
              <Text type="secondary">
                {worker.gender ? `${textUtils.capitalizeWord(t(worker.gender))} · ` : ''}
                {age ? `${age}yo · ` : ''} {worker.mobile}
              </Text>
            </Paragraph>
          </Row>

          {distanceBetween && (
            <Row style={{ marginBottom: 24 }}>
              <Text strong>
                <Trans
                  i18nKey="distanceAwayFromLocation"
                  values={{ distance: `${distanceBetween} km`, location: employmentLocation }}
                />
              </Text>
            </Row>
          )}

          <Row>
            <Col>
              <Text
                strong
                style={{
                  fontSize: '12px',
                  lineHeight: '20px',
                  color: colors.primaryText,
                  marginBottom: 8,
                  display: 'block',
                }}
              >
                {t('experience').toUpperCase()}
              </Text>
              {workerExperience.length > 0 ? (
                workerExperience.map(experience => (
                  <Paragraph key={experience.id} ellipsis={{ rows: 1 }} style={{ marginBottom: '8px' }}>
                    {experience.workmateExperience ? (
                      <Tooltip title={t('verifiedExperienceTooltip')}>
                        <SafetyCertificateOutlined
                          style={{ color: colors.secondaryText, fontSize: '12px', marginRight: 8 }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={t('providedExperienceTooltip')}>
                        <ProfileOutlined style={{ color: colors.secondaryText, fontSize: '12px', marginRight: 8 }} />
                      </Tooltip>
                    )}
                    <Text strong style={{ color: colors.primaryText }}>{`${experience.title}`}</Text>
                    <Text type="secondary" ellipsis={{ tooltip: true }}>
                      {` · ${experience.period} · ${experience.subTitle}`}
                    </Text>
                  </Paragraph>
                ))
              ) : (
                <Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: '8px' }}>
                  <Tooltip title={t('providedExperienceTooltip')}>
                    <ProfileOutlined style={{ color: colors.secondaryText, fontSize: '12px', marginRight: 8 }} />
                  </Tooltip>
                  <Text type="secondary">
                    {t('noExpInThisPosition')}
                    {otherExperienceCount > 0 && (
                      <>
                        {` · `}
                        <Link
                          style={{ color: colors.functionalLink }}
                          to={`${routes.partners}/${worker.id}`}
                          className="underline-on-hover"
                        >
                          {t('seeOtherExperience', { count: otherExperienceCount })}
                        </Link>
                      </>
                    )}
                  </Text>
                </Paragraph>
              )}
            </Col>
          </Row>
        </Col>
        <Col
          span={7}
          style={{
            textAlign: 'right',
            marginLeft: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
          }}
        >
          <WorkerRatingButtons
            employment={employment}
            index={index}
            worker={worker}
            workerRating={employment.rating}
            giveWorkerRating={onWorkerRatingGiven}
            onReject={onEndEmployment}
            onChat={onChat}
          />
          {firstDayMessage && (
            <Row style={{ marginTop: '16px' }}>
              <ProgressBar
                steps={[
                  {
                    icon: <CheckCircleFilled style={{ fontSize: '20px', color: colors.functionalSuccess }} />,
                    bridgeColor:
                      moment(employment.first_day_message_sent_date).diff(moment()) < 0
                        ? colors.functionalSuccess
                        : colors.componentBorder,
                    tooltipTitle: `${t('accepted')} ${moment(employment.created_date).toNow(true)} ${t('ago')}`,
                  },
                  {
                    icon: (
                      <MessageFilled
                        style={{
                          fontSize: '20px',
                          color:
                            moment(employment.first_day_message_sent_date).diff(moment()) < 0
                              ? colors.functionalSuccess
                              : colors.componentBorder,
                        }}
                      />
                    ),
                    bridgeColor: employment.first_day_message_seen_date
                      ? colors.functionalSuccess
                      : colors.componentBorder,
                    tooltipTitle: getFirstDayMessageSentTooltipTitle(employment.first_day_message_sent_date, t),
                  },
                  {
                    icon: (
                      <EyeFilled
                        style={{
                          fontSize: '20px',
                          color: employment.first_day_message_seen_date
                            ? colors.functionalSuccess
                            : colors.componentBorder,
                        }}
                      />
                    ),
                    bridgeColor: null,
                    tooltipTitle: employment.first_day_message_seen_date
                      ? `${t('firstDayMessageSeen')} ${moment(employment.first_day_message_seen_date).toNow(true)} ${t(
                          'ago',
                        )}`
                      : null,
                  },
                ]}
              />
            </Row>
          )}
          <Row style={{ marginTop: '16px', marginBottom: 4, justifyContent: 'flex-end' }}>
            <Text type="secondary">
              <EmploymentStatus
                t={t}
                status={employment.status}
                createdDate={employment?.created_date}
                firstDayMessage={firstDayMessage}
                firstDayMessageSentDate={employment?.first_day_message_sent_date}
                firstDayMessageSeenDate={employment?.first_day_message_seen_date}
                startDate={moment(employment.start_date).format(LONG_DATE)}
                endDate={employment.end_date ? moment(employment.end_date).format(LONG_DATE) : undefined}
              />
            </Text>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(withRouter(EmploymentListItem));
