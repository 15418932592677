import React from 'react';
import logoSrc from '../../assets/images/workmate_dark_logo.svg';

const BrandLogo = ({ onClick, style }) => {
  // If onClick is present, we should show cursor pointer style to show that it is clickable
  let onClickStyle = {};
  if (onClick) {
    onClickStyle = { cursor: 'pointer' };
  }
  return (
    <button type="button" className="button-img-wrapper" onClick={onClick} style={{ ...onClickStyle, ...style }}>
      <img src={logoSrc} alt="Brand logo" style={{ width: '100%' }} />
    </button>
  );
};

export default BrandLogo;
