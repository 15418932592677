import React from 'react';
import moment from 'moment';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { withTranslation } from 'react-i18next';
import WeekSelect from './WeekSelect';

export default withTranslation()(({ t, onChange, selectedWeekStart, loading, disableFutureDates = true }) => {
  // Prevent selection of future weeks if disable future dates is enabled
  const reachedEnd = selectedWeekStart?.clone().add(1, 'weeks') > moment().endOf('isoWeek');
  return (
    <div style={{ display: 'flex' }}>
      <WeekSelect
        onChange={onChange}
        selectedWeekStart={selectedWeekStart}
        loading={loading}
        disableFutureDates={disableFutureDates}
      />
      <Button style={{ margin: '0 0 0 24px' }} onClick={() => onChange(moment())}>
        {t('thisWeek')}
      </Button>

      <Button
        style={{ margin: '0 8px 0 24px', width: '32px', padding: 'unset' }}
        onClick={() =>
          onChange(
            moment(selectedWeekStart)
              .subtract(1, 'weeks')
              .toISOString(),
          )
        }
      >
        <LeftOutlined />
      </Button>
      <Button
        style={{ width: '32px', padding: 'unset' }}
        disabled={reachedEnd && disableFutureDates}
        onClick={() =>
          onChange(
            moment(selectedWeekStart)
              .add(1, 'weeks')
              .toISOString(),
          )
        }
      >
        <RightOutlined />
      </Button>
    </div>
  );
});
