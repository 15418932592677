import * as Sentry from '@sentry/browser';
import { Button, Col, Modal, Row, Typography, message } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useCountryInfo } from '../../../../graphql/queries/countries';
import amountUtils from '../../../../utilities/amountUtils';
import { colors } from '../../../../styles/colors';
import shiftApi from '../../../../services/shiftApi';

const ShiftCancellationModal = ({ visible, onClose, country, shift, onSuccess }) => {
  const [cancelling, setCancelling] = useState(false);
  const { data: countryInfo = {} } = useCountryInfo(country.id);
  const { t } = useTranslation();

  const handleConfirmCancelShift = async () => {
    try {
      setCancelling(true);
      await shiftApi.cancel(shift.id);
      setCancelling(false);
      onClose();
      onSuccess();
    } catch (error) {
      Sentry.captureException(error);
      message.error(t('Sorry there is an error'));
      setCancelling(false);
    }
  };

  const cancellationFee = amountUtils.formatAmount(
    Number(countryInfo?.gig_cancellation_fee) || 0,
    country.currency_code,
  );
  const isWithin4Hours =
    moment(shift?.startTime).diff(moment(moment().format('YYYY-MM-DD HH:mm:ss')), 'hours', true) <= 4;

  return (
    <Modal visible={visible} onCancel={onClose} title={null} closable={null} footer={null}>
      <Row style={{ width: '100%' }}>
        <Col xs={2}>
          <ExclamationCircleOutlined style={{ color: colors.functionalError, fontSize: 20, marginTop: 4 }} />
        </Col>
        <Col xs={22}>
          <Typography.Paragraph style={{ fontSize: 16, fontWeight: '600', marginBottom: 8 }}>
            {isWithin4Hours ? t('cancelShiftWithin4HoursTitle') : t('cancelShiftNotWithin4HoursTitle')}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {isWithin4Hours
              ? t('cancelShiftWithin4HoursSubtitle', { cancellationFee })
              : t('cancelShiftNotWithin4HoursSubtitle')}
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row style={{ justifyContent: 'flex-end' }}>
        <Button style={{ marginRight: 6 }} onClick={onClose}>
          {t('back')}
        </Button>
        <Button type="primary" danger onClick={handleConfirmCancelShift} disabled={cancelling} loading={cancelling}>
          {t('cancelShift')}
        </Button>
      </Row>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    country: state.user.country,
  };
};

export default connect(mapStateToProps)(ShiftCancellationModal);
