import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { roles } from '../../constants';
import routes from '../../routes';

const AuthRedirect = ({ user }) => {
  const isSupervisor = user.role === roles.SUPERVISOR;
  // if user role is supervisor then redirect to workforce route
  // otherwise redirect to jobs route
  const pathToRedirect = isSupervisor ? routes.workforce : routes.staffRequests;
  return <Redirect to={pathToRedirect} />;
};

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(AuthRedirect);
