import queryString from 'query-string';
import base from './base';
import { endpoints, readonlyEndpoints } from '../config';

const searchPartners = async params => {
  const response = await base.api.get(`${endpoints.partners}/`, params);
  return response;
};

const fetchPartnerById = async id => {
  const response = await base.api.get(`${endpoints.partners}/${id}/`);
  return response;
};

const getStatistics = async params => {
  const response = await base.api.get(`${readonlyEndpoints.partners}/statistics/`, { params });
  return response;
};

const fetchOffersByPartnerId = async (id, params) => {
  const query = queryString.stringify(params);
  const response = await base.api.get(`${endpoints.partners}/${id}/offers/?${query}`);
  return response;
};

const fetchWorkmateExperiences = async id => {
  const response = await base.api.get(`${endpoints.partners}/${id}/workmate-experiences/`);
  return response;
};

const createPartner = async partner => {
  const response = await base.api.post(`${endpoints.partners}/`, partner);
  return response;
};

const bulkCreatePartners = async payload => {
  const response = await base.api.post(`${endpoints.partners}/bulk-create/`, payload);
  return response;
};

const validateMobile = async mobile => {
  const response = await base.api.post(`${endpoints.partners}/validate-mobile/`, {
    mobile,
  });
  return response;
};

export default {
  fetchPartnerById,
  getStatistics,
  fetchOffersByPartnerId,
  searchPartners,
  fetchWorkmateExperiences,
  createPartner,
  bulkCreatePartners,
  validateMobile,
};
