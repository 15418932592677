import React from 'react';
import { withTranslation } from 'react-i18next';
import { CalendarOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Avatar, Row, Col, Card, Typography } from 'antd';

import staffRequestApi from '../services/staffRequestApi';
import distanceUtils from '../utilities/distanceUtils';

const { Text, Paragraph } = Typography;

const WorkerPreviewCard = ({ worker, sRLat, sRLng, t }) => {
  const workerHomeLatLng = {
    latitude: worker.address && worker.address.latitude,
    longitude: worker.address && worker.address.longitude,
  };
  const srLatLng = { latitude: sRLat, longitude: sRLng };
  const numKmAway = distanceUtils.getAbsoluteDistance(workerHomeLatLng, srLatLng);
  const distance = numKmAway && `${numKmAway.toFixed(2)} ${t('KM away')}`;
  const workerExperiences = worker.experiences.sort((a, b) => b.months - a.months).slice(0, 4);
  return (
    <Row type="flex" gutter={16} style={{ margin: '32px 0' }}>
      <Col>
        <Avatar shape="square" size={56} src={worker.image} className="v2-avatar-wrapper" />
      </Col>
      <Col>
        <Paragraph ellipsis={{ row: 1 }} style={{ marginBottom: 0 }}>
          <Text strong>{`${worker.first_name} ${worker.last_name}`}</Text>
        </Paragraph>

        <Paragraph ellipsis={{ row: 1 }} style={{ marginBottom: '8px' }}>
          <Text strong style={{ color: '#69e4a6' }}>
            <CalendarOutlined style={{ paddingRight: '4px', fontSize: '16px' }} />
            {`${worker.statistics.days_worked} ${t('days worked')}`}

            <EnvironmentOutlined style={{ paddingLeft: '16px', paddingRight: '4px', fontSize: '16px' }} />
            {distance}
          </Text>
        </Paragraph>

        {workerExperiences.map((experience, index) => (
          <Paragraph key={index} ellipsis={{ rows: 1 }} style={{ marginBottom: '2px' }}>
            <Text>{`${experience.position.name} · `}</Text>
            <Text type="secondary">{`${experience.months} ${t('mos')}`}</Text>
          </Paragraph>
        ))}
      </Col>
    </Row>
  );
};
class PotentialMatchesCard extends React.Component {
  state = {
    isLoading: false,
    matchesCount: 0,
    workerList: [],
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { staffRequest } = this.props;
    const response = await staffRequestApi.fetchPotentialMatches({
      location: staffRequest.location.id,
      position: staffRequest.position.id,
      client: staffRequest.client.id,
    });
    // Order our top 4 workers
    if (response) {
      let workerList = [];
      if (response.results.length > 0) {
        workerList = response.results.sort((a, b) => b.statistics.days_worked - a.statistics.days_worked).slice(0, 4);
      }
      this.setState({ isLoading: false, matchesCount: response.count, workerList });
    }
  }

  render() {
    const { isLoading, matchesCount, workerList } = this.state;
    const { staffRequest, t } = this.props;
    return (
      <Card loading={isLoading} bordered={false} style={{ backgroundColor: 'inherit' }} bodyStyle={{ padding: 0 }}>
        <Row style={{ margin: '8px 0 16px 0' }}>
          <Text strong type="secondary" style={{ fontSize: '12px' }}>
            {t('POTENTIAL MATCHES')}
          </Text>
        </Row>

        <Row style={{ marginBottom: '8px' }}>
          <Text strong style={{ display: 'block' }}>{`${matchesCount} ${t('Workmates available')}`}</Text>
          {matchesCount ? (
            <Text>
              {t(
                `These are the total Workmates who are qualified and available for your staff request. Here's a preview of who some of them are:`,
              )}
            </Text>
          ) : (
            <Text>
              {t(
                `There are currently no Workmates who are qualifed or available for this job position. You can still submit this request, or contact us.`,
              )}
            </Text>
          )}
        </Row>

        <section>
          {workerList.map((worker, index) => {
            const lat = staffRequest.location.address.latitude;
            const lng = staffRequest.location.address.longitude;
            return <WorkerPreviewCard key={index} worker={worker} sRLat={lat} sRLng={lng} t={t} />;
          })}
        </section>
      </Card>
    );
  }
}

export default withTranslation()(PotentialMatchesCard);
