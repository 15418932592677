import React from 'react';
import { Col, Row, Typography } from 'antd';

const { Text, Title } = Typography;

const EmptyChatList = ({ title, message }) => {
  return (
    <>
      <Row justify="center" style={{ marginBottom: '16px' }}>
        <Col>
          <svg width="112" height="123" viewBox="0 0 112 123" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M56 122.074C86.9279 122.074 112 116.584 112 109.811C112 103.039 86.9279 97.5483 56 97.5483C25.0721 97.5483 0 103.039 0 109.811C0 116.584 25.0721 122.074 56 122.074Z"
              fill="#F5F5F5"
            />
            <path
              d="M96.25 74.3537L78.4945 54.2038C77.6423 52.8304 76.398 52 75.0873 52H36.9128C35.602 52 34.3577 52.8304 33.5055 54.2021L15.75 74.3554V90.5408H96.25V74.3537Z"
              stroke="#D9D9D9"
            />
            <path
              d="M78.0873 19.9318C75.3595 17.1948 72.1222 15.0184 68.558 13.5255C64.9024 11.9896 60.9759 11.2027 57.0107 11.2113H56.8746C48.8908 11.2433 41.4195 14.3663 35.8301 20.0118C30.2326 25.6573 27.1816 33.1527 27.2137 41.1364C27.2377 45.9652 28.4389 50.7618 30.6971 54.998L31.0574 55.6706V66.9456H42.3324L43.0051 67.306C47.2412 69.5642 52.0379 70.7653 56.8666 70.7894H57.0027C64.9305 70.7894 72.3777 67.7304 77.9912 62.173C83.6287 56.5835 86.7598 49.1122 86.8078 41.1364C86.8238 37.0845 86.0471 33.1607 84.4935 29.4611C82.9997 25.8974 80.8234 22.6603 78.0873 19.9318V19.9318ZM40.9871 44.8441C39.9813 44.8235 39.0235 44.4095 38.3195 43.6909C37.6154 42.9723 37.221 42.0064 37.221 41.0003C37.221 39.9943 37.6154 39.0283 38.3195 38.3097C39.0235 37.5911 39.9813 37.1771 40.9871 37.1566C41.9929 37.1771 42.9507 37.5911 43.6547 38.3097C44.3588 39.0283 44.7532 39.9943 44.7532 41.0003C44.7532 42.0064 44.3588 42.9723 43.6547 43.6909C42.9507 44.4095 41.9929 44.8235 40.9871 44.8441V44.8441ZM57.0027 44.8441C55.9969 44.8235 55.0392 44.4095 54.3351 43.6909C53.631 42.9723 53.2366 42.0064 53.2366 41.0003C53.2366 39.9943 53.631 39.0283 54.3351 38.3097C55.0392 37.5911 55.9969 37.1771 57.0027 37.1566C58.0086 37.1771 58.9663 37.5911 59.6704 38.3097C60.3745 39.0283 60.7688 39.9943 60.7688 41.0003C60.7688 42.0064 60.3745 42.9723 59.6704 43.6909C58.9663 44.4095 58.0086 44.8235 57.0027 44.8441V44.8441ZM73.0184 44.8441C72.0125 44.8235 71.0548 44.4095 70.3507 43.6909C69.6466 42.9723 69.2523 42.0064 69.2523 41.0003C69.2523 39.9943 69.6466 39.0283 70.3507 38.3097C71.0548 37.5911 72.0125 37.1771 73.0184 37.1566C74.0242 37.1771 74.9819 37.5911 75.686 38.3097C76.3901 39.0283 76.7844 39.9943 76.7844 41.0003C76.7844 42.0064 76.3901 42.9723 75.686 43.6909C74.9819 44.4095 74.0242 44.8235 73.0184 44.8441V44.8441Z"
              fill="white"
            />
            <path
              d="M69.1719 41C69.1719 42.0194 69.5768 42.9971 70.2977 43.7179C71.0185 44.4388 71.9962 44.8438 73.0156 44.8438C74.0351 44.8438 75.0127 44.4388 75.7336 43.7179C76.4544 42.9971 76.8594 42.0194 76.8594 41C76.8594 39.9806 76.4544 39.0029 75.7336 38.2821C75.0127 37.5612 74.0351 37.1563 73.0156 37.1562C71.9962 37.1563 71.0185 37.5612 70.2977 38.2821C69.5768 39.0029 69.1719 39.9806 69.1719 41ZM37.1406 41C37.1406 42.0194 37.5456 42.9971 38.2664 43.7179C38.9873 44.4388 39.9649 44.8438 40.9844 44.8438C42.0038 44.8438 42.9815 44.4388 43.7023 43.7179C44.4232 42.9971 44.8281 42.0194 44.8281 41C44.8281 39.9806 44.4232 39.0029 43.7023 38.2821C42.9815 37.5612 42.0038 37.1563 40.9844 37.1562C39.9649 37.1563 38.9873 37.5612 38.2664 38.2821C37.5456 39.0029 37.1406 39.9806 37.1406 41V41Z"
              fill="#D9D9D9"
            />
            <path
              d="M81.8461 16.1526L81.8465 16.1529C85.0855 19.3998 87.6253 23.1784 89.3974 27.3886C91.2242 31.748 92.1492 36.3807 92.1257 41.1564C92.1021 45.911 91.161 50.5125 89.3112 54.839L89.3108 54.8399C87.5314 59.0182 84.9841 62.7648 81.7456 65.972L81.7447 65.9729C78.5336 69.1641 74.7314 71.6989 70.5507 73.4356C66.2619 75.2154 61.7071 76.125 57.0087 76.125L56.8442 76.125C56.8437 76.125 56.8431 76.125 56.8426 76.125C51.3979 76.0982 46.0334 74.8099 41.1702 72.3614L41.0111 72.2812H40.8329H28.653C27.875 72.2812 27.1288 71.9722 26.5786 71.422C26.0285 70.8719 25.7194 70.1257 25.7194 69.3477V57.1758V56.9976L25.6393 56.8385C23.1905 51.9748 21.9023 46.6098 21.8757 41.1645L21.8757 41.1644C21.8521 36.4112 22.7617 31.7937 24.565 27.4501L24.5651 27.45C26.305 23.2568 28.8208 19.4944 32.0287 16.2551L32.029 16.2548C35.2139 13.0349 38.9948 10.4655 43.161 8.68975L43.1617 8.68942C47.4877 6.83993 52.0885 5.89877 56.8424 5.875H57.0007C61.7227 5.875 66.3004 6.79242 70.6118 8.60321L70.6128 8.60363C74.8148 10.3613 78.6313 12.926 81.8461 16.1526ZM56.8608 71.5391H56.8645H57.0007C65.1254 71.5391 72.7625 68.4025 78.5168 62.7056L77.9891 62.1727L78.5172 62.7053C84.2964 56.9753 87.5065 49.3138 87.5557 41.1407L87.5557 41.1391C87.5722 36.9878 86.776 32.9642 85.1832 29.1707C83.6477 25.5086 81.4305 22.2161 78.6156 19.4011C75.7926 16.5782 72.5082 14.3691 68.8464 12.8337C65.0938 11.2571 61.1111 10.4609 57.0087 10.4609L56.8725 10.4609L56.8695 10.4609C48.6878 10.4938 41.0255 13.6959 35.295 19.4838C29.5567 25.2716 26.4288 32.9583 26.4616 41.1391L26.4616 41.1399C26.4862 46.0889 27.717 51.0056 30.0332 55.3505L30.0339 55.3518L30.3054 55.8586V66.9453V67.6953H31.0554H42.1421L42.6488 67.9668L42.6502 67.9675C46.9951 70.2837 51.9117 71.5144 56.8608 71.5391Z"
              fill="#D9D9D9"
              stroke="white"
              strokeWidth="1.5"
            />
            <path
              d="M53.1562 41C53.1563 42.0194 53.5612 42.9971 54.2821 43.7179C55.0029 44.4388 55.9806 44.8438 57 44.8438C58.0194 44.8438 58.9971 44.4388 59.7179 43.7179C60.4388 42.9971 60.8438 42.0194 60.8438 41C60.8438 39.9806 60.4388 39.0029 59.7179 38.2821C58.9971 37.5612 58.0194 37.1563 57 37.1562C55.9806 37.1563 55.0029 37.5612 54.2821 38.2821C53.5612 39.0029 53.1563 39.9806 53.1562 41V41Z"
              fill="#D9D9D9"
            />
            <path
              d="M72.8227 79.9087C72.8227 77.0969 74.5623 74.7757 76.72 74.774H96.25V106.547C96.25 110.267 93.94 113.315 91.0875 113.315H20.9125C18.06 113.315 15.75 110.265 15.75 106.547V74.774H35.28C37.4377 74.774 39.1772 77.0917 39.1772 79.9034V79.942C39.1772 82.7537 40.936 85.0241 43.092 85.0241H68.908C71.064 85.0241 72.8227 82.7327 72.8227 79.9209V79.9087V79.9087Z"
              fill="#FAFAFA"
              stroke="#D9D9D9"
            />
          </svg>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          {title && (
            <Title type="secondary" level={4}>
              {title}
            </Title>
          )}
          <Text type="secondary">{message}</Text>
        </Col>
      </Row>
    </>
  );
};

export default EmptyChatList;
