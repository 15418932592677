import { endpoints } from '../config';
import base from './base';

const fetchBillingReportFromId = async id => {
  const response = await base.api.get(`${endpoints.billingReports}/${id}/`);
  return response;
};

const fetchBillingReports = async params => {
  const response = await base.api.get(`${endpoints.billingReports}/`, {
    params,
  });
  return response;
};

const fetchBillingItemsWithParams = async params => {
  const response = await base.api.get(`${endpoints.billingItems}/`, {
    params,
  });
  return response;
};

const generatePrepaidBillingReport = async params => {
  const response = await base.api.post(`${endpoints.billingReports}/generate-prepaid/`, params);
  return response;
};

const unbilledExist = async params => {
  const response = await base.api.get(`${endpoints.billingReports}/unbilled-exist/`, { params });
  return response;
};

export default {
  fetchBillingReportFromId,
  fetchBillingReports,
  fetchBillingItemsWithParams,
  generatePrepaidBillingReport,
  unbilledExist,
};
