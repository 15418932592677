import moment from 'moment-timezone';
import { TIME_FORMAT_24_HOUR } from '../constants';

export const getSecondsFromHoursMinutesOfDate = (hours, minutes) => {
  return hours * 3600 + minutes * 60;
};

export const getClockInOutFromDateTime = (date, time) => {
  if (!time) {
    return undefined;
  }

  return date
    .clone()
    .set('hour', time.hours())
    .set('minute', time.minutes());
};

export const getDifferenceInHours = (startString, endString) => {
  const start = moment(startString);
  const end = moment(endString);
  return end.diff(start, 'hours');
};

export const getFriendlyClockInOutTimes = (clockInTime, clockOutTime) => {
  // Assume clockInTime will always be here
  if (!clockInTime) {
    return '-';
  }

  const clockInFormatted = moment(clockInTime).format('DD MMM YYYY, hh:mm A');
  if (!clockOutTime) {
    return { start: clockInFormatted };
  }

  const clockOutFormatted = moment(clockOutTime).format('DD MMM YYYY, hh:mm A');
  const differenceInHours = getDifferenceInHours(clockInTime, clockOutTime);
  return {
    start: clockInFormatted,
    end: clockOutFormatted,
    differenceInHours,
  };
};

export const getFriendlyClockInOutString = (clockInTime, clockOutTime) => {
  if (!clockInTime) {
    return '-';
  }

  const clockInFormatted = moment(clockInTime).format(TIME_FORMAT_24_HOUR);
  if (!clockOutTime) {
    return `${clockInFormatted}`;
  }

  const clockOutFormatted = moment(clockOutTime).format(TIME_FORMAT_24_HOUR);
  return `${clockInFormatted} - ${clockOutFormatted}`;
};

export const getBreakDuration = breakItem => {
  if (!breakItem || !breakItem.end_time) {
    return 0;
  }

  const startTime = moment(breakItem.start_time).startOf('minute');
  const endTime = moment(breakItem.end_time).startOf('minute');
  const ms = endTime.diff(startTime);

  return moment.duration(ms).asMinutes();
};

export const getTotalBreakDuration = breaks => {
  const breakDurations = breaks.map(breakItem => {
    return getBreakDuration(breakItem);
  });

  return breakDurations.reduce((a, b) => a + b, 0);
};

export const getTotalWorkDuration = timesheetEntry => {
  if (!timesheetEntry?.clock_out_time) return 0;

  const clockOutTime = moment(timesheetEntry?.rounded_clock_out_time || timesheetEntry?.clock_out_time).startOf(
    'minute',
  );
  const clockInTime = moment(timesheetEntry?.rounded_clock_in_time || timesheetEntry?.clock_in_time).startOf('minute');
  const workDurationInMinutes = moment.duration(clockOutTime.diff(clockInTime)).asMinutes();

  let totalBreakDurationInMinutes = 0;
  if (timesheetEntry.breaks?.length > 0) {
    const totalBreakDuration = getTotalBreakDuration(timesheetEntry.breaks);
    totalBreakDurationInMinutes = totalBreakDuration;
  }

  return workDurationInMinutes - totalBreakDurationInMinutes;
};

export default {
  getFriendlyClockInOutTimes,
};
