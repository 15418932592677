import React, { Component } from 'react';
import moment from 'moment-timezone';
import { EllipsisOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Card, Empty, List, Typography, Row, Col, Avatar, Dropdown, Menu } from 'antd';

import { getManagerId } from '../../../shared/access/Access';
import StatusTag from '../../../shared/components/StatusTag';
import { colors } from '../../../styles/colors';
import { roles, DEFAULT_DATE_FORMAT } from '../../../constants';
import MemberModal from './components/MemberModal';

import RemoveMemberModal from './components/RemoveMemberModal';

const { Title, Text } = Typography;

class TeamSection extends Component {
  state = {
    memberModalOpen: false,
    removeMemberModalOpen: false,
  };

  getMemberLocations = member => {
    const { client, t } = this.props;
    const locationOptions = client ? client.locations : [];
    const memberLocations = member ? member.locations : [];
    return locationOptions.map(({ id, name, address }) => (
      <Text key={id} value={id} style={{ fontSize: '12px' }}>
        {memberLocations.includes(id) && (
          <>
            <Text strong>{name}</Text>
            <Text> · {address.area ? address.area.name : t('Area not provided')}</Text>
            <br />
          </>
        )}
      </Text>
    ));
  };

  getAdminCount = (isAdmin, members) => {
    let adminCount = 0;
    if (isAdmin) {
      for (let i = 0; i < members.length; i += 1) {
        if (members[i].role === roles.ADMIN) {
          adminCount += 1;
        }
      }
    }
    return adminCount;
  };

  sortMembers = members => {
    if (!members || members.length === 0) {
      return;
    }
    // Sort alphabetically
    members.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      return -1;
    });
  };

  render() {
    const { client, fetchClientAndManager, isAdmin, t } = this.props;
    // Note: managers are referred to as members on FE
    const members = client ? client.managers : null;
    this.sortMembers(members);
    const adminCount = this.getAdminCount(isAdmin, members);
    const managerId = getManagerId();
    return (
      <>
        <Row style={{ marginTop: '14px', marginBottom: '16px' }}>
          <Col span={18}>
            <Title level={3}>{t('Team management')}</Title>
            <Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
              {/* Our current approach using the translations library doesn't support multiline strings */}
              {t(
                'Add team members to manage your business locations and staff requests. Admins have access to every feature and can assign any role to a member. Supervisors can view, post, and manage staff requests for their assigned business locations.',
              )}
            </Text>
          </Col>
        </Row>
        <Row style={{ marginBottom: '32px' }}>
          <Button
            disabled={!isAdmin}
            onClick={() => {
              this.setState({ memberModalOpen: true, member: null });
            }}
          >
            {t('Add member')}
          </Button>
        </Row>
        {members && members.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={<span>{t('Please add a member')}</span>} />
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={members || []}
            loading={!members}
            renderItem={member => (
              <Row
                type="flex"
                justify="space-between"
                style={{ paddingBottom: '24px', paddingTop: '16px', borderBottom: '1px solid #00000017' }}
              >
                <Col span={20}>
                  <Card bordered={false} style={{ width: '100%' }} bodyStyle={{ padding: 0 }}>
                    <Row type="flex" gutter={24}>
                      <Col>
                        <Avatar
                          shape="square"
                          size={72}
                          icon={<LegacyIcon type={member.role === roles.SUPERVISOR ? 'user' : 'laptop'} />}
                          style={{ color: '#BFBFBF', backgroundColor: '#F0F2F5' }}
                        />
                      </Col>
                      <Col>
                        <Row type="flex" style={{ marginBottom: '-4px' }}>
                          <Title strong level={4}>
                            {member.name}
                          </Title>
                        </Row>
                        <Row style={{ marginBottom: '8px', fontSize: '12px' }}>
                          <Text>
                            {member.email} · {member.phone}
                          </Text>
                        </Row>
                        <Row>
                          <StatusTag status={t(member.role)} />
                          <Text type="secondary" style={{ fontSize: '12px' }}>
                            {`${t('Added')} ${moment(member.created_date).format(DEFAULT_DATE_FORMAT)}`}
                          </Text>
                        </Row>
                        <Row style={{ fontSize: '12px' }}>
                          {member.role === roles.SUPERVISOR && (
                            <>
                              <Row style={{ marginTop: '16px' }}>
                                <Text strong type="secondary" style={{ fontSize: '12px' }}>
                                  {t('LOCATIONS')}
                                </Text>
                              </Row>
                              <Row>{this.getMemberLocations(member)}</Row>
                            </>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col>
                  <Dropdown
                    trigger={['click']}
                    overlay={
                      <Menu style={{ width: '120px' }}>
                        {/* TODO fix align to the right */}
                        <Menu.Item disabled={!isAdmin} onClick={() => this.setState({ memberModalOpen: true, member })}>
                          {t('Edit')}
                        </Menu.Item>
                        {member.id !== managerId && (
                          <Menu.Item
                            disabled={!isAdmin}
                            style={{ ...(isAdmin && { color: colors.redAccent }) }}
                            onClick={() => this.setState({ removeMemberModalOpen: true, member })}
                          >
                            {t('remove')}
                          </Menu.Item>
                        )}
                      </Menu>
                    }
                  >
                    <Button icon={<EllipsisOutlined />} />
                  </Dropdown>
                </Col>
              </Row>
            )}
          />
        )}
        {this.state.memberModalOpen && (
          <MemberModal
            client={client}
            onUpdate={fetchClientAndManager}
            adminCount={adminCount}
            member={this.state.member}
            closeModal={() => this.setState({ memberModalOpen: false })}
            t={t}
          />
        )}
        {this.state.removeMemberModalOpen && (
          <RemoveMemberModal
            member={this.state.member}
            onUpdate={fetchClientAndManager}
            closeModal={() => this.setState({ removeMemberModalOpen: false })}
            t={t}
          />
        )}
      </>
    );
  }
}

export default TeamSection;
