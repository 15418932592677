import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Typography, Button, Select, Divider } from 'antd';
import { withTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';

import { employmentCancellationReasons } from '../../../constants';
import { colors } from '../../../styles/colors';

const { Title, Paragraph } = Typography;
const { Item } = Form;
const { Option } = Select;

const MAX_NOTES_LENGTH = 100;

const EndEmploymentModal = ({
  t,
  confirmLoading,
  visible,
  closeModal,
  workerCount,
  onConfirm,
  hasDirectEmployment,
  selectedSingleWorker,
  form,
}) => {
  const cancellationReasons = hasDirectEmployment
    ? employmentCancellationReasons.filter(reason => reason.value !== 'staff_request_reassignment')
    : employmentCancellationReasons;

  let endEmploymentMessage = '';
  if (workerCount) {
    endEmploymentMessage = <Paragraph>{t('endEmploymentMessage', { workerCount })}</Paragraph>;
  } else if (selectedSingleWorker) {
    const workerName = `${selectedSingleWorker.partner.first_name} ${selectedSingleWorker.partner.last_name}`;
    endEmploymentMessage = <Paragraph>{t('endSingleEmploymentMessage', { workerName })}</Paragraph>;
  }

  return (
    <Modal
      className="v2-end-employment-modal"
      visible={visible}
      onCancel={() => {
        closeModal();
      }}
      title={<Title level={4}>{t('endEmploymentTitle')}</Title>}
      confirmLoading={confirmLoading}
      bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
      footer={[
        <Button key="close" onClick={closeModal}>
          {t('Close')}
        </Button>,
        <Button
          key="submit"
          loading={confirmLoading}
          onClick={() => {
            form.validateFieldsAndScroll((errors, values) => {
              if (!errors) {
                onConfirm(values);
              }
            });
          }}
          style={{ background: colors.functionalError, color: colors.white }}
        >
          {t('endEmploymentTitle')}
        </Button>,
      ]}
    >
      <Form hideRequiredMark colon={false} className="v2-form-wrapper">
        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 24, paddingLeft: 24 }}>
          <Typography>
            {endEmploymentMessage}
            <Paragraph>{t('endEmploymentReason')}</Paragraph>
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 24, paddingLeft: 24 }}>
          <Item label={t('endEmploymentReasonLabel')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
            {form.getFieldDecorator('reason', {
              rules: [{ required: true, message: t('fieldRequired.') }],
            })(
              <Select
                placeholder={t('endEmploymentReasonPlaceholder')}
                style={{ width: '100%' }}
                dropdownMatchSelectWidth
              >
                {cancellationReasons.map(reason => (
                  <Option key={reason.value} value={reason.value}>
                    <Typography.Text>{t(`${reason.title}`)}</Typography.Text>
                    <br />
                    <Typography.Text type="secondary" style={{ whiteSpace: 'normal' }}>
                      {t(`${reason.description}`)}
                    </Typography.Text>
                  </Option>
                ))}
              </Select>,
            )}
          </Item>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 24, paddingLeft: 24 }}>
          <Item label={t('endEmploymentNotes')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
            {form.getFieldDecorator('notes', {
              rules: [
                {
                  max: MAX_NOTES_LENGTH,
                  message: t('notesMaximumCharacterError', { maxCount: MAX_NOTES_LENGTH }),
                },
              ],
            })(<TextArea placeholder={t('endEmploymentDetailsPlaceholder')} maxLength={100} />)}
          </Item>
        </div>
        <Divider />
        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 24, paddingLeft: 24 }}>
          <Typography.Text strong>{t('Disclaimer')}</Typography.Text>
          <Typography.Paragraph>{t('endEmploymentDisclaimer')}</Typography.Paragraph>
        </div>
      </Form>
    </Modal>
  );
};

export default Form.create()(withTranslation()(EndEmploymentModal));
