import React from 'react';
import { ExclamationCircleOutlined, HourglassFilled, LineOutlined } from '@ant-design/icons';
import { Badge, Typography, Tooltip } from 'antd';
import { isEqual, camelCase } from 'lodash';
import moment from 'moment';
import { colors } from '../../../styles/colors';
import datetimeUtils from '../../../utilities/datetimeUtils';
import attendanceUtils from '../../../utilities/attendanceUtils';
import { derivedWorkingStatus, attendanceAbsenceReasons } from '../../../constants';

const { Paragraph, Text } = Typography;

export const getPastBackgroundColor = assignment => {
  const { attendance } = assignment;

  if (attendanceUtils.isAssigned(attendance) && !attendanceUtils.isAbsent(attendance)) {
    if (!attendanceUtils.hasNonVoidTimesheetEntry(attendance?.timesheet_entries)) {
      return colors.tagRed;
    }
    return colors.white;
  }
  return colors.backgroundGrey;
};

export const getPastLabelColor = assignment => {
  const { attendance } = assignment;

  if (
    attendanceUtils.isAssigned(attendance) &&
    !attendanceUtils.hasNonVoidTimesheetEntry(attendance?.timesheet_entries) &&
    !attendanceUtils.isAbsent(attendance)
  ) {
    return 'inherit';
  }
  return colors.grey;
};

export const getFutureBackgroundColor = (assignment, updated) => {
  const { contractStatus } = assignment;

  if (!attendanceUtils.isValidWorkingDay(contractStatus)) {
    return colors.backgroundGrey;
  }

  if (updated) {
    // Unpublished
    return colors.infoBackgroundBlue;
  }
  return colors.white;
};

export const getFutureLabelColor = assignment => {
  const { attendance, contractStatus } = assignment;

  if (!attendanceUtils.isAssigned(attendance) || !attendanceUtils.isValidWorkingDay(contractStatus)) return colors.grey;
  return 'inherit';
};

export const getCellAttributes = (assignment, timezone) => {
  const { attendance, originalAttendance, date } = assignment;
  const updated = !isEqual(attendance, originalAttendance);
  const dayStart = datetimeUtils.getDayStart(moment(), timezone);
  const isFutureAssignment = moment(date).isSameOrAfter(dayStart);

  let backgroundColor;
  let labelColor;
  if (isFutureAssignment) {
    backgroundColor = getFutureBackgroundColor(assignment, updated);
    labelColor = getFutureLabelColor(assignment);
  } else {
    backgroundColor = colors.backgroundGrey;
    labelColor = getPastLabelColor(assignment);
  }

  return {
    backgroundColor,
    labelColor,
  };
};

// TODO: Handle contract/no contracts
export const getCellContent = (assignment, timezone, t, isMonthlyView) => {
  const {
    schedule,
    contractStatus,
    date,
    attendance,
    originalAttendance,
    position,
    attendanceOutsideSchedule,
    shifts,
  } = assignment;
  const dayStart = datetimeUtils.getDayStart(moment(), timezone);
  const isFutureAssignment = moment(date).isSameOrAfter(dayStart);
  const confirmed = attendance?.confirmed;
  const updated = !isEqual(attendance, originalAttendance);

  if (isFutureAssignment && contractStatus === derivedWorkingStatus.CONTRACT_NOT_SIGNED) {
    return t('noContract');
  }
  if (isFutureAssignment && contractStatus === derivedWorkingStatus.ON_BREAK) {
    return t('PKHL Break');
  }

  if (attendanceUtils.isAbsent(attendance)) {
    return <ExclamationCircleOutlined style={{ color: colors.functionalWarning, fontSize: '20px' }} />;
  }

  if (
    !isFutureAssignment &&
    attendanceUtils.isAssigned(attendance) &&
    !attendanceUtils.hasNonVoidTimesheetEntry(attendance?.timesheet_entries)
  ) {
    return <ExclamationCircleOutlined style={{ color: colors.redAccent, fontSize: '20px' }} />;
  }

  if (schedule) {
    const displayText =
      shifts[0].shift_roles.length > 0 && attendance.role_id
        ? shifts[0].shift_roles.find(shiftRole => shiftRole.role.id === attendance.role_id).role.name
        : position;
    if (updated) {
      return (
        <div style={{ display: 'flex', width: '100px' }}>
          <Paragraph
            ellipsis
            style={{
              fontSize: isMonthlyView ? 12 : 14,
              color: colors.black,
              margin: isMonthlyView ? '0px 0px 0px 4px' : '0px 0px 0px 8px',
            }}
          >
            {displayText}
          </Paragraph>
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', width: '100px' }}>
        <Paragraph
          ellipsis
          style={{
            fontSize: isMonthlyView ? 12 : 14,
            color: colors.black,
            margin: isMonthlyView ? 4 : '0px 0px 0px 8px',
            padding: isMonthlyView ? 8 : 0,
          }}
        >
          {confirmed ? (
            <Badge
              status={isFutureAssignment ? 'success' : 'default'}
              style={{ marginLeft: isMonthlyView && 4, marginRight: isMonthlyView && 0 }}
            />
          ) : (
            <HourglassFilled
              style={{
                color: isFutureAssignment ? colors.functionalWarning : colors.grey,
                marginRight: isMonthlyView ? 4 : 8,
              }}
            />
          )}
          {displayText}
        </Paragraph>
      </div>
    );
  }

  if (attendanceOutsideSchedule) {
    return (
      <Tooltip title={attendanceOutsideSchedule.schedule.name}>
        <Text style={{ fontSize: '12px', color: colors.grey }}>{t('otherShift')}</Text>
      </Tooltip>
    );
  }

  return <LineOutlined style={{ color: colors.grey, fontSize: '20px' }} />;
};

// TODO: Add as an attribute to get from getCellAttributes and write test with t function mock
export const getToolTipTitle = (assignment, timezone, t) => {
  const { attendance, contractStatus, date } = assignment;
  const dayStart = datetimeUtils.getDayStart(moment(), timezone);
  const isFutureAssignment = moment(date).isSameOrAfter(dayStart);

  if (isFutureAssignment) {
    if (attendanceUtils.isAbsent(attendance)) {
      if (Object.values(attendanceAbsenceReasons).includes(attendance.absence_reason)) {
        return t(camelCase(attendance.absence_reason)); // return translated version if key exists
      }
      return attendance.absence_reason; // else return legacy free text version
    }
    if (contractStatus === derivedWorkingStatus.CONTRACT_NOT_SIGNED) {
      return t('workerNotSignedContract');
    }
    if (contractStatus === derivedWorkingStatus.ON_BREAK) {
      return t('cannotAssignOnPkhl');
    }
  } else {
    if (attendanceUtils.isAbsent(attendance)) {
      if (Object.values(attendanceAbsenceReasons).includes(attendance.absence_reason)) {
        return t(camelCase(attendance.absence_reason)); // return translated version if key exists
      }
      return attendance.absence_reason; // else return legacy free text version
    }

    if (
      attendanceUtils.isAssigned(attendance) &&
      !attendanceUtils.hasNonVoidTimesheetEntry(attendance?.timesheet_entries)
    ) {
      return t('workerNoShow');
    }
  }
};
