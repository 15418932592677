import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { isEmpty, startCase } from 'lodash';
import { Table, Typography, Avatar, Row, Tooltip, Tag } from 'antd';
import { QuestionCircleOutlined, UserOutlined, SendOutlined } from '@ant-design/icons';
import textUtils from '../../../utilities/textUtils';
import routes from '../../../routes';
import { colors } from '../../../styles/colors';
import { DATE_FORMAT, countryCodeOptions, staffRequestContractStatus } from '../../../constants';
import contractUtils from '../../../utilities/contractUtils';

const { Text } = Typography;
const EXPIRY_THRESHOLD = 7;
const CONTRACT_TYPES = {
  PKHL: 'PKHL',
  OTHERS: 'Others',
};

const ContractsTable = ({
  t,
  employments,
  selectedEmployments,
  loading,
  page,
  defaultPageSize,
  currentEmploymentsCount,
  onTableChange,
  onEmploymentsSelectedChange,
  testId,
}) => {
  const getContractDetails = contracts => {
    const activeContract = contractUtils.getCurrentActiveContract(contracts);
    const unsignedContracts = contractUtils.getUnsignedContracts(contracts);
    const unsignedContract = unsignedContracts[0]; // earliest unsigned contract
    const expiredContract = contractUtils.getLatestExpiredContract(contracts);
    const renewedSignedContract = contractUtils.getNextSignedContract(contracts);

    let statusTag;
    let start_date;
    let end_date;
    let country;
    let contractRenewal = {
      sentDate: renewedSignedContract ? renewedSignedContract?.created_date : unsignedContract?.created_date,
      startDate: renewedSignedContract ? renewedSignedContract?.start_date : unsignedContract?.start_date,
    };

    if (activeContract) {
      statusTag = (
        <Tooltip title={t('signedOn', { date: moment(activeContract.signed_date).format(DATE_FORMAT) })}>
          <Tag color="green">{t('signed')}</Tag>
        </Tooltip>
      );
      ({ start_date, end_date, country } = activeContract);
    } else if (unsignedContract) {
      statusTag = (
        <Tooltip title={t('sentOn', { date: moment(unsignedContract.created_date).format(DATE_FORMAT) })}>
          <Tag>{t('notSigned')}</Tag>
        </Tooltip>
      );
      ({ start_date, end_date, country } = unsignedContract);
      contractRenewal = {
        sentDate: unsignedContracts[1]?.created_date, // get next unsigned contract
        startDate: unsignedContract[1]?.start_date,
      };
    } else if (expiredContract) {
      statusTag = (
        <Tooltip title={t('expiredOn', { date: moment(expiredContract.end_date).format(DATE_FORMAT) })}>
          <Tag color="red">{t('expired')}</Tag>
        </Tooltip>
      );
      ({ start_date, end_date, country } = expiredContract);
    } else if (contracts) {
      const nextContract = contractUtils.getNextContract(contracts);
      if (nextContract) {
        const isSignedNextContract = nextContract?.status === staffRequestContractStatus.SIGNED;
        statusTag = (
          <Tooltip
            title={
              isSignedNextContract
                ? t('signedOn', { date: moment(nextContract.signed_date).format(DATE_FORMAT) })
                : t('sentOn', { date: moment(unsignedContract.created_date).format(DATE_FORMAT) })
            }
          >
            <Tag color={isSignedNextContract ? 'green' : null}>
              {isSignedNextContract ? t('signed') : t('notSigned')}
            </Tag>
          </Tooltip>
        );
        ({ start_date, end_date, country } = nextContract);
      }
    }

    return {
      statusTag,
      startDate: start_date,
      endDate: end_date,
      contractRenewal,
      country,
    };
  };

  const tableColumns = [
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('name')}
        </Text>
      ),
      width: 260,
      dataIndex: 'worker',
      sorter: true,
      key: 'partner__first_name', // for django sorting params
      render: worker => (
        <div style={{ display: 'flex', flexDirection: 'row', width: 244, alignItems: 'center' }}>
          <Avatar shape="square" size={36} src={worker.imgSrc} icon={<UserOutlined />} />
          <Text ellipsis style={{ width: 208 }}>
            <a
              href={`${routes.partners}/${worker.id}`}
              style={{ fontWeight: 600, color: colors.black, paddingLeft: '8px' }}
              target="_blank"
              rel="noopener noreferrer"
              className="underline-on-hover"
            >
              {textUtils.toTitleCase(worker.name)}
            </a>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('contractStatus')}
        </Text>
      ),
      width: 160,
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('startDate')}
        </Text>
      ),
      width: 160,
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('endDate')}
        </Text>
      ),
      width: 160,
      dataIndex: 'endDate',
      key: 'endDate',
      render: endDate => {
        const daysTillExpiry = moment(endDate).diff(moment(), 'days');
        const isExpiring = daysTillExpiry < EXPIRY_THRESHOLD && daysTillExpiry > 0;
        return (
          endDate && (
            <Row>
              {moment(endDate).format(DATE_FORMAT)}
              {isExpiring && (
                <Tooltip title={t('expiringIn', { numDays: daysTillExpiry })}>
                  <QuestionCircleOutlined style={{ marginLeft: '10px', color: colors.functionalWarning }} />
                </Tooltip>
              )}
            </Row>
          )
        );
      },
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('contractType')}
        </Text>
      ),
      width: 160,
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('newContractStartDate')}
        </Text>
      ),
      dataIndex: 'contractRenewal',
      key: 'contractRenewal',
      render: contractRenewal => {
        return (
          contractRenewal && (
            <Row>
              {contractRenewal.startDate && moment(contractRenewal.startDate).format(DATE_FORMAT)}
              {contractRenewal.sentDate && (
                <Tooltip title={t('sentOn', { date: moment(contractRenewal.sentDate).format(DATE_FORMAT) })}>
                  <SendOutlined style={{ marginLeft: '10px', color: colors.green }} />
                </Tooltip>
              )}
            </Row>
          )
        );
      },
    },
  ];

  let tableRows = [];
  employments.forEach(employment => {
    const { partner, contracts, contract_required } = employment;

    if (contract_required) {
      const { statusTag, startDate, endDate, contractRenewal, country } = getContractDetails(contracts);
      const countryCode = isEmpty(country) ? partner.address.country.code : country;
      const type =
        countryCode === countryCodeOptions.INDONESIA ? startCase(employment?.contract_type) : CONTRACT_TYPES.OTHERS;
      const row = {
        key: employment.id,
        worker: {
          id: partner.id,
          name: `${partner.first_name} ${partner.last_name}`,
          imgSrc: partner.image,
        },
        status: statusTag,
        startDate: startDate && moment(startDate).format(DATE_FORMAT),
        type,
        endDate,
        contractRenewal,
      };

      tableRows = [...tableRows, row];
    }
  });

  return (
    <Table
      indentSize={0}
      loading={loading}
      columns={tableColumns}
      dataSource={tableRows}
      rowKey="key"
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys: selectedEmployments,
        onChange: selectedRowKeys => onEmploymentsSelectedChange(selectedRowKeys),
      }}
      pagination={{
        defaultPageSize,
        current: page,
        showSizeChanger: true,
        pageSizeOptions: ['20', '50', '100'],
        total: currentEmploymentsCount,
        showTotal: (total, range) => `${range[0]}-${range[1]} ${t('of')} ${total} ${t('items')}`,
        style: { margin: '24px 0px' },
      }}
      onChange={onTableChange}
      data-testid={testId}
      scroll={{ x: 'max-content' }}
      style={{ width: '100%' }}
      size="middle"
    />
  );
};

export default withTranslation()(ContractsTable);
