import React from 'react';
import { Modal, message, Typography } from 'antd';
import { Trans, withTranslation } from 'react-i18next';

import managersService from '../../../../services/managersApi';
import { httpResponseStatuses } from '../../../../constants';

class RemoveMemberModal extends React.Component {
  handleSubmit = async () => {
    const { member, t } = this.props;

    try {
      if (member) {
        await managersService.deleteManager(member);
        message.success(t('teamMemberRemoved'));
      }
      this.props.onUpdate();
      this.handleClose();
    } catch (error) {
      if (error.response.status === httpResponseStatuses.FORBIDDEN) {
        message.error(t('noPermissionToRemoveTeamMember'));
      } else {
        message.error(error.message);
      }
    }
  };

  handleClose = () => {
    this.props.closeModal();
  };

  render() {
    const { member, t } = this.props;
    return (
      <Modal
        visible
        title={t('removeTeamMember')}
        okText={t('confirmRemove')}
        okType="danger"
        onOk={this.handleSubmit}
        onCancel={this.handleClose}
      >
        <Typography.Text>
          <Trans i18nKey="removeTeamMemberMessage" values={{ name: member.name }} />
        </Typography.Text>
      </Modal>
    );
  }
}

export default withTranslation()(RemoveMemberModal);
