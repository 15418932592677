import React from 'react';
import { Row, Typography, Card, Avatar, List, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { colors } from '../../../styles/colors';

const { Text, Title } = Typography;

const SkillTestCard = ({ skillQuizScores, t }) => {
  return (
    <Card bodyStyle={{ padding: 0 }} bordered={false}>
      <Row type="flex" align="middle" style={{ marginBottom: '8px' }}>
        <Title
          style={{
            fontSize: '20px',
            lineHeight: '28px',
            fontWeight: 600,
            color: colors.textTitle,
            marginBottom: 0,
            marginRight: 8,
          }}
        >
          {t('skillTestScores')}
        </Title>
        <Tooltip title={t('skillQuizScoresTooltip')}>
          <QuestionCircleOutlined style={{ color: colors.disabled, fontSize: '14px' }} />
        </Tooltip>
      </Row>
      <Row>
        <List
          dataSource={skillQuizScores}
          renderItem={item => (
            <List.Item
              key={item.position}
              className="no-border application-modal"
              style={{ marginBottom: 0, width: 250 }}
            >
              <List.Item.Meta
                avatar={<Avatar size={50} src={item.iconSrc} />}
                title={
                  <Text strong type="secondary" style={{ fontSize: '12px' }}>
                    {item.position}
                  </Text>
                }
                description={
                  <Title strong style={{ fontSize: '20px', color: colors.textTitle, marginBottom: 0 }}>
                    {item.score}
                  </Title>
                }
              />
            </List.Item>
          )}
        />
      </Row>
    </Card>
  );
};

export default withTranslation()(SkillTestCard);
