import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Modal, Row, Typography, Card, Input, Select, Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import { colors } from '../../../styles/colors';
import {
  choiceTypesWithKeys,
  createQuestionMoreActionTypes,
  MAX_QUESTION_DESCRIPTION_LENGTH,
} from '../../../constants';
import freeTextIcon from '../../../assets/images/free-text.svg';
import singleSelectIcon from '../../../assets/images/radiobox-marked.svg';
import multiSelectIcon from '../../../assets/images/checkbox-marked.svg';
import questionsApi from '../../../services/questionsApi';
import questionsUtils from '../../../utilities/questionsUtils';
import CreateQuestionMoreActions from './CreateQuestionMoreActions';
import CreateQuestionManageOptions from './CreateQuestionManageOptions';
import QuestionImageUpload from './QuestionImageUpload';

const { Item } = Form;
const { TextArea } = Input;
const { Text } = Typography;

const CreateQuestionModal = ({ clientId, visible, onCancel, onCreateSuccess }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [hasDescription, setHasDescription] = useState(false);
  const [hasScore, setHasScore] = useState(false);
  const [hasOther, setHasOther] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const imageFileUploadRef = useRef();

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const initialValues = {
    title: '',
    type: choiceTypesWithKeys.FREE_TEXT.key,
    description: '',
  };

  const onCreateQuestion = async payload => {
    try {
      setIsAdding(true);
      const result = await questionsApi.createQuestion(payload);
      setIsAdding(false);
      onCreateSuccess(result);
      onCancel();
    } catch (error) {
      message.error(t('commonErrorMessage'));
      Sentry.captureException(error);
      setIsAdding(false);
    }
  };

  const onFinish = values => {
    const payload = questionsUtils.getCreateQuestionPayload({ ...values, hasOther, hasScore, clientId, imageFile });
    onCreateQuestion(payload);
  };

  const clickImageFileUpload = () => {
    if (imageFileUploadRef.current) {
      imageFileUploadRef.current.click();
    }
  };

  const resetImage = () => {
    setImageFile(null);
    setImagePreview(null);
  };

  const handleSelectImageDone = (file, url) => {
    setImagePreview(url);
    setImageFile(file);
  };

  const handleMoreActionsChange = ({ actionType }) => {
    switch (actionType) {
      case createQuestionMoreActionTypes.DESCRIPTION:
        setHasDescription(!hasDescription);
        break;
      case createQuestionMoreActionTypes.SCORE_QUESTION:
        setHasScore(!hasScore);
        break;
      case createQuestionMoreActionTypes.ATTACH_IMAGE:
        if (imageFile) {
          resetImage();
        } else {
          clickImageFileUpload();
        }
        break;
      default:
        return null;
    }
  };

  const setInitialOptions = () => {
    const options = questionsUtils.generateInitialOptions();
    form.setFieldsValue({
      choices: options,
    });
  };

  const handleSetOptions = () => {
    const currentOptions = form.getFieldValue('choices');
    if (!currentOptions) {
      setInitialOptions();
    }
  };

  const handleQuestionTypeChange = value => {
    switch (value) {
      case choiceTypesWithKeys.MULTI_SELECT.key:
        handleSetOptions();
        break;
      case choiceTypesWithKeys.SINGLE_SELECT.key:
        handleSetOptions();
        break;
      default:
        return null;
    }
  };

  // Render question type image based on choice type
  const renderChoiceTypeIcon = choiceType => {
    const alt = `${choiceType.title} icon`;
    let imgSrc = '';
    switch (choiceType.key) {
      case choiceTypesWithKeys.FREE_TEXT.key:
        imgSrc = freeTextIcon;
        break;
      case choiceTypesWithKeys.SINGLE_SELECT.key:
        imgSrc = singleSelectIcon;
        break;
      case choiceTypesWithKeys.MULTI_SELECT.key:
        imgSrc = multiSelectIcon;
        break;
      default:
        imgSrc = freeTextIcon;
    }
    return <img src={imgSrc} style={{ width: 16, height: 16, marginRight: 10 }} alt={alt} />;
  };

  const setSingleSelectCorrectAnswer = index => {
    const choices = form.getFieldValue('choices');
    const scoredChoices = choices.filter(choice => choice?.score);
    if (scoredChoices.length > 1) {
      // set correct answer to the current selected choice
      // remove checked from previous selected choice
      const updatedChoices = choices.map((choice, choceIndex) =>
        choceIndex === index ? { ...choice, score: 1 } : { ...choice, score: 0 },
      );
      form.setFieldsValue({ choices: updatedChoices });
    }
  };

  const handleCheckOptionChange = index => {
    const choiceType = form.getFieldValue('type');
    if (choiceType === choiceTypesWithKeys.SINGLE_SELECT.key) {
      setSingleSelectCorrectAnswer(index);
    }
  };

  const selectedMoreActionTypes = [
    hasDescription ? createQuestionMoreActionTypes.DESCRIPTION : '',
    hasScore ? createQuestionMoreActionTypes.SCORE_QUESTION : '',
    imageFile ? createQuestionMoreActionTypes.ATTACH_IMAGE : '',
  ];

  const textAreaAutoSize = {
    maxRows: 4,
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={<Typography.Text style={{ fontWeight: 600 }}>{t('createQuestionModalTitle')}</Typography.Text>}
      width="800px"
      closable
      footer={
        <Row style={{ justifyContent: 'space-between' }}>
          <Button onClick={onCancel}>{t('cancel')}</Button>
          <Button
            key="submit"
            type="primary"
            style={{
              borderColor: colors.functionalSuccess,
              backgroundColor: colors.functionalSuccess,
              color: colors.white,
            }}
            loading={isAdding}
            disabled={isAdding}
            onClick={() => form.submit()}
          >
            {t('addToForm')}
          </Button>
        </Row>
      }
    >
      <Card>
        <Form hideRequiredMark layout="vertical" onFinish={onFinish} form={form} initialValues={initialValues}>
          <Row gutter={16}>
            <Col xs={15}>
              {/* Title Input */}
              <Item
                name="title"
                rules={[
                  { required: true, message: t('questionTitleRequiredError') },
                  { whitespace: true, message: t('questionTitleRequiredError') },
                ]}
                style={{ marginBottom: 0, paddingBottom: 0 }}
              >
                <TextArea
                  placeholder={t('questionCreationTitle')}
                  autoSize={textAreaAutoSize}
                  maxLength={MAX_QUESTION_DESCRIPTION_LENGTH}
                  style={{ fontWeight: '600' }}
                />
              </Item>
            </Col>
            <Col xs={7}>
              {/* Question Type Input */}
              <Item name="type" style={{ marginBottom: 0, paddingBottom: 0 }}>
                <Select onChange={handleQuestionTypeChange}>
                  {Object.keys(choiceTypesWithKeys).map((key, index) => {
                    const choiceType = choiceTypesWithKeys[key];
                    return (
                      <Select.Option key={index} value={choiceType.key} style={{ display: 'flex' }}>
                        {renderChoiceTypeIcon(choiceType)}
                        <Typography.Text>{t(choiceType.transKey)}</Typography.Text>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Item>
            </Col>
            <Col xs={2}>
              {/* More Menu Actions */}
              <Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}>
                {({ getFieldValue }) => {
                  const choiceType = getFieldValue('type');
                  return (
                    <CreateQuestionMoreActions
                      selectedMoreActionTypes={selectedMoreActionTypes}
                      onChange={handleMoreActionsChange}
                      choiceType={choiceType}
                    />
                  );
                }}
              </Item>
            </Col>
          </Row>
          <Row>
            {/* Description Input */}
            {hasDescription && (
              <Item name="description" style={{ marginTop: 16, marginBottom: 0, paddingBottom: 0, width: '100%' }}>
                <TextArea
                  placeholder={t('questionCreationDescription')}
                  autoSize={textAreaAutoSize}
                  maxLength={MAX_QUESTION_DESCRIPTION_LENGTH}
                />
              </Item>
            )}
          </Row>

          {/* Image upload  */}
          <QuestionImageUpload
            ref={imageFileUploadRef}
            imagePreview={imagePreview}
            onChangeImage={clickImageFileUpload}
            onSelectImageDone={handleSelectImageDone}
            onRemoveImage={resetImage}
          />

          <Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}>
            {({ getFieldValue }) => {
              const choiceType = getFieldValue('type');
              return (
                <div style={{ marginTop: 30, flexDirection: 'column' }}>
                  {/* Free text display */}
                  {choiceType === choiceTypesWithKeys.FREE_TEXT.key && (
                    <Row style={{ borderBottom: `1px dashed ${colors.disabled}`, width: 190 }}>
                      <Text style={{ color: colors.secondaryText }}>{t('freeTextExample')}</Text>
                    </Row>
                  )}
                  {/* Single Select / Multi Select Options */}
                  {(choiceType === choiceTypesWithKeys.SINGLE_SELECT.key ||
                    choiceType === choiceTypesWithKeys.MULTI_SELECT.key) && (
                    <CreateQuestionManageOptions
                      form={form}
                      choiceType={choiceType}
                      hasOther={hasOther}
                      setHasOther={setHasOther}
                      hasScore={hasScore}
                      setHasScore={setHasScore}
                      onCheckOptionChange={handleCheckOptionChange}
                    />
                  )}
                </div>
              );
            }}
          </Item>
        </Form>
      </Card>
    </Modal>
  );
};

const mapStateToProps = state => ({
  clientId: state.user.clientId,
});

export default connect(mapStateToProps)(CreateQuestionModal);
