import { endpoints } from '../config';
import base from './base';

const createClient = async client => {
  const response = await base.api.post(`${endpoints.clients}/`, client);
  return response;
};

const fetchClient = async (params = {}) => {
  const response = await base.api.get(`${endpoints.clients}/`, { params });
  return response;
};

const createLocation = async (clientId, location) => {
  const response = await base.api.post(`${endpoints.clients}/${clientId}/locations/`, location);
  return response;
};

const editLocation = async (clientId, location) => {
  const response = await base.api.put(`${endpoints.clients}/${clientId}/locations/${location.id}/`, location);
  return response;
};

const editClient = async client => {
  const response = await base.api.patch(`${endpoints.clients}/${client.id}/`, client, {});
  return response;
};

const fetchRoles = async (clientId, params) => {
  const response = await base.api.get(`${endpoints.clients}/${clientId}/roles/`, { params });
  return response;
};

const createRole = async (clientId, role) => {
  const response = await base.api.post(`${endpoints.clients}/${clientId}/roles/`, role);
  return response;
};

const editRole = async (clientId, role) => {
  const response = await base.api.patch(`${endpoints.clients}/${clientId}/roles/${role.id}/`, role);
  return response;
};

const validateRoleName = async (clientId, params) => {
  const response = await base.api.post(`${endpoints.clients}/${clientId}/roles/validate-name/`, params);
  return response;
};

const uploadLogo = async (clientId, logo) => {
  const formData = new FormData();
  formData.append('logo', logo);
  const response = await base.api.put(`${endpoints.clients}/${clientId}/upload-logo/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response;
};

// Payments service functions
const createPaymentCustomer = async ({
  clientId,
  cardToken,
  lastDigits,
  cardHolderName,
  cardBrand,
  expMonth,
  expYear,
}) => {
  const response = await base.api.post(`${endpoints.clients}/${clientId}/create-payment-customer/`, {
    card_token: cardToken,
    card_last_digits: lastDigits,
    card_exp_month: expMonth,
    card_exp_year: expYear,
    card_holder_name: cardHolderName,
    card_brand: cardBrand,
  });
  return response;
};

const createOTPCharge = async (clientId, staffRequestId) => {
  const response = await base.api.post(`${endpoints.clients}/${clientId}/create-otp-charge/`, {
    staff_request_id: staffRequestId,
  });
  return response;
};

const getCharge = async (clientId, chargeId) => {
  const response = await base.api.get(`${endpoints.clients}/${clientId}/get-charge/?charge_id=${chargeId}`);
  return response;
};

// EDIT card details is punted
const updatePaymentCustomerCard = async (clientId, cardToken) => {
  const response = await base.api.patch(`${endpoints.clients}/${clientId}/update-payment-customer-card/`, {
    card_token: cardToken,
  });
  return response;
};

const getPaymentCustomer = async clientId => {
  try {
    const response = await base.api.get(`${endpoints.clients}/${clientId}/get-payment-customer/`);
    return response;
  } catch (error) {
    return undefined;
  }
};

const deletePaymentCustomer = async clientId => {
  const response = await base.api.post(`${endpoints.clients}/${clientId}/destroy-payment-customer/`);
  return response;
};

const createSignatureRequest = async (clientId, testMode = false) => {
  const response = await base.api.post(`${endpoints.clients}/${clientId}/create-signature-request/`, {
    test_mode: testMode,
  });
  return response;
};

const getSignatureRequestStatus = async clientId => {
  const response = await base.api.get(`${endpoints.clients}/${clientId}/get-signature-request-status/`);
  return response;
};

const getSignatureEmbeddedUrl = async clientId => {
  const response = await base.api.get(`${endpoints.clients}/${clientId}/get-signature-request-embedded-url/`);
  return response;
};

const approve = async clientId => {
  const response = await base.api.post(`${endpoints.clients}/${clientId}/approve/`);
  return response;
};

const fetchTimesheetTagCategories = async clientId => {
  const response = await base.api.get(`${endpoints.clients}/${clientId}/timesheet-tag-categories/`);
  return response;
};

const validateTimesheetTagCategoryName = async (clientId, params) => {
  const response = await base.api.post(
    `${endpoints.clients}/${clientId}/timesheet-tag-categories/validate-name/`,
    params,
  );
  return response;
};

const createTimesheetTagCategory = async (clientId, params) => {
  const response = await base.api.post(`${endpoints.clients}/${clientId}/timesheet-tag-categories/`, params);
  return response;
};

const editTimesheetTagCategory = async (clientId, category) => {
  const response = await base.api.patch(
    `${endpoints.clients}/${clientId}/timesheet-tag-categories/${category.id}/`,
    category,
  );
  return response;
};

const fetchVipWorkers = async (clientId, positionId) => {
  const response = await base.api.get(`${endpoints.clients}/${clientId}/vip-workers/?position=${positionId}`);
  return response;
};

const validateReferralCode = async referralCode => {
  const response = await base.api.get(`${endpoints.clients}/referral-codes/validate/`, {
    params: { referral_code: referralCode },
  });
  return response;
};

const updateTimeRoundingRules = async (clientId, params) => {
  const response = await base.api.post(`${endpoints.clients}/${clientId}/update-rounding-rules/`, params);
  return response;
};

const fetchLeaveEntryTypes = async clientId => {
  const response = await base.api.get(`${endpoints.clients}/${clientId}/leave-entry-types/`);
  return response;
};

export default {
  createClient,
  fetchClient,
  editClient,
  uploadLogo,
  createLocation,
  editLocation,
  fetchRoles,
  createRole,
  editRole,
  validateRoleName,
  approve,
  updateTimeRoundingRules,
  // Payments
  createPaymentCustomer,
  getPaymentCustomer,
  deletePaymentCustomer,
  updatePaymentCustomerCard,
  createOTPCharge,
  getCharge,
  // Contracts
  createSignatureRequest,
  getSignatureRequestStatus,
  getSignatureEmbeddedUrl,
  // Timesheet Tags
  fetchTimesheetTagCategories,
  validateTimesheetTagCategoryName,
  createTimesheetTagCategory,
  editTimesheetTagCategory,
  // vipWorkers
  fetchVipWorkers,
  // referralCodes
  validateReferralCode,
  // Leave entry
  fetchLeaveEntryTypes,
};
