import React, { Component } from 'react';
import moment from 'moment-timezone';
import { SettingOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Table, Typography, Row, Col, Tooltip } from 'antd';

import datetimeUtils from '../../../utilities/datetimeUtils';
import { colors } from '../../../styles/colors';
import ScheduleTableCell from './ScheduleTableCell';
import ScheduleTableHeaderRoleCell from './ScheduleTableHeaderRoleCell';
import AssignmentListView from '../AssignmentListView';
import { maxTextLengthBeforeEllipsis } from '../../../constants';

const { Text } = Typography;

class MonthlyScheduleTable extends Component {
  state = {
    expandedRowKeys: [],
  };

  render() {
    const {
      loading,
      dataSource,
      schedules,
      onRefresh,
      onAssignmentUpdate,
      onRoleUpdate,
      assignmentTableLoading,
      timezone,
      clientId,
      weekdayDates,
      editScheduleButtonOnClick,
      addWorkerButtonOnClick,
      editShiftOnClick,
    } = this.props;
    const { expandedRowKeys } = this.state;

    const tableColumns = [
      {
        width: 190,
        render: record => ({
          // TODO move to component
          props: { style: { padding: 0 } },
          children: (
            <>
              <Row
                type="flex"
                align="middle"
                justify="space-between"
                style={{ width: '374px', padding: 0, borderLeft: `1px solid ${colors.backgroundGrey}` }}
              >
                <Col span={10} style={{ paddingLeft: 16 }}>
                  <Row>
                    <Tooltip title={record.name.length > maxTextLengthBeforeEllipsis ? record.name : null}>
                      <Text type="primary" style={{ fontWeight: 600, maxWidth: 100 }} ellipsis>
                        {record.name}
                      </Text>
                    </Tooltip>
                  </Row>
                  <Row>
                    <Text type="primary">{record.time}</Text>
                  </Row>
                  <Row>
                    <Button
                      style={{ color: colors.black, marginTop: '8px' }}
                      icon={<SettingOutlined />}
                      size="small"
                      onClick={() => {
                        editScheduleButtonOnClick(record);
                      }}
                    />
                  </Row>
                </Col>
                <Col span={14}>
                  {record.scheduleRoles.length > 0 ? (
                    record.scheduleRoles.map((scheduleRole, index) => {
                      return (
                        record.scheduleRolesToDisplay.includes(scheduleRole.roleName) && (
                          <Row>
                            {/* TODO pass date and disable button if in past */}
                            <ScheduleTableHeaderRoleCell
                              title={scheduleRole.roleName}
                              onClick={() => {
                                addWorkerButtonOnClick(record, scheduleRole);
                              }}
                              // TODO fix for 2 roles
                              hideBorder={index === 0 || index === record.scheduleRoles.length - 1}
                              showSmall={
                                record.scheduleRoles.filter(scheduleRoleValue => scheduleRoleValue.staffRequired > 0)
                                  .length > 1
                              }
                              isActive={scheduleRole.isActive}
                            />
                          </Row>
                        )
                      );
                    })
                  ) : (
                    <Row>
                      <ScheduleTableHeaderRoleCell
                        title={record.position}
                        onClick={() => {
                          addWorkerButtonOnClick(record);
                        }}
                        hideBorder
                        isActive
                      />
                    </Row>
                  )}
                </Col>
              </Row>
            </>
          ),
        }),
      },
      ...weekdayDates.map(momentDateObj => {
        const isPastShift = momentDateObj < datetimeUtils.getDayStart(moment(), timezone);
        const isEndOfWeek = moment(momentDateObj).format('ddd') === 'Sun';
        return {
          title: (
            <>
              <Row>
                <Text style={{ color: moment(momentDateObj).isSame(moment(), 'day') && colors.functionalSuccess }}>
                  {moment(momentDateObj).format('ddd')}
                </Text>
              </Row>
              <Row>
                <Text
                  style={{ color: moment(momentDateObj).isSame(moment(), 'day') && colors.functionalSuccess }}
                  strong
                >
                  {moment(momentDateObj).format('DD MMM')}
                </Text>
              </Row>
            </>
          ),
          width: 50,
          dataIndex: moment(momentDateObj).format('DD MMM'),
          render: (shift, schedule) => ({
            props: { style: { padding: '0' } },
            children: (
              <>
                {shift && shift.shift_roles.length > 0 ? (
                  shift.shift_roles
                    .sort((a, b) => a.role.name.localeCompare(b.role.name))
                    .map(shiftRole => {
                      return (
                        schedule.scheduleRolesToDisplay.includes(shiftRole.role.name) && (
                          <ScheduleTableCell
                            isPastShift={isPastShift}
                            shift={shift}
                            timezone={timezone}
                            onClick={() => {
                              if (isPastShift) return;
                              editShiftOnClick(shift, schedule, momentDateObj);
                            }}
                            shiftRole={shiftRole}
                            showSmall={
                              schedule.scheduleRoles.filter(scheduleRole => scheduleRole.staffRequired > 0).length > 1
                            }
                            isMonthlyView
                            isEndOfWeek={isEndOfWeek}
                          />
                        )
                      );
                    })
                ) : (
                  <ScheduleTableCell
                    isPastShift={isPastShift}
                    shift={shift}
                    timezone={timezone}
                    onClick={() => {
                      if (isPastShift) return;
                      editShiftOnClick(shift, schedule, momentDateObj);
                    }}
                    isMonthlyView
                    isEndOfWeek={isEndOfWeek}
                  />
                )}
              </>
            ),
          }),
        };
      }),
    ];

    return (
      <Table
        className="schedules-list-table-v2"
        pagination={false}
        loading={loading}
        columns={tableColumns}
        dataSource={dataSource}
        expandedRowKeys={expandedRowKeys}
        expandedRowRender={record => (
          <AssignmentListView
            employments={record.employments}
            schedules={schedules}
            shifts={record.shifts}
            originalShifts={record.originalShifts}
            weekdayDates={weekdayDates}
            onRefresh={onRefresh}
            onAssignmentUpdate={onAssignmentUpdate}
            onRoleUpdate={onRoleUpdate}
            loading={assignmentTableLoading}
            timezone={timezone}
            clientId={clientId}
            scheduleId={record.id}
            position={record.position}
            shiftsOutsideSchedule={record.shiftsOutsideSchedule}
            isMonthlyView
          />
        )}
        expandIconAsCell
        expandIcon={props => (
          <Row type="flex" align="middle" style={{ marginRight: '16px' }}>
            <Button
              size="small"
              style={{
                color: props.expanded ? colors.blue : colors.black,
                margin: '0px 16px 14px',
              }}
              icon={<LegacyIcon type={props.expanded ? 'minus' : 'plus'} />}
              onClick={() => {
                if (expandedRowKeys.includes(props.record.id)) {
                  const updatedExpandedRowKeys = expandedRowKeys.filter(item => item !== props.record.id);
                  this.setState({
                    expandedRowKeys: updatedExpandedRowKeys,
                  });
                } else {
                  this.setState({ expandedRowKeys: [...expandedRowKeys, props.record.id] });
                }
              }}
            />
          </Row>
        )}
        rowKey="id"
        style={{ marginBottom: '80px' }}
        size="middle"
        scroll={{ x: 'max-content' }}
      />
    );
  }
}

export default MonthlyScheduleTable;
