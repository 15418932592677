import React from 'react';
import { useSortable, defaultAnimateLayoutChanges } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import QuestionListItem from './QuestionListItem';

const QuestionListItemSortable = ({ question, index, sortableId, style = {}, editable = true, sortable = true }) => {
  const animateLayoutChanges = args => defaultAnimateLayoutChanges({ ...args, wasDragging: true });

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: sortableId,
    animateLayoutChanges,
    data: {
      question,
      index,
    },
  });

  const dragItemStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  return (
    <div ref={setNodeRef} style={{ ...style, ...dragItemStyle }} {...attributes}>
      <QuestionListItem
        question={question}
        index={index}
        editable={editable}
        sortable={sortable}
        dragHandleListeners={listeners}
      />
    </div>
  );
};

export default QuestionListItemSortable;
