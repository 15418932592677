import React from 'react';
import { Button, Col, Divider, Popover, Row, Select, Tree } from 'antd';
import equal from 'fast-deep-equal';
import { withTranslation } from 'react-i18next';
import { CaretDownFilled } from '@ant-design/icons';
import { colors } from '../../styles/colors';

const { Option } = Select;

class TreeCheckboxFilter extends React.Component {
  state = {
    checkedKeys: this.props.checkedKeys,
    visible: false,
    searchValue: '',
  };

  componentDidUpdate(prevProps) {
    const { checkedKeys } = this.props;
    if (!equal(checkedKeys, prevProps.checkedKeys)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ checkedKeys });

      if (!checkedKeys || checkedKeys.length === 0) {
        this.onReset();
      }
    }
  }

  onReset = () => {
    this.setState({
      checkedKeys: [],
    });
  };

  onApply = () => {
    const { checkedKeys } = this.state;
    const { onApply } = this.props;
    this.setState(
      {
        visible: false,
      },
      () => onApply && onApply(checkedKeys),
    );
  };

  getKeysFromTreeData = (treeData, key = null) => {
    const keys = [];
    treeData.forEach(item => {
      let found = false;
      if (key === null || key === item.key) {
        keys.push(item.key);
        found = true;
      }
      if (item.children) {
        this.getKeysFromTreeData(item.children, !found ? key : null).forEach(subItem => {
          keys.push(subItem);
        });
      }
    });
    return keys;
  };

  onDeselect = item => {
    const { treeData } = this.props;
    const { checkedKeys } = this.state;
    const uncheckedKeys = this.getKeysFromTreeData(treeData, item.key);
    const newCheckedKeys = [];

    if (checkedKeys) {
      checkedKeys.forEach(key => {
        if (!uncheckedKeys.includes(key)) {
          newCheckedKeys.push(key);
        }
      });
    }

    this.setState({ checkedKeys: newCheckedKeys });
  };

  onSelect = value => {
    const { checkedKeys } = this.state;
    const newCheckedKeys = [];

    if (checkedKeys) {
      checkedKeys.forEach(key => {
        newCheckedKeys.push(key);
      });
    }

    const tokens = value.key.split('__');
    newCheckedKeys.push(tokens[0]);

    this.setState({ checkedKeys: newCheckedKeys, searchValue: '' });
  };

  onCheck = (checkedKeys, e) => {
    // checkedKeys will not return all key values when selecting and deselecting on a filtered list
    this.setState(prevState => {
      // Selecting: add checkedKey to prevState and remove duplicates
      const newCheckedKeys = [...new Set([...(prevState.checkedKeys || []), ...checkedKeys])];
      // Deselecting: remove key from prevState
      if (e.checked === false) {
        const deselectedKey = e.node.key;
        const index = newCheckedKeys.indexOf(deselectedKey);
        newCheckedKeys.splice(index, 1);
      }

      return { checkedKeys: newCheckedKeys, searchValue: '' };
    });
  };

  onVisibleChange = visible => {
    this.setState({ visible }, () => !visible && this.resetChanges());
  };

  resetChanges = () => {
    const { checkedKeys } = this.props;
    this.setState({ checkedKeys, searchValue: '' });
  };

  getCheckedItems = treeData => {
    const { checkedKeys } = this.state;
    const { formatTag } = this.props;
    const items = [];
    treeData.forEach(item => {
      if (checkedKeys?.includes(item.key)) {
        const label = formatTag ? formatTag(item) : item.title;
        items.push({ ...item, label });
      } else if (item.children) {
        const subItems = this.getCheckedItems(item.children);
        if (subItems.length === item.children.length) {
          // Just append the parent item if all of its child items are selected.
          items.push({ ...item, label: item.title });
        } else {
          subItems.forEach(subItem => {
            items.push(subItem);
          });
        }
      }
    });
    return items;
  };

  renderOptions = treeData => {
    return treeData.map(item => {
      return (
        <Option key={item.key} value={`${item.key}__${item.title}`}>
          {item.title}
        </Option>
      );
    });
  };

  flattenTreeData = treeData => {
    const items = [];
    treeData.forEach(item => {
      items.push(item);
      if (item.children) {
        this.flattenTreeData(item.children).forEach(subItem => {
          items.push(subItem);
        });
      }
    });
    return items;
  };

  onSearch = value => {
    this.setState({ searchValue: value });
  };

  filterTreeData = treeData => {
    const { searchValue } = this.state;
    if (!searchValue) return treeData;

    return treeData.filter(item => {
      const index = item.title.toLowerCase().indexOf(searchValue.toLowerCase());

      let filteredChildren = [];
      if (item.children) {
        filteredChildren = item.children.filter(child => {
          const childIndex = child.title.toLowerCase().indexOf(searchValue.toLowerCase());
          return childIndex > -1;
        });
      }

      return index > -1 || filteredChildren.length > 0;
    });
  };

  render() {
    const {
      label,
      placeholder,
      treeData,
      t,
      showActionButtons = false,
      containerWidth = 240,
      expandable = true,
      showSearch = true,
      fillWidth = false,
      isDisabled = false,
    } = this.props;
    const { visible } = this.state;

    const checkedItems = this.getCheckedItems(treeData);
    const treeCheckedKeys = checkedItems.map(item => item.key);
    const flattenedTreeData = this.flattenTreeData(treeData);
    const filteredTreeData = this.filterTreeData(treeData);
    const filteredCheckedItems = this.getCheckedItems(filteredTreeData);
    const filteredTreeCheckedKeys = filteredCheckedItems.map(item => item.key);

    const popoverTrigger = isDisabled ? 'focus' : 'click';

    const selectProps = {
      dropdownStyle: { display: 'none' },
      labelInValue: true,
      mode: 'multiple',
      onDeselect: this.onDeselect,
      onSelect: this.onSelect,
      placeholder,
      style: { marginBottom: 4, width: '100%' },
      value: checkedItems,
      onSearch: this.onSearch,
    };
    const treeProps = {
      checkable: true,
      checkedKeys: filteredTreeCheckedKeys,
      onCheck: this.onCheck,
      selectable: false,
      defaultExpandAll: true,
      className: !expandable && 'hide-expand-icon',
      style: !expandable && { paddingLeft: 8 },
      blockNode: true,
      treeData: filteredTreeData,
    };
    return (
      <Popover
        visible={visible}
        trigger={popoverTrigger}
        placement="bottomLeft"
        onVisibleChange={this.onVisibleChange}
        overlayStyle={{ width: containerWidth }}
        overlayClassName="ant-popover-wrapper tree-checkbox-filter"
        zIndex={2}
        content={
          <>
            {showSearch && <Select {...selectProps}>{this.renderOptions(flattenedTreeData)}</Select>}
            <div style={{ maxHeight: 400, overflowY: 'scroll' }}>
              <Tree {...treeProps} />
            </div>
            {showActionButtons && (
              <div style={{ padding: '0 8px' }}>
                <Divider style={{ margin: '8px 0' }} />
                <Row type="flex" justify="space-between" style={{ padding: '8px 0' }}>
                  <Col>
                    <Button size="small" onClick={this.onReset}>
                      {t('Reset')}
                    </Button>
                  </Col>
                  <Col>
                    <Button size="small" className="ant-btn-v2-primary" onClick={this.onApply}>
                      {t('apply')}
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </>
        }
      >
        <Button
          onClick={this.toggleVisiblity}
          style={{ marginRight: 12, width: fillWidth && '100%' }}
          disabled={isDisabled}
          {...(treeCheckedKeys.length > 0 && {
            style: {
              borderColor: colors.blue,
              backgroundColor: colors.white,
              color: colors.blue,
              zIndex: 1,
              marginRight: 12,
              width: fillWidth && '100%',
            },
          })}
        >
          {label}
          {treeCheckedKeys.length > 0 ? ` (${treeCheckedKeys.length})` : ''}
          <CaretDownFilled />
        </Button>
      </Popover>
    );
  }
}

export default withTranslation()(TreeCheckboxFilter);
