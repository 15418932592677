import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';

const DragButton = styled('button')`
  cursor: move;
  background-color: transparent;
  border: 0;
  border-radius: 6px;
  display: flex;
  align-items: center;

  opacity: ${props => (props.visible ? 1 : 0)};

  svg {
    fill: ${colors.darkGrey};
  }
`;

const DragIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="18" viewBox="0 0 6 18" fill="none">
      <path
        d="M0 0H2V2H0V0ZM4 0H6V2H4V0ZM0 4H2V6H0V4ZM4 4H6V6H4V4ZM0 8H2V10H0V8ZM4 8H6V10H4V8ZM0 12H2V14H0V12ZM4 12H6V14H4V12ZM0 16H2V18H0V16ZM4 16H6V18H4V16Z"
        fill="#BFBFBF"
      />
    </svg>
  );
};

const DragHandle = ({ dragHandleListeners = {}, visible = false }) => {
  return (
    <DragButton visible={visible} type="button" {...dragHandleListeners} className="drag-handle-button">
      <DragIcon />
    </DragButton>
  );
};

export default DragHandle;
