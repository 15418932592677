import { API_ROOT } from '../config';
import base from './base';

const fetchCountryInfo = countryId => {
  return base.api.get(`${API_ROOT}/countries/${countryId}/info/`);
};

export default {
  fetchCountryInfo,
};
