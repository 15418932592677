import React from 'react';
import { Typography, Row, Col, Card, Badge } from 'antd';
import { withTranslation } from 'react-i18next';

const { Text } = Typography;

// Renders a rectangular card that displays the count (number) of an entity.
const CardWithCount = ({ loading = false, additionalStyle = {}, countList, t }) => {
  return (
    <Card
      loading={loading}
      style={{ boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)', width: '100%', marginBottom: '32px', ...additionalStyle }}
      bodyStyle={{ margin: '24px 16px', padding: 0 }}
    >
      <Row type="flex" gutter={72}>
        {countList.map((count, index) => {
          const colSpan = 24 / countList.length;
          // Last count would not have a border to it's right
          const borderStyle = index === countList.length - 1 ? {} : { borderRight: '1px solid #D9D9D9' };
          const cursorStyle = count.onClick ? { cursor: 'pointer' } : {};
          const titleClassName = count.onClick ? 'underline-on-hover' : '';
          return (
            <Col key={index} xs={12} md={colSpan} style={{ ...borderStyle }}>
              <Row style={{ marginBottom: '4px' }}>
                <Text
                  strong
                  type="secondary"
                  className={titleClassName}
                  style={{ ...cursorStyle, fontSize: '12px' }}
                  onClick={count.onClick}
                >
                  {t(count.title.toUpperCase())}
                </Text>
              </Row>
              <Row>
                <Text strong style={{ fontSize: '20px' }}>
                  {count.num} {count.num > 0 && count.hasBadge && <Badge status="error" />}
                </Text>
              </Row>
            </Col>
          );
        })}
      </Row>
    </Card>
  );
};

export default withTranslation()(CardWithCount);
