import React from 'react';
import { withTranslation } from 'react-i18next';
import { UserAddOutlined } from '@ant-design/icons';
import { Typography, Row, Col, Button, Tooltip } from 'antd';
import { colors } from '../../../styles/colors';
import { maxTextLengthBeforeEllipsis } from '../../../constants';

const { Text } = Typography;

const ScheduleTableHeaderRoleCell = ({ title, t, onClick, hideBorder, showSmall, isActive }) => {
  return (
    <Row
      type="flex"
      align="middle"
      justify="space-between"
      style={{
        padding: '16px',
        height: showSmall ? '50px' : '100px',
        border: `1px solid ${colors.backgroundGrey}`,
        borderTop: hideBorder ? '0px' : `1px solid ${colors.backgroundGrey}`,
        borderBottom: hideBorder ? '0px' : `1px solid ${colors.backgroundGrey}`,
      }}
    >
      <Col>
        <Row>
          <Tooltip title={title.length > maxTextLengthBeforeEllipsis ? title : null}>
            <Text type="primary" style={{ fontWeight: 600, maxWidth: 100 }} ellipsis>
              {title}
            </Text>
          </Tooltip>
        </Row>
      </Col>
      <Col>
        <Button
          style={{ color: isActive ? colors.black : colors.disabled, marginRight: '8px' }}
          disabled={!isActive}
          icon={<UserAddOutlined />}
          size="small"
          onClick={() => {
            onClick();
          }}
        >
          {t('add')}
        </Button>
      </Col>
    </Row>
  );
};

export default withTranslation()(ScheduleTableHeaderRoleCell);
