import React from 'react';
import {
  ExclamationCircleTwoTone,
  QuestionCircleOutlined,
  QuestionCircleTwoTone,
  WarningTwoTone,
  WarningOutlined,
  InfoCircleOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { Modal, Typography, Row, Collapse, InputNumber, Divider } from 'antd';
import { Trans } from 'react-i18next';
import { colors } from '../../styles/colors';

const { Text } = Typography;
const { Panel } = Collapse;
const { confirm } = Modal;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Timesheet modals */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
const resolveTimesheetEntry = (workerName, t, onOk) => {
  confirm({
    centered: true,
    title: t(`Resolve disputed timesheet entry`),
    content: (
      <Text>
        {t('You are about to resolve')}
        <Text strong>{` ${workerName} `}</Text>
        {t('timesheet entry. Are you sure?')}
      </Text>
    ),
    okText: t('Yes, resolve'),
    okType: 'v2-primary',
    cancelText: t('cancel'),
    icon: <QuestionCircleTwoTone twoToneColor="#FFC400" style={{ fontSize: '20px' }} />,
    onOk,
  });
};

const approveTimesheetEntries = (numTimesheet, t, onOk) => {
  confirm({
    centered: true,
    title: t(`Approve timesheet entries`),
    content: (
      <Text>
        {t('You are about to approve')}
        <Text strong>{` ${numTimesheet} `}</Text>
        {numTimesheet === 1 ? t('timesheet entry. Are you sure?') : t('timesheet entries. Are you sure?')}
      </Text>
    ),
    okText: t('Yes, approve'),
    okType: 'v2-primary',
    cancelText: t('cancel'),
    icon: <QuestionCircleTwoTone style={{ fontSize: '20px' }} />,
    onOk,
  });
};

const confirmTimesheetEntries = (numTimesheet, t, onOk) => {
  confirm({
    centered: true,
    title: t('confirmTimesheetModalTitle'),
    content: (
      <Text>
        {t('confirmModalMessageConfirmPrompt')}
        <Text strong>{` ${numTimesheet} `}</Text>
        {numTimesheet === 1
          ? t('confirmModalMessageConfirmPromptSingle')
          : t('confirmModalMessageConfirmPromptMultiple')}
      </Text>
    ),
    okText: t('confirmModalSubmitBtn'),
    okType: 'v2-primary',
    cancelText: t('cancel'),
    icon: <QuestionCircleTwoTone style={{ fontSize: '20px' }} />,
    onOk,
  });
};

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Leave Claim modals */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
const rejectLeaveClaims = (numLeaveClaims, t, onOk) => {
  confirm({
    centered: true,
    title: t(`Reject leave claims`),
    content: (
      <Text>
        {t('You are about to reject')}
        <Text strong>{` ${numLeaveClaims} `}</Text>
        {t('leave claims. Are you sure?')}
      </Text>
    ),
    okText: t('Yes, reject'),
    okType: 'danger',
    cancelText: t('cancel'),
    icon: <QuestionCircleOutlined style={{ fontSize: '20px' }} />,
    onOk,
  });
};

const approveLeaveClaims = (numLeaveClaims, t, onOk) => {
  confirm({
    centered: true,
    title: t(`Approve leave claims`),
    content: (
      <Text>
        {t('You are about to approve')}
        <Text strong>{` ${numLeaveClaims} `}</Text>
        {t('leave claims. Are you sure?')}
      </Text>
    ),
    okText: t('Yes, approve'),
    okType: 'v2-primary',
    cancelText: t('cancel'),
    icon: <QuestionCircleTwoTone style={{ fontSize: '20px' }} />,
    onOk,
  });
};

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Offer modals */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
const cancelOffer = (workerName, t, onOk) => {
  confirm({
    centered: true,
    title: t(`Cancel worker from staff request`),
    content: (
      <Text>
        {t('You are about to cancel')}
        <Text strong>{` ${workerName} `}</Text>
        {t('from this staff request. Are you sure?')}
      </Text>
    ),
    okText: t('Yes, cancel'),
    okType: 'danger',
    cancelText: t('cancel'),
    icon: <QuestionCircleOutlined style={{ fontSize: '20px' }} />,
    onOk,
  });
};

const reportWorkerNoShow = (workerName, t, onOk) => {
  confirm({
    centered: true,
    title: t(`Report worker as no-show`),
    content: (
      <Text>
        {t('You are about to report')}
        <Text strong>{` ${workerName}'s `}</Text>
        {t('as no-show. Are you sure?')}
      </Text>
    ),
    okText: t('Yes, report'),
    okType: 'danger',
    cancelText: t('cancel'),
    icon: <QuestionCircleOutlined style={{ fontSize: '20px' }} />,
    onOk,
  });
};

const rejectWorker = (workerName, t, onOk) => {
  confirm({
    centered: true,
    title: `${t('Reject')} ${workerName}`,
    content: (
      <Text>
        {t('You are about to reject')}
        <Text strong>{` ${workerName}'s `}</Text>
        {t('application. Are you sure?')}
      </Text>
    ),
    okText: t('Yes, reject'),
    okType: 'danger',
    cancelText: t('cancel'),
    icon: <QuestionCircleOutlined style={{ fontSize: '20px' }} />,
    onOk,
    zIndex: 99999,
  });
};

const bulkRejectWorkers = (workerCount, t, onOk) => {
  confirm({
    centered: true,
    title: t('bulkRejectWorkersModalTitle', { count: workerCount }),
    content: <Text>{t('bulkRejectWorkerModalDescription')}</Text>,
    okText: t('Yes, reject'),
    okType: 'danger',
    cancelText: t('cancel'),
    icon: <WarningTwoTone twoToneColor={colors.functionalWarning} style={{ fontSize: '20px' }} />,
    onOk,
    zIndex: 99999,
  });
};

const hireWorker = (workerName, smsMsg, inAppNotif, t, onChangeOfferExpiry, isRehire, offerExpiryHour, onOk) => {
  confirm({
    centered: true,
    title: <Text strong>{t('sendWorkerAnOffer', { workerName })}</Text>,
    width: 500,
    content: (
      <>
        <Row>
          <Text>{t('workerWillReceive')}</Text>
        </Row>
        {!isRehire && (
          <Row>
            <Text>{t('bulkHireWorkerModalBullet1')}</Text>
          </Row>
        )}
        <Row>
          <Text>{t('bulkHireWorkerModalBullet2')}</Text>
        </Row>
        <Row>
          <Text>{t('bulkHireWorkerModalBullet3')}</Text>
        </Row>
        <Row>
          <div className="hire-modal-dropdown">
            <Collapse ghost>
              <Panel
                showArrow={false}
                header={isRehire ? t('seeNotificationDetails') : t('seeSmsNotificationDetails')}
                key="1"
                extra={<DownOutlined style={{ marginLeft: 8, fontSize: 8 }} />}
              >
                {!isRehire && (
                  <>
                    <Row>
                      <Text strong>{t('smsMessage')}</Text>
                    </Row>
                    <Row>
                      <Text>{smsMsg}</Text>
                    </Row>
                  </>
                )}
                <Row style={{ marginTop: 8 }}>
                  <Text strong>{t('inAppNotification')}</Text>
                </Row>
                <Row>
                  <Text>{inAppNotif}</Text>
                </Row>
              </Panel>
            </Collapse>
          </div>
        </Row>
        <Divider />
        <Row style={{ marginTop: 12, marginBottom: 8 }}>
          <Text>
            {t('offerExpires1')}
            <InputNumber
              // eslint-disable-next-line no-undef
              defaultValue={offerExpiryHour}
              min={1}
              type="number"
              placeholder="24"
              style={{ marginLeft: 4, marginRight: 4 }}
              onChange={value => {
                onChangeOfferExpiry(value || undefined);
              }}
            />
            {t('offerExpires2')}
          </Text>
        </Row>
        <Row>
          <Text style={{ color: colors.disabled, fontSize: 12 }}>{t('offerExpireDescription')}</Text>
        </Row>
      </>
    ),
    okText: t('proceed'),
    okType: 'v2-primary',
    cancelText: t('cancel'),
    icon: <InfoCircleOutlined style={{ fontSize: '20px', color: colors.blue }} />,
    onOk,
    zIndex: 99999,
    onCancel: () => onChangeOfferExpiry(undefined),
  });
};

const bulkHireWorkers = (workerCount, smsMsg, inAppNotif, t, offerExpiryHour, handleChangeOfferExpiry, onOk) => {
  confirm({
    centered: true,
    title: <Text strong>{t('bulkHireWorkersModalTitle', { count: workerCount })}</Text>,
    width: 500,
    content: (
      <>
        <Row>
          <Text>{t('workerWillReceive')}</Text>
        </Row>
        <Row>
          <Text>{t('bulkHireWorkerModalBullet1')}</Text>
        </Row>
        <Row>
          <Text>{t('bulkHireWorkerModalBullet2')}</Text>
        </Row>
        <Row>
          <Text>{t('bulkHireWorkerModalBullet3')}</Text>
        </Row>
        <Row>
          <div className="hire-modal-dropdown">
            <Collapse ghost>
              <Panel
                showArrow={false}
                header={t('seeSmsNotificationDetails')}
                key="1"
                extra={<DownOutlined style={{ marginLeft: 8, fontSize: 8 }} />}
              >
                <Row>
                  <Text strong>{t('smsMessage')}</Text>
                </Row>
                <Row>
                  <Text>{smsMsg}</Text>
                </Row>
                <Row style={{ marginTop: 8 }}>
                  <Text strong>{t('inAppNotification')}</Text>
                </Row>
                <Row>
                  <Text>{inAppNotif}</Text>
                </Row>
              </Panel>
            </Collapse>
          </div>
        </Row>
        <Divider />
        <Row style={{ marginTop: 12, marginBottom: 8 }}>
          <Text>
            {t('offerExpires1')}
            <InputNumber
              defaultValue={offerExpiryHour}
              min={1}
              type="number"
              placeholder="24"
              style={{ marginLeft: 4, marginRight: 4 }}
              onChange={value => {
                handleChangeOfferExpiry(() => value || undefined);
              }}
            />
            {t('offerExpires2')}
          </Text>
        </Row>
        <Row>
          <Text style={{ color: colors.disabled, fontSize: 12 }}>{t('offerExpireDescription')}</Text>
        </Row>
      </>
    ),
    okText: t('proceed'),
    okType: 'v2-primary',
    cancelText: t('cancel'),
    icon: <InfoCircleOutlined style={{ fontSize: '20px', color: colors.blue }} />,
    onOk,
    zIndex: 99999,
    onCancel: () => handleChangeOfferExpiry(undefined),
  });
};

const retractOffer = (workerName, t, onOk) => {
  confirm({
    centered: true,
    title: <Text strong>{t('retractWorkerOffer', { workerName })}</Text>,
    content: <Text>{t('theyWillBeRemovedFromApplicationProccess')}</Text>,
    okText: 'Reject',
    okType: 'danger',
    cancelText: t('cancel'),
    icon: <WarningOutlined style={{ fontSize: '20px' }} />,
    onOk,
    zIndex: 99999,
  });
};

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* SR modals */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
const cancelSr = (srTitle, srId, t, onOk) => {
  confirm({
    centered: true,
    title: <Text style={{ color: 'red' }}>{t('WARNING: Cancel staff request')}</Text>,
    content: (
      <Text>
        {t('You are about to cancel')}
        <Text strong>{` ${srTitle}(#${srId}) `}</Text>
        {t('Are you sure?')}
      </Text>
    ),
    okText: t('Yes, cancel SR'),
    okType: 'danger',
    cancelText: t('back'),
    icon: <ExclamationCircleTwoTone style={{ fontSize: '20px' }} twoToneColor="red" />,
    onOk,
  });
};

const submitSr = (srTitle, srId, t, onOk) => {
  confirm({
    centered: true,
    title: t('Submit staff request for review'),
    content: (
      <Text>
        {t('You are about to submit')}
        <Text strong>{` ${srTitle}(#${srId}) `}</Text>
        {t('for review. Are you sure?')}
      </Text>
    ),
    okText: t('Yes, submit SR'),
    okType: 'v2-primary',
    cancelText: t('cancel'),
    icon: <QuestionCircleTwoTone style={{ fontSize: '20px' }} />,
    onOk,
  });
};

const deleteStaffRequestTemplate = (t, templateTitle, onOk) => {
  confirm({
    centered: true,
    title: <Text strong>{t('deleteTemplateTitle')}</Text>,
    content: (
      <Text>
        <Trans i18nKey="youAreAboutToDeleteTemplate" values={{ templateTitle }} />
      </Text>
    ),
    okText: t('yesDelete'),
    okType: 'danger',
    cancelText: t('back'),
    onOk,
  });
};

export default {
  reportWorkerNoShow,
  rejectWorker,
  bulkRejectWorkers,
  hireWorker,
  bulkHireWorkers,
  cancelSr,
  submitSr,
  cancelOffer,
  rejectLeaveClaims,
  approveLeaveClaims,
  approveTimesheetEntries,
  confirmTimesheetEntries,
  resolveTimesheetEntry,
  retractOffer,
  deleteStaffRequestTemplate,
};
