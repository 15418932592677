import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Button, Typography } from 'antd';
import { withRouter } from 'react-router-dom';
import EmptyCard from '../../../shared/components/EmptyCard';
import SupportAvatar from '../../../assets/images/support.png';
import { colors } from '../../../styles/colors';

const { Text } = Typography;

const ReviewingStaffRequest = ({ t }) => {
  return (
    <>
      <EmptyCard
        imgSrc={SupportAvatar}
        title={
          <Text strong style={{ color: colors.black }}>
            {t('reviewRequestTitle')}
          </Text>
        }
        description={
          <Row type="flex" justify="center">
            {t('reviewRequestDescription')}
          </Row>
        }
      />
      <Row type="flex" justify="center" style={{ marginTop: '24px', alignItems: 'baseline' }}>
        <Text>{t('needHelp')}</Text>
        <Button
          style={{ padding: '0px', marginLeft: '2px' }}
          type="link"
          onClick={() => window.zE.activate({ hideOnClose: true })}
        >
          {t('Chat with us')}
        </Button>
      </Row>
    </>
  );
};

export default withRouter(withTranslation()(ReviewingStaffRequest));
