import React from 'react';
import { withTranslation } from 'react-i18next';
import { generatePath, withRouter } from 'react-router';
import moment from 'moment';
import { uniqueId, cloneDeep } from 'lodash';
import { MoreOutlined } from '@ant-design/icons';
import { Divider, Typography, Row, Col, Avatar, Menu, Dropdown, Button } from 'antd';

import chatCheckAll from '../../../assets/images/check_all.svg';
import chatCheck from '../../../assets/images/check.svg';

import { DATE_FORMAT, TIME_FORMAT_24_HOUR } from '../../../constants';
import { colors } from '../../../styles/colors';

import routes from '../routes';
import { getLocationState } from '../../../utilities/chatUtils';

const { Text } = Typography;

const MessageDivider = ({ text }) => (
  <Divider style={{ fontSize: '14px', fontWeight: 'normal' }}>
    <Text type="secondary">{text}</Text>
  </Divider>
);

const ChatMessageList = ({
  t,
  history,
  location,
  twilioChatChannel,
  messages,
  messagesReadCounts,
  selectedChannel,
}) => {
  const selectMessage = (message, readCount, readInfo, messageCheckType) => {
    const route = generatePath(routes.openChatMessageInfo, {
      channelId: selectedChannel?.id,
    });
    Object.assign(message, { readCount, readInfo, messageCheckType });
    const selectChannelUpdated = cloneDeep(selectedChannel);
    selectChannelUpdated.selected_message = message;
    history.push(route, {
      ...getLocationState(location),
      channel: selectChannelUpdated,
    });
  };

  const formatMessageInList = (message, index, showDivider, messageDividerDate) => {
    if (message.memberData?.userType === 'manager') {
      const readCount = messagesReadCounts[index];
      const readInfo = readCount ? t('seenByCount', { readCount }) : t('messageSentCheck');
      const messageCheckType = readCount ? chatCheckAll : chatCheck;
      return (
        <div key={uniqueId()}>
          <Row>{showDivider && <MessageDivider text={messageDividerDate.format(DATE_FORMAT)} />}</Row>
          <Row justify="end" style={{ textAlign: 'right', marginBottom: '3px' }}>
            <Text type="secondary">{`${readInfo}`}</Text>
            <img
              src={messageCheckType}
              alt="double tick"
              style={{ width: '14px', marginRight: '4px', marginLeft: '4px' }}
            />
            <Text type="secondary">{` · ${moment(message.timestamp).format(TIME_FORMAT_24_HOUR)}`}</Text>
          </Row>
          <Row key={index} justify="end" style={{ marginBottom: 16 }}>
            <Row>
              <Col style={{ alignSelf: 'flex-end' }}>
                <Dropdown
                  overlayStyle={{ zIndex: 99999 }}
                  trigger={['click']}
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="info"
                        onClick={() => {
                          selectMessage(message, readCount, readInfo, messageCheckType);
                        }}
                      >
                        {t('Message info')}
                      </Menu.Item>
                    </Menu>
                  }
                  placement="bottomRight"
                  arrow
                >
                  <Button
                    className="chat-msg-info-dropdown-btn"
                    type="text"
                    shape="circle"
                    icon={<MoreOutlined />}
                    style={{ color: colors.secondaryText, marginRight: 5 }}
                  />
                </Dropdown>
              </Col>
              <Col style={{ textAlign: 'right' }}>
                {message.imageUrl ? (
                  // We have to define "Width" & "Height" upfront. This is so we can accurately to the bottom.
                  <section
                    style={{
                      paddingTop: '8px',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    <img
                      alt={`chat-img-${index}`}
                      src={message.imageUrl}
                      style={{
                        objectFit: 'cover',
                        maxWidth: '300px',
                        maxHeight: '300px',
                        borderRadius: '16px 0px 16px 16px',
                      }}
                    />
                  </section>
                ) : (
                  <section
                    style={{
                      textAlign: 'left',
                      padding: '8px 16px',
                      borderRadius: '16px 0px 16px 16px',
                      backgroundColor: colors.functionalSuccess,
                      color: colors.white,
                      maxWidth: '300px',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {message.body}
                  </section>
                )}
              </Col>
            </Row>
          </Row>
        </div>
      );
    }
    if (message.memberData?.userType === 'worker') {
      return (
        <div key={uniqueId()}>
          <Row>{showDivider && <MessageDivider text={messageDividerDate.format(DATE_FORMAT)} />}</Row>
          <Row
            justify="start"
            style={{ textAlign: 'right', paddingRight: '16px', marginLeft: '48px', marginBottom: '3px' }}
          >
            <Text type="secondary">
              {`${message.memberData.data.name} · ${moment(message.timestamp).format(TIME_FORMAT_24_HOUR)}`}
            </Text>
          </Row>
          <Row
            key={index}
            type="flex"
            justify="start"
            align="top"
            style={{ marginBottom: '8px', paddingBottom: '8px' }}
          >
            <Col style={{ paddingRight: '8px', paddingTop: '2px' }}>
              <Avatar shape="circle" size="large" src={message.memberData.data.image} className="v2-avatar-wrapper" />
            </Col>
            <Col style={{ textAlign: 'left' }}>
              {message.imageUrl ? (
                // We have to define "Width" & "Height" upfront. This is so we can accurately to the bottom.
                <section
                  style={{
                    paddingTop: '8px',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <img
                    alt={`chat-img-${index}`}
                    src={message.imageUrl}
                    style={{
                      objectFit: 'cover',
                      maxWidth: '300px',
                      maxHeight: '300px',
                      borderRadius: '16px 0px 16px 16px',
                    }}
                  />
                </section>
              ) : (
                <section
                  style={{
                    textAlign: 'left',
                    padding: '8px 16px',
                    borderRadius: '16px',
                    backgroundColor: colors.backgroundGrey,
                    maxWidth: '300px',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {message.body}
                </section>
              )}
            </Col>
          </Row>
        </div>
      );
    }
  };

  let messageDividerDate = moment(twilioChatChannel?.channelState?.dateCreated);
  const createdDate = messageDividerDate.format(DATE_FORMAT);

  return (
    <>
      <MessageDivider text={t('createdDate', { createdDate })} />
      <div style={{ height: 'auto' }}>
        {messages &&
          messagesReadCounts &&
          messages.map(message => {
            const messageTimeStamp = moment(message.timestamp);
            let showDivider = false;
            if (!messageDividerDate.isSame(messageTimeStamp, 'd')) {
              showDivider = true;
              messageDividerDate = messageTimeStamp;
            }
            return formatMessageInList(message, message.index, showDivider, messageDividerDate);
          })}
      </div>
    </>
  );
};

export default withTranslation()(withRouter(ChatMessageList));
