import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_EMPLOYMENTS = gql`
  query getEmployments(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $orderBy: [String]
    $status: String
    $partner: String
    $endDateAfter: DateTime
    $startDateBefore: DateTime
    $location: [String]
    $paymentFrequency: [String]
    $accepted: Boolean
    $positions: [String]
    $search: String
  ) {
    employments(
      first: $first
      after: $after
      before: $before
      orderBy: $orderBy
      status: $status
      partner: $partner
      endDateAfter: $endDateAfter
      startDateBefore: $startDateBefore
      location: $location
      paymentFrequency: $paymentFrequency
      accepted: $accepted
      positions: $positions
      offset: $offset
      search: $search
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          contractRequired
          startDate
          endDate
          status
          partner {
            id
            firstName
            lastName
          }
          location {
            id
            name
          }
          position {
            id
            name
          }
          contracts {
            id
            status
            startDate
            endDate
          }
          absenceDetails {
            id
            status
            absenceDate
          }
          type
          leaveEntries(dateAfter: $endDateAfter, dateBefore: $startDateBefore) {
            id
            status
            date
            paymentStatus
            employmentId
            leaveEntryType {
              id
              name
              isPaid
            }
            partner {
              id
              firstName
              lastName
              image
            }
            location {
              id
              name
              selfieAllowed
              addressLatitude
              addressLongitude
            }
            position {
              id
              name
            }
            disputedReason {
              code
              description
            }
            voidedReason {
              id
            }
            lastModifiedByName
            modifiedDate
            confirmedByName
            confirmedDate
            approvedByName
            approvedDate
            voidedByName
            voidedDate
            notes
          }
          timesheetEntries(clockInTimeAfter: $endDateAfter, clockInTimeBefore: $startDateBefore) {
            id
            status
            clockInTime
            clockInSupervisor {
              name
            }
            clockInSelfie
            clockInSelfieStatus
            clockInLatitude
            clockInLongitude
            clockInSource
            clockOutTime
            clockOutSupervisor {
              name
            }
            clockOutSelfie
            clockOutSelfieStatus
            clockOutLatitude
            clockOutLongitude
            clockOutSource
            roundedClockInTime
            roundedClockOutTime
            overtimeDuration
            totalWorkDurationInMinutes
            supervisorConfirmed
            status
            paymentStatus
            approvedDate
            confirmedDate
            voidedDate
            modifiedDate
            overtimeDuration
            late
            partner {
              id
              firstName
              lastName
              image
            }
            location {
              id
              name
              selfieAllowed
              addressLatitude
              addressLongitude
              maximumClockInRadiusInMeters
            }
            position {
              id
              name
            }
            client {
              id
            }
            disputedReason {
              code
              description
            }
            voidedReason {
              id
            }
            breaks {
              id
              endTime
            }
            tags {
              id
              name
              tagId
              categoryId
            }
            confirmingSupervisor {
              name
            }
            lastModifiedByName
            approvedByName
            voidedByName
            unpaidBreak
            breakDurationInMinutes
          }
        }
      }
    }
  }
`;
