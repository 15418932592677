import * as Sentry from '@sentry/browser';
import React, { useEffect, useState, message } from 'react';
import { useTranslation } from 'react-i18next';
import WorkerList from './WorkerList';
import shiftApplicationApi from '../../../../services/shiftApplicationApi';
import { applicationStatuses } from '../../../../constants';

const WaitlistedWorkerList = ({ shift, shiftTime }) => {
  const [loading, setLoading] = useState(true);
  const [shiftApplications, setShiftApplications] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchWaitlistedApplications = async () => {
      try {
        setLoading(true);
        const data = await shiftApplicationApi.list({
          shift_id: shift.id,
          status: applicationStatuses.SHORTLISTED,
          ordering: 'created_date',
        });
        if (data?.results) {
          setShiftApplications(data.results);
        }
      } catch (error) {
        Sentry.captureException(error);
        message.error(t('Sorry there is an error'));
      } finally {
        setLoading(false);
      }
    };
    fetchWaitlistedApplications();
  }, []);

  const workers = shiftApplications.map(({ partner }) => ({
    ...partner,
    firstName: partner.first_name,
    lastName: partner.last_name,
    statistics: { totalRating: partner.statistics.total_ratings, totalDaysWorked: partner.statistics.days_worked },
  }));

  return (
    <WorkerList
      loading={loading}
      shift={shift}
      shiftTime={shiftTime}
      workers={workers}
      emptyTitle={t('waitlistTabEmptyStateTitle')}
      emptyDescription={t('waitlistTabEmptyStateText')}
    />
  );
};

export default WaitlistedWorkerList;
