import base from './base';
import { endpoints } from '../config';

const list = async (params = {}) => {
  const response = await base.api.get(`${endpoints.shiftApplications}/`, { params });
  return response;
};

export default {
  list,
};
