import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_SHIFTS_LIST = gql`
  query getShifts(
    $staffRequestEmploymentType: [String]
    $staffRequestStatus: [String]
    $first: Int
    $after: String
    $offset: Int
    $location: String
    $orderBy: [String]
    $statusIn: [String]
    $statusNotIn: [String]
  ) {
    shifts(
      staffRequestEmploymentType: $staffRequestEmploymentType
      staffRequestStatus: $staffRequestStatus
      first: $first
      after: $after
      offset: $offset
      location: $location
      orderBy: $orderBy
      statusIn: $statusIn
      statusNotIn: $statusNotIn
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          startTime
          endTime
          attendanceCount
          waitlistedCount
          staffRequired
          status
          hasMissingRatings
          staffRequest {
            title
            status
            vipWorkersOnly
            position {
              name
            }
          }
          position {
            name
          }
          location {
            name
          }
          attendances {
            id
            status
          }
        }
      }
    }
  }
`;

export const GET_SHIFT = gql`
  query getShift($shiftId: Int!) {
    shift(shiftId: $shiftId) {
      id
      startTime
      endTime
      staffRequired
      staffRequestId
      status
      location {
        name
      }
      position {
        name
      }
      staffRequest {
        title
        status
        createdDate
        paused
        description
        client {
          country {
            code
            name
          }
        }
        vipWorkersOnly
      }
      waitlistedCount
      attendances {
        createdDate
        status
        partner {
          id
          firstName
          lastName
          image
          statistics {
            totalDaysWorked
          }
          shiftsRating
          mobile
        }
      }
    }
  }
`;
