import { capitalize } from 'lodash';
import { timesheetEntryStatuses } from '../../../constants';

export const getStatusLabelForTimesheetEntry = timesheetEntry => {
  const { status } = timesheetEntry;
  const supervisorConfirmed = timesheetEntry?.supervisor_confirmed || timesheetEntry?.supervisorConfirmed;
  const cleanStatus = timesheetEntry.status.replace('_', '');

  if (status === timesheetEntryStatuses.CLOCKED_OUT) {
    return `${supervisorConfirmed ? 'confirmed' : 'unconfirmed'}${capitalize(cleanStatus)}`;
  }
  return cleanStatus;
};

export const checkUnconfirmedClockOutEntry = (supervisorConfirmed, status) =>
  !supervisorConfirmed && status === timesheetEntryStatuses.CLOCKED_OUT;
