import React from 'react';
import { GiftOutlined } from '@ant-design/icons';
import { Row, Typography, Alert, Table } from 'antd';
import moment from 'moment';
import numeral from 'numeral';

import { DEFAULT_DATE_FORMAT } from '../../../../constants';

const { Title, Text } = Typography;

const PaymentBreakDown = ({ t, staffRequest, amountDue, paymentSummary }) => {
  const summaryColumns = [
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
      className: 'normal-white-space',
    },
    { title: t('Total'), dataIndex: 'total', key: 'total', align: 'right' },
  ];

  const currency = staffRequest.client.country.currency_code;
  return (
    <Row type="flex" justify="space-between">
      <Row style={{ marginBottom: '24px' }}>
        <Title level={4}>{t('{{amountDue}} {{currency}} due', { amountDue, currency })}</Title>
        <Text type="secondary">
          {t(
            '{{wageAmount}} {{currency}} per day · {{staffRequired}} workmates · {{durationDays}} days ({{startDate}} - {{endDate}})',
            {
              wageAmount: numeral(staffRequest.wage_amount).format('0,0.00'),
              currency,
              staffRequired: staffRequest.staff_required,
              durationDays: moment(staffRequest.end_time).diff(moment(staffRequest.start_time), 'days'),
              startDate: moment(staffRequest.start_time).format(DEFAULT_DATE_FORMAT),
              endDate: moment(staffRequest.end_time).format(DEFAULT_DATE_FORMAT),
            },
          )}
        </Text>
      </Row>
      <Alert
        style={{ marginBottom: 24, width: '100%' }}
        message={<Text strong>{t('Discount applied')}</Text>}
        description={t('Order more than 10 work days and get 2 days’ wages off.')}
        type="success"
        icon={<GiftOutlined />}
        showIcon
      />
      <Table pagination={false} dataSource={paymentSummary} columns={summaryColumns} />{' '}
    </Row>
  );
};

export default PaymentBreakDown;
