import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col, Avatar, Typography } from 'antd';
import styled from 'styled-components';
import {
  CheckCircleFilled,
  EnvironmentFilled,
  ExclamationCircleFilled,
  EyeOutlined,
  HourglassOutlined,
  QrcodeOutlined,
  SolutionOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { selfieLocationStatus, timesheetEntrySources, timesheetEntryStatuses } from '../../../../constants';
import { checkUnconfirmedClockOutEntry } from '../../utils/status';
import distanceUtils from '../../../../utilities/distanceUtils';
import { colors } from '../../../../styles/colors';
import SelfieStatus from './SelfieStatus';
import Maps from '../../../../shared/components/Maps';

const { Text } = Typography;

const SelfieContainer = styled.div`
  position: relative;

  &:hover .middle {
    opacity: 1;
    background: rgb(0, 0, 0, 0.5);
  }
`;

const SelfieContainerHover = styled.div`
  border-radius: 3%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
`;

const SelfieLocationDetails = withTranslation()(
  ({ t, title, selfie, clockingLocation, workLocation, setVisibleSelfieModal, selfieAllowed, selfieStatus }) => {
    const workLocationLat = parseFloat(workLocation.lat);
    const workLocationLng = parseFloat(workLocation.lng);
    const clockingLocationLat = parseFloat(clockingLocation.lat);
    const clockingLocationLng = parseFloat(clockingLocation.lng);
    let locationMessage = t('locationNotRecorded');
    let locationMessageStatus = selfieLocationStatus.WARNING;
    if (clockingLocationLat && clockingLocationLng) {
      const distanceInKM = distanceUtils.getAbsoluteDistance(
        {
          latitude: clockingLocationLat,
          longitude: clockingLocationLng,
        },
        {
          latitude: workLocationLat,
          longitude: workLocationLng,
        },
      );

      const maxRadius = workLocation?.maximumClockInRadiusInMeters || 1000;

      if (distanceInKM > maxRadius / 1000) {
        const distance = `${distanceInKM.toFixed(2)}km`;
        locationMessage = t('awayFromLocation', { distance, maxRadius: `${maxRadius}m` });
        locationMessageStatus = selfieLocationStatus.WARNING;
      } else {
        locationMessage = t('locationWithinRange');
        locationMessageStatus = selfieLocationStatus.SUCCESS;
      }
    }
    return (
      <>
        <Row>
          <Text style={{ fontWeight: 600, fontSize: 14, color: colors.black, lineHeight: '22px' }}>
            {`${title}: ${t('worker')}`}
          </Text>
        </Row>
        <Row style={{ marginTop: '8px', marginBottom: '2px', paddingRight: '8px' }}>
          <Col span={2}>
            {locationMessageStatus === selfieLocationStatus.WARNING ? (
              <ExclamationCircleFilled style={{ color: colors.functionalWarning }} />
            ) : (
              <CheckCircleFilled style={{ color: colors.functionalSuccess }} />
            )}
          </Col>
          <Col span={22}>
            <Text style={{ fontSize: 12, color: colors.black, textWrap: 'wrap' }}>{locationMessage}</Text>
          </Col>
        </Row>
        <Row style={{ width: 240, height: 200 }}>
          {clockingLocationLat && clockingLocationLng ? (
            <div style={{ width: '100%', height: '100%' }}>
              <Maps
                centerPosition={{
                  lat: workLocationLat || 0,
                  lng: workLocationLng || 0,
                }}
                markerPositions={[
                  {
                    lat: workLocationLat || 0,
                    lng: workLocationLng || 0,
                    icon: 'red',
                  },
                  {
                    lat: clockingLocationLat || 0,
                    lng: clockingLocationLng || 0,
                  },
                ]}
                scrollWheelZoom
              />
            </div>
          ) : (
            <div
              style={{
                background: colors.componentBackground,
                width: '100%',
                height: '100%',
                padding: '53px 48.5px',
                border: `1px dashed ${colors.componentBorder}`,
              }}
            >
              <div style={{ width: 153, height: 54 }}>
                <Row style={{ display: 'flex', height: '50%', justifyContent: 'center', alignItems: 'center' }}>
                  <EnvironmentFilled style={{ fontSize: 24, color: colors.disabled }} />
                </Row>
                <Row style={{ display: 'flex', height: '50%', justifyContent: 'center', alignItems: 'center' }}>
                  <Text style={{ fontSize: 14, color: colors.disabled, lineHeight: '22px' }}>
                    {t('locationNotRecorded')}
                  </Text>
                </Row>
              </div>
            </div>
          )}
        </Row>
        {selfieAllowed && (
          <Row style={{ marginTop: 16, height: 125, width: 125 }}>
            {selfie ? (
              <SelfieContainer onClick={() => setVisibleSelfieModal({ title, selfie })}>
                <Avatar className="image" shape="square" src={selfie} size={125} icon={<UserOutlined />} alt="selfie" />
                <SelfieContainerHover className="middle">
                  <EyeOutlined style={{ color: colors.white, fontSize: 14 }} />
                  <Text style={{ fontSize: 14, color: colors.white, lineHeight: '22px', marginLeft: 5 }}>View</Text>
                </SelfieContainerHover>
              </SelfieContainer>
            ) : (
              <div
                style={{
                  background: colors.componentBackground,
                  width: '100%',
                  height: '100%',
                  border: `1px dashed ${colors.componentBorder}`,
                }}
              >
                <SelfieStatus selfieStatus={selfieStatus} />
              </div>
            )}
          </Row>
        )}
      </>
    );
  },
);

const ClockViaSPV = withTranslation()(({ t, title, supervisor, clockingSource }) => {
  const spvName = supervisor.name;
  const isQR = clockingSource === timesheetEntrySources.QR.toUpperCase();
  return (
    <>
      <Row>
        <Text style={{ fontWeight: 600, fontSize: 14, color: colors.black, lineHeight: '22px' }}>
          {`${title}: ${isQR ? `${t('qrScanned')}` : t('manualEntry')}`}
        </Text>
      </Row>
      <Row style={{ display: 'flex', marginTop: '8px', alignItems: 'center' }}>
        {isQR ? (
          <QrcodeOutlined style={{ color: colors.absoluteBlack, fontSize: 18 }} />
        ) : (
          <SolutionOutlined style={{ color: colors.absoluteBlack, fontSize: 18 }} />
        )}
        <Text style={{ fontSize: 14, color: colors.primaryText, lineHeight: '22px', marginLeft: 5 }}>
          {isQR ? t('scannedBy', { spvName }) : t('createdBy', { spvName })}
        </Text>
      </Row>
    </>
  );
});

const ClockingActivity = withTranslation()(
  ({
    title,
    supervisor,
    selfie,
    clockingSource,
    clockingLocation,
    workLocation,
    setVisibleSelfieModal,
    selfieAllowed,
    selfieStatus,
  }) => {
    return !supervisor ? (
      <SelfieLocationDetails
        title={title}
        selfie={selfie}
        clockingLocation={clockingLocation}
        workLocation={workLocation}
        setVisibleSelfieModal={setVisibleSelfieModal}
        selfieAllowed={selfieAllowed}
        selfieStatus={selfieStatus}
      />
    ) : (
      <ClockViaSPV title={title} supervisor={supervisor} clockingSource={clockingSource} />
    );
  },
);

export default withTranslation()(({ t, timesheetEntry, setVisibleSelfieModal, setHeaderWarnings }) => {
  const {
    clockInTime,
    clockInSupervisor,
    clockInSelfie,
    clockInLatitude,
    clockInLongitude,
    clockInSource,
    clockOutTime,
    clockOutSupervisor,
    clockOutSelfie,
    clockOutLatitude,
    clockOutLongitude,
    clockOutSource,
    supervisorConfirmed,
    clockInSelfieStatus,
    clockOutSelfieStatus,
    location: { selfieAllowed, addressLatitude, addressLongitude, maximumClockInRadiusInMeters },
    status,
  } = timesheetEntry;
  const [clockInLocationWarning, setClockInLocationWarning] = useState(null);
  const [clockOutLocationWarning, setClockOutLocationWarning] = useState(null);
  const isUnconfirmedClockOutEntry = checkUnconfirmedClockOutEntry(supervisorConfirmed, status);
  const showHeaderWarning = (isUnconfirmedClockOutEntry || status === timesheetEntryStatuses.DISPUTED) && selfieAllowed;
  useEffect(() => {
    if (showHeaderWarning) {
      (function updateHeaderWarning() {
        const headerWarnings = [];
        // eslint-disable-next-line no-unused-expressions
        clockInLocationWarning && headerWarnings.push(clockInLocationWarning);
        // eslint-disable-next-line no-unused-expressions
        clockOutLocationWarning && headerWarnings.push(clockOutLocationWarning);
        // eslint-disable-next-line no-unused-expressions
        setHeaderWarnings([...new Set(headerWarnings)]);
      })();
    }
  }, [clockInLocationWarning, clockOutLocationWarning, setHeaderWarnings, showHeaderWarning]);
  return (
    <>
      <Col span={12}>
        {clockInTime && (
          <ClockingActivity
            title={t('clockIn')}
            supervisor={clockInSupervisor}
            selfie={clockInSelfie}
            clockingSource={clockInSource}
            clockingLocation={{
              lat: clockInLatitude,
              lng: clockInLongitude,
            }}
            workLocation={{
              lat: addressLatitude,
              lng: addressLongitude,
              maximumClockInRadiusInMeters,
            }}
            setVisibleSelfieModal={setVisibleSelfieModal}
            setHeaderWarnings={setClockInLocationWarning}
            selfieAllowed={selfieAllowed}
            selfieStatus={clockInSelfieStatus}
          />
        )}
      </Col>
      <Col span={12}>
        {clockOutTime ? (
          <ClockingActivity
            title={t('clockOut')}
            supervisor={clockOutSupervisor}
            selfie={clockOutSelfie}
            clockingSource={clockOutSource}
            clockingLocation={{
              lat: clockOutLatitude,
              lng: clockOutLongitude,
            }}
            workLocation={{
              lat: addressLatitude,
              lng: addressLongitude,
              maximumClockInRadiusInMeters,
            }}
            setVisibleSelfieModal={setVisibleSelfieModal}
            setHeaderWarnings={setClockOutLocationWarning}
            selfieAllowed={selfieAllowed}
            selfieStatus={clockOutSelfieStatus}
          />
        ) : (
          <>
            <Row>
              <Text style={{ fontWeight: 600, fontSize: 14, color: colors.disabled, lineHeight: '22px' }}>
                {`${t('clockOut')}: ${t('pending')}`}
              </Text>
            </Row>
            <Row style={{ display: 'flex', marginTop: '8px', alignItems: 'center' }}>
              <HourglassOutlined style={{ color: colors.disabled, fontSize: 18 }} />
              <Text style={{ fontSize: 14, color: colors.disabled, lineHeight: '22px', marginLeft: 5 }}>
                {t('pedingClockout')}
              </Text>
            </Row>
          </>
        )}
      </Col>
    </>
  );
});
