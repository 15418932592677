import React from 'react';
import { Row, Divider, Col, Typography, Select } from 'antd';
import { withTranslation } from 'react-i18next';
import BulkAssignButtonModal from './BulkAssignButtonModal';
import { assignmentStatuses, WEEKLY_DATE_RANGE } from '../../../constants';
import TreeCheckboxFilter from '../../../shared/components/TreeCheckboxFilter';

const { Text } = Typography;

const AssignmentFilterAndAction = ({
  t,
  loading,
  timezone,
  schedules,
  dayDates,
  employments,
  selectedWorkerCount,
  scheduleFilterValue,
  statusFilterValue,
  searchFilter,
  onBulkAssign,
  onScheduleChange,
  onStatusChange,
  onSearchChange,
  selectedDateRange,
}) => {
  const scheduleOptions = schedules.map(schedule => {
    const option = {
      title: schedule.name,
      key: `${schedule.id}`,
      value: `${schedule.id}`,
    };

    return option;
  });

  const isWeekly = selectedDateRange === WEEKLY_DATE_RANGE;
  const additionalStatusOption = isWeekly
    ? assignmentStatuses.NO_SHIFT_THIS_WEEK
    : assignmentStatuses.NO_SHIFT_THIS_MONTH;
  const statusOptions = [
    {
      title: t(assignmentStatuses.CONFIRMED),
      key: assignmentStatuses.CONFIRMED,
      value: assignmentStatuses.CONFIRMED,
    },
    {
      title: t(assignmentStatuses.UNCONFIRMED),
      key: assignmentStatuses.UNCONFIRMED,
      value: assignmentStatuses.UNCONFIRMED,
    },
    {
      title: t(assignmentStatuses.LEAVE),
      key: assignmentStatuses.LEAVE,
      value: assignmentStatuses.LEAVE,
    },
    {
      title: t(assignmentStatuses.NO_SHOW),
      key: assignmentStatuses.NO_SHOW,
      value: assignmentStatuses.NO_SHOW,
    },
    {
      title: t(additionalStatusOption),
      key: additionalStatusOption,
      value: additionalStatusOption,
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{ display: 'flex' }}>
        <Col>
          <Text style={{ marginRight: '8px', color: '#A2A2A2' }}>{t('filters')}</Text>
          <TreeCheckboxFilter
            label={t('schedule')}
            placeholder={t('schedule')}
            treeData={scheduleOptions}
            checkedKeys={scheduleFilterValue}
            showActionButtons
            onApply={onScheduleChange}
            expandable={false}
          />
          <TreeCheckboxFilter
            label={t('assignmentStatus')}
            placeholder={t('assignmentStatus')}
            treeData={statusOptions}
            checkedKeys={statusFilterValue}
            showActionButtons
            onApply={onStatusChange}
            expandable={false}
          />
        </Col>
        <Col>
          <Select
            allowClear
            mode="multiple"
            style={{ width: '360px' }}
            placeholder={t('searchWorker')}
            onChange={onSearchChange}
            optionLabelProp="label"
            optionFilterProp="children"
            value={searchFilter}
            disabled={loading}
          >
            {employments.map(employment => (
              <Select.Option
                key={employment.partner.id}
                value={employment.partner.id}
                label={`${employment.partner.first_name} ${employment.partner.last_name}`}
              >
                {`${employment.partner.first_name} ${employment.partner.last_name} ${
                  employment.partner.identification_number ? `(${employment.partner.identification_number})` : ''
                } `}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col>
          <BulkAssignButtonModal
            weekdayDates={dayDates}
            selectedWorkerCount={selectedWorkerCount}
            schedules={schedules}
            onBulkAssign={onBulkAssign}
            timezone={timezone}
            disabled={!isWeekly}
          />
        </Col>
      </Row>
    </>
  );
};

export default withTranslation()(AssignmentFilterAndAction);
