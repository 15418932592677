import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, AutoComplete } from 'antd';

class AddressAutocomplete extends React.Component {
  state = {
    autocompleteService: new window.google.maps.places.AutocompleteService(),
  };

  setPlacePredictions = request => {
    this.state.autocompleteService.getPlacePredictions(request, places => {
      this.setState({ places });
    });
  };

  handleFocus = () => {
    // update places with current field value on focus
    const address = this.props.getFieldValue('street_1');
    if (!address) return;
    const request = {
      input: address,
      componentRestrictions: { country: this.props.countryCode },
    };
    this.setPlacePredictions(request);
  };

  handleSearch = value => {
    const request = {
      input: value,
      componentRestrictions: { country: this.props.countryCode },
    };
    this.setPlacePredictions(request);
  };

  handleSelect = value => {
    const selectedObj = this.state.places.filter(place => place.description === value);
    this.props.onLocationSelect(selectedObj[0]);
  };

  getAddressInitialValue = location => {
    if (location.address) {
      return location.address.street_1;
    }
    return undefined;
  };

  render() {
    const { t, location, getFieldDecorator, showError } = this.props;
    const { places } = this.state;

    return (
      <>
        <Form.Item label={t('Address')} style={{ marginBottom: '16px', paddingBottom: 0 }}>
          {getFieldDecorator('street_1', {
            rules: [
              { required: !showError && true, message: t('Please input a street') },
              {
                validator(rule, value, callback) {
                  if (showError) {
                    callback(`${t('addressError')}`);
                  }
                  callback();
                },
              },
            ],
            initialValue: location && this.getAddressInitialValue(location),
          })(
            <AutoComplete
              dataSource={places ? places.map(place => place.description) : []}
              onFocus={this.handleFocus}
              onSearch={this.handleSearch}
              onSelect={this.handleSelect}
            >
              <Input.Search placeholder={t('searchAndSelectAddress')} />
            </AutoComplete>,
          )}
        </Form.Item>
      </>
    );
  }
}

export default AddressAutocomplete;
