import { minBy, maxBy, find } from 'lodash';
import moment from 'moment-timezone';
import { derivedWorkingStatus, staffRequestContractStatus, employmentStatuses } from '../constants';
import absenceDetailsUtils from './absenceDetailsUtils';

// special localized state for attendance page and shifts page
// doing || for every variable since we have rest date is using snake_case and graphQL date is using camelCase
const getDerivedWorkingStatus = (date, employment) => {
  const relativeDate = moment(date);
  const employmentAbsenceDetails = employment?.absence_details || employment.absenceDetails;
  const absenceDetail = absenceDetailsUtils.getAbsenceDetail(employmentAbsenceDetails, date);
  if (absenceDetail) {
    return derivedWorkingStatus.ABSENT;
  }

  const employmentContractRequired = employment?.contract_required || employment?.contractRequired;
  if (employment && !employmentContractRequired) {
    const employmentStartDate = employment?.start_date || employment?.startDate;
    const employmentEndDate = employment?.end_date || employment?.endDate;
    if (relativeDate.isBefore(employmentStartDate, 'day')) {
      return derivedWorkingStatus.EMPLOYMENT_NOT_STARTED;
    }
    if (employmentEndDate && relativeDate.isAfter(employmentEndDate, 'day')) {
      return derivedWorkingStatus.RESIGNED;
    }
    return derivedWorkingStatus.WORKING;
  }

  const { contracts } = employment;
  const signedContracts = contracts.filter(
    contract => contract.status.toLowerCase() === staffRequestContractStatus.SIGNED.toLocaleLowerCase(),
  );

  if (signedContracts.length === 0) {
    return derivedWorkingStatus.CONTRACT_NOT_SIGNED;
  }

  const earliestContract = minBy(signedContracts, contract => {
    const contractStartDate = contract?.start_date || contract?.startDate;
    return moment(contractStartDate);
  });
  const latestContract = maxBy(signedContracts, contract => {
    const contractEndDate = contract?.end_date || contract?.endDate;
    return moment(contractEndDate);
  });

  const earliestContractStartDate = earliestContract?.start_date || earliestContract?.startDate;
  const latestContractEndDate = latestContract?.end_date || latestContract?.endDate;
  const employmentEndDate = employment?.end_date || employment?.endDate;

  if (relativeDate.isBefore(earliestContractStartDate, 'day')) {
    return derivedWorkingStatus.EMPLOYMENT_NOT_STARTED;
  }

  if (employmentEndDate && relativeDate.isAfter(employmentEndDate, 'day')) {
    return derivedWorkingStatus.RESIGNED;
  }

  if (relativeDate.isAfter(latestContractEndDate)) {
    if (employment.status.toLocaleLowerCase() === employmentStatuses.CANCELLED.toLowerCase()) {
      return derivedWorkingStatus.RESIGNED;
    }
    const hasFutureUnsignedContract = find(contracts, contract => {
      const contractEndDate = contract?.end_date || contract?.endDate;
      return (
        contract.status.toLocaleLowerCase() === staffRequestContractStatus.PENDING_SIGNATURE.toLocaleLowerCase() &&
        relativeDate.isSameOrBefore(moment(contractEndDate))
      );
    });
    if (hasFutureUnsignedContract) {
      return derivedWorkingStatus.CONTRACT_NOT_SIGNED;
    }
    return derivedWorkingStatus.EMPLOYMENT_ENDED; // default case
  }
  // is within employment period
  const hasValidSignedContractForDate = find(contracts, contract => {
    const contractStartDate = contract?.start_date || contract?.startDate;
    const contractEndDate = contract?.end_date || contract?.endDate;
    return (
      contract.status.toLowerCase() === staffRequestContractStatus.SIGNED.toLowerCase() &&
      relativeDate.isBetween(moment(contractStartDate), moment(contractEndDate), 'day', '[]')
    );
  });
  if (!hasValidSignedContractForDate) {
    return derivedWorkingStatus.ON_BREAK;
  }
  return derivedWorkingStatus.WORKING;
};

export default { getDerivedWorkingStatus };
