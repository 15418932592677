import React from 'react';
import { Modal, Row, Col, Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../styles/colors';
import jobTypeGigImage from '../../../assets/images/job-type-gig.png';
import jobTypeRegularImage from '../../../assets/images/job-type-regular.png';
import { jobPostTypes } from '../../../constants';
import routes from '../../../routes';

const JobTypeCard = ({ src, title, description, onClick }) => {
  return (
    <Card hoverable cover={<img alt={title} src={src} />} onClick={onClick} size="small">
      <Typography.Title level={5}>{title}</Typography.Title>
      <Typography.Paragraph style={{ color: colors.darkGrey, marginBottom: 0, height: 70 }}>
        {description}
      </Typography.Paragraph>
    </Card>
  );
};

const SelectJobTypeModal = ({ visible, onCancel, history }) => {
  const { t } = useTranslation();

  const handleToCreateSr = jobType => {
    if (jobType === jobPostTypes.GIG) {
      history.push(routes.createGig);
    } else {
      history.push(routes.createStaffRequest);
    }
  };

  return (
    <Modal
      title={t('selectJobTypeModalTitle')}
      visible={visible}
      onCancel={onCancel}
      centered
      destroyOnClose
      footer={null}
      width={600}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <JobTypeCard
            src={jobTypeRegularImage}
            title={t('jobTypeRegular')}
            description={t('jobTypeRegularDescription')}
            onClick={() => handleToCreateSr(jobPostTypes.REGULAR)}
          />
        </Col>
        <Col xs={24} md={12}>
          <JobTypeCard
            src={jobTypeGigImage}
            title={t('jobTypeGig')}
            description={t('jobTypeGigDescription')}
            onClick={() => handleToCreateSr(jobPostTypes.GIG)}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default SelectJobTypeModal;
