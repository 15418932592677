/* eslint-disable no-shadow */
import React from 'react';
import { matchPath, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { MenuOutlined, MessageOutlined } from '@ant-design/icons';
import { Row, Col, Dropdown, Button, Menu, Typography, Badge } from 'antd';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import staffRequestUtils from '../../utilities/staffRequestUtils';
import TopNavDropdown from './TopNavDropdown';
import routes from '../../routes';
import BrandLogo from '../components/BrandLogo';
import { colors } from '../../styles/colors';
import { FEATURE_FLAGS, clientTypes, roles } from '../../constants';
import VERSIONNAME from '../../versionname';

const menuNav = [
  { name: 'Staff requests', to: routes.staffRequests },
  { name: 'workforce', to: routes.workforce },
  { name: 'shifts', to: routes.shifts },
  { name: 'Attendance', to: routes.attendance },
  { name: 'Analytics', to: routes.analytics },
  // Add new menus here
  // Nested menus should be in format below
  // {
  //   name: 'Workers',
  //   children: [
  //     { name: 'Workers', to: routes.partners },
  //     { name: 'Payment reports', to: routes.paymentReports },
  //     { name: 'Payment items', to: routes.paymentItems },
  //   ],
  // }
];

const getListOfRoutesInMenu = () => {
  return menuNav.flatMap(item => {
    if (item.children) {
      return item.children.map(childrenItem => childrenItem.to);
    }
    return item.to;
  });
};

const NavMenu = ({
  t,
  selectedKeys,
  mobile = false,
  username,
  userRole,
  clientType,
  chatEnabledAndAdmin,
  chatVisible,
  messageCount,
  chatOnClick,
}) => {
  let menuNavItems = [...menuNav];
  const isWorkmateAccount = staffRequestUtils.isWorkmateAccount(username);
  const staffRequestsChildren = [
    { name: 'Staff requests', to: routes.staffRequests, show: true },
    { name: 'staffRequestTemplates', to: routes.staffRequestTemplates, show: true },
    {
      name: 'Application Forms',
      to: routes.staffRequestForms,
      show: isWorkmateAccount,
    },
  ];
  // add children to staff requests nav if user is workmate account
  menuNavItems = menuNavItems.map(nav =>
    nav.to === routes.staffRequests ? { ...nav, children: staffRequestsChildren.filter(item => item.show) } : nav,
  );

  // remove jobs menu for supervisor role
  if (userRole === roles.SUPERVISOR) {
    menuNavItems = menuNavItems.filter(menuNavItem => menuNavItem.to !== routes.staffRequests);
  }

  // self service client can only see jobs and attendance
  if (clientType === clientTypes.SELF_SERVE) {
    menuNavItems = menuNavItems.filter(
      menuNavItem => menuNavItem.to === routes.staffRequests || menuNavItem.to === routes.attendance,
    );
  }

  return (
    <>
      <Row
        type="flex"
        justify="space-around"
        gutter={8}
        className={mobile && 'v2-top-nav-menu-dropdown'}
        style={{ alignItems: 'center' }}
      >
        {chatEnabledAndAdmin && (
          <ChatButton t={t} chatVisible={chatVisible} messageCount={messageCount} chatOnClick={chatOnClick} mobile />
        )}
        {menuNavItems.map(({ name, to, children }, index) => {
          const selected = to === selectedKeys[0];
          if (children && children.length > 1) {
            const menu = (
              <Menu className="v2-user-dropdown-menu" style={{ marginTop: 0 }}>
                {children.map((child, index) => (
                  <Menu.Item key={index}>
                    <Link to={child.to}>{t(child.name)}</Link>
                  </Menu.Item>
                ))}
              </Menu>
            );
            return (
              <Dropdown overlay={menu} trigger={['click']} key={index}>
                <Col>
                  <Typography.Text className={selected ? 'v2-top-nav-menu-selected' : 'v2-top-nav-menu'}>
                    <span>{t(name)}</span>
                  </Typography.Text>
                </Col>
              </Dropdown>
            );
          }
          return (
            <Row key={index}>
              <Link to={to} className={selected ? 'v2-top-nav-menu-selected' : 'v2-top-nav-menu'}>
                <span>{t(name)}</span>
              </Link>
            </Row>
          );
        })}
      </Row>
    </>
  );
};

const ChatButton = ({ t, chatVisible, messageCount, chatOnClick, mobile }) => {
  return (
    <Row style={mobile && { padding: '20px 16px 16px 0px' }}>
      <Badge count={messageCount} overflowCount={99} style={{ marginRight: '15px' }}>
        <Button
          className={`chat-list-button${chatVisible ? ' active' : ''}`}
          onClick={chatOnClick}
          ghost
          style={{ marginRight: '15px' }}
        >
          <MessageOutlined /> {t('chat')}
        </Button>
      </Badge>
    </Row>
  );
};

const TopNavMenu = ({
  t,
  history,
  username,
  userRole,
  clientName,
  clientType,
  userLogo,
  chatEnabledAndAdmin,
  chatVisible,
  messageCount,
  chatOnClick,
  logoutUser,
  managers,
  initializeUser,
}) => {
  const listOfRoutes = getListOfRoutesInMenu();
  const selectedKeys = listOfRoutes.filter(item => matchPath(history.location.pathname, { path: item }));
  const showClientDashboardVersion = useFeatureFlagEnabled(FEATURE_FLAGS.KEYS.SHOW_CLIENT_DASHBOARD_VERSION);

  return (
    <Row style={{ width: '100%' }}>
      {/* Website navbar */}
      <Col xs={0} sm={0} md={0} lg={0} xl={24} xxl={24}>
        <Row justify="space-between" align="middle" style={{ height: '64px' }}>
          <Col>
            <Row justify="center" align="middle">
              <BrandLogo onClick={() => history.push(routes.home)} style={{ width: '120px' }} />
              {showClientDashboardVersion && (
                <Typography.Text style={{ color: 'white' }}>{VERSIONNAME}</Typography.Text>
              )}{' '}
            </Row>
          </Col>
          <Col>
            <NavMenu
              t={t}
              selectedKeys={selectedKeys}
              username={username}
              userRole={userRole}
              clientType={clientType}
              chatEnabledAndAdmin={chatEnabledAndAdmin}
              chatVisible={chatVisible}
              messageCount={messageCount}
              chatOnClick={chatOnClick}
            />
          </Col>
          <Col>
            <TopNavDropdown
              t={t}
              clientName={clientName}
              history={history}
              logoutUser={logoutUser}
              userLogo={userLogo}
              managers={managers}
              initializeUser={initializeUser}
            />
          </Col>
        </Row>
      </Col>
      {/* Mobile navbar */}
      <Col xs={24} sm={24} md={24} lg={24} xl={0} xxl={0}>
        <Row justify="space-between" align="middle" style={{ height: '64px' }}>
          <Col>
            {messageCount > 0 ? (
              <Badge count={messageCount} overflowCount={99} style={{ marginRight: '15px' }}>
                <Dropdown
                  overlay={
                    <NavMenu
                      t={t}
                      selectedKeys={selectedKeys}
                      mobile
                      username={username}
                      userRole={userRole}
                      clientType={clientType}
                      chatEnabledAndAdmin={chatEnabledAndAdmin}
                      chatVisible={chatVisible}
                      messageCount={messageCount}
                      chatOnClick={chatOnClick}
                    />
                  }
                  trigger={['click']}
                >
                  <Button type="link">
                    <MenuOutlined style={{ fontSize: 16, color: colors.white }} />
                  </Button>
                </Dropdown>
              </Badge>
            ) : (
              <Dropdown
                overlay={
                  <NavMenu
                    t={t}
                    selectedKeys={selectedKeys}
                    mobile
                    username={username}
                    userRole={userRole}
                    clientType={clientType}
                    chatEnabledAndAdmin={chatEnabledAndAdmin}
                    chatVisible={chatVisible}
                    messageCount={messageCount}
                    chatOnClick={chatOnClick}
                  />
                }
                trigger={['click']}
              >
                <Button type="link">
                  <MenuOutlined style={{ fontSize: 16, color: colors.white }} />
                </Button>
              </Dropdown>
            )}
            <BrandLogo onClick={() => history.push(routes.home)} style={{ width: '120px' }} />
          </Col>
          <Col>
            <TopNavDropdown
              t={t}
              clientName={clientName}
              history={history}
              logoutUser={logoutUser}
              userLogo={userLogo}
              managers={managers}
              initializeUser={initializeUser}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default withRouter(TopNavMenu);
