import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Row, Typography, Col, Tabs, message, DatePicker } from 'antd';

import * as userActions from '../../redux/user';
import CostTab from './CostTab';
import PerformanceTab from './PerformanceTab';
import FulfilmentTab from './FulfilmentTab';
import ReportTab from './ReportTab';
import FilterWithSearch from '../../shared/components/FilterWithSearch';
import { checkAccess } from '../../shared/access/Access';
import { permissions } from '../../shared/access/accessConfig';
import authApi from '../../services/authApi';

import { colors } from '../../styles/colors';
import { analyticsTabs, ANALYTICS_DATE_FORMAT } from '../../constants';

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

class AnalyticsPage extends React.Component {
  state = {
    currentActiveTabKey: analyticsTabs.FULFILMENT,
    pageTabs: [],
    locationIds: [],
    positionIds: [],
    clientId: this.props.clientId.toString(),
    granularity: 'day',
    timePeriod: [
      moment()
        .subtract(1, 'months')
        .format(ANALYTICS_DATE_FORMAT),
      moment().format(ANALYTICS_DATE_FORMAT),
    ],
  };

  async componentDidMount() {
    const { t, userUpdate } = this.props;
    try {
      const response = await authApi.fetchCubeJsToken();
      const cubeJsToken = response.token;
      userUpdate({ cubeJsToken });
      this.getAnalyticsTabs();
      if (checkAccess(permissions.analyticsViewCosts)) {
        this.setState({ currentActiveTabKey: analyticsTabs.COSTS });
      }
    } catch {
      message.error(t('commonErrorMessage'));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.timePeriod !== this.state.timePeriod ||
      prevState.locationIds !== this.state.locationIds ||
      prevState.positionIds !== this.state.positionIds ||
      prevState.granularity !== this.state.granularity
    ) {
      this.getAnalyticsTabs();
    }
  }

  getAnalyticsTabs = () => {
    const { locationIds, positionIds, clientId, granularity, timePeriod } = this.state;
    const { t } = this.props;
    const pageTabs = [
      {
        tabTitle: t('fulfilment'),
        key: analyticsTabs.FULFILMENT,
        content: (
          <FulfilmentTab
            timePeriod={timePeriod}
            granularity={granularity}
            clientId={clientId}
            locationIds={locationIds}
            positionIds={positionIds}
          />
        ),
      },
      {
        tabTitle: t('Performance'),
        key: analyticsTabs.PERFORMANCE,
        content: (
          <PerformanceTab
            timePeriod={timePeriod}
            granularity={granularity}
            clientId={clientId}
            locationIds={locationIds}
            positionIds={positionIds}
          />
        ),
      },
      {
        tabTitle: t('Report'),
        key: analyticsTabs.REPORT,
        content: (
          <ReportTab timePeriod={timePeriod} clientId={clientId} locationIds={locationIds} positionIds={positionIds} />
        ),
      },
    ];
    if (checkAccess(permissions.analyticsViewCosts)) {
      pageTabs.unshift({
        tabTitle: t('Costs'),
        key: analyticsTabs.COSTS,
        content: (
          <CostTab
            timePeriod={timePeriod}
            granularity={granularity}
            clientId={clientId}
            locationIds={locationIds}
            positionIds={positionIds}
          />
        ),
      });
    }
    this.setState({ pageTabs });
  };

  onChangeTab = nextActiveTab => {
    this.setState({ currentActiveTabKey: nextActiveTab });
  };

  // TODO Rewrite filter comp
  getLocationFilter = () => {
    const { t, locations } = this.props;
    const { locationIds } = this.state;

    const locationOptions = locations.map(loc => ({ id: loc.id, name: loc.name }));
    const totalLocationsSearched = locationIds.length;
    return {
      title: `${t('Location')} ${totalLocationsSearched > 0 ? `(${totalLocationsSearched})` : ''}`,
      options: locationOptions,
      onSelectChange: values => {
        const selectedLocationIds = values.map(({ key }) => key);
        this.onLocationPositionFilterChange('location', selectedLocationIds);
      },
      loading: false,
      placeholder: t('Search by location name'),
    };
  };

  getPositionFilter = () => {
    const { positions, t } = this.props;
    const { positionIds } = this.state;
    const totalPositionsSearched = positionIds.length;
    return {
      title: `${t('Position')} ${totalPositionsSearched > 0 ? `(${totalPositionsSearched})` : ''}`,
      options: positions,
      onSelectChange: values => {
        const selectedPositionIds = values.map(({ key }) => key);
        this.onLocationPositionFilterChange('position', selectedPositionIds);
      },
      loading: false,
      placeholder: t('searchByPositionName'),
    };
  };

  onLocationPositionFilterChange = (field, value) => {
    if (field === 'location') {
      this.setState({ locationIds: value });
    }

    if (field === 'position') {
      this.setState({ positionIds: value });
    }
  };

  onDateFilterChange = value => {
    if (!value || value.length === 0) {
      return;
    }
    const startDate = moment(value[0]).format(ANALYTICS_DATE_FORMAT);
    const endDate = moment(value[1]).format(ANALYTICS_DATE_FORMAT);
    this.setState({
      timePeriod: [startDate.toString(), endDate.toString()],
      granularity: 'day',
    });
  };

  render() {
    const { timePeriod, currentActiveTabKey, pageTabs } = this.state;
    const { t } = this.props;

    const locationFilter = this.getLocationFilter();
    const positionFilter = this.getPositionFilter();

    return (
      <>
        <Row>
          <Title level={2}>{t('Analytics')}</Title>
        </Row>
        {/* Filters */}
        <Row style={{ marginBottom: '16px' }} gutter={8}>
          <Col span={12} style={{ width: 'auto' }}>
            <RangePicker
              defaultValue={[
                moment(timePeriod[0], ANALYTICS_DATE_FORMAT),
                moment(timePeriod[1], ANALYTICS_DATE_FORMAT),
              ]}
              onChange={value => this.onDateFilterChange(value)}
            />
          </Col>

          <Col xs={12} md={4}>
            <FilterWithSearch
              loading={locationFilter.loading}
              onSearch={locationFilter.onSearch}
              title={locationFilter.title}
              options={locationFilter.options}
              onSelectChange={locationFilter.onSelectChange}
              defaultValue={locationFilter.defaultValue}
              placeholder={locationFilter.placeholder}
              optionLabelField="name"
            />
          </Col>
          <Col xs={12} md={4}>
            <FilterWithSearch
              loading={positionFilter.loading}
              onSearch={positionFilter.onSearch}
              title={positionFilter.title}
              options={positionFilter.options}
              onSelectChange={positionFilter.onSelectChange}
              defaultValue={positionFilter.defaultValue}
              placeholder={positionFilter.placeholder}
              optionLabelField="name"
            />
          </Col>
        </Row>

        {/* Tabs */}
        <section>
          <Tabs activeKey={currentActiveTabKey} onChange={this.onChangeTab}>
            {pageTabs.map(tab => {
              const { tabTitle, key, content } = tab;
              return (
                <TabPane
                  tab={
                    <Text
                      strong
                      style={{
                        fontSize: '20px',
                        color: key === currentActiveTabKey ? colors.black : colors.grey,
                      }}
                    >
                      {tabTitle}
                    </Text>
                  }
                  key={key}
                >
                  {content}
                </TabPane>
              );
            })}
          </Tabs>
        </section>
      </>
    );
  }
}

const mapStateToProps = state => ({
  clientId: state.user.clientId,
  locations: state.user.locations,
  positions: state.global.positions,
});

const mapDispatchToProps = dispatch => {
  const { userUpdate } = userActions;
  return {
    userUpdate: updatedUser => {
      dispatch(userUpdate(updatedUser));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AnalyticsPage));
