import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import { Typography, Card, Row, Col, Button, InputNumber, Checkbox, Select, Alert, message } from 'antd';
import { withTranslation, Trans } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { upperCase } from 'lodash';
import TimesheetTitleWithTooltip from './TimesheetTitleWithTooltip';
import RoundedTimeGuide from '../../../../assets/images/rounded-time-guide.svg';
import { roundingTypes } from '../../../../constants';
import clientApi from '../../../../services/clientApi';

const { Title, Text } = Typography;

const TimesheetRoundingForm = ({ t, form, client }) => {
  const [isEditTimesheetRounding, setIsEditTimesheetRounding] = useState(false);

  const { getFieldDecorator, resetFields } = form;

  const onSubmit = async e => {
    e.preventDefault();
    form.validateFieldsAndScroll(async (formErrors, values) => {
      if (!formErrors) {
        const {
          clock_in_rounding_type,
          clock_out_rounding_type,
          rounding_quantum_in_minutes,
          round_timesheets_to_shift,
        } = values;

        const response = await clientApi.updateTimeRoundingRules(client?.clientId, {
          clock_in_rounding_type,
          clock_out_rounding_type,
          rounding_quantum_in_minutes,
          round_timesheets_to_shift,
        });

        if (response) {
          message.success(t('Changes saved'));
          setIsEditTimesheetRounding(false);
        }
      }
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <Title level={3}>{t('timesheetRounding')}</Title>
      <Text type="secondary">{t('automaticallyRoundTimesheets')}</Text>
      <Card
        style={{
          marginTop: 16,
          paddingLeft: 24,
          paddingRight: 24,
          width: 745,
          boxShadow: '0 2px 8px 0 rgba(0,0,0,0.2)',
        }}
      >
        <Row>
          <Col span={14}>
            <TimesheetTitleWithTooltip title={t('globalTimesheetRounding')} />
          </Col>
          <Col span={10}>
            <div style={{ float: 'right' }}>
              {!isEditTimesheetRounding ? (
                <Button icon={<EditOutlined />} onClick={() => setIsEditTimesheetRounding(true)}>
                  {t('Edit')}
                </Button>
              ) : (
                <>
                  <Button
                    style={{ marginRight: 8 }}
                    onClick={() => {
                      resetFields();
                      setIsEditTimesheetRounding(false);
                    }}
                  >
                    {t('cancel')}
                  </Button>
                  <Button type="v2-primary" htmlType="submit">
                    {t('applyChanges')}
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: 16, display: 'inline-block' }}>
          <Text>{t('roundClockInTimes')}</Text>
          {getFieldDecorator('clock_in_rounding_type', {
            initialValue: client?.timeRoundingRules?.clock_in_rounding_type,
          })(
            <Select
              style={{ width: 160, marginLeft: 8, marginRight: 8 }}
              disabled={!isEditTimesheetRounding}
              options={[
                { value: roundingTypes.NEAREST, label: t('nearest') },
                { value: roundingTypes.DOWN, label: t('downTo') },
              ]}
            />,
          )}
          <Text>{upperCase(t('and'))}</Text>
        </Row>
        <Row style={{ marginTop: 8, marginBottom: 16, alignContent: 'center', alignItems: 'center' }}>
          <Text>{t('roundClockOutTimes')}</Text>
          {getFieldDecorator('clock_out_rounding_type', {
            initialValue: client?.timeRoundingRules?.clock_out_rounding_type,
          })(
            <Select
              style={{ width: 160, marginLeft: 8, marginRight: 8 }}
              disabled={!isEditTimesheetRounding}
              options={[
                { value: roundingTypes.NEAREST, label: t('nearest') },
                { value: roundingTypes.DOWN, label: t('downTo') },
              ]}
            />,
          )}
          {getFieldDecorator('rounding_quantum_in_minutes', {
            initialValue: client?.timeRoundingRules?.rounding_quantum_in_minutes,
          })(<InputNumber disabled={!isEditTimesheetRounding} style={{ width: 56, marginRight: 8 }} />)}
          <Text>{t('minutes')}</Text>
        </Row>
        {isEditTimesheetRounding && (
          <Alert
            message={t('timesheetChangesWarning')}
            type="warning"
            showIcon
            style={{ marginTop: 8, marginBottom: 32 }}
          />
        )}

        <TimesheetTitleWithTooltip
          title={t('specialRulesforTimesheetsWithShift')}
          tooltipText={<Trans i18nKey="specialRulesTooltip" />}
        />
        <Row style={{ marginTop: 12 }}>
          {getFieldDecorator('round_timesheets_to_shift', {
            initialValue: client?.timeRoundingRules?.round_timesheets_to_shift,
            valuePropName: 'checked',
          })(<Checkbox disabled={!isEditTimesheetRounding}>{t('applyRoundingRulesWithShift')}</Checkbox>)}
        </Row>
        <Row style={{ marginTop: 8 }}>
          <ul>
            <li>
              <Text disabled={!isEditTimesheetRounding}>{t('clockInRoundingWithShift')}</Text>
            </li>
            <li>
              <Text disabled={!isEditTimesheetRounding}>{t('clockOutRoundingWithShift')}</Text>
            </li>
          </ul>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <img src={RoundedTimeGuide} alt="Rounded time guide" style={{ width: 441, height: 177 }} />
        </Row>
        {isEditTimesheetRounding && (
          <Alert message={t('timesheetChangesWarning')} type="warning" showIcon style={{ marginTop: 8 }} />
        )}
      </Card>
    </Form>
  );
};

export default Form.create()(withTranslation()(TimesheetRoundingForm));
