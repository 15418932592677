import React from 'react';
import { Button, Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';

import WeekSelectGroup from './WeekSelectGroup';
import MonthSelectGroup from './MonthSelectGroup';
import { colors } from '../../styles/colors';
import { MONTHLY_DATE_RANGE, WEEKLY_DATE_RANGE } from '../../constants';

const ButtonGroup = Button.Group;

export default withTranslation()(
  ({ selectedDateRange, onWeekChange, onMonthChange, selectedDateStart, loading, t, shouldNavigateAway }) => {
    return (
      <Row style={{ marginBottom: '16px' }} gutter={8}>
        <Col span={4} style={{ width: 'auto' }}>
          <ButtonGroup>
            <Button
              style={
                selectedDateRange === MONTHLY_DATE_RANGE
                  ? {
                      borderColor: colors.blue,
                      backgroundColor: colors.white,
                      color: colors.blue,
                      zIndex: 1,
                    }
                  : { backgroundColor: colors.white }
              }
              onClick={() => {
                if (!shouldNavigateAway()) return;
                onMonthChange(selectedDateStart?.clone().startOf('month'));
              }}
            >
              {t('monthView')}
            </Button>
            <Button
              style={
                selectedDateRange === WEEKLY_DATE_RANGE
                  ? {
                      borderColor: colors.blue,
                      backgroundColor: colors.white,
                      color: colors.blue,
                      zIndex: 1,
                    }
                  : { backgroundColor: colors.white }
              }
              onClick={() => {
                if (!shouldNavigateAway()) return;
                onWeekChange(selectedDateStart);
              }}
            >
              {t('weekView')}
            </Button>
          </ButtonGroup>
        </Col>
        {selectedDateRange && selectedDateRange === MONTHLY_DATE_RANGE ? (
          <Col span={16}>
            <MonthSelectGroup
              selectedMonthStart={selectedDateStart?.clone().startOf('month')}
              loading={loading}
              onChange={onMonthChange}
              disableFutureDates={false}
            />
          </Col>
        ) : (
          <Col span={16}>
            <WeekSelectGroup
              selectedWeekStart={selectedDateStart}
              loading={loading}
              onChange={onWeekChange}
              disableFutureDates={false}
            />
          </Col>
        )}
      </Row>
    );
  },
);
