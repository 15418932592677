const toRadians = dec => {
  return (dec * Math.PI) / 180.0;
};

// checks and converts lat, long of coordinates from string to float and returns copy of coordinate
const validateCoordinate = coordinate => {
  let { latitude, longitude } = coordinate;
  latitude = typeof latitude === 'string' ? parseFloat(latitude) : latitude;
  longitude = typeof longitude === 'string' ? parseFloat(longitude) : longitude;
  return { latitude, longitude };
};

// calculates distance in metres between 2 coords based on haversine formula
const getAbsoluteDistance = (coordinateA, coordinateB) => {
  const { latitude: lat1, longitude: lon1 } = validateCoordinate(coordinateA);
  const { latitude: lat2, longitude: lon2 } = validateCoordinate(coordinateB);
  const R = 6371e3; // earth's radius in metres
  const φ1 = toRadians(lat1);
  const φ2 = toRadians(lat2);
  const Δφ = toRadians(lat2 - lat1);
  const Δλ = toRadians(lon2 - lon1);

  const x = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const y = 2 * Math.atan2(Math.sqrt(x), Math.sqrt(1 - x));

  return (R * y) / 1000; // Convert to km
};

export default {
  getAbsoluteDistance,
};
