import React from 'react';
import { Button, Row } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { withTranslation } from 'react-i18next';
import { MONTHLY_DATE_RANGE, WEEKLY_DATE_RANGE } from '../../../constants';
import { colors } from '../../../styles/colors';
import MonthSelectGroup from '../../../shared/components/MonthSelectGroup';
import WeekSelectGroup from '../../../shared/components/WeekSelectGroup';

export default withTranslation()(
  ({ t, onChange, selectedDateStart, loading, selectedDateRange, handleMonthSelect, handleWeekSelect }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Row span={4}>
          <ButtonGroup>
            <Button
              style={
                selectedDateRange === WEEKLY_DATE_RANGE
                  ? { backgroundColor: colors.blue, color: colors.white }
                  : { backgroundColor: colors.white }
              }
              onClick={handleWeekSelect}
            >
              {t('weekView')}
            </Button>
            <Button
              style={
                selectedDateRange === MONTHLY_DATE_RANGE
                  ? { backgroundColor: colors.blue, color: colors.white }
                  : { backgroundColor: colors.white }
              }
              onClick={handleMonthSelect}
            >
              {t('monthView')}
            </Button>
          </ButtonGroup>
        </Row>
        <Row span={20}>
          {selectedDateRange && selectedDateRange === MONTHLY_DATE_RANGE ? (
            <MonthSelectGroup
              selectedMonthStart={selectedDateStart.startOf('month')}
              loading={loading}
              onChange={onChange}
              disableFutureDates={false}
            />
          ) : (
            <WeekSelectGroup
              selectedWeekStart={selectedDateStart}
              loading={loading}
              onChange={onChange}
              disableFutureDates={false}
            />
          )}
        </Row>
      </div>
    );
  },
);
