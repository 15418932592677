/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import styled from 'styled-components';
import { first } from 'lodash';
import { CheckCircleOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Typography, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';

import { colors } from '../../../styles/colors';
import {
  derivedWorkingStatus,
  employmentTypes,
  timesheetEntryPaymentStatus,
  timesheetEntryStatuses,
  leaveEntryPaymentStatus,
} from '../../../constants';

import { getColorsForTimesheetEntryTableCell, getColorsForLeaveEntryCell } from '../utils/color';
import { getStatusLabelForTimesheetEntry } from '../utils/status';
import { getFriendlyClockInOutString } from '../../../utilities/timesheetUtils';
import { getDurationFromSecondsToHours } from '../../../utilities/durationUtils';
import employmentUtils from '../../../utilities/employmentUtils';
import absenceDetailsUtils from '../../../utilities/absenceDetailsUtils';

const EmptyTimesheet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 100px;
  &:hover {
    cursor: pointer;
  }
`;

const AttendanceCard = ({
  timesheetEntry,
  showRoundedTime,
  hasMultipleEntries,
  hasBorder,
  maxNonVoidTsEntryRowCount,
  onCellSelect,
  leaveEntry,
}) => {
  if (timesheetEntry) {
    const { clockInTime, clockOutTime, roundedClockInTime, roundedClockOutTime, paymentStatus } = timesheetEntry;

    const color = getColorsForTimesheetEntryTableCell(timesheetEntry);
    const hasPaidTimesheet = paymentStatus === timesheetEntryPaymentStatus.PAID;
    const hasOvertime = timesheetEntry?.overtimeDuration > 0;
    let timeIn = clockInTime;
    let timeOut = clockOutTime;

    if (showRoundedTime) {
      timeIn = roundedClockInTime || clockInTime;
      timeOut = roundedClockOutTime || clockOutTime;
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: maxNonVoidTsEntryRowCount > 1 ? '56px' : '100%',
          backgroundColor: color && color.secondary,
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={onCellSelect}
      >
        <div style={{ width: 5, backgroundColor: color && color.primary }} />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 8, whiteSpace: 'normal' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography.Text style={{ fontSize: 12 }}>
              {timesheetEntry.status === timesheetEntryStatuses.VOID
                ? getStatusLabelForTimesheetEntry(timesheetEntry)
                : getFriendlyClockInOutString(timeIn, timeOut)}
            </Typography.Text>
          </div>
          <div style={{ display: 'flex', marginTop: 4, alignItems: 'center' }}>
            {hasOvertime && (
              <span style={{ fontSize: 12, color: colors.darkGrey }}>
                + {getDurationFromSecondsToHours(timesheetEntry.overtimeDuration)}
              </span>
            )}
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
              {hasPaidTimesheet && (
                <CheckCircleOutlined style={{ color: colors.functionalApproved, marginBottom: 4 }} />
              )}
              {hasMultipleEntries && (
                <ExclamationCircleOutlined style={{ color: colors.functionalWarning, marginLeft: 4 }} />
              )}
            </div>
          </div>
          {hasBorder && (
            <div
              style={{
                backgroundColor: colors.greyBorder,
                width: '100%',
                height: 2,
                position: 'absolute',
                bottom: 0,
                left: 0,
              }}
            />
          )}
        </div>
      </div>
    );
  }

  if (leaveEntry) {
    const { status, paymentStatus } = leaveEntry;
    const color = getColorsForLeaveEntryCell(status);
    const hasPaidLeave = paymentStatus === leaveEntryPaymentStatus.PAID;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: maxNonVoidTsEntryRowCount > 1 ? '56px' : '100%',
          backgroundColor: color && color.secondary,
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={onCellSelect}
      >
        <div style={{ width: 5, backgroundColor: color && color.primary }} />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 8, whiteSpace: 'normal' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography.Text style={{ fontSize: 12 }}>{`🌴 ${leaveEntry.leaveEntryType.name}`}</Typography.Text>
          </div>
          <div style={{ display: 'flex', marginTop: 4, alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
              {hasPaidLeave && <CheckCircleOutlined style={{ color: colors.functionalApproved, marginBottom: 4 }} />}
              {hasMultipleEntries && (
                <ExclamationCircleOutlined style={{ color: colors.functionalWarning, marginLeft: 4 }} />
              )}
            </div>
          </div>
          {hasBorder && (
            <div
              style={{
                backgroundColor: colors.greyBorder,
                width: '100%',
                height: 2,
                position: 'absolute',
                bottom: 0,
                left: 0,
              }}
            />
          )}
        </div>
      </div>
    );
  }
};

export default withTranslation()(
  ({
    t,
    date,
    employment,
    hasMultipleEntries,
    nonVoidTimesheetEntries,
    nonVoidLeaveEntries,
    showRoundedTime,
    maxNonVoidTsEntryRowCount,
    onCellSelect,
  }) => {
    const timesheetEntry = first(nonVoidTimesheetEntries);
    const leaveEntry = first(nonVoidLeaveEntries);
    const isGig = employment?.type?.toLowerCase() === employmentTypes.GIG.value;
    if (timesheetEntry) {
      // display multiple timesheet entries for gigs
      if (isGig) {
        return nonVoidTimesheetEntries.map((nonVoidTimesheetEntry, index) => (
          <AttendanceCard
            key={nonVoidTimesheetEntry.id}
            timesheetEntry={nonVoidTimesheetEntry}
            showRoundedTime={showRoundedTime}
            hasBorder={
              nonVoidTimesheetEntries.length - 1 > index || maxNonVoidTsEntryRowCount > nonVoidTimesheetEntries.length
            }
            maxNonVoidTsEntryRowCount={maxNonVoidTsEntryRowCount}
            onCellSelect={onCellSelect}
          />
        ));
      }
      // otherwise display the first timesheet entry
      return hasMultipleEntries ? (
        <Tooltip title={t('multipleTimesheets')}>
          <AttendanceCard
            timesheetEntry={timesheetEntry}
            showRoundedTime={showRoundedTime}
            maxNonVoidTsEntryRowCount={maxNonVoidTsEntryRowCount}
            onCellSelect={onCellSelect}
            hasMultipleEntries
          />
        </Tooltip>
      ) : (
        <AttendanceCard
          timesheetEntry={timesheetEntry}
          showRoundedTime={showRoundedTime}
          maxNonVoidTsEntryRowCount={maxNonVoidTsEntryRowCount}
          onCellSelect={onCellSelect}
        />
      );
    }

    if (!timesheetEntry && leaveEntry) {
      return hasMultipleEntries ? (
        <Tooltip title={t('multipleTimesheets')}>
          <AttendanceCard
            leaveEntry={leaveEntry}
            maxNonVoidTsEntryRowCount={maxNonVoidTsEntryRowCount}
            onCellSelect={onCellSelect}
            hasMultipleEntries
          />
        </Tooltip>
      ) : (
        <AttendanceCard
          leaveEntry={leaveEntry}
          maxNonVoidTsEntryRowCount={maxNonVoidTsEntryRowCount}
          onCellSelect={onCellSelect}
        />
      );
    }

    if (!employment) return;
    // cases without timesheetEntry
    const derivedStatus = employmentUtils.getDerivedWorkingStatus(date, employment);
    const absenceDetail = absenceDetailsUtils.getAbsenceDetail(employment.absenceDetails, date);

    if (derivedStatus === derivedWorkingStatus.WORKING) {
      return (
        <EmptyTimesheet onClick={onCellSelect} style={{ height: maxNonVoidTsEntryRowCount > 1 ? '100%' : '56px' }}>
          <PlusOutlined />
        </EmptyTimesheet>
      );
    }

    let text = t('notEmployed');
    let lineColor = colors.transparent;
    let boxColor = colors.componentBackground;

    if (derivedStatus === derivedWorkingStatus.ABSENT) {
      text = absenceDetailsUtils.getAbsenceDetailReasonText(absenceDetail?.manager_reason, t);
      lineColor = colors.textTitle;
      boxColor = colors.disabled;
    }

    const showBorderLine = text !== t('notEmployed');

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          width: '100%',
          backgroundColor: boxColor,
        }}
      >
        {showBorderLine && <div style={{ width: 5, backgroundColor: lineColor }} />}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: 4,
            whiteSpace: 'normal',
          }}
        >
          <Typography.Text style={{ fontSize: 12 }}>{text}</Typography.Text>
        </div>
      </div>
    );
  },
);
