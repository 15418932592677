import React from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Typography, Row, Col, Tooltip, Input, Alert } from 'antd';

import visaLogo from '../../assets/images/visa_logo.svg';
import mastercardLogo from '../../assets/images/mastercard_logo.svg';

const { Text } = Typography;
class CreditCardForm extends React.Component {
  componentDidMount() {
    this.props.setPaymentForm(this.props.form);
  }

  limitCharLength = limit => val => {
    if (val) {
      return val.slice(0, limit);
    }
  };

  formatExpDate = value => {
    if (!value) return;
    if (value.includes('/')) return value.slice(0, 5);

    // Adds "/" character
    let formattedValue = '';
    for (let i = 0; i < Math.min(value.length, 5); i += 1) {
      if (i === 2 && value[i] !== '/') {
        formattedValue += '/';
      }
      formattedValue += value[i];
    }
    return formattedValue;
  };

  // There should be a space every 4 characters. Add them if there isn't.
  formatCardNumber = value => {
    if (!value) {
      return;
    }

    let valueWithSpaces = '';
    for (let i = 0; i < value.length; i += 1) {
      if (i === 19) {
        break;
      }
      // Should be a space in these position (4, 9, 14)
      if ((i === 4 || i === 9 || i === 14) && value[i] !== ' ') {
        // eslint-disable-next-line no-param-reassign
        value = `${value.slice(0, i)} ${value.slice(i, value.length)}`;
      }
      valueWithSpaces += value[i];
    }
    return valueWithSpaces;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { errorMessage, t } = this.props;
    return (
      <>
        {errorMessage.length > 0 && (
          <Alert
            showIcon
            message={t('Credit card declined')}
            type="error"
            description={errorMessage}
            style={{ marginBottom: '24px' }}
          />
        )}
        <Form layout="vertical" hideRequiredMark className="v2-form-wrapper">
          {/* Card number */}
          <Row type="flex" align="middle" justify="space-between">
            <Col xs={18} sm={20}>
              <Form.Item hasFeedback label={t('Card number')} colon={false} style={{ width: '100%' }}>
                {getFieldDecorator('cardNumber', {
                  rules: [{ required: true, message: t('fieldRequired.') }],
                  normalize: this.formatCardNumber,
                })(<Input placeholder="XXXX XXXX XXXX XXXX" />)}
              </Form.Item>
            </Col>
            <Col xs={6} sm={4} style={{ textAlign: 'right' }}>
              <img style={{ marginRight: '8px' }} src={visaLogo} alt="Visa logo" />
              <img src={mastercardLogo} alt="Mastercard logo" />
            </Col>
          </Row>

          {/* Expiration date & CVC */}
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                label={t('Expiration date')}
                colon={false}
                style={{ width: '100%', marginBottom: '16px' }}
              >
                {getFieldDecorator('expDate', {
                  rules: [{ required: true, message: t('fieldRequired.') }],
                  normalize: this.formatExpDate,
                })(<Input placeholder="MM/YY" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hasFeedback
                label={
                  <span>
                    {t('Secure code')}
                    <Tooltip placement="top" title={t('3 digit number on the back of your card')}>
                      <InfoCircleTwoTone style={{ fontSize: '16px', marginLeft: '4px' }} />
                    </Tooltip>
                  </span>
                }
                colon={false}
                style={{ width: '100%', marginBottom: '16px' }}
              >
                {getFieldDecorator('cvc', {
                  rules: [{ required: true, message: t('fieldRequired.') }, { max: 3 }],
                  normalize: this.limitCharLength(3),
                })(<Input type="password" />)}
              </Form.Item>
            </Col>
          </Row>

          {/* Card name */}
          <Form.Item
            hasFeedback
            label={t('Name on card')}
            colon={false}
            style={{ width: '100%', marginBottom: '16px' }}
          >
            {getFieldDecorator('cardHolderName', {
              rules: [
                { type: 'string', message: t('Must only contain digits.') },
                { required: true, message: t('fieldRequired.') },
              ],
            })(<Input />)}
          </Form.Item>

          <Text type="secondary">
            {t(
              'You will not be charged until Workmates clock in and you’ve approved their timesheets. Charges for approved entries are made every Wednesday morning.',
            )}
          </Text>
        </Form>
      </>
    );
  }
}

export default Form.create({ name: 'Credit card form' })(CreditCardForm);
