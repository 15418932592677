import base from './base';
import { endpoints, readonlyEndpoints } from '../config';

const list = async params => {
  const response = await base.api.get(`${readonlyEndpoints.timesheetEntries}/`, { params });
  return response;
};

const attendanceList = async params => {
  const response = await base.api.get(`${readonlyEndpoints.timesheetEntries}/attendance-list/`, { params });
  return response;
};

const fetchTimesheetEntries = async params => {
  const response = await base.api.get(`${readonlyEndpoints.timesheetEntries}/`, { params });
  return response;
};

const create = async data => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/`, data);
  return response;
};

const update = async (id, data) => {
  const response = await base.api.patch(`${endpoints.timesheetEntries}/${id}/`, data);
  return response;
};

const confirm = async id => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/${id}/confirm/`);
  return response;
};

const resolve = async id => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/${id}/resolve/`);
  return response;
};

const approve = async id => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/${id}/approve/`);
  return response;
};

const bulkApprove = async params => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/approve/`, null, { params });
  return response;
};

const bulkConfirm = async params => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/confirm/`, null, { params });
  return response;
};

const bulkVoid = async (ids, voided_reason) => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/void/?id=${ids}`, voided_reason);
  return response;
};

const dispute = async (id, disputed_reason) => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/${id}/dispute/`, { disputed_reason });
  return response;
};

const voidItem = async (id, voided_reason) => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/${id}/void/`, { voided_reason });
  return response;
};

const fetchDisputeReasons = async () => {
  const response = await base.api.get(`${endpoints.timesheetEntries}/dispute-reasons/`);
  return response;
};

const fetchVoidReasons = async () => {
  const response = await base.api.get(`${endpoints.timesheetEntries}/void-reasons/`);
  return response;
};

const getStatistics = async params => {
  const response = await base.api.get(`${readonlyEndpoints.timesheetEntries}/statistics/`, { params });
  return response;
};

const getStatisticsForDateRange = async params => {
  const response = await base.api.get(`${readonlyEndpoints.timesheetEntries}/statistics-for-date-range/`, { params });
  return response;
};

const downloadAttendanceSummary = async params => {
  const response = await base.api.get(`${readonlyEndpoints.timesheetEntries}/download-attendance-summary/`, { params });
  return response;
};

const getBulkApproveCount = async params => {
  const response = await base.api.get(`${endpoints.timesheetEntries}/bulk-approve-count/`, { params });
  return response;
};

export default {
  list,
  attendanceList,
  create,
  update,
  confirm,
  resolve,
  approve,
  bulkApprove,
  bulkConfirm,
  bulkVoid,
  dispute,
  voidItem,
  fetchTimesheetEntries,
  fetchDisputeReasons,
  fetchVoidReasons,
  getStatistics,
  downloadAttendanceSummary,
  getStatisticsForDateRange,
  getBulkApproveCount,
};
