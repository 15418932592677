import React, { useState, useRef } from 'react';
import { Card, Typography, Row, Col, Image, Divider } from 'antd';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';
import remarkBreaks from 'remark-breaks';
import { colors } from '../../../styles/colors';
import fallbackImg from '../../../assets/images/fallback.png';
import useClickOutside from '../../../shared/hooks/useClickOutside';
import Choices from './Choices';
import QuestionDeleteButton from './QuestionDeleteButton';
import DragHandle from './DragHandle';
import DeactivatedQuestionTooltip from './DeactivatedQuestionTooltip';
import { questionStatuses } from '../../../constants';

const StyledCard = styled(Card)`
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(50, 50, 71, 0.06);
  border: 1px solid transparent;

  ${props =>
    props.hoverable &&
    `
      cursor: pointer;
      &:hover {
        box-shadow: 0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(50, 50, 71, 0.06);
        border: 1px solid ${colors.componentBorder};
      }

      &:hover .drag-handle-button{
        opacity: 1;
      }

    `}
`;

const QuestionListItem = ({
  question,
  index,
  style = {},
  editable = true,
  sortable = true,
  dragHandleListeners = {},
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const itemRef = useRef();

  useClickOutside(itemRef, () => {
    setIsClicked(false);
  });

  const cardStyle = isClicked ? { border: `1px solid ${colors.functionalSuccess}` } : {};

  return (
    <div
      ref={itemRef}
      style={{
        ...style,
      }}
    >
      <StyledCard
        hoverable={editable}
        onClick={() => editable && setIsClicked(true)}
        style={cardStyle}
        bodyStyle={{ padding: 16 }}
      >
        <Row>
          <Col style={{ flex: 1 }}>
            <Row>
              <Col style={{ flex: 1 }}>
                <Typography.Paragraph style={{ fontWeight: 600, marginBottom: 8, display: 'block' }}>
                  {question.title}
                </Typography.Paragraph>
              </Col>
              {!editable && question.status === questionStatuses.INACTIVE && (
                <Col style={{ marginLeft: 6 }}>
                  <DeactivatedQuestionTooltip />
                </Col>
              )}
            </Row>
            <Row>
              <Col style={{ flex: 1 }}>
                {question.description && (
                  <div style={{ marginBottom: 8, fontSize: 12, color: colors.primaryText }}>
                    <ReactMarkdown remarkPlugins={[remarkBreaks, remarkGfm]} linkTarget="_blank">
                      {question.description}
                    </ReactMarkdown>
                  </div>
                )}
                <Choices
                  questionId={question.id}
                  choices={question.choices}
                  type={question.type}
                  isClicked={isClicked}
                />
              </Col>
              {question.image && !isClicked && (
                <div style={{ marginLeft: 20 }}>
                  <Image
                    width={136}
                    height={97}
                    style={{ objectFit: 'contain' }}
                    alt={question.title}
                    src={question.image}
                    fallback={fallbackImg}
                    onClick={e => e.stopPropagation()}
                  />
                </div>
              )}
            </Row>
          </Col>
          {sortable && (
            <Col style={{ marginLeft: 12, display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
              <DragHandle visible={isClicked} dragHandleListeners={dragHandleListeners} />
            </Col>
          )}
        </Row>
        {isClicked && (
          <>
            <Divider style={{ margin: '16px 0' }} />
            <Row style={{ justifyContent: 'flex-end' }}>
              <QuestionDeleteButton question={question} index={index} />
            </Row>
          </>
        )}
      </StyledCard>
    </div>
  );
};

export default QuestionListItem;
