import React from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Input, Modal, Row, Select, Tooltip, message, Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import { size } from 'lodash';

import { clockinMethods, countryCodeOptions } from '../../../../constants';

// Taken from V1 folders
import AddressAutocomplete from '../../../../shared/components/AddressAutocomplete';
import ClockinMethodFormItems from '../../../../shared/components/ClockinMethodFormItems';
import Maps from '../../../../shared/components/Maps';
import clientApi from '../../../../services/clientApi';
import locationApi from '../../../../services/locationApi';
import { colors } from '../../../../styles/colors';

// Utility function for validating latitude and longitude
// https://developers.google.com/maps/documentation/javascript/reference/3.43/coordinates
const latitudeBounds = 90;
const longitudeBounds = 180;
const isValidLat = lat => lat >= -latitudeBounds && lat <= latitudeBounds;
const isValidLng = lng => lng >= -longitudeBounds && lng <= longitudeBounds;

// Utility function to extract an address component from the Google Maps result
const getAddressComponent = (components, type) => {
  const component = components.filter(comp => comp.types.includes(type))[0];
  return component && component.short_name;
};

class LocationFormModal extends React.Component {
  state = {
    loading: true,
    cityOptions: [],
    areaOptions: [{ name: this.props.t('Please select a city first') }],
    placeService: new window.google.maps.places.PlacesService(document.createElement('div')),
    geocoder: new window.google.maps.Geocoder(),
    latitude: undefined,
    longitude: undefined,
  };

  async componentDidMount() {
    const { country } = this.props;
    const citiesResponse = await locationApi.fetchCities(country.id);
    this.setState({ cityOptions: citiesResponse });

    if (this.props.location && this.props.location.address.area) {
      this.handleCitySelect(this.props.location.address.area.city.id);
    } else if (country.code === countryCodeOptions.SINGAPORE) {
      this.handleCitySelect(this.state.cityOptions[0].id);
    }

    this.setState({ loading: false });
  }

  handleCitySelect = async cityId => {
    const { country } = this.props;
    const areasResponse = await locationApi.fetchAreas(country.id, cityId);
    this.setState({ areaOptions: areasResponse });
  };

  handleCityChange = cityId => {
    // reset Area when City changes
    if (cityId !== this.props.form.getFieldValue('cityId')) {
      this.props.form.setFieldsValue({
        areaId: undefined,
      });
    }
  };

  getStreetComponentFromPlaceService = placeObj => {
    const isSingapore = this.props.country.code === countryCodeOptions.SINGAPORE;
    let termsArr = [];
    if (isSingapore) {
      // Remove country only for Singapore
      termsArr = placeObj.terms.slice(0, -1);
    } else {
      // Remove country and city from Google Maps result since they are assigned on separate fields
      termsArr = placeObj.terms.slice(0, -2);
    }
    const addressArr = termsArr.map(term => term.value);
    return addressArr.join(', ');
  };

  getStreetComponentFromGeocoder = components => {
    let componentsArr = [];
    componentsArr = components.slice(0, -3);
    const addressArr = componentsArr.map(component => component.long_name);
    return addressArr.join(', ');
  };

  handleLocationSelect = placeObj => {
    const { setFieldsValue } = this.props.form;
    const { placeService } = this.state;
    setFieldsValue({ street_1: this.getStreetComponentFromPlaceService(placeObj) });

    // get place details - postal code and coordinates - using unique place_id
    const request = { placeId: placeObj.place_id };
    placeService.getDetails(request, (result, status) => {
      if (status === 'OK') {
        const { address_components } = result;
        const postalCode = getAddressComponent(address_components, 'postal_code');

        const latitude = result.geometry.location.lat();
        const longitude = result.geometry.location.lng();
        setFieldsValue({ zip: postalCode });
        setFieldsValue({ latitude });
        setFieldsValue({ longitude });
        this.setState({ latitude, longitude });
      }
    });
  };

  setAddress = () => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    const { geocoder } = this.state;
    const lat = parseFloat(getFieldValue('latitude') || 0);
    const lng = parseFloat(getFieldValue('longitude') || 0);
    if (isValidLat(lat) && isValidLng(lng)) {
      geocoder.geocode({ location: { lat, lng } }, results => {
        if (size(results) > 0) {
          const { address_components } = results[0];
          const postalCode = getAddressComponent(address_components, 'postal_code');
          const formattedAddress = this.getStreetComponentFromGeocoder(address_components);

          setFieldsValue({ street_1: formattedAddress });
          setFieldsValue({ zip: postalCode });
        }
      });
    }
  };

  handleMapClick = ({ lat, lng }) => {
    const { setFieldsValue } = this.props.form;
    setFieldsValue({ latitude: lat });
    setFieldsValue({ longitude: lng });
    this.setAddress();
  };

  handleClose = () => {
    this.props.form.resetFields();
    this.props.onClose();
  };

  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll(async (formErrors, values) => {
      if (!formErrors) {
        this.setState({ loading: true });
        try {
          if (!this.props.location) {
            await this.createLocation(values);
          } else {
            await this.editLocation(values);
          }
          this.props.onUpdate();
          this.handleClose();
        } catch (error) {
          this.setState({ loading: false });
        }
      }
    });
  };

  createLocation = async values => {
    const qrOverrideAllowed = values.clockin_method === clockinMethods.QR_SCAN_OR_SELFIE;
    const { clientId, country, t, form } = this.props;
    await clientApi.createLocation(clientId, {
      name: values.name,
      client: clientId,
      address: {
        street_1: values.street_1,
        street_2: values.street_2,
        zip: values.zip,
        latitude: form.getFieldValue('latitude') || this.state.latitude,
        longitude: form.getFieldValue('longitude') || this.state.longitude,
        country: country.id,
        area: values.areaId,
      },
      qr_override_allowed: qrOverrideAllowed,
      fixed_location: values.fixed_location,
      landmark: values.landmark,
      maximum_clock_in_radius_in_meters: values?.maximum_clock_in_radius_in_meters,
    });
    message.success(t('New location added'));
  };

  editLocation = async values => {
    const qrOverrideAllowed = values.clockin_method === clockinMethods.QR_SCAN_OR_SELFIE;
    const { t, form, location, clientId } = this.props;
    await clientApi.editLocation(clientId, {
      id: location.id,
      name: values.name,
      client: clientId,
      address: {
        street_1: values.street_1,
        street_2: values.street_2,
        zip: values.zip,
        latitude: form.getFieldValue('latitude'),
        longitude: form.getFieldValue('longitude'),
        country: location.address.country.id,
        area: values.areaId,
      },
      qr_override_allowed: qrOverrideAllowed,
      fixed_location: values.fixed_location,
      landmark: values.landmark,
      maximum_clock_in_radius_in_meters: values?.maximum_clock_in_radius_in_meters,
    });
    message.success(t('Changes saved'));
  };

  render() {
    const { location, visible, country, t, simplified, closable = true } = this.props;
    const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
    const latitude = parseFloat(getFieldValue('latitude') || 0);
    const longitude = parseFloat(getFieldValue('longitude') || 0);
    const qrOverrideAllowed =
      location?.clockin_method === clockinMethods.QR_SCAN_OR_SELFIE ||
      getFieldValue('clockin_method') === clockinMethods.QR_SCAN_OR_SELFIE;

    if (this.state.loading) return null;

    let initialCity = location && location.address.area ? location.address.area.city.id : undefined;
    if (!initialCity && country.code === countryCodeOptions.SINGAPORE) {
      initialCity = this.state.cityOptions[0].id;
    }

    return (
      <Modal
        destroyOnClose
        visible={visible}
        style={{ top: '20px' }}
        okText={t(location ? 'Save' : 'Add Location')}
        okType="v2-primary"
        cancelText={t('cancel')}
        title={t(location ? 'Edit location' : 'Add Location')}
        onOk={this.handleSubmit}
        onCancel={this.handleClose}
        confirmLoading={this.state.loading}
        closable={closable}
        cancelButtonProps={closable ? {} : { style: { display: 'none' } }}
        maskClosable={closable}
      >
        <Form hideRequiredMark layout="vertical" onSubmit={this.handleSubmit}>
          {simplified && (
            <AddressAutocomplete
              t={t}
              getFieldDecorator={getFieldDecorator}
              getFieldValue={getFieldValue}
              countryCode={country.code}
              location={location}
              onLocationSelect={this.handleLocationSelect}
              showError={!this.state.latitude && !this.state.longitude}
            />
          )}

          <Form.Item hasFeedback label={t('Location name')} style={{ marginBottom: '16px', paddingBottom: 0 }}>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: t('Please input a name') }],
              initialValue: location && location.name,
            })(<Input placeholder={t('nameYourBusinessLocation')} />)}
          </Form.Item>

          <Form.Item hasFeedback label={t('City')} style={{ marginBottom: '16px', paddingBottom: 0 }}>
            {getFieldDecorator('cityId', {
              rules: [{ required: true, message: t('Please select a city') }],
              initialValue: initialCity,
            })(
              <Select placeholder={t('Select city')} onSelect={this.handleCitySelect} onChange={this.handleCityChange}>
                {this.state.cityOptions.map((option, index) => {
                  const { id, name } = option;
                  return (
                    <Select.Option key={index} value={id}>
                      <Typography.Text>{t(name)}</Typography.Text>
                    </Select.Option>
                  );
                })}
              </Select>,
            )}
          </Form.Item>

          <Form.Item hasFeedback label={t('Area')} style={{ marginBottom: '16px', paddingBottom: 0 }}>
            {getFieldDecorator('areaId', {
              rules: [{ required: true, message: t('Please select an area') }],
              initialValue: location && location.address.area ? location.address.area.id : undefined,
            })(
              <Select placeholder={t('Select area')}>
                {this.state.areaOptions.map((option, index) => {
                  const { id, name } = option;
                  return (
                    <Select.Option key={index} value={id}>
                      <Typography.Text>{t(name)}</Typography.Text>
                    </Select.Option>
                  );
                })}
              </Select>,
            )}
          </Form.Item>

          {!simplified && (
            <AddressAutocomplete
              t={t}
              getFieldDecorator={getFieldDecorator}
              getFieldValue={getFieldValue}
              countryCode={country.code}
              location={location}
              onLocationSelect={this.handleLocationSelect}
              showError={!this.state.latitude && !this.state.longitude}
            />
          )}

          {!simplified && (
            <Form.Item
              label={
                <>
                  {`${t('locationLandmark')} (${t('optional')})`}
                  <Tooltip placement="topLeft" title={t('landmarkTooltip')}>
                    <InfoCircleFilled style={{ marginLeft: 5, color: colors.blue, marginTop: 5 }} />
                  </Tooltip>
                </>
              }
              style={{ marginBottom: '16px', paddingBottom: 0 }}
            >
              {getFieldDecorator('landmark', {
                rules: [{ max: 40, message: t('maxCharacterLimit', { limit: 40 }) }],
                initialValue: location && location.landmark,
              })(<Input placeholder={t('locationLandmarkPlaceholder')} />)}
            </Form.Item>
          )}

          {!simplified && (
            <Form.Item label={t('Address line 2 (optional)')} style={{ marginBottom: '16px', paddingBottom: 0 }}>
              {getFieldDecorator('street_2', {
                initialValue: location && location.address.street_2,
              })(<Input placeholder={t('Unit, building, floor etc.')} />)}
            </Form.Item>
          )}

          {!simplified && (
            <Form.Item hasFeedback label={t('Postcode')} style={{ marginBottom: '36px', paddingBottom: 0 }}>
              {getFieldDecorator('zip', {
                initialValue: location && location.address.zip,
              })(<Input placeholder="12345" />)}
            </Form.Item>
          )}

          {!simplified && (
            <div style={{ display: 'block' }}>
              {latitude && longitude && isValidLat(latitude) && isValidLng(longitude) ? (
                <div style={{ width: '100%', height: '200px' }}>
                  <Maps
                    centerPosition={{
                      lat: latitude,
                      lng: longitude,
                    }}
                    markerPositions={[
                      {
                        lat: latitude,
                        lng: longitude,
                        draggable: true,
                        ref: 'locationRef',
                        // TODO
                        popup: 'Drag me to correct location',
                      },
                    ]}
                    onDrag={this.handleMapClick}
                    scrollWheelZoom
                    {...(qrOverrideAllowed && { radius: getFieldValue('maximum_clock_in_radius_in_meters') })}
                  />
                </div>
              ) : null}

              <Row gutter={16} style={{ marginTop: '16px' }}>
                <Col span={12}>
                  <Form.Item onBlur={this.setAddress} hasFeedback label={t('Latitude')} style={{ marginBottom: 0 }}>
                    {getFieldDecorator('latitude', {
                      rules: [
                        { required: true, message: t('Please input a latitude') },
                        {
                          type: 'number',
                          message: t('latitudeValidation', { latitudeBounds }),
                          validator: (_, value) => isValidLat(value),
                        },
                      ],
                      initialValue: location && location.address.latitude,
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item onBlur={this.setAddress} hasFeedback label={t('Longitude')} style={{ marginBottom: 0 }}>
                    {getFieldDecorator('longitude', {
                      rules: [
                        { required: true, message: t('Please input a longitude') },
                        {
                          type: 'number',
                          message: t('longitudeValidation', { longitudeBounds }),
                          validator: (_, value) => isValidLng(value),
                        },
                      ],
                      initialValue: location && location.address.longitude,
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
              <span style={{ fontSize: '12px', color: '#8C8C8C' }}>
                {t('Latitude and logitude are automatically determined by your location on Google Maps.')}{' '}
                {t(
                  'You can manually type the coordinates only if you are a remote location without a registered street address.',
                )}
              </span>
            </div>
          )}

          {!simplified && (
            <>
              <div style={{ fontSize: '16px', marginBottom: '6px', marginTop: '16px' }}>{t('clockInMethodTitle')}</div>
              <Row gutter={16} style={{ marginTop: '16px' }}>
                <Col span={24}>
                  <ClockinMethodFormItems
                    getFieldDecorator={getFieldDecorator}
                    getFieldValue={getFieldValue}
                    setFieldsValue={setFieldsValue}
                    showMaxClockinRadius
                    initialValues={{
                      clockin_method: location?.qr_override_allowed
                        ? clockinMethods.QR_SCAN_OR_SELFIE
                        : clockinMethods.QR_SCAN_ONLY,
                      fixed_location: location?.fixed_location,
                      maximum_clock_in_radius_in_meters: location?.maximum_clock_in_radius_in_meters,
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(withTranslation()(LocationFormModal));
