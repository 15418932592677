const getPaginationConfig = (total, onPageChange) => {
  return {
    total,
    onChange: onPageChange,
    pageSize: 20,
    position: 'bottom',
    size: 'large',
    showQuickJumper: true,
  };
};

export default getPaginationConfig;
