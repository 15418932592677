import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Typography, Row, Col } from 'antd';

import { colors } from '../../styles/colors';

const { Text } = Typography;
const PercentageDiffIcon = ({ percentageDiff, lastTimePeriod = undefined, alternateMessage = undefined, t }) => {
  const isPositive = Math.sign(percentageDiff);
  let iconType;
  let iconColor;
  let border;
  let background;

  if (isPositive > 0) {
    iconType = 'arrow-up';
    iconColor = colors.green;
    border = `1px solid ${colors.brandGreen}`;
    background = colors.backgroundGrey;
  } else if (isPositive < 0) {
    iconType = 'arrow-down';
    iconColor = colors.red;
    border = `1px solid ${colors.grey}`;
    background = colors.backgroundGrey;
    // eslint-disable-next-line no-param-reassign
    percentageDiff = Math.abs(percentageDiff);
  } else {
    iconType = 'minus';
    iconColor = colors.gray;
    border = `1px solid ${colors.grey}`;
    background = colors.backgroundGrey;
  }
  return (
    <Row style={{ textAlign: 'center' }}>
      <Col>
        <Text
          style={{
            fontSize: '12px',
            color: iconColor,
            border,
            borderRadius: '16px',
            padding: '4px 6px',
            background,
          }}
        >
          <LegacyIcon type={iconType} style={{ marginRight: '4px', color: iconColor }} />
          {`${percentageDiff}%`}
        </Text>{' '}
        {lastTimePeriod && (
          <Text style={{ fontSize: '12px', color: iconColor }}>{t(`from last ${lastTimePeriod}`)}</Text>
        )}
        {alternateMessage && <Text style={{ fontSize: '12px', color: iconColor }}>{alternateMessage}</Text>}
      </Col>
    </Row>
  );
};

export default withTranslation()(PercentageDiffIcon);
