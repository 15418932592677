import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { ClockCircleOutlined, EditOutlined, CopyOutlined, StopOutlined } from '@ant-design/icons';
import { Col, List, Progress, Row, Typography, Tooltip, Button, message } from 'antd';
import routes from '../../../routes';
import { DEFAULT_DATE_FORMAT, employmentTypes, staffRequestStatuses, staffRequestTabs } from '../../../constants';
import StatusTag from '../../../shared/components/StatusTag';
import ConfirmModals from '../../../shared/components/ConfirmModals';
import staffRequestApi from '../../../services/staffRequestApi';
import staffRequestUtils from '../../../utilities/staffRequestUtils';
import { colors } from '../../../styles/colors';
import VipWorkersOnlyTooltip from './VipWorkersOnlyTooltip';

const { Text } = Typography;

const handleCancelSr = async (staffRequestId, onUpdate, t) => {
  const cancelledSr = await staffRequestApi.cancelStaffRequest(staffRequestId);
  if (cancelledSr) {
    message.warning(t('srCancelledMessage', { srId: staffRequestId }));
    onUpdate();
  }
};

const HiredRequiredRatio = ({
  t,
  confirmedPartners,
  staffRequired,
  fillRate,
  progressStatus,
  offeredApplicantsCount,
  staffRequestId,
  interviewApplicationCount,
}) => (
  <>
    <Row>
      <Text strong>{t('hiredRequiredRatio', { hired: confirmedPartners.count, required: staffRequired })}</Text>
    </Row>
    <Row>
      <Progress
        size="small"
        showInfo={false}
        percent={fillRate > 0 ? fillRate : 4}
        status={progressStatus}
        style={{ width: '132px', paddingRight: '16px' }}
      />
    </Row>
    <Row>
      {interviewApplicationCount > 0 && (
        <Text type="secondary">
          <Link
            style={{ color: colors.infoHighlightBlue }}
            to={routes.staffRequestDetail.replace(':id', staffRequestId).replace(':tab', staffRequestTabs.INTERVIEW)}
            className="underline-on-hover"
          >
            {t('applicantsToInterview', { count: interviewApplicationCount })}
          </Link>
        </Text>
      )}
    </Row>
    <Row>
      {offeredApplicantsCount > 0 && (
        <Text type="secondary">
          <Link
            style={{ color: colors.primaryText }}
            to={routes.staffRequestDetail.replace(':id', staffRequestId).replace(':tab', staffRequestTabs.OFFERS)}
            className="underline-on-hover"
          >
            {t('pendingOffers', { count: offeredApplicantsCount })}
          </Link>
        </Text>
      )}
    </Row>
  </>
);

const PendingReview = ({ t }) => {
  return (
    <>
      <Row>
        <Text type="warning" strong>
          <ClockCircleOutlined style={{ marginRight: '5px' }} />
          {t('awaitingApproval')}
        </Text>
      </Row>
      <Row>
        <Text type="secondary">{t('reviewingJobPostMessage')}</Text>
      </Row>
    </>
  );
};

const Draft = ({ t }) => {
  return <Text type="secondary">{t('draftMessage')}</Text>;
};

const StaffRequestList = ({
  t,
  history,
  staffRequests,
  loading,
  onUpdate,
  employmentTypeOptions,
  staticContext,
  positions,
  ...props
}) => {
  return (
    <List
      {...props}
      itemLayout="vertical"
      loading={loading}
      dataSource={staffRequests || []}
      renderItem={staffRequest => {
        const {
          id,
          position,
          title,
          status,
          location,
          start_time: startTime,
          end_time: endTime,
          created_date: createdDate,
          confirmed_partners: confirmedPartners,
          staff_required: staffRequired,
          applicant_tasks: applicantTasks,
          interview_application_count: interviewApplicationCount,
          employment_type: employmentType,
          pending_contract_application_count: offeredApplicantsCount,
          client_interview_required: clientInterviewRequired,
          paused,
        } = staffRequest;
        const shouldDisableEditSRButton =
          [staffRequestStatuses.CANCELLED, staffRequestStatuses.ENDED].includes(status) ||
          employmentType === employmentTypes.GIG.value;
        const shouldDisableDuplicateButton = employmentType === employmentTypes.GIG.value;

        const fillRate = (confirmedPartners.count / staffRequired) * 100;
        let progressStatus = 'normal';
        if (fillRate === 0) {
          progressStatus = 'exception';
        } else if (fillRate >= 100) {
          progressStatus = 'success';
        }
        const { timezone } = location.address.area.city;

        const listColumnStyle = { height: '124px', position: 'relative' };
        const listColumnItemStyle = {
          margin: 0,
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
        };

        const endDate = endTime ? moment.tz(endTime, timezone).format(DEFAULT_DATE_FORMAT) : t('permanent');
        const translatedPosition = position ? positions.find(pos => pos?.id === position?.id) : undefined;

        let jobStatus = (
          <HiredRequiredRatio
            t={t}
            confirmedPartners={confirmedPartners}
            staffRequired={staffRequired}
            fillRate={fillRate}
            progressStatus={progressStatus}
            applicantTasks={applicantTasks}
            interviewApplicationCount={interviewApplicationCount}
            offeredApplicantsCount={offeredApplicantsCount}
            staffRequestId={staffRequest.id}
          />
        );

        if (staffRequest.status === staffRequestStatuses.DRAFT) {
          jobStatus = <Draft t={t} />;
        } else if (staffRequest.status === staffRequestStatuses.PENDING_REVIEW) {
          jobStatus = <PendingReview t={t} />;
        }

        return (
          <List.Item>
            <Link to={staffRequestUtils.getSRCallToAction(status, id, applicantTasks, clientInterviewRequired)}>
              <Row gutter={32}>
                <Col xs={12} md={7} style={listColumnStyle}>
                  <div style={listColumnItemStyle}>
                    <Row>
                      {staffRequest?.vip_workers_only && (
                        <VipWorkersOnlyTooltip
                          staffRequest={staffRequest}
                          iconContainerStyle={{ marginRight: 6 }}
                          tooltipProps={{ align: { offset: [10, 5] } }}
                        />
                      )}
                      {title?.length > 33 ? (
                        <Tooltip title={title}>
                          <Text strong className="underline-on-hover">
                            {/* TODO: investigate using className to enable Ant ellipsis */}
                            {title.slice(0, 33)}...
                          </Text>
                        </Tooltip>
                      ) : (
                        <Text strong className="underline-on-hover">
                          {`${title} - ${translatedPosition?.name}`}
                        </Text>
                      )}
                    </Row>
                    <Row>
                      <Text type="secondary">{location.name}</Text>
                    </Row>
                    <Row>
                      <Text type="secondary">{`${t('staff request')} #${id}`}</Text>
                    </Row>
                  </div>
                </Col>
                <Col xs={14} md={5} style={listColumnStyle}>
                  <div style={listColumnItemStyle}>
                    <Row>
                      <Text strong>{`${moment.tz(startTime, timezone).format(DEFAULT_DATE_FORMAT)} – ${endDate}`}</Text>
                    </Row>
                    <Row>
                      <Text type="secondary">{position?.name || '-'}</Text>
                      <Text type="secondary" style={{ marginInline: 6 }}>
                        ·
                      </Text>
                      <Text type="secondary">
                        {
                          employmentTypeOptions.find(
                            employmentTypeOption => employmentTypeOption.key === employmentType,
                          )?.title
                        }
                      </Text>
                    </Row>
                    <Row>
                      <Text type="secondary">{`Created ${moment(createdDate).format(DEFAULT_DATE_FORMAT)}`}</Text>
                    </Row>
                  </div>
                </Col>
                <Col xs={8} md={3} style={listColumnStyle}>
                  <div style={listColumnItemStyle}>
                    <StatusTag status={status} paused={paused} />
                  </div>
                </Col>
                <Col xs={10} md={5} style={listColumnStyle}>
                  <div style={listColumnItemStyle}>{jobStatus}</div>
                </Col>
                <Col xs={6} md={4} style={{ ...listColumnStyle, display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '100%' }}>
                    <Row type="flex" justify="end" gutter={8}>
                      {!shouldDisableEditSRButton && (
                        <Col>
                          <Tooltip placement="top" title={t('edit')} arrowPointAtCenter>
                            <Button
                              onClick={e => {
                                e.preventDefault();
                                history.push({
                                  pathname: routes.editStaffRequest.replace(':id', id),
                                });
                              }}
                            >
                              <EditOutlined />
                            </Button>
                          </Tooltip>
                        </Col>
                      )}
                      {!shouldDisableDuplicateButton && (
                        <Col>
                          <Tooltip placement="top" title={t('duplicate')} arrowPointAtCenter>
                            <Button
                              onClick={e => {
                                e.preventDefault();
                                history.push({
                                  pathname: routes.createStaffRequest,
                                  state: { replicateId: id },
                                });
                              }}
                            >
                              <CopyOutlined />
                            </Button>
                          </Tooltip>
                        </Col>
                      )}
                      {[staffRequestStatuses.POSTED, staffRequestStatuses.IN_PROGRESS].includes(status) && (
                        <Col>
                          <Tooltip placement="top" title={t('cancel')} arrowPointAtCenter>
                            <Button
                              onClick={e => {
                                e.preventDefault();
                                ConfirmModals.cancelSr(title, id, t, () => handleCancelSr(id, onUpdate, t));
                              }}
                            >
                              <StopOutlined />
                            </Button>
                          </Tooltip>
                        </Col>
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Link>
          </List.Item>
        );
      }}
    />
  );
};

export default withRouter(StaffRequestList);
