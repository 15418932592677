import React from 'react';
import { withTranslation } from 'react-i18next';
import { Typography } from 'antd';

import routes from '../../../routes';

const { Text } = Typography;
const FormDisclaimer = props => {
  const { t } = props;
  return (
    <>
      <Text strong>{t('Disclaimer:')} </Text>
      <Text>
        {t(
          `By continuing, you agree to be charged for fees and payment including workers’ wages for days worked at your business and Workmate’s service fees. You agree to our cancellation policies and customer obligations as stated in the`,
        )}{' '}
      </Text>
      <Text underline>
        <a href={routes.termsOfService} target="_blank" rel="noopener noreferrer">
          {t('Workmate Terms')}.
        </a>
      </Text>
    </>
  );
};

export default withTranslation()(FormDisclaimer);
