import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Empty, List, Typography, Row, Col, Dropdown, Menu } from 'antd';
import RoleFormModal from './components/RoleFormModal';
import DeleteRoleFormModal from './components/DeleteRoleFormModal';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';

// Taken from V1 folder
import { colors } from '../../../styles/colors';

const { Title, Text } = Typography;

class RoleSection extends React.Component {
  state = {
    roleModalOpen: false,
    deleteRoleModalOpen: false,
  };

  render() {
    const { client, isAdmin, fetchClientAndManager, roles, isLoading, t } = this.props;
    const { roleModalOpen, deleteRoleModalOpen, role } = this.state;
    return (
      <>
        <Row style={{ marginTop: '14px', marginBottom: '16px' }}>
          <Title level={3}>{t('roles')}</Title>
          <Text type="secondary">{t('roleSettingsPageDescription')}</Text>
        </Row>
        <Row style={{ marginBottom: '32px' }}>
          <Button
            disabled={!isAdmin}
            onClick={() => {
              this.setState({ roleModalOpen: true, role: null });
            }}
          >
            {t('createRole')}
          </Button>
        </Row>
        {roles && roles.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={<span>{t('pleaseCreateARole')}</span>} />
        ) : (
          <List
            itemLayout="horizontal"
            loading={isLoading && { indicator: <LoadingSpinner width="50px" /> }}
            dataSource={roles || []}
            renderItem={roleItem => (
              <Row
                type="flex"
                justify="space-between"
                style={{ paddingBottom: '24px', paddingTop: '16px', borderBottom: '1px solid #00000017' }}
              >
                <Col>
                  <Row>
                    <Title strong level={4}>
                      {roleItem.name}
                    </Title>
                  </Row>
                  <Row>
                    <Text>{roleItem.position.name}</Text>
                  </Row>
                </Col>
                <Col span={2} style={{ textAlign: 'right' }}>
                  <Dropdown
                    trigger={['click']}
                    overlay={
                      <Menu style={{ width: '120px' }}>
                        <Menu.Item
                          disabled={!isAdmin}
                          onClick={() => {
                            this.setState({ roleModalOpen: true, role: roleItem });
                          }}
                        >
                          {t('Edit')}
                        </Menu.Item>
                        <Menu.Item
                          style={{ color: colors.red }}
                          disabled={!isAdmin}
                          onClick={() => {
                            this.setState({ deleteRoleModalOpen: true, role: roleItem });
                          }}
                        >
                          {t('remove')}
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button icon={<EllipsisOutlined />} />
                  </Dropdown>
                </Col>
              </Row>
            )}
          />
        )}
        {roleModalOpen && (
          <RoleFormModal
            clientId={client.clientId}
            role={role} // Does not have to be explicitly set in "this.state". Handled by Antd.
            visible={roleModalOpen}
            onUpdate={fetchClientAndManager}
            onClose={() => this.setState({ roleModalOpen: false })}
          />
        )}
        {deleteRoleModalOpen && (
          <DeleteRoleFormModal
            clientId={client.clientId}
            role={role}
            visible={deleteRoleModalOpen}
            onUpdate={fetchClientAndManager}
            onClose={() => this.setState({ deleteRoleModalOpen: false })}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.user.isLoading,
  roles: state.user.roles,
});

export default connect(mapStateToProps, null)(withTranslation()(RoleSection));
