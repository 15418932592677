import React from 'react';
import { withTranslation } from 'react-i18next';
import { Typography, Empty, Row, Col } from 'antd';

const { Text, Title } = Typography;
const FunnelEmptySearch = ({ t }) => {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <>
          <Row type="flex" justify="center">
            <Title level={4} type="secondary">
              {t('noResultsFound')}
            </Title>
          </Row>
          <Row justify="center">
            <Col>
              <Text type="secondary">{t('adjustYourSearchFilterSelection')}</Text>
            </Col>
          </Row>
        </>
      }
    />
  );
};

export default withTranslation()(FunnelEmptySearch);
