import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Typography, Divider, Radio, Input, Button } from 'antd';
import { connect } from 'react-redux';

import { wageTypes, paymentFrequency } from '../../../constants';
import { colors } from '../../../styles/colors';

const WagesForm = ({ t, form, currencyCode, onSubmit, onCancel }) => {
  const { getFieldDecorator, getFieldsValue, getFieldValue } = form;
  const wageTypeText = {
    [wageTypes.PER_HOUR.value]: t('perHour'),
    [wageTypes.PER_DAY.value]: t('perDay'),
    [wageTypes.PER_MONTH.value]: t('perMonth'),
  };

  // disable if none of the fields have values
  const shouldDisableConfirmButton = !Object.values(getFieldsValue()).reduce((val, acc) => val || acc, false);
  return (
    <Form layout="vertical" hideRequiredMark className="v2-form-wrapper">
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 24, marginRight: 24 }}>
        <Typography.Text style={{ fontWeight: 600 }}>{t('baseWageSettings')}</Typography.Text>
        <Typography.Text>{t('baseWageSettingsDescription')}</Typography.Text>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 24, marginRight: 24 }}>
        <Form.Item label={t('wageType')} style={{ width: '100' }}>
          {getFieldDecorator('wage_type', {
            rules: [],
          })(
            <Radio.Group placeholder={t('wageAmountPlaceholder')}>
              <Radio value={wageTypes.PER_HOUR.value}>{t('hourly')}</Radio>
              <Radio value={wageTypes.PER_DAY.value}>{t('daily')}</Radio>
              <Radio value={wageTypes.PER_MONTH.value}>{t('monthly')}</Radio>
            </Radio.Group>,
          )}
        </Form.Item>

        <Form.Item label={t('wageAmount')} style={{ width: '100' }}>
          {getFieldDecorator('base_wage', {
            rules: [{ pattern: /^\d+$/, message: t('inputNumberErrorMessage') }],
          })(
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Input placeholder={t('wageAmountPlaceholder')} suffix={currencyCode} style={{ width: 180 }} />
              <Typography.Text style={{ marginLeft: 10 }}>
                {wageTypeText[getFieldValue('wage_type')] || t('perDay')}
              </Typography.Text>
            </div>,
          )}
        </Form.Item>
      </div>
      <Divider />
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 24, marginRight: 24 }}>
        <Typography.Text style={{ fontWeight: 600 }}>{t('additionalWageSettings')}</Typography.Text>
        <Typography.Text>{t('additionalWageSettingsDescription')}</Typography.Text>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 24, marginRight: 24 }}>
        <Form.Item label={t('overtime')} style={{ width: '100' }}>
          {getFieldDecorator('overtime_wage', {
            rules: [{ pattern: /^\d+$/, message: t('inputNumberErrorMessage') }],
          })(
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Input placeholder={t('wageAmountPlaceholder')} suffix={currencyCode} style={{ width: 180 }} />
              <Typography.Text style={{ marginLeft: 10 }}>{t('perHour')}</Typography.Text>
            </div>,
          )}
        </Form.Item>
      </div>
      <Divider />
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 24, marginRight: 24 }}>
        <Typography.Text style={{ fontWeight: 600 }}>{t('paymentFrequency')}</Typography.Text>
        <Typography.Text>{t('paymentFrequencyDescription')}</Typography.Text>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 24, marginRight: 24 }}>
        <Form.Item style={{ width: '100' }}>
          {getFieldDecorator('payment_frequency', {
            rules: [],
          })(
            <Radio.Group>
              <Radio value={paymentFrequency.WEEKLY.key}>{t('weekly')}</Radio>
              <Radio value={paymentFrequency.BIWEEKLY.key}>{t('biWeekly')}</Radio>
              <Radio value={paymentFrequency.MONTHLY.key}>{t('monthly')}</Radio>
            </Radio.Group>,
          )}
        </Form.Item>
      </div>

      {/* NOTE: hidden temporarily until other allowance is needed */}
      {/* <Form.Item label={t('otherAllowance')} style={{ width: '100' }}>
          {getFieldDecorator('other_allowance', {
            rules: [{ pattern: /^\d+$/, message: t('inputNumberErrorMessage') }],
          })(
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Input placeholder={t('wageAmountPlaceholder')} suffix={currencyCode} style={{ width: 180 }} />
              <Typography.Text style={{ marginLeft: 10 }}>{t('perTimesheet')}</Typography.Text>
            </div>,
          )}
        </Form.Item> */}

      <Divider />
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 24, marginRight: 24 }}>
        <Typography.Text style={{ fontWeight: 600, color: colors.darkGrey }}>{t('disclaimer')}</Typography.Text>
        <Typography.Text style={{ color: colors.darkGrey }}>{t('employeesWagesDisclaimer')}</Typography.Text>
      </div>

      <Divider style={{ marginBottom: 10 }} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginLeft: 24,
          marginRight: 24,
        }}
      >
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <Button
          disabled={shouldDisableConfirmButton}
          onClick={() =>
            form.validateFieldsAndScroll((errors, values) => {
              if (!errors) {
                onSubmit(values);
              }
            })
          }
          style={{
            marginLeft: 8,
            fontWeight: 600,
            backgroundColor: !shouldDisableConfirmButton && colors.brandGreen,
            color: !shouldDisableConfirmButton && colors.white,
          }}
        >
          {t('confirm')}
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = state => ({
  currencyCode: state.user.country.currency_code,
});

export default connect(mapStateToProps, undefined)(Form.create()(withTranslation()(WagesForm)));
