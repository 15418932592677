import React, { useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { withTranslation } from 'react-i18next';

import { colors } from '../../../../styles/colors';
import VoidTimesheetEntryModal from '../../../../containers/TimesheetList/components/VoidTimesheetEntryModal';
import SelfieModal from '../../../../containers/TimesheetList/components/SelfieModal';
import EditTimesheetFormModal from '../EditTimesheetFormModal';
import TimesheetItemHeader from './TimesheetItemHeader';
import TimesheetItemBody from './TimesheetItemBody';

const { Panel } = Collapse;

export default withTranslation()(
  ({
    timesheetEntry,
    loading,
    handleApproveTimesheet,
    handleConfirmTimesheet,
    onActionComplete,
    setShowVoidModal,
    setSelectedTimesheetEntry,
    setShowEditTimesheetModal,
    setVisibleSelfieModal,
    refreshTimesheetData,
    showVoidModal,
    selectedTimesheetEntry,
    showEditTimesheetModal,
    visibleSelfieModal,
    voidReasons,
    timeRoundingRules,
  }) => {
    const [headerWarnings, setHeaderWarnings] = useState([]);
    return (
      <Collapse
        style={{ marginTop: 24, width: '100%', borderColor: colors.greyBorder }}
        className="site-collapse-custom-collapse"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            style={{
              fontSize: 16,
              position: 'absolute',
              left: 22,
              top: 'calc(50% - 8px)',
            }}
            rotate={isActive ? 90 : 0}
          />
        )}
        defaultActiveKey={['0']}
      >
        <Panel
          header={
            <TimesheetItemHeader
              timesheetEntry={timesheetEntry}
              loading={loading}
              handleApproveTimesheet={handleApproveTimesheet}
              handleConfirmTimesheet={handleConfirmTimesheet}
              onActionComplete={onActionComplete}
              setShowVoidModal={setShowVoidModal}
              setSelectedTimesheetEntry={setSelectedTimesheetEntry}
              setShowEditTimesheetModal={setShowEditTimesheetModal}
              headerWarnings={headerWarnings}
              voidReasons={voidReasons}
              timeRoundingRules={timeRoundingRules}
            />
          }
          className="site-collapse-custom-panel"
          style={{ paddingBottom: 0, background: colors.whiteBackground, borderColor: colors.greyBorder }}
        >
          <div style={{ marginLeft: 44, padding: '8px 8px 8px 0px', width: '100%' }}>
            <TimesheetItemBody
              timesheetEntry={timesheetEntry}
              setVisibleSelfieModal={setVisibleSelfieModal}
              setHeaderWarnings={setHeaderWarnings}
            />
            <VoidTimesheetEntryModal
              visible={showVoidModal}
              timesheet={selectedTimesheetEntry}
              onCancel={() => {
                setShowVoidModal(false);
                setSelectedTimesheetEntry({});
              }}
              onComplete={() => {
                setShowVoidModal(false);
                setSelectedTimesheetEntry({});
                onActionComplete();
              }}
            />
            {showEditTimesheetModal && (
              <EditTimesheetFormModal
                onClose={() => {
                  setShowEditTimesheetModal(false);
                  setSelectedTimesheetEntry({});
                }}
                visible={showEditTimesheetModal}
                selectedTimesheetEntry={selectedTimesheetEntry}
                onUpdateTimesheet={updatedTimesheetEntry => {
                  refreshTimesheetData(updatedTimesheetEntry);
                  setShowEditTimesheetModal(false);
                  setSelectedTimesheetEntry({});
                }}
              />
            )}
            <SelfieModal
              visible={visibleSelfieModal !== null}
              title={visibleSelfieModal?.title}
              selfie={visibleSelfieModal?.selfie}
              onCancel={() => {
                setVisibleSelfieModal(null);
              }}
            />
          </div>
        </Panel>
      </Collapse>
    );
  },
);
