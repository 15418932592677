import React from 'react';
import { withTranslation } from 'react-i18next';
import { LineOutlined } from '@ant-design/icons';
import { Typography, Tooltip, Row, Col } from 'antd';
import styled from 'styled-components';

import { attendanceStatuses } from '../../../constants';
import { colors } from '../../../styles/colors';

const { Text } = Typography;

const ScheduleTableCell = ({ shift, isPastShift, shiftRole, showSmall, t, onClick, isMonthlyView, isEndOfWeek }) => {
  let assigned;
  let needed;
  let isAssignedLessThanNeeded;
  let noStaffRequired;
  if (shift) {
    needed = shiftRole ? shiftRole.staff_required : shift.staff_required;
    const assignedAttendances = shiftRole
      ? shift.attendances.filter(
          attendance =>
            attendance.status === attendanceStatuses.ASSIGNED &&
            !attendance.absence_reason &&
            attendance.role_id === shiftRole.role.id,
        )
      : shift.attendances.filter(
          attendance => attendance.status === attendanceStatuses.ASSIGNED && !attendance.absence_reason,
        );

    assigned = assignedAttendances.length;
    isAssignedLessThanNeeded = needed > assigned;
    noStaffRequired = shiftRole?.staff_required === 0 || shift.staff_required === 0;
  }

  const CellContainer = styled.div`
    background-color: ${(!shift && colors.white) ||
      (isPastShift && colors.componentBackground) ||
      (isAssignedLessThanNeeded && colors.tagYellow) ||
      (!isPastShift && !isAssignedLessThanNeeded && '')};

    &:hover {
      cursor: ${isPastShift ? 'not-allowed' : 'pointer'};
      ${isPastShift} {
        box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 2px;
      }
    }
  `;
  return (
    <CellContainer onClick={() => onClick()}>
      {shift ? (
        <Row
          type="flex"
          justify="start"
          align="middle"
          gutter={4}
          style={{
            margin: 0,
            fontSize: 14,
            width: isMonthlyView ? 80 : 150,
            height: shiftRole && showSmall ? 50 : 100,
            paddingLeft: 16,
            borderBottom: shiftRole && `1px solid ${colors.backgroundGrey}`,
            borderRight: isEndOfWeek && `1px solid ${colors.grey}`,
          }}
        >
          {noStaffRequired ? (
            <Col>
              <LineOutlined style={{ color: colors.grey, fontSize: '20px' }} />
            </Col>
          ) : (
            <Col>
              <Tooltip
                title={
                  <>
                    <Row>
                      <Typography.Text strong style={{ color: colors.white }}>
                        {shiftRole ? shiftRole.role.name : t(shift.position.name)}
                      </Typography.Text>
                    </Row>
                    <Row>
                      <Typography.Text style={{ color: colors.functionalWarning }}>
                        {`${assigned} ${t('assigned')} `}
                      </Typography.Text>
                      <Typography.Text style={{ color: colors.white }}>{`/ ${needed} ${t('needed')}`}</Typography.Text>
                    </Row>
                  </>
                }
              >
                <Text
                  style={
                    (isPastShift && { color: colors.grey }) || (isAssignedLessThanNeeded && { color: colors.yellow })
                  }
                >
                  {`${assigned} / ${needed}`}
                </Text>
              </Tooltip>
            </Col>
          )}
        </Row>
      ) : (
        <Row
          style={{
            margin: 0,
            width: isMonthlyView ? 80 : 150,
            height: 100,
            paddingLeft: 16,
            borderRight: isEndOfWeek && `1px solid ${colors.grey}`,
          }}
        />
      )}
    </CellContainer>
  );
};

export default withTranslation()(ScheduleTableCell);
