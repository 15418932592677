import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { withTranslation } from 'react-i18next';
import { Button, Col, Row, Tooltip, message } from 'antd';
import { CalendarOutlined, CheckCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { connect } from 'react-redux';

import fetchAll from '../../../utilities/apiUtils';
import applicationApi from '../../../services/applicationApi';
import employmentApi from '../../../services/employmentApi';
import ConfirmModals from '../../../shared/components/ConfirmModals';
import BulkHireFailModal from './BulkHireFailModal';
// TODO move to shared
import EndEmploymentModal from '../../WorkforcePage/components/EndEmploymentModal';
import ApplicantRejectionModal from '../../../containers/StaffRequestApplicantsList/components/ApplicantRejectionModal';

import {
  messageDisplayDuration,
  staffRequestTabs,
  smsCopytextTranslations,
  inAppNotificationTranslations,
} from '../../../constants';
import { colors } from '../../../styles/colors';
import staffRequestUtils from '../../../utilities/staffRequestUtils';

const HiringBulkActions = ({
  t,
  disabled,
  selectedIds,
  onBulkActionSuccess,
  staffRequest,
  disableHire = false,
  disableInterview = false,
  disableReject = false,
  acceptedTab = false,
  selectAllApplications = false,
  totalSelected = 0,
  status = undefined,
  onFailedBulkHire = undefined,
  applicationRejectReasons = [],
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showEndEmploymentModal, setShowEndEmploymentModal] = useState(false);
  const [showBulkHireFailModal, setShowBulkHireFailModal] = useState(false);
  const [failedApprovalIds, setfailedApprovalIds] = useState([]);
  const [selectedIdsCopy, setSelectedIdsCopy] = useState([]);
  const [offerExpiryHour, setOfferExpiryHour] = useState(undefined);
  const [bulkHireSwitch, setBulkHireSwitch] = useState(false);
  const [showApplicantRejectionModal, setShowApplicantRejectionModal] = useState(false);

  const countryCode = staffRequest?.client?.country?.code;
  const clientName = staffRequest?.client?.name;
  const jobTitle = staffRequest?.title;
  const hasInterview = staffRequest.client_interview_required;

  const translatedSmsMessage = smsCopytextTranslations[countryCode](clientName, staffRequest?.id);
  const translatedInAppNotification = inAppNotificationTranslations[countryCode](clientName, jobTitle);

  const allApplicationsSelected = async () => {
    const response = await fetchAll(applicationApi.list, {
      staff_request: staffRequest?.id,
      status,
    });

    return response?.map(res => res.id);
  };

  const onBulkInterview = async () => {
    setIsLoading(true);
    try {
      let ids = selectedIds || [];
      if (selectAllApplications) {
        ids = await allApplicationsSelected();
      }

      await applicationApi.bulkInterview(ids);
      onBulkActionSuccess(ids, staffRequestTabs.INTERVIEW);
    } catch (error) {
      Sentry.captureException(error);
      message.warning(t('Something went wrong. No changes were saved'), [messageDisplayDuration]);
    } finally {
      setIsLoading(false);
    }
  };

  const onBulkHire = async () => {
    setIsLoading(true);
    try {
      let ids = selectedIds || [];
      if (selectAllApplications) {
        ids = await allApplicationsSelected();
      }
      const offerExpiry =
        offerExpiryHour ||
        // eslint-disable-next-line no-undef
        sessionStorage.getItem(staffRequestUtils.generateOfferExpirySessionStorageKey(staffRequest.id));
      // eslint-disable-next-line no-undef
      sessionStorage.setItem(staffRequestUtils.generateOfferExpirySessionStorageKey(staffRequest.id), offerExpiry);
      const response = await applicationApi.bulkApprove(ids, offerExpiry);
      if (response.failed_approvals.length > 0) {
        const failedApprovals = response.failed_approvals.map(item => item.application_id);
        setfailedApprovalIds(failedApprovals);
        onFailedBulkHire(failedApprovals);
        setShowBulkHireFailModal(true);
      }
      setSelectedIdsCopy(ids);
      onBulkActionSuccess(ids, staffRequestTabs.OFFERS, response?.succeed_approvals);
    } catch (error) {
      Sentry.captureException(error);
      message.warning(t('Something went wrong. No changes were saved'), [messageDisplayDuration]);
    } finally {
      setIsLoading(false);
    }
  };

  const onBulkReject = async (totalSelectedApplications, reason, notes) => {
    setIsLoading(true);
    try {
      let ids = selectedIds || [];
      if (selectAllApplications) {
        ids = await allApplicationsSelected();
      }

      await applicationApi.bulkReject(ids, reason, notes);
      onBulkActionSuccess(ids, staffRequestTabs.INACTIVE);
      message.success(t('applicantBulkRejectSuccessMessage', { totalSelected: totalSelectedApplications }));
    } catch (error) {
      Sentry.captureException(error);
      message.warning(t('Something went wrong. No changes were saved'), [messageDisplayDuration]);
    } finally {
      setIsLoading(false);
    }
  };

  const onBulkCancel = async values => {
    setIsLoading(true);
    try {
      let ids = selectedIds || [];
      if (selectAllApplications) {
        ids = await allApplicationsSelected();
      }

      const payload = {
        employment_ids: ids,
        cancellation_reason: values.reason,
        notes: values.notes,
      };
      await employmentApi.bulkCancel(payload);
      onBulkActionSuccess(ids);
      setShowEndEmploymentModal(false);
    } catch (error) {
      Sentry.captureException(error);
      message.warning(t('Something went wrong. No changes were saved'), [messageDisplayDuration]);
    } finally {
      setIsLoading(false);
      setBulkHireSwitch(false);
    }
  };

  useEffect(() => {
    if (bulkHireSwitch) {
      onBulkHire();
    }
  }, [bulkHireSwitch]);

  return (
    <>
      <Row type="flex" gutter={8} style={{ marginLeft: 4 }}>
        {!disableInterview && !hasInterview && (
          <Col>
            <Tooltip placement="top" title={t('moveToInterviewTooltip')}>
              <Button disabled={disabled || disableInterview} loading={isLoading} onClick={onBulkInterview}>
                <CalendarOutlined />
              </Button>
            </Tooltip>
          </Col>
        )}
        {!disableHire && (
          <Col>
            <Tooltip placement="top" title={t('sendAnOfferTooltip')}>
              <Button
                disabled={disabled || disableHire}
                loading={isLoading}
                onClick={() => {
                  ConfirmModals.bulkHireWorkers(
                    totalSelected,
                    translatedSmsMessage,
                    translatedInAppNotification,
                    t,
                    // eslint-disable-next-line no-undef
                    sessionStorage.getItem(staffRequestUtils.generateOfferExpirySessionStorageKey(staffRequest.id)),
                    setOfferExpiryHour,
                    () => {
                      setBulkHireSwitch(!bulkHireSwitch);
                    },
                  );
                }}
              >
                <CheckCircleFilled
                  style={{ color: disabled || disableHire ? colors.disabled : colors.functionalSuccess }}
                />
              </Button>
            </Tooltip>
          </Col>
        )}
        {!disableReject && (
          <Col>
            <Tooltip placement="top" title={acceptedTab ? t('endEmploymentTitle') : t('reject')}>
              <Button
                disabled={disabled || disableReject}
                loading={isLoading}
                onClick={
                  acceptedTab
                    ? () => setShowEndEmploymentModal(true)
                    : () => {
                        setShowApplicantRejectionModal(true);
                      }
                }
              >
                <MinusCircleFilled
                  style={{ color: disabled || disableReject ? colors.disabled : colors.functionalError }}
                />
              </Button>
            </Tooltip>
          </Col>
        )}
      </Row>
      <EndEmploymentModal
        visible={showEndEmploymentModal}
        workerCount={selectedIds.length}
        confirmLoading={isLoading}
        closeModal={() => setShowEndEmploymentModal(false)}
        hasDirectEmployment={false}
        onConfirm={onBulkCancel}
      />
      <BulkHireFailModal
        visible={showBulkHireFailModal}
        selectedIdsCount={selectedIdsCopy.length}
        onCancel={() => setShowBulkHireFailModal(false)}
        failedApprovalIdsCount={failedApprovalIds.length}
      />
      {showApplicantRejectionModal && (
        <ApplicantRejectionModal
          title={t('applicantBulkRejectionModalTitle', { totalSelected })}
          applicationRejectReasons={applicationRejectReasons}
          visible={showApplicantRejectionModal}
          onClose={() => {
            setShowApplicantRejectionModal(false);
          }}
          onReject={(reason, notes) => {
            onBulkReject(totalSelected, reason, notes);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(withTranslation()(HiringBulkActions));
