import React from 'react';
import moment from 'moment';
import { Row, Col, Typography, Alert, Tooltip } from 'antd';
import { Trans, withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { CheckCircleOutlined, ExclamationCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { checkUnconfirmedClockOutEntry } from '../../utils/status';
import {
  timesheetDisputeReason,
  timesheetEntryStatuses,
  TIME_FORMAT_24_HOUR,
  timesheetEntryPaymentStatus,
} from '../../../../constants';
import { colors } from '../../../../styles/colors';
import AttendanceStatusTag from '../AttendanceStatusTag';
import TimesheetActions from './TimesheetActions';
import { getTotalBreakDuration } from '../../../../utilities/timesheetUtils';
import { getFormattedDurationInHoursAndMinutes } from '../../../../utilities/durationUtils';
import TimesheetItemTooltip from './TimesheetItemTooltip';

const { Text } = Typography;

export default withTranslation()(
  ({
    t,
    timesheetEntry,
    loading,
    handleApproveTimesheet,
    handleConfirmTimesheet,
    onActionComplete,
    setShowVoidModal,
    setSelectedTimesheetEntry,
    setShowEditTimesheetModal,
    headerWarnings,
    voidReasons,
    timeRoundingRules,
  }) => {
    const {
      clockInTime,
      clockOutTime,
      supervisorConfirmed,
      disputedReason,
      roundedClockInTime,
      roundedClockOutTime,
      totalWorkDurationInMinutes,
      status,
      breaks,
    } = timesheetEntry;
    const { rounding_quantum_in_minutes: roundingQuantumInMinutes } = timeRoundingRules;

    const totalBreakDuration = getTotalBreakDuration(breaks);
    const totalBreak = getFormattedDurationInHoursAndMinutes(totalBreakDuration);

    const totalWork = getFormattedDurationInHoursAndMinutes(totalWorkDurationInMinutes);

    const isUnconfirmedClockOutEntry = checkUnconfirmedClockOutEntry(supervisorConfirmed, status);
    const showWarning = isUnconfirmedClockOutEntry || status === timesheetEntryStatuses.DISPUTED;
    const warnings = [...headerWarnings];
    // TODO remove this when dipute is deprecated
    if (
      showWarning &&
      disputedReason &&
      ![
        ...timesheetDisputeReason.NO_LOCATION,
        ...timesheetDisputeReason.NO_LOCATION_AND_SELFIE,
        ...timesheetDisputeReason.WRONG_LOCATION,
      ].includes(disputedReason.code)
    ) {
      warnings.push(disputedReason.description);
    }
    const voidReason = voidReasons.find(reason => reason.id === timesheetEntry.voidedReason?.id);
    const TimeEntryComponent = ({ title, value, extraInfo, extraInfoTooltipMsg }) => (
      <>
        <Row>
          <Text style={{ fontWeight: 600, fontSize: 12, color: colors.secondaryText, lineHeight: '20px' }}>
            {title.toUpperCase()}
          </Text>
        </Row>
        <Row>
          <Text style={{ fontWeight: 600, fontSize: 20, color: colors.plaintText, lineHeight: '28px' }}>{value}</Text>
        </Row>
        {extraInfo && (
          <Row>
            <Text style={{ fontWeight: 400, fontSize: 14, color: colors.secondaryText, lineHeight: '22px' }}>
              {extraInfo}
              <Tooltip
                title={extraInfoTooltipMsg}
                overlayStyle={{ maxWidth: '900px' }}
                color={colors.white}
                placement="bottom"
              >
                <QuestionCircleOutlined
                  style={{ verticalAlign: 'middle', color: colors.secondaryText, size: '10px', marginLeft: 5 }}
                />
              </Tooltip>
            </Text>
          </Row>
        )}
      </>
    );

    const showOnlyExactTime = roundingQuantumInMinutes === 1;
    const clockIn = roundedClockInTime || clockInTime;
    const clockOut = roundedClockOutTime || clockOutTime;

    return (
      <Col style={{ width: '100%', marginLeft: 60, padding: '8px 0px' }}>
        {isEmpty(breaks) ? (
          <Row style={{ display: 'flex', width: '100%' }}>
            <Col span={9}>
              <TimeEntryComponent
                title={t('clockIn')}
                value={clockIn ? moment(clockIn).format('DD MMM, HH:mm') : '--:--'}
                {...(!showOnlyExactTime && {
                  extraInfo: clockInTime ? moment(clockInTime).format('DD MMM, HH:mm') : '--:--',
                  extraInfoTooltipMsg: (
                    <TimesheetItemTooltip
                      clientName={timesheetEntry?.client?.name}
                      roundingMinutes={roundingQuantumInMinutes}
                      recordedClockInOut={
                        <Trans
                          i18nKey="recordedClockInTime"
                          values={{
                            exact: moment(clockInTime).format(TIME_FORMAT_24_HOUR),
                            rounded: moment(roundedClockInTime || clockInTime).format(TIME_FORMAT_24_HOUR),
                          }}
                        />
                      }
                    />
                  ),
                })}
              />
            </Col>
            <Col span={10}>
              <TimeEntryComponent
                title={t('clockOut')}
                value={clockOut ? moment(clockOut).format('DD MMM, HH:mm') : '--:--'}
                {...(!showOnlyExactTime && {
                  extraInfo: clockOutTime ? moment(clockOutTime).format('DD MMM, HH:mm') : '--:--',
                  extraInfoTooltipMsg: (
                    <TimesheetItemTooltip
                      clientName={timesheetEntry?.client?.name}
                      roundingMinutes={roundingQuantumInMinutes}
                      recordedClockInOut={
                        <Trans
                          i18nKey="recordedClockOutTime"
                          values={{
                            exact: moment(clockOutTime).format(TIME_FORMAT_24_HOUR),
                            rounded: moment(roundedClockOutTime || clockOutTime).format(TIME_FORMAT_24_HOUR),
                          }}
                        />
                      }
                    />
                  ),
                })}
              />
            </Col>
            <Col span={5}>
              <TimeEntryComponent title={t('totalWork')} value={totalWork} />
            </Col>
          </Row>
        ) : (
          <>
            <Row style={{ display: 'flex', width: '100%', height: 48, marginBottom: '1em' }}>
              <Col span={9}>
                <TimeEntryComponent
                  title={t('clockIn')}
                  value={clockIn ? moment(clockIn).format('DD MMM, HH:mm') : '--:--'}
                  {...(!showOnlyExactTime && {
                    extraInfo: clockInTime ? moment(clockInTime).format('DD MMM, HH:mm') : '--:--',
                    extraInfoTooltipMsg: (
                      <TimesheetItemTooltip
                        clientName={timesheetEntry?.client?.name}
                        roundingMinutes={roundingQuantumInMinutes}
                        recordedClockInOut={t('recordedClockInTime', {
                          time: moment(roundedClockInTime).format(TIME_FORMAT_24_HOUR),
                        })}
                      />
                    ),
                  })}
                />
              </Col>
              <Col span={10}>
                <TimeEntryComponent
                  title={t('clockOut')}
                  value={clockOut ? moment(clockOut).format('DD MMM, HH:mm') : '--:--'}
                  {...(!showOnlyExactTime && {
                    extraInfo: clockOutTime ? moment(clockOutTime).format('DD MMM, HH:mm') : '--:--',
                    extraInfoTooltipMsg: (
                      <TimesheetItemTooltip
                        clientName={timesheetEntry?.client?.name}
                        roundingMinutes={roundingQuantumInMinutes}
                        recordedClockInOut={t('recordedClockOutTime', {
                          time: moment(roundedClockOutTime).format(TIME_FORMAT_24_HOUR),
                        })}
                      />
                    ),
                  })}
                />
              </Col>
            </Row>
            <Row style={{ display: 'flex', width: '100%', height: 48 }}>
              <Col span={9}>
                <TimeEntryComponent title={t('totalWork')} value={totalWork} />
              </Col>
              <Col span={10}>
                <TimeEntryComponent title={t('totalBreak')} value={totalBreak} />
              </Col>
            </Row>
          </>
        )}
        {showWarning && !isEmpty(warnings) && (
          <Row
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              width: '100%',
              background: colors.tagYellow,
              boxSizing: 'border-box',
              borderRadius: '2px',
              padding: '9px 16px',
              marginTop: 16,
              border: '1px solid #FFE58F',
            }}
          >
            <Row style={{ width: '100%' }}>
              <Col span={1}>
                <ExclamationCircleFilled style={{ color: colors.functionalWarning }} />
              </Col>
              <Col span={23}>
                {warnings.map((warning, index) => (
                  <div key={`warning-${timesheetEntry.id}-${index}`}>
                    <Text style={{ fontSize: 14, color: colors.primaryText, lineHeight: '22px', marginLeft: '9px' }}>
                      {`${warning}`}
                    </Text>
                    <br />
                  </div>
                ))}
              </Col>
            </Row>
          </Row>
        )}
        {timesheetEntry.status === timesheetEntryStatuses.VOID && voidReason && (
          <Row style={{ width: '100%', marginTop: 16 }}>
            <Alert message={voidReason.name} type="warning" showIcon />
          </Row>
        )}
        <Row style={{ display: 'flex', width: '100%', height: 32, marginTop: 16 }}>
          <Col span={10} style={{ display: 'flex', alignItems: 'center' }}>
            <AttendanceStatusTag timesheetEntry={timesheetEntry} />
          </Col>
          {timesheetEntry?.paymentStatus === timesheetEntryPaymentStatus.PAID && (
            <Col span={3} style={{ display: 'flex', alignItems: 'center' }}>
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleOutlined style={{ color: colors.functionalApproved }} />{' '}
                <Text style={{ color: colors.functionalApproved }}>{t('paid').toUpperCase()}</Text>
              </Row>
            </Col>
          )}
          <Col
            span={timesheetEntry?.paymentStatus === timesheetEntryPaymentStatus.PAID ? 11 : 14}
            style={{ display: 'flex' }}
          >
            <TimesheetActions
              loading={loading}
              timesheetEntry={timesheetEntry}
              handleEdit={event => {
                event.stopPropagation();
                setSelectedTimesheetEntry(timesheetEntry);
                setShowEditTimesheetModal(true);
              }}
              handleVoid={event => {
                event.stopPropagation();
                setSelectedTimesheetEntry(timesheetEntry);
                setShowVoidModal(true);
              }}
              handleConfirm={async event => {
                event.stopPropagation();
                await handleConfirmTimesheet(timesheetEntry);
                onActionComplete();
              }}
              handleApprove={async event => {
                event.stopPropagation();
                await handleApproveTimesheet(timesheetEntry);
                onActionComplete();
              }}
            />
          </Col>
        </Row>
      </Col>
    );
  },
);
