import React, { useState } from 'react';
import { Upload, Skeleton, message } from 'antd';
import { PictureOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { imageTypes } from '../../../constants';

const StyledImagePreviewActionsContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.55);

  button {
    background-color: transparent;
    border: 0;
    margin-bottom: 8px;
    color: ${colors.white};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledImagePreview = styled('div')`
  width: 270px;
  height: 180px;
  position: relative;
  margin: 24px auto;

  .ant-skeleton-element {
    width: 100%;
    height: 100%;
  }

  .ant-skeleton-image-svg {
    width: 100px;
    height: 100px;
  }

  &:hover ${StyledImagePreviewActionsContainer} {
    display: flex;
  }
`;

const StyledSkeletonImage = styled(Skeleton.Image)`
  .ant-skeleton-image {
    width: 100%;
    height: 100%;
  }

  .ant-skeleton-element {
    width: 100%;
    height: 100%;
  }
`;

const QuestionImageUpload = React.forwardRef(
  ({ imagePreview, onChangeImage, onSelectImageDone, onRemoveImage }, ref) => {
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    const getBase64 = (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    };

    const beforeUpload = file => {
      const isValidFileType = [imageTypes.PNG_IMAGE, imageTypes.JPG_IMAGE, imageTypes.JPEG_IMAGE].includes(file.type);
      if (!isValidFileType) {
        message.error(t('uploadImageFormatError'));
      }
      const fileSizeInMb = 4;
      const isValidFileSize = file.size / 1024 / 1024 < fileSizeInMb; // 4mb
      if (!isValidFileSize) {
        message.error(t('createQuestionUploadImageSizeError', { count: fileSizeInMb }));
      }
      return isValidFileType && isValidFileSize;
    };

    const handleUploadChange = info => {
      if (info.file.status === 'uploading') {
        setIsLoading(true);
      }

      if (info.file.status === 'done') {
        getBase64(info.file.originFileObj, url => {
          setIsLoading(false);
          onSelectImageDone(info.file.originFileObj, url);
        });
      }
    };

    return (
      <>
        <Upload
          accept=".jpg,.jpeg,.png"
          showUploadList={false}
          onChange={handleUploadChange}
          beforeUpload={beforeUpload}
          customRequest={({ onSuccess }) => {
            setTimeout(() => {
              onSuccess('ok');
            }, 500);
          }}
        >
          <div ref={ref} style={{ opacity: 0 }} />
        </Upload>

        {isLoading && (
          <StyledImagePreview>
            <StyledSkeletonImage size="large" className="ant-skeleton-active" />
          </StyledImagePreview>
        )}

        {!isLoading && imagePreview && (
          <StyledImagePreview>
            <div
              style={{
                backgroundImage: `url(${imagePreview})`,
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
              }}
            />
            <StyledImagePreviewActionsContainer>
              <button type="button" onClick={onChangeImage}>
                <PictureOutlined style={{ color: 'white', marginRight: 6 }} />
                {t('createQuestionChangeImage')}
              </button>
              <button type="button" onClick={onRemoveImage}>
                <DeleteOutlined style={{ color: 'white', marginRight: 6 }} />
                {t('createQuestionRemoveImage')}
              </button>
            </StyledImagePreviewActionsContainer>
          </StyledImagePreview>
        )}
      </>
    );
  },
);

export default QuestionImageUpload;
