import React, { useContext, useState, useEffect } from 'react';
import { generatePath, withRouter } from 'react-router';
import { Avatar, Badge, Button, Card, Col, Row, Tooltip, Typography } from 'antd';
import { InfoOutlined, TeamOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { colors } from '../../../styles/colors';
import routes from '../routes';
import { channelTypes } from '../constants';
import StatusTag from '../../../shared/components/StatusTag';
import applicationApi from '../../../services/applicationApi';
import Context from '../context';
import staffRequestUtils from '../../../utilities/staffRequestUtils';
import staffRequestApi from '../../../services/staffRequestApi';
import { getLocationState, getMember } from '../../../utilities/chatUtils';
import { channelStatuses } from '../../../constants';

const { Grid } = Card;
const { Text, Title } = Typography;

const ChatInfo = ({ t, history, location, twilioChatChannel, selectedChannel, hoverable, style }) => {
  const context = useContext(Context);
  const [applicationStatus, setApplicationStatus] = useState('');
  const [isInterviewed, setIsInterviewed] = useState(false);
  const [isShortlisted, setIsShortlisted] = useState(false);
  const [staffRequest, setStaffRequest] = useState();
  const [onlineStatus, setOnlineStatus] = useState('');
  const [isOnline, setIsOnline] = useState(false);

  const memberCount = selectedChannel?.members?.length || twilioChatChannel?.members?.size;
  const chatName = selectedChannel?.channel_name || twilioChatChannel?.channelState?.friendlyName;
  const member = getMember(selectedChannel);

  const getApplicationAndStaffRequest = async () => {
    const [applicationResponse, staffRequestResponse] = await Promise.all([
      applicationApi.getApplicationStatus({
        staff_requests: selectedChannel.staff_request.id,
        partners: member ? member.partner.id : 0,
      }),
      staffRequestApi.fetchStaffRequestSummarysWithTasks({
        id: selectedChannel.staff_request.id,
      }),
    ]);
    if (applicationResponse.length > 0 && applicationResponse[0].status) {
      setApplicationStatus(applicationResponse[0].status);
      setIsInterviewed(applicationResponse[0].interviewed);
      setIsShortlisted(applicationResponse[0].shortlisted);
    }
    if (staffRequestResponse.results.length > 0 && staffRequestResponse.results[0]) {
      setStaffRequest(staffRequestResponse.results[0]);
    }
  };

  const getOnlineStatus = () => {
    // Obtain the last date the user was online.
    // Reachability indicator needs to be enabled for this to work.
    // https://www.twilio.com/docs/chat/reachability-indicator
    if (selectedChannel.type === channelTypes.PRIVATE) {
      // eslint-disable-next-line no-shadow
      const member = getMember(selectedChannel);
      twilioChatChannel.participants.forEach(async twilioParticipant => {
        if (twilioParticipant.identity === member.partner?.user?.uuid) {
          const twilioUser = await twilioParticipant.getUser();
          const { date_updated, is_online } = twilioUser;

          setIsOnline(is_online);

          if (date_updated) {
            if (is_online) {
              setOnlineStatus(t('active'));
            } else {
              const lastActive = moment(date_updated).fromNow();
              setOnlineStatus(`${t('lastActive')} ${lastActive}`);
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    getApplicationAndStaffRequest();
    if (selectedChannel.type === channelTypes.PRIVATE && member) {
      getOnlineStatus();
    }
  }, []);

  let staffRequestRoute = '';
  if (staffRequest) {
    staffRequestRoute = staffRequestUtils.getSRCallToAction(
      staffRequest.status,
      staffRequest.id,
      staffRequest.applicant_tasks,
      staffRequest.client_interview_required,
    );
  }

  if (selectedChannel.type === channelTypes.PRIVATE && member) {
    return (
      <>
        <Grid
          hoverable={hoverable}
          style={{
            ...style,
            boxShadow: 'none',
            borderWidth: '1px 0',
            borderColor: colors.backgroundGrey,
            borderStyle: 'solid',
          }}
        >
          <Row type="flex" justify="space-between">
            <Col span={3}>
              <Avatar
                shape="circle"
                size={60}
                className="v2-avatar-wrapper"
                src={member.partner.image}
                style={{ marginRight: 14 }}
              />
            </Col>
            <Col span={18} style={{ display: 'flex', flexDirection: 'column' }}>
              <Title ellipsis level={4} style={{ marginBottom: 0 }}>
                {`${member.partner.first_name} ${member.partner.last_name}`}
              </Title>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Text type="secondary" style={{ marginRight: 8 }}>
                  {isOnline && <Badge status="success" />}
                  {onlineStatus}
                </Text>
                {selectedChannel.status === channelStatuses.ARCHIVED && <StatusTag status={selectedChannel.status} />}
              </div>
            </Col>
            <Col span={2} style={{ textAlign: 'right' }}>
              <Tooltip placement="topRight" title={t('chatInfo')} arrowPointAtCenter>
                <Button
                  className="chat-info-button"
                  shape="circle"
                  size="small"
                  icon={<InfoOutlined />}
                  style={{ borderColor: colors.black, borderWidth: 2 }}
                  onClick={() => {
                    const route = generatePath(routes.openChatInfo, {
                      channelId: selectedChannel?.id,
                    });
                    history.push(route, {
                      ...getLocationState(location),
                      channel: selectedChannel,
                      staffRequestRoute,
                    });
                  }}
                  disabled={!selectedChannel}
                />
              </Tooltip>
            </Col>
          </Row>
        </Grid>
        <Grid
          hoverable={hoverable}
          style={{
            ...style,
            boxShadow: 'none',
            borderBottom: `1px solid ${colors.backgroundGrey}`,
          }}
        >
          <Row type="flex" justify="space-between">
            <Col span={20} style={{ display: 'flex', flexDirection: 'column' }}>
              <Text
                type="secondary"
                ellipsis={{ tooltip: true }}
                strong
                style={{ fontSize: 12, marginBottom: 5, textTransform: 'uppercase' }}
              >
                {selectedChannel.staff_request.title}
              </Text>
              <div>
                {applicationStatus && (
                  <StatusTag status={applicationStatus} interview={isInterviewed} shortlisted={isShortlisted} />
                )}
              </div>
            </Col>
            <Col span={4} style={{ lineHeight: 0, textAlign: 'right' }}>
              <Button
                type="link"
                style={{
                  border: '0 none',
                  fontSize: 12,
                  fontWeight: 700,
                  height: 'auto',
                  padding: 0,
                  textTransform: 'uppercase',
                }}
                onClick={() => {
                  if (staffRequestRoute) {
                    // Force reload the page
                    context.browserHistory.push('');
                    setTimeout(() => {
                      context.browserHistory.push(staffRequestRoute);
                    });
                  }
                }}
              >
                {t('viewJob')}
              </Button>
            </Col>
          </Row>
        </Grid>
      </>
    );
  }

  if (selectedChannel.type === channelTypes.BROADCAST) {
    return (
      <>
        <Grid hoverable={hoverable} style={style}>
          <Row type="flex" justify="space-between">
            <Col span={3}>
              <Avatar
                shape="circle"
                size={60}
                className="v2-avatar-wrapper"
                style={{ backgroundColor: '#9da3a5', marginRight: 14 }}
              >
                <TeamOutlined style={{ fontSize: '16px', marginRight: '1px' }} />
                {memberCount}
              </Avatar>
            </Col>
            <Col span={18} style={{ display: 'flex', flexDirection: 'column' }}>
              <Title ellipsis level={4} style={{ marginBottom: 0 }}>
                {chatName}
              </Title>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Text type="secondary" style={{ marginRight: 8 }}>
                  {isOnline && <Badge status="success" />}
                  {onlineStatus}
                </Text>
                {selectedChannel.status === channelStatuses.ARCHIVED && <StatusTag status={selectedChannel.status} />}
              </div>
            </Col>
            <Col span={2} style={{ textAlign: 'right' }}>
              <Tooltip placement="topRight" title={t('groupInfo')} arrowPointAtCenter>
                <Button
                  className="chat-info-button"
                  shape="circle"
                  size="small"
                  icon={<InfoOutlined />}
                  style={{ borderColor: colors.black, borderWidth: 2 }}
                  onClick={() => {
                    const route = generatePath(routes.openChatInfo, {
                      channelId: selectedChannel?.id,
                    });
                    history.push(route, {
                      ...getLocationState(location),
                      channel: selectedChannel,
                    });
                  }}
                  disabled={!selectedChannel}
                />
              </Tooltip>
            </Col>
          </Row>
        </Grid>
        <Grid
          hoverable={hoverable}
          style={{
            ...style,
            boxShadow: 'none',
            borderBottom: `1px solid ${colors.backgroundGrey}`,
          }}
        >
          <Row type="flex" justify="space-between">
            <Col span={20} style={{ display: 'flex', flexDirection: 'column' }}>
              <Text
                type="secondary"
                ellipsis={{ tooltip: true }}
                strong
                style={{ fontSize: 12, textTransform: 'uppercase' }}
              >
                {selectedChannel.staff_request.title}
              </Text>
            </Col>
            <Col span={4} style={{ lineHeight: 0, textAlign: 'right' }}>
              <Button
                type="link"
                style={{
                  border: '0 none',
                  fontSize: 12,
                  fontWeight: 700,
                  height: 'auto',
                  padding: 0,
                  textTransform: 'uppercase',
                }}
                onClick={() => {
                  if (staffRequestRoute) {
                    // Force reload the page
                    context.browserHistory.push('');
                    setTimeout(() => {
                      context.browserHistory.push(staffRequestRoute);
                    });
                  }
                }}
              >
                {t('viewJob')}
              </Button>
            </Col>
          </Row>
        </Grid>
      </>
    );
  }

  return <></>;
};

export default withTranslation()(withRouter(ChatInfo));
