import React from 'react';
import moment from 'moment-timezone';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, message, InputNumber, Radio, Modal, Typography, Button, Col, Alert, Select } from 'antd';
import { withTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';

import shiftApi from '../../../services/shiftApi';
import scheduleApi from '../../../services/scheduleApi';
import { colors } from '../../../styles/colors';

const { Item } = Form;
const { Title, Text } = Typography;
const { Option } = Select;

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const shiftEditTypes = {
  SINGLE_EDIT: 1,
  BULK_EDIT: 2,
};
class EditShiftFormModal extends React.Component {
  state = {
    isSubmitting: false,
    applyChangeValue: shiftEditTypes.SINGLE_EDIT,
    showShiftCancelWarning: false,
    shiftRoles: [],
  };

  componentDidMount() {
    const { shift } = this.props;
    this.setState({
      shiftRoles: cloneDeep(shift.shift_roles),
    });
  }

  onChange = e => {
    this.setState({
      applyChangeValue: e.target.value,
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { applyChangeValue, shiftRoles } = this.state;
    const { form, shift, schedule, onUpdate, t } = this.props;

    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ isSubmitting: true });
        let shift_staff_required = 0;
        const updatedShiftRoles = shiftRoles.map(shiftRole => {
          shift_staff_required += shiftRole.staff_required;
          return { id: shiftRole.id, role: shiftRole.role.id, staff_required: shiftRole.staff_required };
        });
        try {
          if (applyChangeValue === shiftEditTypes.BULK_EDIT) {
            await scheduleApi.bulkEditShifts(schedule.id, shift.id, {
              staff_required: values.staff_required || shift_staff_required,
              start_time: shift.start_time,
              duration: shift.duration,
              qr_override_allowed: shift.qr_override_allowed,
              qr_code_clock_in: true,
              fixed_location: shift.fixed_location,
              updated_roles: updatedShiftRoles,
            });
          } else {
            await shiftApi.editShift({
              id: shift.id,
              staff_required: values.staff_required || shift_staff_required,
              qr_override_allowed: shift.qr_override_allowed,
              qr_code_clock_in: true,
              fixed_location: shift.fixed_location,
              shift_roles: updatedShiftRoles,
            });
          }
          onUpdate();
          message.success(t('saveShiftsSuccess'));
        } catch (error) {
          message.warning(t('saveShiftsError'));
        } finally {
          this.setState({ isSubmitting: false, showShiftCancelWarning: false });
        }
      }
    });
  };

  handleCancel = () => {
    this.setState({ showShiftCancelWarning: false });
    this.props.onCancel();
  };

  render() {
    const { schedule, shift, date, visible, t, openDeleteShiftModal } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { isSubmitting, applyChangeValue, showShiftCancelWarning, shiftRoles } = this.state;

    return (
      <Modal
        visible={visible}
        onCancel={() => this.handleCancel()}
        title={
          <Title level={4} style={{ paddingBottom: 0, marginBottom: 0 }}>
            {moment(date).format('ddd, DD MMM YYYY')}
          </Title>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              style={{ color: colors.red }}
              onClick={() => {
                openDeleteShiftModal();
              }}
            >
              {t('delete')}
            </Button>
            <div>
              <Button onClick={() => this.handleCancel()}>{t('cancel')}</Button>
              <Button
                type="v2-primary"
                loading={isSubmitting}
                onClick={e => {
                  this.handleSubmit(e);
                }}
              >
                {t('Save')}
              </Button>
            </div>
          </div>
        }
      >
        <Form hideRequiredMark colon={false} className="v2-shifts-form">
          <Row type="flex" gutter={24}>
            <Col span={24}>
              <Text strong>
                {`${schedule.name} • ${moment(schedule.start_time, ['HH.mm']).format(
                  'h:mmA',
                )}-${moment(schedule.end_time, ['HH.mm']).format('h:mmA')}`}
              </Text>
            </Col>
            <Col span={24} style={{ marginBottom: '10px' }}>
              {t('editNumberOfWorkersNeeded')}
            </Col>
            <Col span={24}>
              <Item label={t('Position')} style={{ marginBottom: '4px' }}>
                {getFieldDecorator('position', {
                  rules: [{ required: true, message: t('fieldIsRequired') }],
                  initialValue: shift.position.id,
                })(
                  <Select placeholder={t('selectPosition')} style={{ width: '50%' }} disabled>
                    <Option key={shift.position.id} value={shift.position.id}>
                      <Typography.Text>{t(`${shift.position.name}`)}</Typography.Text>
                    </Option>
                  </Select>,
                )}
              </Item>
            </Col>
            {shiftRoles.length === 0 && (
              <Col span={24}>
                <Item label={t('workerNeeded')} style={{ marginBottom: '4px' }}>
                  {getFieldDecorator('staff_required', {
                    rules: [{ required: true, message: t('fieldIsRequired') }],
                    initialValue: shift?.staff_required,
                  })(<InputNumber min={0} style={{ width: 'calc(50% - 12px)' }} />)}
                </Item>
              </Col>
            )}
            {shiftRoles.length > 0 && (
              <Col span={24}>
                <Row gutter={10} style={{ width: '100%', marginBottom: 16 }}>
                  <Col span={8}>
                    <Text style={{ color: colors.black }}>{t('workersRole')}</Text>
                  </Col>
                  <Col>
                    <Text style={{ color: colors.black }}>{t('workersNeededPerDay')}</Text>
                  </Col>
                </Row>
                {shiftRoles.map(item => {
                  return (
                    item.role.is_active && (
                      <Row key={item.role.id} gutter={10} style={{ width: '100%' }}>
                        <Col span={8}>
                          <Text style={{ width: 100, marginLeft: 8 }} ellipsis>{`${item.role.name}`}</Text>
                        </Col>
                        <Col>
                          <Item style={{ marginBottom: 0 }}>
                            <InputNumber
                              min={0}
                              defaultValue={item.staff_required}
                              style={{ width: '50%' }}
                              onChange={value => {
                                const updatedRoles = cloneDeep(shiftRoles);
                                const roleToUpdate = updatedRoles.find(shiftRole => shiftRole.role.id === item.role.id);
                                roleToUpdate.staff_required = value || 0;
                                this.setState({
                                  shiftRoles: updatedRoles,
                                });
                              }}
                            />
                          </Item>
                        </Col>
                      </Row>
                    )
                  );
                })}
              </Col>
            )}
          </Row>
        </Form>

        <Row style={{ marginTop: '10px' }}>
          <Text>{t('applyChangesTo')}</Text>
        </Row>
        <Radio.Group onChange={this.onChange} value={applyChangeValue}>
          <Radio style={radioStyle} value={shiftEditTypes.SINGLE_EDIT}>
            {t('thisShift')}
          </Radio>
          <Radio style={radioStyle} value={shiftEditTypes.BULK_EDIT}>
            {t('allShifts')}
          </Radio>
        </Radio.Group>
        {showShiftCancelWarning && (
          <Alert
            style={{ margin: '12px 0' }}
            showIcon
            type="warning"
            message={
              <>
                <Row>{t('reduceScheduleWarningTitle')}:</Row>
                <Row>• {t('reduceScheduleWarningPoint1')}</Row>
                <Row>• {t('reduceScheduleWarningPoint2')}</Row>
              </>
            }
          />
        )}
      </Modal>
    );
  }
}
export default Form.create()(withTranslation()(EditShiftFormModal));
