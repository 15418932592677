import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col, Card, Typography, Spin } from 'antd';

import { colors } from '../../../styles/colors';

const { Text } = Typography;

const TotalWorkforceCard = ({ t, loading, totalWorkforceCount }) => {
  return (
    <Card
      style={{
        backgroundColor: colors.infoHighlightBlue,
        border: `0.5px solid ${colors.componentBorder}`,
        borderRadius: 4,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
      }}
      bodyStyle={{
        padding: '16px 24px',
      }}
    >
      <Row>
        <Col>
          <Text style={{ fontWeight: 600, fontSize: 12, lineHeight: '20px', color: colors.white }}>
            {t('totalWorkforce').toUpperCase()}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text style={{ fontWeight: 600, fontSize: 24, lineHeight: '32px', color: colors.white }}>
            {loading ? (
              <Spin className="total-workforce-card-spinner" data-testid="total-workforce-card-spinner" />
            ) : (
              totalWorkforceCount
            )}
          </Text>
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(TotalWorkforceCard);
