import React, { useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Row, Col, Typography, Card, Avatar, List, Button } from 'antd';
import { withTranslation } from 'react-i18next';

import { colors } from '../../../styles/colors';

const { Text, Title } = Typography;

const EducationCard = ({ education, t }) => {
  const [list, setList] = useState(education.slice(0, 1));
  const [expanded, setExpansion] = useState(false);
  return (
    <Card bodyStyle={{ padding: 0 }} bordered={false}>
      <Row type="flex" justify="start" align="middle" style={{ marginBottom: '8px' }}>
        <Title
          style={{
            fontSize: '20px',
            lineHeight: '28px',
            fontWeight: 600,
            color: colors.textTitle,
            marginBottom: 0,
            marginRight: 8,
          }}
        >
          {t('education')}
        </Title>
      </Row>
      <Row type="flex" align="middle">
        <Col span={24}>
          <List
            itemLayout="horizontal"
            loadMore={
              education.length > 1 && (
                <Row type="flex" justify="space-between" style={{ marginTop: '8px' }}>
                  <Col>
                    <Button
                      type="link"
                      style={{ marginTop: 0, padding: 0, fontWeight: 600, color: colors.infoHighlightBlue }}
                      onClick={() => {
                        setExpansion(!expanded);
                        setList(expanded ? education.slice(0, 1) : education);
                      }}
                    >
                      {expanded ? t('showLessEducation') : t('showAllEducation')}
                      <LegacyIcon type={expanded ? 'up' : 'down'} />
                    </Button>
                  </Col>
                </Row>
              )
            }
            dataSource={list}
            renderItem={item => (
              <List.Item key={item.id} className="no-border" style={{ padding: '16px 0' }}>
                <List.Item.Meta
                  avatar={<Avatar shape="square" size={48} style={{ height: '100%' }} src={item.imgSrc} />}
                  title={
                    <Text strong style={{ color: colors.textTitle }}>
                      {item.title}
                    </Text>
                  }
                  description={
                    <>
                      <Text strong style={{ color: colors.primaryText }}>
                        {item.subTitle}
                      </Text>
                      <br />
                      <Text strong style={{ color: colors.primaryText }}>
                        {item.period}
                      </Text>
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(EducationCard);
