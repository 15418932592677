const getFormattedValue = value => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const formatAmount = (amount = 0, currencyCode) => {
  return parseFloat(amount)
    .toFixed(2)
    .toLocaleString(undefined, {
      style: 'currency',
      currency: currencyCode,
    });
};

const amountUtils = {
  getFormattedValue,
  formatAmount,
};

export default amountUtils;
