import React from 'react';
import { Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { applicationStatuses, autoRejectStatuses } from '../../../constants';
import { colors } from '../../../styles/colors';

const { Text } = Typography;

const ApplicationStatus = ({ client, time, t, application }) => {
  const greenStatuses = [applicationStatuses.APPLIED, applicationStatuses.PENDING_CONTRACT];
  const redStatuses = [applicationStatuses.REJECTED, applicationStatuses.WITHDRAWN];
  const {
    approval_date: approvalDate,
    contract_view_time: contractViewTime,
    last_remind_time: lastRemindTime,
    status,
  } = application;
  let formattedStatus = t(status);

  if (greenStatuses.includes(status)) {
    let color = colors.functionalSuccess;
    let formattedTime = time;
    if (application.interviewed) {
      formattedStatus = t('markedForInterview');
    }
    if (application.shortlisted) {
      formattedStatus = t('waitlisted');
    }
    if (status === applicationStatuses.PENDING_CONTRACT) {
      color = colors.black;
      formattedStatus = client.contract_required ? t('contractSent') : t('jobOfferSent');
      const sortedActivities = [
        {
          activityStatus: formattedStatus,
          activityTime: approvalDate ? moment(approvalDate).toNow(true) : null,
          date: approvalDate ? moment(approvalDate) : moment(0),
        },
        {
          activityStatus: client.contract_required ? t('contractSeen') : t('jobOfferSeen'),
          activityTime: contractViewTime ? moment(contractViewTime).toNow(true) : null,
          date: contractViewTime ? moment(contractViewTime) : moment(0),
        },
        {
          activityStatus: t('applicationReminderSent'),
          activityTime: lastRemindTime ? moment(lastRemindTime).toNow(true) : null,
          date: lastRemindTime ? moment(lastRemindTime) : moment(0),
        },
      ].sort((a, b) => b.date.valueOf() - a.date.valueOf());
      if (sortedActivities[0] !== null) {
        const { activityStatus, activityTime } = sortedActivities[0];
        formattedStatus = activityStatus;
        formattedTime = activityTime;
      }
    }

    if (!formattedTime) {
      return null;
    }

    return (
      <>
        <Text style={{ color, fontWeight: 'bold' }}>{formattedStatus}</Text>{' '}
        <Text type="secondary">
          {formattedTime} {t('ago')}
        </Text>
      </>
    );
  }
  if (redStatuses.includes(status)) {
    const rejectionReasonCode = application?.reject_reason?.code || application?.rejection_reason;
    const statusColor =
      status === applicationStatuses.REJECTED && rejectionReasonCode === autoRejectStatuses.failedToSignContract
        ? colors.black
        : colors.functionalError;
    const statusText =
      status === applicationStatuses.REJECTED && rejectionReasonCode === autoRejectStatuses.failedToSignContract
        ? t('offerExpired')
        : formattedStatus;

    return (
      <>
        <Text style={{ color: statusColor, fontWeight: 'bold' }}>{statusText}</Text>{' '}
        <Text type="secondary">
          {time} {t('ago')}
        </Text>
      </>
    );
  }
  // Default
  return null;
};

export default withTranslation()(ApplicationStatus);
