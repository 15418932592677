import React from 'react';
import { Col, Radio, Row } from 'antd';
import { choiceTypes } from '../../../constants';
import SetKnockout from './SetKnockout';
import MCQTextDescription from './MCQTextDescription';
import OtherPleaseSpecify from './OtherPleaseSpecify';

const SingleSelect = ({ choices = [], isClicked, questionId, readonly = true }) => {
  return (
    <Row>
      {choices.map(choice => {
        const hasScore = choice?.score > 0;
        return (
          <Row style={{ width: '100%' }} key={choice.id}>
            <Col style={{ flex: 1, pointerEvents: readonly ? 'none' : 'auto' }}>
              <Radio value={choice.id} style={{ marginBottom: 10 }} className="v2-radio-wrapper" checked={hasScore}>
                {choice.type === choiceTypes.FREE_TEXT ? (
                  <OtherPleaseSpecify />
                ) : (
                  <MCQTextDescription hasScore={hasScore} knockout={choice.knockout} description={choice.description} />
                )}
              </Radio>
            </Col>
            {isClicked && !hasScore && (
              <Col style={{ margin: '0 10px' }}>
                <SetKnockout questionId={questionId} choiceId={choice.id} initialKnockout={choice.knockout} />
              </Col>
            )}
          </Row>
        );
      })}
    </Row>
  );
};

export default SingleSelect;
