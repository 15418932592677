import React from 'react';
import { Card, Typography } from 'antd';
import { RichText } from 'prismic-reactjs';

const { Title, Paragraph } = Typography;
const ArticleDisplay = ({ loading, article, articleTitle }) => {
  return (
    <Card bordered={false} loading={loading} bodyStyle={{ padding: 0 }}>
      <Title level={2}>{articleTitle}</Title>
      <Paragraph style={{ marginBottom: '32px' }}>{RichText.render(article.data.description)}</Paragraph>
      {article.data.sections.map((section, index) => (
        <section key={index} style={{ marginBottom: '32px' }}>
          <Title level={3}>{section.section_title[0] ? section.section_title[0].text : ''}</Title>
          <Paragraph className="v2-article-wrapper">{RichText.render(section.body)}</Paragraph>
        </section>
      ))}
    </Card>
  );
};

export default ArticleDisplay;
