import _ from 'lodash';
import queryString from 'query-string';

export const updateUrlWithParams = (params, history) => {
  const stringified = queryString.stringify(params);
  history.push(`?${stringified}`);
};

export const retrieveParamsFromUrl = url => {
  return queryString.parse(url);
};

export const prepParams = (params, toStringArray = false) => {
  if (typeof params === 'undefined') {
    return {};
  }

  const updatedParams = {};
  Object.entries(params).forEach(([key, value]) => {
    const snakeCaseKey = _.snakeCase(key);
    let paramValue = value;
    if (value && typeof value === 'object') {
      paramValue = prepParams(value);
    }
    if (value && Array.isArray(value)) {
      paramValue = toStringArray ? value.map(item => (item === 'null' ? null : `${item}`)) : value.join(',');
    }
    updatedParams[snakeCaseKey] = paramValue;
  });
  return updatedParams;
};

export const getPageFromURL = string => {
  if (!string) return;

  const url = new URL(string);

  return url.searchParams.get('page');
};
