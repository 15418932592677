// TODO: Refactor out into helpster-components library
import React from 'react';
import { Typography, Row, Col } from 'antd';

const { Title, Text } = Typography;

const EmptyCard = ({ title, description, imgSrc }) => (
  <>
    <Row justify="center" style={{ marginBottom: '16px' }}>
      <Col>
        <img src={imgSrc} alt="empty_result" />
      </Col>
    </Row>
    <Row type="flex" justify="center">
      <Title level={4}>{title}</Title>
    </Row>
    <Row justify="center">
      <Col>
        <Text type="secondary">{description}</Text>
      </Col>
    </Row>
  </>
);

export default EmptyCard;
