import React from 'react';
import { withTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import { Typography, Modal, Avatar } from 'antd';

const SelfieModal = ({ t, title, visible, selfie, onCancel }) => {
  if (!visible) return <> </>;
  return (
    <Modal
      title={<Typography.Title level={3}>{`${t(title)}`}</Typography.Title>}
      centered
      visible={visible}
      onCancel={onCancel}
      width={432}
      height={460}
      bodyStyle={{ padding: '16px' }}
      footer={null}
    >
      <Avatar shape="square" src={selfie} size={400} icon={<UserOutlined />} />
    </Modal>
  );
};

export default withTranslation()(SelfieModal);
