import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Modal, Row, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../styles/colors';

const DeactivatedQuestionsModal = ({ applicationForm, visible, onCancel, onOkay }) => {
  const { t } = useTranslation();

  const { inactiveQuestions, selectedForm } = applicationForm;

  return (
    <Modal visible={visible} onCancel={onCancel} title={null} width="600px" footer={null} closable={false}>
      <Row style={{ flexWrap: 'nowrap' }}>
        <Col>
          <ExclamationCircleOutlined style={{ color: colors.blue, fontSize: 20 }} />
        </Col>
        <Col style={{ flex: 1, marginLeft: 16 }}>
          <Typography.Title level={5}>{t('formImportedPartiallyHeading')}</Typography.Title>
          <Typography.Text style={{ color: colors.primaryText, marginBottom: 8, display: 'block' }}>
            {t('formImportedPartiallyDescription', { count: inactiveQuestions.length, formName: selectedForm?.title })}
          </Typography.Text>
          {inactiveQuestions.map((question, index) => (
            <Row key={question.id} style={{ marginBottom: 6 }}>
              <Typography.Text>
                {index + 1}. {question.title}
              </Typography.Text>
            </Row>
          ))}
          <Row style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
            <Button type="primary" onClick={onOkay}>
              {t('OK')}
            </Button>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

const mapStateToProps = state => ({
  applicationForm: state.applicationForm,
});

export default connect(mapStateToProps)(DeactivatedQuestionsModal);
