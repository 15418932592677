import React from 'react';
import { prismicClient } from '../config';
import ArticleDisplay from '../shared/components/ArticleDisplay';
import LoadingSpinner from '../shared/components/LoadingSpinner';

class PolicyPage extends React.Component {
  state = {
    article: undefined,
  };

  async componentDidMount() {
    const article = await prismicClient.getByUID('policy', 'policy');
    this.setState({ article });
  }

  render() {
    const { article } = this.state;
    if (!article) return <LoadingSpinner />;
    return <ArticleDisplay article={article} isLoading={false} articleTitle={article.data.title[0].text} />;
  }
}

export default PolicyPage;
