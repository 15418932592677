import React from 'react';
import moment from 'moment';
import { Alert, ConfigProvider, Col, Divider, List, Row, Select, Typography, message } from 'antd';
import * as Sentry from '@sentry/browser';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { capitalize, isEqual, isEmpty, camelCase } from 'lodash';

import * as chatActions from '../../redux/chat';
import getPaginationConfig from '../../utilities/getPaginationConfig';
import {
  applicationStatuses,
  audiences,
  genderOptions,
  inactiveStatusOptions,
  workerCovidCertificationStatuses,
  countryCodeOptions,
} from '../../constants';
import workerUtils from '../../utilities/workerUtils';
import staffRequestUtils from '../../utilities/staffRequestUtils';
import TreeCheckboxFilter from '../../shared/components/TreeCheckboxFilter';
import AgePopoverFilter from '../../shared/components/AgePopoverFilter';
import DistancePopoverFilter from '../../shared/components/DistancePopoverFilter';
import NoApplicantsIcon from '../../assets/images/no_applicants_icon.svg';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import FunnelEmptySearch from '../../shared/components/FunnelEmptySearch';
import EmptyCard from '../../shared/components/EmptyCard';

import applicationApi from '../../services/applicationApi';
import ApplicantListItem from '../StaffRequestApplicantsList/components/ApplicantListItem';
import ApplicantToggleModal from '../StaffRequestApplicantsList/components/ApplicantToggleModal';
import MoreFilters from '../../pages/StaffRequestsPage/components/MoreFilters';
// import HiringBulkActions from '../../pages/StaffRequestsPage/components/HiringBulkActions';

import chatRoutes from '../Chat/routes';

const { Text } = Typography;
const { Option } = Select;
const inactiveStatus = `${applicationStatuses.REJECTED}, ${applicationStatuses.WITHDRAWN}`;

class StaffRequestInactiveList extends React.Component {
  state = {
    isLoading: false,
    searchParams: {
      page: 1,
      staff_request: this.props.staffRequestId,
      status: inactiveStatus,
      ordering: 'quality,-created_date',
      include_counts: true,
      page_size: 20,
    },
    numRecords: 0,
    applications: undefined,
    selectedApplicantIndex: undefined,
    showApplicantToggleModal: false,
    selectedApplicationsList: [],
    checkAll: false,
    selectAllApplications: false,
    applicationCounts: {},
    appliedSearchParams: {},
    searchApplied: false,
    appliedStatusFilters: undefined,
    showSearchedList: false,
    linkToApplicationsTab: false,
    offerExpiryHour: undefined,
  };

  async componentDidMount() {
    await this.fetchApplications();
  }

  async componentDidUpdate(prevProps) {
    // Refresh contracts list if worker has been hired
    if (this.props.inactiveCounts > prevProps.inactiveCounts) {
      await this.fetchApplications();

      if (this.props.selectedWorker && this.props.selectedWorker.length > 0) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          showSearchedList: true,
        });
      }
    }

    // if tab is changed and search is applied in current tab, reset the filters
    if (
      this.props.currentActiveTabKey !== prevProps.currentActiveTabKey &&
      this.props.currentActiveTabKey !== this.props.tabKey &&
      this.state.searchApplied
    ) {
      // eslint-disable-next-line no-unused-vars, react/no-did-update-set-state
      this.setState(prevState => {
        const newSearchParams = {
          page: 1,
          ordering: prevState.searchParams.ordering,
          status: inactiveStatus,
          search: undefined,
          shortlisted: this.props?.shortlisted,
          interviewed: this.props?.interviewed,
          include_counts: true,
          page_size: 20,
          selectedDistance: undefined,
        };

        return { searchParams: newSearchParams, searchApplied: false, appliedStatusFilters: undefined };
      }, this.fetchApplicationsWithParams);
    }

    if (
      this.props.currentActiveTabKey === this.props.tabKey &&
      !isEqual(this.props.selectedWorker[0]?.id, prevProps.selectedWorker[0]?.id)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showSearchedList: true });
    } else if (
      this.props.selectedWorker.length > 0 &&
      !isEqual(this.props.currentActiveTabKey, this.props.tabKey) &&
      !isEqual(this.props.selectedWorker[0]?.id, prevProps.selectedWorker[0]?.id)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showSearchedList: false });
    }
  }

  fetchApplications = async () => {
    const { searchParams } = this.state;
    const { selectedWorker, tabKey, staffRequestId, clientId, partnerIdFromParams } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await applicationApi.list(searchParams);
      let appendedApplications = [];
      if (response.count > 0) {
        const partner_ids = response.results.map(application => application.partner.id).join(',');
        const applicationHistoryCountResponse = await applicationApi.applicationHistoryCount({
          partner_ids,
          client_id: clientId,
          staff_request_id: staffRequestId,
        });
        // TODO: handle workmate experiences in backend
        // Before adding more utils to append, this needs to be moved to BE to avoid further maintenance issues
        const applicationsWitExp = await workerUtils.appendStatisticsAndExperienceToApplications(response.results);
        appendedApplications = workerUtils.appendApplicationsCount(
          applicationsWitExp,
          applicationHistoryCountResponse.results,
        );
      }

      let selectedWorkerTab = '';
      if (selectedWorker && selectedWorker.length > 0) {
        selectedWorkerTab = await workerUtils.getApplicationTab(selectedWorker[0]);
      }

      let index = -1;
      let singleApplicationForModal = {};
      if (partnerIdFromParams && appendedApplications?.length > 0) {
        const partnerIndex = appendedApplications?.findIndex(
          application => application?.partner?.id === partnerIdFromParams,
        );

        if (partnerIndex >= 0) {
          index = partnerIndex;
        } else {
          const singleApplication = await applicationApi.list({
            staff_request: staffRequestId,
            partner: partnerIdFromParams,
            ...searchParams,
          });

          // TODO: can probably move this to a util since it is used repetitively
          if (singleApplication.count > 0) {
            const partner_ids = singleApplication.results.map(application => application.partner.id).join(',');
            const applicationHistoryCountResponse = await applicationApi.applicationHistoryCount({
              partner_ids,
              client_id: clientId,
              staff_request_id: staffRequestId,
            });
            // TODO: handle workmate experiences in backend
            // Before adding more utils to append, this needs to be moved to BE to avoid further maintenance issues
            const applicationsWitExp = await workerUtils.appendStatisticsAndExperienceToApplications(
              singleApplication.results,
            );
            singleApplicationForModal = workerUtils.appendApplicationsCount(
              applicationsWitExp,
              applicationHistoryCountResponse.results,
            );
          }
        }
      }

      this.setState({
        isLoading: false,
        numRecords: response.count,
        applications: appendedApplications,
        showSearchedList: selectedWorker && selectedWorker.length > 0 && isEqual(selectedWorkerTab, tabKey),
        selectedApplicantIndex: index,
        showApplicantToggleModal: !isEmpty(singleApplicationForModal) || index >= 0,
        linkToApplicationsTab: !!partnerIdFromParams,
        singleApplicationForModal: singleApplicationForModal[0] || {}, // get single application
      });
    } catch (err) {
      message.error(err.message);
      this.setState({ isLoading: false, applications: [], numRecords: 0 });
    }
  };

  handleSearchParamChange = (field, searchValue) => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams[field] = searchValue;
        nextSearchParams.page = 1;
        return { searchParams: nextSearchParams, searchApplied: !!searchValue };
      },
      () => this.fetchApplicationsWithParams(),
    );
  };

  onPageChange = (pageNum, pageSize) => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams.page = pageNum;
        nextSearchParams.page_size = pageSize;
        return { searchParams: nextSearchParams };
      },
      () => this.fetchApplicationsWithParams(),
    );
  };

  fetchApplicationsWithParams = async () => {
    this.setState({ isLoading: true });
    const { searchParams } = this.state;
    const { clientId, staffRequestId } = this.props;
    const response = await applicationApi.list({
      staff_request: staffRequestId,
      ...searchParams,
    });
    let appendedApplications = [];
    if (response.count > 0) {
      const partner_ids = response.results.map(application => application.partner.id).join(',');
      const applicationHistoryCountResponse = await applicationApi.applicationHistoryCount({
        partner_ids,
        client_id: clientId,
        staff_request_id: staffRequestId,
      });
      // TODO: handle workmate experiences in backend
      // Before adding more utils to append, this needs to be moved to BE to avoid further maintenance issues
      const applicationsWitExp = await workerUtils.appendStatisticsAndExperienceToApplications(response.results);
      appendedApplications = workerUtils.appendApplicationsCount(
        applicationsWitExp,
        applicationHistoryCountResponse.results,
      );
    }

    this.setState({
      isLoading: false,
      applications: appendedApplications,
      numRecords: response.count,
    });
  };

  onShortlist = async (selectedWorkerId, localApplicationIndex) => {
    const response = await applicationApi.shortlist(selectedWorkerId);
    if (response) {
      this.setState(prevState => {
        const { applications } = prevState;
        const nextApplications = applications;
        nextApplications[localApplicationIndex].shortlisted = true;
        return { applications: nextApplications };
      });
    }
  };

  onUnshortlist = async (selectedWorkerId, localApplicationIndex) => {
    const response = await applicationApi.unShortlist(selectedWorkerId);
    if (response) {
      this.setState(prevState => {
        const { applications, searchParams } = prevState;
        const nextApplications = applications;
        // If short listed filter is applied. remove row from table.
        // Else, just toggle the boolean field of the row.
        if (searchParams.shortlisted) {
          nextApplications.splice(localApplicationIndex, 1);
        } else {
          nextApplications[localApplicationIndex].shortlisted = false;
        }
        return { applications: nextApplications };
      });
    }
  };

  getEmptyCard = () => {
    const { isLoading, searchApplied } = this.state;
    const { t } = this.props;
    if (isLoading) return <></>;

    if (searchApplied) {
      return <FunnelEmptySearch clearFilters={this.handleClearFilters} />;
    }

    return (
      <EmptyCard
        title={t('noInactiveApplications')}
        description={t('inactiveWithdrawnRejectedApplications')}
        imgSrc={NoApplicantsIcon}
      />
    );
  };

  handleOpenApplicantModal = (index, linkToApplicationsTab = false) => {
    this.setState({
      selectedApplicantIndex: index,
      showApplicantToggleModal: true,
      linkToApplicationsTab,
    });
  };

  handleNewGroupChat = async () => {
    const { appliedSearchParams, selectedApplicationsList, selectAllApplications } = this.state;
    const { staffRequestId, createGroupChat, preloadChat } = this.props;
    let membersList = [...selectedApplicationsList];

    preloadChat();

    try {
      if (selectAllApplications) {
        const response = await applicationApi.applicantsList({
          staff_request: staffRequestId,
          ...appliedSearchParams,
        });

        membersList = response.map(application => ({
          ...application,
          status: application.status,
        }));
      }

      createGroupChat({
        staffRequestId,
        audience: audiences.SELECTED_APPLICANTS,
        membersList,
      });
      preloadChat(false);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  handleCheck = e => {
    const { applications, selectedApplicationsList } = this.state;

    let newSelectedApplicationsList = [];
    if (e.target.checked) {
      const index = selectedApplicationsList.findIndex(item => item.partner.id === e.target.value.partner.id);
      if (index === -1) {
        newSelectedApplicationsList = [...selectedApplicationsList, e.target.value];
      }
    } else {
      newSelectedApplicationsList = selectedApplicationsList.filter(
        item => item.partner.id !== e.target.value.partner.id,
      );
    }

    this.setState({
      selectedApplicationsList: newSelectedApplicationsList,
      checkAll: applications.length === newSelectedApplicationsList.length,
      selectAllApplications: false,
    });
  };

  handleCheckAllChange = e => {
    const { applications } = this.state;
    this.setState({
      selectedApplicationsList: e.target.checked
        ? applications.map(application => ({ ...application, status: application.status }))
        : [],
      checkAll: e.target.checked,
      selectAllApplications: false,
    });
  };

  handleSelectAllApplicants = selectAllApplications => {
    let clearSelection = {};
    if (!selectAllApplications) {
      clearSelection = {
        selectedApplicationsList: [],
        indeterminate: false,
        checkAll: false,
      };
    }

    this.setState({
      selectAllApplications,
      ...clearSelection,
    });
  };

  getApplicationCounts = () => {
    let totalApplicationCount = 0;

    const { applicationCounts } = this.state;
    // eslint-disable-next-line no-unused-vars
    Object.entries(applicationCounts).forEach(([status, count]) => {
      totalApplicationCount += count;
    });

    return totalApplicationCount;
  };

  handleApplyStatusFilter = checkedKeys => {
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      nextSearchParams.status = checkedKeys.length > 0 ? checkedKeys.join(',') : inactiveStatus;
      nextSearchParams.page = 1;
      return {
        searchParams: nextSearchParams,
        searchApplied: checkedKeys.length > 0,
        appliedStatusFilters: checkedKeys?.join(','),
      };
    }, this.fetchApplicationsWithParams);
  };

  handleApplyGenderFilter = checkedKeys => {
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      nextSearchParams.gender = checkedKeys.join(',');
      nextSearchParams.page = 1;
      return { searchParams: nextSearchParams, searchApplied: checkedKeys.length > 0 };
    }, this.fetchApplicationsWithParams);
  };

  handleApplyDistanceFilter = distance => {
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      if (distance > 0) {
        nextSearchParams.distance = distance;
      } else {
        delete nextSearchParams.distance;
      }
      nextSearchParams.page = 1;
      return { searchParams: nextSearchParams, searchApplied: distance > 0 };
    }, this.fetchApplicationsWithParams);
  };

  handleApplyAgeRangeFilter = selectedAgeRange => {
    const [minimum, maximum] = selectedAgeRange;
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      if (minimum) {
        const date_of_birth_before = moment()
          .endOf('year')
          .subtract(minimum, 'years')
          .toISOString();
        nextSearchParams.date_of_birth_before = date_of_birth_before;
        nextSearchParams.min_age = minimum;
      } else {
        delete nextSearchParams.date_of_birth_before;
        delete nextSearchParams.min_age;
      }

      if (maximum) {
        const date_of_birth_after = moment()
          .startOf('year')
          .subtract(maximum, 'years')
          .toISOString();
        nextSearchParams.date_of_birth_after = date_of_birth_after;
        nextSearchParams.max_age = maximum;
      } else {
        delete nextSearchParams.date_of_birth_after;
        delete nextSearchParams.max_age;
      }
      nextSearchParams.page = 1;
      return { searchParams: nextSearchParams, searchApplied: minimum && maximum };
    }, this.fetchApplicationsWithParams);
  };

  handleApplyCovidCertificationFilter = checkedKeys => {
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      nextSearchParams.covid_certification_status = checkedKeys.join(',');
      nextSearchParams.page = 1;
      return { searchParams: nextSearchParams, searchApplied: checkedKeys.length > 0 };
    }, this.fetchApplicationsWithParams);
  };

  handleApplyFilters = params => {
    this.setState(() => params, this.fetchApplicationsWithParams);
  };

  handleResendOffer = async (applicationId, workerName) => {
    const { t, updateParentStatusCounts, selectedWorker, updateParentSelectedWorker, staffRequestId } = this.props;
    const { offerExpiryHour } = this.state;

    try {
      const response = await applicationApi.approve(applicationId, offerExpiryHour);
      // eslint-disable-next-line no-undef
      sessionStorage.setItem(staffRequestUtils.generateOfferExpirySessionStorageKey(staffRequestId), offerExpiryHour);
      if (response) {
        const [appendedApplication] = await workerUtils.appendStatisticsAndExperienceToApplications([response]);
        await updateParentStatusCounts();
        this.setState(
          prevState => {
            const newApplications = prevState.applications;

            return {
              applications: newApplications.filter(data => data.id !== appendedApplication.id),
              selectedApplicationsList: [],
              indeterminate: false,
              checkAll: false,
              selectAllApplications: false,
            };
          },
          () => {
            if (selectedWorker && selectedWorker.length > 0) {
              updateParentSelectedWorker(appendedApplication);
            }
          },
        );
        message.success(`${t('Successfully sent contract to')} ${workerName}`);
      }
    } catch (error) {
      const errorMessageCode = camelCase(error.response.data.code);
      message.error(t(errorMessageCode) || error.message);
    }
  };

  handleSetOfferExpiry = hour => {
    this.setState({ offerExpiryHour: hour });
  };

  render() {
    const {
      searchParams,
      numRecords,
      isLoading,
      applications,
      selectedApplicantIndex,
      showApplicantToggleModal,
      selectedApplicationsList,
      checkAll,
      selectAllApplications,
      appliedStatusFilters,
      showSearchedList,
      linkToApplicationsTab,
      singleApplicationForModal,
    } = this.state;
    const { t, user, staffRequest, selectedWorker } = this.props;
    const totalCounts = this.getApplicationCounts();

    const covidCertificationStatusOptions = [
      { key: workerCovidCertificationStatuses.VERIFIED, title: t('vaccinated') },
      { key: workerCovidCertificationStatuses.ALL_UNVERIFIED_STATUSES, title: t('notVerifiedYet') },
    ];

    let applicationList = applications || [];
    let numRecordList = numRecords || 0;
    if (selectedWorker.length > 0) {
      if (showSearchedList && this.props.currentActiveTabKey === this.props.tabKey) {
        applicationList = selectedWorker;
        numRecordList = 1;
      } else {
        applicationList = [];
        numRecordList = 0;
      }
    }

    return (
      <>
        {/* Filters & Search */}
        <Row type="flex" justify="space-between" style={{ marginBottom: '16px' }}>
          <Col>
            <Typography.Text level={3} style={{ marginRight: 12 }}>
              {t('filters')}
            </Typography.Text>
            <TreeCheckboxFilter
              treeData={Object.values(inactiveStatusOptions).map(value => ({
                ...value,
                title: capitalize(t(value.key)),
              }))}
              checkedKeys={appliedStatusFilters?.split(',')}
              placeholder={t('filterByStatusPlaceholder')}
              label={t('Status')}
              showActionButtons
              onApply={this.handleApplyStatusFilter}
              expandable={false}
              isDisabled={selectedWorker && selectedWorker.length > 0}
            />
            <DistancePopoverFilter
              label={t('Distance')}
              selectedDistance={searchParams?.distance}
              onApply={this.handleApplyDistanceFilter}
              isDisabled={selectedWorker && selectedWorker.length > 0}
            />
            {user?.country?.code !== countryCodeOptions.SINGAPORE && (
              <>
                <AgePopoverFilter
                  label={t('Age')}
                  selectedAgeRange={[searchParams?.min_age, searchParams?.max_age]}
                  onApply={this.handleApplyAgeRangeFilter}
                  isDisabled={selectedWorker && selectedWorker.length > 0}
                />
                <TreeCheckboxFilter
                  treeData={Object.values(genderOptions).map(value => ({
                    ...value,
                    title: capitalize(t(value.key)),
                  }))}
                  checkedKeys={searchParams?.gender?.split(',')}
                  placeholder={t('filterByGenderPlaceholder')}
                  label={t('Gender')}
                  showActionButtons
                  showSearch={false}
                  onApply={this.handleApplyGenderFilter}
                  expandable={false}
                  isDisabled={selectedWorker && selectedWorker.length > 0}
                />
              </>
            )}

            <TreeCheckboxFilter
              treeData={covidCertificationStatusOptions}
              // TODO: add handling between URL params and state
              checkedKeys={searchParams?.covid_certification_status?.split(',')}
              placeholder={t('filterByVaccinatedPlaceholder')}
              label={t('vaccinated')}
              showActionButtons
              showSearch={false}
              onApply={this.handleApplyCovidCertificationFilter}
              expandable={false}
              isDisabled={selectedWorker && selectedWorker.length > 0}
            />
            <MoreFilters
              searchParams={searchParams}
              selectedWorker={selectedWorker}
              onApplyMoreFilters={this.handleApplyFilters}
            />
            <Text type="secondary">{t('applicationResults', { numRecords: numRecordList })}</Text>
          </Col>
          <Col style={{ marginLeft: 'auto' }}>
            <Text type="secondary">{t('sortBy')}</Text>
            <Select
              defaultValue="quality,-created_date"
              style={{ marginLeft: '8px', width: '156px' }}
              onChange={value => this.handleSearchParamChange('ordering', value)}
            >
              <Option value="created_date">{t('firstApplied')}</Option>
              <Option value="quality,-created_date">{t('latestApplied')}</Option>
              <Option value="-modified_date">{t('mostRecent')}</Option>
            </Select>
          </Col>
        </Row>

        <Divider style={{ marginBottom: '16px', marginTop: '0px' }} />
        {/* Sorting and recommendation */}
        <Row style={{ marginBottom: checkAll ? '16px' : '0' }}>
          {user?.chatEnabled && (
            <>
              {/*
              Temporily hide for pending issues
              <Col>
                <HiringBulkActions disabled selectedIds={[]} />
              </Col>
              */}
            </>
          )}
        </Row>
        {user?.chatEnabled && checkAll && (
          <Row>
            <Col span={24}>
              <Row style={{ marginBottom: '16px' }}>
                <Col span={24}>
                  <Alert
                    message={
                      <>
                        {selectAllApplications ? (
                          <>
                            <Trans
                              i18nKey="allWorkersSelected"
                              values={{ workers: totalCounts }}
                              components={{ strong: <strong /> }}
                            />
                            {selectedApplicationsList.length < totalCounts && (
                              <Text
                                strong
                                style={{ cursor: 'pointer', color: '#1890FF', marginLeft: '5px' }}
                                onClick={() => this.handleSelectAllApplicants(false)}
                              >
                                {t('clearSelection')}
                              </Text>
                            )}
                          </>
                        ) : (
                          <>
                            <Trans
                              i18nKey="allWorkersSelectedOnPage"
                              values={{ workers: selectedApplicationsList.length }}
                              components={{ strong: <strong /> }}
                            />
                            {selectedApplicationsList.length < totalCounts && (
                              <Text
                                strong
                                style={{ cursor: 'pointer', color: '#1890FF', marginLeft: '5px' }}
                                onClick={() => this.handleSelectAllApplicants(true)}
                              >
                                {t('selectAllWorkers', { workers: totalCounts })}
                              </Text>
                            )}
                          </>
                        )}
                      </>
                    }
                    type="info"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {/* Actual Worker list */}
        <ConfigProvider renderEmpty={this.getEmptyCard}>
          <List
            itemLayout="vertical"
            style={{ padding: '0', marginBottom: '24px' }}
            pagination={{
              ...getPaginationConfig(numRecordList, this.onPageChange),
              current: searchParams.page || 1,
              // overwriting pagination config
              pageSize: searchParams.page_size,
              pageSizeOptions: [20, 40, 60, 100],
              showSizeChanger: true,
              showQuickJumper: false,
            }}
            loading={isLoading && { indicator: <LoadingSpinner width="50px" /> }}
            dataSource={applicationList || []}
            renderItem={(application, index) => {
              const checked =
                selectedApplicationsList.findIndex(member => member.partner.id === application.partner.id) !== -1;
              return (
                <ApplicantListItem
                  key={index}
                  application={application}
                  showCheckbox={user?.chatEnabled}
                  checked={checked}
                  onCheck={this.handleCheck}
                  onShortlist={this.onShortlist}
                  onUnshortlist={this.onUnshortlist}
                  onOpenApplicantModal={this.handleOpenApplicantModal}
                  index={index}
                  user={user}
                  positionId={staffRequest.position?.id}
                  onHandleOfferExpiry={this.handleSetOfferExpiry}
                  onResendOffer={this.handleResendOffer}
                />
              );
            }}
          />
        </ConfigProvider>
        {/* Modals */}
        {showApplicantToggleModal && (
          <ApplicantToggleModal
            visible={showApplicantToggleModal}
            onHire={this.onHire}
            onReject={this.onReject}
            onShortlist={this.onShortlist}
            onUnshortlist={this.onUnshortlist}
            onCancel={() => this.setState({ showApplicantToggleModal: false })}
            selectedApplicantIndex={selectedApplicantIndex}
            applications={applicationList}
            pageNum={searchParams.page}
            user={user}
            linkToApplicationsTab={linkToApplicationsTab}
            singleApplicationForModal={singleApplicationForModal}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  clientId: state.user.clientId,
});

const mapDispatchToProps = dispatch => {
  const { openChat, preload } = chatActions;
  return {
    createGroupChat: state => {
      dispatch(openChat(chatRoutes.createGroupChat, state, true));
    },
    preloadChat: (isLoading = true) => {
      dispatch(preload(isLoading));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffRequestInactiveList);
