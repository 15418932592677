import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ExclamationCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  DatePicker,
  TimePicker,
  Checkbox,
  Tag,
  message,
  InputNumber,
  Input,
  Modal,
  Divider,
  Typography,
  Switch,
  Tooltip,
  Select,
  Button,
  Empty,
} from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import RRule from 'rrule';

import routes from '../../../routes';

import { recurrenceOptions, TIME_FORMAT_24_HOUR, settingsTabs } from '../../../constants';
import scheduleApi from '../../../services/scheduleApi';
import clientApi from '../../../services/clientApi';
import { colors } from '../../../styles/colors';
import datetimeUtils from '../../../utilities/datetimeUtils';

const { Item } = Form;
const { Title, Text } = Typography;
const { Option } = Select;

class AddScheduleFormModal extends React.Component {
  state = {
    isSubmitting: false,
    allRoles: [],
    filteredRoles: [],
    hasRoles: false,
    selectedPosition: undefined,
    showAdvancedSettings: false,
    roleWithStaffRequired: false,
  };

  async componentDidMount() {
    const { clientId } = this.props;
    // TODO add try catch
    const response = await clientApi.fetchRoles(clientId, {});
    const roles = this.getRoleKeys(response.results);
    this.setState({ allRoles: roles });
  }

  getRoleKeys = roles => {
    const reformattedRoles = roles.map(role => {
      return { roleId: role.id, name: role.name, positionId: role.position.id, staff_required: 0 };
    });
    return reformattedRoles;
  };

  filterRolesByPosition = () => {
    const { allRoles, selectedPosition } = this.state;
    const filteredRoles = allRoles.filter(role => role.positionId === selectedPosition);
    this.setState({ filteredRoles });
  };

  handleRecurrenceChange = (option, checked) => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    const recurrenceFieldLabel = 'recurrence';
    const selectedRecurrences = getFieldValue(recurrenceFieldLabel);

    if (checked) {
      selectedRecurrences.push(option.value);
      setFieldsValue({ [recurrenceFieldLabel]: selectedRecurrences });
      return;
    }
    const nextSelectedRecurrences = selectedRecurrences.filter(fieldValue => fieldValue !== option.value);
    setFieldsValue({ [recurrenceFieldLabel]: nextSelectedRecurrences });
  };

  formDataToCreatePayload = (values, timezone) => {
    const { filteredRoles, hasRoles } = this.state;
    const { name, position } = values;
    let { staff_required } = values;
    const qr_override_allowed = !!values.qr_override_allowed;
    const fixed_location = !!values.fixed_location;

    const startTime = values.start_time;
    const endTime = values.end_time;
    const duration = datetimeUtils.getDurationFromStartTimeAndEndTime(startTime, endTime).format('HH:mm:00');

    // Get start date from values.start_date and values.start_time
    const startDate = moment
      .tz(
        {
          year: moment(values.start_date).year(),
          month: moment(values.start_date).month(),
          day: moment(values.start_date).date(),
          hour: moment(values.start_time).hour(),
          minute: moment(values.start_time).minute(),
        },
        timezone,
      )
      .toISOString();

    // Get end date from values.end_date and values.end_time
    const endDate = moment
      .tz(
        {
          year: moment(values.end_date).year(),
          month: moment(values.end_date).month(),
          day: moment(values.end_date).date(),
          hour: moment(values.end_time).hour(),
          minute: moment(values.end_time).minute(),
        },
        timezone,
      )
      .toISOString();

    const recurrences = new RRule({
      freq: RRule.WEEKLY,
      byweekday: values.recurrence,
      until: endDate,
    }).toString();

    let scheduleRoles = [];
    if (hasRoles) {
      scheduleRoles = filteredRoles.map(role => {
        // if roles get total staff required
        staff_required += role.staff_required;
        return { role: role.roleId, staff_required: role.staff_required };
      });
    }
    return {
      name,
      staff_required,
      start_date: startDate,
      end_date: endDate,
      recurrences,
      duration,
      qr_override_allowed,
      fixed_location,
      position,
      schedule_roles: scheduleRoles,
    };
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { form, timezone, onUpdate, t, selectedLocation, clientId } = this.props;
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          this.setState({ isSubmitting: true });
          const payload = this.formDataToCreatePayload(values, timezone);
          await scheduleApi.createSchedule({
            ...payload,
            client: clientId,
            location: selectedLocation.id,
          });
          onUpdate();
          message.success(t('scheduleSaved'));
        } catch (error) {
          message.warning(t('submitFailed'));
        } finally {
          this.setState({ isSubmitting: false });
        }
      }
    });
  };

  render() {
    const { visible, onCancel, t, positions, history } = this.props;
    const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
    const {
      isSubmitting,
      hasRoles,
      filteredRoles,
      selectedPosition,
      showAdvancedSettings,
      roleWithStaffRequired,
    } = this.state;

    return (
      <Modal
        className="v2-shifts-modal"
        visible={visible}
        onCancel={() => {
          onCancel();
        }}
        title={<Title level={4}>{t('addNewSchedule')}</Title>}
        okText={t('create')}
        cancelText={t('cancel')}
        okButtonProps={{ type: 'v2-primary', disabled: hasRoles && !roleWithStaffRequired }}
        confirmLoading={isSubmitting}
        onOk={e => {
          this.handleSubmit(e);
        }}
      >
        <Form hideRequiredMark colon={false} className="v2-shifts-form">
          <section key={0}>
            <Row type="flex" gutter={24}>
              <Col span={24}>
                <Item label={t('scheduleTitle')} style={{ marginBottom: '4px' }}>
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: t('fieldIsRequired') }],
                  })(<Input style={{ width: '100%' }} />)}
                </Item>
              </Col>
              <Col xs={12}>
                <Item label={t('startDate')} style={{ marginBottom: '4px' }}>
                  {getFieldDecorator('start_date', {
                    rules: [{ required: true, message: t('fieldIsRequired') }],
                    initialValue: moment(),
                  })(
                    <DatePicker
                      allowClear={false}
                      format="DD-MM-YYYY"
                      disabledDate={current =>
                        current <
                        moment()
                          .subtract(1, 'd')
                          .endOf('day')
                      }
                      style={{ width: '100%' }}
                      onChange={this.onStartDateChange}
                    />,
                  )}
                </Item>
              </Col>
              <Col xs={12}>
                <Item label={t('endDate')} style={{ marginBottom: '4px' }}>
                  {getFieldDecorator('end_date', {
                    rules: [
                      {
                        validator: (rule, value, callback) => {
                          if (value && getFieldValue('start_date')) {
                            const daysApart = value.diff(getFieldValue('start_date'), 'days');
                            if (daysApart > 365) {
                              // Set error on form
                              callback(t('endDateExceedYearError'));
                            }
                            if (daysApart < 0) {
                              callback(t('endDateEarlierThanStartDateError'));
                            }
                          }
                          // Have to be called to validate the rest of the fields.
                          callback();
                        },
                      },
                      { required: true, message: t('fieldIsRequired') },
                    ],
                    initialValue: moment().add(1, 'y'),
                  })(
                    <DatePicker
                      format="DD-MM-YYYY"
                      disabledDate={current => current < moment().endOf('day')}
                      style={{ width: '100%' }}
                      onChange={this.onEndDateChange}
                    />,
                  )}
                </Item>
              </Col>
              <Col xs={12}>
                <Item label={t('startTime')} style={{ marginBottom: '4px' }}>
                  {getFieldDecorator('start_time', {
                    rules: [{ required: true, message: t('fieldIsRequired') }],
                  })(
                    <TimePicker
                      minuteStep={15}
                      format={TIME_FORMAT_24_HOUR}
                      defaultOpenValue={moment('00:00', TIME_FORMAT_24_HOUR)}
                      style={{ width: '100%' }}
                    />,
                  )}
                </Item>
              </Col>
              <Col xs={12}>
                <Item label={t('endTime')} style={{ marginBottom: '4px' }}>
                  {getFieldDecorator('end_time', {
                    rules: [{ required: true, message: t('fieldIsRequired') }],
                  })(
                    <TimePicker
                      minuteStep={15}
                      format={TIME_FORMAT_24_HOUR}
                      defaultOpenValue={moment('00:00', TIME_FORMAT_24_HOUR)}
                      style={{ width: '100%' }}
                    />,
                  )}
                </Item>
              </Col>
              <Col span={24}>
                <Item label={t('repeatOn')} style={{ marginBottom: '4px' }}>
                  {getFieldDecorator('recurrence', {
                    initialValue: [0, 1, 2, 3, 4],
                    // TODO: validation must be triggered immediately
                    rules: [
                      {
                        type: 'array',
                        min: 1,
                        required: true,
                        message: t('fieldIsRequired'),
                      },
                    ],
                  })(
                    <Checkbox.Group style={{ width: '100%' }}>
                      <Row type="flex" className="v2-checkable-tag-wrapper">
                        {recurrenceOptions.map((option, index) => {
                          const recurrenceFieldLabel = 'recurrence';
                          const selectedRecurrences = getFieldValue(recurrenceFieldLabel);
                          return (
                            <Tag.CheckableTag
                              key={index}
                              checked={selectedRecurrences.includes(option.value)}
                              onChange={checked => this.handleRecurrenceChange(option, checked)}
                              style={{
                                padding: '5px 8px',
                                marginBottom: '5px',
                                width: '54px',
                                textAlign: 'center',
                                border: '1px solid #D9D9D9',
                              }}
                            >
                              {t(option.label)}
                            </Tag.CheckableTag>
                          );
                        })}
                      </Row>
                    </Checkbox.Group>,
                  )}
                </Item>
              </Col>
              <Divider />
              <Col span={24} style={{ marginBottom: '14px' }}>
                <Row style={{ marginBottom: '10px' }}>
                  <Text strong>{t('fulfillment')}</Text>
                </Row>
                <Row>
                  <Text type="secondary">{t('fulfillmentDescription')}</Text>
                </Row>
              </Col>
              <Col span={24}>
                <Item label={t('Position')} style={{ marginBottom: '4px' }}>
                  {getFieldDecorator('position', {
                    rules: [{ required: true, message: t('fieldIsRequired') }],
                  })(
                    <Select
                      placeholder={t('selectPosition')}
                      style={{ width: '50%' }}
                      onChange={value => {
                        setFieldsValue({ position: value });
                        this.setState({ selectedPosition: value }, () => this.filterRolesByPosition());
                      }}
                    >
                      {positions.map(position => (
                        <Option key={position.id} value={position.id}>
                          <Typography.Text>{t(`${position.name}`)}</Typography.Text>
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Item>
              </Col>
              <Col span={24} style={{ marginBottom: 16 }}>
                <Switch
                  disabled={!selectedPosition}
                  onChange={checked => {
                    this.setState({ hasRoles: checked });
                  }}
                />{' '}
                {t('addScheduleModalRoles')}{' '}
                <Tooltip title={t('addScheduleRolesTooltip')}>
                  <ExclamationCircleFilled style={{ marginRight: '8px', color: colors.infoHighlightBlue }} />
                </Tooltip>
              </Col>
              {hasRoles && filteredRoles.length > 0 && (
                <Col span={24}>
                  <Row gutter={10} style={{ width: '100%', marginBottom: 16 }}>
                    <Col span={8}>
                      <Text style={{ color: colors.black }}>{t('workersRole')}</Text>
                    </Col>
                    <Col>
                      <Text style={{ color: colors.black }}>{t('workersNeededPerDay')}</Text>
                    </Col>
                  </Row>
                  {filteredRoles.map(role => {
                    return (
                      <Row key={role.roleId} gutter={10} style={{ width: '100%' }}>
                        <Col span={8}>
                          <Text style={{ width: 100, marginLeft: 8 }} ellipsis>{`${role.name}`}</Text>
                        </Col>
                        <Col>
                          <Item style={{ marginBottom: 0 }}>
                            <InputNumber
                              min={0}
                              defaultValue={0}
                              style={{ width: '50%' }}
                              onChange={value => {
                                const updatedRoles = filteredRoles;
                                const roleToUpdate = updatedRoles.find(stateRole => stateRole.roleId === role.roleId);
                                roleToUpdate.staff_required = value || 0;
                                this.setState({
                                  filteredRoles: updatedRoles,
                                  roleWithStaffRequired: value > 0 && true,
                                });
                              }}
                            />
                          </Item>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              )}
              {hasRoles && !(filteredRoles.length > 0) && (
                <Empty
                  description={
                    <>
                      <Row style={{ marginTop: '32px' }}>
                        <Text type="secondary">{t('addScheduleEmptyRoles')}</Text>{' '}
                        <Button
                          type="link"
                          onClick={() => {
                            const route = routes.settings.replace(':tab', settingsTabs.ROLES);
                            history.push(route);
                          }}
                        >
                          {t('workforceSettings')}
                        </Button>
                      </Row>
                    </>
                  }
                />
              )}
              {!hasRoles && (
                <Col span={24}>
                  <Item label={t('workerNeeded')} style={{ marginBottom: '4px' }}>
                    {getFieldDecorator('staff_required', {
                      rules: [{ required: true, message: t('fieldIsRequired') }],
                    })(
                      <InputNumber
                        min={0}
                        style={{ width: '50%' }}
                        onChange={value => {
                          setFieldsValue({ staff_required: value });
                        }}
                      />,
                    )}
                  </Item>
                </Col>
              )}
              <Divider />
              <Col span={24}>
                <Button
                  onClick={() => this.setState({ showAdvancedSettings: !showAdvancedSettings })}
                  style={{ color: colors.black, border: 'none', boxShadow: 'none', padding: 0 }}
                  icon={<LegacyIcon type={showAdvancedSettings ? 'down' : 'right'} />}
                >
                  {t('advancedClockInSettings')}
                </Button>
              </Col>
              {showAdvancedSettings && (
                <>
                  <Col span={24} style={{ marginBottom: '14px' }}>
                    <Row style={{ marginBottom: '10px' }}>
                      <Text strong>{t('clockInMethodTitle')}</Text>
                    </Row>
                    <Row>
                      <Text type="secondary">{t('clockInMethodDescription')}</Text>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Item style={{ marginBottom: '4px' }}>
                      {getFieldDecorator('qr_override_allowed', {
                        initialValue: true,
                      })(
                        <>
                          <Switch
                            defaultChecked
                            onChange={checked => {
                              setFieldsValue({ qr_override_allowed: checked });
                              if (!checked) {
                                setFieldsValue({ fixed_location: false });
                              }
                            }}
                          />{' '}
                          {t('qrOverride')}
                        </>,
                      )}
                    </Item>
                    <Row>• {t('qrOverrideDescription1')}</Row>
                    <Row>• {t('qrOverrideDescription2')}</Row>
                  </Col>
                  <Col span={24}>
                    <Item style={{ marginBottom: '4px' }}>
                      {getFieldDecorator('fixed_location', { initialValue: false })(
                        <>
                          <Switch
                            disabled={!getFieldValue('qr_override_allowed')}
                            checked={!!getFieldValue('fixed_location')}
                            onChange={checked => {
                              setFieldsValue({ fixed_location: checked });
                            }}
                          />{' '}
                          {t('fixedLocation')}
                          <Tooltip placement="right" title={t('fixedLocationTooltip')}>
                            {' '}
                            <InfoCircleFilled style={{ marginLeft: 5, color: colors.blue }} />
                          </Tooltip>
                        </>,
                      )}
                    </Item>
                  </Col>
                </>
              )}
            </Row>
          </section>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  positions: state.global.positions,
  clientId: state.user.clientId,
});
export default Form.create()(withTranslation()(connect(mapStateToProps)(withRouter(AddScheduleFormModal))));
