import * as Sentry from '@sentry/browser';
import { isEmpty } from 'lodash';
import { matchPath } from 'react-router';
import channelApi from '../services/channelApi';
import applicationApi from '../services/applicationApi';
import { channelTypes, UPDATE_DELAY } from '../containers/Chat/constants';
import { staffRequestTabs } from '../constants';
import routes from '../routes';

const UPDATE = 'CHAT_UPDATE_ACTION';

const chatInitialState = {
  isLoading: false,
  error: '',
  channels: [],
  total: 0,
  activeSearchKeyword: '',
};

export const getMember = channel => {
  return channel.members.find(member => channel.type === channelTypes.PRIVATE && member.partner);
};

export default (state = chatInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE:
      return Object.assign({}, state, { ...payload });
    default:
      return state;
  }
};

export const chatUpdateAction = payload => {
  return { type: UPDATE, payload };
};

export const openChat = (route = '', state = {}, forceRerender = false) => {
  return dispatch => {
    if (forceRerender) {
      // Reset Chat component route
      dispatch(chatUpdateAction({ route: '' }));

      // Prevent Redux from batching dispatch calls
      setTimeout(() => {
        dispatch(chatUpdateAction({ visible: true, route, state }));
      });
    } else {
      dispatch(chatUpdateAction({ visible: true, route, state }));
    }
  };
};

export const closeChat = () => {
  return dispatch => {
    dispatch(chatUpdateAction({ visible: false, route: '', isLoading: false }));
  };
};

export const preload = (isLoading = true) => {
  return dispatch => {
    dispatch(chatUpdateAction({ isLoading }));
  };
};

export const setTotalUnreadCount = count => {
  return dispatch => {
    dispatch(chatUpdateAction({ totalUnreadCount: count }));
  };
};

export const fetchTotalUnreadCount = () => {
  return async dispatch => {
    try {
      const response = await channelApi.getTotalUnreadCount();
      dispatch(chatUpdateAction({ totalUnreadCount: response.total_unread_count }));
    } catch (error) {
      dispatch(chatUpdateAction({ totalUnreadCount: 0 }));
    }
  };
};

const appendApplicationStatus = async channels => {
  const staffRequests = [];
  const partners = [];
  channels.forEach(channel => {
    const member = getMember(channel);
    if (member) {
      if (!staffRequests.includes(channel.staff_request.id)) {
        staffRequests.push(channel.staff_request.id);
      }
      if (!partners.includes(member.partner.id)) {
        partners.push(member.partner.id);
      }
    }
  });
  const response = await applicationApi.getApplicationStatus({
    staff_requests: staffRequests.join(','),
    partners: partners.join(','),
  });

  // Flatten application statuses
  const applicationStatuses = {};
  response.forEach(application => {
    if (!applicationStatuses[application.partner.id]) {
      applicationStatuses[application.partner.id] = {};
    }
    applicationStatuses[application.partner.id][application.staff_request.id] = {
      status: application.status,
      shortlisted: application.shortlisted,
      interviewed: application.interviewed,
    };
  });

  // Patch private channels
  return channels.map(channel => {
    const member = getMember(channel);

    let applicationStatus = null;
    if (
      member &&
      applicationStatuses[member.partner.id] &&
      applicationStatuses[member.partner.id][channel.staff_request.id]
    ) {
      applicationStatus = applicationStatuses[member.partner.id][channel.staff_request.id];
    }

    return {
      ...channel,
      applicationStatus,
    };
  });
};

export const fetchChannels = (
  searchParams,
  doSetTotalUnreadCount,
  { runInBackground = false, retry = false, location = null } = {},
) => {
  return async (dispatch, getState) => {
    const { user } = getState();
    const { totalUnreadCount } = getState().chat;
    const { pageSize, search, ...params } = searchParams;
    let overrideSearch = '';
    if (!runInBackground) {
      dispatch(chatUpdateAction({ isLoading: true, activeSearchKeyword: search }));
    }

    if (location) {
      const match = matchPath(location.pathname, {
        path: routes.staffRequestDetail,
        exact: true,
      });
      if (match && Object.values(staffRequestTabs).includes(match.params.tab)) {
        overrideSearch = `${match.params.id}`;
      }
    }

    try {
      const response = await channelApi.fetchChannels({
        ...params,
        client: user.clientId,
        page_size: pageSize,
        search: isEmpty(search) ? overrideSearch : search,
      });

      // eslint-disable-next-line no-shadow
      const channels = await appendApplicationStatus(response.results);

      dispatch(chatUpdateAction({ channels, total: response.count, lastSearchWord: searchParams.search }));

      if (doSetTotalUnreadCount) {
        setTotalUnreadCount(response.total_unread_count);

        if (retry && (response.total_unread_count < 1 || response.total_unread_count === totalUnreadCount)) {
          setTimeout(() => {
            fetchChannels({
              runInBackground: true,
              retry: false, // Never set to true when called inside fetchChannels, else it will cause an infinite recursion.
            });
          }, UPDATE_DELAY);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      if (!runInBackground) {
        dispatch(chatUpdateAction({ isLoading: false }));
      }
    }
  };
};
