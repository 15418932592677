import { channelStatuses } from '../../constants';

const routes = {
  openChatList: '/channels',
  openArchivedChatList: '/channels/archived',
  createChat: '/channels/create',
  createGroupChat: '/channels/create/group',
  openChatDetail: '/channels/:channelId',
  openChatInfo: '/channels/:channelId/info',
  openChatMessageInfo: '/channels/:channelId/messageInfo',
};

export default routes;

// Rule out routes to be reopened when the page is reloaded..
const excludedRoutes = ['/', '', null, routes.openChatList, routes.createChat, routes.createGroupChat];

export const shouldReopenRoute = route => {
  return (
    excludedRoutes.findIndex(excludedRoute => {
      if (excludedRoute instanceof RegExp) {
        return excludedRoute.test(route);
      }

      return excludedRoute === route;
    }) === -1
  );
};

export const getChatListRoute = status => {
  switch (status) {
    case channelStatuses.ARCHIVED:
      return routes.openArchivedChatList;
    case channelStatuses.ACTIVE:
    default:
      return routes.openChatList;
  }
};
