import React from 'react';
import { connect } from 'react-redux';
import cubejs from '@cubejs-client/core';
import { withTranslation } from 'react-i18next';
import { Row, Typography, Col, Card } from 'antd';
import { cubeJsURL } from '../../../config';
import ToolTipWithInfo from '../../../shared/components/ToolTipWithInfo';
import CubeJsQueryRenderer from '../../../shared/components/CubeJsQueryRenderer';
import { colors } from '../../../styles/colors';

const { Text } = Typography;

class RetentionRateCountCard extends React.Component {
  state = {
    additionalDataset: undefined,
    additionalQueryComplete: false,
  };

  async componentDidMount() {
    await this.getAdditionalQuery();
  }

  async componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      await this.getAdditionalQuery();
    }
  }

  getAdditionalQuery = async () => {
    const { timePeriod, clientId, positionIds, locationIds } = this.props;
    this.setState({ additionalQueryComplete: false });
    const additionalQuery = this.formatAdditionalQueryData(timePeriod, clientId, positionIds, locationIds);
    await this.additionalQuery(additionalQuery);
    this.setState({ additionalQueryComplete: true });
  };

  formatQueryData = (timePeriod, clientId, positionIds, locationIds) => {
    const query = {
      filters: [
        {
          member: 'ClientsClient.id',
          operator: 'equals',
          values: [clientId.toString()],
        },
        {
          member: 'ClockingTimesheetentry.status',
          operator: 'notEquals',
          values: ['void'],
        },
        {
          member: 'RecruitmentEmployment.status',
          operator: 'notEquals',
          values: ['cancelled'],
        },
        {
          member: 'RecruitmentEmployment.cancellationReason',
          operator: 'notEquals',
          values: ['staff_request_reassignment'],
        },
      ],
      timeDimensions: [
        {
          dimension: 'ClockingTimesheetentry.clockInTime',
          dateRange: timePeriod,
        },
      ],
    };
    if (positionIds && positionIds.length > 0) {
      query.filters.push({
        member: 'PartnersPosition.id',
        operator: 'equals',
        values: positionIds,
      });
    }
    if (locationIds && locationIds.length > 0) {
      query.filters.push({
        member: 'ClientsLocation.id',
        operator: 'equals',
        values: locationIds,
      });
    }
    return query;
  };

  formatAdditionalQueryData = (timePeriod, clientId, positionIds, locationIds) => {
    const query = {
      filters: [
        {
          member: 'ClientsClient.id',
          operator: 'equals',
          values: [clientId.toString()],
        },
        {
          member: 'RecruitmentEmployment.status',
          operator: 'equals',
          values: ['cancelled'],
        },
        {
          member: 'RecruitmentEmployment.cancellationReason',
          operator: 'notEquals',
          values: ['staff_request_reassignment', 'cancelled'],
        },
      ],
      timeDimensions: [
        {
          dimension: 'RecruitmentEmployment.endDate',
          dateRange: timePeriod,
        },
      ],
    };
    if (positionIds && positionIds.length > 0) {
      query.filters.push({
        member: 'PartnersPosition.id',
        operator: 'equals',
        values: positionIds,
      });
    }
    if (locationIds && locationIds.length > 0) {
      query.filters.push({
        member: 'ClientsLocation.id',
        operator: 'equals',
        values: locationIds,
      });
    }
    return query;
  };

  additionalQuery = async additionalQuery => {
    const { cubeJsToken } = this.props;
    if (cubeJsToken) {
      const cubejsApi = cubejs(cubeJsToken, {
        apiUrl: cubeJsURL,
      });
      await cubejsApi
        .load({
          measures: ['RecruitmentEmployment.count'],
          timeDimensions: additionalQuery.timeDimensions,
          limit: 50000,
          order: {},
          filters: additionalQuery.filters,
          dimensions: [],
        })
        .then(resultSet => {
          this.setState({ additionalDataset: resultSet });
        });
    }
  };

  numberRender = ({ resultSet }) => {
    const { additionalDataset } = this.state;
    const { t } = this.props;
    let rententionRate;
    const retainedWorkers =
      resultSet.loadResponse.results[0].data && Math.round(Object.values(resultSet.loadResponse.results[0].data[0])[0]);

    const churnedWorkers =
      additionalDataset && Math.round(Object.values(additionalDataset.loadResponse.results[0].data[0])[0]);

    if (retainedWorkers > 0 && churnedWorkers) {
      rententionRate = Math.round((retainedWorkers / (retainedWorkers + churnedWorkers)) * 100);
    } else {
      rententionRate = 0;
    }
    return (
      <Card style={{ width: '100%' }} bodyStyle={{ margin: '24px 16px', padding: 0 }}>
        <Row justify="center">
          <Col>
            <Text strong style={{ fontSize: '38px' }}>
              {`${rententionRate}%`}
            </Text>
          </Col>
        </Row>
        <Row style={{ marginBottom: '4px', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
          <Text strong type="secondary" style={{ fontSize: '12px', paddingRight: '8px' }}>
            {t('workerRetention').toUpperCase()}
          </Text>
          <ToolTipWithInfo infoText={t('monthDidNotLeaveWorkerPercentage')} color={colors.blue} />
        </Row>
        <Row justify="center">
          <Col>
            <Text style={{ fontSize: '12px', color: colors.green }}>
              {t('{{number}} worker(s) churned', {
                number: churnedWorkers,
              })}
            </Text>
          </Col>
        </Row>
      </Card>
    );
  };

  render() {
    const { timePeriod, clientId, positionIds, locationIds } = this.props;
    const { additionalQueryComplete } = this.state;
    const formattedQuery = this.formatQueryData(timePeriod, clientId, positionIds, locationIds);

    return (
      <>
        {additionalQueryComplete && (
          <CubeJsQueryRenderer
            measures={['ClockingTimesheetentry.uniqPartners']}
            timeDimensions={formattedQuery.timeDimensions}
            filters={formattedQuery.filters}
            chartFunction={this.numberRender}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  cubeJsToken: state.user.cubeJsToken,
});

export default connect(mapStateToProps, null)(withTranslation()(RetentionRateCountCard));
