import React, { useState, useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Tabs,
  Button,
  Col,
  Row,
  Card,
  Input,
  DatePicker,
  TimePicker,
  Radio,
  Typography,
  InputNumber,
  message,
  Tag,
  Form,
} from 'antd';

import timesheetApi from '../../../services/timesheetApi';
import leaveEntriesApi from '../../../services/leaveEntriesApi';
import { TIME_FORMAT_24_HOUR, leaveEntryStatuses, timesheetEntryStatuses, DATE_KEY_FORMAT } from '../../../constants';
import { getSecondsFromHoursMinutesOfDate, getClockInOutFromDateTime } from '../../../utilities/timesheetUtils';
import { colors } from '../../../styles/colors';

const { Title } = Typography;

const CREATE_TIMESHEET_TAB_KEY = 'createTimesheetEntry';
const CREATE_LEAVE_TAB_KEY = 'createLeaveEntry';

const CreateTimesheetEntryForm = ({ form, employment, date, t }) => {
  useEffect(() => {
    form.setFieldsValue({ clock_in_date: date, clock_out_date: date });
  }, [date]);

  const validateClockInOutTime = (value, rule, callback) => {
    try {
      const clockInDate = form.getFieldValue('clock_in_date');
      const clockOutDate = form.getFieldValue('clock_out_date');
      const clockInTime = form.getFieldValue('clock_in_time');
      const clockOutTime = form.getFieldValue('clock_out_time');

      const clockIn = getClockInOutFromDateTime(clockInDate, clockInTime);
      const clockOut = getClockInOutFromDateTime(clockOutDate, clockOutTime);

      if (clockOut && clockOut.isBefore(clockIn)) {
        callback(`${t('clockOutError')}`);
      }

      callback();
    } catch (err) {
      callback(err);
    }
  };

  const validateMinuteStep = (value, rule, callback) => {
    try {
      const overtimeMinutes = form.getFieldValue('overtime_minutes');
      if (overtimeMinutes % 30 !== 0) {
        callback(`${t('invalidMinuteInterval')}`);
      }
      callback();
    } catch (err) {
      callback(err);
    }
  };

  return (
    <Form
      form={form}
      initialValues={{
        clock_in_date: date,
        late: false,
        overtime_hours: 0,
        overtime_minutes: 0,
      }}
      requiredMark
      colon={false}
      className="v2-form-wrapper"
    >
      <section key={0}>
        <Row type="flex" gutter={24}>
          <Col span={24}>
            <Form.Item label={t('Worker')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
              <Input
                style={{ width: '100%' }}
                disabled
                value={`${employment?.partner?.firstName} ${employment?.partner?.lastName}`}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label={t('Location')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
              <Input style={{ width: '100%' }} disabled value={employment?.location?.name} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label={t('Position')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
              <Input style={{ width: '100%' }} disabled value={employment?.position?.name} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label={t('clockInDate')}
              name="clock_in_date"
              rules={[{ required: true, message: t('fieldRequired.') }]}
              style={{ marginBottom: 16 }}
              labelCol={{ span: 24 }}
            >
              <DatePicker allowClear={false} format="DD MMM YYYY" style={{ width: '100%' }} disabled />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label={t('Clock In Time')}
              name="clock_in_time"
              rules={[{ required: true, message: t('fieldRequired.') }]}
              style={{ marginBottom: 16 }}
              labelCol={{ span: 24 }}
            >
              <TimePicker minuteStep={1} format={TIME_FORMAT_24_HOUR} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label={t('clockOutDate')}
              name="clock_out_date"
              rules={[{ required: true, message: t('fieldRequired.') }]}
              initialValue={date}
              style={{ marginBottom: 16 }}
              labelCol={{ span: 24 }}
            >
              <DatePicker
                allowClear={false}
                format="DD MMM YYYY"
                style={{ width: '100%' }}
                disabledDate={current => current < date || current > date.clone().add(1, 'days')}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label={t('Clock Out Time')}
              name="clock_out_time"
              rules={[{ required: true, message: t('fieldRequired.') }, { validator: validateClockInOutTime }]}
              style={{ marginBottom: 16 }}
              labelCol={{ span: 24 }}
            >
              <TimePicker minuteStep={1} format={TIME_FORMAT_24_HOUR} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={24} xs={12}>
            <Form.Item
              label={t('Late')}
              name="late"
              rules={[{ required: true, message: t('fieldRequired.') }]}
              style={{ marginBottom: 16 }}
              labelCol={{ span: 24 }}
            >
              <Radio.Group onChange={e => form.setFieldsValue({ late: e.target.value })}>
                <Radio value>{t('Yes')}</Radio>
                <Radio value={false}>{t('No')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label={t('Overtime')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
              <div style={{ display: 'flex' }}>
                <Form.Item name="overtime_hours" style={{ marginBottom: 16 }}>
                  <InputNumber
                    min={0}
                    max={23}
                    defaultValue={0}
                    style={{ width: '50%' }}
                    onChange={value => form.setFieldsValue({ overtime_hours: value })}
                  />
                  <span className="ant-form-text"> hours</span>
                </Form.Item>
                <Form.Item
                  name="overtime_minutes"
                  rules={[{ validator: validateMinuteStep }]}
                  style={{ marginBottom: 16 }}
                >
                  <InputNumber
                    min={0}
                    max={30}
                    step={30}
                    defaultValue={0}
                    style={{ width: '50%' }}
                    onChange={value => form.setFieldsValue({ overtime_minutes: value })}
                  />
                  <span className="ant-form-text"> mins</span>
                </Form.Item>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </section>
    </Form>
  );
};

const CreateLeaveEntryForm = ({ form, employment, leaveEntryTypes, date, t }) => {
  useEffect(() => {
    form.setFieldsValue({ date });
  }, [date]);

  return (
    <Form
      form={form}
      initialValues={{
        date,
      }}
      requiredMark
      colon={false}
      className="v2-form-wrapper"
    >
      <section key={1} style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '400px', paddingRight: 2 }}>
        <Row type="flex" gutter={24}>
          <Col span={24}>
            <Form.Item label={t('Worker')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
              <Input
                style={{ width: '100%' }}
                disabled
                value={`${employment?.partner?.firstName} ${employment?.partner?.lastName}`}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label={t('Location')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
              <Input style={{ width: '100%' }} disabled value={employment?.location?.name} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label={t('Position')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
              <Input style={{ width: '100%' }} disabled value={employment?.position?.name} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item name="date" label={t('Date')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
              <DatePicker disabled allowClear={false} format="DD MMM YYYY" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="leave_entry_type"
              label={t('typeOfEntry')}
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: t('fieldRequired.') }]}
            >
              <Radio.Group
                style={{ width: '100%' }}
                onChange={e => form.setFieldsValue({ leave_entry_type: e.target.value })}
              >
                {leaveEntryTypes.map((option, index) => (
                  <Card
                    key={index}
                    style={{
                      marginBottom: 8,
                      borderColor: colors.backgroundGrey,
                    }}
                    bodyStyle={{ display: 'flex', padding: '16px 24px' }}
                  >
                    <Radio style={{ flexGrow: 1 }} key={index} value={option.id}>
                      <Typography.Text>{option.name}</Typography.Text>
                    </Radio>
                    {option.is_paid ? (
                      <Tag color="success" style={{ marginLeft: 8 }}>
                        {t('paidBreakType')}
                      </Tag>
                    ) : (
                      <Tag color="gold" style={{ marginLeft: 8 }}>
                        {t('unpaidBreakType')}
                      </Tag>
                    )}
                  </Card>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </section>
    </Form>
  );
};

const AddEntryModal = ({
  t,
  visible,
  onClose,
  employment,
  attendance,
  date,
  fetchEmploymentsAndTimesheets,
  leaveEntryTypes,
  leaveEnabled,
}) => {
  const [activeTab, setActiveTab] = useState(CREATE_TIMESHEET_TAB_KEY);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createTimesheetEntryForm] = Form.useForm();
  const [createLeaveEntryForm] = Form.useForm();

  const timesheetEntryFormDataToCreatePayload = values => {
    let clockInTime;
    let clockOutTime;
    if (values.clock_in_time) {
      clockInTime = getClockInOutFromDateTime(values.clock_in_date, values.clock_in_time).toISOString();
    }

    if (values.clock_out_time) {
      clockOutTime = getClockInOutFromDateTime(values.clock_out_date, values.clock_out_time).toISOString();
    }

    return {
      partner: employment?.partner?.id,
      clock_in_time: clockInTime,
      clock_out_time: clockOutTime,
      late: values.late,
      overtime_duration: getSecondsFromHoursMinutesOfDate(values.overtime_hours, values.overtime_minutes),
      location: employment?.location?.id,
      position: employment?.position?.id,
      supervisor_confirmed: true,
      attendance: attendance?.id,
      employment: employment?.id,
    };
  };

  const handleCreateTimesheetEntrySubmit = async () => {
    try {
      setIsSubmitting(true);
      const values = await createTimesheetEntryForm.validateFields();
      const payload = timesheetEntryFormDataToCreatePayload(values);
      const response = await timesheetApi.create(payload);
      if (response.status !== timesheetEntryStatuses.DISPUTED) {
        // Add confirmed_supervisor object for attendance detail
        await timesheetApi.confirm(response.id);
      }
      const workerName = `${response.partner.first_name} ${response.partner.last_name}`;
      message.success(`${t('createTimesheetSuccess', { workerName })}`);

      fetchEmploymentsAndTimesheets();
    } catch (err) {
      message.warning(t('submitFailed'));
    } finally {
      createTimesheetEntryForm.resetFields();
      setIsSubmitting(false);
      onClose();
    }
  };

  const leaveEntryFormDataToCreatePayload = values => {
    return {
      date: values.date.format(DATE_KEY_FORMAT),
      leave_entry_type: values.leave_entry_type,
      employment: employment.id,
      location: employment.location.id,
      position: employment.position.id,
      partner: employment.partner.id,
      status: leaveEntryStatuses.CONFIRMED,
    };
  };

  const handleCreateLeaveEntrySubmit = async () => {
    try {
      setIsSubmitting(true);
      const values = await createLeaveEntryForm.validateFields();
      const payload = leaveEntryFormDataToCreatePayload(values);

      const response = await leaveEntriesApi.create(payload);

      // TODO: how to handle disputed / confirmed if initialte leave entry is created as confirmed
      // if (response.status !== timesheetEntryStatuses.DISPUTED) {
      //   // Add confirmed_supervisor object for attendance detail
      //   await timesheetApi.confirm(response.id);
      // }

      const workerName = `${response.partner?.first_name} ${response.partner?.last_name}`;
      message.success(t('createLeaveEntrySuccess', { workerName }));
      fetchEmploymentsAndTimesheets();
    } catch (err) {
      message.warning(t('submitFailed'));
    } finally {
      createLeaveEntryForm.resetFields();
      setIsSubmitting(false);
      onClose();
    }
  };

  const handleSubmit = () => {
    if (activeTab === CREATE_TIMESHEET_TAB_KEY) {
      handleCreateTimesheetEntrySubmit();
    } else if (activeTab === CREATE_LEAVE_TAB_KEY) {
      handleCreateLeaveEntrySubmit();
    }
  };

  return (
    <Modal
      centered
      destroyOnClose
      className="v2-add-entry-modal"
      visible={visible}
      onCancel={() => {
        onClose();
      }}
      confirmLoading={isSubmitting}
      title={<Title level={4}>{t('addEntryModalTitle')}</Title>}
      footer={[
        <Button
          key="close"
          onClick={() => {
            onClose();
          }}
        >
          {t('Close')}
        </Button>,
        <Button
          key="submit"
          type="v2-primary"
          loading={isSubmitting}
          onClick={() => {
            handleSubmit();
          }}
        >
          {t('addEntry')}
        </Button>,
      ]}
      bodyStyle={{ paddingTop: 8 }}
    >
      <Tabs
        className="default-ant-tabs"
        activeKey={activeTab}
        onChange={tab => {
          setActiveTab(tab);
        }}
      >
        <Tabs.TabPane tab={t('timesheetEntry')} key={CREATE_TIMESHEET_TAB_KEY}>
          <CreateTimesheetEntryForm form={createTimesheetEntryForm} employment={employment} date={date} t={t} />
        </Tabs.TabPane>
        {leaveEnabled && (
          <Tabs.TabPane tab={`🌴 ${t('leaveAndOtherEntries')}`} key={CREATE_LEAVE_TAB_KEY}>
            <CreateLeaveEntryForm
              form={createLeaveEntryForm}
              employment={employment}
              date={date}
              leaveEntryTypes={leaveEntryTypes}
              t={t}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Modal>
  );
};

const mapStateToProps = state => ({
  leaveEntryTypes: state.global.leaveEntryTypes,
  leaveEnabled: state.user.leaveEnabled,
});

export default connect(mapStateToProps, null)(withTranslation()(AddEntryModal));
