import React from 'react';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { isEqual } from 'lodash';
import { Button, Typography, Modal, message } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import CopyAssignmentButtonModal from './CopyAssignmentButtonModal';
import { DATE_FORMAT, WEEKLY_DATE_RANGE } from '../../../constants';
import { colors } from '../../../styles/colors';
import shiftApi from '../../../services/shiftApi';

const { Text } = Typography;

const squareIcon = (borderColor, backgroundColor) => (
  <div
    style={{
      display: 'inline-block',
      height: 12,
      width: 12,
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor,
      borderColor,
    }}
  />
);

const getColorLabel = t => {
  const colorLabels = [
    {
      icon: squareIcon(colors.blue, colors.blue),
      name: t('unpublished').toUpperCase(),
    },
    {
      icon: squareIcon(colors.green, colors.green),
      name: t('published').toUpperCase(),
    },
    {
      icon: squareIcon(colors.grey, colors.white),
      name: t('unconfirmed').toUpperCase(),
    },
    {
      icon: <CheckCircleOutlined style={{ color: colors.green }} />,
      name: t('workerConfirmed').toUpperCase(),
    },
    {
      icon: <ExclamationCircleOutlined style={{ color: colors.yellow }} />,
      name: t('leave').toUpperCase(),
    },
    {
      icon: squareIcon(colors.grey, colors.greyBorder),
      name: t('offDay').toUpperCase(),
    },
    {
      icon: squareIcon(colors.red, colors.red),
      name: t('noShow').toUpperCase(),
    },
  ];

  return colorLabels.map(label => (
    <span style={{ paddingRight: 24 }} key={label.name}>
      {label.icon} {label.name}
    </span>
  ));
};

export default withTranslation()(
  ({
    employments,
    dayDates,
    loading,
    clientId,
    onRefresh,
    publishCount,
    timezone,
    onCopy,
    shifts,
    originalShifts,
    selectedDateRange,
    assignmentData,
    t,
  }) => {
    const isWeekly = selectedDateRange === WEEKLY_DATE_RANGE;
    const handleCopyShift = fromDate => onCopy(fromDate);

    const getShiftsAndWorkers = () => {
      const pendingUpdateShifts = [];
      const workersToNotify = [];

      for (let i = 0; i < shifts.length; i += 1) {
        const newAttendances = shifts[i].attendances;
        const originalAttendances = originalShifts[i].attendances;

        if (!isEqual(newAttendances, originalAttendances)) {
          pendingUpdateShifts.push({
            id: shifts[i].id,
            attendances: shifts[i].attendances,
          });

          // A worker should be notified for three cases:
          // 1) New attendance (first time assigned to this shift)
          // 2) Attendance changed from assgined to unassigned (assigned off day or assigned to a different shift)
          // 3) Attendance changed from unassigned to assigned (previously assigned then unassigned, now back to assigned)
          newAttendances.forEach(attendance => {
            const matchingOriginalAttendance = originalAttendances.find(
              originalAtt => originalAtt.partner_id === attendance.partner_id,
            );
            // Case 1, new attendance
            if (!matchingOriginalAttendance) {
              workersToNotify.push(attendance.partner_id);
            } else if (matchingOriginalAttendance.status !== attendance.status) {
              workersToNotify.push(attendance.partner_id);
            }
          });
        }
      }
      const uniqueWorkersToNotify = [...new Set(workersToNotify)];
      return [pendingUpdateShifts, uniqueWorkersToNotify];
    };

    const handlePublish = async (draftShifts, workers) => {
      const publishPromise = draftShifts.map(pendingUpdateShift => shiftApi.editShift(pendingUpdateShift));
      try {
        await Promise.all(publishPromise);
        await shiftApi.notifyPartner({
          client_id: clientId,
          partner_id: workers.join(','),
        });
        message.success(t('shiftPublishSuccess', { workerCount: workers.length }));
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        onRefresh();
      }
    };

    const onPublish = async () => {
      const [pendingUpdateShifts, workersToNotify] = getShiftsAndWorkers();
      const startDate = moment(dayDates[0]).format(DATE_FORMAT);
      const endDate = moment(dayDates[6]).format(DATE_FORMAT);
      const workersCount = workersToNotify.length;

      Modal.confirm({
        centered: true,
        title: <Text strong>{t('publishShiftsTitle', { startDate, endDate })}</Text>,
        content: <Text>{t('publishShiftsBody', { workersCount })}</Text>,
        okText: t('publish'),
        okType: 'v2-primary',
        cancelText: t('cancel'),
        icon: <QuestionCircleTwoTone style={{ fontSize: '20px' }} />,
        onOk: () => handlePublish(pendingUpdateShifts, workersToNotify),
      });
    };

    const getPublishButtonText = (draftShifts, draftEmployments) => {
      const daysWorked = assignmentData.reduce((numOfDays, data) => numOfDays + data.assignedCount, 0);
      if (draftEmployments.length === 0 || (daysWorked === 0 && !publishCount)) return t('noShiftAssigned');

      if (draftShifts.length > 0 && !publishCount) return t('allShiftsPublished');

      if (draftShifts.length > 0 && publishCount) return `${t('publish')} (${publishCount})`;
    };

    return (
      <div
        style={{
          display: 'flex',
          position: 'fixed',
          justifyContent: 'space-between',
          alignItems: 'center',
          left: 0,
          bottom: 0,
          padding: '1em 4.5em 1em 4.5em',
          width: '100%',
          background: colors.white,
          boxShadow: '0 -5px 6px rgba(0,0,0,0.1)',
          WebkitBoxShadow: '0 -5px 6px rgba(0,0,0,0.1)',
          zIndex: 1,
        }}
      >
        <div>{getColorLabel(t)}</div>
        <div>
          <span style={{ marginRight: 8 }}>
            <CopyAssignmentButtonModal
              dayDates={dayDates}
              disabled={publishCount || loading}
              timezone={timezone}
              onCopy={handleCopyShift}
              isActive={isWeekly}
            />
          </span>
          <Button onClick={onPublish} type="v2-primary" disabled={!publishCount}>
            {getPublishButtonText(shifts, employments)}
          </Button>
        </div>
      </div>
    );
  },
);
