import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Typography, Button } from 'antd';

const { Text } = Typography;
const LargeButton = ({ active, disabled, iconType, name, onClick }) => {
  const buttonStyle = active ? { color: '#5ecc95', border: '2px solid #69e4a6' } : { color: '#00000073' };
  const textActiveStyle = active ? { color: '#5ecc95' } : {};
  if (disabled) {
    buttonStyle.opacity = 0.8;
  }
  return (
    <Button block disabled={disabled} type="v2-secondary" style={{ height: '90px', ...buttonStyle }} onClick={onClick}>
      <LegacyIcon type={iconType} style={{ display: 'block', fontSize: '24px' }} />
      <Text type="secondary" strong style={textActiveStyle}>
        {name}
      </Text>
    </Button>
  );
};

export default LargeButton;
