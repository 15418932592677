import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';
import { colors } from '../../../../styles/colors';
import { DATETIME_FORMAT_24_HOUR } from '../../../../constants';

const { Text } = Typography;

const LeaveItemBody = ({ leaveEntry, t }) => (
  <>
    <Row style={{ marginBottom: 8 }}>
      <Col span={12}>
        <Text
          style={{
            fontWeight: 600,
            color: colors.secondaryText,
            fontSize: 12,
            lineHeight: '20px',
            textTransform: 'uppercase',
          }}
        >
          {t('entry')}
        </Text>
      </Col>
      <Col span={12}>
        <Row>
          <Text>{`#${leaveEntry.id}`}</Text>
        </Row>
      </Col>
    </Row>
    {leaveEntry.lastModifiedByName && (
      <Row style={{ marginBottom: 8 }}>
        <Col span={12}>
          <Text
            style={{
              fontWeight: 600,
              color: colors.secondaryText,
              fontSize: 12,
              lineHeight: '20px',
              textTransform: 'uppercase',
            }}
          >
            {t('lastModifiedBy')}
          </Text>
        </Col>
        <Col span={12}>
          <Row>
            <Text>{leaveEntry.lastModifiedByName}</Text>
          </Row>
          {leaveEntry.modifiedDate && (
            <Row>
              <Text>{moment(leaveEntry.modifiedDate).format(DATETIME_FORMAT_24_HOUR)}</Text>
            </Row>
          )}
        </Col>
      </Row>
    )}
    {leaveEntry.confirmedByName && (
      <Row style={{ marginBottom: 8 }}>
        <Col span={12}>
          <Text
            style={{
              fontWeight: 600,
              color: colors.secondaryText,
              fontSize: 12,
              lineHeight: '20px',
              textTransform: 'uppercase',
            }}
          >
            {t('confirmedBy')}
          </Text>
        </Col>
        <Col span={12}>
          <Row>
            <Text>{leaveEntry.confirmedByName}</Text>
          </Row>
          {leaveEntry.confirmedDate && (
            <Row>
              <Text>{moment(leaveEntry.confirmedDate).format(DATETIME_FORMAT_24_HOUR)}</Text>
            </Row>
          )}
        </Col>
      </Row>
    )}
    {leaveEntry.approvedByName && (
      <Row style={{ marginBottom: 8 }}>
        <Col span={12}>
          <Text
            style={{
              fontWeight: 600,
              color: colors.secondaryText,
              fontSize: 12,
              lineHeight: '20px',
              textTransform: 'uppercase',
            }}
          >
            {t('approvedBy')}
          </Text>
        </Col>
        <Col span={12}>
          <Row>
            <Text>{leaveEntry.approvedByName}</Text>
          </Row>
          {leaveEntry.approvedDate && (
            <Row>
              <Text>{moment(leaveEntry.approvedDate).format(DATETIME_FORMAT_24_HOUR)}</Text>
            </Row>
          )}
        </Col>
      </Row>
    )}
    {leaveEntry.voidedByName && (
      <Row style={{ marginBottom: 8 }}>
        <Col span={12}>
          <Text
            style={{
              fontWeight: 600,
              color: colors.secondaryText,
              fontSize: 12,
              lineHeight: '20px',
              textTransform: 'uppercase',
            }}
          >
            {t('voidedBy')}
          </Text>
        </Col>
        <Col span={12}>
          <Row>
            <Text>{leaveEntry.voidedByName}</Text>
          </Row>
          {leaveEntry.voidedDate && (
            <Row>
              <Text>{moment(leaveEntry.voidedDate).format(DATETIME_FORMAT_24_HOUR)}</Text>
            </Row>
          )}
        </Col>
      </Row>
    )}
  </>
);

export default withTranslation()(LeaveItemBody);
