import React from 'react';
import { connect } from 'react-redux';
import { MemoryRouter, Route, Switch } from 'react-router-dom';
import routes from './routes';
import ChatRedirect from './components/ChatRedirect';
import ChatDrawer from './components/ChatDrawer';
import CreateGroupChatForm from './components/CreateGroupChatForm';
import ChatListViewWithStatus from './components/ChatListViewWithStatus';
import ChatDetailView from './components/ChatDetailView';
import ChatInfoView from './components/ChatInfoView';
import ChatMessageInfoView from './components/ChatMessageInfoView';
import { channelStatuses, roles } from '../../constants';

const pages = [
  { exact: true, path: routes.createChat, component: ChatDetailView },
  { exact: true, path: routes.createGroupChat, component: CreateGroupChatForm },
  { exact: true, path: routes.openChatList, component: ChatListViewWithStatus(channelStatuses.ACTIVE) },
  { exact: true, path: routes.openArchivedChatList, component: ChatListViewWithStatus(channelStatuses.ARCHIVED) },
  { exact: true, path: routes.openChatDetail, component: ChatDetailView },
  { exact: true, path: routes.openChatInfo, component: ChatInfoView },
  { exact: true, path: routes.openChatMessageInfo, component: ChatMessageInfoView },
];

const Chat = ({ render, visible, browserHistory }) => {
  if (!render) {
    return <></>;
  }

  return (
    <MemoryRouter>
      <ChatDrawer visible={visible} browserHistory={browserHistory}>
        <>
          <Switch>
            {pages.map(({ exact, path, component: Component }, index) => (
              <Route
                key={index}
                exact={exact}
                path={path}
                component={props => <Component {...props} browserHistory={browserHistory} />}
              />
            ))}
          </Switch>
          <ChatRedirect />
        </>
      </ChatDrawer>
    </MemoryRouter>
  );
};

const mapStateToProps = state => {
  const { hasLoaded, role, chatEnabled } = state.user;
  const render = hasLoaded && role === roles.ADMIN && chatEnabled;

  return {
    render,
    visible: state.chat.visible,
  };
};

export default connect(mapStateToProps)(Chat);
