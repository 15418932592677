import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const DeactivatedQuestionTooltip = () => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('deactivatedQuestionMessage')} placement="rightTop">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M7.99992 1.3335C11.6666 1.3335 14.6666 4.3335 14.6666 8.00016C14.6666 11.6668 11.6666 14.6668 7.99992 14.6668C4.33325 14.6668 1.33325 11.6668 1.33325 8.00016C1.33325 4.3335 4.33325 1.3335 7.99992 1.3335ZM7.99992 2.66683C6.73325 2.66683 5.59992 3.06683 4.73325 3.80016L12.1999 11.2668C12.8666 10.3335 13.3333 9.20016 13.3333 8.00016C13.3333 5.06683 10.9333 2.66683 7.99992 2.66683ZM11.2666 12.2002L3.79992 4.7335C3.06659 5.60016 2.66659 6.7335 2.66659 8.00016C2.66659 10.9335 5.06659 13.3335 7.99992 13.3335C9.26659 13.3335 10.3999 12.9335 11.2666 12.2002Z"
          fill="#D9D9D9"
        />
      </svg>
    </Tooltip>
  );
};

export default DeactivatedQuestionTooltip;
