import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import { STRIPE_PUBLISHABLE_KEY } from '../../../../config';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const PaymentDetailSection = ({
  totalEstimatedCost,
  backButtonElement,
  onPaymentSuccessful,
  gigData,
  currency,
  history,
  estimatedCostElement,
  onHoldAmount,
}) => {
  if (totalEstimatedCost === 0) {
    return null; // this prevent stripe Elements to throw an error
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        currency,
        mode: 'payment',
        // need to multiple by 100 since stripe amount is in cents
        // ref:  ref: https://stripe.com/docs/currencies
        amount: parseInt(totalEstimatedCost, 10) * 100,
        capture_method: 'manual',
        paymentMethodCreation: 'manual',
        payment_method_types: ['card'],
        setup_future_usage: 'off_session',
      }}
    >
      <PaymentForm
        backButtonElement={backButtonElement}
        onPaymentSuccessful={onPaymentSuccessful}
        gigData={gigData}
        history={history}
        estimatedCostElement={estimatedCostElement}
        totalEstimatedCost={totalEstimatedCost}
        currency={currency}
        onHoldAmount={onHoldAmount}
      />
    </Elements>
  );
};

export default PaymentDetailSection;
