import React from 'react';
import { Typography, Row, Button, Col } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { colors } from '../../../../styles/colors';
import { DAY_DATE, LONG_DATE } from '../../../../styles/dates';
import routes from '../../../../routes';
import { staffRequestTabs } from '../../../../constants';

const { Text } = Typography;

const DateSelector = ({ shiftDate, shiftIds, currentShiftId, history }) => {
  const currentShiftIndex = shiftIds.findIndex(shiftId => shiftId === Number(currentShiftId));
  const prevShiftId = shiftIds[currentShiftIndex - 1];
  const nextShiftId = shiftIds[currentShiftIndex + 1];

  const handleNavigate = shiftId => {
    const link = routes.gigDetail.replace(':id', shiftId).replace(':tab', staffRequestTabs.ACCEPTED);
    history.push(link);
  };

  return (
    <Row style={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
      {prevShiftId && (
        <Button type="text" onClick={() => handleNavigate(prevShiftId)} style={{ fontSize: 16 }}>
          <LeftOutlined />
        </Button>
      )}
      <Col style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ fontSize: 16, fontWeight: 600, color: colors.functionalSuccess }}>
          {moment(shiftDate).format(LONG_DATE)} ({moment(shiftDate).format(DAY_DATE)})
        </Text>
      </Col>
      {nextShiftId && (
        <Button type="text" onClick={() => handleNavigate(nextShiftId)} style={{ fontSize: 16 }}>
          <RightOutlined />
        </Button>
      )}
    </Row>
  );
};

export default DateSelector;
