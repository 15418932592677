export const permissions = {
  // Analytics
  analyticsViewCosts: 'analytics:cost',
  // Workforce
  workforceViewWageColumns: 'workforce:wage',
  // Attendance
  attendanceViewTotalWageColumn: 'attendance:wage',
  attendanceSetWageButton: 'attendance:setWage',
  attendanceApproveButton: 'attendance:approve',
  attendanceVoidButton: 'attendance:void',
  attendanceResolveButton: 'attendance:resolve',
  // Worker Profile
  workerProfileViewDetails: 'worker-profile:view-details',
};

export const roles = [
  {
    title: 'Admin',
    value: 'admin',
    description: 'Full access to everything, including user access management privileges.',
  },
  {
    title: 'Supervisor',
    value: 'supervisor',
    description: 'Access only assigned locations. No management privileges.',
  },
];

export const roleAuthorizations = {
  admin: {
    staticPermissions: Object.keys(permissions).map(key => permissions[key]),
  },
  supervisor: {
    staticPermissions: [],
  },
};
