import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const ChatRedirect = ({ route }) => <Redirect to={route} push={false} />;

const mapStateToProps = state => ({
  route: state.chat.route,
});

export default connect(mapStateToProps)(ChatRedirect);
