import { choiceTypesWithKeys } from '../constants';

const checkDuplicateOptions = ({ options = [], description = '', index }) => {
  return options.some(
    (choice, choiceIndex) => choice?.description?.trim() === description.trim() && index !== choiceIndex,
  );
};

// add other choice when hasOther is set to true and question type is single / multi select
const addOtherChoice = ({ type, hasOther, choices }) => {
  const isSelectable = type === choiceTypesWithKeys.MULTI_SELECT.key || type === choiceTypesWithKeys.SINGLE_SELECT.key;
  if (hasOther && isSelectable) {
    return [...choices, { description: 'Other', type: choiceTypesWithKeys.FREE_TEXT.key }];
  }
  return choices;
};

const canSetCorrectAnswer = (type, hasScore) => {
  return hasScore && type === choiceTypesWithKeys.SINGLE_SELECT.key;
};

const getChoiceScore = ({ choice, type, hasScore }) => {
  let score = 0;
  if (canSetCorrectAnswer(type, hasScore)) {
    score = choice?.score ? 1 : 0;
  }
  return score;
};

const getFormattedChoices = ({ choices, type, hasScore }) => {
  let formattedChoices = choices;
  if (type === choiceTypesWithKeys.FREE_TEXT.key) {
    formattedChoices = [];
  } else {
    formattedChoices = formattedChoices.map(choice => ({
      type,
      ...choice,
      score: getChoiceScore({ choice, hasScore, type }),
    }));
  }
  return formattedChoices;
};

const getCreateQuestionPayload = ({
  title,
  description,
  type,
  choices = [],
  clientId,
  imageFile,
  hasOther = false,
  hasScore = false,
}) => {
  const newChoices = addOtherChoice({ type, hasOther, choices });
  const formattedChoices = getFormattedChoices({ choices: newChoices, type, hasScore });
  const payload = {
    image: imageFile,
    question_data: {
      title: title.trim(),
      description: description ? description.trim() : '',
      client_id: clientId,
      type,
      choices: formattedChoices,
    },
  };
  return payload;
};

const generateInitialOptions = (count = 2) => {
  const options = [];
  for (let i = 1; i <= count; i += 1) {
    const option = { description: '' };
    options.push(option);
  }
  return options;
};

export default {
  checkDuplicateOptions,
  getCreateQuestionPayload,
  generateInitialOptions,
  canSetCorrectAnswer,
};
