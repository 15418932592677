import React from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Row, Col, Tooltip, Typography } from 'antd';
import { countryCodeOptions } from '../../../constants';

const { Text, Title } = Typography;
const BillingCostSummary = ({ billingReport, client }) => {
  const {
    wage_fee,
    service_rate,
    service_fee,
    tax_rate,
    service_tax_fee,
    wage_tax_fee,
    billing_amount,
  } = billingReport;
  const taxValue = parseFloat(service_tax_fee) + parseFloat(wage_tax_fee);
  const summaries = [
    { title: 'Subtotal', value: wage_fee.replace(/\B(?=(\d{3})+(?!\d))/g, ',') },
    { title: `Service fee (${service_rate}%)`, value: service_fee.replace(/\B(?=(\d{3})+(?!\d))/g, ',') },
    {
      title: `VAT  (${tax_rate}%)`,
      value: taxValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      titleExtra:
        client.country.code === countryCodeOptions.INDONESIA ? (
          <Tooltip
            placement="top"
            title="Indonesia: VAT only applies to service fees"
            overlayStyle={{ width: '176px' }}
          >
            <InfoCircleTwoTone style={{ marginLeft: '8px' }} />
          </Tooltip>
        ) : (
          <></>
        ),
    },
  ];

  return (
    <>
      {summaries.map(({ title, value, titleExtra }, index) => (
        <Row key={index} type="flex" justify="space-between" style={{ marginBottom: '12px' }}>
          <Col>
            <Text strong>{title}</Text>
            {titleExtra && titleExtra}
          </Col>
          <Col>
            <Text>{value}</Text>
          </Col>
        </Row>
      ))}
      <Row type="flex" justify="space-between" style={{ paddingTop: '8px', borderTop: '1px solid #00000017' }}>
        <Col>
          <Text strong>Amount due</Text>
        </Col>
        <Col>
          <Title level={4}>{billing_amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Title>
        </Col>
      </Row>
    </>
  );
};

export default BillingCostSummary;
