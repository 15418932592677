import base from './base';
import { endpoints } from '../config';

const fetchPositions = async params => {
  const response = await base.api.get(`${endpoints.positions}/`, { params });
  return response;
};

export default {
  fetchPositions,
};
