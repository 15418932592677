import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Button, Col, ConfigProvider, Input, List, message, Pagination, Row, Tooltip, Typography } from 'antd';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { generatePath, withRouter } from 'react-router';
import moment from 'moment';
import { ArrowLeftOutlined, InboxOutlined, TeamOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';

import Header from './Header';
import Footer from './Footer';
import EmptyChatList from './EmptyChatList';
import ChatLoadingErrorPrompt from './ChatLoadingErrorPrompt';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import { getTwilioConnectionState, getTwilioChatClient, getMember } from '../../../utilities/chatUtils';
import routes from '../routes';
import { audiences, channelStatuses, messageDisplayDuration } from '../../../constants';
import { colors } from '../../../styles/colors';
import { UPDATE_DELAY, PAGE_SIZE, twilioConnectionStates, channelTypes } from '../constants';
import DropdownButtons from '../../../shared/components/DropdownButtons';
import ArchiveChatModal from './ArchiveChatModal';
import StatusTag from '../../../shared/components/StatusTag';
import { fetchChannels } from '../../../redux/chat';

const { Paragraph, Text, Title } = Typography;
const MAX = 30;

const ChatListView = ({
  t,
  history,
  location,
  user,
  status = channelStatuses.ACTIVE,
  setTotalUnreadCount = null,
  enableArchive = true,
  browserHistory,
  getChannels,
  isLoading,
  channels,
  total,
}) => {
  const archiveChatModal = useRef();
  const chatListParams = location?.state?.chatListParams || {};
  const { search, page, pageSize } = chatListParams;

  // eslint-disable-next-line no-shadow
  const orderBy = status => {
    switch (status) {
      case channelStatuses.ARCHIVED:
        return '-modified_date';
      case channelStatuses.ACTIVE:
      default:
        return '-last_message_timestamp';
    }
  };

  const [searchParams, setSearchParams] = useState({
    audience: audiences.SELECTED_APPLICANTS,
    status,
    page: page ? parseInt(page, 10) : 1,
    pageSize: pageSize ? parseInt(pageSize, 10) : PAGE_SIZE,
    search: search || '',
    ordering: orderBy(status),
  });

  const twilioConnectionState = getTwilioConnectionState();
  const twilioChatClient = getTwilioChatClient();
  const chatReadyToUse = twilioConnectionState === twilioConnectionStates.CONNECTED;

  const now = moment();

  useEffect(() => {
    getChannels(searchParams, setTotalUnreadCount, {
      location: browserHistory.location,
    });
  }, [searchParams, twilioConnectionState]);

  const reloadChatList = () => {
    // Reload the chat list if there are new messages added and only if the user is on page 1.
    // Added a delay to allow ChatDetailView.setChannelLastMessage to update the channel's last message timestamp.
    // In cases where the delay takes slightly more time, we will allow the method to re-try.
    if (searchParams.page === 1) {
      setTimeout(() => {
        getChannels(searchParams, setTotalUnreadCount, {
          runInBackground: true,
          retry: true,
          location: browserHistory.location,
        });
      }, UPDATE_DELAY);
    }
  };

  const addEvent = async (event, callback) => {
    try {
      twilioChatClient?.on(event, callback);
    } catch (error) {
      Sentry.captureException(error);
      message.warning(t('chatConnectionError'), [messageDisplayDuration]);
    }
  };

  const removeEvent = async (event, callback) => {
    try {
      twilioChatClient?.off(event, callback);
    } catch (error) {
      Sentry.captureException(error);
      message.warning(t('chatConnectionError'), [messageDisplayDuration]);
    }
  };

  useEffect(() => {
    addEvent('messageAdded', reloadChatList);
    return () => {
      removeEvent('messageAdded', reloadChatList);
    };
  }, []);

  // eslint-disable-next-line no-shadow
  const handleSearch = debounce(search => {
    setSearchParams({ ...searchParams, search, page: 1 });
  }, 600);

  // eslint-disable-next-line no-shadow
  const handlePageChange = (page, pageSize) => {
    setSearchParams({ ...searchParams, page, pageSize });
  };

  // eslint-disable-next-line no-shadow
  const handlePageSizeChange = (current, pageSize) => {
    setSearchParams({ ...searchParams, page: 1, pageSize });
  };

  const handleItemClick = channel => {
    const route = generatePath(routes.openChatDetail, {
      channelId: channel.id,
    });
    history.push(route, {
      channel,
      chatListParams: {
        status,
        search: searchParams.search,
        page: searchParams.page,
        pageSize: searchParams.pageSize,
      },
    });
  };

  const handleDisplayAvatar = channel => {
    if (channel.type === channelTypes.BROADCAST) {
      return (
        <Avatar shape="circle" size={60} className="v2-avatar-wrapper" style={{ backgroundColor: '#9da3a5' }}>
          <TeamOutlined style={{ fontSize: '16px', marginRight: '1px' }} />
          {channel.members.length}
        </Avatar>
      );
    }

    if (channel.type === channelTypes.PRIVATE) {
      const member = getMember(channel);
      if (!member) {
        return <></>;
      }
      return (
        <Avatar
          shape="circle"
          size={60}
          className="v2-avatar-wrapper"
          style={{ backgroundColor: '#9da3a5' }}
          src={member.partner.image}
        />
      );
    }

    return <></>;
  };

  const handleDisplayChatName = channel => {
    if (channel.type === channelTypes.BROADCAST) {
      return (
        <>
          <Text className="name" ellipsis={{ tooltip: true }}>
            {channel.channel_name}
          </Text>
        </>
      );
    }

    if (channel.type === channelTypes.PRIVATE) {
      const member = getMember(channel);
      if (!member) {
        return <></>;
      }
      return (
        <>
          <Text className="name" ellipsis={{ tooltip: true }} style={{ marginRight: 5, width: 'auto' }}>
            {`${member.partner.first_name} ${member.partner.last_name}`} ·
          </Text>
          {channel.applicationStatus && (
            <StatusTag
              status={channel.applicationStatus.status}
              interview={channel.applicationStatus.interviewed}
              shortlisted={channel.applicationStatus.shortlisted}
            />
          )}
        </>
      );
    }

    return <></>;
  };

  const displayHeader = () => {
    if (status === channelStatuses.ACTIVE) {
      return (
        <h3 style={{ marginBottom: 0 }}>
          <Text strong>{t('chats')}</Text>
        </h3>
      );
    }

    if (status === channelStatuses.ARCHIVED) {
      return (
        <Button
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => {
            // eslint-disable-next-line no-shadow
            const { search, page, pageSize } = searchParams;
            history.push(routes.openChatList, {
              chatListParams: { search, page, pageSize },
            });
          }}
          style={{ padding: 0, margin: 0, height: 'auto' }}
        >
          <Text style={{ marginLeft: '10px', color: colors.functionalLink }}>{t('back')}</Text>
        </Button>
      );
    }

    return <></>;
  };

  const displaySubheader = () => {
    if (status === channelStatuses.ACTIVE) {
      return (
        <>
          <Row style={{ padding: '24px 24px 12px' }}>
            <Col span={24} style={{ display: 'flex' }}>
              <Input.Search
                loading={isLoading}
                className="group-chat-search"
                allowClear
                maxLength={MAX}
                placeholder={t('chatListSearchPlaceholder')}
                // eslint-disable-next-line no-shadow
                onSearch={search => handleSearch(search)}
                onChange={e => handleSearch(e.target.value)}
                disabled={!chatReadyToUse}
                style={{ marginRight: 8 }}
                defaultValue={searchParams.search}
              />
              <Tooltip title={t('archivedChats')}>
                <Button
                  onClick={() => {
                    history.push(routes.openArchivedChatList);
                  }}
                >
                  <InboxOutlined />
                </Button>
              </Tooltip>
            </Col>
          </Row>
          {searchParams.search && (
            <Row
              type="flex"
              align="bottom"
              style={{
                boxShadow: 'none',
                padding: '0 24px 12px',
              }}
            >
              <Col span={24}>
                <Button
                  block
                  type="text"
                  icon={<InboxOutlined style={{ fontSize: 24 }} />}
                  style={{
                    color: colors.secondaryText,
                    display: 'flex',
                    paddingLeft: 6.4,
                    paddingRight: 6.4,
                    textAlign: 'left',
                  }}
                  onClick={() => {
                    // eslint-disable-next-line no-shadow
                    const { search, page, pageSize } = searchParams;
                    history.push(routes.openArchivedChatList, {
                      chatListParams: { search, page, pageSize },
                    });
                  }}
                >
                  {t('seeArchivedSearchResults')}
                </Button>
              </Col>
            </Row>
          )}
        </>
      );
    }

    if (status === channelStatuses.ARCHIVED) {
      return (
        <Row style={{ padding: '24px 24px 0' }}>
          <Col span={24} style={{ display: 'flex' }}>
            {searchParams.search ? (
              <Title level={4}>{t('archivedSearchResults')}</Title>
            ) : (
              <Title level={4}>{t('archivedChats')}</Title>
            )}
          </Col>
        </Row>
      );
    }

    return <></>;
  };

  const getEmptyList = () => {
    if (status === channelStatuses.ACTIVE) {
      return <EmptyChatList message={t('noConversationsYet')} />;
    }

    if (status === channelStatuses.ARCHIVED) {
      return <EmptyChatList title={t('noArchivedChats')} message={t('archivedChatsWillAppearHere')} />;
    }

    return <></>;
  };

  const getDropdownButtons = channel => {
    const disabled = [channelStatuses.ARCHIVED].includes(channel.status);
    return [
      {
        title: t('archiveChat'),
        disabled,
        onClick: event => {
          event.stopPropagation();
          archiveChatModal.current.open(channel.id, () => {
            getChannels(searchParams, setTotalUnreadCount, {
              location: browserHistory.location,
            });
          });
        },
      },
    ];
  };

  return (
    <>
      <Header>{displayHeader()}</Header>
      {displaySubheader()}
      {isLoading ? (
        <LoadingSpinner width="50px" />
      ) : (
        <>
          {chatReadyToUse ? (
            <Row>
              <Col span={24}>
                <ConfigProvider renderEmpty={() => getEmptyList()}>
                  <List
                    itemLayout="vertical"
                    dataSource={channels}
                    renderItem={channel => {
                      const timestamp = channel.last_message_timestamp ? moment(channel.last_message_timestamp) : null;
                      const jobInfo = `${t('job')} #${channel.staff_request.id} · ${
                        channel.staff_request.position.name
                      } · ${channel.staff_request.location.address.area.city.name} · ${channel.staff_request.title}`;
                      const classNames = ['chat-list-item'];
                      const unread = channel.unread_count > 0;
                      if (unread) {
                        classNames.push('unread');
                      }
                      const author =
                        channel.last_message_author?.id === user.userId ? t('you') : channel.last_message_author?.name;
                      const lastMessage =
                        channel.last_message && channel.last_message.length >= 25
                          ? `${channel.last_message}...`
                          : channel.last_message;

                      const isSameDay = now.isSame(timestamp, 'day');

                      return (
                        <>
                          <Row
                            className={`${classNames.join(' ')}`}
                            style={{ padding: '12px 24px' }}
                            onClick={() => handleItemClick(channel)}
                          >
                            <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
                              {handleDisplayAvatar(channel)}
                            </Col>
                            <Col span={20}>
                              <Row>
                                <Col span={isSameDay ? 20 : 18}>
                                  <Text className="job-info" ellipsis={{ tooltip: true }}>
                                    {jobInfo}
                                  </Text>
                                </Col>
                                <Col span={isSameDay ? 3 : 5} offset={1} style={{ textAlign: 'right' }}>
                                  {timestamp && (
                                    <Text className="timestamp">
                                      {moment(timestamp).format(isSameDay ? 'HH:mm' : 'D MMM YYYY')}
                                    </Text>
                                  )}
                                </Col>
                              </Row>
                              <Row style={{ alignItems: 'center' }}>
                                <Col span={18}>{handleDisplayChatName(channel)}</Col>
                                <Col
                                  span={5}
                                  offset={1}
                                  style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'right' }}
                                >
                                  {enableArchive && (
                                    <DropdownButtons
                                      className="chat-list-item-actions"
                                      size="small"
                                      buttons={getDropdownButtons(channel)}
                                      onClick={e => e.stopPropagation()}
                                    />
                                  )}
                                  <svg
                                    height="10"
                                    width="10"
                                    style={{
                                      display: 'block',
                                      marginLeft: 5,
                                      visibility: unread ? 'visible' : 'hidden',
                                    }}
                                  >
                                    <circle cx="5" cy="5" r="5" fill={colors.red} />
                                  </svg>
                                </Col>
                              </Row>
                              {channel.last_message_author && channel.last_message && (
                                <Paragraph className="message" ellipsis={{ tooltip: false }}>
                                  {`${author}: ${lastMessage}`}
                                </Paragraph>
                              )}
                            </Col>
                          </Row>
                        </>
                      );
                    }}
                  />
                </ConfigProvider>
              </Col>
            </Row>
          ) : (
            <div style={{ height: '100%', display: 'flex', alignItems: 'center', padding: '16px' }}>
              <div>
                <ChatLoadingErrorPrompt />
              </div>
            </div>
          )}
        </>
      )}
      <Footer>
        {channels.length > 0 && (
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 0, padding: '10px' }}>
            <Pagination
              showSizeChanger
              total={total}
              pageSize={searchParams.pageSize}
              current={searchParams.page}
              defaultPageSize={PAGE_SIZE}
              defaultCurrent={1}
              onChange={handlePageChange}
              onShowSizeChange={handlePageSizeChange}
              disabled={!chatReadyToUse}
            />
          </div>
        )}
      </Footer>
      {enableArchive && <ArchiveChatModal ref={archiveChatModal} />}
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  channels: state.chat.channels,
  isLoading: state.chat.isLoading,
  total: state.chat.total,
});

const mapDispatchToProps = dispatch => {
  return {
    getChannels: (searchParams, setTotalUnreadCount, options) => {
      dispatch(fetchChannels(searchParams, setTotalUnreadCount, options));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ChatListView)));
