import React from 'react';
import { Tag, Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import { getStatusLabelForTimesheetEntry } from '../utils/status';
import { getColorsForTimesheetEntryStatusTag } from '../utils/color';
// TODO: rename to TimesheetStatusTag
export default withTranslation()(({ t, timesheetEntry }) => {
  const color = getColorsForTimesheetEntryStatusTag(timesheetEntry);

  return (
    <Tag color={color.secondary} style={{ borderRadius: 16, borderColor: color.primary }}>
      <Typography.Text style={{ color: color.primary }}>
        {t(getStatusLabelForTimesheetEntry(timesheetEntry))}
      </Typography.Text>
    </Tag>
  );
});
