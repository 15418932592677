/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import moment from 'moment';
import { groupBy } from 'lodash';
import { withTranslation } from 'react-i18next';
import { attendanceStatuses, DATE_KEY_FORMAT } from '../../constants';
import datetimeUtils from '../../utilities/datetimeUtils';
import employmentUtils from '../../utilities/employmentUtils';
import AssignmentTable from './components/AssignmentTable';

class AssignmentListView extends React.Component {
  state = {
    selectedRowKeys: [],
  };

  handleSelect = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleAssignmentUpdates = updates => {
    this.props.onAssignmentUpdate(updates);
  };

  handleRoleUpdates = updates => {
    this.props.onRoleUpdate(updates);
  };

  getAttendance = (workerId, shifts) => {
    const attendances = shifts.flatMap(shift => shift.attendances);
    return attendances.find(
      attendance => attendance.partner_id === workerId && attendance.status === attendanceStatuses.ASSIGNED,
    );
  };

  /***************** Data Mapping Start ***********************/
  /* Generate data structure for assignment table
     Combine employment with shifts and originalShift, populate data structure like below
   assignmentData: [
      {
        id,
        worker: {
          ...
          assignments: [
            {date,schedule:{id, name},originalAttendance: {id, partner_id, shift_id,status, change_reason, confirmed}, 
            attendance: {id, partner_id, shift_id, status, change_reason, confirmed }, shifts: {id, schedule: {id, name}}},
            timesheetEntries: [{id, clock_in_time, status}]
          ]
        },
        
      }
    ]*/

  // TODO: Optimize generate assignment table data - look into how to use existing information from schedule record
  generateAssignmentTableData = () => {
    const {
      weekdayDates,
      employments,
      originalShifts,
      shifts,
      timezone,
      shiftsOutsideSchedule,
      position,
      schedules,
    } = this.props;
    const shiftsByDay = groupBy(shifts, shift => {
      return moment(shift.start_time).format(DATE_KEY_FORMAT);
    });

    const originalShiftsByDay = groupBy(originalShifts, shift => {
      return moment(shift.start_time).format(DATE_KEY_FORMAT);
    });

    const shiftsOutsideScheduleByDay = groupBy(shiftsOutsideSchedule, shift => {
      return moment(shift.start_time).format(DATE_KEY_FORMAT);
    });

    let availableShiftsPerDay = {};
    for (const date in shiftsByDay) {
      const shiftsOnDate = shiftsByDay[date];
      const availableShifts = shiftsOnDate.map(shiftItem => {
        const startTime = moment.tz(shiftItem.start_time, timezone);
        const endTime = datetimeUtils.getEndTimeFromStartTimeAndDuration(startTime, shiftItem.duration);
        return {
          id: shiftItem.id,
          schedule: shiftItem.schedule,
          shift_roles: shiftItem.shift_roles,
          staff_required: shiftItem.staff_required,
          startTime,
          endTime,
        };
      });

      availableShiftsPerDay = { ...availableShiftsPerDay, [date]: availableShifts };
    }

    const assignments = employments.map(employment => {
      const dataItem = {
        id: employment.partner.id,
        worker: {
          employmentRoles: employment.roles,
          partner: employment.partner,
          assignments: weekdayDates.map(currentDay => {
            const dateKey = currentDay.format(DATE_KEY_FORMAT);

            const currentDayShifts = shiftsByDay[dateKey] || [];
            const attendance = this.getAttendance(employment.partner.id, currentDayShifts);
            const currentDayShiftsOutsideSchedule = shiftsOutsideScheduleByDay[dateKey] || [];
            const attendanceOutsideSchedule = this.getAttendance(
              employment.partner.id,
              currentDayShiftsOutsideSchedule,
            );

            if (attendanceOutsideSchedule) {
              attendanceOutsideSchedule.schedule = currentDayShiftsOutsideSchedule.find(
                shift => shift.id === attendanceOutsideSchedule.shift_id,
              ).schedule;
            }

            const shift = shifts.find(shiftItem => shiftItem.id === attendance?.shift_id);

            const originCurrentDayShifts = originalShiftsByDay[dateKey] || [];
            const originalAttendance = this.getAttendance(employment.partner.id, originCurrentDayShifts);

            return {
              date: currentDay,
              schedule: shift?.schedule,
              attendance,
              originalAttendance,
              attendanceOutsideSchedule,
              shifts: availableShiftsPerDay[dateKey] || [],
              contractStatus: employmentUtils.getDerivedWorkingStatus(currentDay, employment),
              position,
              schedules,
            };
          }),
        },
      };
      return dataItem;
    });

    return assignments;
  };

  render() {
    const { selectedRowKeys } = this.state;
    const {
      weekdayDates,
      loading: parentLoading,
      employments,
      onRefresh,
      timezone,
      schedules,
      scheduleId,
      isMonthlyView,
    } = this.props;

    let assignmentData;
    if (employments.length > 0) {
      assignmentData = this.generateAssignmentTableData();
    }

    return (
      <AssignmentTable
        loading={parentLoading}
        selectedRowKeys={selectedRowKeys}
        dataSource={assignmentData}
        weekdayDates={weekdayDates}
        schedules={schedules}
        onAssignmentUpdate={this.handleAssignmentUpdates}
        onRoleUpdate={this.handleRoleUpdates}
        onSelect={this.handleSelect}
        scheduleId={scheduleId}
        onRefresh={onRefresh}
        timezone={timezone}
        isMonthlyView={isMonthlyView}
      />
    );
  }
}

export default withTranslation()(AssignmentListView);
