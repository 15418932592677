import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';

const { Text } = Typography;

const BillingInfo = ({ client, t }) => {
  const { street_1, street_2, province, zip, country } = client.billingAddress;
  const infoList = [
    {
      title: t('Business registration'),
      descriptions: [client.legalName, client.businessNumber, client.taxRegistrationNumber],
    },
    {
      title: t('Billing contact'),
      descriptions: [client.billingContactName, client.billingContactEmail, client.billingContactPhone],
    },
    {
      title: t('Billing address'),
      descriptions: [`${street_1}, ${street_2}`, `${province} ${zip}`, country.name],
    },
  ];
  return (
    <>
      <Row type="flex" justify="space-between">
        {infoList.map(({ title, descriptions }, infoIndex) => (
          <Col key={infoIndex} span={12} style={{ marginBottom: '24px' }}>
            <Text strong>{title}</Text>
            <br />
            {descriptions[0]}
            <br />
            {descriptions[1]}
            <br />
            {descriptions[2]}
          </Col>
        ))}
      </Row>
    </>
  );
};

export default withTranslation()(BillingInfo);
