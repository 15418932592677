import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Typography, Modal, Select, message, Checkbox, Row, Tooltip } from 'antd';
import { errorCodes, roles, userValidationErrors } from '../../../../constants';
import managersService from '../../../../services/managersApi';

const roleOptions = [
  { label: 'Admin', value: 'admin', description: 'Full access to everything' },
  {
    label: 'Supervisor',
    value: 'supervisor',
    description: 'Access only assigned locations. No account management privilege',
  },
];

class MemberModal extends React.Component {
  state = {
    isLoading: false,
  };

  handleSubmit = () => {
    const { form, member, t } = this.props;
    this.setState({ isLoading: true });
    form.validateFieldsAndScroll(async (formErrors, values) => {
      if (!formErrors) {
        try {
          if (member) {
            await this.editMember(values);
            message.success(t(`changesSaved`));
          } else {
            await this.createMember(values);
            message.success(t(`newMemberAdded`));
          }
          this.props.onUpdate();
          this.handleClose();
        } catch (error) {
          let errorMessage = error.message;

          if (error.response.data.code === errorCodes.INACTIVE_MANAGER_RECORD_ALREADY_EXISTS) {
            // Reactivate the manager account
            try {
              await this.reactivateMember(values);
              message.success(t('memberReactivated'));
              // eslint-disable-next-line no-shadow
            } catch (error) {
              message.error(error.message);
            }

            this.props.onUpdate();
            this.handleClose();

            return;
          }

          // handling manager serializer email validation errors
          if (error.response.data.code === errorCodes.MANAGER_ALREADY_EXISTS_FOR_CLIENT) {
            errorMessage = t(userValidationErrors.EMAIL_ALREADY_EXISTS);
            form.setFields({
              email: {
                value: values.email,
                errors: [new Error(errorMessage)],
              },
            });
          }
          if (error.response.data.email?.includes(userValidationErrors.ENTER_A_VALID_USERNAME)) {
            errorMessage = t(userValidationErrors.ENTER_A_VALID_USERNAME);
            form.setFields({
              email: {
                value: values.email,
                errors: [new Error(errorMessage)],
              },
            });
          }
          message.error(errorMessage);
        }
      }
      this.setState({ isLoading: false });
    });
  };

  createMember = async values => {
    const { client } = this.props;

    const payload = {
      client: client.clientId,
      name: values.name,
      email: values.email,
      phone: values.phone,
      role: values.role,
      locations: values.locations,
      access_client_dashboard: values.access_client_dashboard || values.role === roles.ADMIN, // if undefined, default to true
      settings: values.settings,
    };

    await managersService.createManager(payload);
  };

  editMember = async values => {
    const { member, client } = this.props;

    const payload = {
      id: member.id,
      client: client.clientId,
      name: values.name,
      email: values.email,
      phone: values.phone,
      role: values.role,
      locations: values.locations,
      access_client_dashboard: values.access_client_dashboard || values.role === roles.ADMIN, // ensure admin access always true
      settings: {
        ...values.settings,
        id: member?.settings?.id,
      },
    };

    await managersService.updateManager(payload);
  };

  reactivateMember = async values => {
    const { client } = this.props;

    const payload = {
      client: client.clientId,
      name: values.name,
      email: values.email,
      phone: values.phone,
      role: values.role,
      locations: values.locations,
      access_client_dashboard: values.access_client_dashboard || values.role === roles.ADMIN, // ensure admin access always true
    };

    await managersService.reactivateManager(payload);
  };

  handleClose = () => {
    this.props.form.resetFields();
    this.props.closeModal();
  };

  showRoleOptions = (member, adminCount) => {
    // Each client should at least have one "Admin". Disallow last "Admin" to make changes to his own role.
    let showRoleOptions = true;
    if (member) {
      showRoleOptions = !(member.role === roles.ADMIN && adminCount === 1);
    }
    return showRoleOptions;
  };

  render() {
    const { form, member, client, adminCount, t } = this.props;
    const { getFieldDecorator } = form;
    if (member) {
      form.getFieldDecorator('role', { initialValue: member.role });
      form.getFieldDecorator('access_client_dashboard', { initialValue: member.access_client_dashboard });
    }
    const formValues = form.getFieldsValue();
    const locationOptions = client ? client.locations.map(loc => ({ label: loc.name, value: loc.id })) : [];
    const showRoleOptions = this.showRoleOptions(member, adminCount);
    const memberIsSupervisor = formValues.role === roles.SUPERVISOR;

    return (
      <Modal
        visible
        title={t(member ? 'Edit member' : 'Add member')}
        okText={t(member ? 'Save' : 'Add member')}
        okType="v2-primary"
        onOk={this.handleSubmit}
        onCancel={this.handleClose}
        confirmLoading={this.state.isLoading}
      >
        <Form layout="vertical" onSubmit={this.handleSubmit} hideRequiredMark>
          <Form.Item label={t('Name')} hasFeedback style={{ width: '100%', marginBottom: '16px' }}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: t('Please input a name'),
                },
              ],
              initialValue: member && member.name,
            })(<Input placeholder={t('Name')} />)}
          </Form.Item>

          <Form.Item hasFeedback label={t('Email (Username)')} style={{ width: '100%', marginBottom: '16px' }}>
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: t('Please input an email'),
                },
                {
                  type: 'email',
                  message: t('Please enter a valid email'),
                },
              ],
              initialValue: member && member.email,
            })(<Input placeholder={t('Email')} />)}
          </Form.Item>

          <Form.Item hasFeedback label={t('Phone')} style={{ width: '100%', marginBottom: '16px' }}>
            {getFieldDecorator('phone', {
              rules: [{ required: true, message: t('Please input a phone number') }],
              initialValue: member && member.phone,
            })(<Input placeholder={t('Phone')} />)}
          </Form.Item>

          {showRoleOptions ? (
            <Form.Item hasFeedback label={t('Role')} style={{ width: '100%', marginBottom: '16px' }}>
              {getFieldDecorator('role', {
                rules: [{ required: true, message: t('Please select a role') }],
                initialValue: member ? member.role : roleOptions[0].value,
              })(
                <Select optionLabelProp="label">
                  {roleOptions.map(({ label, value, description }) => (
                    <Select.Option key={value} value={value} label={t(label)}>
                      <>
                        <Typography.Text>{t(label)}</Typography.Text>
                        <br />
                        <Typography.Text type="secondary">{t(description)}</Typography.Text>
                      </>
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          ) : (
            <Typography.Text type="warning">
              {`${t('Warning')}: ${t('Cannot edit Role. There should at least be one Admin')}`}
              <br />
              <br />
            </Typography.Text>
          )}

          {memberIsSupervisor && (
            <Form.Item hasFeedback label={t('Locations')} style={{ width: '100%', marginBottom: '16px' }}>
              {getFieldDecorator('locations', {
                rules: [{ required: true, message: t('Please select at least one location') }],
                initialValue: member ? member.locations : [],
              })(
                <Select
                  showSearch
                  placeholder={t('Select locations for your supervisor')}
                  optionFilterProp="children"
                  mode="multiple"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={locationOptions}
                />,
              )}
            </Form.Item>
          )}
          {memberIsSupervisor && (
            <Form.Item style={{ width: '100%', marginBottom: '16px' }}>
              {getFieldDecorator('access_client_dashboard', {
                initialValue: member ? member.access_client_dashboard : true,
                valuePropName: 'checked',
              })(<Checkbox>{t('allowBusinessDashboardAccess')}</Checkbox>)}
            </Form.Item>
          )}
          {!memberIsSupervisor && (
            <Form.Item>
              {getFieldDecorator('settings.enable_notifications', {
                initialValue: member?.settings ? member.settings.enable_notifications : true,
                valuePropName: 'checked',
              })(
                <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={t('pushNotifsForAllLocationsTooltip')}>
                  <Checkbox>{t('pushNotifsForAllLocations')}</Checkbox>
                </Tooltip>,
              )}
            </Form.Item>
          )}
          <Row>
            <Typography.Text strong>{t('notificationSettings')}</Typography.Text>
          </Row>
          <Row>
            <Typography.Text>{t('emailMe')}</Typography.Text>
          </Row>
          <Form.Item style={{ marginBottom: '4px', marginTop: '8px' }}>
            {getFieldDecorator('settings.approved_job_posting', {
              initialValue: member?.settings ? member.settings.approved_job_posting : true,
              valuePropName: 'checked',
            })(<Checkbox>{t('jobPostIsApproved')}</Checkbox>)}
          </Form.Item>
          <Form.Item style={{ marginBottom: '4px' }}>
            {getFieldDecorator('settings.new_applications', {
              initialValue: member?.settings ? member.settings.new_applications : true,
              valuePropName: 'checked',
            })(<Checkbox>{t('receiveNewJobApplications')}</Checkbox>)}
          </Form.Item>
          <Form.Item style={{ marginBottom: '4px' }}>
            {getFieldDecorator('settings.new_unread_chat_messages', {
              initialValue: member?.settings ? member.settings.new_unread_chat_messages : true,
              valuePropName: 'checked',
            })(<Checkbox>{t('unreadChatMessages')}</Checkbox>)}
          </Form.Item>
          <Form.Item style={{ marginBottom: '4px' }}>
            {getFieldDecorator('settings.shift_data_email', {
              initialValue: member?.settings ? member.settings.shift_data_email : true,
              valuePropName: 'checked',
            })(
              <Checkbox>
                {t('shiftWeeklySummaryReport')} {t('onlySentToAdmin')}
              </Checkbox>,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('settings.weekly_digest_email', {
              initialValue: member?.settings ? member.settings.weekly_digest_email : true,
              valuePropName: 'checked',
            })(<Checkbox>{t('attendanceWeeklySummaryReport')}</Checkbox>)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(MemberModal);
