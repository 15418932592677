import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Typography, Alert } from 'antd';
import styled from 'styled-components';
import posthog from 'posthog-js';

import logo from '../../assets/images/brand_logo_dark.svg';
import routes from '../../routes';
import { colors } from '../../styles/colors';
import { EVENTS_FLAGS, errorCodes } from '../../constants';

const { Title, Text } = Typography;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
`;

const TitleWrapper = styled.div`
  text-align: left;
  padding-bottom: 24px;
`;

const Card = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 5px 20px 0px #4d4d4d26;
  max-width: 432px;
  padding: 48px 24px 24px 24px;
`;

class LoginPageView extends React.Component {
  async componentDidMount() {
    const token = localStorage.getItem('token');
    if (token) {
      this.props.history.push(routes.home);
    }
  }

  componentDidUpdate() {
    const token = localStorage.getItem('token');
    if (token) {
      this.props.history.push(routes.home);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    posthog.capture(EVENTS_FLAGS.SIGN_IN, {
      event: 'login_start',
    });
    this.props.form.validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        try {
          const { email, password } = values;
          this.props.handleLogin(email, password);
          posthog.capture(EVENTS_FLAGS.SIGN_IN, {
            event: 'login_success',
          });
        } catch (apiError) {
          // eslint-disable-next-line no-console
          console.warn(apiError.message);
          posthog.capture(EVENTS_FLAGS.SIGN_IN, {
            event: 'login_error',
          });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { error, isLoading } = this.props.user;
    const { t } = this.props;
    return (
      <Layout>
        <img src={logo} alt="Brand Logo" style={{ height: '24px', marginBottom: '24px' }} />

        <Card>
          <TitleWrapper>
            <Title
              level={4}
              style={{ color: colors.secondaryText, marginBottom: 0, fontSize: '16px', fontWeight: '600' }}
            >
              {t('loginTitle')}
            </Title>
            <Text style={{ fontSize: '20px', fontWeight: '600' }}>{t('loginSubTitle')}</Text>
          </TitleWrapper>

          <Form layout="vertical" hideRequiredMark colon={false}>
            {/* Login error message */}
            {error.length > 0 &&
              (error === errorCodes.USER_ACCOUNT_IS_NOT_ACTIVE ? (
                <Row>
                  <Alert
                    showIcon
                    closable
                    type="error"
                    message={
                      <Text>
                        {t('havingTroubleLogIn')}
                        <Button
                          type="link"
                          style={{ paddingLeft: 4, height: '16px' }}
                          onClick={() => {
                            window.zE('webWidget', 'show');
                            window.zE.activate({ hideOnClose: true });
                          }}
                        >
                          {t('contactSupport')}
                        </Button>
                      </Text>
                    }
                    onClose={this.props.clearError}
                  />
                </Row>
              ) : (
                <Row>
                  <Alert showIcon closable type="error" message={t(error)} onClose={this.props.clearError} />
                </Row>
              ))}

            {/* Login Form */}
            <Form.Item hasFeedback style={{ marginBottom: '16px' }}>
              {getFieldDecorator('email', {
                rules: [{ required: true, message: t('fieldRequired') }],
                initialValue: '',
              })(<Input type="email" size="large" placeholder={t('businessEmail')} />)}
            </Form.Item>

            <Form.Item hasFeedback style={{ marginBottom: 0 }}>
              {getFieldDecorator('password', {
                rules: [{ required: true, min: 7, message: t('fieldLengthInvalid', { min: 7 }) }],
                initialValue: '',
              })(<Input.Password size="large" placeholder={t('password')} />)}
            </Form.Item>

            <div style={{ textAlign: 'end', paddingBottom: '24px' }}>
              <Button
                type="link"
                onClick={() => {
                  this.props.history.push(routes.forgotPassword);
                }}
              >
                <Text underline style={{ color: '#1890ff' }}>
                  {t('forgotPassword')}
                </Text>
              </Button>
            </div>

            <Button
              block
              size="large"
              type="primary"
              style={{
                backgroundColor: colors.workmateGreen,
                border: `1px solid ${colors.borderGrey}`,
                marginBottom: '24px',
              }}
              htmlType="submit"
              loading={isLoading}
              onClick={this.handleSubmit}
            >
              {t('logIn').toUpperCase()}
            </Button>
            <Text style={{ paddingRight: '4px' }}>{t('newToWorkmate')}</Text>
            <Text
              underline
              style={{ color: colors.functionalLink, cursor: 'pointer' }}
              onClick={() => {
                this.props.history.push(routes.signUp);
              }}
            >
              {t('signUp')}
            </Text>
          </Form>
        </Card>
      </Layout>
    );
  }
}
export default Form.create({ name: 'Login Form' })(LoginPageView);
