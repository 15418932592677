import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';
import { uniqueId } from 'lodash';
import { Card, Row, Col, Typography, Button, Avatar, message, Pagination } from 'antd';
import { ArrowLeftOutlined, InboxOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';

import routes from '../routes';
import Header from './Header';
import Footer from './Footer';
import Context from '../context';
import ChatLoadingErrorPrompt from './ChatLoadingErrorPrompt';

import workerUtils from '../../../utilities/workerUtils';
import fetchChatChannel from '../../../utilities/chatUtils';
import applicationApi from '../../../services/applicationApi';

import StatusTag from '../../../shared/components/StatusTag';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import ApplicantToggleModal from '../../StaffRequestApplicantsList/components/ApplicantToggleModal';

import * as chatActions from '../../../redux/chat';
import { channelTypes, twilioConnectionStates } from '../constants';
import { audiences, applicationStatuses, channelStatuses } from '../../../constants';
import { colors } from '../../../styles/colors';
import DropdownButtons from '../../../shared/components/DropdownButtons';
import ArchiveChatModal from './ArchiveChatModal';

const { Title, Text } = Typography;
const DEFAULT_PAGE_SIZE = 20;

class ChatInfoView extends Component {
  archiveChatModal = React.createRef();

  twilioChatChannel = null;

  state = {
    selectedChannel: undefined,
    isLoading: false,
    isLoadingMembers: false,
    chatName: undefined,
    showApplicantToggleModal: false,
    workersInChat: [],
    selectedApplicantIndex: undefined,
    managersInChat: [],
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1,
    totalMembers: undefined,
    staffRequestRoute: '',
  };

  async componentDidMount() {
    const { location, match } = this.props;
    const { twilioChatClient } = this.context;
    const { channel, staffRequestRoute } = location.state;
    const { channelId } = match.params;

    this.setState({
      isLoading: true,
      staffRequestRoute,
    });

    try {
      const response = await fetchChatChannel(twilioChatClient, channelId, channel);
      this.fetchChatChannelOnSuccess(...response);
    } catch (error) {
      this.fetchChatChannelOnFail(error);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedChannel } = this.state;
    if (selectedChannel && (prevState.page !== this.state.page || prevState.pageSize !== this.state.pageSize)) {
      this.formatChatMemberList();
    }
  }

  componentWillUnmount() {
    this.twilioChatChannel?.off('updated', this.channelUpdated);
  }

  fetchChatChannelOnSuccess = (selectedChannel, twilioChatChannel) => {
    this.setState(
      {
        selectedChannel,
        chatName: selectedChannel?.channelName || twilioChatChannel?.channelState.friendlyName,
        isLoading: false,
      },
      () => {
        this.twilioChatChannel = twilioChatChannel;
        this.twilioChatChannel?.on('updated', this.channelUpdated);
        this.formatChatMemberList();
      },
    );
  };

  fetchChatChannelOnFail = error => {
    const { t } = this.props;
    this.setState({ isLoading: false }, () => Sentry.captureException(error));
    message.warning(t('An unexpected error occurred'));
  };

  channelUpdated = event => {
    const { selectedChannel } = this.state;
    if (event.updateReasons.includes('attributes') && event.conversation.sid === selectedChannel?.sid) {
      if (event.conversation.attributes?.status === channelStatuses.ARCHIVED) {
        this.setState({
          selectedChannel: {
            ...selectedChannel,
            enable_reply: false,
            status: channelStatuses.ARCHIVED,
          },
        });
      }
    }
  };

  formatChatMemberList = async () => {
    const { t } = this.props;
    const { selectedChannel, page, pageSize } = this.state;
    const { members, staff_request } = selectedChannel;
    this.setState({ isLoadingMembers: true });
    const workersInChat = [];
    const managersInChat = [];
    members.forEach(member => {
      if (!member || member?.manager?.id) {
        managersInChat.push(member);
      }
      if (member && !member.manager?.id && member.partner?.id) {
        workersInChat.push(member.partner.id);
      }
    });
    try {
      const response = await applicationApi.list({
        staff_request: staff_request.id,
        partner: workersInChat.join(),
        page_size: pageSize,
        page,
      });

      let workersInChatAppended = [];
      if (response.count > 0) {
        workersInChatAppended = await workerUtils.appendStatisticsAndExperienceToApplications(response.results);
      }
      this.setState({
        managersInChat,
        workersInChat: workersInChatAppended,
        isLoadingMembers: false,
        totalMembers: response.count + managersInChat.length,
      });
    } catch (error) {
      Sentry.captureException(error);
      message.warning(t('channelMembersErrorMessage'));
    }
  };

  openApplicantModal = memberId => {
    const { workersInChat } = this.state;
    const selectedApplicantIndex = workersInChat.findIndex(member => member?.partner.id === memberId);
    this.setState({ selectedApplicantIndex, showApplicantToggleModal: true });
  };

  // Takes a list of workers and update the worker status given a ID.
  // This is used to avoid re-fetching the entire table, but to update the worker table in our state.
  updateLocalApplication = (applications, applicationId, nextStatus) => {
    for (let i = 0; i < applications.length; i += 1) {
      if (applications[i].id === applicationId) {
        // eslint-disable-next-line no-param-reassign
        applications[i].status = nextStatus;
        // eslint-disable-next-line no-param-reassign
        applications[i].modified_date = new Date();
        break;
      }
    }
    return applications;
  };

  handleStartChat = async partner => {
    const { openChat } = this.props;
    const { selectedChannel } = this.state;

    this.setState(
      {
        showApplicantToggleModal: false,
      },
      () => {
        openChat({
          pathname: routes.createChat,
          state: {
            createChannel: true,
            channelAttributes: {
              staffRequestId: selectedChannel.staff_request.id,
              audience: audiences.SELECTED_APPLICANTS,
              members: partner.user.id,
              name: `${selectedChannel.staff_request.client?.name} - ${selectedChannel.staff_request.title}`,
              type: channelTypes.PRIVATE,
            },
          },
        });
      },
    );
  };

  onHire = async (applicationId, workerName) => {
    const { t } = this.props;

    try {
      const response = await applicationApi.approve(applicationId);
      if (response) {
        this.setState(prevState => {
          const nextApplications = this.updateLocalApplication(
            prevState.workersInChat,
            applicationId,
            applicationStatuses.PENDING_CONTRACT,
          );
          return {
            workersInChat: nextApplications,
          };
        });
        message.success(`${t('Successfully sent contract to')} ${workerName}`);
      }
    } catch (error) {
      message.error(error.response.data.detail || error.message);
    }
  };

  onReject = async (applicationId, workerName) => {
    const { t } = this.props;

    try {
      const response = await applicationApi.reject(applicationId);
      if (response) {
        this.setState(prevState => {
          const nextApplications = this.updateLocalApplication(
            prevState.workersInChat,
            applicationId,
            applicationStatuses.REJECTED,
          );
          return {
            workersInChat: nextApplications,
          };
        });
        message.success(`${t('Successfully rejected')} ${workerName}`);
      }
    } catch (error) {
      message.error(error.response.data.detail || error.message);
    }
  };

  onShortlist = async (selectedWorkerId, localApplicationIndex) => {
    const response = await applicationApi.shortlist(selectedWorkerId);
    if (response) {
      this.setState(prevState => {
        const { workersInChat } = prevState;
        const updatedApplications = workersInChat;
        updatedApplications[localApplicationIndex].shortlisted = true;
        updatedApplications[localApplicationIndex].interviewed = false;
        this.setState({ workersInChat: updatedApplications });
      });
    }
  };

  onUnshortlist = async (selectedWorkerId, localApplicationIndex) => {
    const response = await applicationApi.unShortlist(selectedWorkerId);
    if (response) {
      this.setState(prevState => {
        const { workersInChat } = prevState;
        const updatedApplications = workersInChat;
        updatedApplications[localApplicationIndex].shortlisted = false;
        updatedApplications[localApplicationIndex].interviewed = false;
        this.setState({ workersInChat: updatedApplications });
      });
    }
  };

  onInterview = async (selectedWorkerId, localApplicationIndex) => {
    const response = await applicationApi.interview(selectedWorkerId);
    if (response) {
      this.setState(prevState => {
        const { workersInChat } = prevState;
        const updatedApplications = workersInChat;
        updatedApplications[localApplicationIndex].shortlisted = false;
        updatedApplications[localApplicationIndex].interviewed = true;
        this.setState({ workersInChat: updatedApplications });
      });
    }
  };

  getDropdownButtons = member => {
    const disabled = [applicationStatuses.REJECTED, applicationStatuses.WITHDRAWN].includes(member.status);
    const { t } = this.props;
    return [
      {
        title: t('privateChat'),
        disabled,
        onClick: () => {
          const { selectedChannel } = this.state;
          const { openChat } = this.props;

          if (selectedChannel.staff_request) {
            openChat({
              pathname: routes.createChat,
              state: {
                createChannel: true,
                channelAttributes: {
                  staffRequestId: selectedChannel.staff_request.id,
                  audience: audiences.SELECTED_APPLICANTS,
                  members: member.partner.user.id,
                  name: `${selectedChannel.staff_request.client.name} - ${selectedChannel.staff_request.title}`,
                  type: channelTypes.PRIVATE,
                },
              },
            });
          }
        },
      },
    ];
  };

  displayMembersInList = member => {
    const { clientLogo, t } = this.props;
    const { selectedChannel } = this.state;
    if (member?.manager) {
      return (
        <div key={uniqueId()}>
          <Row key={uniqueId()} type="flex" justify="start" align="middle" style={{ padding: '6px 0' }}>
            <Col>
              <Avatar shape="circle" size={60} src={clientLogo} className="v2-avatar-wrapper" />
            </Col>
            <Col style={{ textAlign: 'left' }}>
              <section
                style={{
                  marginTop: '3px',
                  textAlign: 'left',
                  padding: '12px 14px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <Row>
                  <Text className="name">{member.manager.name}</Text>
                </Row>
                <Row>
                  <Text className="name">{t('manager').toUpperCase()}</Text>
                </Row>
              </section>
            </Col>
          </Row>
        </div>
      );
    }
    if (member?.partner) {
      return (
        <div key={uniqueId()}>
          <Row key={uniqueId()} type="flex" justify="start" align="middle" style={{ padding: '6px 0' }}>
            <Col>
              <Avatar
                shape="circle"
                size={60}
                src={member.partner.image}
                className="clickable-chat-avatar"
                onClick={() => {
                  this.openApplicantModal(member.partner.id);
                }}
              />
            </Col>
            <Col style={{ textAlign: 'left' }}>
              <section
                style={{
                  marginTop: '3px',
                  textAlign: 'left',
                  padding: '12px 14px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <Row>
                  <Text className="name">{`${member.partner.first_name} ${member.partner.last_name}`}</Text>
                </Row>
                <Row>
                  {member?.status && (
                    <StatusTag status={member.status} interview={member.interviewed} shortlisted={member.shortlisted} />
                  )}
                </Row>
              </section>
            </Col>
            {selectedChannel.type !== channelTypes.PRIVATE && (
              <Col style={{ marginLeft: 'auto' }}>
                <DropdownButtons
                  overlayClassName="chat-info-member-actions"
                  buttons={this.getDropdownButtons(member)}
                />
              </Col>
            )}
          </Row>
        </div>
      );
    }
  };

  handlePageChange = (page, pageSize) => {
    this.setState({ page, pageSize });
  };

  handlePageSizeChange = (current, pageSize) => {
    this.setState({ page: 1, pageSize });
  };

  handleDisplayMemberList = isChatReadyToUse => {
    const { isLoadingMembers, managersInChat, workersInChat, totalMembers, page, selectedChannel } = this.state;
    const { t } = this.props;
    const memberList =
      page === 1 ? managersInChat && workersInChat && [...managersInChat, ...workersInChat] : workersInChat;
    if (!isLoadingMembers && !isChatReadyToUse) {
      return (
        // Center error prompt
        <div style={{ height: '500px', display: 'flex', alignItems: 'center', padding: '16px' }}>
          <div>
            <ChatLoadingErrorPrompt />
          </div>
        </div>
      );
    }
    if (isLoadingMembers) {
      return (
        // Center loading spinner
        <div style={{ height: '600px', display: 'flex', alignItems: 'center' }}>
          <LoadingSpinner width="50px" />
        </div>
      );
    }
    if (isChatReadyToUse && !isLoadingMembers) {
      return (
        <div style={{ marginTop: selectedChannel?.status !== channelStatuses.ARCHIVED ? 16 : 0, padding: 16 }}>
          <Text strong>{t('channelMemberCount', { memberCount: totalMembers })}</Text>
          {memberList &&
            memberList.map(member => {
              return this.displayMembersInList(member);
            })}
        </div>
      );
    }
  };

  handleDisplayInfo = isChatReadyToUse => {
    const { chatName, selectedChannel, staffRequestRoute } = this.state;
    const { t } = this.props;

    if (isChatReadyToUse) {
      if (selectedChannel.type === channelTypes.BROADCAST) {
        return (
          <>
            <Row style={{ padding: '16px 16px 0' }}>
              <Title level={3}>{t('groupInfo')}</Title>
            </Row>
            <Row type="flex" align="bottom" style={{ padding: '0 16px 16px' }}>
              <Col span={20}>
                <div>
                  <Text strong>{t('groupChatName')}</Text>
                </div>
                <Text style={{ marginRight: 8 }}>{chatName}</Text>
                {selectedChannel?.status === channelStatuses.ARCHIVED && <StatusTag status={selectedChannel.status} />}
              </Col>
            </Row>
          </>
        );
      }

      const staffRequestTitle = selectedChannel.staff_request.title;
      if (selectedChannel.type === channelTypes.PRIVATE) {
        return (
          <>
            <Row style={{ padding: '16px 16px 0' }}>
              <Title level={3}>{t('chatInfo')}</Title>
            </Row>
            <Row type="flex" align="bottom" style={{ padding: '0 16px 16px' }}>
              <Col span={24}>
                <div>
                  <Text strong>{t('jobDetail')}</Text>
                </div>
                <Button
                  type="link"
                  style={{
                    border: '0 none',
                    fontSize: 12,
                    fontWeight: 700,
                    padding: 0,
                    textTransform: 'uppercase',
                    width: '100%',
                  }}
                  onClick={() => {
                    if (staffRequestRoute) {
                      // Force reload the page
                      this.context.browserHistory.push('');
                      setTimeout(() => {
                        this.context.browserHistory.push(staffRequestRoute);
                      });
                    }
                  }}
                >
                  <Text
                    ellipsis={{ tooltip: true }}
                    style={{
                      color: colors.functionalLink,
                      textAlign: 'left',
                      width: '100%',
                    }}
                  >
                    {staffRequestTitle}
                  </Text>
                </Button>
                {selectedChannel?.status === channelStatuses.ARCHIVED && <StatusTag status={selectedChannel.status} />}
              </Col>
            </Row>
          </>
        );
      }
    }

    return <></>;
  };

  handleArchiveChat = () => {
    const { selectedChannel } = this.state;
    this.setState({
      selectedChannel: {
        ...selectedChannel,
        enable_reply: false,
        status: channelStatuses.ARCHIVED,
      },
    });
  };

  // Placed here to avoid eslint error when placed at top
  static contextType = Context;

  render() {
    const { t, history, location, user } = this.props;
    const {
      isLoading,
      isLoadingMembers,
      selectedChannel,
      showApplicantToggleModal,
      selectedApplicantIndex,
      totalMembers,
      pageSize,
      page,
      managersInChat,
    } = this.state;

    const chatReadyToUse = this.context.twilioConnectionState === twilioConnectionStates.CONNECTED && selectedChannel;

    return (
      <div>
        <Header>
          <Button
            type="link"
            icon={<ArrowLeftOutlined />}
            onClick={() => {
              if (selectedChannel?.id) {
                const route = generatePath(routes.openChatDetail, {
                  channelId: selectedChannel?.id,
                });
                history.push(route, {
                  ...location?.state,
                  channel: selectedChannel,
                });
              } else {
                history.push(routes.openChatList, {
                  ...location?.state,
                });
              }
            }}
            style={{ padding: 0, margin: 0, height: 'auto' }}
          >
            <Text style={{ marginLeft: '10px', color: colors.functionalLink }}>{t('back')}</Text>
          </Button>
        </Header>
        {isLoading ? (
          <LoadingSpinner width="50px" />
        ) : (
          <Card
            bordered={false}
            style={{
              overflow: 'hidden',
              width: '100%',
            }}
            bodyStyle={{ display: 'flex', flexDirection: 'column', padding: 0 }}
          >
            {this.handleDisplayInfo(chatReadyToUse)}
            {selectedChannel?.status !== channelStatuses.ARCHIVED && (
              <Row
                type="flex"
                align="bottom"
                style={{
                  boxShadow: 'none',
                  borderWidth: '1px 0',
                  borderColor: colors.backgroundGrey,
                  borderStyle: 'solid',
                  padding: '8px',
                }}
              >
                <Col span={24}>
                  <Button
                    block
                    type="text"
                    icon={<InboxOutlined style={{ fontSize: 24 }} />}
                    style={{
                      display: 'flex',
                      fontWeight: 'bold',
                      paddingLeft: 6.4,
                      paddingRight: 6.4,
                      textAlign: 'left',
                    }}
                    onClick={() => {
                      this.archiveChatModal.current.open(selectedChannel.id, this.handleArchiveChat);
                    }}
                  >
                    {t('archiveChat')}
                  </Button>
                </Col>
              </Row>
            )}
            {this.handleDisplayMemberList(chatReadyToUse)}
          </Card>
        )}
        <Footer>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 0, padding: '10px' }}>
            <Pagination
              showSizeChanger
              total={totalMembers - managersInChat.length || DEFAULT_PAGE_SIZE}
              pageSize={pageSize}
              current={page}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              defaultCurrent={1}
              onChange={this.handlePageChange}
              onShowSizeChange={this.handlePageSizeChange}
              disabled={!chatReadyToUse || isLoadingMembers}
            />
          </div>
        </Footer>
        {showApplicantToggleModal && (
          <ApplicantToggleModal
            visible={showApplicantToggleModal}
            onChat={this.handleStartChat}
            onHire={this.onHire}
            onReject={this.onReject}
            onShortlist={this.onShortlist}
            onUnshortlist={this.onUnshortlist}
            onInterview={this.onInterview}
            onCancel={() => this.setState({ showApplicantToggleModal: false })}
            selectedApplicantIndex={selectedApplicantIndex}
            applications={this.state.workersInChat}
            pageNum={1}
            user={user}
          />
        )}
        <ArchiveChatModal ref={this.archiveChatModal} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clientLogo: state.user.logo,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  const { openChat } = chatActions;
  return {
    openChat: (route, state) => {
      dispatch(openChat(route, state, true));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatInfoView));
