import { useContext } from 'react';
import memoize from 'p-memoize';
import Context from '../containers/Chat/context';
import channelApi from '../services/channelApi';
import { channelTypes } from '../containers/Chat/constants';

export const getTwilioConnectionState = () => {
  const context = useContext(Context);
  return context.twilioConnectionState;
};

export const getEstablishTwilioConnection = () => {
  const context = useContext(Context);
  return context.establishTwilioConnection;
};

export const getTwilioChatClient = () => {
  const context = useContext(Context);
  return context.twilioChatClient;
};

const memoizeConfig = {
  cachePromiseRejection: false,
  maxAge: 180000,
};

export const getChatClient = memoize(async userId => {
  const chatToken = await channelApi.fetchChatToken(`${userId}`);
  const twilioToken = chatToken.apns;
  const chatClient = await window.Twilio.Conversations.Client.create(twilioToken);
  return chatClient;
}, memoizeConfig);

export const getTwilioConversation = memoize(async (channelSid, chatClient) => {
  const conversation = await chatClient.getConversationBySid(channelSid);
  return conversation;
}, memoizeConfig);

export const getMember = channel => {
  return channel.members.find(member => channel.type === channelTypes.PRIVATE && member.partner);
};

const fetchChatChannel = async (chatClient, channelId, channel) => {
  let selectedChannel;

  if (channel) {
    selectedChannel = channel;
  } else if (channelId) {
    selectedChannel = await channelApi.fetchChannelById(channelId);
  }

  const twilioConversation = await getTwilioConversation(selectedChannel.sid, chatClient);
  if (twilioConversation.status === 'notParticipating') {
    await channelApi.joinChannel(selectedChannel.id);
    selectedChannel = await channelApi.fetchChannelById(selectedChannel.id);
  }

  return [selectedChannel, twilioConversation];
};

export default fetchChatChannel;

export const getLocationState = location => {
  // Exclude channel creation params
  const { createChannel, channelAttributes, ...state } = location?.state;
  return state;
};
