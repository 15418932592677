/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';
import { Layout, message, Row, Typography, Result, Button } from 'antd';
import moment from 'moment-timezone';
import routes from '../../routes';
import * as userActions from '../../redux/user';
import * as globalActions from '../../redux/global';
import * as chatActions from '../../redux/chat';
import TopNavMenu from './TopNavMenu';
import ErrorPage from './ErrorPage';
import LoadingSpinner from '../components/LoadingSpinner';
import { UTMContext } from '../hooks/useUTM';

// TODO: Remove below imports once we are permanently switched to new V2

import { colors } from '../../styles/colors';
import { localStorageKeys, roles, sessionStorageKeys } from '../../constants';
import chatRoutes from '../../containers/Chat/routes';
import { retrieveParamsFromUrl } from '../../utilities/urlUtils';
import i18n from '../../i18n';

const { Header, Content } = Layout;
// High-level styles for easy customizability
const DEFAULT_MAX_WIDTH = 1168;

class AuthLayout extends React.Component {
  state = {
    error: null,
    errorStack: null,
  };

  async componentDidMount() {
    window.addEventListener('focus', this.onFocus);
    const { user, global, initializeUser, initializeGlobalData, initializeChat, history, updateGlobal } = this.props;
    window.scrollTo(0, 0);
    const params = retrieveParamsFromUrl(history.location.search);
    const tokenFromUrl = params?.token;
    const managerIdFromUrl = params?.managerId;
    const locationIdFromUrl = params?.locationId;
    const languageFromUrl = params?.lang;
    if (tokenFromUrl && managerIdFromUrl) {
      sessionStorage.setItem(sessionStorageKeys.MANAGER_ID, managerIdFromUrl);
      localStorage.setItem(localStorageKeys.MANAGER_ID, managerIdFromUrl);
      localStorage.setItem(localStorageKeys.TOKEN, tokenFromUrl);
      localStorage.setItem('i18nextLng', languageFromUrl);
      i18n.changeLanguage(languageFromUrl, () => moment.locale(languageFromUrl));
      updateGlobal({ isOnWebView: true, locationId: parseInt(locationIdFromUrl, 10) });
    }
    if (!localStorage.getItem(localStorageKeys.TOKEN)) {
      this.onLogout();
      return;
    }
    // Fetch user if manager is not set
    if (user.managerId === '') {
      await initializeUser();
    }
    // Fetch positions if not set
    if (global.positions.length === 0) {
      initializeGlobalData();
    }

    window.zE('webWidget', 'hide');

    initializeChat();
  }

  // When user routes away from error page, we should hide the error page
  componentDidUpdate(prevProps) {
    if (this.state.error && prevProps.location !== this.props.location) {
      this.clearError();
    }
    const noClientLoaded = this.props.user.hasLoaded && !this.props.user.clientId;
    if (noClientLoaded) {
      this.props.history.push(routes.signUp);
      message.warn(this.props.t('Please complete the signup form'));
    }

    // user has no manager with client
    const noManagerLoaded = this.props.user.hasLoaded && !this.props.user.managerId;
    if (noManagerLoaded) {
      this.onLogout();
    }

    const clientLoaded = this.props.user.hasLoaded && this.props.user.clientId;

    if (clientLoaded) {
      const { username, uuid, clientId } = this.props.user;
      datadogRum.setUser({
        id: uuid.toString(),
        email: username,
        clientId,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onFocus);
  }

  onFocus = () => {
    // everytime we focus a tab, we save the current session's manager to localStorage
    // in order to allow manager sessions to persist in new tabs.
    const managerId = sessionStorage.getItem(sessionStorageKeys.MANAGER_ID);
    if (managerId) localStorage.setItem(localStorageKeys.MANAGER_ID, managerId);
  };

  onLogout = async () => {
    await this.props.logoutUser();
    this.props.history.push(routes.login);
  };

  clearError = () => {
    this.setState({ error: null, errorStack: null });
  };

  componentDidCatch(error, info) {
    this.setState({ error, errorStack: info.componentStack });
  }

  static contextType = UTMContext;

  render() {
    const {
      user,
      history,
      children,
      t,
      maxWidth,
      openChatList,
      totalUnreadCount,
      chatVisible,
      initializeUser,
      global,
    } = this.props;

    if (!user.hasLoaded || !user.clientId || !user.managerId || user.isLoading) return <LoadingSpinner />;

    // assumes user.hasLoaded
    if (user.hasLoaded && !user.accessAllowed) {
      return (
        <Result
          status="warning"
          title={
            <div style={{ marginBottom: 0, display: 'flex', flexDirection: 'column', fontWeight: 600 }}>
              {t('supervisorNoDashboardPermission')}
              <Typography.Text style={{ fontSize: 14, fontWeight: 200 }}>
                {t('supervisorNoDashboardPermissionDescription')}
              </Typography.Text>
            </div>
          }
          extra={
            <Button
              type="primary"
              key="console"
              onClick={this.onLogout}
              style={{
                backgroundColor: colors.workmateGreen,
                maxWidth: 100,
                alignSelf: 'center',
                fontWeight: 600,
              }}
            >
              {t('logout')}
            </Button>
          }
        />
      );
    }

    const { isOnWebView } = global;

    return (
      <Layout style={{ backgroundColor: 'white', height: '100%' }}>
        {!isOnWebView && (
          <Header style={{ position: 'fixed', width: '100%', zIndex: 3 }}>
            <Row
              style={{
                maxWidth: (maxWidth || DEFAULT_MAX_WIDTH) + 80,
                margin: 'auto',
                color: 'white',
              }}
            >
              <TopNavMenu
                t={t}
                history={history}
                username={user.username}
                clientName={user.name}
                clientType={user.type}
                userLogo={user.logo}
                userRole={user.role}
                chatEnabledAndAdmin={user.chatEnabled && user.role === roles.ADMIN}
                chatVisible={chatVisible}
                messageCount={totalUnreadCount}
                chatOnClick={openChatList}
                logoutUser={this.onLogout}
                managers={user.userManagers}
                initializeUser={initializeUser}
              />
            </Row>
          </Header>
        )}

        {/* Page content */}
        <Content
          style={{
            width: '100%',
            margin: 'auto',
            maxWidth: (maxWidth || DEFAULT_MAX_WIDTH) + 100,
            marginTop: !isOnWebView ? 128 : 20,
            minHeight: 'calc(100vh - 128px)',
            padding: '0 20px',
          }}
        >
          {this.state.error ? (
            <ErrorPage
              title="Error"
              subTitle={t('Sorry! There is an error loading the page')}
              errorStack={this.state.errorStack}
              onClick={this.clearError}
            />
          ) : (
            <>{children}</>
          )}
        </Content>
        {/* Customer support menus including zendesk chat widget */}
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    global: state.global,
    totalUnreadCount: state.chat.totalUnreadCount,
    chatVisible: state.chat.visible,
  };
};

const mapDispatchToProps = dispatch => {
  const { logout, fetchClientAndManager } = userActions;
  const { fetchPositions, globalUpdateAction } = globalActions;
  const { openChat, fetchTotalUnreadCount } = chatActions;
  return {
    logoutUser: async () => {
      await dispatch(logout());
    },
    initializeGlobalData: () => {
      dispatch(fetchPositions());
    },
    initializeUser: async () => {
      await dispatch(fetchClientAndManager());
    },
    initializeChat: () => {
      dispatch(fetchTotalUnreadCount());
    },
    openChatList: () => {
      dispatch(openChat(chatRoutes.openChatList));
    },
    updateGlobal: payload => {
      dispatch(globalUpdateAction(payload));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AuthLayout)));
