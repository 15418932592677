import React, { useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Row, Col, Checkbox, Radio, Input, Typography, Tooltip, Form } from 'antd';
import styled from 'styled-components';
import { choiceTypesWithKeys, MAX_QUESTION_DESCRIPTION_LENGTH } from '../../../constants';
import questionsUtils from '../../../utilities/questionsUtils';
import { colors } from '../../../styles/colors';
import DeleteButton from './DeleteButton';
import OtherPleaseSpecify from './OtherPleaseSpecify';

const StyledInputWrapper = styled('div')`
  position: relative;
  border: 1px solid ${colors.componentBorder};
  border-radius: 2px;
  padding: 3px 10px;
  &:hover {
    border-color: ${colors.functionalLink};
  }
`;

const StyledNoFocusInput = styled('input')`
  color: ${colors.disabled};
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  &:focus {
    outline: none;
  }
`;

const StyledInput = styled(Input)`
  &::placeholder {
    font-weight: 400;
  }
`;

const { List, Item } = Form;

const ChoiceOption = ({ choiceType, isChecked = false }) => {
  let element = null;
  if (choiceType === choiceTypesWithKeys.SINGLE_SELECT.key) {
    element = <Radio className="v2-radio-wrapper" checked={isChecked} style={{ margin: 0, pointerEvents: 'none' }} />;
  } else if (choiceType === choiceTypesWithKeys.MULTI_SELECT.key) {
    element = (
      <Checkbox className="v2-checkbox-wrapper" checked={isChecked} style={{ margin: 0, pointerEvents: 'none' }} />
    );
  }
  return element;
};

const CreateQuestionManageOptions = ({ form, choiceType, hasOther, setHasOther, hasScore, onCheckOptionChange }) => {
  const { t } = useTranslation();
  const canFocusRef = useRef(false);

  const canSetCorrectAnswer = questionsUtils.canSetCorrectAnswer(choiceType, hasScore);

  return (
    <List name="choices">
      {(fields, { add, remove }) => (
        <>
          {/* Correct Label */}
          {canSetCorrectAnswer && (
            <Row style={{ justifyContent: 'flex-end', marginBottom: 6 }}>
              <Typography.Text style={{ color: colors.primaryText, fontWeight: 600 }}>
                {t('questionCreateCorrect')}
              </Typography.Text>
            </Row>
          )}
          <Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const checkChoiceScored = index => {
                const choices = getFieldValue('choices');
                const choice = choices[index];
                return hasScore && Boolean(choice?.score);
              };
              return (
                <>
                  {/* Dynamic Choice Input Fields */}
                  {fields.map(({ key, name, ...restField }, index) => {
                    const isScored = checkChoiceScored(index);
                    return (
                      <Row key={key} align="baseline" gutter={8} style={{ marginBottom: 16 }}>
                        <Col xs={20}>
                          <Row>
                            <Col style={{ margin: '7px 8px 0 0' }}>
                              <ChoiceOption choiceType={choiceType} isChecked={canSetCorrectAnswer && isScored} />
                            </Col>
                            <Item
                              {...restField}
                              name={[name, 'description']}
                              rules={[
                                { required: true, message: t('questionOptionEmptyError') },
                                { whitespace: true, message: t('questionOptionEmptyError') },
                                {
                                  validator: async (_, description) => {
                                    if (description && description.trim()) {
                                      const options = form.getFieldValue('choices');
                                      const isDuplicate = questionsUtils.checkDuplicateOptions({
                                        options,
                                        description,
                                        index,
                                      });
                                      if (isDuplicate) {
                                        return Promise.reject(new Error(t('questionOptionDuplicateError')));
                                      }
                                      return Promise.resolve();
                                    }
                                  },
                                },
                              ]}
                              style={{ marginBottom: 0, flex: 1 }}
                            >
                              <StyledInput
                                placeholder={t('questionOptionPlaceholder', { count: index + 1 })}
                                maxLength={MAX_QUESTION_DESCRIPTION_LENGTH}
                                autoFocus={index === fields.length - 1 && canFocusRef.current}
                                style={{
                                  color: canSetCorrectAnswer && isScored ? colors.functionalSuccess : colors.textTitle,
                                  fontWeight: canSetCorrectAnswer && isScored ? '600' : '400',
                                }}
                              />
                            </Item>
                          </Row>
                        </Col>
                        <Col xs={4}>
                          <Row style={{ margin: '7px 0 0 4px' }}>
                            {/* Delete Button */}
                            <Col flex="1" style={{ alignItems: 'flex-start' }}>
                              {fields.length > 1 && <DeleteButton onClick={() => remove(name)} />}
                            </Col>
                            {/* Set as correct answer checkbox */}
                            <Col flex="1" style={{ justifyContent: 'center', display: 'flex' }}>
                              {canSetCorrectAnswer && (
                                <Tooltip
                                  title={t('questionCreateCorrectCheckboxTooltip')}
                                  arrowPointAtCenter
                                  placement="topLeft"
                                >
                                  <Row>
                                    <Item {...restField} name={[name, 'score']} valuePropName="checked" noStyle>
                                      <Checkbox
                                        className="v2-checkbox-wrapper"
                                        style={{ marginTop: -1 }}
                                        onChange={() => onCheckOptionChange(index)}
                                      />
                                    </Item>
                                  </Row>
                                </Tooltip>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              );
            }}
          </Item>

          {/* Other Field */}
          {hasOther && (
            <Row align="center" gutter={8} style={{ marginBottom: 16 }}>
              <Col xs={20}>
                <Row>
                  <Col style={{ marginRight: 8 }}>
                    <ChoiceOption choiceType={choiceType} />
                  </Col>
                  <OtherPleaseSpecify />
                </Row>
              </Col>
              <Col xs={4}>
                <DeleteButton onClick={() => setHasOther(false)} style={{ marginLeft: 4 }} />
              </Col>
            </Row>
          )}

          {/* Add Option or Add Other */}
          <Row align="middle">
            <Col style={{ marginRight: 8 }}>
              <ChoiceOption choiceType={choiceType} />
            </Col>
            <Col>
              <StyledInputWrapper>
                <Typography.Text style={{ color: colors.disabled }}>{t('questionCreateAddOption')}</Typography.Text>
                <Item noStyle>
                  <StyledNoFocusInput
                    onClick={() => {
                      if (!canFocusRef.current) {
                        canFocusRef.current = true;
                      }
                      add();
                    }}
                    readOnly
                  />
                </Item>
              </StyledInputWrapper>
            </Col>

            {!hasOther && (
              <Trans i18nKey="questionCreateOrAddOther">
                <Typography.Paragraph style={{ margin: '0 0 0 6px' }}>
                  or
                  <Typography.Text
                    style={{ color: colors.functionalLink, cursor: 'pointer' }}
                    onClick={() => setHasOther(true)}
                  >
                    Add Other
                  </Typography.Text>
                </Typography.Paragraph>
              </Trans>
            )}
          </Row>
        </>
      )}
    </List>
  );
};

export default CreateQuestionManageOptions;
