import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Button, Table, message, Typography, ConfigProvider, Empty, Row, Col } from 'antd';
import { debounce } from 'lodash';
import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';
import { ReloadOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import routes from '../../routes';
import formsApi from '../../services/formsApi';
import { DEFAULT_DATE_FORMAT, questionStatuses } from '../../constants';
import { colors } from '../../styles/colors';
import previewIcon from '../../assets/images/preview-outline.svg';
import formUtils from '../../utilities/formUtils';
import StatusTag from './components/StatusTag';

const { Text, Title } = Typography;

const ApplicationForms = ({
  createFormBtnType = null,
  createFormOpensNewTab = true,
  hasHeaderButtons = true,
  onRefresh,
  rowSelection,
  history,
  showStatus = false,
  showCreateButton = true,
}) => {
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  const fetchForms = async (triggerRefreshCallback = false) => {
    try {
      setLoading(true);
      const params = showStatus
        ? { status: `${questionStatuses.ACTIVE},${questionStatuses.INACTIVE}` }
        : { status: questionStatuses.ACTIVE };
      const results = await formsApi.list(params);
      setForms(results);
      if (triggerRefreshCallback && typeof onRefresh === 'function' && results.length > 0) {
        onRefresh();
      }
    } catch (error) {
      message.error(t('commonErrorMessage'));
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchForms();
  }, []);

  let columns = [
    {
      title: <strong>{t('formColumnName')}</strong>,
      dataIndex: 'title',
      key: 'title',
      width: '70%',
      sorter: (a, b) => a.title.localeCompare(b.title),
      visible: true,
    },
    {
      title: <strong>{t('status')}</strong>,
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      sorter: (a, b) => b.status.localeCompare(a.status),
      render: status => <StatusTag status={status} />,
      visible: showStatus,
    },
    {
      title: <strong>{t('formColumnDateCreated')}</strong>,
      dataIndex: 'created_date',
      key: 'created_date',
      width: '15%',
      sorter: (a, b) => b.created_date.localeCompare(a.created_date),
      render: date => moment(date).format(DEFAULT_DATE_FORMAT),
      visible: true,
    },
    {
      title: <strong>{t('preview')}</strong>,
      dataIndex: '',
      key: 'preview',
      width: '15%',
      render: (_, form) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <a
            target="_blank"
            href={routes.formPreview.replace(':id', form.id)}
            style={{ color: colors.functionalLink }}
            rel="noreferrer"
          >
            {t('preview')}
            <img src={previewIcon} alt="preview" style={{ marginLeft: 6, width: 16, height: 16 }} />
          </a>
        </div>
      ),
      visible: true,
    },
  ];

  columns = columns.filter(column => column.visible);

  const handleRefresh = debounce(() => {
    fetchForms(true);
  }, 600);

  const emptyTable = () => (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <>
          <Row justify="center" align="middle">
            <Title type="secondary" level={5}>
              {t('applicationFormsEmptyTitle')}
            </Title>
          </Row>
          <Row justify="center" align="middle">
            <Text type="secondary">{t('applicationFormsEmptyDescription')}</Text>
          </Row>
        </>
      }
    >
      <Row justify="center" align="middle">
        <Col>
          {showCreateButton && (
            <Button
              type={createFormBtnType}
              icon={<PlusOutlined />}
              style={
                createFormBtnType === 'primary'
                  ? { background: colors.functionalSuccess, border: 0 }
                  : { color: colors.primaryText }
              }
              onClick={() =>
                createFormOpensNewTab
                  ? window.open(routes.createForm, '_blank', 'noopener,noreferrer')
                  : history.push(routes.createForm)
              }
            >
              {t('createNew')}
            </Button>
          )}
        </Col>
        <Col>
          <Button
            icon={<ReloadOutlined style={{ color: colors.primaryText }} />}
            style={{ color: colors.primaryText, marginLeft: 16 }}
            onClick={handleRefresh}
            disabled={loading}
          >
            {t('refreshList')}
          </Button>
        </Col>
      </Row>
    </Empty>
  );

  const dataSource = showStatus ? formUtils.sortFormsByStatus(forms) : forms;

  return (
    <>
      {hasHeaderButtons && (
        <Row justify="space-between">
          <Col>
            {showCreateButton && (
              <Button
                type={createFormBtnType}
                icon={<PlusOutlined />}
                style={
                  createFormBtnType === 'primary'
                    ? { background: colors.functionalSuccess, border: 0 }
                    : { color: colors.primaryText }
                }
                onClick={() =>
                  createFormOpensNewTab
                    ? window.open(routes.createForm, '_blank', 'noopener,noreferrer')
                    : history.push(routes.createForm)
                }
              >
                {t('createNew')}
              </Button>
            )}
          </Col>
          <Col>
            <Button
              icon={<ReloadOutlined style={{ color: colors.primaryText }} />}
              style={{ marginBottom: 16, color: colors.primaryText }}
              onClick={handleRefresh}
              disabled={loading}
            >
              {t('refreshList')}
            </Button>
          </Col>
        </Row>
      )}
      <ConfigProvider renderEmpty={emptyTable}>
        <Table
          rowSelection={rowSelection}
          loading={loading}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          rowKey="id"
        />
      </ConfigProvider>
    </>
  );
};

export default withRouter(ApplicationForms);
