import React, { useState } from 'react';
import { Avatar, Button, Col, Divider, Form, Input, Row, Typography } from 'antd';
import { debounce, sortBy, uniqueId } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as chatActions from '../../../redux/chat';
import Header from './Header';
import Footer from './Footer';
import StatusTag from '../../../shared/components/StatusTag';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import chatRoutes from '../routes';

const { Text } = Typography;
const MIN = 5;
const MAX = 30;

const CreateGroupChatForm = ({ t, state, user, isLoading, openChat }) => {
  const { staffRequestId, audience, membersList } = state;
  const sortedMembersList = sortBy(membersList, member => {
    return `${member.partner.first_name} ${member.partner.last_name}`.toLowerCase();
  });
  const totalMembers = membersList?.length;
  const totalRows = totalMembers > 1 ? Math.ceil(totalMembers / 4) : 1;

  const [form] = Form.useForm();
  const [groupChatName, setGroupChatName] = useState('');

  const handleCreateGroupChat = async () => {
    openChat({
      pathname: chatRoutes.createChat,
      state: {
        createChannel: true,
        channelAttributes: {
          staffRequestId,
          audience,
          members: membersList.map(member => member.partner.user.id).join(','),
          name: groupChatName.trim(),
        },
        notificationMessage: `${user?.name} ${t('chatInvite')} ${groupChatName.trim()}`,
      },
    });
  };

  const handleGroupChatName = debounce(name => {
    setGroupChatName(name);
  }, 600);

  return (
    <>
      <Header>
        <h3 style={{ marginBottom: 0 }}>
          <Text strong>{t('createGroupChat')}</Text>
        </h3>
      </Header>
      <Form form={form}>
        <Row style={{ padding: '24px' }}>
          <Col span={24}>
            <div style={{ marginBottom: '16px' }}>
              <Text strong>{t('setGroupChatName')}</Text>
            </div>
            <Form.Item
              name="groupChatName"
              rules={[
                {
                  required: true,
                  message: t('fieldRequired'),
                },
                {
                  max: MAX,
                  min: MIN,
                  message: t('fieldLengthInvalid', { min: MIN }),
                },
              ]}
              style={{
                marginBottom: 0,
              }}
            >
              <Input
                className="group-chat-name"
                allowClear
                maxLength={MAX}
                placeholder={t('groupChatNamePlaceholder')}
                onChange={e => handleGroupChatName(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider style={{ margin: '0' }} />
        {isLoading ? (
          <LoadingSpinner width="50px" />
        ) : (
          totalMembers && (
            <>
              <Row style={{ padding: '24px 24px 0' }}>
                <Col span={24}>
                  <div style={{ marginBottom: '16px' }}>
                    <Text strong>{t('addingMembers', { members: totalMembers })}</Text>
                  </div>
                </Col>
              </Row>
              {[...Array(totalRows).keys()].map(index => {
                const startIndex = index * 4;
                let rowMembers = sortedMembersList.slice(startIndex, startIndex + 4);
                if (rowMembers.length < 4) {
                  // Pad the list
                  rowMembers = rowMembers.concat(Array(4 - rowMembers.length).fill(undefined));
                }

                return (
                  <Row justify="space-around" align="middle" style={{ padding: '0 24px 12px' }} key={index}>
                    {rowMembers.map(member => (
                      <Col className="chat-avatar" span={6} key={uniqueId()}>
                        {member && (
                          <>
                            <Avatar shape="circle" size={60} src={member.partner.image} className="v2-avatar-wrapper" />
                            <Text className="name" ellipsis={{ tooltip: true }}>
                              {`${member.partner.first_name} ${member.partner.last_name}`}
                            </Text>
                            {member.status && (
                              <StatusTag
                                status={member.status}
                                interview={member.interviewed}
                                shortlisted={member.shortlisted}
                              />
                            )}
                          </>
                        )}
                      </Col>
                    ))}
                  </Row>
                );
              })}
            </>
          )
        )}
        <Footer>
          <Form.Item style={{ marginBottom: 0, padding: '10px' }}>
            <Button
              block
              className="ant-btn-v2-primary"
              disabled={groupChatName.length < 5 || groupChatName.length > 30}
              onClick={handleCreateGroupChat}
            >
              {t('createGroupChat')}
            </Button>
          </Form.Item>
        </Footer>
      </Form>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  state: state.chat.state,
  isLoading: state.chat.isLoading,
});

const mapDispatchToProps = dispatch => {
  const { openChat } = chatActions;
  return {
    openChat: (route, state) => {
      dispatch(openChat(route, state, true));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateGroupChatForm));
