import React from 'react';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { Alert, Button, Checkbox, Col, ConfigProvider, Divider, List, message, Row, Select, Typography } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { uniqBy, capitalize, findIndex, isEqual } from 'lodash';
import fetchAll from '../../utilities/apiUtils';

import * as chatActions from '../../redux/chat';
import {
  audiences,
  employmentStatuses,
  genderOptions,
  acceptedStatusOptions,
  workerCovidCertificationStatuses,
  staffRequestTabs,
  countryCodeOptions,
} from '../../constants';
import workerUtils from '../../utilities/workerUtils';
import employmentApi from '../../services/employmentApi';
import ratingsApi from '../../services/ratingsApi';
import staffRequestApi from '../../services/staffRequestApi';
import partnerApi from '../../services/partnerApi';
import getPaginationConfig from '../../utilities/getPaginationConfig';

import NoApplicantsIcon from '../../assets/images/no_applicants_icon.svg';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import FunnelEmptySearch from '../../shared/components/FunnelEmptySearch';
import EmptyCard from '../../shared/components/EmptyCard';
import CheckboxFilter from '../../shared/components/CheckboxFilter';
import TreeCheckboxFilter from '../../shared/components/TreeCheckboxFilter';
import AgePopoverFilter from '../../shared/components/AgePopoverFilter';
import DistancePopoverFilter from '../../shared/components/DistancePopoverFilter';
import HiringBulkActions from '../../pages/StaffRequestsPage/components/HiringBulkActions';
import EndEmploymentModal from '../../pages/WorkforcePage/components/EndEmploymentModal';
import WorkerTypeFilter from '../../pages/StaffRequestsPage/components/WorkerTypeFilter';
import MoreFilters from '../../pages/StaffRequestsPage/components/MoreFilters';

import EmploymentListItem from './components/EmploymentListItem';
import chatRoutes from '../Chat/routes';
import { colors } from '../../styles/colors';
import { channelTypes } from '../Chat/constants';

const { Text } = Typography;
const { Option } = Select;
const acceptedStatus = `${Object.values(employmentStatuses).join(',')}`;

class StaffRequestEmploymentsList extends React.Component {
  state = {
    isLoading: false,
    searchParams: {
      page: 1,
      status: 'active,',
      ordering: '-created_date',
      staff_request: this.props.staffRequestId,
      include_counts: true,
      page_size: 20,
    },
    numActive: 0,
    numCancelled: 0,
    numEnded: 0,
    numRecords: 0,
    employments: undefined,
    selectedApplicationsList: [],
    indeterminate: false,
    checkAll: false,
    selectAllEmployments: false,
    employmentCounts: {},
    appliedStatusFilter: [],
    appliedSearchParams: {},
    searchApplied: false,
    showEndEmploymentModal: false,
    selectedEmploymentIndex: undefined,
    appliedStatusFilters: 'active,',
    showSearchedList: false,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { searchParams } = this.state;
    const { selectedWorker, tabKey } = this.props;
    try {
      const [responseWithCount, response] = await Promise.all([
        employmentApi.list({ staff_request: this.props.staffRequestId }),
        employmentApi.list(searchParams),
      ]);
      if (responseWithCount && response) {
        const { status_counts } = responseWithCount;
        // TODO: handle workmate experience in backend
        const appendedEmployments = await this.appendStatisticsAndExperienceToEmployments(response.results);
        const employmentCounts = {};

        if (typeof response.status_counts !== 'undefined') {
          Object.entries(response.status_counts).forEach(([status, count]) => {
            employmentCounts[status] = count;
          });
        }

        let selectedWorkerTab = '';
        if (selectedWorker && selectedWorker.length > 0) {
          selectedWorkerTab = await workerUtils.getApplicationTab(selectedWorker[0]);
        }

        const statusFilter = this.getStatusFilter();
        const appliedStatusFilter = statusFilter.selectedOptions;

        this.setState({
          numActive: status_counts.active,
          numCancelled: status_counts.cancelled,
          numEnded: status_counts.ended,
          employments: appendedEmployments,
          numRecords: response.count,
          employmentCounts,
          appliedStatusFilter,
          appliedSearchParams: searchParams,
          showSearchedList: selectedWorker && selectedWorker.length > 0 && isEqual(selectedWorkerTab, tabKey),
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps) {
    // if tab is changed and search is applied in current tab, reset the filters
    if (
      this.props.currentActiveTabKey !== prevProps.currentActiveTabKey &&
      this.props.currentActiveTabKey !== this.props.tabKey &&
      this.state.searchApplied
    ) {
      // eslint-disable-next-line no-unused-vars, react/no-did-update-set-state
      this.setState(prevState => {
        const newSearchParams = {
          page: 1,
          status: acceptedStatus,
          ordering: prevState.searchParams.ordering,
          staff_request: this.props.staffRequestId,
          include_counts: true,
          page_size: 20,
        };

        return { searchParams: newSearchParams, searchApplied: false, appliedStatusFilters: undefined };
      }, this.fetchEmploymentsWithParams);
    }

    if (
      this.props.currentActiveTabKey === this.props.tabKey &&
      !isEqual(this.props.selectedWorker[0]?.id, prevProps.selectedWorker[0]?.id)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showSearchedList: true });
    } else if (
      this.props.selectedWorker.length > 0 &&
      !isEqual(this.props.currentActiveTabKey, this.props.tabKey) &&
      !isEqual(this.props.selectedWorker[0]?.id, prevProps.selectedWorker[0]?.id)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showSearchedList: false });
    }
  }

  getPartnerStatistics = async partnerIds => {
    return (await partnerApi.getStatistics({ partner: partnerIds })).results;
  };

  getWorkmateExperiences = async partnerIds => {
    const partnersEndedEmployments = fetchAll(employmentApi.getVerifiedExperience, {
      partner: partnerIds,
      status: employmentStatuses.ENDED,
      ordering: '-end_date',
    });

    return partnersEndedEmployments;
  };

  appendStatisticsAndExperienceToEmployments = async employments => {
    if (employments.length < 1) {
      return employments;
    }

    const partnerIds = employments.map(({ partner }) => partner.id).join();
    const partnerStatistics = await this.getPartnerStatistics(partnerIds);
    const workmateExperiences = await this.getWorkmateExperiences(partnerIds);

    return employments.map(employment => ({
      ...employment,
      partnerStatistics: { ...partnerStatistics.find(stat => stat.partner === employment.partner.id) },
      workmateExperiences: [
        ...workmateExperiences.filter(experience => experience.partner.id === employment.partner.id),
      ],
    }));
  };

  handleAllFilterSelectChange = (field, checkedValues, allOptions) => {
    const allOptionsSelected = checkedValues.length === allOptions.length;
    const { searchParams } = this.state;
    const nextSearchParams = searchParams;
    // De-select all
    if (allOptionsSelected) {
      nextSearchParams[field] = undefined;
    } else {
      // Select all available options
      nextSearchParams[field] = allOptions.map(({ id }) => id).join(',');
    }
    this.setState({ searchParams: nextSearchParams });
  };

  handleSearchParamChange = (field, searchValue) => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams[field] = searchValue;
        nextSearchParams.page = 1;
        return { searchParams: nextSearchParams, searchApplied: !!searchValue };
      },
      () => this.fetchEmploymentsWithParams(),
    );
  };

  onPageChange = (pageNum, pageSize) => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams.page = pageNum;
        nextSearchParams.page_size = pageSize;
        return { searchParams: nextSearchParams };
      },
      () => this.fetchEmploymentsWithParams(),
    );
  };

  getStatusCounts = t => {
    return [
      {
        title: t('Hired'),
        num: this.state.numActive,
        hasBadge: false,
        onClick: () => {
          this.handleSearchParamChange('status', employmentStatuses.ACTIVE);
        },
      },
      {
        title: t('Cancelled'),
        num: this.state.numCancelled,
        hasBadge: false,
        onClick: () => {
          this.handleSearchParamChange('status', employmentStatuses.CANCELLED);
        },
      },
      {
        title: t('Ended'),
        num: this.state.numEnded,
        hasBadge: false,
        onClick: () => {
          this.handleSearchParamChange('status', employmentStatuses.ENDED);
        },
      },
    ];
  };

  getStatusFilter = () => {
    const { t } = this.props;
    const employmentStatusOptions = [
      { id: employmentStatuses.ACTIVE, name: 'Hired' },
      { id: employmentStatuses.CANCELLED, name: 'Cancelled' },
      { id: employmentStatuses.ENDED, name: 'Ended' },
    ];
    const { searchParams } = this.state;
    const { status } = searchParams;
    const statusCheckedList = status ? status.split(',') : [];
    return {
      title: t('status'),
      selectAllTitle: t('allStatus'),
      selectedOptions: statusCheckedList,
      options: employmentStatusOptions,
      FilterComponent: CheckboxFilter,
      onChange: values => {
        const joinedValues = values.join(',');
        this.setState(prevState => {
          const nextSearchParams = prevState.searchParams;
          nextSearchParams.status = joinedValues;
          return { searchParams: nextSearchParams };
        });
      },
      onCheckAllChange: () => this.handleAllFilterSelectChange('status', statusCheckedList, employmentStatusOptions),
      onApply: () => {
        this.setState(
          prevState => {
            const nextSearchParams = prevState.searchParams;
            nextSearchParams.page = 1;
            return { searchParams: nextSearchParams };
          },
          () => this.fetchEmploymentsWithParams(),
        );
      },
    };
  };

  onExportContract = async (workerId, workerName) => {
    await staffRequestApi.exportContractForWorker(this.props.staffRequestId, workerId, workerName);
  };

  // TODO: Deprecate this and cancel with reason: "no show"
  onReportNoShow = async (employmentId, workerName) => {
    const { t } = this.props;
    const response = await employmentApi.markNoShow(employmentId);
    if (response) {
      this.setState(prevState => {
        this.props.updateParentStatusCounts([
          { status: employmentStatuses.ACTIVE, increment: false },
          { status: employmentStatuses.CANCELLED, increment: true },
        ]);
        const nextEmployments = this.updateLocalEmploymentData(
          prevState.employments,
          employmentId,
          'status',
          employmentStatuses.CANCELLED,
        );
        return { numHired: prevState.numHired - 1, employments: nextEmployments };
      });
      message.success(`${t('Successfully reported no-show')} ${workerName}`);
    }
  };

  onCancel = async data => {
    const { employments, selectedEmploymentIndex } = this.state;
    const { t, selectedWorker, updateParentSelectedWorker } = this.props;

    let employmentList = employments;
    if (selectedWorker && selectedWorker.length > 0) {
      employmentList = selectedWorker;
    }

    const employmentId = employmentList[selectedEmploymentIndex]?.id;
    const workerName = `${employmentList[selectedEmploymentIndex]?.partner.first_name} ${employmentList[selectedEmploymentIndex]?.partner.last_name}`;

    const response = await employmentApi.cancel(employmentId, data.reason, data.notes);

    if (response) {
      this.setState(
        prevState => {
          const newEmployments = prevState.employments;
          const matchedEmploymentIndex = findIndex(newEmployments, e => {
            return e.id === response.id;
          });

          if (matchedEmploymentIndex >= 0) {
            newEmployments[matchedEmploymentIndex] = response;
          }

          return { employments: newEmployments, showEndEmploymentModal: false };
        },
        () => {
          if (selectedWorker && selectedWorker.length > 0) {
            const updatedSelectedWorker = this.state.employments.find(
              application => application.partner.id === selectedWorker[0].partner.id,
            );
            updateParentSelectedWorker(updatedSelectedWorker);
          }
        },
      );
      message.success(`${t('Successfully cancelled')} ${workerName}`);
    }
  };

  fetchEmploymentsWithParams = async () => {
    this.setState({ isLoading: true });

    this.resetCheckboxes();

    const { searchParams } = this.state;
    try {
      const response = await employmentApi.list({
        staff_request: this.props.staffRequestId,
        ...searchParams,
      });
      const appendedEmployments = await this.appendStatisticsAndExperienceToEmployments(response.results);
      const employmentCounts = {};

      if (typeof response.status_counts !== 'undefined') {
        Object.entries(response.status_counts).forEach(([status, count]) => {
          employmentCounts[status] = count;
        });
      }

      const statusFilter = this.getStatusFilter();
      const appliedStatusFilter = statusFilter.selectedOptions;

      this.setState({
        employments: appendedEmployments,
        numRecords: response.count,
        employmentCounts,
        appliedStatusFilter,
        appliedSearchParams: searchParams,
      });
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  getEmptyCard = () => {
    const { isLoading, searchApplied } = this.state;
    const { t } = this.props;
    if (isLoading) return <></>;

    if (searchApplied) {
      return <FunnelEmptySearch clearFilters={this.handleClearFilters} />;
    }

    return (
      <EmptyCard title={t('noActiveWorkers')} description={t('activeWorkersWillShowUp')} imgSrc={NoApplicantsIcon} />
    );
  };

  onWorkerRatingGiven = async (workerId, employmentId, score) => {
    const ratingResponse = await ratingsApi.createRating({ partnerId: workerId, employmentId, score });
    const { employments } = this.state;
    if (ratingResponse) {
      this.updateLocalEmploymentData(employments, employmentId, 'rating', ratingResponse);
    }
  };

  // Takes a list of workers and update the worker status given a ID.
  // This is used to avoid re-fetching the entire table, but to update the worker table in our state.
  updateLocalEmploymentData = (employments, employmentId, field, nextVal) => {
    for (let i = 0; i < employments.length; i += 1) {
      if (employments[i].id === employmentId) {
        // eslint-disable-next-line no-param-reassign
        employments[i][field] = nextVal;
        break;
      }
    }
    return employments;
  };

  handleNewGroupChat = async () => {
    const { appliedSearchParams, selectedApplicationsList, selectAllEmployments } = this.state;
    const { staffRequestId, createGroupChat, preloadChat } = this.props;
    let membersList = [...selectedApplicationsList];

    preloadChat();

    try {
      if (selectAllEmployments) {
        const response = await employmentApi.employmentsList({
          staff_request: staffRequestId,
          ...appliedSearchParams,
        });

        membersList = uniqBy(
          response.map(employment => ({ ...employment, status: employment.status })),
          'partner.user.id',
        );
      }

      createGroupChat({
        staffRequestId,
        audience: audiences.SELECTED_APPLICANTS,
        membersList,
      });
      preloadChat(false);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  handleCheck = e => {
    const { employments, selectedApplicationsList } = this.state;

    let newSelectedApplicationsList = [];
    if (e.target.checked) {
      const index = selectedApplicationsList.findIndex(item => item.partner.id === e.target.value.partner.id);
      if (index === -1) {
        newSelectedApplicationsList = [...selectedApplicationsList, e.target.value];
      }
    } else {
      newSelectedApplicationsList = selectedApplicationsList.filter(
        item => item.partner.id !== e.target.value.partner.id,
      );
    }

    this.setState({
      selectedApplicationsList: newSelectedApplicationsList,
      indeterminate: !!newSelectedApplicationsList.length && newSelectedApplicationsList.length < employments.length,
      checkAll: employments.length === newSelectedApplicationsList.length,
      selectAllEmployments: false,
    });
  };

  handleCheckAllChange = e => {
    const { employments } = this.state;
    const { selectedWorker } = this.props;
    const employmentList = selectedWorker && selectedWorker.length > 0 ? selectedWorker : employments;

    this.setState({
      selectedApplicationsList: e.target.checked
        ? uniqBy(
            employmentList.map(employment => ({ ...employment, status: employment.status })),
            'partner.user.id',
          )
        : [],
      indeterminate: false,
      checkAll: e.target.checked,
      selectAllEmployments: false,
    });
  };

  handleSelectAllEmployments = selectAllEmployments => {
    let clearSelection = {};
    if (!selectAllEmployments) {
      clearSelection = {
        selectedApplicationsList: [],
        indeterminate: false,
        checkAll: false,
      };
    }

    this.setState({
      selectAllEmployments,
      ...clearSelection,
    });
  };

  resetCheckboxes = () => {
    this.setState({
      selectedApplicationsList: [],
      indeterminate: false,
      checkAll: false,
      selectAllEmployments: false,
    });
  };

  getEmploymentCounts = statuses => {
    let totalEmploymentCount = 0;

    const { employmentCounts } = this.state;
    Object.entries(employmentCounts).forEach(([status, count]) => {
      if (statuses.length < 1 || statuses.includes(status)) {
        totalEmploymentCount += count;
      }
    });

    return totalEmploymentCount;
  };

  handleApplyStatusFilter = checkedKeys => {
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      nextSearchParams.status = checkedKeys.length > 0 ? checkedKeys.join(',') : acceptedStatus;
      nextSearchParams.page = 1;
      return {
        searchParams: nextSearchParams,
        searchApplied: checkedKeys.length > 0,
        appliedStatusFilters: checkedKeys?.join(','),
      };
    }, this.fetchEmploymentsWithParams);
  };

  handleApplyGenderFilter = checkedKeys => {
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      nextSearchParams.gender = checkedKeys.join(',');
      nextSearchParams.page = 1;
      return { searchParams: nextSearchParams, searchApplied: checkedKeys.length > 0 };
    }, this.fetchEmploymentsWithParams);
  };

  handleApplyDistanceFilter = distance => {
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      if (distance > 0) {
        nextSearchParams.distance = distance;
      } else {
        delete nextSearchParams.distance;
      }
      nextSearchParams.page = 1;
      return { searchParams: nextSearchParams, searchApplied: distance > 0 };
    }, this.fetchEmploymentsWithParams);
  };

  handleApplyAgeRangeFilter = selectedAgeRange => {
    const [minimum, maximum] = selectedAgeRange;
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      if (minimum) {
        const date_of_birth_before = moment()
          .endOf('year')
          .subtract(minimum, 'years')
          .toISOString();
        nextSearchParams.date_of_birth_before = date_of_birth_before;
        nextSearchParams.min_age = minimum;
      } else {
        delete nextSearchParams.date_of_birth_before;
        delete nextSearchParams.min_age;
      }

      if (maximum) {
        const date_of_birth_after = moment()
          .startOf('year')
          .subtract(maximum, 'years')
          .toISOString();
        nextSearchParams.date_of_birth_after = date_of_birth_after;
        nextSearchParams.max_age = maximum;
      } else {
        delete nextSearchParams.date_of_birth_after;
        delete nextSearchParams.max_age;
      }
      nextSearchParams.page = 1;
      return { searchParams: nextSearchParams, searchApplied: minimum && maximum };
    }, this.fetchEmploymentsWithParams);
  };

  handleApplyCovidCertificationFilter = checkedKeys => {
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      nextSearchParams.covid_certification_status = checkedKeys.join(',');
      nextSearchParams.page = 1;
      return { searchParams: nextSearchParams, searchApplied: checkedKeys.length > 0 };
    }, this.fetchEmploymentsWithParams);
  };

  handleApplyFilters = params => {
    this.setState(() => params, this.fetchEmploymentsWithParams);
  };

  handleStartChat = async partner => {
    const { openChat, staffRequest } = this.props;
    openChat({
      pathname: chatRoutes.createChat,
      state: {
        createChannel: true,
        channelAttributes: {
          staffRequestId: staffRequest.id,
          audience: audiences.SELECTED_APPLICANTS,
          members: partner.user.id,
          name: `${staffRequest.client?.name} - ${staffRequest.title}`,
          type: channelTypes.PRIVATE,
        },
      },
    });
  };

  handleEndEmploymentModal = index => {
    this.setState(prevState => {
      const modalState = prevState.showEndEmploymentModal;
      return { showEndEmploymentModal: !modalState, selectedEmploymentIndex: index };
    });
  };

  onBulkActionSuccess = selectedIds => {
    const { selectedWorker } = this.props;
    this.setState(prevState => {
      const newEmployments = selectedWorker && selectedWorker.length > 0 ? selectedWorker : prevState.employments;

      selectedIds.forEach(selectedId => {
        const employmentIndex = newEmployments.findIndex(employment => employment.id === selectedId);
        newEmployments[employmentIndex].status = employmentStatuses.CANCELLED;
      });

      return { employments: newEmployments };
    }, this.resetCheckboxes);
  };

  render() {
    const {
      searchParams,
      numRecords,
      isLoading,
      employments,
      selectedApplicationsList,
      indeterminate,
      checkAll,
      selectAllEmployments,
      appliedStatusFilter,
      showEndEmploymentModal,
      selectedEmploymentIndex,
      appliedStatusFilters,
      showSearchedList,
    } = this.state;
    const { client, t, user, selectedWorker, staffRequest } = this.props;

    let totalCounts = this.getEmploymentCounts(appliedStatusFilter);
    if (selectedWorker && selectedWorker.length > 0) {
      totalCounts = selectedWorker.length;
    }
    const totalSelected = selectAllEmployments ? totalCounts : selectedApplicationsList.length;

    const covidCertificationStatusOptions = [
      { key: workerCovidCertificationStatuses.VERIFIED, title: t('vaccinated') },
      { key: workerCovidCertificationStatuses.ALL_UNVERIFIED_STATUSES, title: t('notVerifiedYet') },
    ];

    let employmentList = employments || [];
    let numRecordList = numRecords || 0;
    if (selectedWorker.length > 0) {
      if (showSearchedList && this.props.currentActiveTabKey === this.props.tabKey) {
        employmentList = selectedWorker;
        numRecordList = 1;
      } else {
        employmentList = [];
        numRecordList = 0;
      }
    }

    return (
      <>
        {/* Filters & Search */}
        <Row align="top" justify="space-between" style={{ marginBottom: '16px' }}>
          <Col>
            <Row>
              <Col flex>
                <Typography.Text level={3} style={{ marginRight: 12 }}>
                  {t('filters')}
                </Typography.Text>
                <TreeCheckboxFilter
                  treeData={Object.values(acceptedStatusOptions).map(value => ({
                    ...value,
                    title: capitalize(t(value.title)),
                  }))}
                  checkedKeys={appliedStatusFilters?.split(',')}
                  placeholder={t('filterByStatusPlaceholder')}
                  label={t('Status')}
                  showActionButtons
                  showSearch
                  onApply={this.handleApplyStatusFilter}
                  expandable={false}
                  isDisabled={selectedWorker && selectedWorker.length > 0}
                />
                <DistancePopoverFilter
                  label={t('Distance')}
                  selectedDistance={searchParams?.distance}
                  onApply={this.handleApplyDistanceFilter}
                  isDisabled={selectedWorker && selectedWorker.length > 0}
                />
                {user?.country?.code !== countryCodeOptions.SINGAPORE && (
                  <>
                    <AgePopoverFilter
                      label={t('Age')}
                      selectedAgeRange={[searchParams?.min_age, searchParams?.max_age]}
                      onApply={this.handleApplyAgeRangeFilter}
                      isDisabled={selectedWorker && selectedWorker.length > 0}
                    />
                    <TreeCheckboxFilter
                      treeData={Object.values(genderOptions).map(value => ({
                        ...value,
                        title: capitalize(t(value.key)),
                      }))}
                      checkedKeys={searchParams?.gender?.split(',')}
                      placeholder={t('filterByGenderPlaceholder')}
                      label={t('Gender')}
                      showActionButtons
                      showSearch={false}
                      onApply={this.handleApplyGenderFilter}
                      expandable={false}
                      isDisabled={selectedWorker && selectedWorker.length > 0}
                    />
                  </>
                )}
                <TreeCheckboxFilter
                  treeData={covidCertificationStatusOptions}
                  // TODO: add handling between URL params and state
                  checkedKeys={searchParams?.covid_certification_status?.split(',')}
                  placeholder={t('filterByVaccinatedPlaceholder')}
                  label={t('vaccinated')}
                  showActionButtons
                  showSearch={false}
                  onApply={this.handleApplyCovidCertificationFilter}
                  expandable={false}
                  isDisabled={selectedWorker && selectedWorker.length > 0}
                />
                <WorkerTypeFilter
                  selectedWorker={selectedWorker}
                  searchParams={searchParams}
                  onApplyWorkerTypeFilter={this.handleApplyFilters}
                />
                <MoreFilters
                  searchParams={searchParams}
                  onApplyMoreFilters={this.handleApplyFilters}
                  selectedWorker={selectedWorker}
                />
                <Text type="secondary">{t('applicationResults', { numRecords: numRecordList })}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider style={{ marginBottom: '16px', marginTop: 0 }} />
        {/* Sorting and recommendation */}
        <Row style={{ marginBottom: checkAll ? '16px' : '0' }}>
          {user?.chatEnabled && (
            <>
              <Col>
                <Checkbox
                  className="chat-checkbox"
                  indeterminate={indeterminate}
                  onChange={this.handleCheckAllChange}
                  checked={checkAll}
                  disabled={!employments || employments.length < 1}
                  style={{
                    borderRight: totalSelected ? `1px solid ${colors.greyBorder}` : '0 none',
                    marginRight: totalSelected ? '16px' : '0',
                    padding: '4px 6px 4px 0',
                  }}
                >
                  {totalSelected ? t('numSelected', { num: totalSelected }) : ''}
                </Checkbox>
                <Button onClick={this.handleNewGroupChat} disabled={selectedApplicationsList.length < 1}>
                  <TeamOutlined /> {t('newGroupChat')}
                </Button>
              </Col>
              <Col>
                <HiringBulkActions
                  tab={staffRequestTabs.EMPLOYEES}
                  disabled={selectedApplicationsList.length < 1}
                  selectedIds={selectedApplicationsList.map(item => item.id)}
                  disableHire
                  disableShortlist
                  disableInterview
                  acceptedTab
                  onBulkActionSuccess={this.onBulkActionSuccess}
                  staffRequest={staffRequest}
                />
              </Col>
            </>
          )}
          <Col style={{ marginLeft: 'auto' }}>
            <Text type="secondary">{t('sortBy')}</Text>
            <Select
              defaultValue="-created_date"
              style={{ marginLeft: '8px', width: '156px' }}
              onChange={value => this.handleSearchParamChange('ordering', value)}
            >
              <Option value="created_date">{t('firstAccepted')}</Option>
              <Option value="-created_date">{t('recentlyAccepted')}</Option>
            </Select>
          </Col>
        </Row>
        {user?.chatEnabled && checkAll && (
          <Row>
            <Col span={24}>
              <Row style={{ marginBottom: '16px' }}>
                <Col span={24}>
                  <Alert
                    message={
                      <>
                        {selectAllEmployments ? (
                          <>
                            <Trans
                              i18nKey="allEmploymentsSelected"
                              values={{ workers: totalCounts }}
                              components={{ strong: <strong /> }}
                            />
                            {selectedApplicationsList.length < totalCounts && (
                              <Text
                                strong
                                style={{ cursor: 'pointer', color: '#1890FF', marginLeft: '5px' }}
                                onClick={() => this.handleSelectAllEmployments(false)}
                              >
                                {t('clearSelection')}
                              </Text>
                            )}
                          </>
                        ) : (
                          <>
                            <Trans
                              i18nKey="allEmploymentsSelectedOnPage"
                              values={{ workers: totalSelected }}
                              components={{ strong: <strong /> }}
                            />
                            {selectedApplicationsList.length < totalCounts && (
                              <Text
                                strong
                                style={{ cursor: 'pointer', color: '#1890FF', marginLeft: '5px' }}
                                onClick={() => this.handleSelectAllEmployments(true)}
                              >
                                {t('selectAllWorkers', { workers: totalCounts })}
                              </Text>
                            )}
                          </>
                        )}
                      </>
                    }
                    type="info"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {/* Actual Worker list */}
        <ConfigProvider renderEmpty={this.getEmptyCard}>
          <List
            itemLayout="vertical"
            pagination={{
              ...getPaginationConfig(numRecordList, this.onPageChange),
              current: searchParams.page || 1,
              // overwriting pagination config
              pageSize: searchParams.page_size,
              pageSizeOptions: [20, 40, 60, 100],
              showSizeChanger: true,
              showQuickJumper: false,
            }}
            loading={isLoading && { indicator: <LoadingSpinner width="50px" /> }}
            dataSource={employmentList || []}
            style={{ marginBottom: '24px' }}
            renderItem={(employment, index) => {
              const checked =
                selectedApplicationsList.findIndex(member => member?.partner?.id === employment.partner.id) !== -1;
              return (
                <EmploymentListItem
                  key={index}
                  client={client}
                  employment={employment}
                  showCheckbox={user?.chatEnabled}
                  checked={checked}
                  onCheck={this.handleCheck}
                  onExportContract={this.onExportContract}
                  onReportNoShow={this.onReportNoShow}
                  onEndEmployment={this.handleEndEmploymentModal}
                  onWorkerRatingGiven={this.onWorkerRatingGiven}
                  onChat={this.handleStartChat}
                  index={index}
                  user={user}
                  positionId={staffRequest.position?.id}
                />
              );
            }}
          />
        </ConfigProvider>
        <EndEmploymentModal
          visible={showEndEmploymentModal}
          selectedSingleWorker={selectedEmploymentIndex >= 0 ? employments[selectedEmploymentIndex] : null}
          confirmLoading={isLoading}
          closeModal={() => this.handleEndEmploymentModal()}
          hasDirectEmployment={false}
          onConfirm={this.onCancel}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  const { openChat, preload } = chatActions;
  return {
    openChat: (route, state) => {
      dispatch(openChat(route, state, true));
    },
    createGroupChat: state => {
      dispatch(openChat(chatRoutes.createGroupChat, state, true));
    },
    preloadChat: (isLoading = true) => {
      dispatch(preload(isLoading));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffRequestEmploymentsList);
