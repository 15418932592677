import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as userActions from '../../redux/user';
import * as globalActions from '../../redux/global';
import routes from '../../routes';
import RouteWithProps from '../../shared/components/RouteWithProps';

import StaffRequestDetailView from './StaffRequestDetailView';
import StaffRequestPaymentView from './StaffRequestPaymentView';
import PrepaidDetailView from './StaffRequestPrepaidDetailView';
import StaffRequestFormView from './StaffRequestFormView';
import StaffRequestFormListView from './StaffRequestFormsListView';
import StaffRequestFormPreviewView from './StaffRequestFormPreviewView';
import StaffRequestGigFormView from './StaffRequestGigFormView';
import StaffRequestsView from './StaffRequestsView';
import StaffRequestGigDetailView from './StaffRequestGigDetailView';

const StaffRequestsPage = props => {
  const { user, positions, fetchClientAndManager, fetchPositions, t } = props;
  return (
    <Switch>
      <RouteWithProps
        exact
        path={routes.staffRequests}
        extraProps={{ t, user, positions }}
        component={StaffRequestsView}
      />
      <RouteWithProps
        exact
        path={routes.staffRequestForms}
        extraProps={{ t, user }}
        component={StaffRequestFormListView}
      />
      <RouteWithProps
        exact
        path={routes.formPreview}
        extraProps={{ t, user }}
        component={StaffRequestFormPreviewView}
      />
      <RouteWithProps
        exact
        path={[routes.createStaffRequest, routes.editStaffRequest]}
        extraProps={{ t, user, positions, fetchClientAndManager, fetchPositions }}
        component={StaffRequestFormView}
      />
      <RouteWithProps
        exact
        path={routes.createGig}
        extraProps={{ t, user, positions, fetchClientAndManager, fetchPositions }}
        component={StaffRequestGigFormView}
      />
      <RouteWithProps
        exact
        path={routes.billingStaffRequest}
        extraProps={{ t, user, fetchClientAndManager }}
        component={StaffRequestPaymentView}
      />
      <RouteWithProps
        exact
        path={routes.prepaidDetail}
        extraProps={{ t, user, fetchClientAndManager }}
        component={PrepaidDetailView}
      />
      <RouteWithProps
        exact
        path={routes.gigDetail}
        extraProps={{ t, user, fetchClientAndManager }}
        component={StaffRequestGigDetailView}
      />
      <RouteWithProps
        exact
        path={routes.staffRequestDetail}
        extraProps={{ t, user }}
        component={StaffRequestDetailView}
      />
    </Switch>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  positions: state.global.positions,
  potentialMatches: state.global.potentialMatches,
});

const mapDispatchToProps = dispatch => {
  const { fetchClientAndManager } = userActions;
  const { fetchPotentialMatches, fetchPositions } = globalActions;
  return {
    fetchClientAndManager: async () => {
      await dispatch(fetchClientAndManager());
    },
    fetchPotentialMatches: async filters => {
      await dispatch(fetchPotentialMatches(filters));
    },
    fetchPositions: async () => {
      await dispatch(fetchPositions());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StaffRequestsPage));
