import React from 'react';

import WorkerProfileView from './WorkerProfileView';

const WorkerProfilePage = props => {
  const { id } = props.match.params;
  return <WorkerProfileView workerId={id} />;
};

export default WorkerProfilePage;
