import moment from 'moment-timezone';
import { WEEKLY_DATE_RANGE } from '../constants';

const getDayStart = (date, timezone) => {
  return moment(date)
    .tz(timezone)
    .startOf('day');
};

const getIsoWeekStart = (date, timezone) => {
  return moment(date)
    .tz(timezone)
    .startOf('isoWeek');
};

const getIsoMonthStart = (date, timezone) => {
  return moment(date)
    .tz(timezone)
    .startOf('month');
};

const getEndDateFromStartDateAndDateRange = (startDate, dateRange) => {
  const isoDateRange = dateRange === WEEKLY_DATE_RANGE ? 'isoWeek' : 'month';
  return moment(startDate).endOf(isoDateRange);
};

const getEndTimeFromStartTimeAndDuration = (startTime, duration) => {
  return moment(startTime).add(moment.duration(duration));
};

const getDurationFromStartTimeAndEndTime = (startTime, endTime) => {
  const diff = endTime.diff(startTime); // diff is in milliseconds to parse into utc format
  return moment.utc(moment.duration(diff).as('milliseconds'));
};

export default {
  getDayStart,
  getIsoWeekStart,
  getIsoMonthStart,
  getEndTimeFromStartTimeAndDuration,
  getDurationFromStartTimeAndEndTime,
  getEndDateFromStartDateAndDateRange,
};
