import base from './base';
import { endpoints } from '../config';

const list = async (params = {}) => {
  const response = await base.api.get(`${endpoints.forms}/`, { params });
  return response;
};

const fetchFormById = async formId => {
  const response = await base.api.get(`${endpoints.forms}/${formId}/`);
  return response;
};

const createForm = async (payload, clientId) => {
  const config = {
    params: {
      client: clientId,
    },
  };
  const response = await base.api.post(`${endpoints.forms}/`, payload, config);
  return response;
};

const importForm = async formId => {
  const response = await base.api.get(`${endpoints.forms}/import/`, { params: { form_id: formId } });
  return response;
};

export default {
  list,
  fetchFormById,
  createForm,
  importForm,
};
