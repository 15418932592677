import Prismic from 'prismic-javascript';
import { environment, getEnvironment } from './utilities';

export const { Omise, Xendit } = window;
export const bookDemoLink = 'https://business.workmate.asia/bookdemo';
export const HUBSPOT_FORM_SUBMISSION_API_ENDPOINT = 'https://api.hsforms.com/submissions/v3/integration/submit';

// Determine hostname and some other URLs which are different in different environments
let hostname = 'https://api.staging.workmate.asia';
let srDeepLink = 'https://gethelpster.test-app.link/RmeGwDU6T3';
let branchLink = 'https://link-dev.workmate.asia/wtHhNXWllbb';
let hubspotPortalId = 6135582;
let hubspotSelfSignupFormId = '8f0cc677-e6e1-4934-a958-be57a608edc3';
let jobSignContractLink = 'https://link-dev.workmate.asia/2RAqdahdzmb?';

let workmateBusinessAppLink = 'https://r0bfb.test-app.link/XofDOCd0Pzb';
let workmateWorkerAppLink = 'https://link-dev.workmate.asia/WzEWYTRMCrb';
let stripePublishableKey =
  'pk_test_51N3riSBjZRywIKFSZHC8KB9Ci7MopbrcMW9t8KS93NNQtYSjiumM9Gqf2H3ayy2YsuYu4ZNHn9GgQZCP0geYj8dM00iu1n6rlH';

const env = getEnvironment();
if (env === environment.PRODUCTION) {
  hostname = 'https://api.workmate.asia';
  srDeepLink = 'https://link.workmate.asia/e/rxb5twxwMob';
  branchLink = 'https://link.workmate.asia/dw5MuLPrUbb';
  hubspotPortalId = 5230939;
  hubspotSelfSignupFormId = 'ee44fc82-f626-4236-87b8-b1464a04482f';
  jobSignContractLink = 'https://link.workmate.asia/KjP2Yrm2mpb?';
  workmateBusinessAppLink = 'https://r0bfb.app.link/hT8cgpIhjfb';
  workmateWorkerAppLink = 'https://link.workmate.asia/lbp3ZSd1cvb';
  stripePublishableKey =
    'pk_live_51N3riSBjZRywIKFSSyUAb61piA7F2Wv4bGluCgOOpS0enM13HpR5jDvgK8vgUTKTf9smxluYrjWprfgDoURviogx00YlB7wLo4';
} else if (env === environment.DEMO) {
  hostname = 'https://api.demo.helpster.asia';
}

// Local overrides for the backend host
// Add `REACT_APP_API_ROOT=https://localhost:8000` to .env.local file on root folder
export const HOSTNAME = process.env.REACT_APP_API_ROOT || hostname;

export const API_ROOT = `${HOSTNAME}/v1`;
export const READONLY_API_ROOT = `${HOSTNAME}/v1/readonly`;
export const GRAPHQL_ENDPOINT = `${HOSTNAME}/graphql`;
export const STRIPE_ENDPOINT = `${HOSTNAME}/stripe`;
export const cubeJsURL = process.env.REACT_APP_CUBE_JS_URL || `${HOSTNAME}/cubejs-api/v1`;

// Export the configuration items
export const SR_DEEP_LINK = srDeepLink;
export const DIRECT_HIRE_INVITE_BRANCH_LINK = branchLink;
export const HUBSPOT_PORTAL_ID = hubspotPortalId;
export const HUBSPOT_SELF_SIGNUP_FORM_ID = hubspotSelfSignupFormId;
export const JOB_DETAIL_SIGN_CONTRACT_LINK = jobSignContractLink;
export const WORKMATE_BUSINESS_APP_LINK = workmateBusinessAppLink;
export const WORKER_APP_LINK = workmateWorkerAppLink;
export const STRIPE_PUBLISHABLE_KEY = stripePublishableKey;

export const endpoints = {
  staffRequests: `${API_ROOT}/staff-requests`,
  staffRequestContracts: `${API_ROOT}/staff-request-contracts`,
  applications: `${API_ROOT}/applications`,
  employments: `${API_ROOT}/employments`,
  offers: `${API_ROOT}/offers`,
  partners: `${API_ROOT}/partners`,
  clients: `${API_ROOT}/clients`,
  managers: `${API_ROOT}/managers`,
  positions: `${API_ROOT}/positions`,
  countries: `${API_ROOT}/countries`,
  banks: `${API_ROOT}/banks`,
  paymentReports: `${API_ROOT}/payment-reports`,
  paymentItems: `${API_ROOT}/payment-items`,
  timesheetEntries: `${API_ROOT}/timesheet-entries`,
  leaveEntries: `${API_ROOT}/leave-entries`,
  ratings: `${API_ROOT}/ratings`,
  billingReports: `${API_ROOT}/billing-reports`,
  billingItems: `${API_ROOT}/billing-items`,
  shifts: `${API_ROOT}/shifts`,
  schedules: `${API_ROOT}/schedules`,
  attendances: `${API_ROOT}/attendances`,
  user: `${API_ROOT}/user`,
  channels: `${API_ROOT}/channels`,
  forms: `${API_ROOT}/recruitment/forms`,
  questions: `${API_ROOT}/questions`,
  staffRequestTemplates: `${API_ROOT}/staff-request-templates`,
  absenceDetails: `${API_ROOT}/absence-details`,
  shiftApplications: `${API_ROOT}/shift-applications`,
};

export const readonlyEndpoints = {
  paymentReports: `${READONLY_API_ROOT}/payment-reports`,
  paymentItems: `${READONLY_API_ROOT}/payment-items`,
  timesheetEntries: `${READONLY_API_ROOT}/timesheet-entries`,
  staffRequests: `${READONLY_API_ROOT}/staff-requests`,
  applications: `${READONLY_API_ROOT}/applications`,
  partners: `${READONLY_API_ROOT}/partners`,
  employments: `${READONLY_API_ROOT}/employments`,
};

// Google Location API
export const GOOGLE_MAPS_API_KEY = 'AIzaSyAa4T5AYGrw3TkMSh0DF0OhUj5YQm3pxfw';

// Set up Omise and Xendit
if (env === environment.PRODUCTION) {
  Omise.setPublicKey('pkey_5i91avqopyqx1u977yk');
  Xendit.setPublishableKey('xnd_public_production_Po6GfOQh3LeukpVrfLRPEjTEZtWnqNEuw3Xg+R1r/2fU/7emCwVzgg==');
} else if (env === environment.STAGING || env === environment.DEMO || process.env.REACT_APP_API_ROOT) {
  Omise.setPublicKey('pkey_test_5h2dvuwldrh16rgezxc');
  Xendit.setPublishableKey('xnd_public_development_NYqHfOQh3LeukpVrfLRPEjTEZtWnqNEuw3Xg+R1r/2fU/7emCwVzgg==');
}

// Prismic CMS config.
const prismicEndpoint = 'https://workmate-support.cdn.prismic.io/api/v2';
const accessToken = '';
export const prismicClient = Prismic.client(prismicEndpoint, { accessToken });

export const PRIVACY_POLICY = 'https://www.workmate.asia/privacy-policy';

export const CUSTOMER_SUPPORT_SG_EMAIL = 'customersupport-sg@workmate.asia';

export const FAQ_SG_FREELANCE_WORK_LINK = 'https://bit.ly/SGFreelanceWork';
