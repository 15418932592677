import { roleAuthorizations } from './accessConfig';
import store from '../../store';

const getUser = () => {
  const storeState = store.getState();
  return storeState.user;
};

export const checkAccess = (action, data) => {
  const user = getUser();
  const authorization = roleAuthorizations[user.role];
  if (!authorization) {
    return false;
  }

  const { staticPermissions } = authorization;
  if (staticPermissions && staticPermissions.includes(action)) {
    return true;
  }

  const dynamicPermissions = authorization.dynamic;
  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

export const getManagerId = () => {
  const user = getUser();
  return user?.managerId;
};
