import React from 'react';
import { Typography, Row, Button } from 'antd';
import { withTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { colors } from '../../../styles/colors';

const { Title } = Typography;

const EndingEmploymentsLink = ({ endingEmploymentsCount, onClick, t }) => {
  return (
    <Row type="flex" style={{ alignItems: 'center' }}>
      <Title level={3} style={{ color: endingEmploymentsCount > 0 && colors.functionalWarning, marginBottom: 0 }}>
        {endingEmploymentsCount}
      </Title>
      {endingEmploymentsCount > 0 && (
        <InfoCircleOutlined style={{ color: colors.functionalWarning, margin: '0px 4px 0px 6px', fontSize: '12px' }} />
      )}
      <Button onClick={onClick} size="small" style={{ margin: '0px 0px 0px 8px' }}>
        {t('viewAll')}
      </Button>
    </Row>
  );
};

export default withTranslation()(EndingEmploymentsLink);
