import React from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, Avatar, Button, Typography, Row } from 'antd';

import RequestSuccessLogo from '../../../assets/images/successful_request_illustration.png';
import MissingDetailsLogo from '../../../assets/images/job_post_client_improvement.png';
import InactiveClientAvatar from '../../../assets/images/inactive_sr_modal_avatar.svg';
import { userStatuses } from '../../../constants';
import { colors } from '../../../styles/colors';

const { Title, Paragraph } = Typography;
const SubmittedSRModal = ({ visible, onOk, t, clientStatus, clientLogo = null, clientDesc = null, onGoToSettings }) => {
  const isActiveClient = clientStatus === userStatuses.ACTIVE;

  let logoSrc;
  let activeClientTitle;
  let activeClientDescription;
  let footerButtons = [];
  if (isActiveClient) {
    if (clientLogo && clientDesc?.length >= 150) {
      logoSrc = RequestSuccessLogo;
      activeClientTitle = t('yourJobWillBeGoingLiveSoon');
      activeClientDescription = (
        <>
          <Paragraph style={{ marginBottom: '20px', textAlign: 'center' }}>{t('reviewingYourJobPost')}</Paragraph>
          <Paragraph style={{ marginBottom: '14px', textAlign: 'center' }}>{t('sitBackAndRelax')}</Paragraph>
        </>
      );
      footerButtons = [
        <Button key={0} type="v2-primary" onClick={onOk}>
          {t('Done')}
        </Button>,
      ];
    } else {
      logoSrc = MissingDetailsLogo;
      activeClientTitle = t('jobPostNeedsEnhancement');
      activeClientDescription = (
        <>
          {!clientLogo && (
            <Paragraph style={{ marginBottom: '20px', textAlign: 'center' }}>{t('addCompanyLogo')}</Paragraph>
          )}
          {(!clientDesc || clientDesc?.length < 150) && (
            <Paragraph style={{ marginBottom: '14px', textAlign: 'center' }}>{t('addCompanyDescription')}</Paragraph>
          )}
        </>
      );
      footerButtons = [
        <Button key={0} type="v2-default" onClick={onOk}>
          {t('doThisLater')}
        </Button>,
        <Button key={1} type="v2-primary" onClick={onGoToSettings}>
          {t('goToSettings')}
        </Button>,
      ];
    }
  } else {
    logoSrc = InactiveClientAvatar;
    footerButtons = [
      <Button key={0} type="v2-primary" onClick={onOk}>
        {t('Done')}
      </Button>,
    ];
  }
  return (
    <Modal centered destroyOnClose maskClosable={false} closable={false} visible={visible} footer={footerButtons}>
      <Row type="flex" align="middle" style={{ justifyContent: 'center', marginBottom: 16 }}>
        <Avatar shape="square" size={200} src={logoSrc} />
      </Row>
      <div style={{ marginBottom: '10px' }}>
        {isActiveClient ? (
          <Title level={4} style={{ textAlign: 'center' }}>
            {activeClientTitle}
          </Title>
        ) : (
          <>
            <Title level={4} style={{ marginBottom: 0 }}>
              {t('Account being verified')}
            </Title>
            <Paragraph style={{ marginBottom: 0 }}>{t('Our team is reviewing your account and request')}</Paragraph>
          </>
        )}
      </div>

      <div style={{ color: colors.primaryText }}>
        {isActiveClient ? (
          activeClientDescription
        ) : (
          <Paragraph style={{ marginBottom: '20px' }}>
            {t(
              'We will contact you if needed. You will be notified via email when your request is approved within 24 hours.',
            )}
          </Paragraph>
        )}
      </div>
    </Modal>
  );
};

export default withTranslation()(SubmittedSRModal);
