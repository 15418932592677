import React from 'react';
import { Checkbox, Col, Row } from 'antd';
import { choiceTypes } from '../../../constants';
import SetKnockout from './SetKnockout';
import MCQTextDescription from './MCQTextDescription';
import OtherPleaseSpecify from './OtherPleaseSpecify';

const MultiSelect = ({ choices, isClicked, questionId, readonly = true }) => {
  const selectedValues = choices.filter(choice => choice.score > 0).map(choice => choice.id);
  return (
    <Checkbox.Group style={{ width: '100%' }} defaultValue={selectedValues}>
      {choices.map(choice => {
        const hasScore = choice?.score > 0;
        return (
          <Row key={choice.id}>
            <Col style={{ flex: 1, pointerEvents: readonly ? 'none' : 'auto', marginBottom: 8 }}>
              <Checkbox className="v2-checkbox-wrapper" value={choice.id}>
                {choice.type === choiceTypes.FREE_TEXT ? (
                  <OtherPleaseSpecify />
                ) : (
                  <MCQTextDescription hasScore={hasScore} knockout={choice.knockout} description={choice.description} />
                )}
              </Checkbox>
            </Col>
            {/* tempory hide set as knock out for multi select */}
            {isClicked && !hasScore && (
              <Col style={{ margin: '0 10px', display: 'none' }}>
                <SetKnockout questionId={questionId} choiceId={choice.id} initialKnockout={choice.knockout} />
              </Col>
            )}
          </Row>
        );
      })}
    </Checkbox.Group>
  );
};

export default MultiSelect;
