import { leaveEntryStatuses, timesheetEntryStatuses } from '../../../constants';
import { colors } from '../../../styles/colors';

const TIMESHEET_ENTRY_STATUS_COLOR_MAP = {
  [timesheetEntryStatuses.APPROVED]: {
    primary: colors.functionalApproved,
    secondary: colors.tagPurple,
  },
  [timesheetEntryStatuses.CLOCKED_OUT]: {
    primary: colors.workmateGreen,
    secondary: colors.tagGreen,
  },
  [timesheetEntryStatuses.CLOCKED_IN]: {
    primary: colors.functionalLink,
    secondary: colors.tagBlue,
  },
  [timesheetEntryStatuses.DISPUTED]: {
    primary: colors.functionalError,
    secondary: colors.tagRed,
  },
  [timesheetEntryStatuses.VOID]: {
    primary: colors.componentBorder,
    secondary: colors.componentBackground,
  },
  [timesheetEntryStatuses.RESOLVED]: {
    primary: colors.componentBorder,
    secondary: colors.componentBackground,
  },
};

export const getColorsForTimesheetEntryTableCell = timesheetEntry => {
  const supervisorConfirmed = timesheetEntry?.supervisor_confirmed || timesheetEntry?.supervisorConfirmed;
  let color = TIMESHEET_ENTRY_STATUS_COLOR_MAP[timesheetEntry.status];
  const isUnconfirmedClockOutEntry =
    !supervisorConfirmed && timesheetEntry.status === timesheetEntryStatuses.CLOCKED_OUT;
  if (isUnconfirmedClockOutEntry) {
    color = {
      primary: colors.functionalWarning,
      secondary: colors.tagYellow,
    };
  }
  if (timesheetEntry.status === timesheetEntryStatuses.CLOCKED_IN) {
    color = {
      primary: undefined,
      secondary: undefined,
    };
  }

  return color;
};

export const getColorsForTimesheetEntryStatusTag = timesheetEntry => {
  let color = TIMESHEET_ENTRY_STATUS_COLOR_MAP[timesheetEntry.status];
  const isUnconfirmedClockOutEntry =
    !timesheetEntry?.supervisorConfirmed && timesheetEntry.status === timesheetEntryStatuses.CLOCKED_OUT;
  if (isUnconfirmedClockOutEntry) {
    color = {
      primary: colors.functionalWarning,
      secondary: colors.tagYellow,
    };
  }

  return color;
};

const LEAVE_ENTRY_STATUS_COLOR_MAP = {
  [leaveEntryStatuses.REQUESTED]: {
    primary: colors.yellow,
    secondary: colors.tagYellow,
  },
  [leaveEntryStatuses.CONFIRMED]: {
    primary: colors.workmateGreen,
    secondary: colors.tagGreen,
  },
  [leaveEntryStatuses.APPROVED]: {
    primary: colors.functionalApproved,
    secondary: colors.tagPurple,
  },
  [leaveEntryStatuses.DISPUTED]: {
    primary: colors.functionalError,
    secondary: colors.tagRed,
  },
  [leaveEntryStatuses.VOID]: {
    primary: colors.componentBorder,
    secondary: colors.componentBackground,
  },
  [leaveEntryStatuses.REJECTED]: {
    primary: colors.functionalError,
    secondary: colors.tagRed,
  },
};

export const getColorsForLeaveEntryStatusTag = status => {
  return LEAVE_ENTRY_STATUS_COLOR_MAP[status];
};

export const getColorsForLeaveEntryCell = status => {
  return LEAVE_ENTRY_STATUS_COLOR_MAP[status];
};
