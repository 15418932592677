import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Row, Col, Divider, Typography, Tag } from 'antd';
import { colors } from '../../../../styles/colors';
import {
  getDurationFromSecondsToHours,
  getFormattedDurationInHoursAndMinutes,
} from '../../../../utilities/durationUtils';
import NoSPVSection from './NoSPVSection';
import { DATETIME_FORMAT_24_HOUR } from '../../../../constants';

const { Text } = Typography;

const AdditionalDetails = withTranslation()(({ t, timesheetEntry }) => {
  const {
    id: timesheetEntryId,
    confirmingSupervisor,
    lastModifiedByName,
    approvedByName,
    voidedByName,
    approvedDate,
    confirmedDate,
    voidedDate,
    modifiedDate,
  } = timesheetEntry;

  let orderedLabeledFields = [
    {
      label: t('entry'),
      component: <>#{timesheetEntryId}</>,
    },
  ];
  if (lastModifiedByName) {
    orderedLabeledFields = [
      ...orderedLabeledFields,
      {
        label: t('lastModifiedBy'),
        /* eslint-disable */
        component: lastModifiedByName,
        time: modifiedDate ? moment(modifiedDate).format(DATETIME_FORMAT_24_HOUR) : null,
      },
    ];
  }

  if (confirmingSupervisor) {
    orderedLabeledFields = [
      ...orderedLabeledFields,
      {
        label: t('confirmedBy'),
        component: confirmingSupervisor.name,
        time: confirmedDate ? moment(confirmedDate).format(DATETIME_FORMAT_24_HOUR) : null,
      },
    ];
  }

  if (approvedByName) {
    orderedLabeledFields = [
      ...orderedLabeledFields,
      {
        label: t('approvedBy'),
        /* eslint-disable */
        component: approvedByName,
        time: approvedDate ? moment(approvedDate).format(DATETIME_FORMAT_24_HOUR) : null,
      },
    ];
  }

  if (voidedByName) {
    orderedLabeledFields = [
      ...orderedLabeledFields,
      {
        label: t('voidedBy'),
        /* eslint-disable */
        component: voidedByName,
        time: voidedDate ? moment(voidedDate).format(DATETIME_FORMAT_24_HOUR) : null,
      },
    ];
  }

  return (
    <>
      {orderedLabeledFields.map(({ label, component, time }) => (
        <Row style={{ marginBottom: 10, display: 'flex' }} key={label}>
          <Col span={12}>
            <Text style={{ fontWeight: 600, color: colors.secondaryText, fontSize: 12, lineHeight: '20px' }}>
              {label.toLocaleUpperCase()}
            </Text>
          </Col>
          <Col span={12}>
            <Row>
              <Text>{component}</Text>
            </Row>
            <Row>
              <Text>{time}</Text>
            </Row>
          </Col>
        </Row>
      ))}
    </>
  );
});

const AdjustmentsDetails = withTranslation()(({ t, timesheetEntry }) => {
  const { late, overtimeDuration, tags, breaks } = timesheetEntry;
  let orderedLabeledFields = [
    {
      label: t('breaks'),
      component: (
        <Row /*key={breakItem.id}*/>
          <Text>
            {`${getFormattedDurationInHoursAndMinutes(timesheetEntry?.breakDurationInMinutes)} (${
              timesheetEntry?.unpaidBreak ? t('unpaidBreakType') : t('paidBreakType')
            })`}
          </Text>
        </Row>
      ),
    },
    {
      label: t('Late'),
      component: <>{late ? t('Yes') : t('No')}</>,
    },
    {
      label: t('Overtime'),
      component: <>{getDurationFromSecondsToHours(overtimeDuration)}</>,
    },
    {
      label: t('timesheetTags'),
      component: (
        <>
          {tags.length > 0
            ? tags.map(tag => (
                <Tag key={`timesheet-detail-tag-${tag.id}`} style={{ marginBottom: 10 }}>
                  {tag.name}
                </Tag>
              ))
            : '-'}
        </>
      ),
    },
  ];

  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <Text style={{ fontWeight: 600, fontSize: 14, color: colors.textTitle, lineHeight: '22px' }}>
          {t('adjustments')}
        </Text>
      </Row>
      {orderedLabeledFields.map(({ label, component }) => (
        <Row style={{ marginBottom: 10, display: 'flex' }} key={label}>
          <Col span={12}>
            <Text style={{ fontWeight: 600, color: colors.secondaryText, fontSize: 12, lineHeight: '20px' }}>
              {label.toLocaleUpperCase()}
            </Text>
          </Col>
          <Col span={12}>
            <Text>{component}</Text>
          </Col>
        </Row>
      ))}
    </>
  );
});

export default withTranslation()(({ timesheetEntry, setVisibleSelfieModal, setHeaderWarnings }) => (
  <>
    <Row style={{ width: '100%' }} gutter={[12, 12]}>
      <NoSPVSection
        timesheetEntry={timesheetEntry}
        setVisibleSelfieModal={setVisibleSelfieModal}
        setHeaderWarnings={setHeaderWarnings}
      />
    </Row>
    <Divider style={{ width: '100%' }} />
    <div style={{ width: '100%' }}>
      <AdjustmentsDetails timesheetEntry={timesheetEntry} />
    </div>
    <Divider style={{ width: '100%' }} />
    <div style={{ width: '100%' }}>
      <AdditionalDetails timesheetEntry={timesheetEntry} />
    </div>
  </>
));
