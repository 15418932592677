import moment from 'moment-timezone';
import { SERVER_DATE } from '../styles/dates';
import { absenceDetailStatuses, attendanceAbsenceReasons } from '../constants';

const getAbsenceDetail = (absenceDetails = [], date) => {
  const selectedDate = moment(date).format(SERVER_DATE);
  const absenceDetail = absenceDetails.find(detail => {
    const absenceDate = detail?.absence_date || detail?.absenceDate;
    return (
      moment(absenceDate).format(SERVER_DATE) === selectedDate && detail.status !== absenceDetailStatuses.CANCELLED
    );
  });
  return absenceDetail;
};

const getAbsenceDetailReasonText = (reason, t) => {
  const reasonText = {
    [attendanceAbsenceReasons.NO_SHOW]: t('noShow'),
  };
  return reasonText[reason];
};

export default {
  getAbsenceDetail,
  getAbsenceDetailReasonText,
};
