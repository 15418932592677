import employmentApi from '../services/employmentApi';

export const fetchPositionsAndRolesWithEmployments = async () => {
  const { positions } = await employmentApi.getPositions();
  const { roles } = await employmentApi.getRoles();
  return {
    positions,
    roles,
  };
};

export const getPositionAndRoleOptions = async (allPositions, allRoles, t) => {
  const { positions, roles } = await fetchPositionsAndRolesWithEmployments();
  const positionAndRoleOptions = allPositions
    .filter(position => positions.includes(position.id))
    .map(position => {
      let option = {
        title: position.name,
        key: `${position.id}`,
        value: `${position.id}`,
      };
      const positionRoles = allRoles
        .filter(role => role.position.id === position.id && roles.includes(role.id))
        .map(role => ({
          title: role.name,
          key: `${position.id}-${role.id}`,
          value: `${position.id}-${role.id}`,
        }));

      if (positionRoles.length > 0) {
        positionRoles.push({
          title: t('noAssignedRole'),
          position: position.name,
          key: `${position.id}-null`,
          value: `${position.id}-null`,
        });
      }

      if (positionRoles.length > 0) {
        option = {
          ...option,
          children: positionRoles,
        };
      }
      return option;
    });
  positionAndRoleOptions.unshift({
    title: t('unassigned'),
    key: 'null',
    value: 'null',
  });
  return positionAndRoleOptions;
};

export const formatPositionAndRoleTag = item => {
  if (item.position) {
    return `${item.title} (${item.position})`;
  }
  return item.title;
};

export const convertPositionAndRoleToList = (selectedPositionAndRole, includePosition = false) => {
  const positions = new Set();
  const roles = new Set();
  const noRoles = new Set(); // will contain position IDs
  if (selectedPositionAndRole) {
    selectedPositionAndRole.forEach(option => {
      if (option === ' null') {
        positions.add('null');
      }
      const ids = option.split('-');
      if (ids.length === 1 || (includePosition && ids.length > 0)) {
        positions.add(ids[0]);
      }
      if (ids.length === 2) {
        if (ids[1] !== 'null') {
          roles.add(ids[1]);
        } else {
          noRoles.add(ids[0]);
        }
      }
    });
  }
  return { positions: [...positions], roles: [...roles], noRoles: [...noRoles] };
};

export const convertPositionAndRoleToOptions = (position, role, noRole, allPositions, allRoles) => {
  const positionAndRoleOptions = [];
  const getOptions = options => {
    return options
      .split(',')
      .filter(option => option)
      .map(option => (option !== 'null' ? +option : 'null'));
  };

  const positions = getOptions(position || '');
  const roles = getOptions(role || '');
  const noRoles = getOptions(noRole || '');

  if (positions.includes('null')) {
    positionAndRoleOptions.unshift('null');
  }

  allPositions.forEach(positionValue => {
    if (positions.includes(positionValue.id)) {
      positionAndRoleOptions.push(`${positionValue.id}`);
    }
    allRoles
      .filter(roleValue => roleValue.position.id === positionValue.id)
      .forEach(roleValue => {
        if (roles.includes(roleValue.id)) {
          positionAndRoleOptions.push(`${roleValue.position.id}-${roleValue.id}`);
        }
      });
    if (noRoles.includes(positionValue.id)) {
      positionAndRoleOptions.push(`${positionValue.id}-null`);
    }
  });
  return positionAndRoleOptions;
};
