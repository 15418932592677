import React, { useEffect, useState } from 'react';
import { Col, Row, Typography, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import formsApi from '../../../services/formsApi';
import QuestionList from '../../FormsPage/components/QuestionList';
import { colors } from '../../../styles/colors';
import { formStatuses } from '../../../constants';
import formUtils from '../../../utilities/formUtils';

const StaffRequestFormPreviewView = ({ match }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [applicationForm, setApplicationForm] = useState(null);

  useEffect(() => {
    const fetchForm = async () => {
      const { id } = match.params;
      setApplicationForm(await formsApi.fetchFormById(id));
      setLoading(false);
    };

    fetchForm();
  }, []);

  if (loading) {
    return <></>;
  }

  const totalScore = applicationForm.recruitment_form_questions
    .map(formQuestion => {
      return formQuestion.recruitment_form_choices
        .map(formChoice => formChoice.choice.score)
        .reduce((acc, curr) => acc + curr, 0);
    })
    .reduce((acc, curr) => acc + curr, 0);

  const passingScorePercentage =
    (Number.isNaN(+applicationForm.passing_score) ? 0 : +applicationForm.passing_score) / 100;
  let passingScore = 0;
  if (totalScore) {
    passingScore = Math.ceil(totalScore * passingScorePercentage);
  }

  const questions = formUtils.formatQuestions(applicationForm?.recruitment_form_questions || []);

  return (
    <>
      <Row>
        <Col lg={14}>
          <Row align="center" style={{ justifyContent: 'space-between', marginBottom: 16 }}>
            <Typography.Title level={4}>{applicationForm.title}</Typography.Title>
          </Row>
          {applicationForm.status === formStatuses.INACTIVE && (
            <Alert message={t('formDeactivatedQuestionsDesc')} type="info" showIcon style={{ marginBottom: 20 }} />
          )}

          {passingScore > 0 && totalScore > 0 && (
            <Row align="center" style={{ justifyContent: 'space-between', marginBottom: 14 }}>
              <Typography.Text style={{ color: colors.secondaryText }}>
                {t('passingScoreDesc', {
                  passingScore,
                  totalScore,
                })}
              </Typography.Text>
            </Row>
          )}
          <QuestionList editable={false} sortable={false} questions={questions} />
        </Col>
      </Row>
    </>
  );
};

export default StaffRequestFormPreviewView;
