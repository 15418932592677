import { rrulestr } from 'rrule';
import moment from 'moment-timezone';
import numeral from 'numeral';
import 'moment/locale/th';
import 'moment/locale/id';
import { cloneDeep, endsWith } from 'lodash';
import dateUtils from './dateUtils';
import {
  DEFAULT_TIME_FORMAT,
  staffRequestStatuses,
  staffRequestTabs,
  employmentTypes,
  countryCodeOptions,
  applicationStatuses,
  recurrenceOptions,
  TIME_FORMAT_24_HOUR,
  TIME_FORMAT_24_HOUR_WITH_SECONDS,
  staffRequestTemplateStatuses,
  clientTypes,
  clockinMethods,
  allowedDomains,
} from '../constants';
import routes from '../routes';

const checkMomentLocale = () => {
  const currentLang = localStorage.getItem('i18nextLng');
  moment.locale(currentLang);
};

// Schedule is a schedule object from our backend
const scheduleToForm = schedule => {
  checkMomentLocale();
  // Schedule can be undefined when user is adding a new schedule.
  if (!schedule) {
    return {
      start_date: moment(),
      start_time: moment('09:00', 'h:mm A'),
      end_time: moment('17:00', 'h:mm A'),
      days: [0, 1, 2, 3, 4], // monday to friday
    };
  }
  const rrule = rrulestr(schedule.recurrences);
  const start_time = moment(`${moment().format('YYYY-MM-DD')} ${schedule.start_time}`);
  const end_time = moment(`${moment().format('YYYY-MM-DD')} ${schedule.end_time}`);
  const start_date = moment(schedule.start_date);
  const end_date = rrule.options.until && moment(rrule.options.until).subtract(1, 'd');
  const days = rrule.options.byweekday;
  return {
    id: schedule.id,
    start_date,
    end_date,
    start_time,
    end_time,
    days,
  };
};

const workingDaysToStringArray = workingdays => {
  const workingDaysStringArray = [];
  (workingdays || [])
    .sort((a, b) => a - b)
    .forEach(item => workingDaysStringArray.push(recurrenceOptions.find(option => option.value === item).label));
  return workingDaysStringArray;
};

const workingDaysToIntArray = workingDays => {
  const workingDaysIntArray = [];
  (workingDays || []).forEach(item =>
    workingDaysIntArray.push(recurrenceOptions.find(option => option.label === item).value),
  );
  return workingDaysIntArray;
};

// Transforms form data to a payload to send for API. Shape of payload below:
// https://pegasusapi.helpster.asia/v1/docs/#operation/Create%20a%20Staff%20Request
const formDataToPayload = (values, timezone, country_code) => {
  // Create new values object to prevent mutating form values
  const payload = {};
  payload.title = values.title;
  payload.responsibilities = values.responsibilities;
  payload.requirements = values.requirements;
  payload.benefits = values.benefits;
  payload.wage_amount = values.wage_amount.toString();
  payload.transport_allowance = values.transport_allowance || 0;
  payload.meal_allowance = values.meal_allowance || 0;
  payload.other_allowance = values.other_allowance || 0;
  payload.wage_type = values.wage_type;
  payload.payment_frequency = values.payment_frequency;
  payload.staff_required = values.staff_required;
  payload.gender = values.gender === 'any' || values.gender === null ? null : values.gender;
  payload.min_age = values.min_age === true ? 21 : null;
  payload.require_experience = values.require_experience;
  payload.require_english = values.require_english;
  payload.location = values.location.id;
  payload.position = values.position.id;
  payload.employment_type = values.employment_type;
  payload.start_time = values.start_time;
  payload.form = values.form_id;
  payload.first_day_message = values.first_day_message;
  payload.client_interview_required = values.client_interview_required;
  payload.auto_hire = values.auto_hire || false;
  payload.public_holiday_weekend_wage = values.public_holiday_weekend_wage || 0;
  payload.vip_workers_only = values.vip_workers_only;

  if (country_code === countryCodeOptions.SINGAPORE) {
    payload.working_days = values?.working_days ? workingDaysToStringArray(values.working_days) : [];
    payload.begin_time = values?.begin_time
      ? moment(values?.begin_time, TIME_FORMAT_24_HOUR_WITH_SECONDS).format(TIME_FORMAT_24_HOUR)
      : null;
    payload.finish_time = values?.finish_time
      ? moment(values?.finish_time, TIME_FORMAT_24_HOUR_WITH_SECONDS).format(TIME_FORMAT_24_HOUR)
      : null;
    payload.break_duration_in_minutes = values.break_duration_in_minutes;
    payload.unpaid_break = values.unpaid_break;
    payload.weekend_wage = values.weekend_wage || 0;
    payload.public_holiday_wage = values.public_holiday_wage || 0;
    payload.weekend_includes_friday = values.weekend_includes_friday;
  } else if (country_code === countryCodeOptions.INDONESIA) {
    payload.contract_type = values.contract_type;
  }

  if (typeof values.start_time === 'string') {
    payload.start_time = moment(values.start_time)
      .tz(timezone)
      .startOf('d')
      .toISOString();
  }
  payload.end_time = values.end_time || null;
  if (typeof values.end_time === 'string') {
    payload.end_time = moment(values.end_time)
      .tz(timezone)
      .endOf('d')
      .toISOString();
  }
  return payload;
};

// Returns an array of objects about the:
// 1. days: "Mon, Tue, Wed"
// 2. hourDuration: "9:00AM – 5:00PM"
// 3. period: "Mon, 25 Nov 2019 - Tue, 26 Nov 2019"
const schedulesToRecurrenceString = (schedules = [], t) => {
  checkMomentLocale();
  const timetoAmPm = timeString => {
    const time = moment(`${moment().format('YYYY-MM-DD')} ${timeString}`);
    return time.format('hh:mmA');
  };

  return schedules.map(schedule => {
    const rrule = rrulestr(schedule.recurrences);
    const days = rrule.options.byweekday; // an array of integers [0-6] representing Mon - Sun
    const endTime = timetoAmPm(schedule.end_time);
    const startTime = timetoAmPm(schedule.start_time);
    const endDate = rrule.options.until && moment(rrule.options.until).subtract(1, 'd');
    const startDate = moment(schedule.start_date);
    const periodFormat = 'ddd, DD MMM YYYY';
    return {
      days: days
        .sort()
        .map(val => t(dateUtils.getDayOfWeek(val)))
        .join(', '),
      hourDuration: `${startTime} - ${endTime}`,
      period: `${startDate.format(periodFormat)} - ${endDate && endDate.format(periodFormat)}`,
    };
  });
};

const getEffectiveWageAmount = staffRequest => {
  const baseWage = staffRequest.wage_amount ? parseFloat(staffRequest.wage_amount) : 0;
  const transportAllowance = staffRequest.transport_allowance ? parseFloat(staffRequest.transport_allowance) : 0;
  const mealAllowance = staffRequest.meal_allowance ? parseFloat(staffRequest.meal_allowance) : 0;
  const otherAllowance = staffRequest.other_allowance ? parseFloat(staffRequest.other_allowance) : 0;

  return baseWage + transportAllowance + mealAllowance + otherAllowance;
};

const wageToString = (staffRequest, t) => {
  return `${staffRequest.location.address.country.currency_code} ${numeral(getEffectiveWageAmount(staffRequest)).format(
    '0,0.00',
  )} ${t(`${staffRequest.wage_type.replace('_', ' ')}`)}`;
};

const getEmploymentTypeOptions = (t, user) => {
  const employmentTypeOptions = [
    { disabled: false, value: employmentTypes.FULL_TIME.value, label: t(employmentTypes.FULL_TIME.label) },
    { disabled: false, value: employmentTypes.PART_TIME.value, label: t(employmentTypes.PART_TIME.label) },
    { disabled: false, value: employmentTypes.GIG.value, label: t(employmentTypes.GIG.label) },
  ];

  employmentTypeOptions.splice(
    2, // index
    0, // insert only
    user.country.code === countryCodeOptions.INDONESIA
      ? {
          disabled: false,
          value: employmentTypes.DAILY.value,
          label: t(employmentTypes.DAILY.label),
        }
      : { disabled: false, value: employmentTypes.CONTRACT.value, label: t(employmentTypes.CONTRACT.label) },
  );

  if (user.type === clientTypes.SELF_SERVE) {
    return employmentTypeOptions.map(option =>
      option.value !== employmentTypes.GIG.value ? { ...option, disabled: true } : option,
    );
  }

  return employmentTypeOptions;
};

export const getInterviewDescription = (clientInterviewRequired, autoHire, t) => {
  let interviewDescription = '';
  if (clientInterviewRequired && autoHire) {
    interviewDescription = t('interviewRequiredAutohireDesc');
  } else if (clientInterviewRequired && !autoHire) {
    interviewDescription = t('interviewRequiredYesDesc');
  } else {
    interviewDescription = t('interviewRequiredNoDesc');
  }
  return interviewDescription;
};

const getSRSummaryData = staffRequest => {
  const {
    id,
    status,
    position,
    title,
    description,
    client,
    location,
    created_date,
    employment_type,
    t,
    user,
    paused,
  } = staffRequest;

  checkMomentLocale();
  const employmentTypeOptions = getEmploymentTypeOptions(t, user);
  const highlightedData = [
    { iconType: 'environment', value: location.name },
    {
      iconType: 'solution',
      value: employmentTypeOptions.find(employmentTypeOption => employmentTypeOption.value === employment_type).label,
    },
    { iconType: 'dollar', value: wageToString(staffRequest, t) },
    {
      iconType: 'calendar',
      value: getInterviewDescription(staffRequest?.client_interview_required, staffRequest?.auto_hire, t),
    },
  ];
  return {
    id,
    clientName: client.name,
    title,
    position: position.name,
    description,
    highlightedData,
    status,
    paused,
    footNoteText: `${t('Created on')} ${moment(created_date).format('DD MMM YYYY')}`,
  };
};

// Used primarily to transform "order_by" in URL to a friendly label
const getFriendlyNameFromOrdering = ordering => {
  if (ordering === '-end_time') {
    return 'End date';
  }
  if (ordering === '-start_time') {
    return 'Start date';
  }
  return 'Most recent';
};

// Given a list of staff requests, only return cleaned data
const cleanSRListData = staffRequests => {
  return staffRequests.map(staffRequest => {
    return getSRSummaryData(staffRequest);
  });
};

// Finds the earliest start date & latest end date in all schedules
// Diff the two dates from the above to get the greatest period in all schedules
// If period is >= 90 days, we should show Legal break duration for users to set
const showLegalBreakDuration = (form, user) => {
  // Only indonesian clients who have not set up legal break duration will see this
  if ((user.country && user.country.id !== 1) || user.legalBreakDuration !== null) {
    return false;
  }

  // Check if schedules are set in form or not
  const values = form.getFieldsValue();
  if (!values.end_date || !values.end_date[values.scheduleKeys[0]]) {
    return false;
  }
  let earliestStartDate = values.start_date[values.scheduleKeys[0]];
  let latestEndDate = values.end_date[values.scheduleKeys[0]];

  for (let i = 1; i < values.scheduleKeys.length; i += 1) {
    const key = values.scheduleKeys[i];
    const startDate = values.start_date[key];
    const endDate = values.end_date[key];

    if (startDate && startDate.diff(earliestStartDate, 'days') < 0) {
      earliestStartDate = startDate;
    }
    if (endDate && endDate.diff(latestEndDate, 'days') > 0) {
      latestEndDate = endDate;
    }
  }

  return latestEndDate.diff(earliestStartDate, 'days') >= 90;
};

// For viewing on StaffRequest Table
const rawDataToRows = staffRequests => {
  if (!staffRequests || staffRequests.length === 0) {
    return [];
  }
  return staffRequests.map(request => {
    const { start_time, end_time, wage_amount, staff_required, timezone } = request;
    const fillRate = `${request.offer_counts.confirmed} / ${staff_required}`;
    const period = dateUtils.toFriendlyPeriod(start_time, end_time, timezone, timezone, false);
    const createdDate = dateUtils.toFriendly(request.created_date, timezone, true);
    // TODO: "request.wage_type" should determine the "wage" string below. e.g "per day" or "per month".
    const wage = `${wage_amount} per day`;

    // Depending on the status of our SR, we want to redirect them to the detail page with an appropriate tab opened
    let tab = staffRequestTabs.SUMMARY;
    if (request.status === staffRequestStatuses.ACTIVE || request.status === staffRequestStatuses.IN_PROGRESS) {
      tab = staffRequestTabs.TIMESHEET;
    } else if (request.status === staffRequestStatuses.POSTED) {
      tab = staffRequestTabs.OFFERS;
    }

    return {
      period,
      wage,
      fillRate,
      createdDate,
      key: request.id,
      srUrl: { id: request.id, tab, status: request.status },
      // TODO: [temp fix for missing locations]. All SR's should have location.
      title: request.title,
      location: request.location ? request.location.name : '-',
      position: request.position ? request.position.name : 'No Position',
      status: request.status,
      requested: request.staff_required,
      applied: request.offer_counts.applied,
      confirmed: request.offer_counts.confirmed,
      sent: request.offer_counts.sent,
    };
  });
};

// For viewing on billing
const toSummary = (staffRequest, t) => {
  if (!staffRequest) {
    return [];
  }
  const { timezone } = staffRequest;
  const readableSchedules = staffRequest.schedules?.map(schedule => {
    const rrule = rrulestr(schedule.recurrences);
    const days = rrule.options.byweekday;
    const endDate = rrule.options.until && moment(rrule.options.until).subtract(1, 'd');
    const startDate = moment(schedule.start_date).tz(timezone);
    const endTime = moment(`${moment().format('YYYY-MM-DD')} ${schedule.end_time}`).tz(timezone);
    const startTime = moment(`${moment().format('YYYY-MM-DD')} ${schedule.start_time}`).tz(timezone);

    let endDateString = '';
    if (endDate) {
      endDateString = endDate.format('ll');
    }
    return {
      period: `${startDate.format('ll')} – ${endDateString}`,
      shiftHours: `${startTime.format(DEFAULT_TIME_FORMAT)} – ${endTime.format(DEFAULT_TIME_FORMAT)}`,
      days: days.map(val => t(dateUtils.getDayOfWeek(val))).join(', '),
    };
  });

  return [
    {
      title: 'Business name',
      value: staffRequest.client.name,
    },
    {
      title: 'Location',
      value: staffRequest.location.name,
    },
    {
      title: 'Position',
      value: staffRequest.position.name,
    },
    {
      title: 'Title',
      value: staffRequest.title,
    },
    {
      title: 'Staff required',
      value: staffRequest.staff_required,
    },
    {
      title: 'Must be over 21',
      value: staffRequest.min_age >= 21 ? 'Yes' : 'No',
    },
    {
      title: 'English required',
      value: staffRequest.require_english ? 'Yes' : 'No',
    },
    {
      title: 'Experience required',
      value: staffRequest.require_experience ? 'Yes' : 'No',
    },
    {
      title: 'Gender',
      value: staffRequest.gender ? staffRequest.gender : 'Any',
    },
    {
      title: 'Wage amount',
      value: `${staffRequest.location.address.country.currency_code} ${staffRequest.wage_amount} per day`,
    },
    {
      title: 'Shift',
      value: readableSchedules,
    },
  ];
};

const getSRCallToAction = (status, srId, applicantTasks, clientInterviewRequired) => {
  const srDetailRoute = routes.staffRequestDetail.replace(':id', srId);
  let to = srDetailRoute.replace(':tab', staffRequestTabs.EMPLOYEES);
  if (status === staffRequestStatuses.DRAFT) {
    to = routes.editStaffRequest.replace(':id', srId);
  } else if (
    [staffRequestStatuses.POSTED, staffRequestStatuses.IN_PROGRESS].includes(status) &&
    applicantTasks.count > 0
  ) {
    to = srDetailRoute.replace(
      ':tab',
      clientInterviewRequired ? staffRequestTabs.INTERVIEW : staffRequestTabs.WAITLISTED,
    );
  } else if (status === staffRequestStatuses.IN_PROGRESS) {
    to = srDetailRoute.replace(':tab', staffRequestTabs.EMPLOYEES);
  }
  return to;
};

const updateApplicationStatus = (application, movedToNewTab) => {
  const localApplication = cloneDeep(application);
  if (movedToNewTab === staffRequestTabs.WAITLISTED) {
    localApplication.interviewed = false;
    localApplication.shortlisted = true;
  }
  if (movedToNewTab === staffRequestTabs.INTERVIEW) {
    localApplication.interviewed = true;
    localApplication.shortlisted = false;
  }
  if (movedToNewTab === staffRequestTabs.OFFERS) {
    localApplication.status = applicationStatuses.PENDING_CONTRACT;
  }
  if (movedToNewTab === staffRequestTabs.INACTIVE) {
    localApplication.status = applicationStatuses.REJECTED;
  }
  return localApplication;
};

const isWorkmateAccount = username => {
  return allowedDomains.some(domain => endsWith(username, domain));
};

const generateOfferExpirySessionStorageKey = staffRquestId => `job-${staffRquestId}-offer-expiry`;

const formStaffRequestTemplatePayload = (staffRequestTemplate, clientId, action) => {
  return {
    template_name: staffRequestTemplate?.template_name,
    title: staffRequestTemplate?.title,
    benefits: staffRequestTemplate?.benefits,
    employment_type: staffRequestTemplate?.employment_type,
    first_day_message: staffRequestTemplate?.first_day_message,
    meal_allowance: staffRequestTemplate?.meal_allowance || 0,
    other_allowance: staffRequestTemplate?.other_allowance || 0,
    transport_allowance: staffRequestTemplate?.transport_allowance || 0,
    payment_frequency: staffRequestTemplate?.payment_frequency,
    requirements: staffRequestTemplate?.requirements,
    responsibilities: staffRequestTemplate?.responsibilities,
    wage_amount: staffRequestTemplate?.wage_amount,
    wage_type: staffRequestTemplate?.wage_type,
    form: staffRequestTemplate?.form_id,
    require_experience: staffRequestTemplate?.require_experience,
    position: staffRequestTemplate?.position?.id,
    location: staffRequestTemplate?.location?.id,
    client: clientId,
    contract_type: staffRequestTemplate?.contract_type,
    status:
      action === 'submit' || staffRequestTemplate?.status === staffRequestTemplateStatuses.POSTED
        ? staffRequestTemplateStatuses.POSTED
        : staffRequestTemplateStatuses.DRAFT,
    version: 1, // need to confirm
    weekend_wage: staffRequestTemplate?.weekend_wage || 0,
    public_holiday_wage: staffRequestTemplate?.public_holiday_wage || 0,
    weekend_includes_friday: staffRequestTemplate?.weekend_includes_friday,
    public_holiday_weekend_wage: staffRequestTemplate?.public_holiday_weekend_wage || 0,
    fixed_location: staffRequestTemplate?.fixed_location,
    qr_override_allowed: staffRequestTemplate?.clockin_method === clockinMethods.QR_SCAN_OR_SELFIE || false,
  };
};

const getEmploymentTypesWithoutGig = () => {
  return Object.values(employmentTypes)
    .filter(employmentType => employmentType.value !== employmentTypes.GIG.value)
    .map(employmentType => employmentType.value)
    .join(',');
};

export default {
  getFriendlyNameFromOrdering,
  wageToString,
  cleanSRListData,
  schedulesToRecurrenceString,
  scheduleToForm,
  formDataToPayload,
  showLegalBreakDuration,
  getEffectiveWageAmount,
  rawDataToRows,
  toSummary,
  getEmploymentTypeOptions,
  getSRCallToAction,
  updateApplicationStatus,
  isWorkmateAccount,
  generateOfferExpirySessionStorageKey,
  workingDaysToStringArray,
  workingDaysToIntArray,
  formStaffRequestTemplatePayload,
  getInterviewDescription,
  getEmploymentTypesWithoutGig,
};
