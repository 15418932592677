import React from 'react';
import { withTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import { Row, Col, Card, Avatar, Divider } from 'antd';
import SkillTestCard from './SkillTestCard';

const ProfileCard = ({ worker, skillQuizScores }) => {
  return (
    <Card
      style={{ width: '100%' }}
      cover={
        <Avatar
          icon={<UserOutlined />}
          shape="square"
          src={worker.imgSrc}
          style={{ width: '100%', height: '324px', marginBottom: '24px' }}
          size={324}
        />
      }
      bordered={false}
      bodyStyle={{ padding: '0 24px 0 0' }}
    >
      <Row>
        <Col span={24}>
          {skillQuizScores && (
            <>
              <SkillTestCard skillQuizScores={skillQuizScores} />
              <Divider style={{ margin: '32px 0' }} />
            </>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(ProfileCard);
