// TODO: Refactor out into helpster-components library
import React, { useState } from 'react';
import { Typography, Row, Col, Button } from 'antd';
import { withTranslation } from 'react-i18next';
import { getEstablishTwilioConnection } from '../../../utilities/chatUtils';
import ChatLoadingError from '../../../assets/images/chat_loading_error.svg';

const { Title, Text } = Typography;

const ChatLoadingErrorPrompt = ({ t, additionalFunctions = undefined }) => {
  const [isLoading, setIsLoading] = useState(false);
  const establishTwilioConnection = getEstablishTwilioConnection();
  const onClick = async () => {
    setIsLoading(true);
    await establishTwilioConnection();
    if (additionalFunctions) {
      await additionalFunctions();
    }
    setIsLoading(false);
  };
  return (
    <>
      <Row justify="center" style={{ marginBottom: '16px' }}>
        <Col>
          <img src={ChatLoadingError} alt="empty_result" />
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Title level={4}>{t('chatLoadingErrorPromptTitle')}</Title>
      </Row>
      <Row type="flex" justify="center" style={{ textAlign: 'center' }}>
        <Text>{t('chatLoadingErrorPromptDescription')}</Text>
      </Row>
      <Row type="flex" justify="center" style={{ margin: '16px' }}>
        <Button type="v2-primary" loading={isLoading} onClick={onClick}>
          {t('reloadChat')}
        </Button>
      </Row>
    </>
  );
};

export default withTranslation()(ChatLoadingErrorPrompt);
