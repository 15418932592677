import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Dropdown, Menu, Typography, Grid, message } from 'antd';
import { ShareAltOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { colors } from '../../../../styles/colors';
import { shiftStatuses } from '../../../../constants';
import getDeepLink from '../../../../utilities/getDeepLink';

const { useBreakpoint } = Grid;

const GigDetailActions = ({ shift, onClickShowDetails, onClickCancel }) => {
  const { t } = useTranslation();
  const { lg } = useBreakpoint();

  const handleShare = () => {
    const srDeeplink = getDeepLink(
      shift?.staffRequestId,
      shift?.staffRequest?.client?.country?.code,
      shift?.staffRequest?.client?.country?.name?.toLowerCase(),
    );
    navigator.clipboard.writeText(srDeeplink);
    message.success(t('linkCopiedToClipboard'));
  };

  const allowCancel = ![shiftStatuses.CANCELLED, shiftStatuses.ENDED].includes(shift?.status?.toLowerCase());

  return (
    <>
      <Row style={{ margin: lg ? 0 : '16px 0' }} gutter={8}>
        <Col xs={10}>
          <Button block icon={<ShareAltOutlined />} onClick={handleShare}>
            {t('Share')}
          </Button>
        </Col>
        <Col xs={10}>
          <Button block icon={<EditOutlined />} disabled>
            {t('edit')}
          </Button>
        </Col>
        <Col xs={4}>
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item key="details" style={{ padding: '12px 20px' }} onClick={onClickShowDetails}>
                  <Typography.Text style={{ color: colors.primaryText }}> {t('details')}</Typography.Text>
                </Menu.Item>
                {allowCancel && (
                  <Menu.Item key="cancel" style={{ padding: '12px 20px' }} onClick={onClickCancel}>
                    <Typography.Text style={{ color: colors.functionalError }}> {t('cancel')}</Typography.Text>
                  </Menu.Item>
                )}
              </Menu>
            }
            placement="bottomRight"
          >
            <Button block>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};

export default GigDetailActions;
