export default {
  home: '/',
  login: '/login/',
  signUp: '/signup/',
  resetPassword: '/reset/:uid/:token',
  forgotPassword: '/forgot-password',
  settings: '/settings/:tab?',
  policies: '/policies',
  termsOfService: '/terms-of-service',
  // Partner routes
  partners: `/partners`,
  partnerDetail: '/partners/:id',
  // SR routes
  staffRequests: `/jobs`,
  staffRequestTemplates: `/job-templates`,
  createStaffRequest: '/jobs/create',
  createGig: '/jobs/create-gig',
  staffRequestForms: '/jobs/application-forms',
  formPreview: '/jobs/application-forms/:id/preview',
  editStaffRequest: '/jobs/:id/edit',
  billingStaffRequest: '/jobs/:id/billing',
  potentialMatches: '/jobs/:id/potential-matches',
  staffRequestDetail: '/jobs/:id/:tab?',
  prepaidDetail: '/jobs/:id/prepaid-detail',
  gigDetail: '/jobs/gigs/:id/:tab',
  billingReportDetail: '/billing/:id',
  signContract: '/sign-contract',
  attendance: '/attendance',
  // Shifts
  shifts: '/shifts',
  shiftsV2: '/shiftsV2',
  analytics: '/analytics',
  // Workforce
  workforce: '/workforce',
  // Forms
  forms: '/forms',
  createForm: '/forms/create',
  createJobTemplate: '/job-templates/create',
  editJobTemplate: '/job-templates/:id/edit',
};
