import React from 'react';
import { Card, Typography, Image, Row, Col } from 'antd';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import { PlusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { choiceTypes } from '../../../constants';
import fallBackImg from '../../../assets/images/fallback.png';

const StyledCard = styled(Card)`
  border-radius: 0;
  border: 1px solid transparent;
  border-bottom: 1px solid ${colors.componentBorder};
  &:hover {
    border: 1px solid ${colors.functionalSuccess};
    svg {
      color: ${colors.functionalSuccess};
    }
  }
`;

const QuestionLibraryChoices = ({ type, choices = [] }) => {
  const isLastItem = index => {
    return index === choices.length - 1;
  };

  switch (type) {
    case choiceTypes.MULTI_SELECT:
    case choiceTypes.SINGLE_SELECT:
      return (
        <Row>
          {choices.map((choice, index) => {
            const hasScore = choice?.score > 0;
            return (
              <Col key={choice.id}>
                <Typography.Text>
                  <span
                    style={{
                      color: hasScore ? colors.functionalSuccess : colors.primaryText,
                      fontWeight: hasScore ? 600 : 400,
                    }}
                  >
                    {choice.description}
                  </span>
                  {!isLastItem(index) && <span style={{ margin: '0 4px' }}>•</span>}
                </Typography.Text>
              </Col>
            );
          })}
        </Row>
      );
    case choiceTypes.FREE_TEXT: {
      const [choice] = choices;
      return choice?.description ? <Typography.Text>{choice.description}</Typography.Text> : '';
    }
    default:
      return null;
  }
};

const QuestionLibraryItem = ({ question, style = {}, onSelectQuestion }) => {
  return (
    <StyledCard style={{ ...style, cursor: 'pointer' }} onClick={onSelectQuestion}>
      <Row wrap={false}>
        <Col flex={1}>
          <Typography.Paragraph style={{ fontWeight: 600, marginBottom: 8 }}>{question.title}</Typography.Paragraph>
          {question.description && (
            <div style={{ marginBottom: 8, fontSize: 12, color: colors.primaryText }}>
              <ReactMarkdown remarkPlugins={[remarkBreaks, remarkGfm]} linkTarget="_blank">
                {question.description}
              </ReactMarkdown>
            </div>
          )}
          <Row style={{ marginRight: 16 }} wrap={false}>
            <Col flex={1}>
              <QuestionLibraryChoices choices={question.choices} type={question.type} />
            </Col>
            <Col style={{ marginLeft: 16, width: 100 }}>
              {question.image && (
                <Image
                  width={100}
                  height={70}
                  src={question.image}
                  onClick={e => e.stopPropagation()}
                  style={{ objectFit: 'contain' }}
                  fallback={fallBackImg}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col style={{ color: colors.componentBorder, alignSelf: 'center', paddingLeft: 10 }}>
          <PlusCircleOutlined style={{ color: 'inherit', fontSize: 20 }} />
        </Col>
      </Row>
    </StyledCard>
  );
};

export default QuestionLibraryItem;
