import React from 'react';
import { Button, Row, Col, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { colors } from '../../styles/colors';

const { Text } = Typography;

const BackButton = ({ title, onClick }) => {
  return (
    <Button
      type="link"
      onClick={onClick}
      style={{
        padding: '0px',
        marginBottom: '20px',
      }}
    >
      <Row>
        <Col>
          <LeftOutlined style={{ marginRight: '8px', color: colors.secondaryText }} />
          <Text type="secondary" style={{ color: colors.secondaryText }}>
            {title}
          </Text>
        </Col>
      </Row>
    </Button>
  );
};

export default BackButton;
