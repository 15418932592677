/* eslint-disable no-restricted-syntax */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Table, Typography, Avatar, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';

import textUtils from '../../../utilities/textUtils';
import routes from '../../../routes';
import { colors } from '../../../styles/colors';
import { DATE_FORMAT } from '../../../constants';

const { Text } = Typography;

const EndingEmploymentsTable = ({
  t,
  employments,
  selectedEmployments,
  loading,
  page,
  defaultPageSize,
  totalEmploymentsCount,
  onTableChange,
  onEmploymentsSelectedChange,
  testId,
}) => {
  const tableColumns = [
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('Name')}
        </Text>
      ),
      dataIndex: 'worker',
      sorter: true,
      key: 'worker',
      render: worker => (
        <div style={{ display: 'flex', flexDirection: 'row', width: 244, alignItems: 'center' }}>
          <Avatar shape="square" size={36} src={worker.imgSrc} icon={<UserOutlined />} />
          <Text ellipsis style={{ width: 208 }}>
            <a
              href={`${routes.partners}/${worker.id}`}
              style={{ fontWeight: 600, color: colors.black, paddingLeft: '8px' }}
              target="_blank"
              rel="noopener noreferrer"
              className="underline-on-hover"
            >
              {textUtils.toTitleCase(worker.name)}
            </a>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('Location')}
        </Text>
      ),
      width: 130,
      dataIndex: 'location',
      key: 'location',
      sorter: true,
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('Position')}
        </Text>
      ),
      width: 180,
      dataIndex: 'position',
      key: 'position',
      sorter: true,
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('Start date')}
        </Text>
      ),
      width: 130,
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: true,
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('End date')}
        </Text>
      ),
      width: 120,
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: true,
    },
  ];

  let tableRows = [];

  for (const employment of employments) {
    const { partner } = employment;
    const location = employment.location?.name;
    const position = employment.position?.name;
    const startDate = employment.start_date;
    const endDate = employment.end_date;

    const row = {
      key: employment.id,
      worker: {
        id: partner.id,
        name: `${partner.first_name} ${partner.last_name}`,
        imgSrc: partner.image,
      },
      mobile: partner.mobile,
      location: (
        <Tooltip title={location} placement="right">
          {`${location.substring(0, 20)}`}
          {location?.length > 20 && `...`}
        </Tooltip>
      ),
      position,
      startDate: moment(startDate).format(DATE_FORMAT),
      endDate: endDate ? moment(endDate).format(DATE_FORMAT) : '-',
    };

    tableRows = [...tableRows, row];
  }

  return (
    <Table
      indentSize={0}
      loading={loading}
      columns={tableColumns}
      dataSource={tableRows}
      rowKey="key"
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys: selectedEmployments,
        onChange: selectedRowKeys => onEmploymentsSelectedChange(selectedRowKeys),
      }}
      pagination={{
        defaultPageSize,
        current: page,
        total: totalEmploymentsCount,
        showTotal: (total, range) => `${range[0]}-${range[1]} ${t('of')} ${total} ${t('items')}`,
        style: { margin: '24px 0px' },
        hideOnSinglePage: true,
        showSizeChanger: true,
      }}
      onChange={onTableChange}
      data-testid={testId}
      size="middle"
    />
  );
};

export default withTranslation()(EndingEmploymentsTable);
