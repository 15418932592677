import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, Typography, Button, Col, Row, Radio, DatePicker } from 'antd';
import { colors } from '../../../styles/colors';
import { DATE_FORMAT } from '../../../constants';

const { Title, Paragraph, Text } = Typography;
const NEW_END_DATE_OPTION_KEY = 1;
const NO_END_DATE_OPTION_KEY = 2;

const ExtendEmploymentModal = ({ t, confirmLoading, visible, closeModal, endDate, onConfirm }) => {
  const [selectedEndDate, setEndDate] = useState(undefined);
  const [selectedOption, setOption] = useState(undefined);

  const onRadioChange = ({ target }) => {
    const { value } = target;
    setOption(value);
  };

  const onDatePickerChange = (date, datestring) => {
    setEndDate(datestring);
  };

  const onSubmit = () => {
    /* If no end date option is selected, set new end date as null.
       If new end date option is selected but there is no datepicker selection, use latest end date as default.
    */
    const newEndDate = selectedOption === NEW_END_DATE_OPTION_KEY ? selectedEndDate || endDate : null;
    onConfirm(newEndDate);
  };

  return (
    <Modal
      destroyOnClose
      visible={visible}
      onCancel={() => {
        closeModal();
      }}
      title={<Title level={4}>{t('extendEmployment')}</Title>}
      confirmLoading={confirmLoading}
      bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
      footer={[
        <Button key="close" onClick={closeModal}>
          {t('Close')}
        </Button>,
        <Button
          key="submit"
          disabled={!selectedOption}
          loading={confirmLoading}
          onClick={onSubmit}
          style={{
            backgroundColor: selectedOption && colors.brandGreen,
            color: selectedOption && colors.white,
            fontWeight: 600,
          }}
        >
          {t('Save')}
        </Button>,
      ]}
    >
      <Col style={{ paddingRight: 24, paddingLeft: 24 }}>
        <Typography>
          <Paragraph>{t('extendEmploymentMessage')}</Paragraph>
        </Typography>
        <Radio.Group
          onChange={onRadioChange}
          value={selectedOption}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Col style={{ padding: '0px' }}>
            <Row>
              <Radio value={NEW_END_DATE_OPTION_KEY}>
                <Text strong style={{ color: colors.black }}>
                  {t('setNewEndDate')}
                </Text>
              </Radio>
            </Row>
            <DatePicker
              style={{ width: '260px', margin: '8px 0px 16px 24px' }}
              defaultValue={endDate}
              onChange={onDatePickerChange}
              format={DATE_FORMAT}
              disabledDate={current => current < endDate}
            />
          </Col>
          <Col style={{ padding: '0px' }}>
            <Row>
              <Radio value={NO_END_DATE_OPTION_KEY}>
                <Text strong style={{ color: colors.black }}>
                  {t('noEndDate')}
                </Text>
              </Radio>
            </Row>
          </Col>
          <Typography>
            <Paragraph style={{ marginLeft: '24px' }}>{t('noEndDateMessage')}</Paragraph>
          </Typography>
        </Radio.Group>
      </Col>
    </Modal>
  );
};

export default withTranslation()(ExtendEmploymentModal);
