import React, { Component } from 'react';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import { Table, Typography, Row, Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
// import { VTComponents, VTScroll } from 'virtualizedtableforantd4';
import { isEqual } from 'lodash';

import shiftUtils from '../../utilities/shiftUtils';
import {
  attendanceStatuses,
  // SHIFTS_ASSIGNMENT_TABLE_VT_ID,
  // SHIFTS_SCHEDULE_TABLE_VT_ID,
  WEEKLY_DATE_RANGE,
} from '../../constants';
import { colors } from '../../styles/colors';
import EditShiftFormModal from './components/EditShiftFormModal';
import ShiftsTableCell from './components/ShiftsTableCell';
import DeleteShiftFormModal from './components/DeleteShiftFormModal';
import EditScheduleFormModal from './components/EditScheduleFormModal';
import datetimeUtils from '../../utilities/datetimeUtils';
import scheduleUtils from '../../utilities/scheduleUtils';

const { Text } = Typography;

class ScheduleListView extends Component {
  state = {
    loading: false,
    selectedShift: undefined,
    scheduleData: [],
    editShiftFormModalVisible: false,
    deleteShiftModalVisible: false,
    editScheduleFormModalVisible: false,
  };

  componentDidMount() {
    this.getTableData();
  }

  componentDidUpdate(prevProp) {
    if (!isEqual(prevProp.schedules, this.props.schedules) || !isEqual(prevProp.shifts, this.props.shifts)) {
      this.getTableData();
    }
  }

  getTableData = () => {
    const { schedules, shifts, dayDates } = this.props;
    this.setState({ loading: true });
    if (!schedules || !shifts) {
      return [];
    }

    const scheduleData = schedules.map(schedule => {
      const shiftData = {};
      const scheduleShifts = shifts.filter(shift => shift.schedule.id === schedule.id);
      if (scheduleShifts.length > 0) {
        dayDates.forEach(momentDateObj => {
          const shiftForTheDay = shiftUtils.getMatchingShiftForTheDay(scheduleShifts, momentDateObj);
          if (shiftForTheDay) {
            shiftData[moment(momentDateObj).format('DD MMM')] = shiftForTheDay;
          }
        });
      }
      // TODO: use start_date, duration and recurrences!!
      return {
        id: schedule.id,
        name: schedule.name,
        recurrences: schedule.recurrences,
        isGig: schedule.isGig,
        ...shiftData,
      };
    });
    this.setState({ scheduleData, loading: false });
  };

  getShiftFulfillment = shift => {
    const shiftAttendancesCount = shift?.attendances?.reduce((count, attendance) => {
      if (attendance.status === attendanceStatuses.ASSIGNED) return count + 1;
      return count;
    }, 0);
    if (shift.staff_required) {
      return (
        <div>
          <Text type={shiftAttendancesCount < shift.staff_required && 'danger'} style={{ paddingRight: 5 }}>
            {shiftAttendancesCount}
          </Text>
          <Text>/ {shift.staff_required}</Text>
        </div>
      );
    }
    return <div>{shiftAttendancesCount} / - </div>;
  };

  handleEditSchedule = record => {
    const { schedules, shifts, timezone } = this.props;
    this.setState({
      editScheduleFormModalVisible: true,
      selectedShift: shifts.find(sh => sh.schedule.id === record.id),
      selectedSchedule: schedules.find(sch => sch.id === record.id),
      selectedDate: datetimeUtils.getDayStart(moment(), timezone),
    });
  };

  render() {
    const {
      loading: partnerLoading,
      dayDates,
      schedules,
      onScheduleUpdate,
      shifts,
      timezone,
      selectedDateRange,
    } = this.props;
    const {
      loading,
      editShiftFormModalVisible,
      editScheduleFormModalVisible,
      selectedShift,
      selectedSchedule,
      scheduleData,
      selectedDate,
      deleteShiftModalVisible,
    } = this.state;

    const isWeekly = selectedDateRange === WEEKLY_DATE_RANGE;

    const tableColumns = [
      {
        title: <Text strong />,
        width: isWeekly ? 100 : 280,
        fixed: isWeekly ? null : 'left',
        render: record => (
          <>
            <Row>
              <Text type="primary" style={{ fontWeight: 600 }}>
                {record.name}
              </Text>
            </Row>
            <Row>
              <Button
                style={{ color: colors.black, marginTop: '8px' }}
                icon={<SettingOutlined />}
                size="small"
                onClick={() => {
                  this.handleEditSchedule(record);
                }}
                disabled={
                  record.isGig ||
                  datetimeUtils.getDayStart(moment(), timezone) >
                    moment(scheduleUtils.getEndDateFromRecurrences(record.recurrences))
                }
              />
            </Row>
          </>
        ),
      },
      ...dayDates.map(momentDateObj => {
        const isPastShift = momentDateObj.isBefore(moment(), 'day');
        return {
          title: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ color: moment(momentDateObj).isSame(moment(), 'day') && colors.functionalSuccess }}>
                {moment(momentDateObj).format('ddd')}
              </Text>
              <Text style={{ color: moment(momentDateObj).isSame(moment(), 'day') && colors.functionalSuccess }} strong>
                {moment(momentDateObj).format('DD MMM')}
              </Text>
            </div>
          ),
          width: isWeekly ? 50 : 135,
          dataIndex: moment(momentDateObj).format('DD MMM'),
          render: (shift, schedule) => ({
            props: { style: { padding: 0 } },
            children: (
              <>
                {shift ? (
                  <ShiftsTableCell
                    isPastShift={isPastShift}
                    shift={shift}
                    timezone={timezone}
                    onClick={() => {
                      if (isPastShift || schedule.isGig) return;
                      this.setState({
                        editShiftFormModalVisible: true,
                        selectedShift: shift,
                        selectedSchedule: schedules.find(sch => sch.id === schedule.id),
                        selectedDate: momentDateObj,
                      });
                    }}
                  />
                ) : (
                  <div style={{ width: 50 }} />
                )}
              </>
            ),
          }),
        };
      }),
    ];

    return (
      <>
        <Table
          className="schedules-list-table"
          pagination={false}
          loading={loading || partnerLoading}
          columns={tableColumns}
          dataSource={scheduleData}
          rowKey="id"
          style={{ marginBottom: '64px' }}
          size="middle"
          scroll={{ y: 400, x: isWeekly ? 0 : 1500 }}
          // components={VTComponents({
          //   id: SHIFTS_SCHEDULE_TABLE_VT_ID,
          //   overscanRowCount: 3,
          //   onScroll: ({ left }) => {
          //     VTScroll(SHIFTS_ASSIGNMENT_TABLE_VT_ID, { left });
          //   },
          // })}
        />
        {selectedShift && selectedSchedule && (
          <EditShiftFormModal
            key={selectedShift.id}
            shift={selectedShift}
            schedule={selectedSchedule}
            timezone={timezone}
            date={selectedDate}
            visible={editShiftFormModalVisible}
            onCancel={() => this.setState({ editShiftFormModalVisible: false })}
            onUpdate={() => {
              this.setState({ editShiftFormModalVisible: false });
              onScheduleUpdate();
            }}
            openDeleteShiftModal={() =>
              this.setState({ editShiftFormModalVisible: false, deleteShiftModalVisible: true })
            }
          />
        )}
        {selectedShift && selectedSchedule && (
          <EditScheduleFormModal
            key={selectedSchedule.id}
            shift={selectedShift}
            schedule={selectedSchedule}
            timezone={timezone}
            date={selectedDate}
            visible={editScheduleFormModalVisible}
            onCancel={() => this.setState({ editScheduleFormModalVisible: false })}
            onUpdate={() => {
              this.setState({ editScheduleFormModalVisible: false });
              onScheduleUpdate();
            }}
            openDeleteShiftModal={() =>
              this.setState({ editScheduleFormModalVisible: false, deleteShiftModalVisible: true })
            }
          />
        )}
        {selectedShift && (
          <DeleteShiftFormModal
            key={`d-${selectedShift.id}`}
            shift={selectedShift}
            shifts={shifts}
            schedule={selectedSchedule}
            timezone={timezone}
            date={selectedDate}
            visible={deleteShiftModalVisible}
            onCancel={() => this.setState({ deleteShiftModalVisible: false })}
            onUpdate={() => {
              this.setState({ deleteShiftModalVisible: false });
              onScheduleUpdate();
            }}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(ScheduleListView);
