import React from 'react';
import { EnvironmentTwoTone, CalendarTwoTone } from '@ant-design/icons';
import { Row, Card, Typography, Spin, Col } from 'antd';
import { withTranslation } from 'react-i18next';

import { colors } from '../../../styles/colors';

const { Title, Text } = Typography;

const CardWithStats = ({ loading = false, statsList }) => {
  return (
    <Card
      style={{
        boxShadow: '0 2px 6px #00000026',
        height: '96px',
      }}
      bodyStyle={{ padding: '22px 24px' }}
    >
      <Row type="flex" gutter={36}>
        {statsList.map((stats, index) => {
          const colSpan = 24 / statsList.length;
          // Last stats would not have a border to its right
          const borderStyle =
            index === statsList.length - 1 ? {} : { borderRight: `1px solid ${colors.componentBorder}` };
          return (
            <Col key={index} span={colSpan} style={{ ...borderStyle, padding: '0 24px' }}>
              <Row>
                <Text type="secondary" style={{ fontSize: 12 }}>
                  {stats.title}
                </Text>
              </Row>
              <Row>
                <Title level={3} style={{ margin: 0, color: stats.color }}>
                  {loading ? <Spin /> : stats.num}
                </Title>
              </Row>
            </Col>
          );
        })}
      </Row>
    </Card>
  );
};

export default withTranslation()(
  ({
    loading,
    t,
    employmentCount,
    confirmationRate,
    unconfirmedWorkerCount,
    absencesCount,
    unfilledShiftCount,
    position,
    location,
    scheduleCount,
  }) => {
    const stats = [
      { title: t('workerHired'), num: employmentCount, color: 'initial' },
      {
        title: t('unfilledShifts'),
        num: unfilledShiftCount,
        color: unfilledShiftCount > 0 ? colors.yellow : 'initial',
      },
      {
        title: t('unconfirmed'),
        num: unconfirmedWorkerCount,
        color: unconfirmedWorkerCount > 0 ? colors.yellow : 'initial',
      },
      { title: t('confirmationRate'), num: `${confirmationRate}%`, color: 'initial' },
      {
        title: t('leave'),
        num: absencesCount,
        color: absencesCount > 0 ? colors.yellow : 'initial',
      },
    ];

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Card bordered={false} bodyStyle={{ padding: 0 }}>
          <Title level={2} style={{ marginTop: 8 }}>
            {position.name}
          </Title>
          <Row style={{ marginBottom: 4 }}>
            <EnvironmentTwoTone twoToneColor={colors.disabled} style={{ marginRight: 10 }} />
            <Text>{location.name}</Text>
          </Row>
          <Row style={{ marginBottom: 4 }}>
            <CalendarTwoTone twoToneColor={colors.disabled} style={{ marginRight: 10 }} />
            <Text>{t('shiftScheduleCount', { number: scheduleCount })}</Text>
          </Row>
        </Card>

        <CardWithStats loading={loading} statsList={stats} />
      </div>
    );
  },
);
