import React from 'react';
import { cardTypes } from '../../constants';
import visaLogo from '../../assets/images/visa_logo.svg';
import mastercardLogo from '../../assets/images/mastercard_logo.svg';

const CreditCardBrandImg = ({ brand, style }) => {
  let source;

  switch (brand) {
    case cardTypes.MASTERCARD:
      source = mastercardLogo;
      break;
    case cardTypes.VISA:
      source = visaLogo;
      break;
    default:
      source = visaLogo;
  }

  return <img src={source} alt="Credit card logo" style={style} />;
};

export default CreditCardBrandImg;
