import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Typography, Col, Card } from 'antd';
import ToolTipWithInfo from '../../../shared/components/ToolTipWithInfo';
import CubeJsQueryRenderer from '../../../shared/components/CubeJsQueryRenderer';
import { colors } from '../../../styles/colors';

const { Text } = Typography;

class FulfilmentRateCard extends React.Component {
  formatQueryData = () => {
    const { positionIds, locationIds, timePeriod, clientId } = this.props;
    const query = {
      timeDimensions: [
        {
          dimension: 'SchedulingShift.startTime',
          dateRange: timePeriod,
        },
      ],
      filters: [
        {
          member: 'ClientsClient.id',
          operator: 'equals',
          values: [clientId.toString()],
        },
      ],
      measures: ['FulfilmentRate.fulfilmentRate'],
    };
    if (positionIds && positionIds.length > 0) {
      query.filters.push({
        member: 'PartnersPosition.id',
        operator: 'equals',
        values: positionIds,
      });
    }
    if (locationIds && locationIds.length > 0) {
      query.filters.push({
        member: 'ClientsLocation.id',
        operator: 'equals',
        values: locationIds,
      });
    }
    return query;
  };

  numberRender = ({ resultSet }) => {
    const { t } = this.props;
    const fulfilmentRate = resultSet && Math.round(Object.values(resultSet.loadResponses[0].data[0])[0]);
    return (
      <Card style={{ width: '100%' }} bodyStyle={{ margin: '24px 16px', padding: 0 }}>
        <Row justify="center">
          <Col>
            <Text strong style={{ fontSize: '38px' }}>
              {`${fulfilmentRate}%`}
            </Text>
          </Col>
        </Row>
        <Row justify="center" align="center">
          <Col>
            <Text strong type="secondary" style={{ fontSize: '12px', paddingRight: '8px' }}>
              {t('fulfilment rate').toUpperCase()}
            </Text>
            <ToolTipWithInfo infoText={t('fulfilmentRateTooltip')} color={colors.blue} />
          </Col>
        </Row>
      </Card>
    );
  };

  render() {
    const fulfilmentQuery = this.formatQueryData();

    return (
      <CubeJsQueryRenderer
        measures={fulfilmentQuery.measures}
        timeDimensions={fulfilmentQuery.timeDimensions}
        filters={fulfilmentQuery.filters}
        chartFunction={this.numberRender}
      />
    );
  }
}

export default withTranslation()(FulfilmentRateCard);
