import React from 'react';
import { Card, Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';

import partnerApi from '../../services/partnerApi';
import employmentApi from '../../services/employmentApi';
import workerUtils from '../../utilities/workerUtils';
import ConfettiLogo from '../../assets/images/confetti.svg';

import ProfileCard from './components/ProfileCard';
import VaccinationDetailCard from './components/VaccinationDetailCard';
import AddressDetailCard from './components/AddressDetailCard';
import DetailCard from '../../shared/components/DetailCard';
import EmptyCard from '../../shared/components/EmptyCard';
import ListWithShowMore from '../../shared/components/ListWithShowMore';
import StatusTag from '../../shared/components/StatusTag';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { checkAccess } from '../../shared/access/Access';
import { permissions } from '../../shared/access/accessConfig';
import {
  employmentStatuses,
  partnerSourceTypes,
  workerCovidCertificationStatuses,
  countryCodeOptions,
} from '../../constants';

class WorkerProfileView extends React.Component {
  state = {
    worker: undefined,
    workerStatistics: undefined,
    employments: undefined,
    isLoading: false,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { workerId } = this.props;
    const worker = await partnerApi.fetchPartnerById(workerId);
    const employments = (
      await employmentApi.getVerifiedExperience({
        partner: workerId,
        status: employmentStatuses.ENDED,
        ordering: '-end_date',
        page: 1,
      })
    ).results;

    const [workerStatistics] = (await partnerApi.getStatistics({ partner: workerId })).results;

    this.setState({ worker, workerStatistics, employments, isLoading: false });
  }

  commaSeparatedValuesToTags = values => {
    const interests = values.split(',');
    const tagInterests = interests.map((interest, index) => <StatusTag key={index} status={interest} />);
    return tagInterests;
  };

  // Checks all experiences & education of worker to see if there are any data
  workerHasExperience = experiencesAndEducation => {
    for (let i = 0; i < experiencesAndEducation.length; i += 1) {
      const exp = experiencesAndEducation[i];
      if (exp.dataList.length > 0) {
        return true;
      }
    }
    return false;
  };

  render() {
    const { isLoading, worker, workerStatistics, employments } = this.state;
    const { t } = this.props;
    if (isLoading || !worker) {
      return <LoadingSpinner />;
    }
    const shouldHideDetails = worker?.country.code === countryCodeOptions.SINGAPORE;
    const cleanedWorker = workerUtils.cleanProfile(worker, t);
    const workInterests = workerUtils.getWorkInterests(worker, t);
    const workerDetails = workerUtils.getWorkDetails(worker, t);
    const experienceAndEducation = workerUtils.getExperienceAndEducation(worker, employments, t);
    const workerIdentification = workerUtils.getWorkerIdentification(worker, t, shouldHideDetails);
    const workerBankDetails = workerUtils.getWorkerBankDetails(worker, t, shouldHideDetails);
    const workerAccountDetails = workerUtils.getWorkerAccountDetails(worker, t);

    const daysWorked = workerStatistics?.total_days_worked;
    const workerCovidCertificationImage = worker.covid_certification_image;
    const workerCovidCertificationStatus = worker.covid_certification_status;
    const isVaccinated = workerCovidCertificationStatus === workerCovidCertificationStatuses.VERIFIED;
    const isDirectJobInvite = worker.source === partnerSourceTypes.DIRECT_JOB_INVITE;
    return (
      <Row type="flex" gutter={50}>
        <Col xs={24} sm={8}>
          <Row style={{ marginBottom: '48px' }}>
            <ProfileCard
              worker={cleanedWorker}
              daysWorked={daysWorked}
              isDirectJobInvite={isDirectJobInvite}
              shouldHideDetails={shouldHideDetails}
            />
          </Row>

          {checkAccess(permissions.workerProfileViewDetails) && (
            <>
              <Row style={{ marginBottom: '24px', borderTop: '1px solid #00000017' }}>
                <DetailCard headerTitle={t('identification')} contentList={workerIdentification} />
              </Row>

              {isVaccinated && (
                <Row style={{ marginBottom: '24px', borderTop: '1px solid #00000017' }}>
                  <VaccinationDetailCard covidCertificationImage={workerCovidCertificationImage} />
                </Row>
              )}

              <Row style={{ marginBottom: '24px', borderTop: '1px solid #00000017' }}>
                <DetailCard headerTitle={t('bankingDetails')} contentList={workerBankDetails} />
              </Row>

              {worker.address && !shouldHideDetails && (
                <Row style={{ marginBottom: '24px', borderTop: '1px solid #00000017' }}>
                  <AddressDetailCard address={worker.address} />
                </Row>
              )}
            </>
          )}

          <Row style={{ marginBottom: '24px', borderTop: '1px solid #00000017' }}>
            <DetailCard
              headerTitle={t('workInterests')}
              contentList={workInterests.map(interest => ({
                title: interest.title,
                value: this.commaSeparatedValuesToTags(interest.value),
              }))}
            />
          </Row>

          <Row style={{ marginBottom: '24px', borderTop: '1px solid #00000017' }}>
            <DetailCard headerTitle={t('Work details')} contentList={workerDetails} />
          </Row>

          {checkAccess(permissions.workerProfileViewDetails) && (
            <Row style={{ marginBottom: '24px', borderTop: '1px solid #00000017' }}>
              <DetailCard headerTitle={t('accountInformation')} contentList={workerAccountDetails} />
            </Row>
          )}
        </Col>

        <Col xs={24} sm={16}>
          {this.workerHasExperience(experienceAndEducation) ? (
            <Card bodyStyle={{ padding: 0 }} bordered={false}>
              {experienceAndEducation.length > 0 &&
                experienceAndEducation.map((experience, index) => {
                  if (experience.dataList.length === 0) return null;
                  return (
                    <ListWithShowMore
                      key={index}
                      header={t(experience.header)}
                      dataList={experience.dataList}
                      minimumLength={4}
                    />
                  );
                })}
            </Card>
          ) : (
            <Row type="flex" align="middle" justify="center" style={{ height: '324px' }}>
              <EmptyCard title={t(`I'm a new Workmate!`)} description="" imgSrc={ConfettiLogo} />
            </Row>
          )}
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(WorkerProfileView);
