// Misc functions
export const environment = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
  DEMO: 'demo',
};

export const getEnvironment = () => {
  const hostname = window && window.location && window.location.hostname;
  if (hostname === 'portal.helpster.asia' || hostname === 'dashboard.workmate.asia') {
    return environment.PRODUCTION;
  }
  if (hostname === 'portal.helpster.tech') {
    return environment.STAGING;
  }
  if (hostname === 'demo.helpster.tech' || hostname === 'demo.workmate.asia') {
    return environment.DEMO;
  }
  return environment.DEVELOPMENT;
};

export const getPaginationConfig = (total, onPageChange) => {
  return {
    total,
    onChange: onPageChange,
    pageSize: 20,
    position: 'bottom',
    size: 'large',
    showQuickJumper: true,
  };
};

export const createIntArray = length => {
  const arr = [];
  for (let i = 0; i < length; i += 1) {
    arr.push(i);
  }
  return arr;
};
