import moment from 'moment';
import numeral from 'numeral';
import i18next from 'i18next';

const MINUTES_IN_HOUR = 60;

export const calculateHoursFromDuration = rawDuration => {
  return (
    rawDuration &&
    moment
      .duration(rawDuration)
      .asHours()
      .toFixed(2)
  );
};

export const getFormattedDurationInHours = rawDuration => {
  const hours = calculateHoursFromDuration(rawDuration);
  return `${numeral(hours).format('0[.]0')} ${hours === 1 ? i18next.t('hour') : i18next.t('hours')}`;
};

export const getDurationFromSecondsToHours = rawDuration => {
  const hours = moment.duration(rawDuration, 'seconds').asHours();
  return `${numeral(hours).format('0[.]0')} ${hours === 1 ? i18next.t('hour') : i18next.t('hours')}`;
};

export const getFormattedDurationInHoursAndMinutes = rawDurationInMinutes => {
  if (rawDurationInMinutes === 0) {
    return '-';
  }

  const hours = Math.floor(rawDurationInMinutes / MINUTES_IN_HOUR);
  const minutes = Math.floor(rawDurationInMinutes % MINUTES_IN_HOUR);

  return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
};

export const getTimeAgo = date => {
  moment.relativeTimeThreshold('d', 7); // set minimum 7 days to be a week
  moment.relativeTimeThreshold('w', 4); // set minimum 4 weeks to be a month
  moment.relativeTimeThreshold('M', 12); // set minimum 12 months to be a year

  return moment(date).fromNow();
};
