/* eslint-disable no-return-await */
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Row, Typography, Table } from 'antd';
import cubejs from '@cubejs-client/core';

import { cubeJsURL } from '../../../config';
import { colors } from '../../../styles/colors';

const { Text } = Typography;

const DEFAULT_PAGE_SIZE = 20;

class WorkerReportTable extends React.Component {
  state = {
    workerData: undefined,
    loading: false,
    total: undefined,
    defaultPageSize: DEFAULT_PAGE_SIZE,
  };

  async componentDidMount() {
    await this.getWorkerData();
  }

  async componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      await this.getWorkerData();
    }
  }

  getWorkerData = async () => {
    this.setState({ loading: true });
    const additionalQuery = this.formatQueryData();
    await this.handleCubejsQuery(additionalQuery);
    this.setState({ loading: false });
  };

  handleCubejsQuery = async additionalQuery => {
    const { cubeJsToken } = this.props;
    if (cubeJsToken) {
      const cubejsApi = cubejs(cubeJsToken, {
        apiUrl: cubeJsURL,
      });
      await cubejsApi.load(additionalQuery).then(resultSet => {
        const dataOutput = resultSet.tablePivot({
          x: ['PartnersPartner.fullName', 'ClientsLocation.name', 'PartnersPosition.name'],
          y: ['measures'],
          fillMissingDates: true,
          joinDateRange: false,
        });
        this.setState({ workerData: dataOutput, total: resultSet?.loadResponse?.results[0]?.data?.length });
      });
    }
  };

  formatQueryData = () => {
    const { positionIds, locationIds, timePeriod, clientId } = this.props;
    const { ordering } = this.state;
    const updatedTimePeriod = [...timePeriod];
    // Remove future data e.g. assigned
    if (moment(updatedTimePeriod[1]).isSame(moment(), 'day') || moment(updatedTimePeriod[1]).isAfter(moment(), 'day')) {
      updatedTimePeriod[1] = moment()
        .subtract(1, 'day')
        .format();
    }

    const query = {
      dimensions: [
        'PartnersPartner.id',
        'PartnersPartner.fullName',
        'ClientsLocation.id',
        'ClientsLocation.name',
        'PartnersPosition.id',
        'PartnersPosition.name',
      ],
      timeDimensions: [
        {
          dimension: 'SchedulingShift.startTime',
          dateRange: updatedTimePeriod,
        },
      ],
      filters: [
        {
          member: 'ClientsClient.id',
          operator: 'equals',
          values: [clientId.toString()],
        },
      ],
      measures: [
        'SchedulingAttendance.assignedCount',
        'ClockingTimesheetentry.count',
        'SchedulingAttendance.medicalLeaveCount',
        'SchedulingAttendance.personalLeaveCount',
        'SchedulingAttendance.noShowCount',
        'ClockingTimesheetentry.lateCount',
        'ClockingTimesheetentry.overtimeTotal',
      ],
      order: {},
    };
    if (positionIds && positionIds.length > 0) {
      query.filters.push({
        member: 'PartnersPosition.id',
        operator: 'equals',
        values: positionIds,
      });
    }
    if (locationIds && locationIds.length > 0) {
      query.filters.push({
        member: 'ClientsLocation.id',
        operator: 'equals',
        values: locationIds,
      });
    }
    if (ordering) {
      const { field, sortOrder } = ordering;
      query.order = {
        [field]: sortOrder,
      };
    }
    return query;
  };

  getOrdering = sorter => {
    const { field, order } = sorter;
    let sortOrder;
    if (order && order === 'ascend') {
      sortOrder = 'asc';
    }
    if (order && order === 'descend') {
      sortOrder = 'desc';
    }
    return { field, sortOrder };
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { pageSize } = pagination;
    const ordering = this.getOrdering(sorter);

    this.setState(
      {
        defaultPageSize: pageSize,
        ordering,
      },
      async () => await this.getWorkerData(),
    );
  };

  render() {
    const { workerData, loading, total, defaultPageSize } = this.state;
    const { t } = this.props;

    const tableColumns = [
      {
        title: (
          <Text strong style={{ color: colors.black }}>
            {t('Name')}
          </Text>
        ),
        width: 200,
        dataIndex: 'PartnersPartner.fullName',
        key: 'name',
        sorter: true,
        fixed: 'left',
      },
      {
        title: (
          <Text strong style={{ color: colors.black }}>
            {t('Location')}
          </Text>
        ),
        width: 180,
        dataIndex: 'ClientsLocation.name',
        key: 'location',
        sorter: true,
      },
      {
        title: (
          <Text strong style={{ color: colors.black }}>
            {t('Position')}
          </Text>
        ),
        width: 180,
        dataIndex: 'PartnersPosition.name',
        key: 'position',
        sorter: true,
      },
      {
        title: (
          <Text strong style={{ color: colors.black }}>
            {t('totalShiftsAssigned')}
          </Text>
        ),
        width: 140,
        dataIndex: 'SchedulingAttendance.assignedCount',
        key: 'assigned',
        sorter: true,
      },
      {
        title: (
          <Text strong style={{ color: colors.black }}>
            {t('totalShiftsWorked')}
          </Text>
        ),
        width: 120,
        dataIndex: 'ClockingTimesheetentry.count',
        key: 'worked',
        sorter: true,
      },
      {
        title: (
          <Text strong style={{ color: colors.black }}>
            {t('totalSickDays')}
          </Text>
        ),
        width: 120,
        dataIndex: 'SchedulingAttendance.medicalLeaveCount',
        key: 'sick',
        sorter: true,
      },
      {
        title: (
          <Text strong style={{ color: colors.black }}>
            {t('totalLeaveCount')}
          </Text>
        ),
        width: 120,
        dataIndex: 'SchedulingAttendance.personalLeaveCount',
        key: 'leave',
        sorter: true,
      },
      {
        title: (
          <Text strong style={{ color: colors.black }}>
            {t('totalNoShows')}
          </Text>
        ),
        width: 120,
        dataIndex: 'SchedulingAttendance.noShowCount',
        key: 'noShow',
        sorter: true,
      },
      {
        title: (
          <Text strong style={{ color: colors.black }}>
            {t('totalLateCount')}
          </Text>
        ),
        width: 120,
        dataIndex: 'ClockingTimesheetentry.lateCount',
        key: 'late',
        sorter: true,
      },
      {
        title: (
          <Text strong style={{ color: colors.black }}>
            {t('totalOvertimeHours')}
          </Text>
        ),
        width: 140,
        dataIndex: 'ClockingTimesheetentry.overtimeTotal',
        key: 'overtime',
        sorter: true,
      },
    ];
    return (
      <Row style={{ paddingTop: '16px', paddingBottom: '50px' }}>
        <Table
          columns={tableColumns}
          dataSource={workerData}
          loading={loading}
          pagination={{
            defaultPageSize,
            showSizeChanger: true,
            total,
            // eslint-disable-next-line no-shadow
            showTotal: total => `Total ${total} items`,
            pageSizeOptions: ['20', '50', '100'],
            style: { margin: '24px 0px' },
          }}
          onChange={this.handleTableChange}
          rowKey={row => `${row['PartnersPartner.id']}${row['ClientsLocation.id']}${row['PartnersPosition.id']}`}
          scroll={{ x: 'max-content' }}
          size="middle"
        />
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  cubeJsToken: state.user.cubeJsToken,
});

export default connect(mapStateToProps, null)(withTranslation()(WorkerReportTable));
