import React from 'react';
import { connect } from 'react-redux';
import cubejs from '@cubejs-client/core';
import { withTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import { Row, Typography, Card, Empty, Col } from 'antd';
import moment from 'moment-timezone';
import { cubeJsURL } from '../../../config';
import ToolTipWithInfo from '../../../shared/components/ToolTipWithInfo';
import CubeJsQueryRenderer from '../../../shared/components/CubeJsQueryRenderer';
import { colors } from '../../../styles/colors';
import { getNumberAbbreviation, getLabelColors } from '../../../utilities/analyticsUtils';

const { Text } = Typography;

class CostTypeBreakdownChart extends React.Component {
  state = {
    additionalApprovedDataset: undefined,
    additionalEstimatedDataset: undefined,
    additionalQueryComplete: false,
  };

  async componentDidMount() {
    await this.getAdditionalQuery();
  }

  async componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      await this.getAdditionalQuery();
    }
  }

  getAdditionalQuery = async () => {
    const { timePeriod, clientId, positionIds, locationIds, granularity } = this.props;
    this.setState({ additionalQueryComplete: false });
    const additionalApprovedQuery = this.formatQueryData(
      timePeriod,
      clientId,
      positionIds,
      locationIds,
      granularity,
      'PaymentsPaymentitem.date',
    );
    const additionalEstimatedQuery = this.formatQueryData(
      timePeriod,
      clientId,
      positionIds,
      locationIds,
      granularity,
      'ClockingTimesheetentry.clockInTime',
    );
    await this.additionalTotalApprovedCostQuery(additionalApprovedQuery);
    await this.additionalTotalEstimatedCostQuery(additionalEstimatedQuery);
    this.setState({ additionalQueryComplete: true });
  };

  formatQueryData = (timePeriod, clientId, positionIds, locationIds, granularity, timeDimension) => {
    const query = {
      filters: [
        {
          member: 'ClientsClient.id',
          operator: 'equals',
          values: [clientId.toString()],
        },
      ],
      timeDimensions: [
        {
          dimension: timeDimension,
          dateRange: timePeriod,
          granularity,
        },
      ],
    };
    if (positionIds && positionIds.length > 0) {
      query.filters.push({
        member: 'PartnersPosition.id',
        operator: 'equals',
        values: positionIds,
      });
    }
    if (locationIds && locationIds.length > 0) {
      query.filters.push({
        member: 'ClientsLocation.id',
        operator: 'equals',
        values: locationIds,
      });
    }
    return query;
  };

  additionalTotalApprovedCostQuery = async additionalQuery => {
    const { t, cubeJsToken } = this.props;
    if (cubeJsToken) {
      const cubejsApi = cubejs(cubeJsToken, {
        apiUrl: cubeJsURL,
      });
      await cubejsApi
        .load({
          measures: ['PaymentsPaymentitem.totalExpenses'],
          timeDimensions: additionalQuery.timeDimensions,
          limit: 50000,
          order: {},
          filters: additionalQuery.filters,
          dimensions: [],
          timezone: moment.tz.guess(),
        })
        .then(resultSet => {
          this.createDataSet(resultSet, t('totalApprovedCost'), colors.black);
        });
    }
  };

  createDataSet = (resultSet, label, labelColour, approved = true) => {
    const dataOutput = {
      labels: resultSet.categories().map(c => c.category),
      datasets: resultSet.series().map(s => ({
        label,
        data: s.series.map(r => r.value),
        borderColor: labelColour,
        backgroundColor: labelColour,
        fill: false,
      })),
    };
    if (approved) {
      this.setState({ additionalApprovedDataset: dataOutput });
    } else {
      this.setState({ additionalEstimatedDataset: dataOutput });
    }
  };

  additionalTotalEstimatedCostQuery = async additionalQuery => {
    const { cubeJsToken, t } = this.props;
    if (cubeJsToken) {
      const cubejsApi = cubejs(cubeJsToken, {
        apiUrl: cubeJsURL,
      });
      await cubejsApi
        .load({
          measures: ['ClockingTimesheetentry.estimatedTotalCost'],
          timeDimensions: additionalQuery.timeDimensions,
          limit: 50000,
          order: {},
          filters: additionalQuery.filters,
          dimensions: [],
          timezone: moment.tz.guess(),
        })
        .then(resultSet => {
          this.createDataSet(resultSet, t('totalEstimatedCost'), colors.darkGrey, false);
        });
    }
  };

  barRender = ({ resultSet }) => {
    const { additionalApprovedDataset, additionalEstimatedDataset } = this.state;
    const { t } = this.props;
    const labelColors = getLabelColors(resultSet.series().length);
    const data = {
      labels: resultSet.categories().map(c => moment(c.category).format('ddd, DD MMM')),
      datasets: resultSet.series().map((s, index) => ({
        label: this.formatLabel(s.title.split(',')[0]),
        data: s.series.map(r => r.value),
        borderColor: labelColors[index],
        backgroundColor: labelColors[index],
        fill: false,
      })),
    };
    if (resultSet.loadResponse.results[0].data.length > 0 && additionalApprovedDataset) {
      data.datasets.unshift(additionalApprovedDataset.datasets[0]);
    }
    if (resultSet.loadResponse.results[0].data.length > 0 && additionalEstimatedDataset) {
      data.datasets.unshift(additionalEstimatedDataset.datasets[0]);
    }
    const options = {
      elements: {
        line: {
          tension: 0,
        },
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: t('Date'),
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: value => {
                const tickMark = getNumberAbbreviation(value);
                return tickMark;
              },
            },
            scaleLabel: {
              display: true,
              labelString: t('Cost'),
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          title: tooltipItem => tooltipItem[0].label,
          label: tooltipItem => {
            return `${getNumberAbbreviation(tooltipItem.value)}`;
          },
        },
        backgroundColor: colors.white,
        titleFontColor: colors.black,
        bodyFontColor: colors.black,
        borderColor: colors.grey,
        borderWidth: 0.5,
      },
    };
    const legend = {
      display: true,
      // position: 'right',
      fullWidth: true,
      reverse: false,
    };
    return data.datasets.length > 0 ? <Bar data={data} options={options} legend={legend} /> : <Empty />;
  };

  formatLabel = label => {
    const { t } = this.props;
    const setLabels = {
      base: t('baseCost'),
      meal_allowance: t('mealAllowance'),
      other_allowance: t('otherAllowance'),
      transport_allowance: t('transportAllowance'),
      overtime: t('Overtime'),
      incentive: t('Incentive'),
      late: t('Late'),
      bonus: t('Bonuses'),
      adjustment: t('Adjustment'),
      referral_reward: t('Referral reward'),
    };
    return setLabels[label];
  };

  render() {
    const { timePeriod, clientId, positionIds, locationIds, title, tooltip, granularity, t } = this.props;
    const { additionalQueryComplete } = this.state;
    const formattedQuery = this.formatQueryData(
      timePeriod,
      clientId,
      positionIds,
      locationIds,
      granularity,
      'PaymentsPaymentitem.date',
    );
    return (
      <Card style={{ width: '100%', marginBottom: '32px' }}>
        <Row style={{ padding: '16px' }}>
          <Col span={24}>
            <Row type="flex" justify="space-between" style={{ alignItems: 'center', marginBottom: '24px' }}>
              <Col>
                <Text strong style={{ fontSize: '20px', paddingRight: '8px' }}>
                  {t('costTypeBreakdown')}
                </Text>
                <ToolTipWithInfo infoText={t('costTypeBreakdownTooltip')} color={colors.blue} />
              </Col>
            </Row>
            {additionalQueryComplete && (
              <CubeJsQueryRenderer
                measures={['PaymentsPaymentitem.totalExpenses']}
                timeDimensions={formattedQuery.timeDimensions}
                filters={formattedQuery.filters}
                chartFunction={this.barRender}
                dimensions={['PaymentsPaymentitem.type']}
                title={title}
                tooltip={tooltip}
              />
            )}
          </Col>
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  cubeJsToken: state.user.cubeJsToken,
});

export default connect(mapStateToProps, null)(withTranslation()(CostTypeBreakdownChart));
