import React, { useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Button, Popover, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { choiceTypesWithKeys, createQuestionMoreActionTypes } from '../../../constants';
import { colors } from '../../../styles/colors';
import dotsIcon from '../../../assets/images/dots-horizontal.svg';

const CreateQuestionMoreActions = ({ onChange, selectedMoreActionTypes, choiceType }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  const getMenuItems = () => {
    const items = [
      {
        title: t('createQuestionMoreActionDescription'),
        actionType: createQuestionMoreActionTypes.DESCRIPTION,
      },
      {
        title: t('createQuestionMoreActionAttachImage'),
        actionType: createQuestionMoreActionTypes.ATTACH_IMAGE,
      },
    ];
    // if single select question, add score question menu item
    if (choiceType === choiceTypesWithKeys.SINGLE_SELECT.key) {
      items.push({
        title: t('createQuestionMoreActionScore'),
        actionType: createQuestionMoreActionTypes.SCORE_QUESTION,
      });
    }
    return items;
  };

  const menuItems = getMenuItems();

  const handleChange = menuItem => {
    onChange(menuItem);
    setIsVisible(false);
  };

  const handleVisibleChange = visible => {
    if (!visible) {
      setIsVisible(false);
    }
  };

  return (
    <Popover
      placement="bottomLeft"
      trigger={['click']}
      visible={isVisible}
      onVisibleChange={handleVisibleChange}
      content={
        <Row style={{ flexDirection: 'column' }}>
          {menuItems.map((menuItem, index) => (
            <button
              onClick={() => handleChange(menuItem)}
              type="button"
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
                border: 0,
                cursor: 'pointer',
                color: colors.primaryText,
                padding: '5px 0',
              }}
            >
              <Row style={{ width: 14, marginRight: 10, alignItems: 'center' }}>
                {selectedMoreActionTypes.includes(menuItem.actionType) && <CheckOutlined />}
              </Row>
              <Typography.Text>{menuItem.title}</Typography.Text>
            </button>
          ))}
        </Row>
      }
      overlayClassName="ant-popover-wrapper"
    >
      <Button
        type="text"
        style={{ fontSize: 16, display: 'flex', alignItems: 'center' }}
        onClick={() => setIsVisible(true)}
      >
        <img src={dotsIcon} alt="dots horizontal icon" style={{ width: 20 }} />
      </Button>
    </Popover>
  );
};

export default CreateQuestionMoreActions;
