import React from 'react';
import { Row, Card, Typography, Button } from 'antd';
import { withTranslation } from 'react-i18next';
import Maps from '../../../shared/components/Maps';

const { Paragraph, Text } = Typography;

const AddressDetailCard = ({ address, loading = false, t }) => {
  const { street_1, street_2, area, latitude, longitude } = address;
  const lat = parseFloat(latitude);
  const lng = parseFloat(longitude);
  return (
    <Card loading={loading} bordered={false} style={{ backgroundColor: 'inherit' }} bodyStyle={{ padding: 0 }}>
      <Row style={{ margin: '8px 0 16px 0' }}>
        <Text strong type="secondary" style={{ fontSize: '12px' }}>
          {t('Address').toUpperCase()}
        </Text>
      </Row>
      <Row type="flex" justify="space-between" c>
        <Paragraph style={{ marginBottom: 8 }}>
          {street_1 || street_2 || area ? (
            <>
              {street_1 && <span>{street_1},</span>}
              <br />
              {street_2 && <span>{street_2},</span>}{' '}
              {area && (
                <span>
                  {area.name}, {area.city.name}
                </span>
              )}
            </>
          ) : (
            <Text type="secondary" style={{ color: '#BFBFBF' }}>
              {t('notProvided')}
            </Text>
          )}{' '}
          <br />
          {lat && lng && (
            <Button
              type="link"
              style={{ padding: 0, height: 0 }}
              onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank')}
            >
              {t('openInGoogleMaps')}
            </Button>
          )}
        </Paragraph>
      </Row>
      <Row style={{ marginBottom: '16px' }}>
        <Maps
          centerPosition={{
            lat: lat || 0,
            lng: lng || 0,
          }}
          markerPositions={[
            {
              lat: lat || 0,
              lng: lng || 0,
            },
          ]}
          zoom={15}
          style={{ height: '170px' }}
        />
      </Row>
    </Card>
  );
};

export default withTranslation()(AddressDetailCard);
