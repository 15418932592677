/* eslint-disable dot-notation */
import React from 'react';
import { Pie } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import { Row, Col, Typography, Card, Empty } from 'antd';
import { capitalize } from 'lodash';

import CubeJsQueryRenderer from '../../../shared/components/CubeJsQueryRenderer';
import ToolTipWithInfo from '../../../shared/components/ToolTipWithInfo';
import { colors } from '../../../styles/colors';
import { getLabelColors } from '../../../utilities/analyticsUtils';

const { Text } = Typography;

const formatQueryData = (timePeriod, clientId, positionIds, locationIds) => {
  const query = {
    filters: [
      {
        member: 'ClientsClient.id',
        operator: 'equals',
        values: [clientId.toString()],
      },
    ],
    timeDimensions: [
      {
        dimension: 'RecruitmentEmployment.startDate',
        dateRange: timePeriod,
      },
    ],
  };
  if (positionIds && positionIds.length > 0) {
    query.filters.push({
      member: 'PartnersPosition.id',
      operator: 'equals',
      values: positionIds,
    });
  }
  if (locationIds && locationIds.length > 0) {
    query.filters.push({
      member: 'ClientsLocation.id',
      operator: 'equals',
      values: locationIds,
    });
  }
  return query;
};

// TODO turn comp into stateful
const WorkerRecurringPieChart = ({ timePeriod, clientId, positionIds, locationIds, t }) => {
  const formattedProps = formatQueryData(timePeriod, clientId, positionIds, locationIds);
  const pieRender = ({ resultSet }) => {
    const labelColors = getLabelColors(resultSet.series().length);
    const data = {
      labels: resultSet.categories().map(c => t(capitalize(c.category))),
      datasets: resultSet.series().map(s => ({
        label: s.title,
        data: s.series.map(r => r.value),
        backgroundColor: labelColors,
        hoverBackgroundColor: labelColors,
      })),
    };
    const legend = {
      display: true,
      position: 'right',
      fullWidth: true,
      reverse: false,
    };
    const options = {
      tooltips: {
        callbacks: {
          // eslint-disable-next-line no-shadow
          title: (tooltipItem, data) => data['labels'][tooltipItem[0]['index']],
          // eslint-disable-next-line no-shadow
          label: (tooltipItem, data) => {
            const number = data['datasets'][0]['data'][tooltipItem['index']];
            const dataset = data['datasets'][0];
            const percent = Math.round(
              (dataset['data'][tooltipItem['index']] / Object.values(dataset['_meta'])[0].total) * 100,
            );
            return `${number} (${percent}%)`;
          },
        },
        backgroundColor: colors.white,
        titleFontColor: colors.black,
        bodyFontColor: colors.black,
        borderColor: colors.grey,
        borderWidth: 0.5,
      },
    };
    return data.datasets.length > 0 ? <Pie data={data} options={options} legend={legend} /> : <Empty />;
  };

  return (
    <Card style={{ width: '100%', marginBottom: '16px' }}>
      <Row style={{ padding: '16px' }}>
        <Col span={24}>
          <Row type="flex" justify="space-between" style={{ alignItems: 'center', marginBottom: '24px' }}>
            <Col>
              <Text strong style={{ fontSize: '20px', paddingRight: '8px' }}>
                {t('Workforce breakdown')}
              </Text>
              <ToolTipWithInfo infoText={t('workersWithPriorExperiencePercentage')} color={colors.blue} />
            </Col>
          </Row>
          <CubeJsQueryRenderer
            measures={['PartnersPartner.count']}
            timeDimensions={formattedProps.timeDimensions}
            filters={formattedProps.filters}
            dimensions={['PartnersPartner.newOrRecurring']}
            chartFunction={pieRender}
            pivotConfig={{
              x: ['PartnersPartner.newOrRecurring'],
              y: ['measures'],
              fillMissingDates: true,
              joinDateRange: false,
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(WorkerRecurringPieChart);
