import React from 'react';
import { withTranslation } from 'react-i18next';

const Address = ({ address, t }) => {
  if (!address) {
    return null;
  }

  return (
    <div>
      <div>
        {address.street_1 && <div>{address.street_1},</div>}
        {address.street_2 && <div>{address.street_2},</div>}
        {address.area ? `${address.area?.name}, ${address.area?.city?.name},` : `${address.province},`}{' '}
        {address.zip ? address.zip : ''} {address.country ? address.country.name : ''}
      </div>
      {address.latitude && address.longitude && (
        <a
          style={{ textDecoration: 'underline' }}
          href={`https://www.google.com/maps/search/?api=1&query=${address.latitude},${address.longitude}`}
        >
          {t('openInGoogleMaps')}
        </a>
      )}
    </div>
  );
};
export default withTranslation()(Address);
