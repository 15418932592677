import { Button, Typography, Row } from 'antd';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import checkCircle from '../../../../assets/images/check_circle_success.svg';
import { colors } from '../../../../styles/colors';

const PaymentSuccess = ({ onContinue, estimatedCostElement, amount, isOnlineInvoicePaymentMethod, email }) => {
  const { t } = useTranslation();
  return (
    <Row
      style={{
        margin: '60px auto',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: 480,
      }}
    >
      <img src={checkCircle} alt="success" style={{ width: 100, height: 100, marginBottom: 32 }} />
      <Typography.Paragraph style={{ fontSize: 20, fontWeight: 600, color: colors.primaryText, textAlign: 'center' }}>
        {t('paymentSuccessTitle')}
      </Typography.Paragraph>
      {isOnlineInvoicePaymentMethod ? (
        <Typography.Paragraph style={{ color: colors.primaryText, marginBottom: 28, textAlign: 'center' }}>
          {t('otherPaymentMethodDescription2', { email })}
        </Typography.Paragraph>
      ) : (
        <Typography.Paragraph style={{ color: colors.primaryText, marginBottom: 28, textAlign: 'center' }}>
          <Trans i18nKey="paymentSuccessDesc" values={{ amount }} components={{ strong: <strong /> }} />
        </Typography.Paragraph>
      )}
      <div style={{ width: '100%', marginBottom: 30, marginTop: isOnlineInvoicePaymentMethod ? 28 : 0 }}>
        <div style={{ maxWidth: 600, margin: '0 auto' }}>{estimatedCostElement}</div>
      </div>
      <Button style={{ padding: '0 32px', marginRight: '10px' }} type="v2-primary" onClick={onContinue}>
        {t('viewMyGig')}
      </Button>
    </Row>
  );
};

export default PaymentSuccess;
