import base from './base';
import { endpoints } from '../config';

const getStatistics = async params => {
  const response = await base.api.get(`${endpoints.absenceDetails}/statistics/`, { params });
  return response;
};

export default {
  getStatistics,
};
