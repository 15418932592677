import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import { Typography, Spin, Row } from 'antd';
import { cubeJsURL } from '../../config';

const { Text } = Typography;

const CubeJsQueryRenderer = ({
  measures,
  timeDimensions,
  order = {},
  filters,
  dimensions = [],
  chartFunction,
  pivotConfig,
  cubeJsToken,
  t,
}) => {
  const cubejsApi = cubejs(cubeJsToken, {
    apiUrl: cubeJsURL,
  });
  // eslint-disable-next-line no-shadow
  const renderChart = (Component, pivotConfig) => ({ resultSet, error }) => {
    return (
      <Row type="flex" justify="center" align="middle">
        {(resultSet && <Component resultSet={resultSet} pivotConfig={pivotConfig} />) ||
          (error && <Text>{t('Something went wrong, if problem persists please contact support')}</Text>) || <Spin />}
      </Row>
    );
  };
  return (
    <>
      {cubeJsToken && (
        <QueryRenderer
          query={{
            // Array of strings
            measures,
            // Array of objects
            timeDimensions,
            limit: 50000,
            // Object
            order,
            // Array of objects
            filters,
            // Array of strings
            dimensions,
            timezone: moment.tz.guess(),
          }}
          cubejsApi={cubejsApi}
          render={renderChart(chartFunction, pivotConfig)}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  cubeJsToken: state.user.cubeJsToken,
});

export default connect(mapStateToProps, null)(withTranslation()(CubeJsQueryRenderer));
