import React, { useState } from 'react';
import moment from 'moment';
import { Popover, Checkbox, Row, Typography, Button } from 'antd';
import { withTranslation } from 'react-i18next';

import { DATE_WITH_DAY_FORMAT } from '../../../constants';
import textUtils from '../../../utilities/textUtils';
import { colors } from '../../../styles/colors';

const { Text } = Typography;

const AssignShiftPopover = ({
  t,
  date,
  onAssignmentChange,
  onRoleChange,
  checked,
  shiftRoles,
  roleId,
  worker,
  assignableRoles,
  children,
}) => {
  const [selectedRoleId, setRoleId] = useState(roleId);

  const content = (
    <Row style={{ width: '240px' }}>
      <Row>
        <Checkbox
          onChange={() => {
            if (shiftRoles.length > 0) {
              const targetRoleId = assignableRoles[0].role.id;
              setRoleId(assignableRoles[0].role.id);
              onAssignmentChange(targetRoleId);
            } else {
              onAssignmentChange();
            }
          }}
          checked={checked}
        >
          {t('assigned')}
        </Checkbox>
      </Row>
      <Row>
        {shiftRoles.length > 0 && (
          <>
            <Row style={{ marginTop: '8px' }}>{t('assignAs')}</Row>
            <Row>
              {assignableRoles.map(shiftRole => {
                const selected = shiftRole.role.id === selectedRoleId;
                return (
                  <Button
                    disabled={!checked}
                    onClick={() => {
                      setRoleId(shiftRole.role.id);
                      onRoleChange(shiftRole.role.id);
                    }}
                    size="small"
                    style={{
                      background: selected && checked ? colors.functionalLink : checked && colors.lightGrey,
                      color: selected && checked && colors.white,
                      margin: '4px 8px 0px 0px',
                    }}
                  >
                    {shiftRole.role.name}
                  </Button>
                );
              })}
            </Row>
          </>
        )}
      </Row>
    </Row>
  );

  return (
    <Popover
      placement="rightTop"
      title={
        <>
          <Row>
            <Text strong>{textUtils.toTitleCase(`${worker.first_name} ${worker.last_name}`)}</Text>
          </Row>
          <Row>
            <Text>{moment(date).format(DATE_WITH_DAY_FORMAT)}</Text>
          </Row>
        </>
      }
      content={content}
      trigger="click"
    >
      {children}
    </Popover>
  );
};

export default withTranslation()(AssignShiftPopover);
