import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import WorkerOtBarChart from './components/WorkerOtBarChart';
import ReducedDemandCountCard from './components/ReducedDemandCountCard';
import FulfilmentRateCard from './components/FulfilmentRateCard';
import FulfilmentTrendChart from './components/FulfilmentTrendChart';
import FulfilmentFunnelChart from './components/FulfilmentFunnelChart';

const FulfilmentTab = ({ timePeriod, clientId, locationIds, positionIds, granularity }) => {
  return (
    <Row align="top">
      <Col span={24}>
        <Row type="flex" style={{ height: '100%', marginBottom: '16px' }} gutter={16}>
          <Col xs={24} md={12}>
            <FulfilmentRateCard
              timePeriod={timePeriod}
              clientId={clientId}
              locationIds={locationIds}
              positionIds={positionIds}
            />
          </Col>
          <Col xs={24} md={12}>
            <ReducedDemandCountCard
              timePeriod={timePeriod}
              clientId={clientId}
              locationIds={locationIds}
              positionIds={positionIds}
            />
          </Col>
        </Row>
        <Row type="flex" justify="center" align="middle" style={{ height: '100%', marginBottom: '34px' }} gutter={16}>
          <Col xs={24}>
            <FulfilmentTrendChart
              timePeriod={timePeriod}
              granularity={granularity}
              clientId={clientId}
              locationIds={locationIds}
              positionIds={positionIds}
            />
          </Col>
          <Col xs={24}>
            <FulfilmentFunnelChart
              timePeriod={timePeriod}
              granularity={granularity}
              clientId={clientId}
              locationIds={locationIds}
              positionIds={positionIds}
            />
          </Col>
          <Col xs={24}>
            <WorkerOtBarChart
              timePeriod={timePeriod}
              granularity={granularity}
              clientId={clientId}
              locationIds={locationIds}
              positionIds={positionIds}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default withTranslation()(FulfilmentTab);
