import React from 'react';
import { Modal, message } from 'antd';
import { withTranslation } from 'react-i18next';
import BillingForm from '../shared/components/BillingForm';
import clientApi from '../services/clientApi';

class BillingFormModal extends React.Component {
  state = {
    confirmLoading: false,
    billingForm: {},
  };

  setBillingForm = form => {
    this.setState({ billingForm: form });
  };

  submitBillingForm = async () => {
    const { client, fetchClientAndManager, closeModal, t } = this.props;
    const { billingForm } = this.state;
    this.setState({ confirmLoading: true });
    billingForm.validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { prefix, street_1, street_2, province, zip, country, ...payload } = values;
        payload.billing_address = { street_1, street_2, province, zip, country };
        payload.billing_contact_phone = `${prefix}${payload.billing_contact_phone}`;
        payload.id = client.clientId;
        const data = await clientApi.editClient(payload);
        if (data) {
          await fetchClientAndManager();
          message.success(t('Billing details successfully saved'));
          this.setState({ confirmLoading: false });
          closeModal();
        } else {
          this.setState({ confirmLoading: false });
          message.warn(t('Something went wrong. No changes were saved'));
        }
      }
    });
  };

  render() {
    const { isAdmin, client, visible, closeModal, t } = this.props;
    const { confirmLoading } = this.state;
    return (
      <Modal
        destroyOnClose
        style={{ top: '20px' }}
        title={t('Edit Billing Details')}
        okType="v2-primary"
        okText={t('Save')}
        cancelText={t('cancel')}
        visible={visible}
        confirmLoading={confirmLoading}
        onCancel={closeModal}
        onOk={this.submitBillingForm}
      >
        <BillingForm isAdmin={isAdmin} client={client} setBillingForm={this.setBillingForm} />
      </Modal>
    );
  }
}

export default withTranslation()(BillingFormModal);
