import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { createHtmlPortalNode, OutPortal } from 'react-reverse-portal';
import { Drawer, message } from 'antd';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import * as chatActions from '../../../redux/chat';
import { getChatClient } from '../../../utilities/chatUtils';
import { shouldReopenRoute } from '../routes';
import Context from '../context';
import { colors } from '../../../styles/colors';
import { UPDATE_DELAY, twilioConnectionStates } from '../constants';
import { messageDisplayDuration } from '../../../constants';

const ChatDrawer = ({
  t,
  userId,
  history,
  location,
  children,
  visible,
  fetchTotalUnreadCount,
  closeChat,
  browserHistory,
}) => {
  const titleNode = createHtmlPortalNode();
  const footerNode = createHtmlPortalNode();
  const [twilioConnectionState, updateTwilioConnectionState] = useState(undefined);
  const [twilioChatClient, updateTwilioChatClient] = useState(undefined);

  const updateTwilioListeners = chatClient => {
    chatClient?.on('participantUpdated', fetchTotalUnreadCount);
    chatClient?.on('connectionStateChanged', updateTwilioConnectionState);
    return () => {
      chatClient?.off('participantUpdated', fetchTotalUnreadCount);
      chatClient?.off('connectionStateChanged', updateTwilioConnectionState);
    };
  };

  const establishTwilioConnection = async () => {
    if (
      [undefined, twilioConnectionStates.DISCONNECTED, twilioConnectionStates.DENIED].includes(twilioConnectionState)
    ) {
      try {
        const chatClient = await getChatClient(userId);
        updateTwilioChatClient(chatClient);
        updateTwilioConnectionState(twilioConnectionStates.CONNECTED);
        updateTwilioListeners(chatClient);
      } catch (error) {
        Sentry.captureException(error);
        message.warning(t('chatConnectionError'), [messageDisplayDuration]);
      }
    }
  };

  useEffect(() => {
    establishTwilioConnection();
    if (visible) {
      localStorage.setItem('@chat:lastRoute', location.pathname);
    } else {
      const lastRoute = localStorage.getItem('@chat:lastRoute');
      if (shouldReopenRoute(lastRoute)) {
        // FIXME: ChatDetailView is throwing errors when reopened.
        // openChat(lastRoute);
      }
    }
  }, [location, visible, twilioConnectionState]);

  const handleClose = () => {
    // Prevent the current route from being remounted when closing the chat drawer.
    history.push('');

    localStorage.removeItem('@chat:lastRoute');
    closeChat();
  };

  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      className="chat-drawer"
      footer={<OutPortal node={footerNode} />}
      headerStyle={{
        border: 0,
        backgroundColor: colors.backgroundGrey,
      }}
      mask={false}
      placement="right"
      title={<OutPortal node={titleNode} />}
      visible={visible}
      width={512}
      zIndex={99997}
      onClose={handleClose}
    >
      <Context.Provider
        value={{
          titleNode,
          footerNode,
          twilioChatClient,
          twilioConnectionState,
          establishTwilioConnection,
          browserHistory,
        }}
      >
        {children}
      </Context.Provider>
    </Drawer>
  );
};

const mapStateToProps = state => ({
  userId: state.user.userId,
});

const mapDispatchToProps = dispatch => {
  const { fetchTotalUnreadCount, openChat, closeChat } = chatActions;
  return {
    fetchTotalUnreadCount: () => {
      setTimeout(() => {
        dispatch(fetchTotalUnreadCount());
      }, UPDATE_DELAY);
    },
    openChat: route => {
      dispatch(openChat(route));
    },
    closeChat: () => {
      dispatch(closeChat());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ChatDrawer)));
