import React from 'react';
import moment from 'moment';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { withTranslation } from 'react-i18next';
import MonthSelect from './MonthSelect';

export default withTranslation()(({ t, onChange, selectedMonthStart, loading }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ marginBottom: -35 }}>
        <MonthSelect onChange={onChange} selectedMonthStart={selectedMonthStart} loading={loading} />
      </div>
      <Button style={{ margin: '0 0 0 24px' }} onClick={() => onChange(moment().toISOString())}>
        {t('thisMonth')}
      </Button>
      <Button
        style={{ margin: '0 8px 0 24px', width: '32px', padding: 'unset' }}
        onClick={() =>
          onChange(
            moment(selectedMonthStart)
              .subtract(1, 'months')
              .toISOString(),
          )
        }
      >
        <LeftOutlined />
      </Button>
      <Button
        style={{ width: '32px', padding: 'unset' }}
        onClick={() =>
          onChange(
            moment(selectedMonthStart)
              .add(1, 'months')
              .toISOString(),
          )
        }
      >
        <RightOutlined />
      </Button>
    </div>
  );
});
