import React from 'react';
import { Cascader, Row } from 'antd';
import ArticleDisplay from '../shared/components/ArticleDisplay';
import LoadingSpinner from '../shared/components/LoadingSpinner';

import { prismicClient } from '../config';

const options = [
  {
    value: 'workmate-terms-of-service-singapore',
    label: '🇸🇬 Singapore',
  },
  {
    value: 'workmate-terms-of-service-thailand',
    label: '🇹🇭 Thailand',
    children: [
      {
        value: 'en-gb',
        label: 'English',
      },
      {
        value: 'th',
        label: 'ไทย',
      },
    ],
  },
  {
    value: 'workmate-terms-of-service-indonesia',
    label: '🇮🇩 Indonesia',
    children: [
      {
        value: 'en-gb',
        label: 'English',
      },
      {
        value: 'id',
        label: 'Bahasa Indonesia',
      },
    ],
  },
];

// const { Title } = Typography;
class TermsOfServicePage extends React.Component {
  state = {
    isLoading: false,
    article: undefined,
    articleUID: 'workmate-terms-of-service-singapore',
    lang: 'en-gb',
  };

  async componentDidMount() {
    await this.fetchAndSetArticle();
  }

  onChange = value => {
    this.setState({ articleUID: value[0], lang: value[1] }, () => this.fetchAndSetArticle());
  };

  fetchAndSetArticle = async () => {
    this.setState({ isLoading: true });
    const { articleUID, lang } = this.state;
    const article = await prismicClient.getByUID('terms_of_service', articleUID, {
      lang,
    });
    this.setState({ isLoading: false, article });
  };

  render() {
    const { isLoading, articleUID, lang, article } = this.state;
    if (isLoading || !article) return <LoadingSpinner />;
    return (
      <>
        <Row style={{ marginBottom: '32px' }}>
          <Cascader
            style={{ width: '228px' }}
            allowClear={false}
            options={options}
            onChange={this.onChange}
            defaultValue={[articleUID, lang]}
          />
        </Row>

        <ArticleDisplay loading={isLoading} article={article} articleTitle={article.data.title[0].text} />
      </>
    );
  }
}

export default TermsOfServicePage;
