import React, { useState } from 'react';

import { CalendarOutlined } from '@ant-design/icons';

import { Button, DatePicker, Row, Typography } from 'antd';

import { DATE_MONTH_FORMAT } from '../../constants';
import { colors } from '../../styles/colors';

const { Text } = Typography;
const { MonthPicker } = DatePicker;

export default ({ onChange, selectedMonthStart, loading }) => {
  const [open, setOpen] = useState(false);

  const monthDisplayString = `${selectedMonthStart?.clone().format(DATE_MONTH_FORMAT)}`;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        style={{
          minWidth: '220px',
          padding: '0px',
        }}
        onClick={() => setOpen(!open)}
      >
        <Row type="flex" justify="space-between">
          <Text strong style={{ fontSize: '20px', marginLeft: '12px', color: open && colors.grey }}>
            {monthDisplayString}
          </Text>
          <CalendarOutlined style={{ color: open && colors.grey, margin: '7px 12px 0px 0px' }} />
        </Row>
      </Button>
      <MonthPicker
        defaultValue={selectedMonthStart}
        value={selectedMonthStart}
        onChange={onChange}
        loading={loading}
        open={open}
        style={{ visibility: 'hidden', width: 0 }}
        onOpenChange={openValue => setOpen(openValue)}
      />
    </div>
  );
};
