import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Row, Col, Typography, Modal, Avatar } from 'antd';
import { workerTags } from '../../../constants';
import WorkerTag from '../../../shared/components/WorkerTag';

const { Text } = Typography;

class VaccinationDetailCard extends React.Component {
  state = {
    imageModalVisible: false,
  };

  render() {
    const { covidCertificationImage, loading = false, t } = this.props;
    const { imageModalVisible } = this.state;
    return (
      <Card loading={loading} bordered={false} style={{ backgroundColor: 'inherit' }} bodyStyle={{ padding: 0 }}>
        <Row style={{ margin: '8px 0 16px 0' }}>
          <Text strong type="secondary" style={{ fontSize: '12px' }}>
            {t('vaccinationStatus').toUpperCase()}
          </Text>
        </Row>
        <Row style={{ marginBottom: '16px' }}>
          <Col>
            <WorkerTag type={workerTags.COVID_VACCINATED} />
          </Col>
        </Row>
        <Row>
          <Col style={{ marginBottom: '4px' }}>
            <Text strong>{t('vaccinationCertification')}</Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Avatar
              shape="square"
              size={128}
              src={covidCertificationImage}
              style={{ padding: '8px', border: '1px solid #D9D9D9' }}
              onClick={() =>
                this.setState({
                  imageModalVisible: true,
                })
              }
            />
          </Col>
        </Row>
        <Modal visible={imageModalVisible} footer={null} onCancel={() => this.setState({ imageModalVisible: false })}>
          <img alt="Covid Vaccination Certificate" style={{ width: '100%' }} src={covidCertificationImage} />
        </Modal>
      </Card>
    );
  }
}

export default withTranslation()(VaccinationDetailCard);
