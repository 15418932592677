import React from 'react';
import { withTranslation } from 'react-i18next';
import { BankOutlined, CreditCardOutlined } from '@ant-design/icons';
import { Modal, Typography, Row, Button } from 'antd';

const { Text } = Typography;
const ChangePaymentModal = ({ visible, onClose, t }) => {
  return (
    <Modal
      centered
      destroyOnClose
      onCancel={onClose}
      visible={visible}
      title={t('Contact us')}
      footer={[
        <Button key="close" onClick={onClose}>
          {t('Close')}
        </Button>,
      ]}
    >
      <>
        <Row style={{ marginBottom: '16px' }}>
          <Text>{t('Workmate has two payment methods you can choose from.')}</Text>
        </Row>

        <Row type="flex" align="middle" style={{ marginBottom: '12px' }}>
          <Text type="secondary">
            <CreditCardOutlined style={{ fontSize: '24px', marginRight: '8px' }} />
          </Text>
          <Text strong>{t('creditCard')}</Text>
        </Row>

        <Row type="flex" align="middle" style={{ marginBottom: '16px' }}>
          <Text type="secondary">
            <BankOutlined style={{ fontSize: '24px', marginRight: '8px' }} />
          </Text>
          <Text strong>{t('Monthly invoicing')}</Text>
        </Row>

        <Row>
          <Text>{t('To change your payment method, please call our Customer Success team or chat with us.')}</Text>
        </Row>
      </>
    </Modal>
  );
};

export default withTranslation()(ChangePaymentModal);
