import React from 'react';
import { Button, Col, Divider, Popover, Row, Typography, InputNumber } from 'antd';
import { withTranslation } from 'react-i18next';
import { CaretDownFilled } from '@ant-design/icons';
import { colors } from '../../styles/colors';

const { Text } = Typography;

class AgePopoverFilter extends React.Component {
  state = {
    selectedAgeRange: this.props.selectedAgeRange,
    visible: false,
  };

  componentDidUpdate(prevProps) {
    // if no age range, reset the filter component
    if (this.props.selectedAgeRange !== prevProps.selectedAgeRange) {
      if (!this.props.selectedAgeRange[0] && !this.props.selectedAgeRange[1]) {
        this.onReset();
      }
    }
  }

  onReset = () => {
    this.setState({
      selectedAgeRange: [undefined, undefined],
    });
  };

  onApply = () => {
    const { selectedAgeRange } = this.state;
    const { onApply } = this.props;
    this.setState(
      {
        visible: false,
      },
      () => onApply && onApply(selectedAgeRange),
    );
  };

  onVisibleChange = visible => {
    this.setState({ visible }, () => !visible && this.resetChanges());
  };

  resetChanges = () => {
    const { selectedAgeRange } = this.props;
    this.setState({ selectedAgeRange });
  };

  onAgeFilterOnChange = (selectedAge, rangeType) => {
    this.setState(prevState => {
      const nextSelectedAgeRange = prevState.selectedAgeRange;
      nextSelectedAgeRange[rangeType] = selectedAge;
      return { selectedAgeRange: nextSelectedAgeRange };
    });
  };

  render() {
    const { label, isDisabled = false, t } = this.props;
    const { visible, selectedAgeRange } = this.state;
    const [minimumAge, maximumAge] = selectedAgeRange;

    const popoverTrigger = isDisabled ? 'focus' : 'click';

    return (
      <Popover
        visible={visible}
        trigger={popoverTrigger}
        placement="bottomLeft"
        onVisibleChange={this.onVisibleChange}
        overlayStyle={{ zIndex: 2 }}
        overlayClassName="ant-popover-wrapper tree-checkbox-filter"
        content={
          <>
            <Row style={{ padding: '12px 8px' }}>
              <InputNumber
                min={16}
                max={85}
                defaultValue={minimumAge}
                value={minimumAge}
                onChange={value => this.onAgeFilterOnChange(value, 0)}
                placeholder={16}
              />{' '}
              <Text style={{ marginLeft: '8px', marginRight: '8px', lineHeight: '32px' }}>{t('to')}</Text>{' '}
              <InputNumber
                min={0}
                max={100}
                defaultValue={maximumAge}
                value={maximumAge}
                onChange={value => this.onAgeFilterOnChange(value, 1)}
                placeholder={60}
              />
            </Row>
            <Row type="flex" justify="space-between" style={{ padding: '4px 8px' }}>
              <Divider style={{ margin: '12px 0' }} />
              <Col>
                <Button size="small" onClick={this.onReset}>
                  {t('Reset')}
                </Button>
              </Col>
              <Col>
                <Button size="small" className="ant-btn-v2-primary" onClick={this.onApply}>
                  {t('apply')}
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Button
          onClick={this.toggleVisiblity}
          disabled={isDisabled}
          style={
            minimumAge > 0 || maximumAge < 100
              ? {
                  borderColor: colors.blue,
                  backgroundColor: colors.white,
                  color: colors.blue,
                  zIndex: 1,
                  marginRight: 12,
                }
              : { marginRight: 12 }
          }
        >
          {label}
          {minimumAge > 0 || maximumAge < 100 ? ' (1)' : ''}
          <CaretDownFilled />
        </Button>
      </Popover>
    );
  }
}

export default withTranslation()(AgePopoverFilter);
