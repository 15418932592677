import React from 'react';
import { withTranslation } from 'react-i18next';
import TreeCheckboxFilter from '../../../shared/components/TreeCheckboxFilter';
import { workerTypeOptions, workerTags, partnerSourceTypes } from '../../../constants';

const WorkerTypeFilter = ({ selectedWorker, searchParams, onApplyWorkerTypeFilter, t }) => {
  const handleApplyWorkerTypeFilter = checkedKeys => {
    const nextSearchParams = { ...searchParams };
    const isAllSelected = workerTypeOptions.every(option => checkedKeys?.includes(option.key));
    if (isAllSelected || !checkedKeys?.length) {
      delete nextSearchParams.source;
      delete nextSearchParams.exclude_source;
    } else {
      const [selectedKey] = checkedKeys;
      const isJobInvitationSelected = selectedKey === workerTags.JOB_INVITATION;
      const fieldToAdd = isJobInvitationSelected ? 'source' : 'exclude_source';
      const fieldToRemove = isJobInvitationSelected ? 'exclude_source' : 'source';
      nextSearchParams[fieldToAdd] = partnerSourceTypes.DIRECT_JOB_INVITE;
      delete nextSearchParams[fieldToRemove];
    }
    nextSearchParams.page = 1;
    onApplyWorkerTypeFilter({ searchParams: nextSearchParams, searchApplied: checkedKeys.length > 0 });
  };

  return (
    <TreeCheckboxFilter
      treeData={workerTypeOptions}
      label={t('workerType')}
      showActionButtons
      showSearch={false}
      onApply={handleApplyWorkerTypeFilter}
      expandable={false}
      isDisabled={selectedWorker && selectedWorker.length > 0}
    />
  );
};

export default withTranslation()(WorkerTypeFilter);
