/* eslint-disable global-require */
import React from 'react';
import moment from 'moment-timezone';
import 'moment/locale/th';
import 'moment/locale/id';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/browser';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import idID from 'antd/lib/locale/id_ID';
import thTH from 'antd/lib/locale/th_TH';

import routes from './routes';
import AuthLayout from './shared/layouts/AuthLayout';
import PublicContentLayout from './shared/layouts/PublicContentLayout';
import GlobalError from './shared/components/GlobalError';
import PublicLayout from './shared/layouts/PublicLayout';
import AuthRedirect from './shared/components/AuthRedirect';
import { UTMProvider } from './shared/hooks/useUTM';

// Pages
import StaffRequestsPage from './pages/StaffRequestsPage';
import AttendancePage from './pages/AttendancePage';
import SettingsPage from './pages/SettingsPage';
import WorkerProfilePage from './pages/WorkerProfilePage';
import PolicyPage from './pages/PolicyPage';
import BillingPage from './pages/BillingPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import ShiftsPage from './pages/ShiftsPage';
import ShiftsPageV2 from './pages/ShiftsPageV2';
import AnalyticsPage from './pages/AnalyticsPage';
import WorkforcePage from './pages/WorkforcePage';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import FormsPage from './pages/FormsPage';
import Chat from './containers/Chat';
import StaffRequestTemplatePage from './pages/StaffRequestTemplatePage';

const pages = [
  // Public pages
  { exact: true, path: routes.login, component: LoginPage, layout: PublicLayout },
  { exact: true, path: routes.signUp, component: SignUpPage, layout: PublicLayout },
  { exact: true, path: routes.forgotPassword, component: ForgotPasswordPage, layout: PublicLayout },
  { exact: true, path: routes.resetPassword, component: ResetPasswordPage, layout: PublicLayout },
  { exact: true, path: routes.termsOfService, component: TermsOfServicePage, layout: PublicContentLayout },
  // Auth pages
  { exact: true, path: routes.home, component: AuthRedirect, layout: AuthLayout },
  { exact: true, path: routes.shifts, component: ShiftsPage, layout: AuthLayout },
  { exact: true, path: routes.shiftsV2, component: ShiftsPageV2, layout: AuthLayout },
  { exact: false, path: routes.staffRequests, component: StaffRequestsPage, layout: AuthLayout },
  { exact: false, path: routes.staffRequestTemplates, component: StaffRequestTemplatePage, layout: AuthLayout },
  { exact: true, path: routes.attendance, component: AttendancePage, layout: AuthLayout },
  { exact: false, path: routes.settings, component: SettingsPage, layout: AuthLayout },
  { exact: false, path: routes.policies, component: PolicyPage, layout: AuthLayout },
  { exact: true, path: routes.partnerDetail, component: WorkerProfilePage, layout: AuthLayout },
  { exact: true, path: routes.billingReportDetail, component: BillingPage, layout: AuthLayout },
  { exact: true, path: routes.analytics, component: AnalyticsPage, layout: AuthLayout },
  { exact: true, path: routes.workforce, component: WorkforcePage, layout: AuthLayout },
  { exact: false, path: routes.forms, component: FormsPage, layout: AuthLayout },
];

const localeConfig = {
  id: idID,
  th: thTH,
  en: enUS,
};

class App extends React.Component {
  state = {
    hasError: false,
  };

  componentDidMount() {
    require('./styles/index.css');
    require('./styles/override.css');
  }

  setErrorToFalse = () => {
    this.setState({ hasError: false });
  };

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const history = createBrowserHistory();
    const { hasError } = this.state;
    const currentLocale = localStorage.getItem('i18nextLng');
    // Set day of week to start on Monday
    moment.locale(currentLocale, { week: { dow: 1 } });
    return (
      <>
        <Router history={history}>
          <Switch>
            {pages.map(({ exact, path, component: Component, layout: Layout, layoutProps }, index) => (
              <Route
                key={index}
                exact={exact}
                path={path}
                component={props => (
                  <UTMProvider>
                    <Layout {...layoutProps}>
                      <ConfigProvider locale={localeConfig[currentLocale]}>
                        <Component {...props} />
                      </ConfigProvider>
                    </Layout>
                  </UTMProvider>
                )}
              />
            ))}
            <Redirect to={routes.home} />
          </Switch>
        </Router>

        {/* Chat */}
        <Chat browserHistory={history} />

        {hasError && <GlobalError onClickLink={this.setErrorToFalse} afterClose={this.setErrorToFalse} />}
      </>
    );
  }
}

export default App;
