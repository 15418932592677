import base from './base';
import { getPageFromURL } from '../utilities/urlUtils';
import { endpoints } from '../config';

const fetchChatToken = async userId => {
  const response = await base.api.get(`${endpoints.user}/${userId}/chat-token/`);
  return response;
};

const fetchChannels = async params => {
  const response = await base.api.get(`${endpoints.channels}/channel-list/`, {
    params,
  });
  return {
    nextPage: getPageFromURL(response.next),
    ...response,
  };
};

const fetchMembers = async (id, params) => {
  const response = await base.api.get(`${endpoints.channels}/${id}/member-list/`, { params });
  return response;
};

const fetchChannelById = async (id, params) => {
  const response = await base.api.get(`${endpoints.channels}/${id}/`, params);
  return response;
};

const joinChannel = async channelId => {
  const response = await base.api.post(`${endpoints.channels}/${channelId}/members/`);
  return response;
};

const createChannel = async params => {
  const { staffRequestId, ...channelAttributes } = params;
  const response = await base.api.post(`${endpoints.channels}/`, {
    ...channelAttributes,
    staff_request: staffRequestId,
  });
  return response;
};

const patchChannel = (channelId, params) => {
  return base.api.patch(`${endpoints.channels}/${channelId}/`, params);
};

const notifyChannel = (channelId, params) => {
  return base.api.post(`${endpoints.channels}/${channelId}/send-notification/`, params);
};

const archiveChannel = channelId => {
  return base.api.post(`${endpoints.channels}/${channelId}/archive/`);
};

const updateMemberAttributes = (channelId, params) => {
  return base.api.post(`${endpoints.channels}/${channelId}/update-member-attributes/`, params);
};

const getTotalUnreadCount = () => {
  return base.api.get(`${endpoints.channels}/total-unread-count/`);
};

export default {
  fetchChatToken,
  fetchChannels,
  fetchMembers,
  fetchChannelById,
  joinChannel,
  createChannel,
  patchChannel,
  notifyChannel,
  archiveChannel,
  updateMemberAttributes,
  getTotalUnreadCount,
};
