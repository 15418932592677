/* eslint no-unused-vars : "off" */
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Button,
  Col,
  Row,
  Input,
  DatePicker,
  TimePicker,
  Radio,
  Typography,
  InputNumber,
  message,
  Divider,
  Dropdown,
  Menu,
} from 'antd';
import { get, range } from 'lodash';
import { DownOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { timesheetEntryStatuses, TIME_FORMAT_24_HOUR } from '../../../constants';
import timesheetApi from '../../../services/timesheetApi';
import { getSecondsFromHoursMinutesOfDate, getClockInOutFromDateTime } from '../../../utilities/timesheetUtils';
import { colors } from '../../../styles/colors';

const { Item } = Form;
const { Title, Text } = Typography;

class EditTimesheetFormModal extends React.Component {
  state = {
    isSubmitting: false,
    selectedTags: {},
  };

  componentDidMount() {
    const { selectedTimesheetEntry } = this.props;
    if (selectedTimesheetEntry?.tags) {
      // eslint-disable-next-line prefer-const
      let selectedTags = {};
      // eslint-disable-next-line no-return-assign
      selectedTimesheetEntry.tags.map(item => {
        const category = item?.tag?.category || item?.categoryId;
        const tagId = item?.tag?.id || item?.tagId;
        // eslint-disable-next-line no-return-assign
        return (selectedTags[`${category}`] = { tag: parseInt(tagId, 10) });
      });
      this.setState({ selectedTags });
    }
  }

  formDataToCreatePayload = values => {
    const { selectedTimesheetEntry } = this.props;
    const { selectedTags } = this.state;

    const payload = {};
    if (values.clock_in_time) {
      const clockInTime = getClockInOutFromDateTime(values.clock_in_date, values.clock_in_time).toISOString();
      const selectedTimesheetEntryClockInTime =
        selectedTimesheetEntry?.clock_in_time || selectedTimesheetEntry?.clockInTime;
      // add clock_in_time to payload object only when clock in time field value time changed
      const isClockInTimeChanged = !moment(selectedTimesheetEntryClockInTime).isSame(clockInTime);
      if (isClockInTimeChanged) {
        payload.clock_in_time = clockInTime;
      }
    }

    if (values.clock_out_time) {
      const clockOutTime = getClockInOutFromDateTime(values.clock_out_date, values.clock_out_time).toISOString();
      const selectedTimesheetEntryClockOutTime =
        selectedTimesheetEntry?.clock_out_time || selectedTimesheetEntry?.clockOutTime;
      // add clock_out_time to payload object only when clock out time field value time changed
      const isClockOutTimeChanged = !moment(selectedTimesheetEntryClockOutTime).isSame(clockOutTime);
      if (isClockOutTimeChanged) {
        payload.clock_out_time = clockOutTime;
      }
    }

    return {
      ...payload,
      partner: selectedTimesheetEntry?.partner?.id,
      late: values.late,
      overtime_duration: getSecondsFromHoursMinutesOfDate(values.overtime_hours, values.overtime_minutes),
      location: selectedTimesheetEntry?.location?.id,
      position: selectedTimesheetEntry?.position?.id,
      tags: Object.values(selectedTags),
      break_duration_in_minutes: values.break_duration_in_minutes,
    };
  };

  handleSubmit = async () => {
    const { t, form, selectedTimesheetEntry, onUpdateTimesheet } = this.props;

    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          this.setState({ isSubmitting: true });
          const payload = this.formDataToCreatePayload(values);
          const workerName = `${selectedTimesheetEntry?.partner?.firstName} ${selectedTimesheetEntry?.partner?.lastName}`;
          if (selectedTimesheetEntry.status === timesheetEntryStatuses.DISPUTED) {
            await timesheetApi.resolve(selectedTimesheetEntry.id);
          }
          const updatedTimesheetEntry = await timesheetApi.update(selectedTimesheetEntry.id, payload);
          // Add confirmed_supervisor object for attendance detail
          if (selectedTimesheetEntry.status !== timesheetEntryStatuses.CLOCKED_IN) {
            await timesheetApi.confirm(selectedTimesheetEntry.id);
          }
          message.success(`${t('updateTimesheetSuccess', { workerName })}`);
          onUpdateTimesheet(updatedTimesheetEntry);
        } catch (error) {
          message.warning(t('submitFailed'));
        } finally {
          this.setState({ isSubmitting: false });
        }
      }
    });
  };

  validateClockInOutTime = (value, rule, callback) => {
    const { form, t } = this.props;

    try {
      const clockInDate = form.getFieldValue('clock_in_date');
      const clockOutDate = form.getFieldValue('clock_out_date');
      const clockInTime = form.getFieldValue('clock_in_time');
      const clockOutTime = form.getFieldValue('clock_out_time');

      const clockIn = getClockInOutFromDateTime(clockInDate, clockInTime);
      const clockOut = getClockInOutFromDateTime(clockOutDate, clockOutTime);

      if (clockOut && clockOut.isBefore(clockIn)) {
        callback(`${t('clockOutError')}`);
      }

      callback();
    } catch (err) {
      callback(err);
    }
  };

  validateMinuteStep = (value, rule, callback) => {
    const { form, t } = this.props;

    try {
      const overtimeMinutes = form.getFieldValue('overtime_minutes');
      if (overtimeMinutes % 30 !== 0) {
        callback(`${t('invalidMinuteInterval')}`);
      }
      callback();
    } catch (err) {
      callback(err);
    }
  };

  hadleSetSelectedTags = (tagId, categoryId) => {
    const { selectedTags } = this.state;
    const selectedTagCopy = selectedTags;
    const categoryTag = get(selectedTags, `${categoryId}.tag`);
    if (categoryTag === tagId) {
      delete selectedTagCopy[`${categoryId}`];
      this.setState({ selectedTags: selectedTagCopy });
    } else {
      this.setState({ selectedTags: { ...selectedTags, [`${categoryId}`]: { tag: tagId } } });
    }
  };

  render() {
    const { t, visible, onClose, selectedTimesheetEntry, timesheetTagCategories } = this.props;
    const { getFieldDecorator, setFieldsValue, getFieldValue } = this.props.form;
    const { isSubmitting, selectedTags } = this.state;
    // separating snake_case and camelCase due to component received data from rest and/or graphql endpoint
    const partnerFirstName = selectedTimesheetEntry.partner?.first_name || selectedTimesheetEntry.partner?.firstName;
    const partnerLastName = selectedTimesheetEntry.partner?.last_name || selectedTimesheetEntry.partner?.lastName;
    const clockInTime = selectedTimesheetEntry?.clock_in_time || selectedTimesheetEntry?.clockInTime;
    const clockOutTime = selectedTimesheetEntry?.clock_out_time || selectedTimesheetEntry?.clockOutTime;
    const overtimeDuration = selectedTimesheetEntry?.overtime_duration || selectedTimesheetEntry?.overtimeDuration;
    const disputedReason = selectedTimesheetEntry?.disputed_reason || selectedTimesheetEntry?.disputedReason;
    const breakRange = range(0, 241, 5);

    return (
      <Modal
        centered
        destroyOnClose
        className="v2-add-entry-modal"
        visible={visible}
        onCancel={() => {
          onClose();
        }}
        confirmLoading={isSubmitting}
        title={<Title level={4}>{t('Edit Timesheet Entry')}</Title>}
        footer={[
          <Button key="close" onClick={onClose}>
            {t('Close')}
          </Button>,
          <Button
            key="submit"
            type="v2-primary"
            loading={isSubmitting}
            onClick={() => {
              this.handleSubmit();
            }}
          >
            {t('update')}
          </Button>,
        ]}
      >
        <div style={{ height: 516, overflowY: 'scroll', width: '100%' }}>
          {selectedTimesheetEntry?.status === timesheetEntryStatuses.DISPUTED && (
            <Row
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                background: colors.tagYellow,
                boxSizing: 'border-box',
                borderRadius: '2px',
                padding: '9px 16px',
                marginBottom: 16,
                border: '1px solid #FFE58F',
              }}
            >
              <Row style={{ width: '100%' }}>
                <Col span={1}>
                  <ExclamationCircleFilled style={{ color: colors.functionalWarning }} />
                </Col>
                <Col span={23}>
                  <Row>
                    <Text
                      style={{
                        fontSize: 14,
                        color: colors.primaryText,
                        lineHeight: '22px',
                        marginLeft: '9px',
                        fontWeight: 'bold',
                      }}
                    >
                      {t('editTimesheetDisputedHeader')}
                    </Text>
                  </Row>
                  <Row style={{ marginTop: 4 }}>
                    <Text style={{ fontSize: 14, color: colors.primaryText, lineHeight: '22px', marginLeft: '9px' }}>
                      {disputedReason?.description}
                    </Text>
                  </Row>
                </Col>
              </Row>
            </Row>
          )}
          <Form hideRequiredMark colon={false} className="v2-form-wrapper" style={{ width: '97%' }}>
            <section key={0}>
              <Row type="flex" gutter={24}>
                <Col span={24}>
                  <Item label={t('Worker')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
                    <Input style={{ width: '100%' }} disabled value={`${partnerFirstName} ${partnerLastName}`} />
                  </Item>
                </Col>
                <Col xs={12}>
                  <Item label={t('Location')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
                    <Input style={{ width: '100%' }} disabled value={selectedTimesheetEntry?.location?.name} />
                  </Item>
                </Col>
                <Col xs={12}>
                  <Item label={t('Position')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
                    <Input style={{ width: '100%' }} disabled value={selectedTimesheetEntry?.position?.name} />
                  </Item>
                </Col>
                <Col xs={12}>
                  <Item label={t('clockInDate')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
                    {getFieldDecorator('clock_in_date', {
                      rules: [{ required: true, message: t('fieldRequired.') }],
                      initialValue: moment(clockInTime),
                    })(<DatePicker allowClear={false} format="DD MMM YYYY" style={{ width: '100%' }} />)}
                  </Item>
                </Col>
                <Col xs={12}>
                  <Item label={t('Clock In Time')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
                    {getFieldDecorator('clock_in_time', {
                      rules: [{ required: true, message: t('fieldRequired.') }],
                      initialValue: moment(clockInTime),
                    })(
                      <TimePicker
                        minuteStep={1}
                        format={TIME_FORMAT_24_HOUR}
                        defaultOpenValue={moment('00:00', TIME_FORMAT_24_HOUR)}
                        style={{ width: '100%' }}
                      />,
                    )}
                  </Item>
                </Col>
                <Col xs={12}>
                  <Item label={t('clockOutDate')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
                    {getFieldDecorator('clock_out_date', {
                      rules: [{ required: true, message: t('fieldRequired.') }],
                      initialValue: clockOutTime ? moment(clockOutTime) : null,
                    })(<DatePicker allowClear={false} format="DD MMM YYYY" style={{ width: '100%' }} />)}
                  </Item>
                </Col>
                <Col xs={12}>
                  <Item label={t('Clock Out Time')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
                    {getFieldDecorator('clock_out_time', {
                      rules: [
                        { required: true, message: t('fieldRequired.') },
                        { validator: this.validateClockInOutTime },
                      ],
                      initialValue: selectedTimesheetEntry && clockOutTime ? moment(clockOutTime) : null,
                    })(
                      <TimePicker
                        minuteStep={1}
                        format={TIME_FORMAT_24_HOUR}
                        defaultOpenValue={moment('00:00', TIME_FORMAT_24_HOUR)}
                        style={{ width: '100%' }}
                      />,
                    )}
                  </Item>
                </Col>
                <Col xs={12}>
                  <Item label={t('Break')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
                    {getFieldDecorator('break_duration_in_minutes', {
                      initialValue: selectedTimesheetEntry?.breakDurationInMinutes,
                    })(
                      <>
                        <Dropdown
                          trigger={['click']}
                          placement="topRight"
                          overlay={
                            <Menu className="break-dropdown">
                              {breakRange.map((minute, index) => (
                                <Menu.Item key={index}>
                                  <Button
                                    style={{ width: '100%', textAlign: 'left' }}
                                    type="text"
                                    onClick={() => setFieldsValue({ break_duration_in_minutes: minute })}
                                  >
                                    {minute || t('none')}
                                  </Button>
                                </Menu.Item>
                              ))}
                            </Menu>
                          }
                        >
                          <Row>
                            <Col span={18}>
                              <Button style={{ width: '100%' }}>
                                <Row>
                                  <Col span={12} type="flex" align="left">
                                    <Typography.Text>
                                      {getFieldValue('break_duration_in_minutes') || t('none')}
                                    </Typography.Text>
                                  </Col>
                                  <Col span={12} type="flex" align="right">
                                    <DownOutlined size={2} />
                                  </Col>
                                </Row>
                              </Button>
                            </Col>
                            <Col span={6}>
                              <Typography.Text style={{ paddingLeft: 8 }}>{t('mins')}</Typography.Text>
                            </Col>
                          </Row>
                        </Dropdown>
                      </>,
                    )}
                  </Item>
                </Col>
                <Col xs={12}>
                  <Item label={t('Late')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }}>
                    {getFieldDecorator('late', {
                      rules: [{ required: true, message: t('fieldRequired.') }],
                      initialValue: selectedTimesheetEntry?.late || false,
                    })(
                      <Radio.Group
                        onChange={value => {
                          setFieldsValue({ late: value });
                        }}
                      >
                        <Radio value>{t('Yes')}</Radio>
                        <Radio value={false}>{t('No')}</Radio>
                      </Radio.Group>,
                    )}
                  </Item>
                </Col>
                <Col xs={12}>
                  <Item label={t('Overtime')} style={{ marginBottom: 16 }} labelCol={{ span: 24 }} />
                  <div style={{ display: 'flex' }}>
                    <Item style={{ marginBottom: 16 }}>
                      {getFieldDecorator('overtime_hours', {
                        initialValue: overtimeDuration ? moment.duration(overtimeDuration, 'seconds').hours() : 0,
                      })(
                        <InputNumber
                          min={0}
                          max={23}
                          style={{ width: '50%' }}
                          onChange={value => {
                            setFieldsValue({ overtime_hours: value });
                          }}
                        />,
                      )}
                      <span className="ant-form-text"> hours</span>
                    </Item>
                    <Item style={{ marginBottom: 16 }}>
                      {getFieldDecorator('overtime_minutes', {
                        initialValue: overtimeDuration ? moment.duration(overtimeDuration, 'seconds').minutes() : 0,

                        rules: [{ validator: this.validateMinuteStep }],
                      })(
                        <InputNumber
                          min={0}
                          max={30}
                          step={30}
                          style={{ width: '50%' }}
                          onChange={value => {
                            setFieldsValue({ overtime_minutes: value });
                          }}
                        />,
                      )}
                      <span className="ant-form-text"> mins</span>
                    </Item>
                  </div>
                </Col>
              </Row>
            </section>
            <Divider />
            <section key={1}>
              <Row type="flex" gutter={24}>
                <Col span={24}>
                  <Text style={{ fontWeight: 600 }}>{t('timesheetTags')}</Text>
                </Col>
                {timesheetTagCategories.map(category => (
                  <div key={`category-${category.id}`} style={{ marginTop: 16, width: '100%', paddingLeft: 1 }}>
                    <Col span={24} style={{ marginBottom: 8 }}>
                      <Text>{category.name}</Text>
                    </Col>
                    <Col span={24}>
                      {category.tags.map(tag => {
                        const selectedTag = get(selectedTags[`${category.id}`], 'tag');
                        const isSelected = selectedTag === tag.id;
                        if (tag.is_active || isSelected) {
                          return (
                            <Button
                              key={tag.id}
                              style={{
                                marginBottom: 8,
                                marginRight: 8,
                                borderRadius: 2,
                                borderColor: colors.componentBorder,
                              }}
                              type={`${isSelected ? 'primary' : 'text'}`}
                              onClick={e => {
                                e.preventDefault();
                                this.hadleSetSelectedTags(tag.id, category.id);
                              }}
                            >
                              {tag.name}
                            </Button>
                          );
                        }
                        return null;
                      })}
                    </Col>
                  </div>
                ))}
              </Row>
            </section>
          </Form>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  managerId: state.user.managerId,
  timesheetTagCategories: state.user.timesheetTagCategories,
});

export default connect(mapStateToProps, null)(Form.create()(withTranslation()(EditTimesheetFormModal)));
