import { arrayMove } from '@dnd-kit/sortable';
import { v4 as uuidv4 } from 'uuid';
import { range } from 'lodash';
import formsApi from '../services/formsApi';

const checkFormNameIfExists = async formName => {
  const results = await formsApi.list({ title: formName });
  return results.length > 0;
};

const getChoicesScore = (choices = []) => {
  return choices.reduce((prev, curr) => (curr.score > 0 ? prev + curr.score : prev), 0);
};

const getQuestionsTotalScore = (questions = []) => {
  return questions.reduce((prev, curr) => {
    const totalChoiceScore = getChoicesScore(curr.choices);
    return prev + totalChoiceScore;
  }, 0);
};

const getPassingScore = (passingPercentage = 0, totalScore = 0) => {
  const percentageNum = parseInt(passingPercentage, 10);
  const percentageInDecimal = percentageNum / 100;
  return Math.round(percentageInDecimal * parseInt(totalScore, 10));
};

const updateChoicesKnockout = ({ choices, choiceId, knockout }) => {
  return choices.map(choice =>
    choice.id === choiceId
      ? {
          ...choice,
          knockout,
        }
      : choice,
  );
};

const getCreateFormPayload = ({ title, passing_score, selectedQuestions }) => {
  const questions = selectedQuestions.map(selectedQuestion => {
    const choices = selectedQuestion.choices.map(choice => {
      return {
        id: choice.id,
        knockout: Boolean(choice.knockout),
      };
    });
    return {
      id: selectedQuestion.id,
      choices,
    };
  });

  const payload = {
    form: {
      title,
      passing_score: Number(passing_score),
      language_code: 'en',
    },
    questions,
  };

  return payload;
};

const formatQuestions = questions => {
  return questions.map(item => {
    const choices = item.recruitment_form_choices.map(formChoice => {
      return {
        ...formChoice.choice,
        knockout: formChoice.knockout,
      };
    });
    return {
      ...item.question,
      choices,
      key: uuidv4(),
    };
  });
};

const reOrderedQuestions = (questions, activeIndex, overIndex) => {
  return arrayMove(questions, activeIndex, overIndex);
};

const sortFormsByStatus = (forms = []) => {
  return forms.sort((a, b) => a.status.localeCompare(b.status));
};

const getBreakDurationChoices = t => {
  return [
    { label: t('none'), value: 0 },
    ...range(5, 241, 5).map(value => ({
      label: `${value}`,
      value,
    })),
  ];
};

export default {
  checkFormNameIfExists,
  getQuestionsTotalScore,
  updateChoicesKnockout,
  getCreateFormPayload,
  getPassingScore,
  formatQuestions,
  reOrderedQuestions,
  sortFormsByStatus,
  getBreakDurationChoices,
};
