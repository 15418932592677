import React from 'react';
import { withTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import moment from 'moment-timezone';
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Typography, Row, Col, Modal, Avatar, Select } from 'antd';

import timesheetApi from '../../../services/timesheetApi';

const { Title, Text } = Typography;

const TitleAndTime = ({ timeInISO, title }) => (
  <>
    <Text type="secondary" strong>
      {title.toUpperCase()}
    </Text>
    <Title level={4} style={{ margin: '4px 0 16px 0' }}>
      {moment(timeInISO).format('DD MMM, hh:mm A')}
    </Title>
  </>
);

class VoidTimesheetEntryModal extends React.Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    this.fetchVoidReasons();
  }

  fetchVoidReasons = async () => {
    this.setState({ isLoading: true });
    try {
      // TODO use void reason from redux
      const response = await timesheetApi.fetchVoidReasons();
      this.setState({ isLoading: false, voidReasons: response });
    } catch (error) {
      this.setState({ isLoading: false });
      Sentry.captureException(error);
    }
  };

  handleSubmit = e => {
    const { t } = this.props;
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        const response = await timesheetApi.voidItem(this.props.timesheet.id, values.voided_reason);

        if (response) {
          const workerName = `${this.props.timesheet?.partner?.firstName} ${this.props.timesheet?.partner?.lastName}`;
          message.success(`${t('voidTimesheetSuccess', { workerName })}`);
          this.setState({ isLoading: false });
          this.props.onComplete();
          return;
        }
        this.setState({ isLoading: false });
      }
    });
  };

  render() {
    const { onCancel, visible, timesheet, t } = this.props;
    if (!visible) return <> </>;

    const { isLoading } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { partner } = timesheet;
    const timesheetId = timesheet.id;
    const fullName = `${partner.firstName} ${partner.lastName}`;

    return (
      <Modal
        title={<Title level={4}>{`${t('voidReasonModalTitle')} #${timesheetId}`}</Title>}
        centered
        visible={visible}
        onCancel={onCancel}
        onOk={this.handleSubmit}
        width={480}
        okType="danger"
        okText={t('Void')}
        okButtonProps={{ loading: isLoading }}
      >
        <Row style={{ marginBottom: '16px' }}>{t('provideVoidReasonPrompt')}</Row>

        <Row type="flex" style={{ marginBottom: '16px' }} align="middle" gutter={16}>
          <Col>
            <Avatar shape="square" icon={<UserOutlined />} size={88} />
          </Col>
          <Col>
            <Title level={4}>{fullName}</Title>
          </Col>
        </Row>

        <Row type="flex" justify="space-between" style={{ maginBottom: '16px' }} gutter={32}>
          <Col span={12}>
            {/* TODO change to translation variable format */}
            <TitleAndTime title={t('Clocked in')} timeInISO={timesheet.clockInTime} />
          </Col>
          <Col span={12}>
            <TitleAndTime title={t('Clocked out')} timeInISO={timesheet.clockOutTime} />
          </Col>
        </Row>

        <Form hideRequiredMark>
          <Form.Item label={t('formVoidReasonPrompt')}>
            {getFieldDecorator('voided_reason', {
              // TODO change to translation variable format
              rules: [{ required: true, message: t('Reason is required') }],
            })(
              <Select autoFocus placeholder={t('Please give your reason and some details...')} optionLabelProp="label">
                {this.state.voidReasons?.map(({ id, name, description }) => (
                  <Select.Option key={id} label={name}>
                    <Typography.Text>{name}</Typography.Text>
                    <br />
                    <Typography.Text type="secondary" style={{ whiteSpace: 'normal' }}>
                      {description}
                    </Typography.Text>
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({ name: 'void-timesheet-entry-form' })(withTranslation()(VoidTimesheetEntryModal));
