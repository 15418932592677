import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { isEqual, uniq } from 'lodash';

import shiftUtils from '../../../utilities/shiftUtils';
import datetimeUtils from '../../../utilities/datetimeUtils';
import EditShiftFormModal from './EditShiftFormModal';
import EditScheduleModal from './EditScheduleModal';
import AddWorkersDrawer from './AddWorkersDrawer';
// TODO update this if design is different
import DeleteShiftFormModal from '../../ShiftsPage/components/DeleteShiftFormModal';
import MonthlyScheduleTable from './MonthlyScheduleTable';
import WeeklyScheduleTable from './WeeklyScheduleTable';
import { attendanceStatuses, MONTHLY_DATE_RANGE } from '../../../constants';

class ScheduleListView extends Component {
  state = {
    loading: false,
    selectedShift: undefined,
    scheduleData: [],
    editScheduleModalVisible: false,
    editShiftModalVisible: false,
    deleteShiftModalVisible: false,
    addWorkersModalVisible: false,
    selectedScheduleRole: undefined,
    selectedSchedule: undefined,
    expandedRowKeys: [],
  };

  componentDidMount() {
    this.getTableData();
  }

  componentDidUpdate(prevProp) {
    if (
      !isEqual(prevProp.schedules, this.props.schedules) ||
      !isEqual(prevProp.shifts, this.props.shifts) ||
      !isEqual(prevProp.employments, this.props.employments)
    ) {
      this.getTableData();
    }
  }

  getTableData = () => {
    const {
      schedules,
      shifts,
      weekdayDates,
      positions,
      originalShifts,
      employments,
      scheduleTableExpandedRowKeys,
    } = this.props;
    this.setState({ loading: true });
    if (!schedules || !shifts) {
      return [];
    }
    const scheduleData = schedules
      .filter(schedule => {
        const noSchedules = schedule.schedule_roles.length === 0;
        const hasActiveRequiredRoles =
          schedule.schedule_roles.length > 0 &&
          schedule.schedule_roles.filter(scheduleRole => scheduleRole.role.is_active && scheduleRole.staff_required > 0)
            .length > 0;
        return (noSchedules || hasActiveRequiredRoles) && schedule;
      })
      .map(schedule => {
        const shiftData = {};
        const scheduleShifts = shifts.filter(shift => shift.schedule.id === schedule.id);
        const scheduleShiftsWithRoles = scheduleShifts.filter(shift => shift.shift_roles.length > 0);

        if (scheduleShifts.length > 0) {
          weekdayDates.forEach(momentDateObj => {
            const shiftForTheDay = shiftUtils.getMatchingShiftForTheDay(scheduleShifts, momentDateObj);
            if (shiftForTheDay) {
              shiftData[moment(momentDateObj).format('DD MMM')] = shiftForTheDay;
            }
          });
        }
        // TODO check for historical - e.g. if delete sched on tue, mon should show
        const scheduleRoleData = [];
        const scheduleRolesToDisplay = [];

        if (schedule.schedule_roles.length > 0) {
          schedule.schedule_roles.map(scheduleRole => {
            if (scheduleRole.staff_required > 0) {
              scheduleRolesToDisplay.push(scheduleRole.role.name);
            } else {
              const hasShifts =
                scheduleShiftsWithRoles.filter(shift =>
                  shift.shift_roles.find(
                    shiftRole => shiftRole.role.id === scheduleRole.role.id && shiftRole.staff_required > 0,
                  ),
                ).length > 0;
              if (hasShifts) {
                scheduleRolesToDisplay.push(scheduleRole.role.name);
              }
            }
            return scheduleRoleData.push({
              roleId: scheduleRole.role.id,
              roleName: scheduleRole.role.name,
              staffRequired: scheduleRole.staff_required,
              isActive: scheduleRole.role.is_active,
            });
          });
          // Order the scheduleRoles to match the shift_roles
          scheduleRoleData.sort((a, b) => a.roleName.localeCompare(b.roleName));
        }

        // TODO: use start_date, duration and recurrences!!
        // TODO fix BE shift generation function - if schedule created today, first shift created tomorrow

        // Get list of partner ids assigned to a shift under the current schedule
        const partnerIds = scheduleShifts.reduce((assignedPartnerIds, shift) => {
          const ids = shift.attendances
            .filter(attendance => attendance.status === attendanceStatuses.ASSIGNED || attendance.unpublished)
            .map(attendance => attendance.partner_id);
          return assignedPartnerIds.concat(ids);
        }, []);

        const filteredEmployments = employments.filter(employment => uniq(partnerIds).includes(employment.partner.id));
        return {
          id: schedule.id,
          name: schedule.name,
          time: `${moment(schedule.start_time, ['HH.mm']).format('h:mmA')}-${moment(schedule.end_time, [
            'HH.mm',
          ]).format('h:mmA')}`,
          position:
            (positions.find(position => schedule.position.id === position.id) &&
              positions.find(position => schedule.position.id === position.id).name) ||
            '',
          scheduleRoles: scheduleRoleData,
          scheduleRolesToDisplay,
          ...shiftData,
          originalShifts: originalShifts.filter(shift => shift.schedule.id === schedule.id),
          shifts: scheduleShifts,
          employments: filteredEmployments,
          shiftsOutsideSchedule: shifts.filter(shift => shift.schedule.id !== schedule.id),
          weekdayDates,
        };
      });
    if (scheduleTableExpandedRowKeys.length > 0) {
      // If there are expanded row keys being passed as props, set current expanded row keys from props
      this.setState({ scheduleData, expandedRowKeys: scheduleTableExpandedRowKeys, loading: false });
    } else {
      this.setState({ scheduleData, loading: false });
    }
  };

  tableEditScheduleButtonOnClick = record => {
    const { schedules, shifts, timezone } = this.props;
    this.setState({
      editScheduleModalVisible: true,
      selectedShift: shifts.find(sh => sh.schedule.id === record.id),
      selectedSchedule: schedules.find(sch => sch.id === record.id),
      selectedDate: datetimeUtils.getDayStart(moment(), timezone),
    });
  };

  tableAddWorkerButtonOnClick = (record, scheduleRole) => {
    const { schedules } = this.props;
    this.setState({
      addWorkersModalVisible: true,
      selectedSchedule: schedules.find(sch => sch.id === record.id),
      selectedScheduleRole: scheduleRole,
    });
  };

  tableEditShiftOnClick = (shift, schedule, momentDateObj) => {
    const { schedules } = this.props;
    this.setState({
      editShiftModalVisible: true,
      selectedShift: shift,
      selectedSchedule: schedules.find(sch => sch.id === schedule.id),
      selectedDate: momentDateObj,
    });
  };

  render() {
    const {
      weekdayDates,
      schedules,
      loading: scheduleLoading,
      onScheduleUpdate,
      shifts,
      timezone,
      onRefresh,
      onAssignmentUpdate,
      onRoleUpdate,
      onAddWorkersToShift,
      clientId,
      employments,
      employmentsLoading,
      selectedDateRange,
    } = this.props;
    const {
      loading,
      editScheduleModalVisible,
      editShiftModalVisible,
      selectedShift,
      selectedSchedule,
      scheduleData,
      selectedDate,
      deleteShiftModalVisible,
      addWorkersModalVisible,
      selectedScheduleRole,
      expandedRowKeys,
    } = this.state;

    return (
      <>
        {/* TODO when changing date range, do we need to unmount table? Could we use tabs?  */}
        {selectedDateRange === MONTHLY_DATE_RANGE ? (
          <MonthlyScheduleTable
            loading={loading || scheduleLoading}
            dataSource={scheduleData}
            expandedRowKeys={expandedRowKeys}
            schedules={schedules}
            onRefresh={onRefresh}
            onAssignmentUpdate={onAssignmentUpdate}
            onRoleUpdate={onRoleUpdate}
            assignmentTableLoading={employmentsLoading}
            timezone={timezone}
            clientId={clientId}
            weekdayDates={weekdayDates}
            editScheduleButtonOnClick={record => this.tableEditScheduleButtonOnClick(record)}
            addWorkerButtonOnClick={(record, scheduleRole) => this.tableAddWorkerButtonOnClick(record, scheduleRole)}
            editShiftOnClick={(shift, schedule, momentDateObj) =>
              this.tableEditShiftOnClick(shift, schedule, momentDateObj)
            }
          />
        ) : (
          <WeeklyScheduleTable
            loading={loading || scheduleLoading}
            dataSource={scheduleData}
            expandedRowKeys={expandedRowKeys}
            schedules={schedules}
            onRefresh={onRefresh}
            onAssignmentUpdate={onAssignmentUpdate}
            onRoleUpdate={onRoleUpdate}
            assignmentTableLoading={employmentsLoading}
            timezone={timezone}
            clientId={clientId}
            weekdayDates={weekdayDates}
            editScheduleButtonOnClick={record => this.tableEditScheduleButtonOnClick(record)}
            addWorkerButtonOnClick={(record, scheduleRole) => this.tableAddWorkerButtonOnClick(record, scheduleRole)}
            editShiftOnClick={(shift, schedule, momentDateObj) =>
              this.tableEditShiftOnClick(shift, schedule, momentDateObj)
            }
          />
        )}
        {selectedShift && selectedSchedule && (
          <EditScheduleModal
            key={selectedShift.id}
            shift={selectedShift}
            schedule={selectedSchedule}
            timezone={timezone}
            date={selectedDate}
            visible={editScheduleModalVisible}
            onCancel={() => this.setState({ editScheduleModalVisible: false })}
            onUpdate={() => {
              this.setState({ editScheduleModalVisible: false });
              onScheduleUpdate();
            }}
            openDeleteShiftModal={() =>
              this.setState({ editScheduleModalVisible: false, deleteShiftModalVisible: true })
            }
          />
        )}
        {selectedShift && selectedSchedule && (
          <EditShiftFormModal
            key={selectedShift.id}
            shift={selectedShift}
            schedule={selectedSchedule}
            timezone={timezone}
            date={selectedDate}
            visible={editShiftModalVisible}
            onCancel={() => {
              this.setState({ editShiftModalVisible: false, selectedShift: undefined, selectedSchedule: undefined });
            }}
            onUpdate={() => {
              this.setState({ editShiftModalVisible: false });
              onScheduleUpdate();
            }}
            openDeleteShiftModal={() => this.setState({ editShiftModalVisible: false, deleteShiftModalVisible: true })}
          />
        )}
        {selectedShift && selectedSchedule && (
          <DeleteShiftFormModal
            key={`d-${selectedShift.id}`}
            shift={selectedShift}
            shifts={shifts}
            schedule={selectedSchedule}
            timezone={timezone}
            date={selectedDate}
            visible={deleteShiftModalVisible}
            onCancel={() =>
              this.setState({ deleteShiftModalVisible: false, selectedShift: undefined, selectedSchedule: undefined })
            }
            onUpdate={() => {
              this.setState({ deleteShiftModalVisible: false });
              onScheduleUpdate();
            }}
          />
        )}
        {selectedSchedule && (
          <AddWorkersDrawer
            key={selectedSchedule.id}
            schedule={selectedSchedule}
            employments={employments}
            shifts={shifts}
            timezone={timezone}
            visible={addWorkersModalVisible}
            weekdayDates={weekdayDates}
            onCancel={() =>
              this.setState({
                addWorkersModalVisible: false,
                selectedSchedule: undefined,
                selectedScheduleRole: undefined,
              })
            }
            onUpdate={() => {
              this.setState({
                addWorkersModalVisible: false,
                selectedScheduleRole: undefined,
                selectedSchedule: undefined,
              });
              onScheduleUpdate();
            }}
            onSubmit={selectedWorkerIds => {
              this.setState({
                addWorkersModalVisible: false,
                selectedScheduleRole: undefined,
                selectedSchedule: undefined,
                expandedRowKeys: [...expandedRowKeys, selectedSchedule.id],
              });
              onAddWorkersToShift(selectedWorkerIds, selectedSchedule, selectedScheduleRole);
            }}
            scheduleRole={selectedScheduleRole}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  positions: state.global.positions,
});

export default withTranslation()(connect(mapStateToProps)(ScheduleListView));
