/* eslint-disable react/no-access-state-in-setstate */
import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Empty, Input, List, message, Row, Typography, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { cloneDeep, isEmpty, sortBy } from 'lodash';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import FilterWithSearch from '../../../shared/components/FilterWithSearch';
import TreeCheckboxFilter from '../../../shared/components/TreeCheckboxFilter';
import staffRequestUtils from '../../../utilities/staffRequestUtils';
import { colors } from '../../../styles/colors';
import getPaginationConfig from '../../../utilities/getPaginationConfig';
import { updateUrlWithParams } from '../../../utilities/urlUtils';
import StatusTag from '../../../shared/components/StatusTag';
import staffRequestTemplateApi from '../../../services/staffRequestTemplateApi';
import routes from '../../../routes';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import { DEFAULT_DATE_FORMAT, staffRequestTemplateStatuses } from '../../../constants';
import EmptySearchResult from '../../../shared/components/EmptySearchResult';
import ConfirmModals from '../../../shared/components/ConfirmModals';

const { Title, Text } = Typography;

const StaffRequestTemplateListView = ({ t, positions, locations, user, history, location }) => {
  const pageParams = queryString.parse(location.search);
  const [searchParams, setSearchParams] = useState({
    page: pageParams?.page || 1,
    search: pageParams?.search,
    positions:
      (pageParams?.position_name &&
        pageParams.position_name.split(';').map(item => ({
          value: item,
          lable: item,
          key: item,
        }))) ||
      [],
    location: (pageParams?.location && pageParams.location.split(',')) || [],
    employment_type: (pageParams?.employment_type && pageParams.employment_type.split(',')) || [],
  });
  const [staffRequestsTemplates, setStaffRequestsTemplates] = useState([]);
  const [numRecords, setNumRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const isSearchApplied =
    !isEmpty(searchParams.search) ||
    !isEmpty(searchParams.positions) ||
    !isEmpty(searchParams.location) ||
    !isEmpty(searchParams.employment_type);
  const positionOptions = positions.map(pos => ({ id: pos.name, name: pos.name }));
  const locationOptions = sortBy(
    locations?.map(({ id, name }) => ({ key: String(id), title: name })),
    option => option.title,
  );
  const employmentTypeOptions = staffRequestUtils.getEmploymentTypeOptions(t, user).map(item => ({
    disabled: item.disabled,
    key: item.value,
    title: item.label,
  }));

  const onSearchParamChange = (field, value) => {
    const searchParamsClone = cloneDeep(searchParams);
    searchParamsClone[field] = value;
    setSearchParams(searchParamsClone);
  };

  const onPageChange = page => {
    setSearchParams({
      ...searchParams,
      page,
    });
  };

  const updateSearchParamStructure = () => {
    const { employment_type, location: locationFilter, positions: positionsFilter } = searchParams;
    const updatedSearchParams = cloneDeep(searchParams);
    updatedSearchParams.position_name = positionsFilter?.map(({ key }) => key).join(';');
    updatedSearchParams.location = locationFilter?.join(',');
    updatedSearchParams.employment_type = employment_type?.join(',');
    updatedSearchParams.status = [staffRequestTemplateStatuses.DRAFT, staffRequestTemplateStatuses.POSTED].join(',');
    delete updatedSearchParams.positions;
    return updatedSearchParams;
  };

  const fetchStaffRequestTemplate = async () => {
    setLoading(true);
    try {
      const updatedSearchParams = updateSearchParamStructure();
      updateUrlWithParams({ ...updatedSearchParams }, history);
      const response = await staffRequestTemplateApi.fetchStaffRequestTemplates(updatedSearchParams);
      setStaffRequestsTemplates(response?.results);
      setNumRecords(response?.count);
    } catch (error) {
      message.error(t('commonErrorMessage'));
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteStaffRequestTemplate = async staffRequestTemplate => {
    try {
      await staffRequestTemplateApi.deleteStaffRequestTemplate(staffRequestTemplate);
      fetchStaffRequestTemplate();
      message.success(t('templateDeleted'));
    } catch (error) {
      message.error(t('commonErrorMessage'));
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    fetchStaffRequestTemplate();
  }, [searchParams]);

  return (
    <Row type="flex" gutter={50}>
      <Col xs={24} style={{ marginBottom: '24px' }}>
        <Row type="flex" justify="space-between" style={{ marginBottom: '32px' }}>
          <Col>
            <Title level={2}>{t('staffRequestTemplates')}</Title>
          </Col>
          <Col>
            <Button onClick={() => history.push(routes.createJobTemplate)} type="v2-primary" icon={<PlusOutlined />}>
              {t('addStaffRequestTemplate')}
            </Button>
          </Col>
        </Row>
        <Row type="flex" justify="space-between" align="bottom">
          <Col xs={24} md={16}>
            <Row gutter={8}>
              <Col xs={12} md={1} style={{ display: 'flex', flex: 1, alignItems: 'center', marginRight: 14 }}>
                <Text level={2}>{t('filters')}</Text>
              </Col>
              <Col xs={12} md={4}>
                <FilterWithSearch
                  title={`${t('position')}${
                    searchParams.positions?.length > 0 ? ` (${searchParams.positions.length})` : ''
                  }`}
                  options={positionOptions}
                  onSelectChange={value => onSearchParamChange('positions', value)}
                  selectedOptions={searchParams.positions}
                  placeholder={t('searchByPositionName')}
                  optionLabelField="name"
                />
              </Col>
              <Col xs={12} md={4}>
                <TreeCheckboxFilter
                  label={t('locations')}
                  placeholder={t('filterByLocationPlaceholder')}
                  treeData={locationOptions}
                  checkedKeys={searchParams.location || []}
                  showActionButtons
                  expandable={false}
                  onApply={value => onSearchParamChange('location', value)}
                  fillWidth
                />
              </Col>
              <Col xs={12} md={6}>
                <TreeCheckboxFilter
                  label={t('employmentType')}
                  placeholder={t('filterByEmploymentTypePlaceholder')}
                  treeData={employmentTypeOptions}
                  checkedKeys={searchParams.employment_type || []}
                  showActionButtons
                  expandable={false}
                  onApply={value => onSearchParamChange('employment_type', value)}
                  fillWidth
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={7}>
            <Row>
              <Input.Search
                style={{ zIndex: 1 }}
                allowClear
                placeholder={t('searchStaffRequestTemplateByName')}
                onSearch={value => onSearchParamChange('search', value)}
                defaultValue={searchParams.search}
              />
            </Row>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 0 }} />
        <List
          locale={{
            emptyText: isSearchApplied ? (
              <EmptySearchResult />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <Text style={{ fontSize: '14px', fontWeight: 400, color: colors.primaryText }}>
                    {t('emptyTemplateList')}
                  </Text>
                }
              />
            ),
          }}
          loading={loading && { indicator: <LoadingSpinner width="50px" /> }}
          itemLayout="vertical"
          pagination={{
            ...getPaginationConfig(numRecords, onPageChange),
            defaultCurrent: searchParams.page,
            current: searchParams.page,
          }}
          dataSource={staffRequestsTemplates}
          renderItem={staffRequestTemplate => {
            const {
              created_date: createdDate,
              template_name: templateName,
              employment_type: employmentType,
              location: staffRequestTemplateLocation,
              status,
              position,
            } = staffRequestTemplate;
            return (
              <List.Item style={{ padding: 20 }}>
                <Row gutter={8}>
                  <Col xs={12} md={7}>
                    <Row>
                      <Text style={{ fontSize: '14px', fontWeight: 600, color: colors.textTitle }}>{templateName}</Text>
                    </Row>
                    <Row>
                      <Text style={{ fontSize: '14px', fontWeight: 400, color: colors.secondaryText }}>
                        {staffRequestTemplateLocation?.name}
                      </Text>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Text style={{ fontSize: '14px', fontWeight: 600, color: colors.primaryText }}>
                        {t(`${employmentType}`)}
                      </Text>
                    </Row>
                    <Row>
                      <Text style={{ fontSize: '14px', fontWeight: 400, color: colors.secondaryText }}>
                        {position?.name}
                      </Text>
                    </Row>
                    <Row>
                      <Text style={{ fontSize: '14px', fontWeight: 400, color: colors.secondaryText }}>
                        {`${t('created')} ${moment(createdDate).format(DEFAULT_DATE_FORMAT)}`}
                      </Text>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <StatusTag status={status} />
                  </Col>
                  <Col xs={12} md={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Col>
                      <Button
                        onClick={e => {
                          e.preventDefault();
                          history.push({
                            pathname: routes.editJobTemplate.replace(':id', staffRequestTemplate?.id),
                          });
                        }}
                      >
                        <EditOutlined />
                      </Button>
                    </Col>
                    <Tooltip placement="top" title={t('duplicate')} arrowPointAtCenter>
                      <Button
                        onClick={e => {
                          e.preventDefault();
                          history.push({
                            pathname: routes.createJobTemplate,
                            state: { replicateId: staffRequestTemplate.id },
                          });
                        }}
                      >
                        <CopyOutlined />
                      </Button>
                    </Tooltip>
                    <Col>
                      <Button
                        onClick={e => {
                          e.preventDefault();
                          ConfirmModals.deleteStaffRequestTemplate(t, staffRequestTemplate?.template_name, () =>
                            deleteStaffRequestTemplate(staffRequestTemplate),
                          );
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </List.Item>
            );
          }}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  positions: state.global.positions,
  locations: state.user.locations,
});

export default connect(mapStateToProps)(withTranslation()(StaffRequestTemplateListView));
