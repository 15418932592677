import React, { useEffect, useState } from 'react';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, Row, Typography, Button, Tooltip, message } from 'antd';
import { withTranslation } from 'react-i18next';
import { size, isEmpty } from 'lodash';
import { getOr } from 'lodash/fp';

import clientApi from '../../../../services/clientApi';
import { httpResponseStatuses } from '../../../../constants';

import { colors } from '../../../../styles/colors';

const { Text } = Typography;
const characterLimit = 20;
const intialIndex = 0;
const tagLimit = 29; // 30 count starts at 0;
let id = intialIndex;

const TimesheetTagFormModal = ({ t, category, visible, form, onClose, onUpdate, clientId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [removedTags, setRemovedTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  getFieldDecorator('keys', { initialValue: [] });

  const hasOneOrMoreTags = () => {
    const tags = getFieldValue('tags');
    return tags && size(tags.filter(tag => tag.name)) > 0;
  };

  const validateCategoryName = async categoryName => {
    try {
      await clientApi.validateTimesheetTagCategoryName(clientId, {
        name: categoryName,
      });
    } catch (error) {
      if (error.response.status === httpResponseStatuses.BAD_REQUEST_400) {
        message.error(t('categoryNameAlreadyExistErrorMessage'));
        return false;
      }
    }
    return true;
  };

  const onCreate = async values => {
    const tags = [];
    values.tags.forEach(tag => {
      if (tag?.name) {
        tags.push({ name: tag.name });
      }
    });
    await clientApi.createTimesheetTagCategory(clientId, {
      name: values.name,
      tags,
    });
    message.success(t('tagCategoryCreatedSuccessMessage'));
  };

  const onEdit = async values => {
    const tags = [];
    values.tags.forEach(tag => {
      if (tag?.name) {
        const tagObj = tag.id ? { name: tag.name, id: tag.id } : { name: tag.name };
        tags.push(tagObj);
      }
    });
    await clientApi.editTimesheetTagCategory(clientId, {
      id: category.id,
      name: values.name,
      tags: [...tags, ...removedTags],
    });
    message.success(t('changesSaved'));
  };

  const onSubmit = () => {
    form.validateFieldsAndScroll(async (formErrors, values) => {
      if (!formErrors) {
        setIsLoading(true);
        try {
          if (!category) {
            const categoryNameValid = await validateCategoryName(values.name);
            const canCreate = categoryNameValid && hasOneOrMoreTags();
            if (canCreate) {
              await onCreate(values);
              onUpdate();
              onClose();
            }
          } else {
            let categoryNameValid = true;
            if (values.name.toUpperCase() !== category.name.toUpperCase()) {
              categoryNameValid = await validateCategoryName(values.name);
            }
            const canEdit = categoryNameValid && hasOneOrMoreTags();
            if (canEdit) {
              await onEdit(values);
              onUpdate();
              onClose();
            }
          }
          setIsLoading(false);
        } catch (error) {
          message.warning(t('submitFailed'));
          setIsLoading(false);
        }
      }
    });
  };

  const onRemoveTag = key => event => {
    event.preventDefault();
    setHasUnsavedChanges(true);
    const keys = form.getFieldValue('keys');
    const tag = form.getFieldValue('tags')[key];
    if (tag.id) {
      setRemovedTags([...removedTags, { id: tag.id, is_active: false }]);
    }
    if (keys.length === 1) {
      return;
    }
    setFieldsValue({
      keys: keys.filter(keyItem => keyItem !== key),
    });
  };

  const onAddTag = () => {
    setHasUnsavedChanges(true);
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat((id += 1));
    setFieldsValue({
      keys: nextKeys,
    });
  };

  // work around for manually mapping a tag to it's corresponding keys on edit
  useEffect(() => {
    id = intialIndex;
    if (category) {
      (function initializeForm() {
        category.tags.map(() => {
          onAddTag();
          return null;
        });
      })();
    } else {
      // if not category add one key that will server as initial tag input as specified in figma
      onAddTag();
    }
    setHasUnsavedChanges(false);
    // eslint-disable-next-line
  }, [category]);

  const onCancel = () => {
    if (hasUnsavedChanges) {
      // eslint-disable-next-line no-alert
      if (window.confirm(t('unsavedChanges'))) {
        onClose();
      }
    } else {
      onClose();
    }
  };

  const resetPreviousName = (key, value) => {
    if (value === '' && selectedTag?.name) {
      const tags = getFieldValue('tags');
      tags[key] = selectedTag;
      setFieldsValue({ tags });
    }
  };

  const isTagLimitReached = () => size(getFieldValue('keys')) > tagLimit;

  return (
    <Modal
      destroyOnClose
      visible={visible}
      style={{ top: '20px' }}
      okText={t(category ? 'save' : 'create')}
      onOk={onSubmit}
      okType="v2-primary"
      title={t(category ? 'edit' : 'createTagCategory')}
      onCancel={onCancel}
      confirmLoading={isLoading}
    >
      <Form hideRequiredMark layout="vertical" onSubmit={onSubmit}>
        <Form.Item
          label={
            <>
              <Row>
                <Text style={{ fontWeight: 'bold' }}>{t('categoryName')}</Text>
              </Row>
              <Row>
                <Text>{t('categoryNameSubLabel')}</Text>
              </Row>
            </>
          }
          style={{ marginBottom: '16px', paddingBottom: 0 }}
        >
          {getFieldDecorator('name', {
            rules: [
              { required: true, message: t('categoryNameRequired') },
              { max: characterLimit, message: t('maxCharacterLimit', { limit: characterLimit }) },
            ],
            initialValue: category?.name || '',
          })(
            <Input
              placeholder={t('categoryName')}
              onChange={() => setHasUnsavedChanges(true)}
              style={{ width: '274px' }}
            />,
          )}
        </Form.Item>
        <Form.Item
          label={
            <>
              <Row>
                <Text style={{ fontWeight: 'bold' }}>{t('tag')}</Text>
              </Row>
              <Row>
                <Text>{t('tagSubLabel')}</Text>
              </Row>
            </>
          }
          style={{ marginBottom: 0, paddingBottom: 0 }}
        />
        {getFieldValue('keys') &&
          getFieldValue('keys').map((key, index) => (
            <div key={key}>
              <Form.Item style={{ marginBottom: 0 }} required={false}>
                {getFieldDecorator(`tags[${key}].name`, {
                  rules: [
                    { max: characterLimit, message: t('maxCharacterLimit', { limit: characterLimit }) },
                    {
                      validator: async (_, input) => {
                        if (!isEmpty(input)) {
                          const tags = getFieldValue('tags');
                          const formattedInput = input.replace(/^\s+|\s+$/g, '').toUpperCase();
                          let isDuplicate = false;
                          // eslint-disable-next-line no-shadow
                          tags.forEach((tag, index) => {
                            const formattedName = tag.name ? tag.name.replace(/^\s+|\s+$/g, '').toUpperCase() : '';
                            if (tag && formattedName === formattedInput && key !== index) {
                              isDuplicate = true;
                              // eslint-disable-next-line no-useless-return
                              return;
                            }
                          });
                          if (isDuplicate) {
                            return Promise.reject(new Error(t('duplicateTagName')));
                          }
                        }
                      },
                    },
                  ],
                  initialValue: getOr(null, `tags.${key}.name`, category),
                })(
                  <Input
                    placeholder={t('newTag')}
                    style={{ width: '274px', marginRight: 8 }}
                    onChange={() => setHasUnsavedChanges(true)}
                    onFocus={() => setSelectedTag(getFieldValue('tags')[key])}
                    onBlur={e => resetPreviousName(key, e.target.value)}
                  />,
                )}
                {(category || (!category && index > 0)) && (
                  <Tooltip placement="top" title={t('removeTagTooltip')}>
                    <Button
                      onClick={onRemoveTag(key)}
                      icon={<CloseOutlined />}
                      style={{ color: colors.functionalError }}
                    />
                  </Tooltip>
                )}
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} required={false} key={key}>
                {getFieldDecorator(`tags[${key}].id`, {
                  initialValue: getOr(null, `tags.${key}.id`, category),
                })(<Input style={{ width: '274px', marginRight: 8 }} hidden />)}
              </Form.Item>
            </div>
          ))}
        {!hasOneOrMoreTags() && (
          <Typography.Text style={{ color: colors.functionalError }}>{t('atLeastOneTagErrorMessage')}</Typography.Text>
        )}
        <Form.Item>
          <Tooltip placement="top" title={isTagLimitReached() ? t('tagLimitTooltipMessage') : null}>
            <Button
              style={{ left: -8 }}
              type="link"
              onClick={onAddTag}
              disabled={isTagLimitReached()}
              icon={<PlusCircleOutlined />}
            >
              {t('addTag')}
            </Button>
          </Tooltip>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(withTranslation()(TimesheetTagFormModal));
