import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en.json';
import translationTH from './locales/th.json';
import translationID from './locales/id.json';

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  id: {
    translation: translationID,
  },
  th: {
    translation: translationTH,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      lookupQuerystring: 'lng',
      lookupLocalStorage: 'i18nextLng',
    },
    // Parsing
    resources,
    fallbackLng: 'en',
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

// To turn "en-SG", "en-US"... etc. to "en".
if (i18n.language.includes('en')) {
  i18n.changeLanguage('en');
}

export default i18n;
