import React from 'react';
import { Button, Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import firstGigPostedImg from '../../../../assets/images/first-gig-posted.png';

const FirstGigPostedModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal transparent animationType="fade" visible={visible} onCancel={onClose} footer={null} closable={null}>
      <div style={{ textAlign: 'center' }}>
        <img src={firstGigPostedImg} alt="first gig posted" style={{ marginBottom: 24 }} />
        <Typography.Title level={3} style={{ marginBottom: 8 }}>
          {t('firstGigPostedModalTitle')}
        </Typography.Title>
        <Typography.Paragraph>{t('firstGigPostedModalDesc')}</Typography.Paragraph>
      </div>
      <Button block size="large" type="v2-primary" onClick={onClose}>
        {t('viewMyGig')}
      </Button>
    </Modal>
  );
};

export default FirstGigPostedModal;
