import React from 'react';
import moment from 'moment';
import { Row, Col, Typography, Button } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import { checkAccess } from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';
import { colors } from '../../../../styles/colors';
import LeaveEntryStatusTag from '../LeaveEntryStatusTag';
import { leaveEntryPaymentStatus, leaveEntryStatuses } from '../../../../constants';

const { Text } = Typography;

export default withTranslation()(
  ({
    leaveEntry,
    loading,
    setShowVoidModal,
    setShowRejectModal,
    setSelectedLeaveEntry,
    handleApproveLeaveEntry,
    handleResolveLeaveEntry,
    onActionComplete,
    t,
  }) => {
    const showWarning = leaveEntry.status === leaveEntryStatuses.DISPUTED;
    return (
      <Col style={{ width: '100%', marginLeft: 60, padding: '8px 0px' }}>
        <Row>
          <Text
            style={{
              fontWeight: 600,
              fontSize: 12,
              color: colors.secondaryText,
              lineHeight: '20px',
              textTransform: 'uppercase',
            }}
          >
            {`🌴 ${leaveEntry.leaveEntryType.name}`}
          </Text>
        </Row>
        <Row style={{ marginBottom: 16 }}>
          <Text
            style={{
              fontWeight: 600,
              fontSize: 20,
              color: colors.title,
              lineHeight: '28px',
            }}
          >
            {moment(leaveEntry.date).format('DD MMM YYYY')}
          </Text>
        </Row>
        {showWarning && (
          <Row
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              width: '100%',
              background: colors.tagYellow,
              boxSizing: 'border-box',
              borderRadius: '2px',
              padding: '9px 16px',
              marginBottom: 16,
              border: '1px solid #FFE58F',
            }}
          >
            <Row style={{ width: '100%' }}>
              <Col span={1}>
                <ExclamationCircleFilled style={{ color: colors.functionalWarning }} />
              </Col>
              <Col span={23}>
                <div>
                  <Text style={{ fontSize: 14, color: colors.primaryText, lineHeight: '22px', marginLeft: '9px' }}>
                    {leaveEntry?.disputedReason.description}
                  </Text>
                  <br />
                </div>
              </Col>
            </Row>
          </Row>
        )}
        <Row type="flex" align="middle">
          <div style={{ flexGrow: 1 }}>
            <LeaveEntryStatusTag status={leaveEntry.status} />
          </div>
          {leaveEntry.status === leaveEntryStatuses.DISPUTED && checkAccess(permissions.attendanceResolveButton) && (
            <Button
              loading={loading}
              style={{
                color: colors.workmateGreen,
                borderColor: colors.workmateGreen,
              }}
              onClick={async e => {
                e.stopPropagation();
                // needs to be async, as otherwise onActionComplete triggers before BE updates
                await handleResolveLeaveEntry(leaveEntry);
                setSelectedLeaveEntry({});
                onActionComplete();
              }}
            >
              {/* To avoid confusion for the user, we want the btn to say confirm */}
              {/* This matches the state the resolved leave entry will end up in */}
              {t('Confirm')}
            </Button>
          )}
          {/* Client should be able to void a leave in any status as long as it hasn't been marked as paid */}
          {leaveEntry.status !== leaveEntryStatuses.VOID &&
            leaveEntry.paymentStatus !== leaveEntryPaymentStatus.PAID &&
            checkAccess(permissions.attendanceVoidButton) && (
              <Button
                loading={loading}
                style={{
                  marginLeft: 8,
                  color: colors.functionalError,
                  backgroundColor: colors.white,
                }}
                onClick={e => {
                  e.stopPropagation();
                  setSelectedLeaveEntry(leaveEntry);
                  setShowVoidModal(true);
                }}
              >
                {t('void')}
              </Button>
            )}
          {(leaveEntry.status === leaveEntryStatuses.REQUESTED ||
            leaveEntry.status === leaveEntryStatuses.CONFIRMED) && (
            <Button
              loading={loading}
              style={{
                color: colors.functionalError,
                backgroundColor: colors.white,
                marginLeft: 8,
              }}
              onClick={e => {
                e.stopPropagation();
                setSelectedLeaveEntry(leaveEntry);
                setShowRejectModal(true);
              }}
            >
              {t('reject')}
            </Button>
          )}
          {(leaveEntry.status === leaveEntryStatuses.REQUESTED || leaveEntry.status === leaveEntryStatuses.CONFIRMED) &&
            checkAccess(permissions.attendanceApproveButton) && (
              <Button
                loading={loading}
                style={{
                  fontWeight: 600,
                  color: colors.white,
                  backgroundColor: colors.workmateGreen,
                  marginLeft: 8,
                }}
                onClick={async e => {
                  e.stopPropagation();
                  // needs to be async, as otherwise onActionComplete triggers before BE updates
                  await handleApproveLeaveEntry(leaveEntry);
                  setSelectedLeaveEntry({});
                  onActionComplete();
                }}
              >
                {t('approve')}
              </Button>
            )}
        </Row>
      </Col>
    );
  },
);
