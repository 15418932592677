import React from 'react';
import { Row, Col } from 'antd';

const NumberedPoint = ({ number }) => (
  <div
    style={{
      border: '2px solid #69e4a6',
      borderRadius: '50%',
      width: '24px',
      height: '24px',
      textAlign: 'center',
      marginRight: '8px',
    }}
  >
    <span style={{ color: '#69e4a6' }}>
      <b>{number}</b>
    </span>
  </div>
);

const NumberedListInfo = ({ title, list }) => {
  return (
    <>
      <Row style={{ marginBottom: '16px' }}>{title}</Row>
      {list.map((text, index) => (
        <Row key={index} style={{ marginBottom: '16px' }}>
          <Col span={2}>
            <NumberedPoint number={index + 1} />
          </Col>
          <Col>{text}</Col>
        </Row>
      ))}
    </>
  );
};

export default NumberedListInfo;
