import React from 'react';
import { Input, Row } from 'antd';

const FreeText = ({ choices = [], readonly = true }) => {
  const [choice] = choices;
  return (
    <Row style={{ pointerEvents: readonly ? 'none' : 'auto' }}>
      <Input size="large" placeholder={choice?.description || ''} />
    </Row>
  );
};

export default FreeText;
