import React from 'react';
import { Modal, Col, Row, Typography, Button } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import unpaidOnlinePaymentImg from '../../../assets/images/unpaid_online_payment.png';
import { colors } from '../../../styles/colors';

const UnpaidOnlinePaymentModal = ({ visible, onCancel, email }) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={onCancel}
      width={424}
      footer={
        <Row style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px' }}>
          <Button type="default" onClick={onCancel}>
            <Typography.Text
              style={{
                fontSize: '14px',
                fontWeight: '400',
                color: colors.primaryText,
                textAlign: 'center',
              }}
            >
              {t('Close')}
            </Typography.Text>
          </Button>
        </Row>
      }
    >
      <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Row style={{ marginTop: '18px', marginBottom: '24px' }}>
          <img src={unpaidOnlinePaymentImg} alt="first gig posted" style={{ width: '192px', height: '103px' }} />
        </Row>
        <Row style={{ marginBottom: '12px' }}>
          <Typography.Text
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: colors.neutralDark,
              textAlign: 'center',
            }}
          >
            {t('unpaidInvoiceTitle')}
          </Typography.Text>
        </Row>
        <Typography.Text
          style={{
            fontSize: '14px',
            fontWeight: '400',
            color: colors.neutralMedium,
            textAlign: 'center',
          }}
        >
          <Trans i18nKey="unpaidInvoiceDescription" values={{ email }}>
            Please check your email at
            <Typography.Text
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: colors.neutralMedium,
                textAlign: 'center',
              }}
            >
              {email}
            </Typography.Text>
            to resolve any unpaid invoices before posting new job.
          </Trans>
        </Typography.Text>
      </Col>
    </Modal>
  );
};

export default UnpaidOnlinePaymentModal;
