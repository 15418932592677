// Belongs to helpster-components
import React from 'react';
import { ShareAltOutlined } from '@ant-design/icons';
import { Row, Typography, Popover, Button } from 'antd';

const { Text, Paragraph } = Typography;

const PopoverCopyText = ({ title, text, t }) => {
  const content = (
    <>
      <Row>
        <Text strong>{t(title)}</Text>
      </Row>
      <Paragraph
        copyable
        ellipsis={{ row: 1 }}
        style={{
          marginBottom: 0,
          padding: '8px',
          border: '1px solid lightgray',
          borderRadius: '8px',
          marginTop: '4px',
        }}
      >
        {t(text)}
      </Paragraph>
    </>
  );
  return (
    <Popover destroyTooltipOnHide content={content} trigger="click" placement="bottomLeft">
      <Button icon={<ShareAltOutlined />} style={{ marginRight: '8px' }}>
        {t('Share')}
      </Button>
    </Popover>
  );
};

export default PopoverCopyText;
