import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Row, Col, Button, Typography } from 'antd';

import staffRequestApi from '../../../services/staffRequestApi';
import clientApi from '../../../services/clientApi';
import billingUtils from '../../../utilities/billingUtils';
import delay from '../../../utilities/delay';
import {
  userStatuses,
  paymentServices,
  paymentMethods,
  staffRequestStatuses,
  roles,
  seenModals,
  countryCodeOptions,
  PREPAID_SERVICE_RATE,
} from '../../../constants';
import routes from '../../../routes';

import LargeButton from '../../../shared/components/LargeButton';
import NumberedListInfo from '../../../shared/components/NumberedListInfo';
import CreditCardForm from '../../../shared/components/CreditCardForm';
import BillingForm from '../../../shared/components/BillingForm';
import PotentialMatchesCard from '../../../containers/PotentialMatchesCard';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import FormDisclaimer from '../components/FormDisclaimer';

import SrSummaryCard from './components/SrSummaryCard';
import PaymentHelp from '../../../shared/components/PaymentHelp';

const { Title, Text } = Typography;
const CERDIT_CARD_OPTION = 0;
const PREPAID_OPTION = 1;
class StaffRequestPaymentView extends React.Component {
  state = {
    isLoading: false,
    isSubmitting: false,
    hasCompleteBillingInfo: false,
    hasCompletePaymentInfo: false,
    paymentErrorMessage: '',
    chosenPaymentOption: CERDIT_CARD_OPTION,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const staffRequestId = this.props.match.params.id;
    const staffRequest = await staffRequestApi.fetchStaffRequestById(staffRequestId);
    const hasCompletePaymentInfo = Boolean(this.props.user.paymentMethod);
    const hasCompleteBillingInfo = billingUtils.hasCompleteBillingInfo(this.props.user);
    this.reRouteIfNecessary(staffRequest.status, staffRequestId, hasCompletePaymentInfo, hasCompleteBillingInfo);
    this.setState({ staffRequest, isLoading: false, hasCompleteBillingInfo, hasCompletePaymentInfo });
  }

  reRouteIfNecessary = (status, id, hasCompletePaymentInfo, hasCompleteBillingInfo) => {
    if (
      (hasCompleteBillingInfo && hasCompletePaymentInfo) ||
      ![staffRequestStatuses.DRAFT, staffRequestStatuses.PENDING_REVIEW].includes(status)
    ) {
      this.props.history.push(`${routes.staffRequests}/${id}/`);
    }
  };

  goToEditPage = () => {
    const staffRequestId = this.props.match.params.id;
    this.props.history.push(routes.editStaffRequest.replace(':id', staffRequestId));
  };

  // main entry point for form submission
  submitAllForms = async () => {
    this.setState({ isSubmitting: true, paymentErrorMessage: '' });

    try {
      await this.validateForms();
      await this.submitBillingForm();
      await this.submitPaymentForm();
    } catch (error) {
      this.setState({ isSubmitting: false });
    }
  };

  // 1. Checks all forms that have to be submitted if there are any errors
  validateForms = () => {
    let formError;
    const { chosenPaymentOption, paymentForm, billingForm } = this.state;
    if (paymentForm && chosenPaymentOption === CERDIT_CARD_OPTION) {
      paymentForm.validateFieldsAndScroll(errors => {
        formError = errors;
      });
    }
    if (billingForm) {
      billingForm.validateFieldsAndScroll(errors => {
        formError = errors;
      });
    }
    if (formError) throw new Error(formError);
  };

  // 2. check and submit billing form
  submitBillingForm = async () => {
    const { user } = this.props;
    const { billingForm } = this.state;
    if (!billingForm) return;

    // Submit billing form
    const { prefix, street_1, street_2, province, zip, country, ...payload } = billingForm.getFieldsValue();
    payload.billing_address = { street_1, street_2, province, zip, country };
    payload.billing_contact_phone = `${prefix}${payload.billing_contact_phone}`;
    payload.id = user.clientId;
    const response = await clientApi.editClient(payload);
    return response;
  };

  // 3. check and submit payment form and route user to different routes and do OTP for credit card/validate contract and ....
  submitPaymentForm = async () => {
    const { user } = this.props;
    const { paymentForm, chosenPaymentOption, hasCompletePaymentInfo, staffRequest } = this.state;

    // Rre-paid method, update payment method and go to view payment summary
    if (chosenPaymentOption === PREPAID_OPTION) {
      await clientApi.editClient({
        id: user.clientId,
        payment_method: paymentMethods.PREPAID,
        service_fee: PREPAID_SERVICE_RATE,
      });
      this.props.history.push(routes.prepaidDetail.replace(':id', staffRequest.id));
      return;
    }

    if (hasCompletePaymentInfo) {
      this.submitStaffRequestAndReroute();
      return;
    }

    // Credit card method
    // If Omise, callback would to re-route to awaiting-payments
    // This is because, first-time Omise customers have to go through OTP process
    let afterSubmitCallback = async () => {
      await this.checkIfUserCanBeActive();
      this.submitStaffRequestAndReroute();
    };
    if (billingUtils.getPaymentService(user.country.code) === paymentServices.OMISE) {
      afterSubmitCallback = async chargeResponse => {
        await this.checkIfUserCanBeActive();
        this.handleOTPCharge(chargeResponse);
      };
    }

    const formValues = paymentForm.getFieldsValue();
    await billingUtils.handleCreditCardSubmission({
      handleCreditCardError: this.handleCreditCardError,
      afterSubmitCallback,
      staffRequestId: staffRequest.id,
      clientId: user.clientId,
      countryCode: user.country.code,
      cardNumber: formValues.cardNumber,
      expDate: formValues.expDate,
      cvc: formValues.cvc,
      cardHolderName: formValues.cardHolderName,
    });
  };

  // Submit SR to pending_review status & move to next page
  submitStaffRequestAndReroute = async () => {
    const { staffRequest } = this.state;
    const response = await staffRequestApi.submitStaffRequest(staffRequest.id);
    if (response) {
      localStorage.setItem(seenModals.SHOW_SUBMITTED_SR, true);
      this.props.fetchClientAndManager();
      this.props.history.push(routes.home);
    }
    this.setState({ isSubmitting: false });
  };

  handleOTPCharge = async chargeResponse => {
    // Delay to show "Redirecting..." text on button long enough for users to read
    localStorage.setItem('charge_id', chargeResponse.charge_id);
    await delay(1500);
    // Go to authorize url
    window.location.replace(chargeResponse.authorize_uri);
  };

  handleCreditCardError = errorMessage => {
    this.setState({ paymentErrorMessage: errorMessage, isSubmitting: false });
  };

  setPaymentForm = paymentForm => {
    this.setState({ paymentForm });
  };

  setBillingForm = billingForm => {
    this.setState({ billingForm });
  };

  // Only use this function if client is paying by credit card
  checkIfUserCanBeActive = async () => {
    const { user } = this.props;
    if (user.status === userStatuses.ACTIVE) {
      return;
    }

    await clientApi.approve(user.clientId);
  };

  render() {
    const {
      isLoading,
      isSubmitting,
      staffRequest,
      chosenPaymentOption,
      hasCompleteBillingInfo,
      hasCompletePaymentInfo,
      paymentErrorMessage,
    } = this.state;
    const { user, t } = this.props;
    if (isLoading || !staffRequest) return <LoadingSpinner />;

    const creditCardSelected = chosenPaymentOption === CERDIT_CARD_OPTION;
    const isAdmin = user.role === roles.ADMIN;
    const isRedirecting = isSubmitting && creditCardSelected && user.country.code === countryCodeOptions.THAILAND;
    return (
      <Row type="flex" justify="space-around" gutter={{ md: 24, lg: 0 }}>
        <Col sm={24} md={16} style={{ maxWidth: '572px' }}>
          {/* Header */}
          <div style={{ marginBottom: '24px' }}>
            <Row>
              <Title level={3} style={{ marginBottom: '8px' }}>
                {t('Payment')}
              </Title>
            </Row>
            <Row>
              <Text type="secondary">{t('Review your payment method to submit your request.')}</Text>
            </Row>
          </div>

          {/* Payment method */}
          {!hasCompletePaymentInfo && (
            <section style={{ marginBottom: '48px' }}>
              <div style={{ marginBottom: '24px' }}>
                <Row>
                  <Title level={4} style={{ marginBottom: '8px' }}>
                    {t('Payment method')}
                  </Title>
                </Row>
                <Row>
                  <Text type="secondary">{t('Make payment via credit card or monthly invoicing.')}</Text>
                </Row>
              </div>

              <Row type="flex" gutter={24} style={{ marginBottom: '32px' }}>
                <Col span={12}>
                  <LargeButton
                    active={chosenPaymentOption === CERDIT_CARD_OPTION}
                    name={t('creditCard')}
                    iconType="credit-card"
                    onClick={() => this.setState({ chosenPaymentOption: CERDIT_CARD_OPTION })}
                  />
                </Col>
                {user.country && user.country.code === countryCodeOptions.INDONESIA && (
                  <Col span={12}>
                    <LargeButton
                      active={chosenPaymentOption === PREPAID_OPTION}
                      name={t('Prepaid transfer')}
                      iconType="wallet"
                      onClick={() => this.setState({ chosenPaymentOption: PREPAID_OPTION })}
                    />
                  </Col>
                )}
              </Row>

              <Row style={{ marginBottom: '24px' }}>
                {creditCardSelected && (
                  <CreditCardForm errorMessage={paymentErrorMessage} setPaymentForm={this.setPaymentForm} t={t} />
                )}
                {chosenPaymentOption === PREPAID_OPTION && (
                  <NumberedListInfo
                    title={t(
                      'Make a prepaid bank transfer through your preferred online banking method or ATM. Your request will be posted after we receive your payment.',
                    )}
                    list={[
                      t('Enter your billing details below.'),
                      t('Pay the amount due to the bank details in the next screen.'),
                      t('We will activate your account after we verify your payment.'),
                    ]}
                  />
                )}
              </Row>
              <PaymentHelp />
            </section>
          )}

          {/* Billing information */}
          {!hasCompleteBillingInfo && (
            <section style={{ marginBottom: '32px' }}>
              <Row style={{ marginBottom: '24px' }} justify="space-between">
                <Col span={24}>
                  <Title level={4} style={{ marginBottom: '8px' }}>
                    {t('Billing information')}
                  </Title>
                </Col>
                <Col span={24}>
                  <Text type="secondary">{t(`Enter your company's billing information for invoicing.`)}</Text>
                </Col>
              </Row>
              <BillingForm isAdmin={isAdmin} client={this.props.user} setBillingForm={this.setBillingForm} />
            </section>
          )}

          <section style={{ marginBottom: '32px' }}>
            <FormDisclaimer />
          </section>

          <section style={{ marginBottom: '64px' }}>
            <Button loading={isSubmitting} size="large" type="v2-primary" onClick={() => this.submitAllForms()}>
              {chosenPaymentOption === PREPAID_OPTION ? (
                t('Continue')
              ) : (
                <>{isRedirecting ? t('Redirecting...') : t('Submit request')}</>
              )}
            </Button>
          </section>
        </Col>

        <Col sm={24} md={8}>
          <section style={{ marginBottom: '40px', borderTop: '1px solid #00000017' }}>
            <SrSummaryCard staffRequest={staffRequest} />
            <Button icon={<ArrowLeftOutlined />} type="link" onClick={this.goToEditPage} style={{ padding: 0 }}>
              {t('Edit request')}
            </Button>
          </section>

          <section style={{ borderTop: '1px solid #00000017' }}>
            <PotentialMatchesCard staffRequest={staffRequest} />
          </section>
        </Col>
      </Row>
    );
  }
}

export default StaffRequestPaymentView;
