import React, { useState } from 'react';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';
import { Button, DatePicker, Row, Typography } from 'antd';

import { DATE_FORMAT } from '../../constants';
import { colors } from '../../styles/colors';

const { Text } = Typography;
const { WeekPicker } = DatePicker;

export default ({ onChange, selectedWeekStart, loading, disableFutureDates, disablePastDates }) => {
  const [open, setOpen] = useState(false);

  const startDate = selectedWeekStart.clone().startOf('isoWeek');
  const endDate = selectedWeekStart.clone().endOf('isoWeek');
  const weekDisplayString = `${startDate.format(DATE_FORMAT)} - ${endDate.format(DATE_FORMAT)}`;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: 'inherit' }}>
      <Button
        style={{
          minWidth: '320px',
          padding: '0px',
        }}
        onClick={() => setOpen(!open)}
      >
        <Row type="flex" justify="space-between">
          <Text strong style={{ fontSize: '20px', marginLeft: '12px', color: open && colors.grey }}>
            {weekDisplayString}
          </Text>
          <CalendarOutlined style={{ color: open && colors.grey, margin: '7px 12px 0px 0px' }} />
        </Row>
      </Button>
      <WeekPicker
        defaultValue={selectedWeekStart}
        value={selectedWeekStart}
        onChange={onChange}
        loading={loading}
        open={open}
        style={{ visibility: 'hidden', width: 0, height: 0, padding: 0 }}
        onOpenChange={openValue => setOpen(openValue)}
        disabledDate={current => {
          if (disableFutureDates) {
            return current > moment().endOf('isoWeek');
          }
          if (disablePastDates) {
            return current < moment().startOf('isoWeek');
          }
        }}
      />
    </div>
  );
};
