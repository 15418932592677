import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, Row, Select, message, Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// Taken from V1 folders
import clientApi from '../../../../services/clientApi';
import { httpResponseStatuses } from '../../../../constants';

const { Text } = Typography;

class RoleFormModal extends React.Component {
  state = {
    loading: false,
    positions: [],
  };

  async componentDidMount() {
    const { positions } = this.props;
    this.setState({ positions });
  }

  handleClose = () => {
    this.props.form.resetFields();
    this.props.onClose();
  };

  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll(async (formErrors, values) => {
      if (!formErrors) {
        this.setState({ loading: true });
        try {
          const roleNameValid = await this.validateRoleName(values);
          if (roleNameValid) {
            if (!this.props.role) {
              await this.createRole(values);
            } else {
              await this.editRole(values);
            }
            this.props.onUpdate();
            this.handleClose();
          }
          this.setState({ loading: false });
        } catch (error) {
          this.setState({ loading: false });
        }
      }
    });
  };

  createRole = async values => {
    const { clientId, t } = this.props;
    await clientApi.createRole(clientId, {
      position: values.positionId,
      name: values.name,
    });
    message.success(t('newRoleCreated', { roleName: values.name }));
  };

  editRole = async values => {
    const { clientId, role, t } = this.props;
    await clientApi.editRole(clientId, {
      id: role.id,
      position: values.positionId,
      name: values.name,
    });
    message.success(t('Changes saved'));
  };

  validateRoleName = async values => {
    const { clientId, t } = this.props;
    try {
      await clientApi.validateRoleName(clientId, {
        position: values.positionId,
        name: values.name,
      });
    } catch (error) {
      if (error.response.status === httpResponseStatuses.BAD_REQUEST_400) {
        message.error(t('roleNameExists'));
        return false;
      }
    }
    return true;
  };

  render() {
    const { role, visible, t } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        destroyOnClose
        visible={visible}
        style={{ top: '20px' }}
        okText={t(role ? 'Save' : 'createRole')}
        okType="v2-primary"
        cancelText={t('cancel')}
        title={t(role ? 'editRole' : 'createRole')}
        onOk={this.handleSubmit}
        onCancel={this.handleClose}
        confirmLoading={this.state.loading}
      >
        <Form hideRequiredMark layout="vertical" onSubmit={this.handleSubmit}>
          <Form.Item
            label={
              <>
                <Row>
                  <Text style={{ fontWeight: 'bold' }}>{t('Job position')}</Text>
                </Row>
                <Row>
                  <Text>{t('roleFormJobPositionSubLabel')}</Text>
                </Row>
              </>
            }
            style={{ marginBottom: '16px', paddingBottom: 0 }}
          >
            {getFieldDecorator('positionId', {
              rules: [{ required: true, message: t('pleaseSelectAJobPosition') }],
              initialValue: role && role.position ? role.position.id : undefined,
            })(
              <Select placeholder={t('Select a job position')}>
                {this.state.positions.map((option, index) => {
                  const { id, name } = option;
                  return (
                    <Select.Option key={index} value={id}>
                      <Typography.Text>{t(name)}</Typography.Text>
                    </Select.Option>
                  );
                })}
              </Select>,
            )}
          </Form.Item>
          <Form.Item
            hasFeedback
            label={
              <>
                <Row>
                  <Text style={{ fontWeight: 'bold' }}>{t('role')}</Text>
                </Row>
                <Row>
                  <Text>{t('roleFormRoleNameSublabel')}</Text>
                </Row>
              </>
            }
            style={{ marginBottom: '16px', paddingBottom: 0 }}
          >
            {getFieldDecorator('name', {
              rules: [
                { required: true, message: t('Please input a name') },
                { max: 30, message: t('maxCharacterNameError', { maxLength: 30 }) },
              ],
              initialValue: role ? role.name : undefined,
            })(<Input placeholder={t('admin')} />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  positions: state.global.positions,
});

export default connect(mapStateToProps, undefined)(Form.create()(withTranslation()(RoleFormModal)));
