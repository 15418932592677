import React from 'react';
import moment from 'moment-timezone';
import {
  Row,
  message,
  Modal,
  Typography,
  Button,
  Col,
  TimePicker,
  Divider,
  Input,
  DatePicker,
  Checkbox,
  Tag,
  Alert,
  Select,
  Radio,
  Tooltip,
} from 'antd';
import { Form } from '@ant-design/compatible';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import RRule, { rrulestr } from 'rrule';
import shiftApi from '../../../services/shiftApi';
import scheduleApi from '../../../services/scheduleApi';
import { colors } from '../../../styles/colors';
import { recurrenceOptions, attendanceStatuses, TIME_FORMAT_24_HOUR, clockinMethods } from '../../../constants';
import scheduleUtils from '../../../utilities/scheduleUtils';
import datetimeUtils from '../../../utilities/datetimeUtils';
import ClockinMethodFormItems from '../../../shared/components/ClockinMethodFormItems';
import formUtils from '../../../utilities/formUtils';
import { DAY_DATE } from '../../../styles/dates';

const { Item } = Form;
const { Title, Text } = Typography;

class EditScheduleFormModal extends React.Component {
  state = {
    isSubmitting: false,
    showShiftCancelWarning: false,
    showAdvancedSettings: false,
  };

  handleStartDateChange = momentDate => {
    const { schedule, form } = this.props;
    this.setState({ showShiftCancelWarning: false });
    if (momentDate && momentDate > moment(schedule.start_date)) {
      this.setState({ showShiftCancelWarning: true });
    }
    const startDateDay = momentDate?.isoWeekday() - 1;
    const selectedRecurrences = form.getFieldValue('recurrence');
    if (!selectedRecurrences.includes(startDateDay)) {
      const newRecurrences = [...selectedRecurrences, startDateDay];
      form.setFieldsValue({ recurrence: newRecurrences });
    }
  };

  handleEndDateChange = momentDate => {
    const { schedule } = this.props;
    this.setState({ showShiftCancelWarning: false });
    if (momentDate && momentDate < moment(scheduleUtils.getEndDateFromRecurrences(schedule.recurrences))) {
      this.setState({ showShiftCancelWarning: true });
    }
  };

  handleRecurrenceChange = (option, checked) => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    const selectedRecurrences = getFieldValue('recurrence');

    if (checked) {
      selectedRecurrences.push(option.value);
      setFieldsValue({ recurrence: selectedRecurrences });
    } else {
      const nextSelectedRecurrences = selectedRecurrences.filter(fieldValue => fieldValue !== option.value);
      setFieldsValue({ recurrence: nextSelectedRecurrences });
    }
  };

  formDataToEditPayload = (schedule, values, timezone) => {
    const { name } = values;

    // Fields that don't change for schedule edit
    const { id } = schedule;
    const { staff_required } = schedule;
    const { duration } = schedule;
    const { qr_code_clock_in } = schedule;

    // Get new start date from values.start_date
    const yearInt = moment(values.start_date).year();
    const monthInt = moment(values.start_date).month();
    const dayInt = moment(values.start_date).date();

    // Get old start time from schedule.start_date
    // new start time is updated in the bulk edit shifts endpoint
    const hourInt = moment.tz(schedule.start_date, timezone).hour();
    const minuteInt = moment.tz(schedule.start_date, timezone).minute();
    const startDate = moment
      .tz(
        {
          year: yearInt,
          month: monthInt,
          day: dayInt,
          hour: hourInt,
          minute: minuteInt,
        },
        timezone,
      )
      .toISOString();

    // Get end date from values.end_date and values.end_time
    const endDate = moment
      .tz(
        {
          year: moment(values.end_date).year(),
          month: moment(values.end_date).month(),
          day: moment(values.end_date).date(),
          hour: moment(values.end_time).hour(),
          minute: moment(values.end_time).minute(),
        },
        timezone,
      )
      .toISOString();

    const recurrences = new RRule({
      freq: RRule.WEEKLY,
      byweekday: values.recurrence,
      until: endDate,
    }).toString();

    return {
      id,
      name,
      staff_required,
      start_date: startDate,
      end_date: endDate,
      duration,
      recurrences,
      qr_code_clock_in,
      unpaid_break: values.unpaid_break,
      break_duration_in_minutes: values.break_duration_in_minutes,
    };
  };

  notifyWorkersOfShiftTimeUpdate = async shift => {
    const partner_ids_to_notify = shift.attendances
      .filter(att => att.status === attendanceStatuses.ASSIGNED && att.confirmed)
      .map(att => att.partner_id)
      .join(',');
    if (partner_ids_to_notify.length > 0) {
      await shiftApi.notifyTimeUpdate(shift.id, { partner_id: partner_ids_to_notify });
    }
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { form, shift, schedule, onUpdate, timezone, t } = this.props;

    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ isSubmitting: true });
        try {
          const payload = this.formDataToEditPayload(schedule, values, timezone);
          await scheduleApi.editSchedule(payload);

          const startTime = values.start_time;
          const endTime = values.end_time;
          const duration = datetimeUtils.getDurationFromStartTimeAndEndTime(startTime, endTime).format('HH:mm:00');

          const qrOverrideAllowed = values.clockin_method === clockinMethods.QR_SCAN_OR_SELFIE;
          const fixedLocation = values.fixed_location;

          await scheduleApi.bulkEditShifts(schedule.id, shift.id, {
            staff_required: schedule.staff_required,
            start_time: startTime.toISOString(),
            duration,
            qr_override_allowed: qrOverrideAllowed,
            qr_code_clock_in: true,
            fixed_location: fixedLocation,
            unpaid_break: values.unpaid_break,
            break_duration_in_minutes: values.break_duration_in_minutes,
          });
          onUpdate();
          message.success(t('saveShiftsSuccess'));
        } catch (error) {
          message.warning(t('saveShiftsError'));
        } finally {
          this.setState({ isSubmitting: false, showShiftCancelWarning: false });
        }
      }
    });
  };

  handleCancel = () => {
    this.setState({ showShiftCancelWarning: false });
    this.props.form.resetFields();
    this.props.onCancel();
  };

  render() {
    const { schedule, shift, visible, t, openDeleteShiftModal, timezone } = this.props;
    const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
    const { isSubmitting, showShiftCancelWarning, showAdvancedSettings } = this.state;

    const rrule = rrulestr(schedule.recurrences);

    const startDate = moment(schedule.start_date);
    const endDate = schedule.end_date
      ? moment(schedule.end_date)
      : moment(scheduleUtils.getEndDateFromRecurrences(schedule.recurrences));
    const reucurringDays = rrule.options.byweekday;

    const startTime = moment.tz(shift.start_time, timezone);
    const endTime = datetimeUtils.getEndTimeFromStartTimeAndDuration(startTime, shift.duration);

    return (
      <Modal
        visible={visible}
        onCancel={() => this.handleCancel()}
        title={<Title level={4}>{t('editSchedule')}</Title>}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              style={{ color: colors.red }}
              onClick={() => {
                openDeleteShiftModal();
              }}
            >
              {t('delete')}
            </Button>
            <div>
              <Button onClick={() => this.handleCancel()}>{t('cancel')}</Button>
              <Button
                type="v2-primary"
                loading={isSubmitting}
                onClick={e => {
                  this.handleSubmit(e);
                }}
              >
                {t('Save')}
              </Button>
            </div>
          </div>
        }
      >
        <Form hideRequiredMark colon={false} className="v2-shifts-form">
          <Row type="flex" gutter={24}>
            <Col span={24}>
              <Text strong>{t('schedule')}</Text>
            </Col>
            <Col span={24} style={{ marginBottom: '10px' }}>
              {t('editScheduleDescription')}
            </Col>
            <Col span={12}>
              <Item label={t('location')} style={{ marginBottom: '4px' }}>
                {shift.location.name}
              </Item>
            </Col>
            <Col span={12}>
              <Item label={t('position')} style={{ marginBottom: '4px' }}>
                {shift.position?.name}
              </Item>
            </Col>
            <Col span={24}>
              <Item label={t('scheduleTitle')} style={{ marginBottom: '8px' }}>
                {getFieldDecorator('name', {
                  initialValue: schedule.name,
                  rules: [{ required: true, message: t('fieldIsRequired') }],
                })(<Input style={{ width: '100%' }} />)}
              </Item>
            </Col>

            <Col xs={12}>
              <Item label={t('startDate')} style={{ marginBottom: '8px' }}>
                {getFieldDecorator('start_date', {
                  initialValue: startDate,
                  rules: [{ required: true, message: t('fieldIsRequired') }],
                })(
                  <DatePicker
                    allowClear={false}
                    format="DD-MM-YYYY"
                    disabled={startDate <= moment().endOf('day')}
                    disabledDate={current => current < moment().endOf('day')}
                    style={{ width: '100%' }}
                    onChange={this.handleStartDateChange}
                  />,
                )}
              </Item>
            </Col>
            <Col xs={12}>
              <Item label={t('endDate')} style={{ marginBottom: '8px' }}>
                {getFieldDecorator('end_date', {
                  initialValue: endDate,
                  rules: [
                    {
                      validator: (rule, value, callback) => {
                        if (value && getFieldValue('start_date')) {
                          const daysApart = value.diff(getFieldValue('start_date'), 'days');
                          if (daysApart > 365) {
                            // Set error on form
                            callback(t('endDateExceedYearError'));
                          }
                          if (daysApart < 0) {
                            callback(t('endDateEarlierThanStartDateError'));
                          }
                        }
                        // Have to be called to validate the rest of the fields.
                        callback();
                      },
                    },
                    { required: true, message: t('fieldIsRequired') },
                  ],
                })(
                  <DatePicker
                    format="DD-MM-YYYY"
                    disabled={endDate < moment().startOf('day')}
                    disabledDate={current => current < moment().endOf('day')}
                    style={{ width: '100%' }}
                    onChange={this.handleEndDateChange}
                  />,
                )}
              </Item>
            </Col>
            <Col xs={12}>
              <Item label={t('startTime')} style={{ marginBottom: '4px' }}>
                {getFieldDecorator('start_time', {
                  initialValue: startTime,
                  rules: [{ required: true, message: t('fieldIsRequired') }],
                })(
                  <TimePicker
                    minuteStep={15}
                    format={TIME_FORMAT_24_HOUR}
                    defaultOpenValue={moment('00:00', TIME_FORMAT_24_HOUR)}
                    style={{ width: '100%' }}
                  />,
                )}
              </Item>
            </Col>
            <Col xs={12}>
              <Item label={t('endTime')} style={{ marginBottom: '4px' }}>
                {getFieldDecorator('end_time', {
                  initialValue: endTime,
                  rules: [{ required: true, message: t('fieldIsRequired') }],
                })(
                  <TimePicker
                    minuteStep={15}
                    format={TIME_FORMAT_24_HOUR}
                    defaultOpenValue={moment('00:00', TIME_FORMAT_24_HOUR)}
                    style={{ width: '100%' }}
                  />,
                )}
              </Item>
            </Col>
            <Col span={12}>
              <Item label={t('totalBreakDuration')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                {getFieldDecorator('break_duration_in_minutes', {
                  rules: [{ required: true, message: t('fieldIsRequired') }],
                  initialValue: schedule.break_duration_in_minutes || 0,
                })(
                  <Select
                    style={{ width: '100%' }}
                    options={formUtils.getBreakDurationChoices(t)}
                    onChange={selectedInput => setFieldsValue({ break_duration_in_minutes: selectedInput })}
                  />,
                )}
              </Item>
            </Col>
            <Col span={12}>
              <Item label={t('breakType')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                {getFieldDecorator('unpaid_break', {
                  rules: [{ required: true, message: t('fieldIsRequired') }],
                  initialValue: Boolean(schedule.unpaid_break),
                })(
                  <Radio.Group
                    className="v2-radio-wrapper"
                    style={{ width: '100%' }}
                    onChange={e =>
                      setFieldsValue({
                        unpaid_break: e.target.value,
                      })
                    }
                  >
                    {[
                      { value: true, label: t('unpaidBreakType') },
                      { value: false, label: t('paidBreakType') },
                    ].map((option, index) => (
                      <Radio key={index} value={option.value}>
                        {option.label}
                      </Radio>
                    ))}
                  </Radio.Group>,
                )}
              </Item>
            </Col>
            <Col span={24}>
              <Item label={t('whatDaysWorkersWork')} style={{ marginBottom: '8px' }} labelCol={{ span: 24 }}>
                {getFieldDecorator('recurrence', {
                  initialValue: reucurringDays,
                  rules: [
                    {
                      type: 'array',
                      min: 1,
                      required: true,
                      message: t('fieldIsRequired'),
                    },
                  ],
                })(
                  <Checkbox.Group style={{ width: '100%' }}>
                    <Row type="flex" className="v2-checkable-tag-wrapper">
                      {[...recurrenceOptions]
                        .sort((a, b) => a.value - b.value)
                        .map((option, index) => {
                          const selectedRecurrences = getFieldValue('recurrence');
                          const currentStartDate = getFieldValue('start_date');
                          const startDateName =
                            currentStartDate?.format(DAY_DATE) ||
                            moment()
                              .startOf('week')
                              .format(DAY_DATE);
                          const startDateDay = currentStartDate?.isoWeekday() - 1;
                          const isStartDateDaySelected = startDateDay === option.value;
                          if (isStartDateDaySelected) {
                            return (
                              <Tooltip title={t('schedStartsOn', { day: startDateName })}>
                                <Tag.CheckableTag
                                  key={index}
                                  checked
                                  style={{
                                    padding: '5px 8px',
                                    marginBottom: '5px',
                                    width: '54px',
                                    textAlign: 'center',
                                    border: '1px solid #D9D9D9',
                                    cursor: 'not-allowed',
                                    opacity: '0.5',
                                  }}
                                >
                                  {t(option.label)}
                                </Tag.CheckableTag>
                              </Tooltip>
                            );
                          }
                          return (
                            <Tag.CheckableTag
                              key={index}
                              checked={selectedRecurrences.includes(option.value)}
                              onChange={checked => this.handleRecurrenceChange(option, checked)}
                              style={{
                                padding: '5px 8px',
                                marginBottom: '5px',
                                width: '54px',
                                textAlign: 'center',
                                border: `1px solid ${colors.componentBorder}`,
                              }}
                            >
                              {t(option.label)}
                            </Tag.CheckableTag>
                          );
                        })}
                    </Row>
                  </Checkbox.Group>,
                )}
              </Item>
            </Col>

            <Divider />
            <Col span={24}>
              <Button
                onClick={() => this.setState({ showAdvancedSettings: !showAdvancedSettings })}
                style={{ color: colors.black, border: 'none', boxShadow: 'none', padding: 0 }}
                icon={showAdvancedSettings ? <DownOutlined /> : <RightOutlined />}
              >
                {t('advancedClockInSettings')}
              </Button>
            </Col>
            <Col span={24} style={{ marginTop: 10, display: showAdvancedSettings ? 'block' : 'none' }}>
              <ClockinMethodFormItems
                getFieldDecorator={getFieldDecorator}
                getFieldValue={getFieldValue}
                setFieldsValue={setFieldsValue}
                initialValues={{
                  clockin_method: shift?.qr_override_allowed
                    ? clockinMethods.QR_SCAN_OR_SELFIE
                    : clockinMethods.QR_SCAN_ONLY,
                  fixed_location: shift?.fixed_location,
                }}
              />
            </Col>
          </Row>
        </Form>
        {showShiftCancelWarning && (
          <Alert
            style={{ margin: '12px 0' }}
            showIcon
            type="warning"
            message={
              <>
                <Row>{t('reduceScheduleWarningTitle')}:</Row>
                <Row>• {t('reduceScheduleWarningPoint1')}</Row>
                <Row>• {t('reduceScheduleWarningPoint2')}</Row>
              </>
            }
          />
        )}
      </Modal>
    );
  }
}
export default Form.create()(withTranslation()(EditScheduleFormModal));
