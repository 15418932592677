/* eslint-disable dot-notation */
import React from 'react';
import { Pie } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import { Row, Col, Typography, Card, Empty } from 'antd';

import CubeJsQueryRenderer from '../../../shared/components/CubeJsQueryRenderer';
import ToolTipWithInfo from '../../../shared/components/ToolTipWithInfo';
import { colors } from '../../../styles/colors';
import { getLabelColors } from '../../../utilities/analyticsUtils';

const { Text } = Typography;

class WorkerChurnReasonsPieChart extends React.Component {
  pieRender = ({ resultSet }) => {
    const labelColors = getLabelColors(resultSet.series().length);
    const data = {
      labels: resultSet.categories().map(c => this.formatLabel(c.category)),
      datasets: resultSet.series().map(s => ({
        label: s.title,
        data: s.series.map(r => r.value),
        backgroundColor: labelColors,
        hoverBackgroundColor: labelColors,
      })),
    };
    const legend = {
      display: true,
      position: 'right',
      fullWidth: true,
      reverse: false,
    };
    const options = {
      tooltips: {
        callbacks: {
          // eslint-disable-next-line no-shadow
          title: (tooltipItem, data) => data['labels'][tooltipItem[0]['index']],
          // eslint-disable-next-line no-shadow
          label: (tooltipItem, data) => {
            const number = data['datasets'][0]['data'][tooltipItem['index']];
            const dataset = data['datasets'][0];
            const percent = Math.round(
              (dataset['data'][tooltipItem['index']] / Object.values(dataset['_meta'])[0].total) * 100,
            );
            return `${number} (${percent}%)`;
          },
        },
        backgroundColor: colors.white,
        titleFontColor: colors.black,
        bodyFontColor: colors.black,
        borderColor: colors.grey,
        borderWidth: 0.5,
      },
    };
    return data.datasets.length > 0 ? <Pie data={data} options={options} legend={legend} /> : <Empty />;
  };

  formatQueryData = (timePeriod, clientId, positionIds, locationIds) => {
    const query = {
      filters: [
        {
          member: 'ClientsClient.id',
          operator: 'equals',
          values: [clientId.toString()],
        },
        {
          member: 'RecruitmentEmployment.status',
          operator: 'equals',
          values: ['cancelled'],
        },
        {
          member: 'RecruitmentEmployment.cancellationReason',
          operator: 'notEquals',
          values: ['staff_request_reassignment', 'cancelled'],
        },
      ],
      timeDimensions: [
        {
          dimension: 'RecruitmentEmployment.endDate',
          dateRange: timePeriod,
        },
      ],
    };
    if (positionIds && positionIds.length > 0) {
      query.filters.push({
        member: 'PartnersPosition.id',
        operator: 'equals',
        values: positionIds,
      });
    }
    if (locationIds && locationIds.length > 0) {
      query.filters.push({
        member: 'ClientsLocation.id',
        operator: 'equals',
        values: locationIds,
      });
    }
    return query;
  };

  formatLabel = label => {
    const { t } = this.props;
    const setLabels = {
      other: t('other'),
      resigned: t('workerResigned'),
      worker_suspended: t('workerSuspended'),
      reduced_demand: t('workersYouCancelled'),
      absence_without_notice: t('absentWithoutNotice'),
      inappropriate_behaviour: t('cancelledForQualityReasons'),
      no_show: t('noShowPlain'),
      underperformance: t('cancelledForQualityReasons'),
    };
    return setLabels[label];
  };

  render() {
    const { timePeriod, clientId, positionIds, locationIds, t } = this.props;
    const formattedProps = this.formatQueryData(timePeriod, clientId, positionIds, locationIds);
    return (
      <Card style={{ width: '100%', marginBottom: '16px' }}>
        <Row style={{ padding: '16px' }}>
          <Col span={24}>
            <Row type="flex" justify="space-between" style={{ alignItems: 'center', marginBottom: '24px' }}>
              <Col>
                <Text strong style={{ fontSize: '20px', paddingRight: '8px' }}>
                  {t('workerChurnReasons')}
                </Text>
                <ToolTipWithInfo
                  infoText={t('Breakdown of resignation reasons provided by workers.')}
                  color={colors.blue}
                />
              </Col>
            </Row>
            <CubeJsQueryRenderer
              measures={['RecruitmentEmployment.count']}
              timeDimensions={formattedProps.timeDimensions}
              filters={formattedProps.filters}
              dimensions={['RecruitmentEmployment.cancellationReason']}
              chartFunction={this.pieRender}
              pivotConfig={{
                x: ['RecruitmentEmployment.cancellationReason'],
                y: ['measures'],
                fillMissingDates: true,
                joinDateRange: false,
              }}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}

export default withTranslation()(WorkerChurnReasonsPieChart);
