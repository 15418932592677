import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import { message, Button, Spin } from 'antd';
import moment from 'moment-timezone';
import { LoadingOutlined } from '@ant-design/icons';
import staffRequestApi from '../../../services/staffRequestApi';
import { DATE_FORMAT_WITH_TIME } from '../../../constants';

const DownloadFormResponsesLink = ({ staffRequestId, totalResponses = 0 }) => {
  const [csvData, setCsvData] = useState(null);
  const [filename, setFilename] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const exportToCsvLink = useRef(null);

  const { t } = useTranslation();

  const getCSVFilename = () => {
    const currentDate = moment().format(DATE_FORMAT_WITH_TIME);
    const [date, time] = currentDate.split(' ');
    return `${staffRequestId}_${date}_${time}`;
  };

  const handleDownloadLink = async () => {
    try {
      setIsLoading(true);
      const data = await staffRequestApi.downloadStaffRequestFormSubmissions(staffRequestId, 'csv');
      setCsvData(data);
      const csvFilename = getCSVFilename();
      setFilename(csvFilename);
      exportToCsvLink.current.link.click();
    } catch (error) {
      message.error({
        content: t('An unexpected error has occured.'),
        duration: 4,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabledLink = totalResponses === 0;

  return (
    <>
      <Button
        style={{
          cursor: isDisabledLink ? 'not-allowed' : 'pointer',
          height: 'auto',
          lineHeight: 'normal',
          padding: 0,
          margin: 0,
        }}
        onClick={handleDownloadLink}
        type="link"
        disabled={isDisabledLink}
      >
        <span style={{ textDecoration: 'underline' }}>{t('downloadResponsesText', { count: totalResponses })}</span>
        {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 14, marginLeft: 6 }} />} />}
      </Button>
      <CSVLink
        target="_self"
        ref={exportToCsvLink}
        asyncOnClick
        data={csvData || []}
        filename={filename}
        key="csv-link"
      />
    </>
  );
};

export default DownloadFormResponsesLink;
