import React from 'react';
import { withTranslation } from 'react-i18next';
// import { PlusOutlined } from '@ant-design/icons';
// import { Row, Button } from 'antd';
import { withRouter } from 'react-router-dom';

import EmptyCard from '../../../shared/components/EmptyCard';
// import routes from '../../../routes';
import PostAJobLogo from '../../../assets/images/post-a-job.svg';
import { clientTypes } from '../../../constants';

// TODO uncomment this comp and remove dupe after SG office is closed
// const EmptyStaffRequestList = ({ history, t, clientType, onPostJobClick, isOnWebView }) => {
//   const isSelfServe = clientType === clientTypes.SELF_SERVE;
//   return (
//     <>
//       <EmptyCard
//         imgSrc={PostAJobLogo}
//         title={isSelfServe || isOnWebView ? t('postAGig') : t('postANewJob')}
//         description={
//           <p style={{ margin: 'auto', maxWidth: '50%' }}>{isSelfServe ? t('postAGigDesc') : t('postANewJobDesc')}</p>
//         }
//       />
//       {/* TODO remove this line after SG office is closed */}
//       <Row type="flex" justify="center" style={{ marginTop: '24px' }}>
//         {isSelfServe || isOnWebView ? (
//           <Button icon={<PlusOutlined />} type="v2-primary" onClick={() => history.push(routes.createGig)}>
//             {t('postAGig')}
//           </Button>
//         ) : (
//           <Button icon={<PlusOutlined />} type="v2-primary" onClick={onPostJobClick}>
//             {t('Request workers')}
//           </Button>
//         )}
//       </Row>
//     </>
//   );
// };

const EmptyStaffRequestList = ({ t, clientType, isOnWebView }) => {
  const isSelfServe = clientType === clientTypes.SELF_SERVE;
  return (
    <EmptyCard
      imgSrc={PostAJobLogo}
      title={isSelfServe || isOnWebView ? t('postAGig') : t('postANewJob')}
      description={
        <p style={{ margin: 'auto', maxWidth: '50%' }}>{isSelfServe ? t('postAGigDesc') : t('postANewJobDesc')}</p>
      }
    />
  );
};

export default withRouter(withTranslation()(EmptyStaffRequestList));
