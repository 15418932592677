import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Typography, Col, Card } from 'antd';
import ToolTipWithInfo from '../../../shared/components/ToolTipWithInfo';
import CubeJsQueryRenderer from '../../../shared/components/CubeJsQueryRenderer';
import PercentageDiffIcon from '../../../shared/components/PercentageDiffIcon';
import { colors } from '../../../styles/colors';
import { calculateComparisonTime } from '../../../utilities/analyticsUtils';

const { Text } = Typography;

// TODO update ComparisonCard to accomodate for recurring workers
class RecurringWorkerCard extends React.Component {
  formatQueryData = (timePeriod, clientId, positionIds, locationIds) => {
    const query = {
      filters: [
        {
          member: 'ClientsClient.id',
          operator: 'equals',
          values: [clientId.toString()],
        },
      ],
      timeDimensions: [
        {
          dimension: 'RecruitmentEmployment.startDate',
          compareDateRange: [timePeriod, calculateComparisonTime(timePeriod)],
        },
      ],
    };
    if (positionIds && positionIds.length > 0) {
      query.filters.push({
        member: 'PartnersPosition.id',
        operator: 'equals',
        values: positionIds,
      });
    }
    if (locationIds && locationIds.length > 0) {
      query.filters.push({
        member: 'ClientsLocation.id',
        operator: 'equals',
        values: locationIds,
      });
    }
    return query;
  };

  numberRender = ({ resultSet }) => {
    const { t } = this.props;

    let percentageDiff;
    let recurringPercentage;
    const newSelectedTimePeriod =
      resultSet.loadResponses[0].data.length > 0 && Object.values(resultSet.loadResponses[0].data[0])[1];
    const recurringSelectedTimePeriod =
      resultSet.loadResponses[0].data.length > 1 && Object.values(resultSet.loadResponses[0].data[1])[1];
    const recurringComparedTimePeriod =
      resultSet.loadResponses[1].data.length > 1 && Object.values(resultSet.loadResponses[1].data[1])[1];

    if (recurringComparedTimePeriod > 0) {
      percentageDiff = Math.round(
        ((recurringSelectedTimePeriod - recurringComparedTimePeriod) / recurringComparedTimePeriod) * 100,
      );
    } else if (
      (recurringComparedTimePeriod === 0 || !recurringComparedTimePeriod) &&
      (recurringSelectedTimePeriod === 0 || !recurringSelectedTimePeriod)
    ) {
      percentageDiff = 0;
    } else {
      percentageDiff = 100;
    }

    if (recurringSelectedTimePeriod > 0) {
      recurringPercentage = Math.round(
        (parseInt(recurringSelectedTimePeriod, 10) /
          (parseInt(recurringSelectedTimePeriod, 10) + parseInt(newSelectedTimePeriod, 10))) *
          100,
      );
    } else if (recurringSelectedTimePeriod === 0 || !recurringSelectedTimePeriod) {
      recurringPercentage = 0;
    } else {
      recurringPercentage = 100;
    }

    const alternateMessage = `${recurringSelectedTimePeriod || 0} ${t('recurringWorkers')}`;

    return (
      <Card style={{ width: '100%' }} bodyStyle={{ margin: '24px 16px', padding: 0 }}>
        <Row justify="center">
          <Col>
            <Text strong style={{ fontSize: '38px' }}>
              {`${recurringPercentage}%`}
            </Text>
          </Col>
        </Row>
        <Row style={{ marginBottom: '8px', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
          <Text strong type="secondary" style={{ fontSize: '12px', paddingRight: '8px' }}>
            {t('recurringWorkers').toUpperCase()}
          </Text>
          <ToolTipWithInfo infoText={t('recurringWorkersTooltip')} color={colors.blue} />
        </Row>
        <Row justify="center">
          <Col>
            <PercentageDiffIcon percentageDiff={percentageDiff} alternateMessage={alternateMessage} />
          </Col>
        </Row>
      </Card>
    );
  };

  render() {
    const { timePeriod, clientId, positionIds, locationIds } = this.props;
    const formattedQuery = this.formatQueryData(timePeriod, clientId, positionIds, locationIds);
    return (
      <CubeJsQueryRenderer
        measures={['PartnersPartner.count']}
        timeDimensions={formattedQuery.timeDimensions}
        filters={formattedQuery.filters}
        dimensions={['PartnersPartner.newOrRecurring']}
        chartFunction={this.numberRender}
      />
    );
  }
}

export default withTranslation()(RecurringWorkerCard);
