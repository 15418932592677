import * as Sentry from '@sentry/browser';
import { API_ROOT } from '../config';
import base from './base';

const logout = async () => {
  const response = await base.api.post(`${API_ROOT}/auth/logout/`);
  return response;
};

const fetchUser = async () => {
  const data = await base.api.get(`${API_ROOT}/auth/user/`);
  return data;
};

const login = async (username, password) => {
  const response = await fetch(`${API_ROOT}/auth/login/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  });
  const responseJson = await response.json();

  if (response.status >= 400) {
    throw new Error(responseJson.code);
  }

  const { key } = responseJson;
  localStorage.setItem('token', key);

  // Fetch user info after getting token
  const user = await fetchUser();
  const { id, email } = user;
  const uuid = user.uuid.toString();

  // Analytics
  Sentry.configureScope(scope => {
    scope.setUser({
      id: uuid,
      username,
      email,
    });
  });

  // google tag manager user-defined variables
  window.dataLayer.push({
    event: 'login',
    userId: id,
    username,
    locale: localStorage.getItem('i18nextLng') || 'en',
  });

  return { id, token: key };
};

const forgotPassword = async email => {
  const data = await base.api.post(`${API_ROOT}/auth/password/reset/`, {
    email,
  });
  return data;
};

const resetPassword = async (id, token, password, confirmPassword) => {
  const data = await base.api.post(`${API_ROOT}/auth/password/reset/confirm/`, {
    token,
    uid: id,
    new_password1: password,
    new_password2: confirmPassword,
  });
  return data;
};

const fetchCubeJsToken = async () => {
  const data = await base.api.get(`${API_ROOT}/auth/cubejs-token-generation/`);
  return data;
};

export default {
  login,
  logout,
  forgotPassword,
  resetPassword,
  fetchUser,
  fetchCubeJsToken,
};
