import { Col, Divider, Row, Typography } from 'antd';
import React from 'react';
import moment from 'moment';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { attendanceStatuses, shiftStatuses, staffRequestTabs, TIME_FORMAT_24_HOUR } from '../../../../constants';
import { LONG_DATE } from '../../../../styles/dates';
import { colors } from '../../../../styles/colors';
import routes from '../../../../routes';
import StatusTag from '../../../../shared/components/StatusTag';
import VipWorkersOnlyTooltip from '../../components/VipWorkersOnlyTooltip';

const GigItem = ({ shift, hasDivider }) => {
  const assignedWorkers = shift.attendances.filter(
    attendance => attendance.status.toLowerCase() === attendanceStatuses.ASSIGNED,
  );

  const date = `${moment(shift.startTime).format(LONG_DATE)}`;
  const time = `${moment(shift.startTime).format(TIME_FORMAT_24_HOUR)} - ${moment(shift.endTime).format(
    TIME_FORMAT_24_HOUR,
  )}`;
  const dateWithTime = `${date} · ${time}`;

  const isFilled = assignedWorkers.length === shift.staffRequired;

  const gigLink = routes.gigDetail.replace(':id', shift.id).replace(':tab', staffRequestTabs.ACCEPTED);

  return (
    <>
      <Link to={gigLink}>
        <Row style={{ padding: '16px' }}>
          <Col xs={20}>
            <Typography.Paragraph style={{ fontWeight: 'bold', marginBottom: 4 }}>
              {shift?.staffRequest?.vipWorkersOnly && (
                <VipWorkersOnlyTooltip iconContainerStyle={{ marginRight: 6 }} staffRequest={shift?.staffRequest} />
              )}
              {shift.staffRequest.title}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ color: colors.secondaryText, marginBottom: 4 }}>
              {shift.location.name}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ color: colors.secondaryText, marginBottom: 0 }}>
              {dateWithTime}
            </Typography.Paragraph>
          </Col>
          <Col xs={4}>
            <Row style={{ flexDirection: 'column', alignItems: 'flex-end', height: '100%' }}>
              <Col style={{ flex: 1 }}>
                <Typography.Paragraph
                  style={{
                    color: isFilled ? colors.primaryText : colors.functionalLink,
                    fontWeight: '600',
                    fontSize: 20,
                    marginBottom: shift.waitlistedCount > 0 ? 4 : 0,
                  }}
                >
                  {`${assignedWorkers.length}/${shift.staffRequired}`}
                </Typography.Paragraph>
                {shift.waitlistedCount > 0 && (
                  <Row style={{ alignItems: 'center' }}>
                    <ClockCircleOutlined style={{ color: colors.secondaryText, marginRight: 6, fontSize: 13 }} />
                    <Typography.Paragraph style={{ color: colors.secondaryText, marginBottom: 0, fontSize: 16 }}>
                      {shift.waitlistedCount}
                    </Typography.Paragraph>
                  </Row>
                )}
              </Col>
              <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {[shiftStatuses.CANCELLED, shiftStatuses.ENDED].includes(shift?.status?.toLowerCase()) && (
                  <StatusTag status={shift.status.toLowerCase()} style={{ marginRight: 0 }} />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Link>
      {hasDivider && <Divider style={{ margin: 0 }} />}
    </>
  );
};

export default GigItem;
