import { v4 as uuidv4 } from 'uuid';
import { message } from 'antd';
import i18next from 'i18next';
import * as Sentry from '@sentry/browser';
import questionsApi from '../services/questionsApi';
import formUtils from '../utilities/formUtils';

// Action Types
const ADD_SELECTED_QUESTION = 'ADD_SELECTED_QUESTION';
const RESET_SELECTED_QUESTIONS = 'RESET_SELECTED_QUESTIONS';
const TOGGLE_KNOCK_OUT = 'TOGGLE_KNOCK_OUT';
const DELETE_SELECTED_QUESTION = 'DELETE_SELECTED_QUESTION';
const IMPORT_SELECTED_QUESTIONS = 'IMPORT_SELECTED_QUESTIONS';
const REORDER_SELECTED_QUESTIONS = 'REORDER_SELECTED_QUESTIONS';
const SELECT_FORM = 'SELECT_FORM';
const CLEAR_SELECTED_FORM = 'CLEAR_FORM';
const FETCH_QUESTIONS_START = 'FETCH_QUESTIONS_START';
const FETCH_QUESTIONS_SUCCESS = 'FETCH_QUESTIONS_SUCCESS';
const FETCH_QUESTIONS_FAILED = 'FETCH_QUESTIONS_FAILED';
const RESET_QUESTION_LIBRARY = 'RESET_QUESTION_LIBRARY';

// Initial State
const initialState = {
  selectedQuestions: [],
  selectedForm: null,
  inactiveQuestions: [],
  questionLibrary: {
    questions: [],
    loading: true,
    search: '',
  },
};

// Reducer
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_SELECTED_QUESTION:
      return {
        ...state,
        selectedQuestions: [...state.selectedQuestions, { ...payload, key: uuidv4() }],
      };
    case TOGGLE_KNOCK_OUT: {
      const selectedQuestions = state.selectedQuestions.map(question => {
        if (question.id === payload.questionId) {
          const choices = formUtils.updateChoicesKnockout({
            choices: question.choices,
            choiceId: payload.choiceId,
            knockout: payload.knockout,
          });
          return {
            ...question,
            choices,
          };
        }
        return question;
      });
      return {
        ...state,
        selectedQuestions,
      };
    }
    case DELETE_SELECTED_QUESTION: {
      const selectedQuestions = state.selectedQuestions.filter(
        (question, index) => !(payload.id === question.id && index === payload.index),
      );
      return {
        ...state,
        selectedQuestions,
      };
    }
    case IMPORT_SELECTED_QUESTIONS: {
      const { activeQuestions, inactiveQuestions } = payload;
      return {
        ...state,
        selectedQuestions: [...state.selectedQuestions, ...activeQuestions],
        inactiveQuestions,
      };
    }
    case RESET_SELECTED_QUESTIONS:
      return {
        ...state,
        selectedQuestions: [],
      };
    case REORDER_SELECTED_QUESTIONS: {
      const orderedQuestions = formUtils.reOrderedQuestions(
        state.selectedQuestions,
        payload.activeIndex,
        payload.overIndex,
      );
      return {
        ...state,
        selectedQuestions: orderedQuestions,
      };
    }
    case SELECT_FORM: {
      return {
        ...state,
        selectedForm: action.payload,
      };
    }
    case CLEAR_SELECTED_FORM: {
      return {
        ...state,
        selectedForm: null,
      };
    }
    case FETCH_QUESTIONS_START: {
      return {
        ...state,
        questionLibrary: {
          ...state.questionLibrary,
          loading: true,
        },
      };
    }
    case FETCH_QUESTIONS_SUCCESS: {
      return {
        ...state,
        questionLibrary: {
          questions: payload.questions,
          search: payload.search,
          loading: false,
        },
      };
    }
    case FETCH_QUESTIONS_FAILED: {
      return {
        ...state,
        questionLibrary: {
          ...state.questionLibrary,
          loading: false,
        },
      };
    }
    case RESET_QUESTION_LIBRARY: {
      return {
        ...state,
        questionLibrary: initialState.questionLibrary,
      };
    }
    default:
      return state;
  }
};

// Actions
export const addSelectedQuestion = question => {
  return dispatch => {
    dispatch({ type: ADD_SELECTED_QUESTION, payload: question });
  };
};

export const importSelectedQuestions = questions => {
  return dispatch => {
    dispatch({ type: IMPORT_SELECTED_QUESTIONS, payload: questions });
  };
};

export const toggleKnockOut = ({ questionId, choiceId, knockout }) => {
  return dispatch => {
    dispatch({ type: TOGGLE_KNOCK_OUT, payload: { questionId, choiceId, knockout } });
  };
};

export const resetSelectedQuestions = () => {
  return dispatch => {
    dispatch({ type: RESET_SELECTED_QUESTIONS });
  };
};

export const deleteSelectedQuestion = ({ id, index }) => {
  return dispatch => {
    dispatch({ type: DELETE_SELECTED_QUESTION, payload: { id, index } });
  };
};

export const reorderSelectedQuestions = ({ activeIndex, overIndex }) => {
  return dispatch => {
    dispatch({ type: REORDER_SELECTED_QUESTIONS, payload: { activeIndex, overIndex } });
  };
};

export const selectForm = form => {
  return dispatch => {
    dispatch({ type: SELECT_FORM, payload: form });
  };
};

export const clearSelectedForm = () => {
  return dispatch => {
    dispatch({ type: CLEAR_SELECTED_FORM });
  };
};

export const resetQuestionLibrary = () => {
  return dispatch => {
    dispatch({ type: RESET_QUESTION_LIBRARY });
  };
};

export const getQuestions = search => async (dispatch, getState) => {
  try {
    const { applicationForm, user } = getState();
    const { questionLibrary } = applicationForm;
    const searchText = search === undefined ? questionLibrary.search : search;
    dispatch({ type: FETCH_QUESTIONS_START });
    const questions = await questionsApi.searchQuestions({ search: searchText, client_id: user?.clientId });
    dispatch({ type: FETCH_QUESTIONS_SUCCESS, payload: { questions, search: searchText } });
  } catch (error) {
    dispatch({ type: FETCH_QUESTIONS_FAILED });
    message.error(i18next.t('commonErrorMessage'));
    Sentry.captureException(error);
  }
};
