import React from 'react';
import { Tag, Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import { getColorsForLeaveEntryStatusTag } from '../utils/color';

export default withTranslation()(({ t, status }) => {
  const color = getColorsForLeaveEntryStatusTag(status);

  return (
    <Tag color={color.secondary} style={{ borderRadius: 16, borderColor: color.primary }}>
      <Typography.Text style={{ color: color.primary }}>{t(status)}</Typography.Text>
    </Tag>
  );
});
