import React from 'react';
import { withTranslation } from 'react-i18next';
import TreeCheckboxFilter from '../../../shared/components/TreeCheckboxFilter';
import { moreFilterOptions } from '../../../constants';

const MoreFilters = ({ selectedWorker, searchParams, onApplyMoreFilters, t }) => {
  const resetParams = nextSearchParams => {
    moreFilterOptions.forEach(({ key }) => {
      // eslint-disable-next-line no-param-reassign
      delete nextSearchParams[key];
    });
    return nextSearchParams;
  };

  const getNextSearchParams = checkedKeys => {
    const nextSearchParams = { ...searchParams };
    if (!checkedKeys?.length) {
      return resetParams(nextSearchParams);
    }
    moreFilterOptions.forEach(({ key }) => {
      if (checkedKeys.includes(key)) {
        nextSearchParams[key] = true;
      } else {
        delete nextSearchParams[key];
      }
    });
    return nextSearchParams;
  };

  const handleApplyMoreFilters = checkedKeys => {
    const nextSearchParams = getNextSearchParams(checkedKeys);
    nextSearchParams.page = 1;
    onApplyMoreFilters({ searchParams: nextSearchParams, searchApplied: checkedKeys?.length > 0 });
  };

  const treeData = moreFilterOptions.map(option => ({ ...option, title: t(option.localeKey) }));

  return (
    <TreeCheckboxFilter
      treeData={treeData}
      label={t('moreFilters')}
      showActionButtons
      showSearch={false}
      onApply={handleApplyMoreFilters}
      expandable={false}
      isDisabled={selectedWorker && selectedWorker.length > 0}
    />
  );
};

export default withTranslation()(MoreFilters);
