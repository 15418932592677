import React from 'react';
import { Tag, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';

import {
  userStatuses,
  staffRequestStatuses,
  timesheetEntryStatuses,
  billingReportStatuses,
  employmentStatuses,
  applicationStatuses,
  channelStatuses,
  staffRequestTemplateStatuses,
} from '../../constants';
import textUtils from '../../utilities/textUtils';

const StatusTag = ({ status, style, t, interview = false, shortlisted = false, paused = false }) => {
  const greenTags = [
    userStatuses.ACTIVE,
    staffRequestStatuses.IN_PROGRESS,
    timesheetEntryStatuses.APPROVED,
    billingReportStatuses.BILLED,
    employmentStatuses.ACTIVE,
  ];
  const blueTags = [
    staffRequestStatuses.POSTED,
    staffRequestTemplateStatuses.POSTED,
    timesheetEntryStatuses.CLOCKED_IN,
    applicationStatuses.PENDING_CONTRACT,
  ];
  const redTags = [
    userStatuses.DISABLED,
    userStatuses.REJECTED,
    staffRequestStatuses.CANCELLED,
    employmentStatuses.CANCELLED,
    timesheetEntryStatuses.DISPUTED,
    billingReportStatuses.HOLD,
  ];
  const goldTags = [
    userStatuses.LEAD,
    staffRequestStatuses.PENDING_REVIEW,
    applicationStatuses.APPLIED,
    timesheetEntryStatuses.CLOCKED_OUT,
    timesheetEntryStatuses.RESOLVED,
    billingReportStatuses.UNBILLED,
  ];
  const purpleTags = [
    applicationStatuses.PENDING_ONBOARDING,
    staffRequestStatuses.POSTED,
    staffRequestStatuses.IN_PROGRESS,
  ];
  const greyTags = [channelStatuses.ARCHIVED, staffRequestTemplateStatuses.DRAFT];

  if (purpleTags.includes(status) || (purpleTags.includes(status) && paused === true)) {
    if (status === applicationStatuses.PENDING_ONBOARDING) {
      return (
        <Tag color="purple" style={{ ...style }}>
          {t(textUtils.makeFriendly(status))}
        </Tag>
      );
    }

    /** Statuses for paused jobs */
    if (paused === true) {
      return (
        <Tooltip title={t('pausedTooltip')}>
          <Tag color="purple" style={{ ...style, cursor: 'pointer' }}>
            {`${t('paused')}`}
          </Tag>
        </Tooltip>
      );
    }
  }
  if (greenTags.includes(status)) {
    return (
      <Tag color="green" style={{ ...style }}>
        {status === employmentStatuses.ACTIVE ? t('Hired') : t(textUtils.makeFriendly(status))}
      </Tag>
    );
  }
  if (blueTags.includes(status)) {
    return (
      <Tag color="blue" style={{ ...style }}>
        {status === applicationStatuses.PENDING_CONTRACT ? t('Contract sent') : t(textUtils.makeFriendly(status))}
      </Tag>
    );
  }
  if (redTags.includes(status)) {
    return (
      <Tag color="red" style={{ ...style }}>
        {t(textUtils.makeFriendly(status))}
      </Tag>
    );
  }
  if (goldTags.includes(status)) {
    if (shortlisted) {
      return (
        <Tag color="gold" style={{ ...style }}>
          {t('shortlisted')}
        </Tag>
      );
    }
    if (interview) {
      return (
        <Tag color="gold" style={{ ...style }}>
          {t('interview')}
        </Tag>
      );
    }
    return (
      <Tag color="gold" style={{ ...style }}>
        {status === staffRequestStatuses.PENDING_REVIEW ? t('reviewing') : t(textUtils.makeFriendly(status))}
      </Tag>
    );
  }
  if (greyTags.includes(status)) {
    return <Tag style={{ ...style }}>{t(textUtils.makeFriendly(status))}</Tag>;
  }
  // Default
  return <Tag style={{ backgroundColor: 'white', ...style }}>{t(textUtils.makeFriendly(status))}</Tag>;
};

export default withTranslation()(StatusTag);
