import React from 'react';
import { Row, Col, Typography, Tabs } from 'antd';
import moment from 'moment-timezone';

import { roles, DEFAULT_DATE_FORMAT, settingsTabs } from '../../constants';

import routes from '../../routes';
import CompanySection from './CompanySection';
import BillingSection from './BillingSection';
import TeamSection from './TeamSection';
import LocationsSection from './LocationsSection';
import LegalSection from './LegalSection';
import LanguageSection from './LanguageSection';
import RolesSection from './RolesSection';
// Should be in HC library
import DetailCard from '../../shared/components/DetailCard';
import TimesheetTagsSection from './TimesheetTagsSection';

const { TabPane } = Tabs;
const { Title } = Typography;

class SettingsView extends React.Component {
  state = {
    isLoading: false,
    activeTab: settingsTabs.COMPANY,
  };

  componentDidMount() {
    const { match } = this.props;
    const { activeTab } = this.state;
    if (!match.params.tab) {
      this.props.history.push(routes.settings.replace(':tab', activeTab));
    }
    if (match.params.tab && match.params.tab !== ':tab') {
      this.setState({ activeTab: match.params.tab.toLowerCase() });
    }
  }

  // Listen to Url changes in history & update active tab
  componentDidUpdate(prevProps) {
    if (this.props.match.params.tab !== prevProps.match.params.tab) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ activeTab: this.props.match.params.tab });
    }
  }

  getCompanyAccountDetails = () => {
    const { clientId, clientCreatedDate } = this.props.user;
    return {
      title: 'Company account details',
      contentList: [
        { title: 'Account ID', value: clientId },
        { title: 'Created on', value: `${moment(clientCreatedDate).format(DEFAULT_DATE_FORMAT)}` },
      ],
    };
  };

  // When user clicks on another tab, we want to update the URl to reflect the new active tab.
  handleTabChange = nextActiveTab => {
    if (this.state.activeTab === nextActiveTab) {
      return;
    }
    this.updateUrl(nextActiveTab);
    this.updateAnalytics(nextActiveTab);
  };

  updateUrl = nextActiveTab => {
    this.props.history.push(routes.settings.replace(':tab', nextActiveTab));
    this.setState({ activeTab: nextActiveTab });
  };

  updateAnalytics = nextActiveTab => {
    switch (nextActiveTab) {
      case settingsTabs.COMPANY:
        break;
      case settingsTabs.BILLING:
        break;
      case settingsTabs.LEGAL:
        break;
      case settingsTabs.TEAM:
        break;
      case settingsTabs.LOCATIONS:
        break;
      case settingsTabs.TIMESHEET_TAGS:
        break;
      case settingsTabs.LANGUAGE:
        break;
      default:
        break;
    }
  };

  render() {
    const { isLoading, activeTab } = this.state;
    const { user, fetchClientAndManager, fetchPositions, t } = this.props;
    const isIndonesian = user.country.id === 1;
    const isAdmin = user.role === roles.ADMIN;
    const companyAccountDetails = this.getCompanyAccountDetails();
    return (
      <Row type="flex" gutter={50}>
        <Col xs={24} md={16}>
          {/* Header */}
          <Row style={{ marginBottom: '48px' }}>
            <Title level={2}>{t('Settings')}</Title>
          </Row>
          <Tabs className="v2-tab-wrapper" activeKey={activeTab} onChange={this.updateUrl} destroyInactiveTabPane>
            <TabPane tab={t('Company')} key={settingsTabs.COMPANY}>
              <CompanySection client={user} isAdmin={isAdmin} fetchClientAndManager={fetchClientAndManager} t={t} />
            </TabPane>
            <TabPane tab={t('Billing')} key={settingsTabs.BILLING}>
              <BillingSection client={user} isAdmin={isAdmin} fetchClientAndManager={fetchClientAndManager} t={t} />
            </TabPane>
            {isIndonesian && (
              <TabPane tab={t('Legal')} key={settingsTabs.LEGAL}>
                <LegalSection client={user} isAdmin={isAdmin} fetchClientAndManager={fetchClientAndManager} t={t} />
              </TabPane>
            )}
            <TabPane tab={t('Team')} key={settingsTabs.TEAM}>
              <TeamSection client={user} isAdmin={isAdmin} fetchClientAndManager={fetchClientAndManager} t={t} />
            </TabPane>
            <TabPane tab={t('Locations')} key={settingsTabs.LOCATIONS}>
              <LocationsSection client={user} isAdmin={isAdmin} fetchClientAndManager={fetchClientAndManager} t={t} />
            </TabPane>
            <TabPane tab={t('roles')} key={settingsTabs.ROLES}>
              <RolesSection client={user} isAdmin={isAdmin} fetchClientAndManager={fetchClientAndManager} t={t} />
            </TabPane>
            <TabPane tab={t('Timesheet')} key={settingsTabs.TIMESHEET_TAGS}>
              <TimesheetTagsSection
                client={user}
                isAdmin={isAdmin}
                fetchClientAndManager={fetchClientAndManager}
                t={t}
              />
            </TabPane>
            <TabPane tab={t('Language')} key={settingsTabs.LANGUAGE}>
              <LanguageSection t={t} fetchPositions={fetchPositions} />
            </TabPane>
          </Tabs>
        </Col>
        <Col xs={24} md={8}>
          <Row style={{ borderTop: '1px solid #00000017' }}>
            <DetailCard
              headerTitle={companyAccountDetails.title}
              contentList={companyAccountDetails.contentList}
              loading={isLoading}
            />
          </Row>
        </Col>
      </Row>
    );
  }
}

export default SettingsView;
