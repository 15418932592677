import numeral from 'numeral';
import moment from 'moment';
import { ANALYTICS_DATE_FORMAT } from '../constants';

export const COLORS_SERIES = [
  '#6665DD',
  '#34D1BF',
  '#1AA6B7',
  '#3B507D',
  '#5D54A4',
  '#9D65C9',
  '#D789D7',
  '#3592AF',
  '#206A5D',
  '#81B214',
  '#BFDCAE',
  '#89C9B8',
  '#1890FF',
  '#1890FF',
  '#80C2FF',
  '#F57B51',
  '#D63447',
];

export const GENDER_LABELS = ['male', 'female', null];

export const getNumberAbbreviation = count => {
  if (!count || count === 0) {
    return 0;
  }
  if (count.toString().length > 0) {
    return numeral(count).format('0.00a');
  }
};

export const getLabelColors = arrayLength => {
  const labelSeries = [];
  const test = Math.ceil(arrayLength / COLORS_SERIES.length);
  for (let i = 0; i < test; i += 1) {
    labelSeries.push(COLORS_SERIES);
  }
  return labelSeries.flat();
};

export const calculateComparisonTime = timePeriod => {
  return [
    moment(timePeriod[0], ANALYTICS_DATE_FORMAT)
      .subtract(1, 'month')
      .format(ANALYTICS_DATE_FORMAT),
    moment(timePeriod[1], ANALYTICS_DATE_FORMAT)
      .subtract(1, 'month')
      .format(ANALYTICS_DATE_FORMAT),
  ];
};

export const formatQueryData = (
  timePeriod,
  timeDimensionField,
  clientId,
  positionIds,
  locationIds,
  positionFilterFields,
  locationFilterFields,
  granularity,
  alteredProps,
) => {
  const query = {
    filters: [
      {
        member: 'ClientsClient.id',
        operator: 'equals',
        values: [clientId.toString()],
      },
    ],
    timeDimensions: [
      {
        dimension: timeDimensionField,
        dateRange: timePeriod,
      },
    ],
  };
  if (granularity) {
    query.timeDimensions[0].granularity = granularity;
  }
  if (positionIds && positionIds.length > 0) {
    query.filters.push({
      member: positionFilterFields,
      operator: 'equals',
      values: positionIds,
    });
  }
  if (locationIds && locationIds.length > 0) {
    query.filters.push({
      member: locationFilterFields,
      operator: 'equals',
      values: locationIds,
    });
  }
  if (alteredProps) {
    alteredProps(query);
  }
  return query;
};
