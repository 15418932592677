import { Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { questionStatuses } from '../../../constants';
import { colors } from '../../../styles/colors';

const StatusTag = ({ status }) => {
  const { t } = useTranslation();
  return (
    <Row style={{ alignItems: 'center' }}>
      <div
        style={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: status === questionStatuses.ACTIVE ? colors.functionalSuccess : colors.grey,
          marginRight: 8,
        }}
      />
      <Typography.Text>{status === questionStatuses.ACTIVE ? t('active') : t('deactivated')}</Typography.Text>
    </Row>
  );
};

export default StatusTag;
