import React from 'react';
import { Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import ApplicationForms from '../../../containers/ApplicationForms';

const StaffRequestFormsListView = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row align="center" style={{ justifyContent: 'space-between', marginBottom: 32 }}>
        <Typography.Title level={4}>{t('Application Forms')}</Typography.Title>
      </Row>
      <ApplicationForms createFormBtnType="primary" createFormOpensNewTab={false} showStatus />
    </>
  );
};

export default StaffRequestFormsListView;
