import moment from 'moment';
import RRule, { rrulestr } from 'rrule';
import { MINIMAL_TIME_FORMAT, DATE_FORMAT } from '../constants';

const formatTimeString = time => {
  // Parse time from 09:00:00 to 09:00 AM
  const [hourStr, minuteStr] = time.split(':');
  const hourInt = parseInt(hourStr, 10);
  const minuteInt = parseInt(minuteStr, 10);
  return moment({ hour: hourInt, minute: minuteInt }).format(MINIMAL_TIME_FORMAT);
};

const getEndDateFromRecurrences = recurrences => {
  const rrule = rrulestr(recurrences);
  // recurrence is until the end of the day
  // e.g. if end date is 2020-09-30, it is recorded as 2020-09-31 00:00 on backend
  const endDate = rrule.options.until && moment(rrule.options.until).subtract(1, 'd');
  return endDate?.format(DATE_FORMAT);
};

const formDataToEditPayload = (schedule, values, timezone) => {
  const { name } = values;
  const { id } = schedule;
  const {
    staff_required,
    staff_request,
    start_date: startDate,
    start_time: startTime,
    end_time: endTime,
    recurrences,
  } = schedule;
  return {
    id,
    name,
    staff_request,
    staff_required,
    recurrences,
    start_date:
      startDate &&
      moment(startDate)
        .tz(timezone)
        .startOf('d')
        .toISOString(),
    start_time: startTime,
    end_time: endTime,
  };
};

const formDataToCreatePayload = (values, timezone) => {
  const {
    name,
    staff_required,
    start_date: startDate,
    end_date: endDate,
    start_time: startTime,
    end_time: endTime,
  } = values;
  // Schedules & recurrences have to be converted to RRule
  const recurrences = new RRule({
    freq: RRule.WEEKLY,
    byweekday: values.recurrence,
    until:
      endDate &&
      endDate
        .tz(timezone)
        .add(1, 'd')
        .startOf('d')
        .toISOString(),
  }).toString();
  return {
    name,
    staff_required,
    recurrences,
    start_date:
      startDate &&
      startDate
        .tz(timezone)
        .startOf('d')
        .toISOString(),
    end_date:
      endDate &&
      endDate
        .tz(timezone)
        .startOf('d')
        .toISOString(),
    start_time: startTime.format('HH:mm:00'),
    end_time: endTime.format('HH:mm:00'),
  };
};

export default {
  formatTimeString,
  getEndDateFromRecurrences,
  formDataToCreatePayload,
  formDataToEditPayload,
};
