import React from 'react';
import { Route } from 'react-router-dom';

const RouteWithProps = ({ exact, path, extraProps, component: Component }) => {
  // TODO: Bug where every refresh routes user to /signup. This is because clientId has to be re-fethced and updates too slowly.
  // const storeState = store.getState();
  // if (token && !storeState.user.clientId && !storeState.user.isLoading) {
  //   return <Redirect to={routes.signUp} />;
  // }
  return (
    <Route
      exact={exact}
      path={path}
      render={props => {
        const allProps = { ...props, ...extraProps };
        return <Component {...allProps} />;
      }}
    />
  );
};

export default RouteWithProps;
