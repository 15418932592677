import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Row, Col, Avatar, Typography, Button } from 'antd';
import AttendanceDetailBanner from '../AttendanceDetailBanner';
import { employmentTypes, leaveEntryStatuses, timesheetEntryStatuses } from '../../../../constants';
import TimesheetItem from './TimesheetItem';
import LeaveItem from './LeaveItem';
import { colors } from '../../../../styles/colors';

const PartnerDetails = ({ employment, onClickAddEntry }) => {
  const { t } = useTranslation();
  const { id: partnerId, firstName, lastName, image } = employment.partner;
  const positionName = employment.position?.name;
  const detailStyle = { fontSize: 14, color: colors.primaryText, lineHeight: '22px', top: 4 };
  return (
    <Row style={{ display: 'flex' }}>
      <Col flex="72px">
        <Avatar shape="square" src={image} size={72} icon={<UserOutlined />} alt="partner logo" />
      </Col>
      <Col flex="auto" style={{ marginLeft: 24 }}>
        <Row>
          <Typography.Text style={{ fontWeight: 600, fontSize: 20, color: colors.textTitle, lineHeight: '28px' }}>
            {firstName} {lastName}
          </Typography.Text>
        </Row>
        <Row>
          <Typography.Text style={detailStyle}>{positionName}</Typography.Text>
        </Row>
        <Row>
          <Typography.Text style={detailStyle}>#{partnerId}</Typography.Text>
        </Row>
      </Col>
      <Col>
        <Button onClick={onClickAddEntry} icon={<PlusOutlined />}>
          {t('addEntry')}
        </Button>
      </Col>
    </Row>
  );
};

export default withTranslation()(
  ({
    t,
    loading,
    employment,
    voidReasons,
    onClickAddEntry,
    // timesheet entry props
    timesheetEntries,
    refreshTimesheetData,
    handleApproveTimesheet,
    handleConfirmTimesheet,
    onTimesheetActionComplete,
    timeRoundingRules,
    // leave entry props
    leaveEntries,
    handleApproveLeaveEntry,
    handleResolveLeaveEntry,
    onLeaveActionComplete,
  }) => {
    const [showVoidModal, setShowVoidModal] = useState(false);
    const [selectedTimesheetEntry, setSelectedTimesheetEntry] = useState(undefined);
    const [showEditTimesheetModal, setShowEditTimesheetModal] = useState(false);
    const [visibleSelfieModal, setVisibleSelfieModal] = useState(null);
    const [showLeaveVoidModal, setShowLeaveVoidModal] = useState(false);
    const [showLeaveRejectModal, setShowRejectVoidModal] = useState(false);
    const [selectedLeaveEntry, setSelectedLeaveEntry] = useState(undefined);

    const isGig = employment?.type?.toLowerCase() === employmentTypes.GIG.value;
    const nonVoidTimesheetEntriesCount = timesheetEntries?.filter(
      timesheet => timesheet.status !== timesheetEntryStatuses.VOID,
    ).length;
    const nonVoidLeaveEntriesCount = leaveEntries?.filter(leave => leave.status !== leaveEntryStatuses.VOID).length;
    const hasMultipleEntries =
      nonVoidTimesheetEntriesCount > 1 ||
      nonVoidLeaveEntriesCount > 1 ||
      (nonVoidTimesheetEntriesCount >= 1 && nonVoidLeaveEntriesCount >= 1);

    return (
      <div>
        <PartnerDetails employment={employment} onClickAddEntry={onClickAddEntry} />
        {hasMultipleEntries && !isGig && <AttendanceDetailBanner description={t('multipleEntriesDescription')} />}
        {timesheetEntries?.length > 0 &&
          timesheetEntries.map((timesheetEntry, index) => (
            <TimesheetItem
              key={index}
              timesheetEntry={timesheetEntry}
              loading={loading}
              handleApproveTimesheet={handleApproveTimesheet}
              handleConfirmTimesheet={handleConfirmTimesheet}
              onActionComplete={onTimesheetActionComplete}
              setShowVoidModal={setShowVoidModal}
              setSelectedTimesheetEntry={setSelectedTimesheetEntry}
              setShowEditTimesheetModal={setShowEditTimesheetModal}
              setVisibleSelfieModal={setVisibleSelfieModal}
              refreshTimesheetData={refreshTimesheetData}
              showVoidModal={showVoidModal}
              selectedTimesheetEntry={selectedTimesheetEntry}
              showEditTimesheetModal={showEditTimesheetModal}
              visibleSelfieModal={visibleSelfieModal}
              voidReasons={voidReasons}
              timeRoundingRules={timeRoundingRules}
            />
          ))}
        {leaveEntries?.length > 0 &&
          leaveEntries.map((leaveEntry, index) => (
            <div key={index}>
              <LeaveItem
                key={index}
                loading={loading}
                leaveEntry={leaveEntry}
                selectedLeaveEntry={selectedLeaveEntry}
                setSelectedLeaveEntry={setSelectedLeaveEntry}
                onActionComplete={onLeaveActionComplete}
                refreshLeaveData={() => {}}
                handleApproveLeaveEntry={handleApproveLeaveEntry}
                handleResolveLeaveEntry={handleResolveLeaveEntry}
                setShowVoidModal={setShowLeaveVoidModal}
                showVoidModal={showLeaveVoidModal}
                setShowRejectModal={setShowRejectVoidModal}
                showRejectModal={showLeaveRejectModal}
              />
            </div>
          ))}
      </div>
    );
  },
);
