import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Typography, Spin, Row, Col, Tooltip, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { colors } from '../../../styles/colors';
import EndingEmploymentsLink from './EndingEmploymentsLink';

const { Text, Title } = Typography;

const WorkforceStatsCard = ({
  t,
  loading,
  employmentStats,
  onEndingEmploymentsClick,
  onResendInviteClick,
  resendInviteDisabled,
}) => {
  let activeWorkersCount;
  let invitedWorkersCount;
  let endingEmploymentsCount;
  if (employmentStats) {
    const { workforce_counts } = employmentStats;
    activeWorkersCount = workforce_counts.accepted;
    invitedWorkersCount = workforce_counts.invited;
    endingEmploymentsCount = workforce_counts.ending;
  }
  return (
    <Card
      style={{
        boxShadow: '0 2px 6px #00000026',
      }}
      bodyStyle={{ padding: '16px 24px' }}
    >
      <Row type="flex">
        <Col span={7} style={{ borderRight: `1px solid ${colors.componentBorder}` }}>
          <Row>
            <Col>
              <Text type="secondary" style={{ fontSize: 12, lineHeight: '20px', fontWeight: 600 }}>
                {t('activeWorkers').toUpperCase()}
              </Text>
              <Tooltip title={t('activeWorkersToolTip')}>
                <QuestionCircleOutlined style={{ marginLeft: '5px', color: colors.darkGrey }} />
              </Tooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <Title level={3} style={{ margin: 0 }}>
                {loading ? <Spin data-testid="stats-card-spinner" /> : activeWorkersCount}
              </Title>
            </Col>
          </Row>
        </Col>
        <Col span={8} style={{ paddingLeft: '24px', borderRight: `1px solid ${colors.componentBorder}` }}>
          <Row>
            <Col>
              <Text type="secondary" style={{ fontSize: 12, fontWeight: 600 }}>
                {t('invitedWorkers').toUpperCase()}
              </Text>
              <Tooltip title={t('invitedWorkersToolTip')}>
                <QuestionCircleOutlined style={{ marginLeft: '5px', color: colors.darkGrey }} />
              </Tooltip>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col>
              <Title level={3} style={{ marginBottom: 0 }}>
                {loading ? <Spin data-testid="stats-card-spinner" /> : invitedWorkersCount}
              </Title>
            </Col>
            <Col>
              <Button
                disabled={resendInviteDisabled}
                onClick={onResendInviteClick}
                size="small"
                style={{ marginLeft: '8px' }}
              >
                {t('resendInvite')}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={9} style={{ paddingLeft: '24px' }}>
          <Row>
            <Col>
              <Text type="secondary" style={{ fontSize: 12, fontWeight: 600 }}>
                {t('endingEmployment').toUpperCase()}
              </Text>
              <Tooltip title={t('endingEmploymentTooltip')}>
                <QuestionCircleOutlined style={{ marginLeft: '5px', color: colors.darkGrey }} />
              </Tooltip>
            </Col>
          </Row>
          <Row style={{ display: 'flex' }}>
            <Col>
              <Title level={3} style={{ margin: 0 }}>
                {loading ? (
                  <Spin data-testid="stats-card-spinner" />
                ) : (
                  <EndingEmploymentsLink
                    endingEmploymentsCount={endingEmploymentsCount}
                    onClick={onEndingEmploymentsClick}
                  />
                )}
              </Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(WorkforceStatsCard);
