import React from 'react';
import { Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FireFilled, StarFilled, CarryOutFilled } from '@ant-design/icons';
import { colors } from '../../../../styles/colors';

const StatisticsTags = ({ statistics }) => {
  const { t } = useTranslation();

  const totalRating = parseFloat(statistics?.totalRating || 0).toFixed(1);
  const tags = [];

  if (totalRating > 0) {
    tags.push({
      icon: <StarFilled style={{ color: colors.functionalSuccess }} />,
      label: totalRating,
      bgColor: colors.tagGreen,
      textColor: colors.functionalSuccess,
    });
  }

  if (statistics?.totalDaysWorked > 0) {
    tags.push({
      icon: <CarryOutFilled style={{ color: colors.functionalSuccess }} />,
      label: t('daysWorkedTag', { daysWorked: statistics?.totalDaysWorked }),
      bgColor: 'transparent',
      textColor: colors.functionalSuccess,
    });
  }

  if (!tags.length) {
    tags.push({
      icon: <FireFilled style={{ color: colors.functionalApproved }} />,
      label: t('justJoined'),
      bgColor: colors.tagPurple,
      textColor: colors.functionalApproved,
    });
  }

  return (
    <>
      {tags.map(tag => (
        <Tag key={tag.label} color={tag.bgColor} style={{ padding: '1px 8px', marginRight: 8, marginBottom: 2 }}>
          {tag.icon}
          <Typography.Text style={{ color: tag.textColor }}>{tag.label}</Typography.Text>
        </Tag>
      ))}
    </>
  );
};

export default StatisticsTags;
