import React, { useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Table, Typography } from 'antd';
import { withTranslation } from 'react-i18next';

import dateUtils from '../../../utilities/dateUtils';
import { calculateHoursFromDuration } from '../../../utilities/durationUtils';
import attendanceUtils from '../../../utilities/attendanceUtils';
import { AttendanceStatus } from '../../../constants';

const AttendanceStatusLabel = {
  [AttendanceStatus.CLOCKED_IN]: 'Clocked in',
  [AttendanceStatus.CONFIRMED]: 'Confirmed',
  [AttendanceStatus.UNCONFIRMED]: 'Unconfirmed',
  [AttendanceStatus.DISPUTED]: 'disputed',
  [AttendanceStatus.APPROVED]: 'approved',
};

const dateKey = date => date.format('ddd DD/MM');

const statusCountsRowKey = 1;
const overtimeDurationRowKey = 2;
const absenceDetailsKey = 3;

export default withTranslation()(
  ({ t, startDate, timesheetEntryStatistics, absenceDetailsStatistics, loading, style }) => {
    const [showExpandedStatusCounts, setShowExpandedStatusCounts] = useState(false);
    const expandedRowKeys = showExpandedStatusCounts ? [statusCountsRowKey] : [];

    let statusCountsRow = {
      key: statusCountsRowKey,
      rowTitle: t('Timesheet'),
      rowSubTitle: t('totalEntries'),
    };
    let columns = [
      {
        width: 100,
        dataIndex: 'rowTitle',
      },
      {
        width: 100,
        dataIndex: 'rowSubTitle',
      },
    ];

    const dates = dateUtils.getWeekDatesFromSelectedDate(startDate);
    if (timesheetEntryStatistics) {
      statusCountsRow = { ...statusCountsRow, ...timesheetEntryStatistics };
      columns = [
        ...columns,
        ...dates.map(date => {
          return {
            title: (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography.Text>{date.format('ddd')}</Typography.Text>
                <Typography.Text style={{ fontWeight: 800 }}>{date.format('DD/MM')}</Typography.Text>
              </div>
            ),
            dataIndex: dateKey(date),
            width: 100,
            render: (text, record) => {
              if (record.key === statusCountsRowKey) {
                // status counts total
                return {
                  children: <div>{text?.total}</div>,
                };
              }
              if (record.key === overtimeDurationRowKey) {
                return {
                  children: <div>{calculateHoursFromDuration(text?.total_overtime_duration)}</div>,
                };
              }
              if (record.rowKey === absenceDetailsKey) {
                return {
                  children: <div>{text || 0}</div>,
                };
              }
            },
          };
        }),
      ];
    }

    const absenceDetailStatsRow = attendanceUtils.getAbsenceDetailStatsRows({
      absenceDetailsStatistics,
      rowKey: absenceDetailsKey,
      t,
    });

    // overtime duration row
    const overtimeDurationRow = {
      key: overtimeDurationRowKey,
      rowTitle: t('Overtime'),
      rowSubTitle: t('totalHours'),
      ...timesheetEntryStatistics,
    };

    return (
      <Table
        loading={loading}
        columns={columns}
        dataSource={[statusCountsRow, ...absenceDetailStatsRow, overtimeDurationRow]}
        expandedRowKeys={expandedRowKeys}
        expandedRowRender={record =>
          record.key === statusCountsRowKey &&
          // When total row expands, each status will have its own row
          Object.keys(AttendanceStatus).map(statusKey => (
            <div key={statusKey} style={{ display: 'flex', width: '101.3%' }}>
              <div style={{ padding: 16, width: '26.6%', paddingLeft: '16%' }}>
                {t(`${AttendanceStatusLabel[AttendanceStatus[statusKey]]}`)}
              </div>
              {dates.map(date => {
                const statusCount = record[dateKey(date)] && record[dateKey(date)][AttendanceStatus[statusKey]];
                return (
                  <div key={`${dateKey(date)}-${statusKey}`} style={{ padding: 16, width: '10.7%' }}>
                    {statusCount || '-'}
                  </div>
                );
              })}
            </div>
          ))
        }
        expandIcon={({ record }) =>
          record.key === statusCountsRowKey && (
            <LegacyIcon
              type={showExpandedStatusCounts ? 'minus-square' : 'plus-square'}
              onClick={() => setShowExpandedStatusCounts(!showExpandedStatusCounts)}
            />
          )
        }
        pagination={false}
        style={style}
      />
    );
  },
);
