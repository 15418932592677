import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';

import CostTypeBreakdownChart from './components/CostTypeBreakdownChart';
import ComparisonCard from './components/ComparisonCard';
import { colors } from '../../styles/colors';

// TODO remove along with highlight items logic
// const OVERTIME_THRESHOLD = 24;
const { Text } = Typography;

const CostTab = ({ timePeriod, clientId, locationIds, positionIds, granularity }) => {
  const { t } = useTranslation();

  // TODO
  // state = {
  //   displayHighlights: false,
  //   hightlightItems: [],
  //   previousMonth: [
  //     moment()
  //       .subtract(1, 'months')
  //       .startOf('month')
  //       .format(),
  //     moment()
  //       .subtract(1, 'months')
  //       .endOf('month')
  //       .format(),
  //   ],
  // };

  // async componentDidMount() {
  //   await this.getHighlightItems();
  // }

  // async componentDidUpdate(prevProps) {
  //   if (this.props !== prevProps) {
  //     await this.getHighlightItems();
  //   }
  // }

  // getHighlightItems = async () => {
  //   const { t } = this.props;
  //   const overtimePercentage = await this.getOvertimePercentage();

  //   const hightlightsToDisplay = [];
  //   if (overtimePercentage > OVERTIME_THRESHOLD) {
  //     hightlightsToDisplay.push(t('overtimeHighlight', { number: overtimePercentage }));
  //   }
  //   return (
  //     hightlightsToDisplay.length > 0 &&
  //     this.setState({
  //       displayHighlights: true,
  //       hightlightItems: hightlightsToDisplay,
  //     })
  //   );
  // };

  // getOvertimePercentage = async () => {
  //   const { cubeJsToken, clientId } = this.props;
  //   const { previousMonth } = this.state;
  //   let result;
  //   if (cubeJsToken) {
  //     const cubejsApi = cubejs(cubeJsToken, {
  //       apiUrl: cubeJsURL,
  //     });
  //     await cubejsApi
  //       .load({
  //         measures: ['PaymentsPaymentitem.overtimePercentage'],
  //         timeDimensions: [
  //           {
  //             dimension: 'PaymentsPaymentitem.date',
  //             dateRange: previousMonth,
  //           },
  //         ],
  //         filters: [
  //           {
  //             member: 'ClientsClient.id',
  //             operator: 'equals',
  //             values: [clientId.toString()],
  //           },
  //         ],
  //       })
  //       .then(resultSet => {
  //         result = Math.round(resultSet.loadResponses[0].data[0]['PaymentsPaymentitem.overtimePercentage']) || null;
  //       });
  //   }
  //   return result;
  // };

  return (
    <Row align="top">
      <Col span={24}>
        <Row type="flex" justify="center" align="middle" style={{ height: '100%', marginBottom: '16px' }} gutter={16}>
          {/* TODO do we still want this? */}
          {/* {displayHighlights && (
              <Col xs={24} md={12}>
                <HighlightsListCard hightlightItems={hightlightItems} />
              </Col>
            )} */}
          <Col xs={24} md={8}>
            <ComparisonCard
              title={t('totalEstimatedCost')}
              tooltipText={
                <>
                  <Text style={{ color: colors.white }}>{t('totalEstimatedCostTooltip')}</Text>
                  <br />
                  <Text style={{ color: colors.white }}>{t('note')}</Text>
                  <ul style={{ paddingLeft: '16px' }}>
                    <li>{t('estimatedCostExcludedTimesheets')}</li>
                    <li>{t('monthlyWageExcludedTimesheets')}</li>
                    <li>{t('platformFeesExcluded')}</li>
                  </ul>
                </>
              }
              filterDimension="ClockingTimesheetentry.clockInTime"
              filterMeasure="ClockingTimesheetentry.estimatedTotalCost"
              timePeriod={timePeriod}
              clientId={clientId}
              locationIds={locationIds}
              positionIds={positionIds}
            />
          </Col>
          <Col xs={24} md={8}>
            <ComparisonCard
              title={t('totalApprovedCost')}
              tooltipText={
                <>
                  <Text style={{ color: colors.white }}>{t('totalApprovedCostTooltip')}</Text>
                  <br />
                  <Text style={{ color: colors.white }}>{t('note')}</Text>
                  <ul style={{ paddingLeft: '16px' }}>
                    <li>{t('approvedCostExcludedTimesheets')}</li>
                    <li>{t('monthlyWageExcludedTimesheets')}</li>
                    <li>{t('platformFeesExcluded')}</li>
                  </ul>
                </>
              }
              filterDimension="PaymentsPaymentitem.date"
              filterMeasure="PaymentsPaymentitem.totalExpenses"
              timePeriod={timePeriod}
              clientId={clientId}
              locationIds={locationIds}
              positionIds={positionIds}
            />
          </Col>
          <Col xs={24} md={8}>
            <ComparisonCard
              title={t('averageCostPerWorker')}
              tooltipText={t('averageCostPerWorkerTooltip')}
              filterDimension="PaymentsPaymentitem.date"
              filterMeasure="PaymentsPaymentitem.avgCostPerWorker"
              timePeriod={timePeriod}
              clientId={clientId}
              locationIds={locationIds}
              positionIds={positionIds}
            />
          </Col>
        </Row>
        <Row justify="center" align="middle" style={{ height: '100%', marginBottom: '34px' }}>
          <Col span={24}>
            <CostTypeBreakdownChart
              timePeriod={timePeriod}
              granularity={granularity}
              clientId={clientId}
              locationIds={locationIds}
              positionIds={positionIds}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  cubeJsToken: state.user.cubeJsToken,
});

export default connect(mapStateToProps, null)(CostTab);
