import base from './base';
import { endpoints, readonlyEndpoints } from '../config';
import { DEFAULT_APPLICATION_EXPIRE_HOUR } from '../constants';

const list = async params => {
  const response = await base.api.get(`${endpoints.applications}/`, { params });
  return response;
};

const applicantsList = async params => {
  const response = await base.api.get(`${readonlyEndpoints.applications}/chat-members-list/`, { params });
  return response;
};

const applicationHistoryCount = async params => {
  const response = await base.api.get(`${readonlyEndpoints.applications}/application-history-count/`, { params });
  return response;
};

const applicationHistory = async (applicationId, params) => {
  const response = await base.api.get(`${readonlyEndpoints.applications}/${applicationId}/application-history/`, {
    params,
  });
  return response;
};

const approve = async (applicationId, offerExpiryHour) => {
  // This captures if offerExpiryHour is null OR undefined
  let offerExpiry = offerExpiryHour;
  if (!offerExpiry) {
    offerExpiry = DEFAULT_APPLICATION_EXPIRE_HOUR;
  }
  const response = await base.api.post(`${endpoints.applications}/${applicationId}/approve/`, {
    offer_expire_minutes: offerExpiry * 60, // convert offer expiry time from hours to minutes
  });
  return response;
};

const bulkApprove = async (applicationIds, offerExpiryHour) => {
  // This captures if offerExpiryHour is null OR undefined
  let offerExpiry = offerExpiryHour;
  if (!offerExpiry) {
    offerExpiry = DEFAULT_APPLICATION_EXPIRE_HOUR;
  }
  const response = await base.api.post(`${endpoints.applications}/bulk-approve/`, {
    application_ids: applicationIds,
    offer_expire_minutes: offerExpiry * 60, // convert offer expiry time from hours to minutes
  });
  return response;
};

const reject = async (applicationId, reason, notes) => {
  const response = await base.api.post(`${endpoints.applications}/${applicationId}/reject/`, {
    rejection_reason: reason,
    notes,
  });
  return response;
};

const bulkReject = async (applicationIds, reason, notes) => {
  const response = await base.api.post(`${endpoints.applications}/bulk-reject/`, {
    application_ids: applicationIds,
    rejection_reason: reason,
    notes,
  });
  return response;
};

const shortlist = async applicationId => {
  const response = await base.api.post(`${endpoints.applications}/${applicationId}/shortlist/`);
  return response;
};

const bulkShortlist = async applicationIds => {
  const response = await base.api.post(`${endpoints.applications}/bulk-shortlist/`, {
    application_ids: applicationIds,
  });
  return response;
};

const unShortlist = async applicationId => {
  const response = await base.api.post(`${endpoints.applications}/${applicationId}/unshortlist/`);
  return response;
};

const bulkUnshortlist = async applicationIds => {
  const response = await base.api.post(`${endpoints.applications}/bulk-unshortlist/`, {
    application_ids: applicationIds,
  });
  return response;
};

const interview = async applicationId => {
  const response = await base.api.post(`${endpoints.applications}/${applicationId}/interview/`);
  return response;
};

const bulkInterview = async applicationIds => {
  const response = await base.api.post(`${endpoints.applications}/bulk-interview/`, {
    application_ids: applicationIds,
  });
  return response;
};

const unInterview = async applicationId => {
  const response = await base.api.post(`${endpoints.applications}/${applicationId}/uninterview/`);
  return response;
};

const bulkUninterview = async applicationIds => {
  const response = await base.api.post(`${endpoints.applications}/bulk-uninterview/`, {
    application_ids: applicationIds,
  });
  return response;
};

const getStatistics = async params => {
  const response = await base.api.get(`${readonlyEndpoints.applications}/statistics/`, { params });
  return response;
};

const getApplicationStatus = async params => {
  const response = await base.api.get(`${readonlyEndpoints.applications}/application-status/`, { params });
  return response;
};

const getHiringStatistics = async params => {
  const response = await base.api.get(`${readonlyEndpoints.applications}/hiring-statistics/`, { params });
  return response;
};

const remindWorker = async applicationId => {
  const response = await base.api.post(`${endpoints.applications}/${applicationId}/remind-worker/`);
  return response;
};

const getRejectReasons = async (config = {}) => {
  const response = await base.api.get(`${endpoints.applications}/reject-reasons/`, config);
  return response;
};

export default {
  list,
  applicantsList,
  approve,
  bulkApprove,
  reject,
  bulkReject,
  shortlist,
  bulkShortlist,
  unShortlist,
  bulkUnshortlist,
  interview,
  bulkInterview,
  unInterview,
  bulkUninterview,
  getStatistics,
  getApplicationStatus,
  getHiringStatistics,
  applicationHistoryCount,
  applicationHistory,
  remindWorker,
  getRejectReasons,
};
