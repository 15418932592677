import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Upload, Button, Typography, Row, Col } from 'antd';
import clientsService from '../../services/clientApi';

const { Title, Text } = Typography;

class CompanySection extends React.Component {
  state = {
    isLoading: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        this.setState({ isLoading: true });
        const { client, fetchClientAndManager, t } = this.props;
        const userResponse = await clientsService.editClient({
          id: client.clientId,
          name: values.name,
          website: values.website,
          description: values.description,
        });
        if (userResponse) {
          message.success(t('Changes saved'));
          // Uploading logo is a separate API call. Check if user has uploaded any logo to upload.
          await this.uploadLogoIfNeeded(client.clientId, values.logo);
          await fetchClientAndManager();
          this.setState({ isLoading: false });
        } else {
          message.error(t('commonErrorMessage'));
          this.setState({ isLoading: false });
        }
      }
    });
  };

  uploadLogoIfNeeded = async (clientId, logo) => {
    // Upload logo if a new one was provided
    if (logo && logo.length > 0 && logo[0].originFileObj) {
      const data = await clientsService.uploadLogo(clientId, logo[0].originFileObj);
      return data;
    }
  };

  // We have this because of the Ant tutorial
  handleFileChange = e => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList.length > 1) {
      e.fileList.shift();
    }
    return e && e.fileList;
  };

  render() {
    const { isLoading } = this.state;
    const { client, isAdmin, t } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col xs={24} md={18}>
          <Row style={{ marginTop: '14px', marginBottom: '24px' }}>
            <Title level={3}>{t('Company details')}</Title>
            <Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
              {t(
                'Enter your company name and description. Workers see these on their mobile app when viewing your job offers, so it is a chance to promote your company.',
              )}
            </Text>
          </Row>
          <Form hideRequiredMark layout="vertical" onSubmit={this.handleSubmit}>
            <Form.Item hasFeedback label={t('companyName')} style={{ width: '50%', padding: 0 }}>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: t('Please input your name') }],
                initialValue: client.name,
              })(<Input disabled={!isAdmin} placeholder={t('companyName')} />)}
            </Form.Item>

            <Form.Item hasFeedback label={t('Website')} style={{ width: '50%' }}>
              {getFieldDecorator('website', {
                rules: [
                  { required: true, message: t('Please input a website') },
                  { type: 'url', message: t('Please input a valid URL') },
                ],
                initialValue: client.website,
              })(<Input disabled={!isAdmin} placeholder="https://www.example.com" />)}
            </Form.Item>

            <Form.Item hasFeedback label={t('Description')} style={{ width: '100%', marginBottom: '48px', padding: 0 }}>
              {getFieldDecorator('description', {
                rules: [
                  { required: true, message: t('Please input a description') },
                  { max: 1000, message: t('Please input less than 1000 characters') },
                ],
                initialValue: client.description,
              })(
                <Input.TextArea
                  autoSize={{ minRows: 7 }}
                  disabled={!isAdmin}
                  placeholder={t('descriptionHintText')}
                  style={{ marginBottom: 0 }}
                />,
              )}
            </Form.Item>

            <Row style={{ marginTop: '14px', marginBottom: '24px' }}>
              <Title level={4}>{t('Company Logo')}</Title>
              <Text style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.45)' }}>
                {t(
                  'Upload an image of your company logo to increase the credibility of your job postings. Square JPG min 480px, 1MB max file size.',
                )}
              </Text>
            </Row>
            <Form.Item label={t('Logo')}>
              {getFieldDecorator('logo', {
                valuePropName: 'fileList',
                getValueFromEvent: this.handleFileChange,
                // Set the initial fileList to the existing client logo if available
                initialValue: client.logo && [{ uid: '-1', name: '', status: 'done', url: client.logo }],
              })(
                <Upload
                  disabled={!isAdmin}
                  name="file"
                  accept=".jpg,.jpeg,.png"
                  listType="picture-card"
                  customRequest={({ onSuccess }) => {
                    setTimeout(() => {
                      onSuccess('ok');
                    }, 0);
                  }}
                >
                  <div>
                    <PlusOutlined />
                    {t('Upload')}
                  </div>
                </Upload>,
              )}
            </Form.Item>
            <Button
              type="v2-primary"
              htmlType="submit"
              loading={isLoading}
              onClick={this.handleSubmit}
              style={{ width: '120px', marginBottom: '24px' }}
              disabled={!isAdmin}
            >
              {t('Save')}
            </Button>
          </Form>
        </Col>
      </Row>
    );
  }
}

export default Form.create({ name: 'Company form' })(CompanySection);
