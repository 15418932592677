import React from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, Button, message, Upload, Typography, Row } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const { Text } = Typography;
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const UploadButton = ({ isLoading, t }) => {
  return (
    <>
      <Row>{isLoading ? <LoadingOutlined /> : <PlusOutlined />}</Row>
      <Row>
        <Text>{t('Upload')}</Text>
      </Row>
    </>
  );
};

class ChatUploadImageModal extends React.Component {
  state = {
    isLoading: false,
    imageUrl: undefined,
    fileType: undefined,
    fileObj: undefined,
  };

  beforeUpload = file => {
    const { t } = this.props;
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(t('uploadImageFormatError'));
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t('uploadImageSizeError'));
    }
    return isJpgOrPng && isLt2M;
  };

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ isLoading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Have to convert image to base64 for previewing & sending
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          fileObj: info.file.originFileObj,
          fileType: info.file.type,
          isLoading: false,
        }),
      );
    }
  };

  render() {
    const { visible, onCancel, onSend, t, onSendLoading } = this.props;
    const { isLoading, imageUrl, fileType, fileObj } = this.state;
    return (
      <Modal
        centered
        destroyOnClose
        maskClosable
        visible={visible}
        width="600px"
        bodyStyle={{ height: '432px' }}
        title={t('chatUploadImageModalTitle')}
        onCancel={onCancel}
        zIndex={99999}
        footer={
          <>
            <Button onClick={onCancel}>{t('cancel')}</Button>
            <Button
              type="v2-primary"
              loading={onSendLoading}
              disabled={!imageUrl}
              onClick={() => onSend(fileObj, fileType)}
            >
              {t('chatUploadImageModalSendImage')}
            </Button>
          </>
        }
      >
        <Upload
          name="avatar"
          listType="picture-card"
          accept=".jpg,.jpeg,.png"
          className="chat-image-uploader"
          showUploadList={false}
          beforeUpload={this.beforeUpload}
          onChange={this.handleChange}
          customRequest={({ onSuccess }) => {
            setTimeout(() => {
              onSuccess('ok');
            }, 0);
          }}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="avatar" style={{ objectFit: 'cover', maxHeight: '100%', maxWidth: '100%' }} />
          ) : (
            <UploadButton isLoading={isLoading} t={t} />
          )}
        </Upload>

        {/* Description of constraints */}
        <section style={{ paddingTop: '16px', paddingBottom: '32px' }}>
          <Text type="secondary">{t('chatUploadImageModalInstructions')}</Text>
        </section>
      </Modal>
    );
  }
}

export default withTranslation()(ChatUploadImageModal);
