import React, { useState, useEffect } from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Row, Col, Button, message, Typography, Grid, notification, Card } from 'antd';
import * as Sentry from '@sentry/browser';
import { PaymentElement, useStripe, useElements, AddressElement } from '@stripe/react-stripe-js';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import stripeApi from '../../../../services/stripeApi';
import { colors } from '../../../../styles/colors';
import PaymentSuccess from './PaymentSuccess';
import routes from '../../../../routes';
import { countryIds, jobTypes, paymentMethods } from '../../../../constants';
import LoadingSpinner from '../../../../shared/components/LoadingSpinner';
import PaymentMethodCard from './PaymentMethodCard';
import clientApi from '../../../../services/clientApi';
import { userUpdate } from '../../../../redux/user';
import { CUSTOMER_SUPPORT_SG_EMAIL } from '../../../../config';

const { useBreakpoint } = Grid;

const PaymentForm = ({
  backButtonElement,
  onPaymentSuccessful,
  gigData,
  estimatedCostElement,
  totalEstimatedCost,
  currency,
  history,
  isOnWebView,
  user,
  updateUser,
  onHoldAmount,
}) => {
  const [currentOnlinePaymentMethod, setCurrentOnlinePaymentMethod] = useState(null);
  const [isFirstGigJob, setIsFirstGigJob] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods.ONLINE_DIRECT_PAYMENT);
  const [loading, setLoading] = useState(true);
  const [confirming, setConfirming] = useState(false);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);

  const { xs } = useBreakpoint();
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  notification.config({ maxCount: 1 });
  const [notificationAction, contextHolder] = notification.useNotification();
  const notificationParams = {
    duration: 0,
    className: 'v2-notication',
    placement: 'topCenter',
    icon: <CloseCircleFilled style={{ color: colors.functionalError }} />,
  };

  const hasOnlineDirectPaymentMethod = user.paymentMethod === paymentMethods.ONLINE_DIRECT_PAYMENT;
  const hasOnlineInvoicePaymentMethod = user.paymentMethod === paymentMethods.ONLINE_INVOICE_PAYMENT;
  const hasPaymentMethod = hasOnlineDirectPaymentMethod || hasOnlineInvoicePaymentMethod;
  const isOnlineDirectPaymentMethod = selectedPaymentMethod === paymentMethods.ONLINE_DIRECT_PAYMENT;
  const isOnlineInvoicePaymentMethod = selectedPaymentMethod === paymentMethods.ONLINE_INVOICE_PAYMENT;
  const invoicePaymentMethod = isOnlineInvoicePaymentMethod || hasOnlineInvoicePaymentMethod;

  useEffect(() => {
    const getPaymentMethods = async () => {
      try {
        setLoading(true);
        const results = await stripeApi.getPaymentMethods();
        if (results?.data?.length) {
          setCurrentOnlinePaymentMethod(results.data[0]);
        }
        setLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        setLoading(false);
      }
    };

    getPaymentMethods();
  }, []);

  useEffect(() => {
    (() => setIsFirstGigJob(!hasPaymentMethod))();
  }, []);

  const updatePaymentIntentMetaData = (paymentIntentId, staffRequestId) => {
    if (paymentIntentId && staffRequestId) {
      try {
        stripeApi.updatePaymentIntent({ payment_intent_id: paymentIntentId, staff_request_id: staffRequestId });
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  const updateClient = async () => {
    try {
      const addressElement = elements.getElement('address');
      const billingInfo = await addressElement.getValue();
      const billingAddress = billingInfo.value.address;
      const client = await clientApi.editClient({
        id: user.clientId,
        payment_method: selectedPaymentMethod,
        billing_contact_name: billingInfo.value.name,
        billing_address: {
          street_1: billingAddress.line1,
          street_2: billingAddress.line2,
          province: billingAddress.state,
          zip: billingAddress.postal_code,
          country: countryIds[billingInfo.value.address.country],
        },
      });
      if (client) {
        await updateUser({
          paymentMethod: client.payment_method,
          billingAddress: client.billing_address,
          billingContactName: client.billing_contact_name,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleInvoicePayment = async paymentIntentId => {
    const createdGigResponse = await onPaymentSuccessful();

    if (paymentIntentId) {
      updatePaymentIntentMetaData(paymentIntentId, createdGigResponse?.id);
    }
    setConfirming(false);
    setShowPaymentSuccess(true);
    if (isOnWebView) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ success: true }));
    }
  };

  const handlePostConfirmAction = async response => {
    if (response.error) {
      notificationAction.error({ ...notificationParams, message: response.error });
    } else if (response.status === 'requires_action') {
      // Use Stripe.js to handle the required next action
      const { error } = await stripe.handleNextAction({
        clientSecret: response.client_secret,
      });
      if (error) {
        notificationAction.error({ ...notificationParams, message: error.message });
      } else {
        await handleInvoicePayment(response?.id);
      }
    } else {
      await handleInvoicePayment(response?.id);
    }
  };

  const handleConfirmPayment = async paymentMethodId => {
    try {
      // Create the PaymentIntent and obtain clientSecret
      const response = await stripeApi.createConfirmIntent({
        payment_method_id: paymentMethodId,
        amount: parseFloat(totalEstimatedCost.toFixed(2)), // TODO: this should be in the BE, remove this once done with the correct calculation in the backend
        ...gigData,
      });
      await handlePostConfirmAction(response);
    } catch (e) {
      notificationAction.error({
        ...notificationParams,
        message: t('Sorry! An error occured in submission, please contact support'),
      });
      Sentry.captureException(e);
      setConfirming(false);
    }
  };

  const handleAddCreditCardAndHold = async () => {
    // Create the PaymentMethod using the details collected by the Payment Element
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
    });
    if (error) {
      notificationAction.error({
        ...notificationParams,
        message: error.message,
      });
      setConfirming(false);
      return;
    }
    handleConfirmPayment(paymentMethod.id);
  };

  const handleHoldCreditCard = () => {
    if (!currentOnlinePaymentMethod?.id) {
      return;
    }
    handleConfirmPayment(currentOnlinePaymentMethod.id);
  };

  const handlePostGig = async () => {
    setConfirming(true);
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setConfirming(false);
      return;
    }

    if (invoicePaymentMethod) {
      handleInvoicePayment();
    } else if (!currentOnlinePaymentMethod && (isOnlineDirectPaymentMethod || hasOnlineDirectPaymentMethod)) {
      handleAddCreditCardAndHold();
    } else if (currentOnlinePaymentMethod && hasOnlineDirectPaymentMethod) {
      handleHoldCreditCard();
    }

    if (!user.billingAddress || selectedPaymentMethod !== user.paymentMethod) {
      await updateClient();
    }
  };

  const handleContinue = () => {
    if (!currentOnlinePaymentMethod && isFirstGigJob) {
      history.push(`${routes.staffRequests}?tab=${jobTypes.GIGS}&firstGigPosted=true`);
    } else {
      message.success(t('gigIsPostedSucess'));
      history.push(`${routes.staffRequests}?tab=${jobTypes.GIGS}`);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (showPaymentSuccess) {
    return (
      <PaymentSuccess
        amount={`${onHoldAmount} ${currency.toUpperCase()}`}
        estimatedCostElement={estimatedCostElement}
        isOnlineInvoicePaymentMethod={invoicePaymentMethod}
        onContinue={handleContinue}
        email={user?.billingContactEmail}
      />
    );
  }

  return (
    <Row>
      {contextHolder}
      <Col xs={24} md={14}>
        {hasPaymentMethod ? (
          <div style={{ marginBottom: xs ? 0 : 40 }}>
            {hasOnlineDirectPaymentMethod &&
              (currentOnlinePaymentMethod ? (
                <Card
                  style={{
                    marginBottom: 20,
                    border: `1px solid ${colors.infoHighlightBlue}`,
                    borderRadius: '4px',
                  }}
                >
                  <Row style={{ alignItems: 'center', marginBottom: '8px' }}>
                    <Typography.Text
                      style={{
                        fontSize: '16px',
                        fontWeight: '600',
                        color: colors.textTitle,
                        marginRight: '8px',
                      }}
                    >
                      {t('creditCard')}
                    </Typography.Text>
                    <CheckCircleFilled style={{ color: colors.infoHighlightBlue, fontSize: '16px' }} />
                  </Row>
                  <Typography.Paragraph style={{ marginBottom: 16, color: colors.primaryText }}>
                    {t('paymentDetailsDescription', { amount: onHoldAmount })}
                  </Typography.Paragraph>
                  <PaymentMethodCard paymentMethod={currentOnlinePaymentMethod} />
                </Card>
              ) : (
                <>
                  <Card
                    style={{
                      marginBottom: 20,
                      border: `1px solid ${colors.infoHighlightBlue}`,
                      borderRadius: '4px',
                    }}
                  >
                    <Row style={{ alignItems: 'center', marginBottom: '8px' }}>
                      <Typography.Text
                        style={{
                          fontSize: '16px',
                          fontWeight: '600',
                          color: colors.textTitle,
                          marginRight: '8px',
                        }}
                      >
                        {t('creditCard')}
                      </Typography.Text>
                      <CheckCircleFilled style={{ color: colors.infoHighlightBlue, fontSize: '16px' }} />
                    </Row>
                    <Typography.Paragraph style={{ marginBottom: '16px', color: colors.primaryText }}>
                      {t('paymentDetailsDescription', { amount: onHoldAmount })}
                    </Typography.Paragraph>
                    <PaymentElement options={{ layout: 'auto' }} />
                  </Card>
                  <div style={{ marginBottom: 20 }}>
                    <Typography.Paragraph
                      style={{ fontSize: 16, fontWeight: '600', marginBottom: 10, color: colors.textTitle }}
                    >
                      {t('billingAddress')}
                    </Typography.Paragraph>
                    <AddressElement options={{ mode: 'billing' }} />
                  </div>
                </>
              ))}
            {hasOnlineInvoicePaymentMethod && (
              <Card
                style={{
                  marginBottom: 20,
                  border: `1px solid ${colors.infoHighlightBlue}`,
                  borderRadius: '4px',
                }}
              >
                <Row style={{ alignItems: 'center', marginBottom: '8px' }}>
                  <Typography.Text
                    style={{
                      fontSize: '16px',
                      fontWeight: '600',
                      color: colors.textTitle,
                      marginRight: '8px',
                    }}
                  >
                    {t('otherPaymentMethod')}
                  </Typography.Text>
                  <CheckCircleFilled style={{ color: colors.infoHighlightBlue, fontSize: '16px' }} />
                </Row>
                <Typography.Paragraph style={{ marginBottom: '14px', color: colors.primaryText }}>
                  {t('otherPaymentMethodDescription1')}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ marginBottom: '14px', color: colors.primaryText }}>
                  {t('otherPaymentMethodDescription2', { email: user.billingContactEmail })}
                </Typography.Paragraph>
              </Card>
            )}
            <Typography.Text
              style={{
                fontSize: '12px',
                fontWeight: '400',
                color: colors.secondaryText,
              }}
            >
              <Trans i18nKey="changePaymentMethodGuide">
                To change your payment method,
                <a
                  href={`mailto:${CUSTOMER_SUPPORT_SG_EMAIL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  {' '}
                  contact support
                </a>
                .
              </Trans>
            </Typography.Text>
          </div>
        ) : (
          <>
            <Typography.Paragraph
              style={{ fontSize: 16, fontWeight: '600', marginBottom: 10, color: colors.textTitle }}
            >
              {t('paymentMethod')}
            </Typography.Paragraph>
            <Card
              hoverable
              style={{
                marginBottom: 20,
                border: `1px solid ${isOnlineDirectPaymentMethod ? colors.infoHighlightBlue : colors.componentBorder}`,
                borderRadius: '4px',
              }}
              onClick={() => setSelectedPaymentMethod(paymentMethods.ONLINE_DIRECT_PAYMENT)}
            >
              <Row style={{ alignItems: 'center', marginBottom: '8px' }}>
                <Typography.Text
                  style={{
                    fontSize: '16px',
                    fontWeight: '600',
                    color: colors.textTitle,
                    marginRight: '8px',
                  }}
                >
                  {t('creditCard')}
                </Typography.Text>
                {isOnlineDirectPaymentMethod && (
                  <CheckCircleFilled style={{ color: colors.infoHighlightBlue, fontSize: '16px' }} />
                )}
              </Row>
              <Typography.Paragraph style={{ marginBottom: '16px', color: colors.primaryText }}>
                {t('paymentDetailsDescription', { amount: onHoldAmount })}
              </Typography.Paragraph>
              {isOnlineDirectPaymentMethod && <PaymentElement options={{ layout: 'auto' }} />}
            </Card>
            <Card
              disabled={user.isOnlineDirectPaymentSetManually}
              hoverable
              style={{
                marginBottom: 20,
                border: `1px solid ${!isOnlineDirectPaymentMethod ? colors.infoHighlightBlue : colors.componentBorder}`,
                borderRadius: '4px',
              }}
              onClick={() => setSelectedPaymentMethod(paymentMethods.ONLINE_INVOICE_PAYMENT)}
            >
              <Row style={{ alignItems: 'center', marginBottom: '8px' }}>
                <Typography.Text
                  style={{
                    fontSize: '16px',
                    fontWeight: '600',
                    color: colors.textTitle,
                    marginRight: '8px',
                  }}
                >
                  {t('otherPaymentMethod')}
                </Typography.Text>
                {!isOnlineDirectPaymentMethod && (
                  <CheckCircleFilled style={{ color: colors.infoHighlightBlue, fontSize: '16px' }} />
                )}
              </Row>
              <Typography.Paragraph style={{ marginBottom: '14px', color: colors.primaryText }}>
                {t('otherPaymentMethodDescription1')}
              </Typography.Paragraph>
              <Typography.Paragraph style={{ marginBottom: '14px', color: colors.primaryText }}>
                {t('otherPaymentMethodDescription2', { email: user.billingContactEmail })}
              </Typography.Paragraph>
            </Card>
            <div style={{ marginBottom: 20 }}>
              <Typography.Paragraph
                style={{ fontSize: 16, fontWeight: '600', marginBottom: 10, color: colors.textTitle }}
              >
                {t('billingAddress')}
              </Typography.Paragraph>
              <AddressElement options={{ mode: 'billing' }} />
            </div>
          </>
        )}
        {xs && estimatedCostElement}
        <Row style={{ margin: '40px 0 20px 0' }}>
          <Button
            style={{ padding: '0 32px', marginRight: '10px' }}
            type="v2-primary"
            onClick={handlePostGig}
            disabled={confirming}
            loading={confirming}
            size={xs ? 'large' : 'middle'}
          >
            {t('postGig')}
          </Button>
          {backButtonElement}
        </Row>
      </Col>
      {!xs && (
        <Col xs={24} sm={24} md={{ span: 7, offset: 3 }} style={{ marginBottom: 40 }}>
          {estimatedCostElement}
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = state => {
  return {
    isOnWebView: state.global.isOnWebView,
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUser: updatedUser => {
      dispatch(userUpdate(updatedUser));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
