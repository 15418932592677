import React from 'react';
import { Row, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';

const ProgressBar = ({ steps }) => (
  <Row style={{ width: '200px', justifyContent: 'flex-end' }}>
    {steps.map((item, index) => (
      <Row
        key={`progress-bar-item-${index}`}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Tooltip title={item.tooltipTitle}>{item.icon}</Tooltip>
        {item.bridgeColor && (
          <div
            style={{
              width: '40px',
              height: '4px',
              right: '105px',
              marginInline: '3px',
              backgroundColor: item.bridgeColor,
            }}
          />
        )}
      </Row>
    ))}
  </Row>
);

export default withTranslation()(ProgressBar);
