import React from 'react';
import { Row, Col, Button, Typography } from 'antd';
import { userStatuses } from '../../../../constants';

import CreditCardBrandImg from '../../../../shared/components/CreditCardBrandImg';
import ChangePaymentModal from '../../../../shared/components/ChangePaymentModal';

const { Paragraph, Text } = Typography;
class CompletedCreditDisplay extends React.Component {
  state = {
    showChangePaymentModal: false,
  };

  render() {
    const { showChangePaymentModal } = this.state;
    const { isAdmin, t, client } = this.props;
    const clientIsActive = client.status === userStatuses.ACTIVE;
    return (
      <>
        <Row type="flex" style={{ marginBottom: '24px' }} gutter={16}>
          <Col>
            <CreditCardBrandImg brand={client.cardBrand} style={{ width: '45px' }} />
          </Col>
          <Col>
            <Paragraph style={{ marginBottom: '0' }}>
              <Text strong>
                {client.cardBrand} {t('ending in')} {client.cardLastDigits}
              </Text>
            </Paragraph>
            <Paragraph style={{ marginBottom: '0' }}>
              {t('Expires')} {`${client.cardExpMonth}/${client.cardExpYear}`}
            </Paragraph>
          </Col>
        </Row>
        <Row type="flex" gutter={8}>
          <Col>
            <Button
              disabled={!isAdmin || clientIsActive}
              type="v2-secondary"
              onClick={() => {
                this.setState({ showChangePaymentModal: true });
              }}
            >
              {t('Change payment method')}
            </Button>
          </Col>
        </Row>

        {/* Modals */}
        {showChangePaymentModal && (
          <ChangePaymentModal
            visible={showChangePaymentModal}
            onClose={() => this.setState({ showChangePaymentModal: false })}
          />
        )}
      </>
    );
  }
}

export default CompletedCreditDisplay;
