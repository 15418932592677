import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Steps,
  Form,
  Typography,
  InputNumber,
  Button,
  TimePicker,
  Select,
  Switch,
  message,
  Radio,
  Grid,
} from 'antd';
import { PlusCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import { range } from 'lodash';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import staffRequestTemplateApi from '../../../services/staffRequestTemplateApi';
import routes from '../../../routes';
import {
  TIME_FORMAT_24_HOUR,
  staffRequestTemplateStatuses,
  employmentTypes,
  roles,
  clientTypes,
  jobTypes,
  SERVER_DATE_FORMAT_WITH_TIME,
  clientStatuses,
  gigContractTypes,
  countryMinWageAmounts,
  onlinePaymentMethods,
} from '../../../constants';
import dateUtils from '../../../utilities/dateUtils';
import DateSelector from './components/DateSelector';
import ReviewGigDetails from './components/ReviewGigDetails';
import OnboardingModal from '../../../shared/components/OnboardingModal';
import LocationFormModal from '../../SettingsPage/LocationsSection/components/LocationFormModal';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import EstimatedCost from './components/EstimatedCost';
import { colors } from '../../../styles/colors';
import staffRequestApi from '../../../services/staffRequestApi';
import formsApi from '../../../services/formsApi';
import gigUtils from '../../../utilities/gigUtils';
import PaymentDetailSection from './components/PaymentDetailSection';
import { SERVER_DATE } from '../../../styles/dates';
import clientApi from '../../../services/clientApi';
import { FAQ_SG_FREELANCE_WORK_LINK } from '../../../config';

const { Title, Text, Paragraph } = Typography;
const { Step } = Steps;
const { Item } = Form;
const { useBreakpoint } = Grid;
const initialValues = {
  staff_required: 1,
  template: undefined,
  location: undefined,
  start_time: undefined,
  end_time: undefined,
  unpaid_break: true,
  break_duration_in_minutes: 0,
  selected_dates: [],
  wage_amount: undefined,
  vip_workers_only: false,
};

const BREAK_RANGE = range(5, 241, 5);
const DEFAULT_TIMEZONE = moment.tz.guess();
const MAX_STAFF_REQUIRED = 20;

const StaffRequestGigFormView = ({
  user,
  history,
  fetchClientAndManager,
  isOnWebView,
  vipWorkerJobPosting,
  selectedLocationId,
}) => {
  const [fields, setFields] = useState(initialValues);
  const [vipWorkerCount, setVipWorkerCount] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [staffRequestTemplates, setStaffRequestTemplates] = useState([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
  const [selectedDates, setSelectedDates] = useState([]);
  const [posting, setPosting] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(
    user?.type === clientTypes.SELF_SERVE && user?.locations.length === 0,
  );
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [breakDownCalculation, setBreakDownCalculation] = useState(undefined);

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { xs } = useBreakpoint();

  const isOnlinePayment =
    user?.type === clientTypes.SELF_SERVE &&
    (!user?.paymentMethod || onlinePaymentMethods.includes(user?.paymentMethod));

  const getTemplatesByLocationId = locationId =>
    staffRequestTemplates.filter(
      template =>
        template?.location?.id === locationId || (template?.is_global && user?.type === clientTypes.SELF_SERVE),
    );

  const getAndSetVipWorkerCount = async position => {
    let newVipWorkerCount = 0;
    try {
      if (position?.id && user?.clientId) {
        const response = await clientApi.fetchVipWorkers(user.clientId, position.id);
        newVipWorkerCount = response?.count || 0;
      }
      if (newVipWorkerCount > 0) {
        form.setFields([
          {
            name: 'vip_workers_only',
            errors: [],
          },
        ]);
      }
      setVipWorkerCount(newVipWorkerCount || 0);
    } catch (error) {
      Sentry.captureException(error);
      message.warning(t('An unexpected error occurred'));
    }
  };

  const setVipWorkers = async position => {
    await getAndSetVipWorkerCount(position);
  };

  useEffect(() => {
    const fetchStaffRequestTemplates = async () => {
      try {
        setIsLoadingTemplates(true);
        const params = {
          status: staffRequestTemplateStatuses.POSTED,
          employment_type: employmentTypes.GIG.value,
          include_global: true,
        };
        const templatesResponse = await staffRequestTemplateApi.fetchStaffRequestTemplates(params);
        setStaffRequestTemplates(templatesResponse?.results || []);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setIsLoadingTemplates(false);
      }
    };
    fetchStaffRequestTemplates();
  }, []);

  useEffect(() => {
    if (!staffRequestTemplates.length) {
      return;
    }
    let template;
    let defaultLocation;
    if (selectedLocationId) {
      const [firstTemplate] = getTemplatesByLocationId(selectedLocationId);
      template = firstTemplate;
    } else {
      const [firstTemplate] = staffRequestTemplates;
      template = firstTemplate;
    }
    if (selectedLocationId && template?.location?.id !== selectedLocationId) {
      defaultLocation = user.locations.find(location => location.id === selectedLocationId);
    } else {
      defaultLocation = template?.location || user.locations[0];
    }

    // Set default location and template
    form.setFieldsValue({
      location: defaultLocation?.id,
      template: template?.id,
      wage_amount: template?.wage_amount,
    });
    setFields({
      ...fields,
      location: defaultLocation,
      wage_amount: template?.wage_amount,
      template,
    });
    setVipWorkers(template?.position);
  }, [staffRequestTemplates]);

  useEffect(() => {
    if (!isLoadingTemplates && staffRequestTemplates.length === 0) {
      // show error message when no template created
      form.setFields([
        {
          name: 'template',
          errors: [t('selectJobTemplateError')],
        },
      ]);
      if (user.locations.length) {
        // set default location
        const [location] = user.locations;
        form.setFieldsValue({ location: location?.id });
        setFields({ ...fields, location });
      } else {
        // show error message when no location created
        form.setFields([
          {
            name: 'location',
            errors: [t('Please select a location')],
          },
        ]);
      }
    }
  }, [staffRequestTemplates, isLoadingTemplates]);

  useEffect(() => {
    const fetchForm = async formId => {
      try {
        const result = await formsApi.fetchFormById(formId);
        setFields({ ...fields, template: { ...fields.template, form_title: result.title } });
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    // fetch form details when template form_id changes
    if (fields?.template?.form_id) {
      fetchForm(fields?.template?.form_id);
    }
  }, [fields?.template?.form_id]);

  const isFormFieldsValid = () => {
    const requiredFields = ['staff_required', 'template', 'location', 'start_time', 'end_time'];
    return requiredFields.every(field => fields[field]) && selectedDates.length > 0;
  };

  const getDuration = (hours, minutes) => {
    if (!fields?.start_time || !fields?.end_time) {
      return t('totalHoursCount', { count: 0 });
    }
    const hoursText = t('totalHoursCount', { count: hours });
    const minutesText = minutes > 0 ? t('totalMinsCount', { count: minutes }) : '';
    return `${hoursText} ${minutesText}`.trim();
  };

  const checkIsCrossShift = () => {
    if (!fields.start_time || !fields.end_time) {
      return false;
    }
    const start = moment(fields.start_time);
    const end = moment(fields.end_time);
    const isSame = start.format(TIME_FORMAT_24_HOUR) === end.format(TIME_FORMAT_24_HOUR);
    if (isSame) {
      return false;
    }
    return end.isBefore(start);
  };

  const getGigBreakDownCalculation = async params => {
    try {
      const response = await staffRequestApi.getGigBreakDownCalculation(params);
      setBreakDownCalculation(response);
    } catch (error) {
      Sentry.captureException(error);
      message.error(t('commonErrorMessage'));
    }
  };

  const onValuesChange = (changeValues, values) => {
    if (values.template && values.staff_required && values.start_time && values.end_time) {
      // get updated gig calculation when form value changes
      getGigBreakDownCalculation({
        staff_request_template: values.template,
        wage_amount: values.wage_amount ? parseFloat(values.wage_amount).toFixed(2) : undefined,
        unpaid_break: 'unpaid_break' in changeValues ? !changeValues?.unpaid_break : fields.unpaid_break,
        staff_required: values.staff_required,
        break_duration_in_minutes: values.break_duration_in_minutes,
        shift_dates: values.selected_dates.map(date => moment(date).format(SERVER_DATE)),
        start_time: values?.start_time?.format(SERVER_DATE_FORMAT_WITH_TIME),
        end_time: values?.end_time?.format(SERVER_DATE_FORMAT_WITH_TIME),
      });
    }

    Object.keys(values).forEach(field => {
      const error = form.getFieldError(field);
      if (!error.length) {
        return;
      }
      // validate field
      form.validateFields([field]);
    });
  };

  const handleOnChangeFields = (name, value) => {
    setFields({ ...fields, [name]: value });
  };

  const handleLocationChange = (locations, locationId) => {
    const location = locations.find(({ id }) => id === locationId);
    // automatically changed selected template if location change
    const template = fields.template || getTemplatesByLocationId(location?.id)[0];
    form.setFieldsValue({ template: template?.id });
    setFields({ ...fields, location, template });
    if (!template) {
      form.setFields([
        {
          name: 'template',
          errors: [t('selectJobTemplateError')],
        },
      ]);
    }
  };

  const handleSrTemplateChange = templateId => {
    const template = staffRequestTemplates.find(item => item.id === templateId);
    form.setFieldsValue({
      template: template?.id,
      wage_amount: template.wage_amount,
    });
    setFields({
      ...fields,
      template: { ...fields.template, ...template },
      wage_amount: template.wage_amount,
    });
    setVipWorkers(template?.position);
  };

  const handleBreakDurationChange = value => {
    if (value) {
      setFields({ ...fields, unpaid_break: true, break_duration_in_minutes: value });
    } else {
      setFields({ ...fields, unpaid_break: true, break_duration_in_minutes: 0 });
    }
  };

  const handleDateChange = dates => {
    setSelectedDates(dates);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNext = async () => {
    try {
      await form.validateFields();
      setCurrentStep(currentStep + 1);
    } catch (error) {
      if (error?.errorFields) {
        const { name } = error.errorFields[0];
        form.scrollToField(name[0], {
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    }
  };

  const createGig = () => {
    const { clientId } = user;
    const isCrossShift = checkIsCrossShift();
    const gigParams = gigUtils.getGigParams({ fields, selectedDates, clientId, isCrossShift });

    return staffRequestApi.createGig(gigParams);
  };

  const handlePostGig = async () => {
    try {
      setPosting(true);
      await createGig();
      setPosting(false);
      history.push(`${routes.staffRequests}?tab=${jobTypes.GIGS}`);
    } catch (error) {
      Sentry.captureException(error);
      message.error(t('Sorry! An error occurred in submission, please contact support'));
      setPosting(false);
    }
  };

  const handlePaymentSuccess = async () => {
    try {
      if (user?.type === clientTypes.SELF_SERVE && user?.status === clientStatuses.PILOT) {
        clientApi.approve(user.clientId);
      }
      const gigResponse = await createGig();
      return gigResponse;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleChangeStaffRequired = action => {
    let staffRequired = fields.staff_required;
    if (action === 'increase') {
      staffRequired += 1;
    } else {
      staffRequired -= 1;
    }
    form.setFieldsValue({
      staff_required: staffRequired,
    });
    setFields({ ...fields, staff_required: staffRequired });
    // manually trigger onValuesChange since setFields do not trigger onFieldsChange and onValuesChange
    // https://ant.design/components/form#setFieldsValue-do-not-trigger-onFieldsChange-or-onValuesChange
    onValuesChange(
      { staff_required: staffRequired },
      {
        ...fields,
        staff_required: staffRequired,
        location: fields.location.id,
        template: fields.template.id,
        selected_dates: selectedDates,
      },
    );
  };

  const getSteps = () => {
    const steps = [{ title: t('gigDetails').toUpperCase() }, { title: t('reviewSubmit').toUpperCase() }];
    if (isOnlinePayment) {
      steps.push({ title: t('paymentDetails').toUpperCase() });
    }
    return steps;
  };

  const steps = getSteps();

  const isFormValid = isFormFieldsValid();
  const { locations } = user;
  const [hours, minutes] = dateUtils.getDurationHoursAndMinutes(fields?.start_time, fields?.end_time);
  const duration = getDuration(hours, minutes);
  const isCrossShift = checkIsCrossShift();

  const breakDurationChoices = [
    { label: t('none'), value: 0 },
    ...BREAK_RANGE.map(value => ({
      label: `${value}`,
      value,
    })),
  ];

  const staffRequestTemplatesChoices = getTemplatesByLocationId(fields?.location?.id).map(template => ({
    value: template.id,
    label: template?.template_name,
  }));

  const locationChoices = locations.map(location => ({ value: location.id, label: location.name }));

  const wageAmount = fields?.wage_amount || fields?.template?.wage_amount || 0;

  const shiftDurationWithBreaks = gigUtils.getShiftDurationWithBreaks({
    hours,
    minutes,
    breakDuration: fields?.break_duration_in_minutes,
    unpaidBreak: fields.unpaid_break,
  });

  const isFreelanceContract = user?.gig_contract_type === gigContractTypes.FREELANCE;
  const { days, dateRange } = dateUtils.generateTwoWeeks(DEFAULT_TIMEZONE);

  const minWageAmount = countryMinWageAmounts[user.country.code][fields?.template?.wage_type] || 0;

  if (posting) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Form
        hideRequiredMark
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        {/* Header */}
        <Row style={{ flex: 1, flexDirection: 'column', marginBottom: '36px' }}>
          <Title level={2} style={{ marginBottom: '8px' }}>
            {t('postAGig')}
          </Title>
        </Row>
        {/* STEPS */}
        <Row>
          <Col xs={24} style={{ maxWidth: steps.length * 250 }}>
            <Steps current={currentStep} className="v2-steps-wrapper" responsive style={{ marginBottom: '44px' }}>
              {steps.map(step => (
                <Step disabled key={step.title} title={step.title} />
              ))}
            </Steps>
          </Col>
        </Row>
        {/* GIG CREATION SECTIONS */}
        <section style={{ display: currentStep === 0 ? 'block' : 'none' }}>
          <Row>
            <Col xs={24} md={13}>
              <Row style={{ marginBottom: 20, flexDirection: 'column' }}>
                <Text strong style={{ fontSize: '16px' }}>
                  {t('gigDetails')}
                </Text>
                <Col xs={24} md={12}>
                  <Item
                    label={t('gigLocation')}
                    name="location"
                    rules={[
                      { required: true, message: t('Please select a location') },
                      {
                        validator(rule, value, callback) {
                          if (value && !locations.find(({ id }) => id === value)) {
                            callback(t('chooseFromListError'));
                          }
                          callback();
                        },
                      },
                    ]}
                  >
                    <Select
                      disabled={Boolean(selectedLocationId)}
                      loading={isLoadingTemplates}
                      placeholder={t('Location')}
                      onChange={selection => handleLocationChange(locations, selection)}
                      size={xs ? 'large' : 'middle'}
                      className="v2-input"
                    >
                      {locationChoices.map(option => (
                        <Select.Option key={option.value} value={option.value}>
                          <Typography.Text>{t(option.label)}</Typography.Text>
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                  {isOnWebView ? (
                    <Paragraph style={{ color: colors.secondaryText, marginTop: 8, marginBottom: 0 }}>
                      {t('addNewLocationInClientDash')}
                    </Paragraph>
                  ) : (
                    <>
                      {user.role === roles.ADMIN && (
                        <Button
                          type="link"
                          icon={<PlusCircleOutlined />}
                          onClick={() => setShowLocationModal(true)}
                          style={{ marginTop: 8, padding: '0px' }}
                        >
                          <span style={{ textDecoration: 'underline' }}>{t('Create new location')}</span>
                        </Button>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: 20, flexDirection: 'column' }}>
                <Col xs={24} md={12}>
                  <Item
                    label={t('gigJobTemplate')}
                    style={{ marginBottom: '8px', marginTop: 16 }}
                    rules={[{ required: true, message: t('selectJobTemplateError') }]}
                    name="template"
                  >
                    <Select
                      loading={isLoadingTemplates}
                      placeholder={t('autoCompletePlaceholder')}
                      onChange={selection => handleSrTemplateChange(selection)}
                      size={xs ? 'large' : 'middle'}
                      className="v2-input"
                    >
                      {staffRequestTemplatesChoices.map(option => (
                        <Select.Option key={option.value} value={option.value}>
                          <Typography.Text>{t(option.label)}</Typography.Text>
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                  {isOnWebView ? (
                    <Paragraph style={{ color: colors.secondaryText, marginTop: 8, marginBottom: 0 }}>
                      {t('addNewTemplateInClientDash')}
                    </Paragraph>
                  ) : (
                    <Button
                      type="link"
                      icon={<PlusCircleOutlined />}
                      style={{ padding: 0 }}
                      onClick={() => window.open(routes.createJobTemplate, '_blank', 'noopener,noreferrer')}
                    >
                      {t('createNewTemplate')}
                    </Button>
                  )}
                </Col>
              </Row>
              <Col style={{ marginBottom: 20 }} xs={24} md={12}>
                <Typography.Text>
                  {isFreelanceContract ? t('howManyFreelancersAreLookingFor') : t('howManyAreLookingFor')}
                </Typography.Text>
                <Row style={{ marginTop: 10 }} gutter={6}>
                  <Col>
                    <Button
                      onClick={() => handleChangeStaffRequired('decrease')}
                      disabled={Number(fields.staff_required) ? Number(fields.staff_required) <= 1 : true}
                      size={xs ? 'large' : 'middle'}
                    >
                      <MinusOutlined />
                    </Button>
                  </Col>
                  <Col style={{ flex: 1 }}>
                    <Item
                      style={{ marginBottom: 0 }}
                      rules={[
                        { required: true, message: t('cannotExceedStaffRequiredError') },
                        {
                          validator(rule, value, callback) {
                            if (value && value > MAX_STAFF_REQUIRED) {
                              callback(t('cannotExceedStaffRequiredError'));
                            }
                            callback();
                          },
                        },
                      ]}
                      name="staff_required"
                    >
                      <InputNumber
                        min={1}
                        parser={val => (val ? parseInt(val, 10) : '')}
                        onKeyUp={e => handleOnChangeFields('staff_required', parseInt(e.target.value, 10))}
                        onStep={value => handleOnChangeFields('staff_required', parseInt(value, 10))}
                        type="tel"
                        maxLength={2}
                        style={{ width: '100%', textAlign: 'center' }}
                        size={xs ? 'large' : 'middle'}
                        className="v2-input"
                      />
                    </Item>
                  </Col>
                  <Col>
                    <Button onClick={() => handleChangeStaffRequired('increase')} size={xs ? 'large' : 'middle'}>
                      <PlusOutlined />
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col style={{ marginBottom: 20, marginTop: 16 }}>
                <Text>{isFreelanceContract ? t('freelancerFee') : t('staffFee')}</Text>
                <Row style={{ marginTop: 8 }}>
                  <Text style={{ padding: '4px 4px 4px 0px' }}>
                    {isFreelanceContract ? t('hourlyRate') : t('baseWage')}
                  </Text>
                  <Item
                    name="wage_amount"
                    rules={[
                      { required: true, message: t('Please input a wage amount') },
                      {
                        validator(rule, value, callback) {
                          if (value !== null && value < minWageAmount) {
                            callback(
                              t('wageAmountPleaseEnterError', { currency: user.country.code, amount: minWageAmount }),
                            );
                          } else {
                            callback();
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      addonBefore={user.country.currency_code}
                      min={1}
                      precision={2}
                      onKeyUp={e => handleOnChangeFields('wage_amount', parseFloat(e.target.value).toFixed(2))}
                      onStep={value => handleOnChangeFields('wage_amount', parseFloat(value).toFixed(2))}
                      type="tel"
                      addonAfter={isFreelanceContract ? t('perHour') : null}
                      placeholder={minWageAmount}
                      size={xs ? 'large' : 'middle'}
                      className="v2-input"
                    />
                  </Item>
                </Row>
              </Col>
              <Row style={{ marginBottom: 20, flexDirection: 'column' }}>
                <Text style={{ marginBottom: 10 }}>{t('selectDate', { dateRange })}</Text>
                <Col xs={24} md={20}>
                  <Item
                    name="selected_dates"
                    rules={[{ required: true, message: t('pleaseSelectDate') }]}
                    style={{ marginBottom: 0 }}
                  >
                    <DateSelector dates={days} onChange={handleDateChange} />
                  </Item>
                </Col>
              </Row>
              <Row style={{ marginBottom: 6 }} gutter={16}>
                <Col xs={12} md={8}>
                  <Item
                    style={{ marginBottom: 0 }}
                    label={t('shiftStartTime')}
                    name="start_time"
                    rules={[
                      { required: true, message: t('fieldIsRequired') },
                      {
                        validator(rule, value, callback) {
                          const shiftDates = dateUtils.sortDatesByEarliest(selectedDates);
                          const [startDate] = shiftDates;
                          const isTodaySelected = dateUtils.isTodaySelected(startDate);
                          if (value && isTodaySelected && moment(fields.start_time).isBefore(moment())) {
                            callback(t('startTimeInvalid'));
                          } else {
                            callback();
                          }
                        },
                      },
                    ]}
                  >
                    <TimePicker
                      minuteStep={15}
                      format={TIME_FORMAT_24_HOUR}
                      style={{ width: '100%' }}
                      onChange={time => handleOnChangeFields('start_time', time)}
                      disabled={selectedDates.length === 0}
                      inputReadOnly
                      popupClassName="v2-timepicker"
                      size={xs ? 'large' : 'middle'}
                      className="v2-input"
                    />
                  </Item>
                </Col>
                <Col xs={12} md={8}>
                  <Item
                    label={t('shiftEndTime')}
                    name="end_time"
                    rules={[
                      { required: true, message: t('fieldIsRequired') },
                      {
                        validator(rule, value, callback) {
                          if (value && hours === 0) {
                            callback(t('endTimeMustBeAtLeast1Hour'));
                          } else {
                            callback();
                          }
                        },
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <TimePicker
                      minuteStep={15}
                      format={TIME_FORMAT_24_HOUR}
                      style={{ width: '100%' }}
                      onChange={time => handleOnChangeFields('end_time', time)}
                      disabled={selectedDates.length === 0}
                      inputReadOnly
                      popupClassName="v2-timepicker"
                      size={xs ? 'large' : 'middle'}
                      className="v2-input"
                    />
                  </Item>
                </Col>
              </Row>
              <Row style={{ width: '100%', marginBottom: 20 }}>
                <Col xs={24} md={16} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Text style={{ color: colors.secondaryText }}>
                    {t('totalHoursPerShift')} {duration} {isCrossShift && `(${t('nextDay')})`}
                  </Text>
                </Col>
              </Row>
              <Typography.Paragraph style={{ marginBottom: 4 }}>{t('totalBreakDurationLabel')}</Typography.Paragraph>
              <Row gutter={16}>
                <Col xs={12} md={8}>
                  <Item
                    name="break_duration_in_minutes"
                    style={{ marginBottom: 0 }}
                    rules={[
                      {
                        validator(rule, value, callback) {
                          const totalShiftDurationInMinutes = hours * 60 + minutes || 0;
                          if (value && value > totalShiftDurationInMinutes) {
                            callback(t('breakDurationExceedShift'));
                          } else {
                            callback();
                          }
                        },
                      },
                    ]}
                  >
                    <Select
                      style={{ width: '100%' }}
                      options={breakDurationChoices}
                      onChange={value => handleBreakDurationChange(value)}
                      size={xs ? 'large' : 'middle'}
                      className="v2-input"
                    />
                  </Item>
                </Col>
                <Col xs={12} style={{ alignSelf: 'center' }}>
                  <Row style={{ alignItems: 'center' }}>
                    <Item name="unpaid_break" style={{ marginBottom: 0, marginRight: 4 }}>
                      <Switch
                        onChange={value => {
                          handleOnChangeFields('unpaid_break', !value);
                        }}
                        checked={!fields.unpaid_break}
                        disabled={!fields.break_duration_in_minutes}
                      />
                    </Item>
                    <Text disabled={!fields.break_duration_in_minutes}>{t('paidBreak')}</Text>
                  </Row>
                </Col>
              </Row>
              {vipWorkerJobPosting && (
                <Row style={{ marginTop: '16px' }}>
                  <Col xs={24} md={16}>
                    <Item name="vip_workers_only" label={t('vipJobLabel')} style={{ marginBottom: '8px' }}>
                      <Radio.Group
                        className="v2-radio-wrapper"
                        style={{ width: '100%' }}
                        onChange={e => handleOnChangeFields('vip_workers_only', e.target.value)}
                      >
                        {[
                          { value: false, label: t('openToAnyone') },
                          { value: true, label: t('vipWorkersOnly') },
                        ].map((option, index) => (
                          <Row key={index} style={{ width: '100%', marginBottom: 10 }}>
                            <Col>
                              <Radio value={option.value}>
                                <Typography.Text style={{ display: 'block', color: colors.primaryText }}>
                                  {t(option.label)}
                                </Typography.Text>
                              </Radio>
                            </Col>
                          </Row>
                        ))}
                      </Radio.Group>
                    </Item>
                    {fields.vip_workers_only && vipWorkerCount === 0 && (
                      <Typography.Text
                        style={{
                          display: 'block',
                          color: colors.functionalError,
                        }}
                      >
                        {t('noVipWorkerError')}
                      </Typography.Text>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
            <Col xs={24} sm={24} md={{ span: 10, offset: 1 }}>
              <EstimatedCost
                visible={staffRequestTemplates.length > 0}
                wageAmount={wageAmount}
                template={fields.template}
                shiftDurationWithBreaks={shiftDurationWithBreaks}
                selectedStaffRequired={fields.staff_required || 0}
                selectedDates={selectedDates}
                pricing={user?.pricing}
                countryCode={user.country.code}
                currencyCode={user.country.currency_code}
                clientType={user.type}
                gigContractType={user.gig_contract_type}
                breakDownCalculation={breakDownCalculation}
              />
            </Col>
          </Row>
        </section>
        {/* REVIEW DETAILS SECTION */}
        <section style={{ display: currentStep === 1 ? 'block' : 'none' }}>
          <Row>
            <Col xs={24} md={16}>
              <ReviewGigDetails
                fields={fields}
                selectedDates={selectedDates}
                currencyCode={user.country.currency_code}
                isFreelanceContract={isFreelanceContract}
              />
            </Col>
            <Col xs={24} sm={24} md={{ span: 7, offset: 1 }}>
              <EstimatedCost
                visible={staffRequestTemplates.length > 0}
                wageAmount={wageAmount}
                template={fields.template}
                shiftDurationWithBreaks={shiftDurationWithBreaks}
                selectedStaffRequired={fields.staff_required || 0}
                selectedDates={selectedDates}
                pricing={user?.pricing}
                countryCode={user.country.code}
                currencyCode={user.country.currency_code}
                clientType={user.type}
                gigContractType={user.gig_contract_type}
                breakDownCalculation={breakDownCalculation}
              />
            </Col>
          </Row>
        </section>
        {/* Payment details section */}
        {isOnlinePayment && (
          <section style={{ display: currentStep === steps.length - 1 ? 'block' : 'none' }}>
            <PaymentDetailSection
              currency={user.country.currency_code.toLowerCase()}
              onPaymentSuccessful={handlePaymentSuccess}
              totalEstimatedCost={breakDownCalculation?.total_estimated_cost || 0}
              onHoldAmount={breakDownCalculation?.on_hold_amount}
              isOnlinePayment={isOnlinePayment}
              history={history}
              backButtonElement={
                <Button
                  style={{ padding: '0 32px', marginRight: '10px' }}
                  type="v2-secondary"
                  onClick={handleBack}
                  size={xs ? 'large' : 'middle'}
                >
                  {t('back')}
                </Button>
              }
              gigData={{
                staff_request_template: fields?.template?.id,
                wage_amount: wageAmount,
                break_duration_in_minutes: fields.break_duration_in_minutes,
                unpaid_break: fields.unpaid_break,
                staff_required: fields.staff_required,
                shift_dates: selectedDates.map(date => moment(date).format(SERVER_DATE)),
                start_time: fields?.start_time?.format(SERVER_DATE_FORMAT_WITH_TIME),
                end_time: fields?.end_time?.format(SERVER_DATE_FORMAT_WITH_TIME),
              }}
              estimatedCostElement={
                <EstimatedCost
                  visible={staffRequestTemplates.length > 0}
                  wageAmount={wageAmount}
                  template={fields.template}
                  shiftDurationWithBreaks={shiftDurationWithBreaks}
                  selectedStaffRequired={fields.staff_required || 0}
                  selectedDates={selectedDates}
                  pricing={user?.pricing}
                  countryCode={user.country.code}
                  currencyCode={user.country.currency_code}
                  clientType={user.type}
                  gigContractType={user.gig_contract_type}
                  breakDownCalculation={breakDownCalculation}
                />
              }
            />
          </section>
        )}

        {(!isOnlinePayment || (isOnlinePayment && currentStep !== steps.length - 1)) && (
          <Row style={{ margin: '40px 0 20px 0' }}>
            {currentStep > 0 && (
              <Button
                style={{ padding: '0 32px', marginRight: '10px' }}
                type="v2-secondary"
                onClick={handleBack}
                size={xs ? 'large' : 'middle'}
              >
                {t('back')}
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button
                disabled={!isFormValid}
                onClick={handleNext}
                style={{ padding: '0 32px', marginRight: '10px' }}
                type="v2-primary"
                size={xs ? 'large' : 'middle'}
              >
                {t('Next')}
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button
                disabled={posting}
                loading={posting}
                onClick={handlePostGig}
                style={{ padding: '0 32px', marginRight: '10px' }}
                type="v2-primary"
                size={xs ? 'large' : 'middle'}
              >
                {t('postGig')}
              </Button>
            )}
          </Row>
        )}
        {isOnWebView && isOnlinePayment && currentStep === 1 && (
          <Paragraph>
            <Trans i18nKey="paymentFAQDescription">
              Workmate will put a hold on the Total Estimated Cost on your credit card. You will only be charged when
              the work is completed. See our payment
              <a
                target="_blank"
                rel="noreferrer"
                href={FAQ_SG_FREELANCE_WORK_LINK}
                style={{ color: colors.functionalLink, fontWeight: '600' }}
              >
                FAQ
              </a>
              for more info.
            </Trans>
          </Paragraph>
        )}
      </Form>
      <OnboardingModal
        title={t('welcomeToWorkmate')}
        description={t('welcomeToWorkmateDescription')}
        visible={showOnboardingModal}
        onClose={() => setShowOnboardingModal(false)}
        onOk={() => setShowLocationModal(true)}
        currentActiveStep={1}
        cancelText={t('doItLater')}
        okText={t('next')}
      />
      {showLocationModal && (
        <LocationFormModal
          simplified
          closable={user.locations.length > 0}
          clientId={user.clientId}
          country={user.country}
          onUpdate={fetchClientAndManager}
          location={null} // Does not have to be explicitly set in "this.state". Handled by Antd.
          visible={showLocationModal}
          onClose={() => setShowLocationModal(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    isOnWebView: state.global.isOnWebView,
    selectedLocationId: state.global.locationId,
    vipWorkerJobPosting: state.user.vipWorkerJobPosting,
  };
};

export default connect(mapStateToProps)(StaffRequestGigFormView);
