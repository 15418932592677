import React from 'react';
import moment from 'moment';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, message, Radio, Modal, Typography, Alert } from 'antd';
import RRule, { rrulestr } from 'rrule';
import { uniqBy } from 'lodash';
import { withTranslation } from 'react-i18next';

import shiftApi from '../../../services/shiftApi';
import scheduleApi from '../../../services/scheduleApi';
import { colors } from '../../../styles/colors';
import { attendanceStatuses, TIME_FORMAT_24_HOUR } from '../../../constants';
import datetimeUtils from '../../../utilities/datetimeUtils';

const { Title, Text } = Typography;

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const shiftDeleteTypes = {
  SINGLE_DELETE: 1,
  BULK_DELETE: 2,
};

class DeleteShiftFormModal extends React.Component {
  state = {
    isSubmitting: false,
    selectedDeleteType: shiftDeleteTypes.BULK_DELETE,
    shiftCount: 0,
    attendanceCount: 0,
    workerCount: 0,
  };

  componentDidMount() {
    this.setThisShiftCounts();
  }

  setThisShiftCounts = () => {
    const { shift } = this.props;

    const atttendancesToDelete = shift.attendances.filter(att => att.status === attendanceStatuses.ASSIGNED);
    const attendanceCount = atttendancesToDelete.length;
    const workerCount = uniqBy(atttendancesToDelete, 'partner_id').length;

    this.setState({ shiftCount: 1, attendanceCount, workerCount });
  };

  onChange = e => {
    const selectedDeleteType = e.target.value;
    const { schedule, date, shifts } = this.props;

    if (selectedDeleteType === shiftDeleteTypes.BULK_DELETE) {
      const thisAndFollowingShifts = shifts.filter(
        shift => shift.schedule.id === schedule.id && moment(shift.start_time).isSameOrAfter(date),
      );
      const shiftCount = thisAndFollowingShifts.length;
      const atttendancesToDelete = thisAndFollowingShifts
        .flatMap(shift => shift.attendances)
        .filter(att => att.status === attendanceStatuses.ASSIGNED);
      const attendanceCount = atttendancesToDelete.length;
      const workerCount = uniqBy(atttendancesToDelete, 'partner_id').length;
      this.setState({
        shiftCount,
        attendanceCount,
        workerCount,
      });
    } else {
      this.setThisShiftCounts();
    }
    this.setState({
      selectedDeleteType,
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { selectedDeleteType } = this.state;
    const { form, shift, schedule, onUpdate, timezone, date } = this.props;

    form.validateFieldsAndScroll(async err => {
      if (!err) {
        this.setState({ isSubmitting: true });
        if (selectedDeleteType === shiftDeleteTypes.BULK_DELETE) {
          try {
            // Get end date
            const rrule = rrulestr(schedule.recurrences);
            const days = rrule.options.byweekday; // an array of integers [0-6] representing Mon - Sun
            const recurrences = new RRule({
              freq: RRule.WEEKLY,
              byweekday: days,
              // deleting this and following shifts is same as modifying schedule end date to be yesterday
              until: moment(date)
                .tz(timezone)
                .startOf('d')
                .toDate(),
            }).toString();
            await scheduleApi.editSchedule({
              id: schedule.id,
              name: schedule.name,
              staff_request: schedule.staff_request,
              staff_required: schedule.staff_required,
              start_date: schedule.start_date,
              duration: schedule.duration,
              recurrences,
            });
            onUpdate();
            message.success('Shift(s) successfully deleted');
          } catch (error) {
            message.warning('Sorry! An error occurred in submission, please contact support');
          } finally {
            this.setState({ isSubmitting: false });
          }
        } else {
          try {
            await shiftApi.deleteShift(shift.id);
            onUpdate();
            message.success('Shift(s) successfully deleted');
          } catch (error) {
            message.warning('Sorry! An error occurred in submission, please contact support');
          } finally {
            this.setState({ isSubmitting: false });
          }
        }
      }
    });
  };

  render() {
    const { schedule, shift, date, visible, onCancel, timezone, t } = this.props;
    const { isSubmitting, selectedDeleteType, shiftCount, attendanceCount, workerCount } = this.state;
    const startTime = moment.tz(shift.start_time, timezone);
    const endTime = datetimeUtils.getEndTimeFromStartTimeAndDuration(startTime, shift.duration);

    return (
      <Modal
        visible={visible}
        title={<Title level={4}>{t('deleteShift')}</Title>}
        onCancel={() => {
          onCancel();
        }}
        okText={t('Delete')}
        cancelText={t('cancel')}
        okButtonProps={{ style: { borderColor: colors.red, backgroundColor: colors.red } }}
        confirmLoading={isSubmitting}
        onOk={e => {
          this.handleSubmit(e);
        }}
      >
        <Row>
          <Text strong>
            {schedule.name} • {moment(startTime).format(TIME_FORMAT_24_HOUR)} -{' '}
            {moment(endTime).format(TIME_FORMAT_24_HOUR)}
          </Text>
        </Row>
        <Row>
          <Text>{moment(date).format('ddd, DD MMM YYYY')}</Text>
        </Row>
        <Radio.Group onChange={this.onChange} value={selectedDeleteType} style={{ margin: '10px 0' }}>
          {/* TODO: disabled for now until we implement recurrence exclusion */}
          <Radio disabled style={radioStyle} value={shiftDeleteTypes.SINGLE_DELETE}>
            {t('thisShift')}
          </Radio>
          <Radio style={radioStyle} value={shiftDeleteTypes.BULK_DELETE}>
            {t('allShifts')}
          </Radio>
        </Radio.Group>
        <Alert
          showIcon
          type="warning"
          message={
            <>
              <Row>
                {selectedDeleteType === shiftDeleteTypes.SINGLE_DELETE
                  ? t('singleShiftDeletionWarningTitle')
                  : t('bulkhiftDeletionWarningTitle')}
              </Row>
              <Row>• {t('shiftDeletionWarningBody1', { shiftCount, attendanceCount })}</Row>
              <Row>• {t('shiftDeletionWarningBody2', { workerCount })}</Row>
            </>
          }
        />
      </Modal>
    );
  }
}
export default Form.create()(withTranslation()(DeleteShiftFormModal));
