// TODO: Refactor out into helpster-components library
/* eslint-disable no-else-return */
import React from 'react';
import { Card, Button, Select, Row } from 'antd';
import { withTranslation } from 'react-i18next';
import { CaretDownFilled } from '@ant-design/icons';
import { colors } from '../../styles/colors';

const { Option } = Select;

class FilterWithSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false,
    };
    this.filterWithSearchNode = React.createRef();
    this.id = `filter-with-search-${Date.now()}`;
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  handleClick = event => {
    // If click happens inside filter component, we don't toggle & return
    if (this.filterWithSearchNode.current.contains(event.target)) {
      return;
    }
    // A click happened outside. "Close" our filter if it is opened.
    if (this.state.showPopover) {
      this.togglePopover();
    }
  };

  togglePopover = () => {
    this.setState(prevState => {
      return { showPopover: !prevState.showPopover };
    });
  };

  render() {
    const {
      title,
      options,
      optionLabelField,
      onSelectChange,
      onSearch,
      loading,
      placeholder = 'Search here',
      selectedOptions,
    } = this.props;
    const { showPopover } = this.state;
    const { t } = this.props;
    const shouldHideCard = showPopover ? '' : 'none';
    return (
      // Append ref to the parent <div> to detect clicks inside this component
      <div ref={this.filterWithSearchNode}>
        <Row>
          <Button id="popup-button" block onClick={() => this.togglePopover()}>
            {t(title)}
            <CaretDownFilled />
          </Button>
          <Card
            style={{ display: shouldHideCard, position: 'absolute', zIndex: 2, width: '250%', marginTop: '34px' }}
            bodyStyle={{ padding: '4px 4px 8px 4px', backgroundColor: colors.white }}
            id={this.id}
            className="v2-select-wrapper"
          >
            <Select
              showSearch
              labelInValue
              mode="multiple"
              optionFilterProp="filter"
              notFoundContent={t('Not Found')}
              open={showPopover}
              onChange={onSelectChange}
              onSearch={onSearch}
              loading={loading}
              placeholder={t(placeholder)}
              style={{ width: '100%' }}
              dropdownStyle={{ padding: '4px', boxShadow: 'none' }}
              getPopupContainer={() => document.getElementById(this.id)}
              defaultValue={selectedOptions}
            >
              {options &&
                options.map(option => (
                  <Option key={option.id} filter={option[optionLabelField]}>
                    {t(option[optionLabelField])}
                  </Option>
                ))}
            </Select>
          </Card>
        </Row>
      </div>
    );
  }
}
export default withTranslation()(FilterWithSearch);
