/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
import moment from 'moment-timezone';
import { attendanceStatuses } from '../constants';
import datetimeUtils from './datetimeUtils';

const getStartTimeFromDateAndSrSchedule = (dateMomentObj, scheduleStartTime, timezone) => {
  // Take example of an ops assigning a worker to a 9am Aug 31 shift in Jakarta
  // We generate shift start_time (a datetime field) in following steps:
  // 1) retrieve date string from the shifts table column header 2020-08-31
  //    * dateMomentObj is shifts table column header, a moment object with browser's local timezone,
  //    * which might sometimes be on a different date from schedule timezone.
  //    * Regardless of browser lcoal time, we treat it as date in Jakarta time
  //    * because op's purpose is to assign worker based on Jakarta time.
  // 2) retrieve time string from SR schedule 09:00:00
  // 3) pass in all date and time strings with SR timezone to get a timezone sensitive moment object
  // 4) parse into ISO format

  // year: 2020, month: 7, day: 31 (Months are zero indexed, so January is month 0.)
  const yearInt = moment(dateMomentObj).year();
  const monthInt = moment(dateMomentObj).month();
  const dayInt = moment(dateMomentObj).date();

  // hour: 9, minute: 0, second: 0
  const [hourStr, minuteStr, secondStr] = scheduleStartTime.split(':');
  const hourInt = parseInt(hourStr, 10);
  const minuteInt = parseInt(minuteStr, 10);
  const secondInt = parseInt(secondStr, 10);

  const shiftStartTime = moment.tz(
    {
      year: yearInt,
      month: monthInt,
      day: dayInt,
      hour: hourInt,
      minute: minuteInt,
      second: secondInt,
    },
    timezone, // +07:00
  );

  // 2020-08-31T02:00:00.000Z
  return shiftStartTime.toISOString();
};

const createDraftShift = (dateMomentObj, staff_request, schedule, attendances) => {
  // startTime is a datetime in which time taken from schedule, date taken from shift table column date
  const startTime = getStartTimeFromDateAndSrSchedule(dateMomentObj, schedule.start_time, staff_request.timezone);
  return {
    staff_request: staff_request.id,
    schedule: schedule.id,
    start_time: startTime,
    duration: schedule.duration,
    client: staff_request.client.id,
    location: staff_request.location.id,
    position: staff_request.position.id,
    attendances,
  };
};

const createDraftAttendance = (partnerId, status) => {
  return {
    status,
    partner_id: partnerId,
    confirmed: false,
    change_reason: null,
  };
};

const getMatchingShiftForTheDay = (shifts, date) => {
  // !IMPORTANT! If the two moments have different timezones, the timezone of the first moment will be used for the comparison.
  // https://momentjscom.readthedocs.io/en/latest/moment/05-query/02-is-same/
  return shifts.find(shift => moment(date).isSame(shift.start_time, 'day'));
};

const filterMatchingShiftForTheDay = (shifts, dateMomentObj) => {
  return shifts.filter(shift => moment(dateMomentObj).isSame(shift.start_time, 'day'));
};

// Confirmed status of an attendance is derived in following priority: unassigned -> change requested -> confirmed
// See table in notion for all scenarios: https://www.notion.so/workmate/f8eabb1383bd4c27824cd8d04d5ac965?v=239b6500cfce4ed08f92093d4237769b
const getDerivedConfirmedStatusFromAttendance = attendance => {
  if (!attendance || attendance.status === attendanceStatuses.UNASSIGNED) {
    return true;
  } else {
    // must be 'Assigned' attendance
    if (attendance.change_reason) {
      // if change requested for attendance
      return false;
    } else if (attendance.confirmed) {
      // if no change requested, and is confirmed
      return true;
    } else {
      // purely 'Assigned'
      return false;
    }
  }
};

const areShiftsOverlapping = (shiftA, shiftB) => {
  const startTimeA = shiftA.startTime;
  const endTimeA = datetimeUtils.getEndTimeFromStartTimeAndDuration(startTimeA, shiftA.duration);
  const startTimeB = shiftB.startTime;
  const endTimeB = datetimeUtils.getEndTimeFromStartTimeAndDuration(startTimeB, shiftB.duration);
  return moment(startTimeA) <= endTimeB && endTimeA >= moment(startTimeB);
};

export default {
  createDraftShift,
  createDraftAttendance,
  getMatchingShiftForTheDay,
  getDerivedConfirmedStatusFromAttendance,
  filterMatchingShiftForTheDay,
  areShiftsOverlapping,
};
