import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Card, List, Tabs, Tag, Empty, Button } from 'antd';
import { withTranslation, Trans } from 'react-i18next';

import workerUtils from '../../../utilities/workerUtils';
import employmentApi from '../../../services/employmentApi';
import { colors } from '../../../styles/colors';
import ApplicationStatusTag from './ApplicationStatusTag';
import applicationApi from '../../../services/applicationApi';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';

const { Text } = Typography;

// eslint-disable-next-line no-unused-vars
const tabNames = ['All Applications', 'In progress'];

const ApplicationsCard = ({ application, handleClickView, positions, t }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [employments, setEmployments] = useState([]);
  const [inProgressApplications, setInProgressApplications] = useState({ applications: [], total_count: -1 });
  const [allApplications, setAllApplications] = useState({ applications: [], total_count: -1 });
  const [thisJobId, setThisJobId] = useState(0);

  const { staff_request } = application;

  const fetchApplications = async () => {
    // eslint-disable-next-line no-shadow
    const applicationHistoryResponse = await applicationApi.applicationHistory(application.id);
    setInProgressApplications(applicationHistoryResponse?.in_progress_applications);
    setAllApplications(applicationHistoryResponse?.all_applications);
    setThisJobId(applicationHistoryResponse?.this_job_id);
  };

  const fetchEmployments = async () => {
    // eslint-disable-next-line no-shadow
    const staffRequestIds = allApplications.applications.map(application => {
      return application.staff_request.id;
    });
    const employmentsResponse = await employmentApi.list({
      staff_request: staffRequestIds.join(','),
      partner: application.partner.id,
    });
    setEmployments(employmentsResponse.results);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  useEffect(() => {
    fetchEmployments();
  }, [inProgressApplications, allApplications, thisJobId]);

  // eslint-disable-next-line no-shadow
  const allApplicationsWithEmploymentStatus = allApplications.applications.map(application => {
    // eslint-disable-next-line no-shadow
    const employment = employments.find(employment => application.staff_request.id === employment?.staff_request?.id);
    if (employment) {
      return { ...application, employmentStatus: employment.status };
    }
    return application;
  });

  return (
    <Card bodyStyle={{ padding: 0 }} bordered={false}>
      <Row type="flex" align="middle">
        <Col span={24}>
          {!isLoading && inProgressApplications.total_count > -1 && allApplications.total_count > -1 ? (
            <Tabs className="default-ant-tabs" defaultActiveKey="1">
              <Tabs.TabPane
                tab={`${t('allApplications')} (${
                  allApplications?.total_count > 10 ? '10+' : allApplications?.total_count
                })`}
                key="1"
              >
                <>
                  <Row style={{ marginBottom: '8px' }}>
                    <Text type="secondary">
                      {t('applicationsCompanyName', { clientName: application.staff_request.client.name })}
                    </Text>
                  </Row>
                  <List
                    itemLayout="horizontal"
                    dataSource={allApplicationsWithEmploymentStatus}
                    renderItem={item => {
                      const isThisJob = item.id === thisJobId;
                      const translatedPositionObject = positions.find(
                        position => position?.id === item?.staff_request?.position?.id,
                      );
                      return (
                        <List.Item key={item.id} style={{ padding: '16px 0' }}>
                          <List.Item.Meta
                            title={
                              <Row type="flex" justify="space-between">
                                <Col span={16}>
                                  <Text strong style={{ color: colors.textTitle }} ellipsis>
                                    {item.staff_request.title}
                                  </Text>
                                </Col>
                                {isThisJob ? (
                                  <Col span={8} style={{ textAlign: 'right' }}>
                                    <Tag color="default">{t('thisJob')}</Tag>
                                  </Col>
                                ) : (
                                  <Col span={8} style={{ textAlign: 'right' }}>
                                    <Button
                                      type="link"
                                      onClick={() => handleClickView(item)}
                                      style={{ height: 'auto', padding: 0, marginRight: 8 }}
                                    >
                                      {t('View')}
                                    </Button>
                                  </Col>
                                )}
                              </Row>
                            }
                            description={
                              <>
                                <Row style={{ marginBottom: 4 }}>
                                  <Text type="secondary">
                                    <Text strong type="secondary">
                                      {translatedPositionObject?.name}
                                    </Text>
                                    {` · `}
                                    <Trans
                                      i18nKey="distanceAwayFromLocation"
                                      values={{
                                        distance: `${workerUtils.getDistanceBetween(
                                          item?.staff_request?.location?.address,
                                          item?.partner?.address,
                                        )} km`,
                                        location: item?.staff_request?.location?.name,
                                      }}
                                    />
                                  </Text>
                                </Row>
                                <Row>
                                  <Text type="secondary">
                                    <ApplicationStatusTag t={t} application={item} />
                                  </Text>
                                </Row>
                              </>
                            }
                          />
                        </List.Item>
                      );
                    }}
                  />
                </>
              </Tabs.TabPane>
              <Tabs.TabPane tab={`${t('inProgressApplications')} (${inProgressApplications?.total_count})`} key="2">
                <>
                  <Row style={{ marginBottom: '8px' }}>
                    <Text type="secondary">
                      {t('applicationsCompanyName', { clientName: staff_request.client.name })}
                    </Text>
                  </Row>
                  {inProgressApplications.total_count > 0 ? (
                    <List
                      itemLayout="horizontal"
                      dataSource={inProgressApplications.applications}
                      renderItem={item => {
                        const isThisJob = item.id === thisJobId;
                        const translatedPositionObject = positions.find(
                          position => position?.id === item?.staff_request?.position?.id,
                        );
                        return (
                          <List.Item key={item.id} style={{ padding: '16px 0' }}>
                            <List.Item.Meta
                              title={
                                <Row type="flex" justify="space-between">
                                  <Col span={16}>
                                    <Text strong style={{ color: colors.textTitle }} ellipsis>
                                      {item.staff_request.title}
                                    </Text>
                                  </Col>
                                  {isThisJob ? (
                                    <Col span={8} style={{ textAlign: 'right' }}>
                                      <Tag color="default">{t('thisJob')}</Tag>
                                    </Col>
                                  ) : (
                                    <Col span={8} style={{ textAlign: 'right' }}>
                                      <Button
                                        type="link"
                                        onClick={() => handleClickView(item)}
                                        style={{ height: 'auto', padding: 0, marginRight: 8 }}
                                      >
                                        {t('View')}
                                      </Button>
                                    </Col>
                                  )}
                                </Row>
                              }
                              description={
                                <>
                                  <Row style={{ marginBottom: 4 }}>
                                    <Text type="secondary">
                                      <Text strong type="secondary">
                                        {translatedPositionObject?.name}
                                      </Text>
                                      {` · `}
                                      <Trans
                                        i18nKey="distanceAwayFromLocation"
                                        values={{
                                          distance: `${workerUtils.getDistanceBetween(
                                            item?.staff_request?.location?.address,
                                            item?.partner?.address,
                                          )} km`,
                                          location: item?.staff_request?.location?.name,
                                        }}
                                      />
                                    </Text>
                                  </Row>
                                  <Row>
                                    <Text type="secondary">
                                      <ApplicationStatusTag t={t} application={item} />
                                    </Text>
                                  </Row>
                                </>
                              }
                            />
                          </List.Item>
                        );
                      }}
                    />
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('noApplicationsInProgress')} />
                  )}
                </>
              </Tabs.TabPane>
            </Tabs>
          ) : (
            <LoadingSpinner width="40px" style={{ textAlign: 'center' }} />
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(ApplicationsCard);
