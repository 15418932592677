import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';
import { uniqueId } from 'lodash';
import { Card, Row, Col, Typography, Button, Avatar, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';

import routes from '../routes';
import Header from './Header';
import EmptyChatList from './EmptyChatList';

import channelApi from '../../../services/channelApi';

import LoadingSpinner from '../../../shared/components/LoadingSpinner';

import { DATETIME_FORMAT_24_HOUR, messageDisplayDuration } from '../../../constants';
import { colors } from '../../../styles/colors';

const { Title, Text } = Typography;

class ChatMessageInfoView extends Component {
  state = {
    isLoading: false,
    selectedMessage: undefined,
    channelMembers: undefined,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { location, t } = this.props;
    const channel = location?.state?.channel;
    const message_index = channel?.selected_message.index;
    try {
      const response = await channelApi.fetchMembers(channel?.id, { message_index });
      this.setState({
        selectedMessage: channel?.selected_message,
        channelMembers: response,
      });
    } catch (error) {
      message.error(t('channelMembersErrorMessage'), [messageDisplayDuration]);
      Sentry.captureException(error);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  displayMembersInList = () => {
    const { channelMembers } = this.state;
    const { t } = this.props;
    const workersInChannel = channelMembers.filter(member => member.partner);
    if (workersInChannel.length > 0) {
      return workersInChannel.map(member => {
        return this.processMembersInList(member);
      });
    }
    return (
      <div style={{ padding: '60px' }}>
        <EmptyChatList message={t('noSeenInfoYet')} />
      </div>
    );
  };

  processMembersInList = member => {
    if (member?.partner) {
      return (
        <div key={uniqueId()}>
          <Row key={uniqueId()} type="flex" justify="start" align="middle" style={{ padding: '6px 0' }}>
            <Col>
              <Avatar shape="circle" size={60} src={member.partner.image} />
            </Col>
            <Col style={{ textAlign: 'left' }}>
              <section
                style={{
                  marginTop: '3px',
                  textAlign: 'left',
                  padding: '12px 14px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <Row>
                  <Text className="name">{`${member.partner.first_name} ${member.partner.last_name}`}</Text>
                </Row>
                <Row>
                  <Text type="secondary" style={{ fontSize: '12px' }}>
                    {moment(member.modified_date).format(DATETIME_FORMAT_24_HOUR)}
                  </Text>
                </Row>
              </section>
            </Col>
          </Row>
        </div>
      );
    }
  };

  render() {
    const { t, history, location } = this.props;
    const { isLoading, selectedMessage } = this.state;
    const channel = location?.state?.channel;

    return (
      <div>
        <Header>
          <Button
            type="link"
            icon={<ArrowLeftOutlined />}
            onClick={() => {
              const route = generatePath(routes.openChatDetail, {
                channelId: channel?.id,
              });
              history.push(route, {
                ...location?.state,
              });
            }}
            style={{ padding: 0, margin: 0, height: 'auto' }}
          >
            <Text style={{ marginLeft: '10px', color: colors.functionalLink }}>{t('back')}</Text>
          </Button>
        </Header>
        {isLoading || !selectedMessage ? (
          <LoadingSpinner width="50px" />
        ) : (
          <Card
            bordered={false}
            style={{
              overflow: 'hidden',
              width: '100%',
            }}
          >
            <Row>
              <Title level={3}>{t('messageInfo')}</Title>
            </Row>
            <div key={uniqueId()}>
              <Row justify="end" style={{ textAlign: 'right', marginBottom: '3px' }}>
                <Text type="secondary">{`${selectedMessage.readInfo}`}</Text>
                <img
                  src={selectedMessage.messageCheckType}
                  alt="double tick"
                  style={{ width: '14px', marginRight: '4px', marginLeft: '4px' }}
                />
                <Text type="secondary">
                  {` · ${moment(selectedMessage.timestamp).format(DATETIME_FORMAT_24_HOUR)}`}
                </Text>
              </Row>
              <Row justify="end" style={{ marginBottom: '8px', paddingBottom: '8px' }}>
                <Row>
                  <Col style={{ textAlign: 'right' }}>
                    {selectedMessage.imageUrl ? (
                      // We have to define "Width" & "Height" upfront. This is so we can accurately to the bottom.
                      <section
                        style={{
                          paddingTop: '8px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        <img
                          alt="chat-img"
                          src={selectedMessage.imageUrl}
                          style={{
                            objectFit: 'cover',
                            maxWidth: '300px',
                            maxHeight: '300px',
                            borderRadius: '16px 0px 16px 16px',
                          }}
                        />
                      </section>
                    ) : (
                      <section
                        style={{
                          textAlign: 'left',
                          padding: '8px 16px',
                          borderRadius: '16px 0px 16px 16px',
                          backgroundColor: colors.functionalSuccess,
                          color: colors.white,
                          maxWidth: '300px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {selectedMessage.body}
                      </section>
                    )}
                  </Col>
                </Row>
              </Row>
            </div>
            <div style={{ paddingTop: '32px' }}>
              <Text strong>{t('seenBy')}</Text>
              {this.displayMembersInList()}
            </div>
          </Card>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clientLogo: state.user.logo,
  user: state.user,
});

export default connect(mapStateToProps)(withTranslation()(ChatMessageInfoView));
