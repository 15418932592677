import React from 'react';
import { Switch } from 'react-router-dom';
import routes from '../../routes';
import RouteWithProps from '../../shared/components/RouteWithProps';
import FormCreateView from './FormCreateView';

const FormsPage = () => {
  return (
    <Switch>
      <RouteWithProps exact path={routes.createForm} component={FormCreateView} />
    </Switch>
  );
};

export default FormsPage;
