import base from './base';
import { endpoints } from '../config';

const reject = async (id, notes) => {
  const response = await base.api.post(`${endpoints.leaveEntries}/${id}/reject/`, { notes });
  return response;
};

const fetchLeaveEntries = async params => {
  const response = await base.api.get(`${endpoints.leaveEntries}/`, { params });
  return response;
};

const fetchVoidReasons = async () => {
  const response = await base.api.get(`${endpoints.leaveEntries}/void-reasons/`);
  return response;
};

const create = async data => {
  const response = await base.api.post(`${endpoints.leaveEntries}/`, data);
  return response;
};

const approve = async id => {
  const response = await base.api.post(`${endpoints.leaveEntries}/${id}/approve/`);
  return response;
};

const resolve = async id => {
  const response = await base.api.post(`${endpoints.leaveEntries}/${id}/resolve/`);
  return response;
};

const voidEntry = async (id, voidedReason, notes) => {
  const response = await base.api.post(`${endpoints.leaveEntries}/${id}/void/`, { voided_reason: voidedReason, notes });
  return response;
};

const rejectEntry = async id => {
  const response = await base.api.post(`${endpoints.leaveEntries}/${id}/reject/`);
  return response;
};

export default {
  reject,
  fetchLeaveEntries,
  //  new
  fetchVoidReasons,
  create,
  approve,
  resolve,
  voidEntry,
  rejectEntry,
};
