import React from 'react';
import { Row, Col, Divider, Tabs, Empty } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import partnerApi from '../../services/partnerApi';
import employmentApi from '../../services/employmentApi';
import workerUtils from '../../utilities/workerUtils';
import { employmentStatuses, partnerSourceTypes } from '../../constants';

import ProfileCard from './components/ProfileCard';
import ProfileHeader from './components/ProfileHeader';
import ExperienceCard from './components/ExperienceCard';
import EducationCard from './components/EducationCard';
import ApplicationsCard from './components/ApplicationsCard';
import StatusTag from '../../shared/components/StatusTag';
import LoadingSpinner from '../../shared/components/LoadingSpinner';

// eslint-disable-next-line no-unused-vars
const tabNames = ['Experience', 'Education', 'All Applications'];

class WorkerHiringProfileView extends React.Component {
  state = {
    worker: undefined,
    workerStatistics: undefined,
    employments: undefined,
    isLoading: false,
    application: undefined,
    showExpandButton: false,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { workerId, application } = this.props;
    const worker = await partnerApi.fetchPartnerById(workerId);
    const employments = (
      await employmentApi.getVerifiedExperience({
        partner: workerId,
        status: employmentStatuses.ENDED,
        ordering: '-end_date',
        page: 1,
      })
    ).results;

    let workerStatistics;
    if (application) {
      workerStatistics = application.partnerStatistics;
      this.setState({ application });
    } else {
      [workerStatistics] = (await partnerApi.getStatistics({ partner: workerId })).results;
    }

    this.setState({ worker, workerStatistics, employments, isLoading: false });
  }

  commaSeparatedValuesToTags = values => {
    const interests = values.split(',');
    const tagInterests = interests.map((interest, index) => <StatusTag key={index} status={interest} />);
    return tagInterests;
  };

  // Checks all experiences & education of worker to see if there are any data
  workerHasExperience = experiencesAndEducation => {
    for (let i = 0; i < experiencesAndEducation.length; i += 1) {
      const exp = experiencesAndEducation[i];
      if (exp.dataList.length > 0) {
        return true;
      }
    }
    return false;
  };

  handleOnEllipsis = () => {
    this.setState({
      showExpandButton: true,
    });
  };

  openApplicationInNewTab = async application => {
    const currentTab = await workerUtils.getApplicationTab(application);
    window.open(`/jobs/${application?.staff_request?.id}/${currentTab}?pid=${application?.partner?.id}`);
  };

  render() {
    const { isLoading, worker, workerStatistics, employments, application, showExpandButton } = this.state;
    const { t, user, linkToApplicationsTab, positions } = this.props;

    if (isLoading || !worker) {
      return <LoadingSpinner />;
    }

    const cleanedWorker = workerUtils.cleanProfile(worker, t);
    const [workmateExperience, otherWorkExperience] = workerUtils.getWorkerExperience(worker, employments, t);
    const workerEducation = workerUtils.getWorkerEducation(worker, t);

    let daysWorked;
    let skillQuizScores;
    if (workerStatistics) {
      const skillQuizStatistics =
        workerStatistics.skill_quiz_statistics && JSON.parse(workerStatistics.skill_quiz_statistics);
      const applicationPosition = application.staff_request.position;
      daysWorked = workerStatistics.total_days_worked;
      skillQuizScores = workerUtils.getSkillQuizScores(skillQuizStatistics, applicationPosition.code);
    }
    const covidCertificationStatus = worker.covid_certification_status;

    const isDirectJobInvite = application.source === partnerSourceTypes.DIRECT_JOB_INVITE;

    return (
      <Row type="flex" gutter={40}>
        <Col span={8}>
          <Row style={{ marginBottom: '48px' }}>
            <ProfileCard worker={cleanedWorker} skillQuizScores={skillQuizScores} />
          </Row>
        </Col>

        <Col span={16} style={{ paddingBottom: '32px' }}>
          <ProfileHeader
            worker={cleanedWorker}
            covidCertificationStatus={covidCertificationStatus}
            daysWorked={daysWorked}
            application={application}
            handleOnEllipsis={this.handleOnEllipsis}
            showExpandButton={showExpandButton}
            user={user}
            isDirectJobInvite={isDirectJobInvite}
          />
          <Divider style={{ margin: '32px 0' }} />

          <Tabs defaultActiveKey={linkToApplicationsTab ? '3' : '1'} type="card">
            <Tabs.TabPane tab={t('experience')} key="1">
              {workmateExperience.dataList.length > 0 || otherWorkExperience.dataList.length > 0 ? (
                <>
                  <ExperienceCard
                    workmateExperience={workmateExperience.dataList}
                    otherWorkExperience={otherWorkExperience.dataList}
                  />
                  <Divider style={{ margin: '32px 0' }} />
                </>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('applicantNotProvidedExperience')} />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('education')} key="2">
              {workerEducation.dataList.length > 0 ? (
                <EducationCard education={workerEducation.dataList} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('applicantNotProvidedEducation')} />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('allApplications')} key="3">
              <ApplicationsCard
                application={application}
                positions={positions}
                handleClickView={this.openApplicationInNewTab}
              />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  positions: state.global.positions,
});

export default connect(mapStateToProps, undefined)(withTranslation()(WorkerHiringProfileView));
