import React from 'react';
import { Card } from 'antd';
import { withTranslation } from 'react-i18next';

import { colors } from '../../../styles/colors';

import ApproveTimesheetsSection from './ApproveTimesheetsSection';
import UnconfirmedTimesheetsSection from './UnconfirmedTimesheetsSection';
import DisputedTimesheetsSection from './DisputedTimesheetsSection';

const AttendanceHeaderSummary = ({
  loading,
  locations,
  timesheetEntries,
  refreshAttendancePageData,
  confirmedTimesheetsCount,
  disputedTimesheetsCount,
  unconfirmedTimesheetsCount,
  selectedWeekStart,
  filters,
  handleApproveAll,
}) => {
  return (
    <div>
      <Card
        style={{
          justifyContent: 'flex-end',
          backgroundColor: colors.white,
          border: `1px solid ${colors.componentBorder}`,
          borderRadius: 4,
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
        }}
        bodyStyle={{ padding: '16px 24px', height: '112px' }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
          <div style={{ display: 'flex', flex: 0.6, borderRight: `1px solid ${colors.componentBorder}` }}>
            <ApproveTimesheetsSection
              timesheetsToApproveCount={confirmedTimesheetsCount}
              handleApproveAll={handleApproveAll}
              loading={loading}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flex: 0.2,
              borderRight: `1px solid ${colors.componentBorder}`,
              paddingLeft: 32,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <UnconfirmedTimesheetsSection
                locations={locations}
                timesheetEntries={timesheetEntries}
                unconfirmedTimesheetsCount={unconfirmedTimesheetsCount}
                refreshAttendancePageData={refreshAttendancePageData}
                selectedWeekStart={selectedWeekStart}
                filters={filters}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flex: 0.2, paddingLeft: 32 }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <DisputedTimesheetsSection
                locations={locations}
                disputedTimesheetsCount={disputedTimesheetsCount}
                timesheetEntries={timesheetEntries}
                refreshAttendancePageData={refreshAttendancePageData}
                selectedWeekStart={selectedWeekStart}
                filters={filters}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default withTranslation()(AttendanceHeaderSummary);
