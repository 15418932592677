import { SR_DEEP_LINK } from '../config';

const getDeepLink = (srId, countryCode, city) => {
  if (!srId) {
    return '';
  }
  return `${SR_DEEP_LINK}?job_id=${srId}&country_code=${countryCode}&city=${city}`;
};

export default getDeepLink;
