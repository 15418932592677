import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import * as applicationFormActions from '../../../redux/applicationForm';

const StyledText = styled('p')`
  color: ${colors.functionalLink};
  margin-bottom: 0;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

const SetKnockout = ({ toggleKnockOut, questionId, choiceId, initialKnockout = false }) => {
  const [knockout, setKnockout] = useState(initialKnockout);

  const { t } = useTranslation();

  const handleToggleKnockout = () => {
    setKnockout(!knockout);
    toggleKnockOut({ questionId, choiceId, knockout: !knockout });
  };

  const tooltipProps = knockout ? { visible: false } : {};

  return (
    <Tooltip {...tooltipProps} title={t('knockOutFailFormMessage')} placement="topLeft">
      {knockout ? (
        <StyledText onClick={handleToggleKnockout}>{t('clearKnockout')}</StyledText>
      ) : (
        <StyledText onClick={handleToggleKnockout}>{t('setAsKnockout')}</StyledText>
      )}
    </Tooltip>
  );
};

const mapDispatchToProps = dispatch => {
  const { toggleKnockOut } = applicationFormActions;
  return {
    toggleKnockOut: payload => {
      dispatch(toggleKnockOut(payload));
    },
  };
};

export default connect(undefined, mapDispatchToProps)(SetKnockout);
