import React from 'react';
import { withTranslation } from 'react-i18next';
import { Table, Typography, Avatar, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import numeral from 'numeral';

import textUtils from '../../../utilities/textUtils';
import routes from '../../../routes';
import { colors } from '../../../styles/colors';

import { checkAccess } from '../../../shared/access/Access';
import { permissions } from '../../../shared/access/accessConfig';

const { Text, Paragraph } = Typography;

const WorkforceTable = ({
  t,
  employments,
  selectedEmployments,
  loading,
  page,
  defaultPageSize,
  currentEmploymentsCount,
  onTableChange,
  onEmploymentsSelectedChange,
  testId,
}) => {
  let tableColumns = [
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('Name')}
        </Text>
      ),
      dataIndex: 'worker',
      sorter: true,
      fixed: 'left',
      key: 'partner__first_name', // for django sorting params
      render: worker => (
        <div style={{ display: 'flex', flexDirection: 'row', width: 244, alignItems: 'center' }}>
          <Avatar shape="square" size={36} src={worker.imgSrc} icon={<UserOutlined />} />

          <Text ellipsis style={{ width: 208 }}>
            <a
              href={`${routes.partners}/${worker.id}`}
              style={{ fontWeight: 600, color: colors.black, paddingLeft: '8px' }}
              target="_blank"
              rel="noopener noreferrer"
              className="underline-on-hover"
            >
              {textUtils.toTitleCase(worker.name)}
            </a>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('status')}
        </Text>
      ),
      width: 130,
      dataIndex: 'status',
      key: 'accepted', // for django sorting params,
      sorter: true,
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('Location')}
        </Text>
      ),
      width: 180,
      dataIndex: 'location',
      key: 'location',
      sorter: true,
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('Position')}
        </Text>
      ),
      width: 180,
      dataIndex: 'position',
      key: 'position',
      sorter: true,
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('role')}
        </Text>
      ),
      width: 160,
      dataIndex: 'role',
      key: 'role',
      sorter: false,
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('paymentFrequencyShort')}
        </Text>
      ),
      width: 160,
      dataIndex: 'paymentFrequency',
      key: 'payment_frequency', // for django sorting params
      sorter: true,
    },
  ];

  if (checkAccess(permissions.workforceViewWageColumns)) {
    tableColumns = [
      ...tableColumns.slice(0, 5),
      // insert wage columns between position and status
      {
        title: (
          <Text strong style={{ color: colors.black }}>
            {t('Wage')}
          </Text>
        ),
        width: 140,
        dataIndex: 'wage',
        key: 'wage',
        sorter: false,
      },
      {
        title: (
          <Text strong style={{ color: colors.black }}>
            {t('wageType')}
          </Text>
        ),
        width: 120,
        dataIndex: 'wageType',
        key: 'wageType',
        sorter: false,
      },
      {
        title: (
          <Text strong style={{ color: colors.black }}>
            {t('overtime')}
          </Text>
        ),
        width: 160,
        dataIndex: 'overtimeWage',
        key: 'overtimeWage',
        sorter: false,
      },
      ...tableColumns.slice(5),
    ];
  }

  let tableRows = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const employment of employments) {
    const { partner } = employment;
    const location = employment.location?.name;
    const position = employment.position?.name;
    const currencyCode = employment.client.country.currency_code;

    const baseWage = employment.base_wage ? parseFloat(employment.base_wage) : 0;
    const transportAllowance = employment.transport_allowance ? parseFloat(employment.transport_allowance) : 0;
    const mealAllowance = employment.meal_allowance ? parseFloat(employment.meal_allowance) : 0;
    const otherAllowance = employment.other_allowance ? parseFloat(employment.other_allowance) : 0;
    const wage = baseWage + transportAllowance + mealAllowance + otherAllowance;
    const role = employment.roles?.reduce((filtered, employmentRole) => {
      if (employmentRole.is_assigned && employmentRole.role.is_active) filtered.push(employmentRole.role.name);
      return filtered;
    }, []);

    const row = {
      key: employment.id,
      worker: {
        id: partner.id,
        name: `${partner.first_name} ${partner.last_name}`,
        imgSrc: partner.image,
      },
      mobile: partner.mobile,
      location,
      position,
      role: (
        <Paragraph ellipsis style={{ marginBottom: 0 }}>
          {role.join(', ')}
        </Paragraph>
      ),
      wage: `${currencyCode} ${numeral(wage).format('0,0.00')}`,
      wageType: t(`${employment.wage_type.replace('_', ' ')}`),
      overtimeWage: employment.overtime_wage ? `${currencyCode} ${employment.overtime_wage} /hr` : '',
      paymentFrequency: t(employment.payment_frequency) || '',
      status: employment.accepted ? <Tag color="green">{t('active')}</Tag> : <Tag color="gold">{t('invited')}</Tag>,
    };

    tableRows = [...tableRows, row];
  }

  return (
    <Table
      indentSize={0}
      loading={loading}
      columns={tableColumns}
      dataSource={tableRows}
      rowKey="key"
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys: selectedEmployments,
        onChange: selectedRowKeys => onEmploymentsSelectedChange(selectedRowKeys),
      }}
      pagination={{
        defaultPageSize,
        current: page,
        showSizeChanger: true,
        pageSizeOptions: ['20', '50', '100'],
        total: currentEmploymentsCount,
        showTotal: (total, range) => `${range[0]}-${range[1]} ${t('of')} ${total} ${t('items')}`,
        style: { margin: '24px 0px' },
      }}
      onChange={onTableChange}
      data-testid={testId}
      scroll={{ x: 'max-content' }}
      size="middle"
    />
  );
};

export default withTranslation()(WorkforceTable);
