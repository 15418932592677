import React from 'react';

import { choiceTypes } from '../../../constants';
import SingleSelect from './SingleSelect';
import MultiSelect from './MultiSelect';
import FreeText from './FreeText';

const Choices = ({ choices, type, isClicked, questionId }) => {
  switch (type) {
    case choiceTypes.SINGLE_SELECT:
      return <SingleSelect choices={choices} isClicked={isClicked} questionId={questionId} />;
    case choiceTypes.MULTI_SELECT:
      return <MultiSelect choices={choices} isClicked={isClicked} questionId={questionId} />;
    case choiceTypes.FREE_TEXT:
      return <FreeText choices={choices} />;
    default:
      return null;
  }
};

export default Choices;
