/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, Dropdown, Menu, Avatar, Col, Row, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { localStorageKeys, sessionStorageKeys, settingsTabs } from '../../constants';
import routes from '../../routes';
import { colors } from '../../styles/colors';

const { Text } = Typography;

class TopNavDropdown extends React.Component {
  goToRoute = route => {
    this.props.history.push(route);
  };

  render() {
    const { clientName, t, logoutUser, managers, initializeUser, userLogo } = this.props;
    const selectedManagerId = localStorage.getItem(localStorageKeys.MANAGER_ID);
    const managerMenuItems = managers.map(manager => {
      const selectedStyle =
        Number(selectedManagerId) === manager.id
          ? { opacity: 1, color: colors.functionalSuccess }
          : { opacity: 0.65, color: colors.white };
      return {
        name: manager.client?.name,
        id: manager.id,
        onClick: () => {
          sessionStorage.setItem(sessionStorageKeys.MANAGER_ID, manager.id);
          localStorage.setItem(localStorageKeys.MANAGER_ID, manager.id);
          localStorage.removeItem(localStorageKeys.SHIFT_FILTERS);
          initializeUser();
          this.goToRoute(routes.staffRequests);
        },
        style: selectedStyle,
      };
    });

    const menuList = [
      {
        name: t('Settings'),
        onClick: () => this.goToRoute(routes.settings.replace(':tab', settingsTabs.COMPANY)),
        style: { borderTop: '1px solid #595959' },
      },
      { name: t('Logout'), onClick: logoutUser },
    ];
    if (managerMenuItems.length > 1) {
      // add to top of list the clients you manage
      menuList.unshift(...managerMenuItems);
    }
    const menu = (
      <Menu className="v2-user-dropdown-menu">
        {menuList.map(({ onClick, name, style }, index) => (
          <Menu.Item key={`${index}`} onClick={onClick} style={style}>
            {name}
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <Button type="link" style={{ color: 'white', padding: 0 }}>
          <Row type="flex" style={{ alignItems: 'center' }}>
            {/* Mobile navbar */}
            <Col xs={24} md={0}>
              <Row align="middle" style={{ width: '100%' }}>
                <Text style={{ color: colors.white, textOverflow: 'ellipsis', maxWidth: '100px' }} ellipsis>
                  {clientName}
                </Text>
                <DownOutlined />
              </Row>
            </Col>
            {/* Website navbar */}
            <Col xs={0} sm={0} md={24}>
              <Row align="middle" style={{ width: '100%' }}>
                <Text style={{ color: colors.white, textOverflow: 'ellipsis', maxWidth: '400px' }} ellipsis>
                  {clientName}
                </Text>
                <Avatar size="large" src={userLogo} style={{ marginLeft: '16px' }} />
              </Row>
            </Col>
          </Row>
        </Button>
      </Dropdown>
    );
  }
}
export default TopNavDropdown;
