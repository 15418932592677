/* eslint-disable import/prefer-default-export */
export const colors = {
  white: '#FFFFFF',
  backgroundGrey: '#F0F0F0',
  grey: '#CBCBCB',
  lightBlue: '#108EE9',
  green: '#69E4A6',
  darkGreen: '#52C41A',
  yellow: '#FFC400',
  red: '#F5222D',
  black: '#222222',
  absoluteBlack: '#000000',
  darkOrange: '#FF8D04',
  limeGreen: '#32CD32',
  workmateGreen: '#69E4A6',
  brandGreen: '#69E4A6',
  redAccent: '#FF5251',
  borderGrey: '#5ECC95',

  componentBorder: '#D9D9D9',
  componentBackground: '#F5F5F5',
  functionalApproved: '#6665DD',
  functionalLink: '#1890FF',
  functionalError: '#FF4D4F',
  functionalWarning: '#FAAD14',
  tagPurple: '#F6F6FF',
  tagGreen: '#E6FFF1',
  tagBlue: '#E6F7FF',
  tagRed: '#FFF1F0',
  tagYellow: '#FFFBE6',
  dataVizGreen: '#34D1BF',
  dataVizOrange: '#FF715B',
  dataVizYellow: '#FDC93F',
  lightGrey: '#F5F5F5',
  darkGrey: '#999999',
  blue: '#3B86FF',
  orange: '#FF8474',
  functionalSuccess: '#00CA7F',
  disabled: '#BFBFBF',
  overlay: '#F7F7F7',
  infoBackgroundBlue: '#E6F7FF',
  infoHighlightBlue: '#1890FF',

  textTitle: '#262626',
  primaryText: '#595959',
  secondaryText: '#8C8C8C',
  plaintText: 'rgba(0, 0, 0, 0.85)',
  greyBorder: '#E8E8E8',
  whiteBackground: '#FBFBFB',
  neutralBackground: '#F6F7F7',
  textLightBlack: '#757575',

  neutralDark: '#212121',
  neutralMedium: '#424242',
};
