import React from 'react';
import moment from 'moment';
import {
  UserOutlined,
  ProfileOutlined,
  SafetyCertificateOutlined,
  CloseCircleTwoTone,
  FileSearchOutlined,
  BellFilled,
  EyeFilled,
  FileTextFilled,
  FileTextOutlined,
} from '@ant-design/icons';
import { Row, Col, Avatar, Card, Checkbox, Typography, Tooltip, Button } from 'antd';
import { withTranslation, Trans } from 'react-i18next';

import WorkerTag from '../../../shared/components/WorkerTag';
import SparkTag from '../../../shared/components/SparkTag';
import workerUtils from '../../../utilities/workerUtils';
import {
  workerCovidCertificationStatuses,
  workerTags,
  partnerSourceTypes,
  applicationStatuses,
  resendOfferInAppNotificationTranslations,
  DEFAULT_DATETIME_FORMAT,
} from '../../../constants';
import { colors } from '../../../styles/colors';
import textUtils from '../../../utilities/textUtils';

import ApplicationActions from './ApplicationActions';
import ApplicationStatus from './ApplicationStatus';
import ProgressBar from '../../../shared/components/ProgressBar';
import ConfirmModals from '../../../shared/components/ConfirmModals';
import staffRequestUtils from '../../../utilities/staffRequestUtils';

const { Paragraph, Text, Title } = Typography;
const hideLastActiveOnApplicationStatuses = [
  applicationStatuses.PENDING_CONTRACT,
  applicationStatuses.REJECTED,
  applicationStatuses.WITHDRAWN,
];
const inactiveStatuses = [applicationStatuses.REJECTED, applicationStatuses.WITHDRAWN];

const ApplicantListItem = ({
  application,
  showCheckbox,
  checked,
  onCheck,
  onHire,
  onReject,
  onOpenApplicantModal,
  onChat,
  index,
  t,
  alreadyHired = false,
  user,
  positionId,
  onRemindWorker,
  onHandleOfferExpiry,
  onResendOffer,
}) => {
  const worker = application.partner;
  const workerStatistics = application.partnerStatistics;
  const { workmateExperiences, inProgressApplicationsCount, allApplicationsCount } = application;
  const daysWorked = (workerStatistics && workerStatistics?.total_days_worked) || 0;
  const rating = workerStatistics && workerStatistics.avg_rating_from_client;
  const age = worker.date_of_birth && `${moment().diff(worker.date_of_birth, 'years')}`;
  const workerName = workerUtils.getWorkerName(worker);
  const isVaccinated = worker.covid_certification_status === workerCovidCertificationStatuses.VERIFIED;

  const staffRequest = application.staff_request;
  const staffRequestLocation = staffRequest.location.name;
  const distanceBetween = workerUtils.getDistanceBetween(staffRequest.location.address, worker.address);
  // Partners through applications are already active partners

  const skillQuizPosition = staffRequest.position;
  const skillQuizStatistics =
    workerStatistics && workerStatistics.skill_quiz_statistics && JSON.parse(workerStatistics.skill_quiz_statistics);
  let skillQuizScore;
  if (skillQuizStatistics) {
    skillQuizScore = workerUtils.getApplicationPositionSkillQuizScore(skillQuizPosition.code, skillQuizStatistics);
  }

  const [workmateExperience = [], otherWorkExperience = []] = workerUtils.getWorkerExperience(
    worker,
    workmateExperiences,
    t,
    staffRequest.position,
  );
  const workerExperience = [...(workmateExperience.dataList || []), ...(otherWorkExperience.dataList || [])].slice(
    0,
    3,
  );
  const otherExperienceCount = workmateExperience.otherExperienceCount + otherWorkExperience.otherExperienceCount;
  const clientExperience = workerUtils.getWorkerClientHistory(user.clientId, workmateExperiences);
  const positionDaysWorked = workerUtils.getPositionDaysWorked(workmateExperiences, positionId);

  const isDirectJobInvite = application.source === partnerSourceTypes.DIRECT_JOB_INVITE;
  const isAutohired = application.auto_hired;
  const isRecommended = application.recommended;

  const workerTagInfo = [
    { type: workerTags.NEW_JOINER, isActive: !clientExperience && workerUtils.isNewWorker(daysWorked) },
    {
      type: workerTags.RECOMMENDED,
      isActive: workerUtils.isRecommendedWorker(application.reliability_score, isRecommended),
    },
    { type: workerTags.PAST_WORKER, isActive: clientExperience },
    {
      type: workerTags.EXPERIENCED,
      isActive: application?.partnerStatistics?.total_days_worked >= 240 || false,
    },
    { type: workerTags.JOB_INVITATION, isActive: isDirectJobInvite },
    { type: workerTags.SKILL_SCORE, isActive: skillQuizScore },
  ];

  const workerExperienceInfo = [
    { type: workerTags.RATING, isActive: rating },
    { type: workerTags.DAYS_WORKED, isActive: daysWorked },
    { type: workerTags.COVID_VACCINATED, isActive: isVaccinated },
  ];

  const showInProgressAppsCount = inProgressApplicationsCount > 1;

  const showPreviousAppsIcon =
    allApplicationsCount - inProgressApplicationsCount > 0 && inProgressApplicationsCount === 1;

  const resendOfferInAppNotification = resendOfferInAppNotificationTranslations[
    application?.staff_request?.client?.country?.code
  ](application?.staff_request?.client?.name, application?.partner?.first_name);

  return (
    <Card
      bordered={false}
      style={{
        backgroundColor: 'inherit',
        borderBottom: '1px solid #00000017',
      }}
      bodyStyle={{ padding: 0 }}
      className="hover-shadow"
    >
      <Row
        type="flex"
        style={{ paddingTop: 32, paddingBottom: 24 }}
        onClick={() => {
          onOpenApplicantModal(index);
        }}
      >
        <Col span={3}>
          <Row>
            {showCheckbox && (
              <Col style={{ marginRight: '10px' }}>
                <Checkbox
                  disabled={alreadyHired}
                  checked={checked}
                  onChange={onCheck}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  value={application}
                />
              </Col>
            )}
            <Col>
              <Avatar
                icon={<UserOutlined />}
                shape="square"
                size={120}
                src={worker.image}
                className="v2-avatar-wrapper"
                style={alreadyHired && { filter: 'grayscale(100%)' }}
              />
              {isAutohired && <SparkTag />}
            </Col>
          </Row>
        </Col>
        <Col span={13} style={{ paddingLeft: 12 }}>
          <Row type="flex" align="middle" style={{ marginBottom: 4 }}>
            <Paragraph ellipsis={{ row: 1 }} style={{ marginBottom: 0 }}>
              <Title strong level={4} style={{ marginBottom: 0, marginRight: 12, display: 'inline-block' }}>
                <span style={{ color: alreadyHired ? colors.disabled : 'inherit' }}>{workerName}</span>
              </Title>
            </Paragraph>
            {workerExperienceInfo
              .filter(({ isActive }) => isActive)
              .map(({ type }) => (
                <span key={type} style={{ marginRight: 12 }}>
                  <WorkerTag
                    type={type}
                    daysWorked={daysWorked}
                    positionDaysWorked={positionDaysWorked}
                    rating={rating}
                    companyName={staffRequest.client?.name}
                  />
                </span>
              ))}
          </Row>

          {/* Age & location */}
          <Row style={{ paddingBottom: 8 }}>
            {workerTagInfo
              .filter(({ isActive }) => isActive)
              .map(({ type }) => (
                <span key={type} style={{ marginRight: 12 }}>
                  <WorkerTag
                    type={type}
                    daysWorked={daysWorked}
                    skillScore={skillQuizScore}
                    positionName={skillQuizPosition.name}
                    companyName={staffRequest.client?.name}
                  />
                </span>
              ))}
          </Row>
          <Row>
            <Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: '4px' }}>
              <Text type="secondary">
                {worker.gender ? `${textUtils.capitalizeWord(t(worker.gender))} · ` : ''}
                {age ? `${age}yo · ` : ''} {worker.mobile}
              </Text>
            </Paragraph>
          </Row>

          {distanceBetween && (
            <Row style={{ marginBottom: 24 }}>
              <Text strong style={{ color: alreadyHired && colors.disabled }}>
                <Trans
                  i18nKey="distanceAwayFromLocation"
                  values={{ distance: `${distanceBetween} km`, location: staffRequestLocation }}
                />
              </Text>
            </Row>
          )}

          <Row>
            <Col>
              <Text
                strong
                style={{
                  fontSize: '12px',
                  lineHeight: '20px',
                  color: alreadyHired ? colors.disabled : colors.primaryText,
                  marginBottom: 8,
                  display: 'block',
                }}
              >
                {t('experience').toUpperCase()}
              </Text>
              {workerExperience.length > 0 ? (
                workerExperience.map(experience => (
                  <Paragraph
                    key={experience.id}
                    ellipsis={{ rows: 1 }}
                    style={{ marginBottom: '8px', color: alreadyHired && colors.disabled }}
                  >
                    {experience.workmateExperience ? (
                      <Tooltip title={t('verifiedExperienceTooltip')}>
                        <SafetyCertificateOutlined
                          style={{
                            color: alreadyHired ? colors.disabled : colors.secondaryText,
                            fontSize: '12px',
                            marginRight: 8,
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={t('providedExperienceTooltip')}>
                        <ProfileOutlined
                          style={{
                            color: alreadyHired ? colors.disabled : colors.secondaryText,
                            fontSize: '12px',
                            marginRight: 8,
                          }}
                        />
                      </Tooltip>
                    )}
                    <Text strong style={{ color: alreadyHired ? colors.disabled : colors.primaryText }}>
                      {`${experience.title}`}
                    </Text>
                    <Text type="secondary" style={{ color: alreadyHired && colors.disabled }}>
                      {` · ${experience.period} · ${experience.subTitle}`}
                    </Text>
                  </Paragraph>
                ))
              ) : (
                <Paragraph
                  ellipsis={{ rows: 1 }}
                  style={{ marginBottom: '8px', color: alreadyHired && colors.disabled }}
                >
                  <Tooltip title={t('providedExperienceTooltip')}>
                    <ProfileOutlined
                      style={{
                        color: alreadyHired ? colors.disabled : colors.secondaryText,
                        fontSize: '12px',
                        marginRight: 8,
                      }}
                    />
                  </Tooltip>
                  <Text type="secondary" style={{ color: alreadyHired && colors.disabled }}>
                    {t('noExpInThisPosition')}
                    {otherExperienceCount > 0 && (
                      <>
                        {` · `}
                        <Button className="underline-on-hover" type="link" style={{ padding: 0 }}>
                          {t('seeOtherExperience', { count: otherExperienceCount })}
                        </Button>
                      </>
                    )}
                  </Text>
                </Paragraph>
              )}
            </Col>
          </Row>
        </Col>
        <Col
          span={7}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            textAlign: 'right',
            marginLeft: 'auto',
          }}
        >
          <Row style={{ marginBottom: '16px' }}>
            <ApplicationActions
              t={t}
              applicationIndex={index}
              application={application}
              worker={worker}
              onHire={onHire}
              onReject={onReject}
              onChat={onChat}
              alreadyHired={alreadyHired}
              onRemindWorker={onRemindWorker}
              onChangeOfferExpiry={onHandleOfferExpiry}
            />
          </Row>
          {showInProgressAppsCount && (
            <Button
              type="link"
              icon={<FileSearchOutlined />}
              onClick={e => {
                e.stopPropagation();
                onOpenApplicantModal(index, true);
              }}
              style={{ padding: 0, margin: 0 }}
            >
              <Text style={{ marginLeft: '4px', color: colors.functionalLink }}>
                {`${inProgressApplicationsCount} ${t('inProgressApplicationsCount')}`}
              </Text>
            </Button>
          )}
          {showPreviousAppsIcon && (
            <Button
              type="link"
              icon={<FileSearchOutlined />}
              onClick={e => {
                e.stopPropagation();
                onOpenApplicantModal(index, true);
              }}
              style={{ padding: 0, margin: 0 }}
            />
          )}
          {application.status === applicationStatuses.PENDING_CONTRACT && application.approval_date && (
            <>
              <Row style={{ marginBottom: '12px', display: 'flex', alignItems: 'center' }}>
                <FileTextOutlined />
                <Text style={{ marginLeft: '4px', color: colors.primaryText }}>
                  {`${t('offerExpire')}: ${moment(application.approval_date)
                    .add(application.expire_minutes, 'minutes')
                    .format(DEFAULT_DATETIME_FORMAT)}`}
                </Text>
              </Row>
              <Row style={{ marginBottom: '12px' }}>
                <ProgressBar
                  steps={[
                    {
                      icon: <FileTextFilled style={{ fontSize: '20px', color: colors.functionalSuccess }} />,
                      bridgeColor:
                        application.contract_view_time || application.last_remind_time
                          ? colors.functionalSuccess
                          : colors.componentBorder,
                      tooltipTitle: application.approval_date
                        ? `${staffRequest.client.contract_required ? t('contractSent') : t('jobOfferSent')} ${moment(
                            application.approval_date,
                          ).toNow(true)} ${t('ago')}`
                        : null,
                    },
                    {
                      icon: (
                        <EyeFilled
                          style={{
                            fontSize: '20px',
                            color: application.contract_view_time ? colors.functionalSuccess : colors.componentBorder,
                          }}
                        />
                      ),
                      bridgeColor: application.last_remind_time ? colors.functionalSuccess : colors.componentBorder,
                      tooltipTitle: application.contract_view_time
                        ? `${staffRequest.client.contract_required ? t('contractSeen') : t('jobOfferSeen')} ${moment(
                            application.contract_view_time,
                          ).toNow(true)} ${t('ago')}`
                        : null,
                    },
                    {
                      icon: (
                        <BellFilled
                          style={{
                            fontSize: '20px',
                            color: application.last_remind_time ? colors.functionalSuccess : colors.componentBorder,
                          }}
                        />
                      ),
                      bridgeColor: null,
                      tooltipTitle: application.last_remind_time
                        ? `${t('applicationReminderSent')} ${moment(application.last_remind_time).toNow(true)} ${t(
                            'ago',
                          )}`
                        : null,
                    },
                  ]}
                />
              </Row>
            </>
          )}
          <Row style={{ marginBottom: 4, justifyContent: 'flex-end' }}>
            <Text type="secondary">
              <ApplicationStatus
                t={t}
                client={staffRequest.client}
                time={moment(application.modified_date).toNow(true)}
                application={application}
              />
            </Text>
          </Row>
          {inactiveStatuses.includes(application.status) && (
            <Button
              onClick={e => {
                e.stopPropagation();
                ConfirmModals.hireWorker(
                  workerName,
                  null,
                  resendOfferInAppNotification,
                  t,
                  onHandleOfferExpiry,
                  true,
                  // eslint-disable-next-line no-undef
                  sessionStorage.getItem(staffRequestUtils.generateOfferExpirySessionStorageKey(staffRequest.id)),
                  () => {
                    onResendOffer(application.id, workerName);
                  },
                );
              }}
            >
              {t('resendOffer')}
            </Button>
          )}
          {!hideLastActiveOnApplicationStatuses.includes(application.status) && (
            <Row style={{ height: 'auto' }}>
              <Text type="secondary">
                {t('lastActiveTimeAgo', { time: moment(worker.last_opened_app).toNow(true) })}
              </Text>
            </Row>
          )}
          {alreadyHired && (
            <Row justify="middle" style={{ height: 'auto', justifyContent: 'flex-end' }}>
              <CloseCircleTwoTone twoToneColor={colors.functionalError} style={{ fontSize: '12px', marginRight: 8 }} />{' '}
              <Text style={{ color: colors.functionalError }}>{t('applicantUnavailable')}</Text>
            </Row>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(ApplicantListItem);
