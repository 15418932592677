import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../constants';
import countriesApi from '../../services/countriesApi';

const getCountryInfo = async countryId => {
  const response = await countriesApi.fetchCountryInfo(countryId);
  return response[0];
};

// eslint-disable-next-line import/prefer-default-export
export const useCountryInfo = countryId => {
  return useQuery(queryKeys.COUNTRY_INFO, () => getCountryInfo(countryId), {
    staleTime: 24 * 60 * 60 * 1000, //24h
  });
};
