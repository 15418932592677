import React from 'react';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { isEqual, camelCase } from 'lodash';
import moment from 'moment';

import { colors } from '../../../styles/colors';
import datetimeUtils from '../../../utilities/datetimeUtils';
import attendanceUtils from '../../../utilities/attendanceUtils';
import { derivedWorkingStatus, attendanceAbsenceReasons } from '../../../constants';

export const getPastSidebarColor = assignment => {
  const { attendance } = assignment;

  if (
    attendanceUtils.isAssigned(attendance) &&
    !attendanceUtils.hasNonVoidTimesheetEntry(attendance?.timesheet_entries) &&
    !attendanceUtils.isAbsent(attendance)
  ) {
    return colors.red;
  }
  return colors.grey;
};

export const getPastBackgroundColor = assignment => {
  const { attendance } = assignment;

  if (attendanceUtils.isAssigned(attendance) && !attendanceUtils.isAbsent(attendance)) {
    if (!attendanceUtils.hasNonVoidTimesheetEntry(attendance?.timesheet_entries)) {
      return colors.tagRed;
    }
    return colors.white;
  }
  return colors.backgroundGrey;
};

export const getPastIcon = assignment => {
  const { attendance } = assignment;

  if (attendanceUtils.hasNonVoidTimesheetEntry(attendance?.timesheet_entries)) {
    return <CheckCircleOutlined style={{ color: colors.grey }} />;
  }
};

export const getPastLabelColor = assignment => {
  const { attendance } = assignment;

  if (
    attendanceUtils.isAssigned(attendance) &&
    !attendanceUtils.hasNonVoidTimesheetEntry(attendance?.timesheet_entries) &&
    !attendanceUtils.isAbsent(attendance)
  ) {
    return 'inherit';
  }
  return colors.grey;
};

export const getFutureSidebarColor = (assignment, updated) => {
  const { attendance, contractStatus } = assignment;

  if (!attendanceUtils.isValidWorkingDay(contractStatus)) {
    return colors.grey;
  }

  if (!updated) {
    if (attendanceUtils.isAbsent(attendance)) {
      // Published and has change request
      return colors.functionalWarning;
    }
    if (attendanceUtils.isAssigned(attendance)) {
      // Published and assigned
      return colors.workmateGreen;
    }
    // Published and unassigned
    return colors.grey;
  }
  // Unpublished
  return colors.infoHighlightBlue;
};

export const getFutureBackgroundColor = (assignment, updated) => {
  const { attendance, contractStatus } = assignment;

  if (!attendanceUtils.isValidWorkingDay(contractStatus)) {
    return colors.backgroundGrey;
  }

  if (!updated) {
    if (attendanceUtils.isAbsent(attendance)) {
      // Published and has change request
      return colors.tagYellow;
    }
    if (!attendanceUtils.isAssigned(attendance)) {
      // Published and unassigned
      return colors.backgroundGrey;
    }
  } else if (attendanceUtils.isAssigned(attendance)) {
    // Unpublished and assigned
    return colors.infoBackgroundBlue;
  }
  return colors.white;
};

export const getFutureIcon = (attendance, updated) => {
  if (!updated && attendanceUtils.isAssigned(attendance)) {
    if (attendanceUtils.isAbsent(attendance)) {
      // Published, assigned and has change request
      return <ExclamationCircleOutlined style={{ color: colors.functionalWarning }} />;
    }
    if (attendance.confirmed) {
      // Published, assigned and confirmed
      return <CheckCircleOutlined style={{ color: colors.workmateGreen }} />;
    }
  }
};

export const getFutureLabelColor = assignment => {
  const { attendance, contractStatus } = assignment;

  if (!attendanceUtils.isAssigned(attendance) || !attendanceUtils.isValidWorkingDay(contractStatus)) return colors.grey;
  return 'inherit';
};

export const getCellAttributes = (assignment, timezone) => {
  const { attendance, originalAttendance, date } = assignment;
  const updated = !isEqual(attendance, originalAttendance);
  const dayStart = datetimeUtils.getDayStart(moment(), timezone);
  const isFutureAssignment = moment(date).isSameOrAfter(dayStart);

  if (isFutureAssignment) {
    return {
      sideBarColor: getFutureSidebarColor(assignment, updated),
      backgroundColor: getFutureBackgroundColor(assignment, updated),
      labelColor: getFutureLabelColor(assignment),
      icon: getFutureIcon(attendance, updated),
    };
  }
  return {
    sideBarColor: getPastSidebarColor(assignment),
    backgroundColor: getPastBackgroundColor(assignment),
    labelColor: getPastLabelColor(assignment),
    icon: getPastIcon(assignment),
  };
};

// TODO: Add as an attribute to get from getCellAttributes and write test with t function mock
export const getLabelText = (attendance, assignment, timezone, t) => {
  const { schedule, contractStatus, date } = assignment;
  const dayStart = datetimeUtils.getDayStart(moment(), timezone);
  const isFutureAssignment = moment(date).isSameOrAfter(dayStart);

  // Labels for future assignments that are not on work day
  if (isFutureAssignment && contractStatus === derivedWorkingStatus.CONTRACT_NOT_SIGNED) {
    return t('noContract');
  }
  if (isFutureAssignment && contractStatus === derivedWorkingStatus.ON_BREAK) {
    return t('PKHL Break');
  }

  if (attendanceUtils.isAbsent(attendance)) {
    return t('leave');
  }
  // If assigned but not worked for that day
  if (
    !isFutureAssignment &&
    attendanceUtils.isAssigned(attendance) &&
    !attendanceUtils.hasNonVoidTimesheetEntry(attendance?.timesheet_entries)
  ) {
    return t('noShow');
  }

  // Otherwise, either show assigned schedule name or off day label
  if (schedule) {
    return schedule.name;
  }
  return t('off');
};

// TODO: Add as an attribute to get from getCellAttributes and write test with t function mock
export const getToolTipTitle = (assignment, timezone, t) => {
  const { attendance, contractStatus, date } = assignment;
  const dayStart = datetimeUtils.getDayStart(moment(), timezone);
  const isFutureAssignment = moment(date).isSameOrAfter(dayStart);

  if (isFutureAssignment) {
    if (attendanceUtils.isAbsent(attendance)) {
      if (
        Object.values(attendanceAbsenceReasons).includes(
          attendance.absence_reason || attendance?.absence_detail?.manager_reason,
        )
      ) {
        return t(camelCase(attendance.absence_reason || attendance?.absence_detail?.manager_reason)); // return translated version if key exists
      }
      // for backward compatibility
      return attendance.absence_reason || attendance?.absence_detail?.manager_reason; // else return legacy free text version
    }
    if (contractStatus === derivedWorkingStatus.CONTRACT_NOT_SIGNED) {
      return t('workerNotSignedContract');
    }
    if (contractStatus === derivedWorkingStatus.ON_BREAK) {
      return t('cannotAssignOnPkhl');
    }
  } else if (attendanceUtils.isAbsent(attendance)) {
    if (Object.values(attendanceAbsenceReasons).includes(attendance.absence_reason || attendance.absence_detail)) {
      return t(camelCase(attendance.absence_reason || attendance?.absence_detail?.manager_reason)); // return translated version if key exists
    }
    // for backward compatibility
    return attendance.absence_reason || attendance?.absence_detail?.manager_reason; // else return legacy free text version
  }
};
