import React from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { debounce, sortBy } from 'lodash';
import { EllipsisOutlined } from '@ant-design/icons';
import { Typography, Input, Button, Row, Col, Radio, Menu, Dropdown, Divider } from 'antd';
import { withTranslation } from 'react-i18next';
import { DIRECT_HIRE_INVITE_BRANCH_LINK } from '../../config';
import { checkAccess } from '../../shared/access/Access';
import { permissions } from '../../shared/access/accessConfig';
import { countryCodeOptions, employmentStatuses } from '../../constants';
import staffRequestApi from '../../services/staffRequestApi';
import employmentApi from '../../services/employmentApi';
import clientsApi from '../../services/clientApi';
import {
  getPositionAndRoleOptions,
  formatPositionAndRoleTag,
  convertPositionAndRoleToList,
  convertPositionAndRoleToOptions,
} from '../../utilities/positionAndRoleUtils';
import { prepParams, retrieveParamsFromUrl, updateUrlWithParams } from '../../utilities/urlUtils';
import { colors } from '../../styles/colors';
import TreeCheckboxFilter from '../../shared/components/TreeCheckboxFilter';

import WorkforceTable from './components/WorkforceTable';
import TotalWorkforceCard from './components/TotalWorkforceCard';
import WorkforceStatsCard from './components/WorkforceStatsCard';
import LocationPositionRoleUpdateFormModal from './components/LocationPositionRoleUpdateFormModal';
import WagesUpdateModal from './components/WagesUpdateModal';
import InviteNewWorkersModal from './components/InviteNewWorkersModal';
import EndEmploymentModal from './components/EndEmploymentModal';
import EndingEmploymentsModal from './components/EndingEmploymentsModal';
import InviteLinkModal from './components/InviteLinkModal';
import ContractsTable from './components/ContractsTable';

const { Title, Text } = Typography;
const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_ORDERING = 'partner__first_name';
const DEFAULT_VIEW_TYPE_KEY = 'default';
const CONTRACT_VIEW_TYPE_KEY = 'contract';

class WorkforcePage extends React.Component {
  constructor(props) {
    super(props);
    this.searchFilter = React.createRef();
  }

  state = {
    loading: false,
    hasFetchedEmploymentStats: false,
    employments: [],
    positionAndRoleOptions: [],
    selectedStatuses: [],
    selectedLocations: [],
    selectedPositionsAndRoles: [],
    selectedEmployments: [], // seperate list of checked employments
    showLocationPositionRoleUpdateModal: false,
    showWagesUpdateModal: false,
    showEndEmploymentModal: false,
    showEndingEmploymentsModal: false,
    search: undefined,
    employmentStatistics: undefined,
    currentEmploymentsCount: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1,
    ordering: DEFAULT_ORDERING, // sort by partner first name asc as default
    showInviteNewWorkersModal: false,
    showInviteLinkModal: false,
    client: undefined,
    viewType: DEFAULT_VIEW_TYPE_KEY,
  };

  async componentDidMount() {
    await this.updateStateFromParams(); // Get params from url
    await this.fetchPositionAndRoleOptions();
    await this.fetchEmploymentsAndStatistics();

    const response = await clientsApi.fetchClient();
    const client = response.results[0];
    this.setState({
      client,
    });
  }

  onSearchChange = debounce(search => {
    this.handleSearch(search);
  }, 600);

  fetchPositionAndRoleOptions = async () => {
    const { positions, roles, t } = this.props;
    const positionAndRoleOptions = await getPositionAndRoleOptions(positions, roles, t);

    this.setState({ positionAndRoleOptions });
  };

  getPositionAndRoleParams = () => {
    const filters = convertPositionAndRoleToList(this.state.selectedPositionsAndRoles, true);
    return prepParams(filters);
  };

  getEmploymentFetchParams = () => {
    const { selectedLocations, selectedStatuses, search } = this.state;
    const positionsAndRoles = this.getPositionAndRoleParams();
    return {
      location: selectedLocations.join(','),
      accepted: selectedStatuses.join(','),
      search,
      ...positionsAndRoles,
    };
  };

  /* Fetch and update employment stats */
  fetchEmploymentStatistics = async () => {
    this.updateParamsFromState(); // Update params when there is change in filter/page
    const { page, pageSize } = this.state;
    const params = {
      ...this.getEmploymentFetchParams(),
      page,
      page_size: pageSize,
      status: employmentStatuses.ACTIVE,
    };
    this.setState({ hasFetchedEmploymentStats: false });
    try {
      const employmentStatistics = await employmentApi.getStatistics(params);
      this.setState({
        hasFetchedEmploymentStats: true,
        currentEmploymentsCount: employmentStatistics.status_counts.active,
        employmentStatistics,
      });
    } catch (err) {
      this.setState({
        hasFetchedEmploymentStats: true,
        currentEmploymentsCount: 0,
        employmentStatistics: undefined,
      });
    }
  };

  /* Fetch and update employments */
  fetchEmployments = async () => {
    this.updateParamsFromState(); // Update params when there is change in filter/page
    const { page, pageSize, ordering, viewType } = this.state;
    const params = {
      ...this.getEmploymentFetchParams(),
      page,
      page_size: pageSize,
      status: employmentStatuses.ACTIVE,
      ordering: ordering || DEFAULT_ORDERING,
      ...(viewType === CONTRACT_VIEW_TYPE_KEY && { contract_required: true }),
    };
    this.setState({ loading: true });
    try {
      const response = await employmentApi.list(params);
      this.setState({
        employments: response.results,
        currentEmploymentsCount: response.count,
      });
    } catch (err) {
      this.setState({ employments: [], currentEmploymentsCount: 0 });
    } finally {
      this.setState({ loading: false });
    }
  };

  fetchEmploymentsAndStatistics = async () => {
    await Promise.all([this.fetchEmployments(), this.fetchEmploymentStatistics()]);
  };

  getOrdering = sorter => {
    const { order, columnKey: sortField } = sorter;
    if (!order) return DEFAULT_ORDERING;
    return order === 'descend' ? `-${sortField}` : sortField;
  };

  handleWorkforceTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const ordering = this.getOrdering(sorter);
    this.setState(
      {
        page: current,
        selectedEmployments: [],
        pageSize,
        ordering,
      },
      () => this.fetchEmployments(),
    );
  };

  handleContractsTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const ordering = this.getOrdering(sorter);
    this.setState(
      {
        page: current,
        selectedEmployments: [],
        pageSize,
        ordering,
      },
      () => this.fetchEmployments(),
    );
  };

  resetSearchFilter = async () => {
    // Use ref to clear search filter value
    await this.setState({ search: undefined }, () => {
      this.searchFilter.current.state.value = undefined;
    });
  };

  handleSearch = value => {
    this.setState({ page: 1, search: value, selectedEmployments: [] }, () => this.fetchEmployments());
  };

  updateStateFromParams = async () => {
    const {
      page,
      page_size,
      status,
      location,
      position,
      role,
      no_role: noRole,
      search,
      ordering,
    } = retrieveParamsFromUrl(this.props.location.search);
    const { positions, roles } = this.props;
    const selectedPositionsAndRoles = convertPositionAndRoleToOptions(position, role, noRole, positions, roles);
    this.setState({
      page: parseInt(page, 10) || 1,
      // eslint-disable-next-line react/no-unused-state
      page_size: parseInt(page_size, 10) || DEFAULT_PAGE_SIZE,
      selectedStatuses: status ? status.split(',') : [],
      selectedLocations: location ? location.split(',') : [],
      selectedPositionsAndRoles,
      search,
      ordering: ordering || DEFAULT_ORDERING,
    });
  };

  handleDownloadContracts = () => {
    const { selectedEmployments, employments } = this.state;
    const partnerIds = employments
      .filter(employment => selectedEmployments.includes(employment.id))
      .map(employment => employment.partner.id);
    staffRequestApi.exportContracts(partnerIds);
    this.setState({ selectedEmployments: [] });
  };

  handleViewTypeChange = async e => {
    this.setState(
      {
        viewType: e.target.value,
      },
      () => this.fetchEmployments(),
    );
  };

  handleEndEmployment = async data => {
    const { selectedEmployments } = this.state;
    const requests = selectedEmployments.map(employmentId =>
      employmentApi.cancel(employmentId, data.reason, data.notes),
    );

    await Promise.all(requests);
    await this.fetchEmploymentsAndStatistics();
    this.setState({ showEndEmploymentModal: false, selectedEmployments: [] }); // unselect employments since it's cancelled and will not appear in table
  };

  handleEmploymentsSelectedChange = selectedEmployments => {
    this.setState({
      selectedEmployments,
    });
  };

  handleUpdateEmploymentsLocationPositionRoles = async (selectedLocation, selectedPosition, selectedRoles = []) => {
    const { selectedEmployments, employments } = this.state;

    const requests = selectedEmployments.map(employmentId => {
      const currentEmployment = employments.filter(employment => employment.id === employmentId)[0];
      let updatedRoles;
      if (selectedPosition !== undefined) {
        updatedRoles = this.updateEmploymentRoles(currentEmployment?.roles, selectedRoles);
      }
      const payload = {
        location: selectedLocation || currentEmployment?.location.id,
        position: selectedPosition || currentEmployment?.position.id,
        roles: updatedRoles,
      };
      return employmentApi.update(employmentId, payload);
    });

    await Promise.all(requests);
    //refresh
    await this.fetchPositionAndRoleOptions();
    await this.fetchEmployments();
  };

  handleRemindWorkersToSignContract = () => {
    const { selectedEmployments, employments } = this.state;
    const partnerIds = employments
      .filter(employment => selectedEmployments.includes(employment.id))
      .map(employment => employment.partner.id);
    staffRequestApi.remindWorkersToSignContract(partnerIds);
    this.setState({ selectedEmployments: [] });
  };

  updateEmploymentRoles = (previousRoles, selectedRoles) => {
    const newRoles = selectedRoles.slice();
    let payload = [];
    if (previousRoles?.length > 0) {
      // copy and reassign any existing employment
      payload = previousRoles.map(employmentRole => {
        if (newRoles.includes(employmentRole.role.id)) {
          const roleIndex = newRoles.indexOf(employmentRole.role.id);
          // remove any previous existing roles from new roles to create
          newRoles.splice(roleIndex, 1);
        }
        return {
          id: employmentRole.id,
          is_assigned: selectedRoles.includes(employmentRole.role.id),
        };
      });
    }
    // updated previous roles + new employment roles to create
    payload = [...payload, ...newRoles.map(role => ({ role }))];
    return payload;
  };

  handleUpdateEmploymentsWages = async data => {
    const { selectedEmployments } = this.state;
    const requests = selectedEmployments.map(employmentId =>
      employmentApi.update(employmentId, {
        ...data,
      }),
    );

    await Promise.all(requests);

    await this.fetchEmployments(); //refresh
    this.setState({ showWagesUpdateModal: false });
  };

  handleEndEmployment = async data => {
    const { selectedEmployments } = this.state;
    const payload = {
      employment_ids: selectedEmployments,
      cancellation_reason: data.reason,
      notes: data.notes,
    };

    this.setState({ loading: true });
    try {
      await employmentApi.bulkCancel(payload);
      await this.fetchEmploymentsAndStatistics();
      this.setState({ selectedEmployments: [] }); // unselect employments since it's cancelled and will not appear in table
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      this.setState({ showEndEmploymentModal: false, loading: false });
    }
  };

  handleApplyStatusFilter = checkedKeys => {
    this.setState({ page: 1, selectedStatuses: checkedKeys }, this.fetchEmploymentsAndStatistics);
  };

  handleApplyLocationFilter = checkedKeys => {
    this.setState({ page: 1, selectedLocations: checkedKeys }, this.fetchEmploymentsAndStatistics);
  };

  handleApplyPositionAndRoleFilter = checkedKeys => {
    this.setState({ page: 1, selectedPositionsAndRoles: checkedKeys }, this.fetchEmploymentsAndStatistics);
  };

  updateParamsFromState() {
    const {
      selectedStatuses,
      selectedLocations,
      selectedPositionsAndRoles,
      search,
      page,
      pageSize,
      ordering,
    } = this.state;
    const { positions, roles, noRoles } = convertPositionAndRoleToList(selectedPositionsAndRoles);
    updateUrlWithParams(
      {
        page,
        page_size: pageSize,
        status: selectedStatuses.join(',') || undefined,
        location: (selectedLocations && selectedLocations.join(',')) || undefined,
        position: positions.join(',') || undefined,
        role: roles.join(',') || undefined,
        no_role: noRoles.join(',') || undefined,
        search: search || undefined,
        ordering,
      },
      this.props.history,
    );
  }

  hasDirectEmployment() {
    const { employments, selectedEmployments } = this.state;
    const filteredEmployments = employments.filter(employment => selectedEmployments.includes(employment.id));
    return filteredEmployments.some(employment => employment.direct_hire === true);
  }

  render() {
    const { t, locations, positions, clientId, country } = this.props;
    const {
      employments,
      currentEmploymentsCount,
      loading,
      hasFetchedEmploymentStats,
      page,
      selectedStatuses,
      selectedLocations,
      showLocationPositionRoleUpdateModal,
      showWagesUpdateModal,
      showEndEmploymentModal,
      selectedEmployments,
      showInviteNewWorkersModal,
      showEndingEmploymentsModal,
      employmentStatistics,
      showInviteLinkModal,
      viewType,
      client,
      selectedPositionsAndRoles,
      positionAndRoleOptions,
    } = this.state;

    // Sort locations alphabetically
    const locationOptions = sortBy(
      locations.map(({ id, name }) => ({ key: String(id), title: name })),
      option => option.title,
    );
    const statusOptions = [
      { title: t('active'), key: 'true' },
      { title: t('invited'), key: 'false' },
    ];

    const bulkActionsMenu = (
      <Menu>
        <Menu.Item
          onClick={() => {
            this.setState({ showEndEmploymentModal: true });
          }}
        >
          <Text style={{ color: colors.functionalError }}>{t('endEmploymentTitle')}</Text>
        </Menu.Item>
      </Menu>
    );

    const isSGClient = client?.country?.code === countryCodeOptions.SINGAPORE;

    return (
      <>
        <Row
          type="flex"
          style={{
            justifyContent: 'space-between',
            marginBottom: '17px',
          }}
        >
          <Title level={2}>{t('workforce')}</Title>
          {client && !isSGClient && (
            <Button
              type="v2-primary"
              disabled={loading}
              onClick={() => {
                this.setState({ showInviteNewWorkersModal: true });
              }}
            >
              {`+ ${t('inviteNewWorkers')}`}
            </Button>
          )}
        </Row>
        <Row type="flex" gutter={32}>
          <Col span={24}>
            <Row type="flex" gutter={120} style={{ marginBottom: '32px' }}>
              {/* Cards Section */}
              <Col span={6}>
                <TotalWorkforceCard
                  loading={!hasFetchedEmploymentStats}
                  totalWorkforceCount={employmentStatistics?.status_counts.active || 0}
                />
              </Col>
              <Col span={18}>
                <WorkforceStatsCard
                  loading={!hasFetchedEmploymentStats}
                  employmentStats={employmentStatistics}
                  onEndingEmploymentsClick={() => {
                    this.setState({ showEndingEmploymentsModal: true });
                  }}
                  onResendInviteClick={() => {
                    this.setState({ showInviteLinkModal: true });
                  }}
                  resendInviteDisabled={!client}
                />
              </Col>
            </Row>
            <Row type="flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              {/* Filters Section */}
              <Col flex="auto">
                <Typography.Text
                  level={3}
                  style={{ marginRight: 12, color: colors.secondaryText, fontSize: '14px', lineHeight: '22px' }}
                >
                  {t('filters')}
                </Typography.Text>
                <TreeCheckboxFilter
                  label={t('status')}
                  placeholder={t('filterByStatusPlaceholder')}
                  treeData={statusOptions}
                  checkedKeys={selectedStatuses}
                  showActionButtons
                  expandable={false}
                  onApply={this.handleApplyStatusFilter}
                />
                <TreeCheckboxFilter
                  label={t('locations')}
                  placeholder={t('filterByLocationPlaceholder')}
                  treeData={locationOptions}
                  checkedKeys={selectedLocations}
                  showActionButtons
                  expandable={false}
                  onApply={this.handleApplyLocationFilter}
                />
                <TreeCheckboxFilter
                  label={t('positionRoles')}
                  placeholder={t('filterByPositionAndRole')}
                  treeData={positionAndRoleOptions}
                  checkedKeys={selectedPositionsAndRoles}
                  showActionButtons
                  formatTag={formatPositionAndRoleTag}
                  onApply={this.handleApplyPositionAndRoleFilter}
                  containerWidth={400}
                />
                <Text style={{ color: colors.secondaryText }}>
                  {t('filterResults', { num: currentEmploymentsCount })}
                </Text>
              </Col>
              <Col>
                {/* Search Input */}
                <Input.Search
                  loading={loading}
                  placeholder={t('searchWorker')}
                  style={{ width: 360 }}
                  allowClear
                  onSearch={this.handleSearch}
                  onChange={event => this.onSearchChange(event.target.value)}
                  ref={this.searchFilter}
                />
              </Col>
            </Row>
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            <Row type="flex" style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '24px' }}>
              {/* Employment Actions */}
              <Col flex="auto">
                <Text style={{ color: colors.secondaryText }}>
                  {t('numSelected', { num: selectedEmployments.length })}
                </Text>
                <Divider type="vertical" style={{ height: '35px', marginRight: 16, marginLeft: 16 }} />
                <Button
                  style={{ marginRight: 8 }}
                  disabled={selectedEmployments.length === 0}
                  onClick={() => {
                    this.setState({ showLocationPositionRoleUpdateModal: true });
                  }}
                >
                  {t('setLocationPositionRole')}
                </Button>
                {checkAccess(permissions.attendanceSetWageButton) && (
                  <Button
                    style={{ marginRight: 8 }}
                    disabled={selectedEmployments.length === 0}
                    onClick={() => {
                      this.setState({ showWagesUpdateModal: true });
                    }}
                  >
                    {t('setWages')}
                  </Button>
                )}
                <Dropdown overlay={bulkActionsMenu} placement="bottomLeft" disabled={selectedEmployments.length === 0}>
                  <Button disabled={selectedEmployments.length === 0}>
                    <EllipsisOutlined />
                  </Button>
                </Dropdown>
              </Col>
              {/* View Type Selector */}
              <Col>
                <Text style={{ marginRight: '24px' }}>{t('viewType')}</Text>
                <Radio.Group
                  defaultValue={DEFAULT_VIEW_TYPE_KEY}
                  buttonStyle="solid"
                  onChange={this.handleViewTypeChange}
                >
                  <Radio.Button value={DEFAULT_VIEW_TYPE_KEY}>{t('default')}</Radio.Button>
                  <Radio.Button value={CONTRACT_VIEW_TYPE_KEY}>{t('contract')}</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
            <Row style={{ maxWidth: '100%' }}>
              {viewType === DEFAULT_VIEW_TYPE_KEY ? (
                <WorkforceTable
                  employments={employments}
                  selectedEmployments={selectedEmployments}
                  currentEmploymentsCount={currentEmploymentsCount}
                  loading={loading}
                  page={page}
                  defaultPageSize={DEFAULT_PAGE_SIZE}
                  onTableChange={this.handleWorkforceTableChange}
                  onEmploymentsSelectedChange={this.handleEmploymentsSelectedChange}
                  testId="workforce-table"
                />
              ) : (
                <ContractsTable
                  employments={employments}
                  selectedEmployments={selectedEmployments}
                  currentEmploymentsCount={currentEmploymentsCount}
                  loading={loading}
                  page={page}
                  defaultPageSize={DEFAULT_PAGE_SIZE}
                  onTableChange={this.handleContractsTableChange}
                  onEmploymentsSelectedChange={this.handleEmploymentsSelectedChange}
                  testId="contracts-table"
                />
              )}
            </Row>
          </Col>
          <LocationPositionRoleUpdateFormModal
            clientId={clientId}
            visible={showLocationPositionRoleUpdateModal}
            confirmLoading={loading}
            onConfirm={this.handleUpdateEmploymentsLocationPositionRoles}
            closeModal={() => this.setState({ showLocationPositionRoleUpdateModal: false })}
          />
          {showWagesUpdateModal && (
            <WagesUpdateModal
              visible={showWagesUpdateModal}
              confirmLoading={loading}
              onConfirm={this.handleUpdateEmploymentsWages}
              closeModal={() => this.setState({ showWagesUpdateModal: false })}
            />
          )}
          <EndEmploymentModal
            visible={showEndEmploymentModal}
            workerCount={selectedEmployments.length}
            confirmLoading={loading}
            closeModal={() => {
              this.setState({ showEndEmploymentModal: false });
            }}
            hasDirectEmployment={this.hasDirectEmployment()}
            onConfirm={this.handleEndEmployment}
          />
          {showInviteNewWorkersModal && client && (
            <InviteNewWorkersModal
              visible={showInviteNewWorkersModal}
              onUpdate={() => {
                this.fetchEmploymentsAndStatistics(); // refresh
              }}
              closeModal={() => this.setState({ showInviteNewWorkersModal: false })}
              clientId={clientId}
              country={country}
              locations={locations}
              positions={positions}
              contractRequired={client.contract_required}
            />
          )}
          {showInviteLinkModal && (
            <InviteLinkModal
              visible={showInviteLinkModal}
              closeModal={() => this.setState({ showInviteLinkModal: false })}
              inviteLink={`${DIRECT_HIRE_INVITE_BRANCH_LINK}?client_name=${encodeURI(
                client.name,
              )}&client_id=${client.uuid?.replace(/-/g, '')}&country_code=${client.country.code}`} // remove dashes from client uuid to align with sms link
            />
          )}
          {showEndingEmploymentsModal && (
            <EndingEmploymentsModal
              visible={showEndingEmploymentsModal}
              onUpdate={() => {
                this.fetchEmploymentStatistics();
              }}
              closeModal={() => {
                this.setState({ showEndingEmploymentsModal: false });
              }}
              selectedLocations={selectedLocations}
              selectedPositionsAndRoles={this.getPositionAndRoleParams()}
            />
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => ({
  locations: state.user.locations,
  positions: state.global.positions,
  roles: state.user.roles,
  clientId: state.user.clientId,
  country: state.user.country,
});

export default connect(mapStateToProps, null)(withTranslation()(WorkforcePage));
