import React, { Fragment } from 'react';
import * as Sentry from '@sentry/browser';
import { PlusCircleOutlined, DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Alert,
  Button,
  Col,
  Checkbox,
  Collapse,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Steps,
  Tooltip,
  Typography,
  AutoComplete,
  Switch,
} from 'antd';
import { debounce } from 'lodash';

import numeral from 'numeral';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import routes from '../../../routes';
import staffRequestUtils from '../../../utilities/staffRequestUtils';

import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import {
  staffRequestStatuses,
  roles,
  wageTypes,
  paymentFrequency,
  employmentTypes,
  countryCodeOptions,
  TAFEP_GUIDELINES_LINK,
  userStatuses,
  staffRequestTemplateStatuses,
  indoContractTypeSelection,
  clientTypes,
  gigContractTypes,
  countryMinWageAmounts,
  clockinMethods,
} from '../../../constants';
import textUtils from '../../../utilities/textUtils';
import { colors } from '../../../styles/colors';
import LocationFormModal from '../../SettingsPage/LocationsSection/components/LocationFormModal';
import formsApi from '../../../services/formsApi';
import ApplicationForms from '../../../containers/ApplicationForms';
import previewIcon from '../../../assets/images/preview-outline.svg';
import ErrorPage from '../../../shared/layouts/ErrorPage';
import staffRequestTemplateApi from '../../../services/staffRequestTemplateApi';
import * as userActions from '../../../redux/user';
import * as globalActions from '../../../redux/global';
import ClockinMethodFormItems from '../../../shared/components/ClockinMethodFormItems';

const numberInputDelay = 300;
const textInputDelay = 600;

const colSpan = 16;

const { Step } = Steps;
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;
const { Panel } = Collapse;

const fields = {
  EMPLOYMENT_TYPE: 'employment_type',
  WAGE_TYPE: 'wage_type',
  WAGE_AMOUNT: 'wage_amount',
};

class StaffRequestTemplateFormView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isDuplicate: false,
      // TODO the default values of each field need adding here
      staffRequestTemplate: {
        template_name: undefined,
        title: undefined,
        responsibilities: undefined,
        requirements: undefined,
        position: undefined,
        require_experience: false,
        wage_type: wageTypes.PER_DAY.value,
        wage_amount: undefined,
        employment_type: employmentTypes.FULL_TIME.value,
        transport_allowance: undefined,
        meal_allowance: undefined,
        other_allowance: undefined,
        payment_frequency: undefined,
        form_id: undefined,
        first_day_message: undefined,
        public_holiday_wage: undefined,
        public_holiday_weekend_wage: undefined,
        clockin_method: undefined,
        fixed_location: false,
      },
      currencyCode: this.props.user.country.currency_code,
      isLoading: false,
      isSubmitting: false,
      locationModalOpen: false,
      currentStep: 0,
      firstDayMessageToggle: true,
      applicationFormToggle: true,
      selectedApplicationForm: undefined,
      isFreelanceContract: false,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { history, location, user, t } = this.props;

    const isDuplicate = location.state && location.state.replicateId;
    const urlParts = history.location.pathname.split('/');
    const isEdit = urlParts.includes('edit');

    const isWorkmateAccount = staffRequestUtils.isWorkmateAccount(user?.username);

    if (!isEdit && user?.type === clientTypes.SELF_SERVE) {
      this.setState(prevState => ({
        staffRequestTemplate: {
          ...prevState.staffRequestTemplate,
          employment_type: employmentTypes.GIG.value,
          wage_type: wageTypes.PER_HOUR.value,
        },
      }));
    }

    if (isEdit) {
      const { id } = this.props.match.params;
      await this.initializeForm(id, isWorkmateAccount, isEdit);
      this.setState({ isEdit: true });
    }
    if (isDuplicate) {
      // Copy from an existing SR
      const id = location.state.replicateId;
      await this.initializeForm(id, isWorkmateAccount);

      // Append title with "copy of" for the duplicate SR
      this.setState(prevState => ({
        staffRequestTemplate: {
          ...prevState.staffRequestTemplate,
          id: undefined,
          // template_name: `Copy of ${prevState.staffRequestTemplate.template_name}`,
          // title: `Copy of ${prevState.staffRequestTemplate.title}`,
          template_name: t('copyOf', { title: prevState.staffRequestTemplate.template_name }),
          title: t('copyOf', { title: prevState.staffRequestTemplate.title }),
        },
        isDuplicate: true,
      }));
    }

    this.setState({ isLoading: false, isFreelanceContract: user?.gig_contract_type === gigContractTypes.FREELANCE });
  }

  initializeForm = async (id, isWorkmateAccount, isEdit = false) => {
    const { t } = this.props;
    const staffRequestTemplate = await staffRequestTemplateApi.fetchStaffRequestTemplateById(id);
    const isDraft = staffRequestTemplateStatuses.DRAFT === staffRequestTemplate?.status;
    if ((!isEdit || isDraft) && !isWorkmateAccount) {
      staffRequestTemplate.form_id = null;
    }
    const state = {
      staffRequestTemplate: {
        ...staffRequestTemplate,
        weekend_wage: staffRequestTemplate?.weekend_wage > 0 ? staffRequestTemplate?.weekend_wage : undefined,
        public_holiday_wage:
          staffRequestTemplate?.public_holiday_wage > 0 ? staffRequestTemplate?.public_holiday_wage : undefined,
        public_holiday_weekend_wage:
          staffRequestTemplate?.public_holiday_weekend_wage > 0
            ? staffRequestTemplate?.public_holiday_weekend_wage
            : undefined,
        clockin_method: staffRequestTemplate?.qr_override_allowed
          ? clockinMethods.QR_SCAN_OR_SELFIE
          : clockinMethods.QR_SCAN_ONLY,
      },
      firstDayMessageToggle: staffRequestTemplate?.first_day_message,
    };
    if (staffRequestTemplate.form_id) {
      try {
        const applicationForm = await formsApi.fetchFormById(staffRequestTemplate.form_id);
        if (applicationForm) {
          state.selectedApplicationForm = applicationForm;
          state.applicationFormToggle = true;
        }
      } catch (error) {
        Sentry.captureException(error);
        message.warning(t('Sorry! An error occured in submission, please contact support'));
      }
    } else {
      state.applicationFormToggle = false;
    }
    this.setState(state);
  };

  handleAutoSelect = (selection, field) => {
    const { user, positions } = this.props;
    if (field === 'position') {
      const position = positions.find(positionValue => positionValue.name === selection);

      this.setState(prevState => {
        const { staffRequestTemplate } = prevState;
        staffRequestTemplate.position = position;
        return { staffRequestTemplate };
      });
    }
    if (field === 'location') {
      const location = user.locations.find(({ name }) => name === selection);
      this.setState(prevState => {
        const { staffRequestTemplate } = prevState;
        staffRequestTemplate.location = location;
        staffRequestTemplate.clockin_method = location?.qr_override_allowed
          ? clockinMethods.QR_SCAN_OR_SELFIE
          : clockinMethods.QR_SCAN_ONLY;
        staffRequestTemplate.fixed_location = location.fixed_location;
        return { staffRequestTemplate, timezone: location.address.area.city.timezone };
      });
    }
  };

  handleSelectInput = (selectedInput, field) => {
    this.setState(prevState => {
      const { staffRequestTemplate } = prevState;
      staffRequestTemplate[field] = selectedInput;
      return { staffRequestTemplate };
    });
  };

  handleAddSrDetails = (text, field) => {
    this.setState(prevState => {
      const { staffRequestTemplate } = prevState;
      staffRequestTemplate[field] = text.currentTarget.value;
      return { staffRequestTemplate };
    });
  };

  handleAddRequireWorkExp = isChecked => {
    this.setState(prevState => {
      const { staffRequestTemplate } = prevState;
      staffRequestTemplate.require_experience = isChecked;
      return { staffRequestTemplate };
    });
  };

  handleTypeChange = (e, field) => {
    const { getFieldValue, getFieldError } = this.props.form;
    const isGigEmploymentType = e.target.value === employmentTypes.GIG.value;
    if ([fields.EMPLOYMENT_TYPE, fields.WAGE_TYPE].includes(field)) {
      if (field === fields.EMPLOYMENT_TYPE) {
        const employmentTypeField = getFieldValue(fields.EMPLOYMENT_TYPE);
        // There is a delay passing through the new field value, so check for previous
        if (
          employmentTypeField === employmentTypes.DAILY.value ||
          employmentTypeField === employmentTypes.CONTRACT.value
        ) {
          this.props.form.setFields({
            end_time: {
              errors: [],
            },
          });
        }
      }
      this.props.form.setFieldsValue({
        weekend_wage: undefined,
        public_holiday_wage: undefined,
        public_holiday_weekend_wage: undefined,
        transport_allowance: undefined,
        meal_allowance: undefined,
        other_allowance: undefined,
      });
      // reset error in wage_amount field
      if (getFieldError(fields.WAGE_AMOUNT)) {
        this.props.form.setFields({
          wage_amount: {
            errors: undefined,
          },
        });
      }
    }
    this.setState(prevState => {
      const { staffRequestTemplate } = prevState;
      const statesToUpdate = {};

      staffRequestTemplate[field] = e.target.value;
      if ([fields.EMPLOYMENT_TYPE, fields.WAGE_TYPE].includes(field)) {
        staffRequestTemplate.weekend_wage = undefined;
        staffRequestTemplate.public_holiday_wage = undefined;
        staffRequestTemplate.public_holiday_weekend_wage = undefined;
        staffRequestTemplate.transport_allowance = undefined;
        staffRequestTemplate.meal_allowance = undefined;
        staffRequestTemplate.other_allowance = undefined;
      }

      if (isGigEmploymentType) {
        const wageType =
          this.props.user.country.code === countryCodeOptions.THAILAND
            ? wageTypes.PER_DAY.value
            : wageTypes.PER_HOUR.value;
        staffRequestTemplate.wage_type = wageType;
        this.props.form.setFieldsValue({
          wage_type: wageType,
        });

        statesToUpdate.applicationFormToggle = true;
      }

      return { ...statesToUpdate, staffRequestTemplate };
    });
  };

  handleSubmit = async (e, action) => {
    e.preventDefault();
    const { staffRequestTemplate, isEdit } = this.state;
    const { t, history, fetchClientAndManager, user } = this.props;
    try {
      this.setState({ isSubmitting: true });
      let response = {};
      const payload = staffRequestUtils.formStaffRequestTemplatePayload(staffRequestTemplate, user.clientId, action);
      if (isEdit) {
        response = staffRequestTemplateApi.editStaffRequestTemplate({
          id: staffRequestTemplate.id,
          ...payload,
        });
        fetchClientAndManager();
        history.push(routes.staffRequestTemplates);
      } else {
        try {
          response = await staffRequestTemplateApi.submitStaffRequestTemplate(payload);

          if (response) {
            message.success(t('templateCreated'));
            fetchClientAndManager();
            history.push(routes.staffRequestTemplates);
          }
        } catch (error) {
          Sentry.captureException(error);
          this.setState({ isSubmitting: false });
          message.warning(t('duplicateStaffRequestTemplate'));
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      this.setState({ isSubmitting: false });
      message.warning(t('Sorry! An error occured in submission, please contact support'));
    }
  };

  // Throw error if user selected a field but left it blank
  // TODO: Find correct validate trigger to dropdown fields and add validations
  checkIfTouchedFormHasError = () => {
    const { isFieldTouched, getFieldError } = this.props.form;
    if (isFieldTouched('title')) {
      getFieldError('title');
    }
    if (isFieldTouched('wage_amount')) {
      getFieldError('wage_amount');
    }
  };

  toggleLocationModal = () => {
    this.setState(prevState => {
      return {
        locationModalOpen: !prevState.locationModalOpen,
      };
    });
  };

  newlineToLineBreak = text => {
    return typeof text === 'string'
      ? text.split('\n').map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })
      : '';
  };

  getEmploymentDetailsReviewData = () => {
    const { staffRequestTemplate, currencyCode, isFreelanceContract } = this.state;
    const { t, user } = this.props;
    const employmentDetailsReviewData = [
      {
        label: t(isFreelanceContract ? 'typeOfEngagement' : 'typeOfEmployment'),
        value:
          staffRequestTemplate.employment_type &&
          t(employmentTypes[staffRequestTemplate.employment_type.toUpperCase()].label),
      },
      {
        label: t(isFreelanceContract ? 'paymentStructure' : 'Wage type'),
        value: t(textUtils.makeFriendly(staffRequestTemplate?.wage_type)),
      },
      {
        label: t(isFreelanceContract ? 'freelanceFee' : 'Wage amount'),
        value: `${currencyCode} ${numeral(staffRequestTemplate?.wage_amount).format('0,0.00')}`,
      },
    ];

    if (user?.country?.code === countryCodeOptions.SINGAPORE) {
      const otherWageTypes = [
        {
          label: staffRequestTemplate?.weekend_includes_friday
            ? `${t('weekend')} - ${t('includeFriday')}`
            : t('weekend'),
          value: staffRequestTemplate?.weekend_wage || 0,
        },
      ];
      if (staffRequestTemplate?.employment_type !== employmentTypes.FULL_TIME.value) {
        otherWageTypes.push({ label: t('publicHoliday'), value: staffRequestTemplate?.public_holiday_wage });
        otherWageTypes.push({
          label: t('publicHolidayWeekend'),
          value: staffRequestTemplate?.public_holiday_weekend_wage,
        });
      }

      if (otherWageTypes.some(item => item.value > 0)) {
        employmentDetailsReviewData.push({
          label: t(isFreelanceContract ? 'otherFees' : 'otherWageTypes'),
          value: (
            <Col>
              {otherWageTypes.map(item => (
                <Row key={item.label}>
                  <Text>{`${currencyCode} ${numeral(item.value).format('0,0.00')} (${item.label})`}</Text>
                </Row>
              ))}
            </Col>
          ),
        });
      }
    }

    const allowances = [
      { label: t('Transport allowance'), value: staffRequestTemplate?.transport_allowance || 0 },
      { label: t('Meal allowance'), value: staffRequestTemplate?.meal_allowance || 0 },
      { label: t('Other allowance'), value: staffRequestTemplate?.other_allowance || 0 },
    ];

    if (allowances.some(item => item.value > 0)) {
      const allowanceData = {
        label: t('Allowances'),
        value: (
          <>
            {allowances.map(item => (
              <Row key={item.label}>
                <Text>{`${currencyCode} ${numeral(item.value).format('0,0.00')} (${item.label})`}</Text>
              </Row>
            ))}
          </>
        ),
      };

      employmentDetailsReviewData.splice(3, 0, allowanceData);
    }

    if (staffRequestTemplate?.payment_frequency) {
      employmentDetailsReviewData.push({
        label: t('Payment frequency'),
        value: t(staffRequestTemplate?.payment_frequency),
      });
    }

    if (staffRequestTemplate?.benefits) {
      employmentDetailsReviewData.push({
        label: t('Benefits'),
        value: <Text>{this.newlineToLineBreak(staffRequestTemplate?.benefits)}</Text>,
      });
    }

    if (staffRequestTemplate.employment_type === employmentTypes.GIG.value) {
      employmentDetailsReviewData.push({
        label: t('clockInMethodTitle'),
        value: (
          <Text>
            {this.newlineToLineBreak(
              staffRequestTemplate.clockin_method === clockinMethods.QR_SCAN_ONLY
                ? t('clockinMethodQrScanOnly')
                : t('clockinMethodQrScanWithSelfie'),
            )}
          </Text>
        ),
      });
    }
    return employmentDetailsReviewData;
  };

  handleSelectForm = form => {
    const { staffRequestTemplate } = this.state;
    this.setState({
      staffRequestTemplate: { ...staffRequestTemplate, form_id: form.id },
      selectedApplicationForm: form,
    });
  };

  handleRefresh = () => {
    const { t } = this.props;
    message.success(t('formListRefreshed'));
  };

  validateWageAmount(value, callback, transKey) {
    const { staffRequestTemplate } = this.state;
    const { t, user } = this.props;
    const minWageAmount = countryMinWageAmounts[user.country.code][staffRequestTemplate.wage_type] || 0;
    const isGig = staffRequestTemplate.employment_type === employmentTypes.GIG.value;
    if (isGig && value !== null && value < minWageAmount) {
      callback(t(transKey, { currency: user.country.code, amount: minWageAmount }));
    } else {
      callback();
    }
  }

  render() {
    if (this.state.isLoading || this.state.isSubmitting) {
      return <LoadingSpinner />;
    }
    const {
      staffRequestTemplate,
      currencyCode,
      isEdit,
      isDuplicate,
      locationModalOpen,
      currentStep,
      firstDayMessageToggle,
      applicationFormToggle,
      selectedApplicationForm,
      isFreelanceContract,
    } = this.state;
    const { t, positions, user, form, fetchClientAndManager } = this.props;
    const { getFieldDecorator, getFieldValue, getFieldError, setFieldsValue } = this.props.form;
    const isWorkmateAccount = staffRequestUtils.isWorkmateAccount(user?.username);

    if (user.status === userStatuses.LEAD) {
      return <ErrorPage title="Error" subTitle={t('Sorry! There is an error loading the page')} />;
    }

    const isGigType = staffRequestTemplate?.employment_type === employmentTypes.GIG.value;
    const isClientSelfServe = user?.type === clientTypes.SELF_SERVE;

    let wageTypeOptions = [
      {
        disabled: isGigType && user.country.code === countryCodeOptions.THAILAND,
        value: wageTypes.PER_HOUR.value,
        label: t('hourly'),
      },
      {
        disabled: isGigType && user.country.code === countryCodeOptions.SINGAPORE,
        value: wageTypes.PER_DAY.value,
        label: t('daily'),
      },
      { disabled: isGigType, value: wageTypes.PER_MONTH.value, label: t('monthly') },
    ];
    const paymentFrequencyOptions = [
      { disabled: false, value: paymentFrequency.WEEKLY.key, label: t('weekly') },
      { disabled: false, value: paymentFrequency.BIWEEKLY.key, label: t('biWeekly') },
      { disabled: false, value: paymentFrequency.MONTHLY.key, label: t('monthly') },
    ];

    let employmentTypeOptions = staffRequestUtils.getEmploymentTypeOptions(t, user);
    if (isClientSelfServe) {
      employmentTypeOptions = employmentTypeOptions.filter(option => option.value === employmentTypes.GIG.value);
      wageTypeOptions = wageTypeOptions.filter(option => option.value === wageTypes.PER_HOUR.value);
    }

    this.checkIfTouchedFormHasError();

    const translatedPosition =
      staffRequestTemplate && positions
        ? positions.find(pos => pos?.id === staffRequestTemplate?.position?.id)
        : undefined;

    const isDraftOrPendingReview = [staffRequestStatuses.DRAFT, staffRequestStatuses.PENDING_REVIEW].includes(
      staffRequestTemplate?.status,
    );

    return (
      <Form hideRequiredMark layout="vertical">
        {/* Header */}
        <Row style={{ flex: 1, flexDirection: 'column', marginBottom: '36px' }}>
          <Title level={2} style={{ marginBottom: '8px' }}>
            {isEdit ? t('editTemplate') : t('createTemplate')}
          </Title>
        </Row>
        {/* STEPS */}
        <Row>
          <Col xs={24}>
            <Steps
              current={currentStep}
              className="v2-steps-wrapper"
              onChange={newTabNumber => {
                form.validateFieldsAndScroll(async err => {
                  if (!err) {
                    this.setState({ currentStep: newTabNumber });
                  }
                });
              }}
              responsive
              style={{ marginBottom: '44px' }}
            >
              <Step disabled title={t('jobDetails')} />
              <Step disabled title={t(isFreelanceContract ? 'shiftDetails' : 'employmentDetails')} />
              <Step disabled title={t('applicationForm')} />
              <Step disabled title={t('firstDayMessage')} />
              <Step disabled title={t('reviewSubmit')} />
            </Steps>
          </Col>
        </Row>
        <section style={{ marginBottom: '48px', display: currentStep !== 0 && 'none' }}>
          {isEdit && (
            <Row style={{ marginBottom: '32px' }}>
              <Col>
                <Alert message={t('editSRTemplateInfoMessage')} type="info" showIcon />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={24} md={8}>
              <Text strong style={{ fontSize: '16px' }}>
                {t('basicsTitle')}
              </Text>
              <Row style={{ marginTop: '16px' }}>
                <Col xs={24}>
                  <Item label={t('giveYourTemplateName')} style={{ marginBottom: 0 }}>
                    {getFieldDecorator('template_name', {
                      validate: [
                        {
                          trigger: 'onBlur',
                          rules: [
                            { required: true, message: t('Please input a name') },
                            { max: 50, message: t('templateNameMaximumCharacterError') },
                          ],
                        },
                        {
                          trigger: 'onBlur',
                          rules: [
                            {
                              message: t('duplicateStaffRequestTemplate'),
                              validator: async (rule, value, callback) => {
                                if (!value || (isEdit && staffRequestTemplate.template_name === value)) {
                                  return callback();
                                }
                                const position = positions.find(
                                  positionValue => positionValue.name === form.getFieldValue('position'),
                                );
                                const location = user.locations.find(
                                  ({ name }) => name === form.getFieldValue('location'),
                                );

                                if (!position || !location) {
                                  return callback();
                                }
                                try {
                                  await staffRequestTemplateApi.validateTemplateName({
                                    template_name: value,
                                    position: position?.id,
                                    location: location?.id,
                                  });

                                  callback();
                                } catch (e) {
                                  callback(t('duplicateStaffRequestTemplate'));
                                }
                              },
                            },
                          ],
                        },
                      ],
                      initialValue: staffRequestTemplate?.template_name || undefined,
                    })(
                      <TextArea
                        autoSize={{ maxRows: 1 }}
                        showCount
                        maxLength={50}
                        style={{ width: '100%' }}
                        placeholder={t('templateNamePlaceholder')}
                        onChange={debounce(text => this.handleAddSrDetails(text, 'template_name'), textInputDelay)}
                      />,
                    )}
                  </Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <Item label={t('typeOfWorker')} style={{ marginBottom: '8px' }}>
                    {getFieldDecorator('position', {
                      rules: [
                        { required: true, message: t('Please select a position') },
                        {
                          validator(rule, value, callback) {
                            if (value && !positions.find(positionValue => positionValue.name === value)) {
                              callback(t('chooseFromListError'));
                            }
                            callback();
                          },
                        },
                      ],
                      initialValue: translatedPosition?.name || undefined,
                    })(
                      <AutoComplete
                        style={{ width: '100%' }}
                        options={positions.map(position => ({ value: position.name }))}
                        filterOption
                        onSelect={selection => this.handleAutoSelect(selection, 'position')}
                        notFoundContent={t('searchNothingFoundMsg')}
                        allowClear
                      >
                        <Input
                          placeholder={t('autoCompletePlaceholder')}
                          suffix={<DownOutlined className="sr-autocomplete-caret" />}
                        />
                      </AutoComplete>,
                    )}
                  </Item>
                </Col>
              </Row>
              <Row>
                <Text style={{ fontSize: '14px' }}>{t('whatIsTheLocation')}</Text>
              </Row>
              <Row>
                <Col xs={24} style={{ marginBottom: 0 }}>
                  <Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator('location', {
                      rules: [
                        { required: true, message: t('Please select a location') },
                        {
                          validator(rule, value, callback) {
                            if (value && !user.locations.find(({ name }) => name === value)) {
                              callback(t('chooseFromListError'));
                            }
                            callback();
                          },
                        },
                      ],
                      initialValue: staffRequestTemplate?.location?.name || undefined,
                    })(
                      <AutoComplete
                        style={{ width: '100%' }}
                        options={user.locations.map(location => ({ value: location.name }))}
                        filterOption
                        onSelect={selection => this.handleAutoSelect(selection, 'location')}
                        disabled={user.locations.length === 0}
                        notFoundContent={t('searchNothingFoundMsg')}
                        suffixIcon={<DownOutlined />}
                      >
                        <Input
                          placeholder={
                            user.locations.length > 0
                              ? t('autoCompletePlaceholder')
                              : t('Please add a new location first')
                          }
                          suffix={<DownOutlined className="sr-autocomplete-caret" />}
                        />
                      </AutoComplete>,
                    )}
                  </Item>
                  <Row style={{ marginBottom: '16px' }}>
                    {this.props.user.role === roles.ADMIN && (
                      <Button
                        type="link"
                        icon={<PlusCircleOutlined />}
                        onClick={this.toggleLocationModal}
                        style={{ padding: '0px' }}
                      >
                        <span style={{ textDecoration: 'underline' }}>{t('Create new location')}</span>
                      </Button>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            {user?.country?.code === countryCodeOptions.SINGAPORE && (
              <Col
                xs={24}
                sm={24}
                md={{ span: 6, offset: 10 }}
                style={{ backgroundColor: colors.whiteBackground, padding: '16px', height: '80%', marginBottom: 0 }}
              >
                <Text strong>{t('tafepGuideLineTitle')}</Text>
                <ul style={{ paddingLeft: 0, marginBottom: 20, listStyle: 'none' }}>
                  <li>{t('tafepGuideLineDescription')}</li>
                </ul>
                <a
                  href={TAFEP_GUIDELINES_LINK}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline' }}
                >
                  {t('tafepGuideLineCTA')}
                </a>
              </Col>
            )}
          </Row>
          <Text strong style={{ fontSize: '16px' }}>
            {t('Job description')}
          </Text>
          <Row style={{ marginBottom: '16px', marginTop: '16px' }}>
            <Col sm={24} md={10}>
              <Item label={t('jobPostTitle')} style={{ marginBottom: 0 }}>
                {getFieldDecorator('title', {
                  validate: [{ trigger: 'onBlur', rules: [{ required: true, message: t('Please input a title') }] }],
                  initialValue: staffRequestTemplate?.title || undefined,
                })(
                  <TextArea
                    autoSize={{ maxRows: 1 }}
                    style={{ width: '100%' }}
                    placeholder={t('jobPostTitlePlaceholder')}
                    onChange={debounce(text => this.handleAddSrDetails(text, 'title'), textInputDelay)}
                  />,
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" style={{ marginBottom: '16px' }}>
            <Col sm={24} md={colSpan}>
              <Item label={t('responsibilitiesTitle')} style={{ marginBottom: 0 }}>
                {getFieldDecorator('responsibilities', {
                  validate: [
                    {
                      trigger: 'onBlur',
                      rules: [
                        {
                          required: true,
                          min: 50,
                          message: t('minimumCharacterError'),
                        },
                      ],
                    },
                  ],
                  initialValue: staffRequestTemplate?.responsibilities || undefined,
                })(
                  <TextArea
                    autoSize={{ minRows: 12, maxRows: 12 }}
                    style={{ width: '100%', marginBottom: 0 }}
                    placeholder={t('responsibilitiesPlaceholder')}
                    onChange={debounce(text => this.handleAddSrDetails(text, 'responsibilities'), textInputDelay)}
                  />,
                )}
              </Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={{ span: 6, offset: 2 }}
              style={{ backgroundColor: colors.whiteBackground, padding: '16px', height: '80%' }}
            >
              <Text strong>{t('hint1Title')}</Text>
              <ul style={{ paddingLeft: '24px' }}>
                <li>{t('hint1Point1')}</li>
                <li>{t('hint1Point2')}</li>
                <li>{t('hint1Point3')}</li>
                <li>{t('hint1Point4')}</li>
                <li>{t('hint1Point5')}</li>
                <li>{t('hint1Point6')}</li>
              </ul>
            </Col>
          </Row>
          <Row type="flex" style={{ marginBottom: '16px' }}>
            <Col xs={24} sm={24} md={colSpan}>
              <Item label={t('requirementsTitle')} style={{ marginBottom: 0 }}>
                {getFieldDecorator('requirements', {
                  validate: [
                    {
                      trigger: 'onBlur',
                      rules: [
                        {
                          required: true,
                          min: 50,
                          message: t('minimumCharacterError'),
                        },
                      ],
                    },
                  ],
                  initialValue: staffRequestTemplate?.requirements || undefined,
                })(
                  <TextArea
                    autoSize={{ minRows: 12, maxRows: 12 }}
                    style={{ width: '100%', marginBottom: 0 }}
                    placeholder={t('requirementsPlaceholder')}
                    onChange={debounce(text => this.handleAddSrDetails(text, 'requirements'), textInputDelay)}
                  />,
                )}
              </Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={{ span: 6, offset: 2 }}
              style={{ backgroundColor: colors.whiteBackground, padding: '16px', height: '80%' }}
            >
              <Text strong>{t('hint2Title')}</Text>
              <ul style={{ paddingLeft: '24px' }}>
                <li>{t('hint2Point1')}</li>
                <li>{t('hint2Point2')}</li>
                <li>{t('hint2Point3')}</li>
                <li>{t('hint2Point4')}</li>
                <li>{t('hint2Point5')}</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col span={colSpan}>
              <Item style={{ margin: 0 }}>
                {getFieldDecorator('require_experience', {
                  initialValue: staffRequestTemplate?.require_experience,
                })(
                  <Checkbox
                    onChange={e => {
                      this.handleAddRequireWorkExp(e.target.checked);
                    }}
                    checked={staffRequestTemplate?.require_experience}
                  >
                    {t('relevantWorkExperienceNeeded')}
                  </Checkbox>,
                )}
              </Item>
            </Col>
          </Row>
        </section>
        {currentStep === 1 && (
          <section style={{ marginBottom: '48px' }}>
            <Row style={{ marginBottom: '16px' }}>
              <Text strong style={{ fontSize: '16px' }}>
                {t(isFreelanceContract ? 'Engagement' : 'Employment')}
              </Text>
            </Row>
            <Row>
              <Col>
                <Item label={t(isFreelanceContract ? 'typeOfEngagement' : 'typeOfEmployment')}>
                  {getFieldDecorator(fields.EMPLOYMENT_TYPE, {
                    initialValue: staffRequestTemplate?.employment_type,
                  })(
                    <Radio.Group
                      className="v2-radio-wrapper"
                      style={{ width: '100%' }}
                      onChange={e => this.handleTypeChange(e, fields.EMPLOYMENT_TYPE)}
                    >
                      {employmentTypeOptions.map((option, index) => (
                        <Radio key={index} value={option.value}>
                          {option.label}
                        </Radio>
                      ))}
                    </Radio.Group>,
                  )}
                </Item>
              </Col>
            </Row>
            <Col style={{ marginTop: 0 }}>
              <Text strong style={{ fontSize: '16px' }}>
                {t(isFreelanceContract ? 'fee' : 'wageTitle')}
              </Text>
              <Row style={{ marginTop: '24px' }}>
                <Text style={{ fontSize: 14, color: colors.black }}>
                  {t(isFreelanceContract ? 'paymentStructure' : 'wageType')}
                </Text>
              </Row>
              <Row>
                <Item style={{ marginBottom: 0, paddingTop: 8 }}>
                  {getFieldDecorator(fields.WAGE_TYPE, {
                    initialValue: staffRequestTemplate?.wage_type || wageTypes.PER_DAY.value,
                  })(
                    <Radio.Group
                      className="v2-radio-wrapper"
                      style={{ width: '100%' }}
                      onChange={e => this.handleTypeChange(e, fields.WAGE_TYPE)}
                    >
                      {wageTypeOptions.map(({ value, label, disabled }, index) => (
                        <Radio key={index} value={value} disabled={disabled}>
                          {label}
                        </Radio>
                      ))}
                    </Radio.Group>,
                  )}
                </Item>
              </Row>
            </Col>

            <Row>
              <Col sm={24} md={colSpan}>
                <Row style={{ marginTop: '24px' }}>
                  <Text style={{ fontSize: 14, color: colors.black }}>
                    {t(isFreelanceContract ? 'freelanceFee' : 'wageAmount')}
                  </Text>
                </Row>
                <Row style={{ paddingTop: 8, alignItems: 'center' }}>
                  <Col span={24} style={{ display: 'flex' }}>
                    <Text style={{ marginRight: 10, marginTop: 6 }}>
                      {t(isFreelanceContract ? 'hourlyRate' : 'baseWage')}
                    </Text>
                    <Item style={{ marginBottom: 0, paddingBottom: 0, width: 275 }}>
                      {getFieldDecorator('wage_amount', {
                        rules: [
                          { required: true, message: t('Please input a wage amount') },
                          {
                            validator: (rule, value, callback) => {
                              this.validateWageAmount(value, callback, 'wageAmountPleaseEnterError');
                            },
                          },
                        ],
                        initialValue: staffRequestTemplate?.wage_amount || undefined,
                      })(
                        <InputNumber
                          formatter={value => `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                          onChange={debounce(
                            selectedInput => this.handleSelectInput(selectedInput, 'wage_amount'),
                            numberInputDelay,
                          )}
                          style={{ width: '100%' }}
                        />,
                      )}
                    </Item>
                  </Col>
                </Row>
                <Row style={{ marginTop: '12px' }}>
                  <Col sm={24} md={colSpan}>
                    <Collapse ghost>
                      <Panel
                        header={
                          user?.country?.code === countryCodeOptions.SINGAPORE
                            ? t(
                                isFreelanceContract
                                  ? 'otherFeesAllowancesPayFrequency'
                                  : 'otherWageTypeAllowancesPayFrequency',
                              )
                            : t('allowancesPayFrequency')
                        }
                        headerClass="advanced-settings-header"
                        className="advanced-settings-content"
                      >
                        {user?.country?.code === countryCodeOptions.SINGAPORE && !isFreelanceContract && (
                          <Row style={{ marginBottom: 32 }}>
                            <Col xs={24}>
                              <Row>
                                <Col>
                                  <Text strong>{t('otherWageTypes')}</Text>
                                </Col>
                              </Row>
                              <Row type="flex" style={{ paddingTop: 16 }}>
                                <Col span={17} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Text style={{ width: 200, marginTop: 6 }}>{t('weekend')}</Text>
                                  <div style={{ width: '100%' }}>
                                    <Item style={{ marginBottom: 0, paddingBottom: 0, width: '100%' }}>
                                      {getFieldDecorator('weekend_wage', {
                                        initialValue: staffRequestTemplate?.weekend_wage || undefined,
                                        rules: [
                                          {
                                            validator: (rule, value, callback) => {
                                              this.validateWageAmount(value, callback, 'wageAmountMinDefaultError');
                                            },
                                          },
                                        ],
                                      })(
                                        <InputNumber
                                          min={0}
                                          formatter={value =>
                                            `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                          }
                                          parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                          onChange={debounce(
                                            selectedInput => this.handleSelectInput(selectedInput, 'weekend_wage'),
                                            numberInputDelay,
                                          )}
                                          style={{ width: '100%' }}
                                        />,
                                      )}
                                    </Item>
                                    {!getFieldError('weekend_wage') && (
                                      <Typography.Text style={{ color: colors.secondaryText, fontSize: 14 }}>
                                        {t('defaultAsBaseWage')}
                                      </Typography.Text>
                                    )}
                                  </div>
                                </Col>
                                <Col span={7} style={{ paddingLeft: 12, marginTop: 6 }}>
                                  <Checkbox
                                    disabled={!staffRequestTemplate?.weekend_wage}
                                    checked={staffRequestTemplate?.weekend_includes_friday}
                                    onChange={e => this.handleSelectInput(e.target.checked, 'weekend_includes_friday')}
                                  >
                                    {t('includeFriday')}
                                  </Checkbox>
                                </Col>
                              </Row>
                              <Row type="flex" style={{ paddingTop: 16 }}>
                                <Col span={17} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Text style={{ width: 200, marginTop: 6 }}>{t('publicHoliday')}</Text>
                                  <div style={{ width: '100%' }}>
                                    <Item style={{ marginBottom: 0, paddingBottom: 0, width: '100%' }}>
                                      {getFieldDecorator('public_holiday_wage', {
                                        initialValue: staffRequestTemplate?.public_holiday_wage,
                                        rules: [
                                          {
                                            validator: (rule, value, callback) => {
                                              this.validateWageAmount(value, callback, 'wageAmountMinDefaultError');
                                            },
                                          },
                                        ],
                                      })(
                                        <InputNumber
                                          min={0}
                                          disabled={
                                            staffRequestTemplate.employment_type === employmentTypes.FULL_TIME.value
                                          }
                                          formatter={value =>
                                            `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                          }
                                          parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                          onChange={debounce(
                                            selectedInput =>
                                              this.handleSelectInput(selectedInput, 'public_holiday_wage'),
                                            numberInputDelay,
                                          )}
                                          style={{ width: '100%' }}
                                        />,
                                      )}
                                    </Item>
                                    {!getFieldError('public_holiday_wage') && (
                                      <Typography.Text style={{ color: colors.secondaryText, fontSize: 14 }}>
                                        {t('defaultAsBaseWage')}
                                      </Typography.Text>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                              <Row type="flex" style={{ paddingTop: 16 }}>
                                <Col span={17} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Text style={{ width: 200, marginTop: 6 }}>{t('publicHolidayWeekend')}</Text>
                                  <div style={{ width: '100%' }}>
                                    <Item style={{ marginBottom: 0, paddingBottom: 0, width: '100%' }}>
                                      {getFieldDecorator('public_holiday_weekend_wage', {
                                        initialValue: staffRequestTemplate?.public_holiday_weekend_wage,
                                        rules: [
                                          {
                                            validator: (rule, value, callback) => {
                                              this.validateWageAmount(value, callback, 'wageAmountMinDefaultError');
                                            },
                                          },
                                        ],
                                      })(
                                        <InputNumber
                                          min={0}
                                          disabled={
                                            staffRequestTemplate.employment_type === employmentTypes.FULL_TIME.value
                                          }
                                          formatter={value =>
                                            `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                          }
                                          parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                          onChange={debounce(
                                            selectedInput =>
                                              this.handleSelectInput(selectedInput, 'public_holiday_weekend_wage'),
                                            numberInputDelay,
                                          )}
                                          style={{ width: '100%' }}
                                        />,
                                      )}
                                    </Item>
                                    {!getFieldError('public_holiday_wage') && (
                                      <Typography.Text style={{ color: colors.secondaryText, fontSize: 14 }}>
                                        {t('defaultAsBaseWage')}
                                      </Typography.Text>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )}

                        <Row>
                          <Col xs={24} md={24}>
                            <Row>
                              <Col>
                                <Text strong>{`${t('allowances')}`}</Text>
                              </Col>
                            </Row>
                            <Row type="flex" style={{ paddingTop: 16, alignItems: 'center' }}>
                              <Col
                                span={17}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                              >
                                <Text style={{ width: 200 }}>{t('transportAllowance')}</Text>
                                <Item style={{ marginBottom: 0, paddingBottom: 0, width: '100%' }}>
                                  {getFieldDecorator('transport_allowance', {
                                    initialValue: staffRequestTemplate?.transport_allowance || undefined,
                                  })(
                                    <InputNumber
                                      min={0}
                                      disabled={getFieldValue('wage_type') === wageTypes.PER_MONTH.value}
                                      formatter={value =>
                                        `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                      }
                                      parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                      onChange={debounce(
                                        selectedInput => this.handleSelectInput(selectedInput, 'transport_allowance'),
                                        numberInputDelay,
                                      )}
                                      style={{ width: '100%' }}
                                    />,
                                  )}
                                </Item>
                              </Col>
                            </Row>
                            <Row type="flex" style={{ paddingTop: 16, alignItems: 'center' }}>
                              <Col
                                span={17}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                              >
                                <Text style={{ width: 200 }}>{t('mealAllowance')}</Text>
                                <Item style={{ marginBottom: 0, paddingBottom: 0, width: '100%' }}>
                                  {getFieldDecorator('meal_allowance', {
                                    initialValue: staffRequestTemplate?.meal_allowance || undefined,
                                  })(
                                    <InputNumber
                                      min={0}
                                      disabled={getFieldValue('wage_type') === wageTypes.PER_MONTH.value}
                                      formatter={value =>
                                        `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                      }
                                      parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                      onChange={debounce(
                                        selectedInput => this.handleSelectInput(selectedInput, 'meal_allowance'),
                                        numberInputDelay,
                                      )}
                                      style={{ width: '100%' }}
                                    />,
                                  )}
                                </Item>
                              </Col>
                            </Row>
                            <Row type="flex" style={{ paddingTop: 16, alignItems: 'center' }}>
                              <Col
                                span={17}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                              >
                                <Text style={{ width: 200 }}>{t('otherAllowance')}</Text>
                                <Item style={{ marginBottom: 0, paddingBottom: 0, width: '100%' }}>
                                  {getFieldDecorator('other_allowance', {
                                    initialValue: staffRequestTemplate?.other_allowance || undefined,
                                  })(
                                    <InputNumber
                                      min={0}
                                      disabled={getFieldValue('wage_type') === wageTypes.PER_MONTH.value}
                                      formatter={value =>
                                        `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                      }
                                      parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                      onChange={debounce(
                                        selectedInput => this.handleSelectInput(selectedInput, 'other_allowance'),
                                        numberInputDelay,
                                      )}
                                      style={{ width: '100%' }}
                                    />,
                                  )}
                                </Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: 32 }}>
                          <Col>
                            <Text strong>{`${t('paymentFrequency')}`}</Text>
                          </Col>
                        </Row>
                        <Row type="flex">
                          <Col span={10}>
                            <Item style={{ paddingTop: 8 }}>
                              {getFieldDecorator('payment_frequency', {
                                initialValue: staffRequestTemplate?.payment_frequency || undefined,
                              })(
                                <Select
                                  allowClear
                                  placeholder={t('selectOne')}
                                  style={{ width: '100%' }}
                                  onChange={selectedInput => this.handleSelectInput(selectedInput, 'payment_frequency')}
                                >
                                  {paymentFrequencyOptions.map(({ value, label, disabled }, index) => (
                                    <Option key={index} value={value} disabled={disabled}>
                                      {label}
                                    </Option>
                                  ))}
                                </Select>,
                              )}
                            </Item>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              </Col>
              {user?.country?.code === countryCodeOptions.SINGAPORE && (
                <Col
                  xs={24}
                  sm={24}
                  md={{ span: 6, offset: 2 }}
                  style={{ backgroundColor: colors.whiteBackground, padding: '16px', height: '80%' }}
                >
                  <Text strong>{t('otherWagesHintHeader')}</Text>
                  <ul>
                    <li>{t('otherWagesHint1')}</li>
                    <li>{t('otherWagesHint2')}</li>
                    <li>{t('otherWagesHint3')}</li>
                  </ul>
                  <Trans i18nKey="otherWagesHintSubheader">
                    <Text>
                      You can set your own fixed amounts for these days under
                      <Text style={{ fontWeight: 'bold' }}> Other wage types</Text>
                    </Text>
                  </Trans>
                </Col>
              )}
            </Row>

            <Row style={{ marginTop: '12px' }}>
              <Col sm={24} md={colSpan}>
                <Item label={t('benefitsTitle')} style={{ marginBottom: 0 }}>
                  {getFieldDecorator('benefits', {
                    initialValue: staffRequestTemplate?.benefits || undefined,
                  })(
                    <TextArea
                      autoSize={{ minRows: 6, maxRows: 6 }}
                      style={{ width: '100%', marginBottom: 0 }}
                      placeholder={t('benefitsPlaceholder')}
                      onChange={debounce(text => this.handleAddSrDetails(text, 'benefits'), textInputDelay)}
                    />,
                  )}
                </Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={{ span: 6, offset: 2 }}
                style={{ backgroundColor: colors.whiteBackground, padding: '16px', height: '80%' }}
              >
                <Text strong>{t('hint3Title')}</Text>
                <ul>
                  <li>{t('hint3Point1')}</li>
                  <li>{t('hint3Point2')}</li>
                </ul>
              </Col>
            </Row>

            {staffRequestTemplate.employment_type === employmentTypes.GIG.value && (
              <Row style={{ marginTop: '12px' }}>
                <Col sm={24} md={colSpan}>
                  <Row style={{ marginBottom: '16px' }}>
                    <Text style={{ color: colors.absoluteBlack, fontSize: '14px', fontWeight: '400' }}>
                      {`${t('clockInMethodTitle')}`}
                    </Text>
                  </Row>
                  <ClockinMethodFormItems
                    getFieldDecorator={getFieldDecorator}
                    getFieldValue={getFieldValue}
                    setFieldsValue={setFieldsValue}
                    initialValues={{
                      clockin_method: staffRequestTemplate.clockin_method,
                      fixed_location: staffRequestTemplate.fixed_location,
                    }}
                    onChange={this.handleSelectInput}
                  />
                </Col>
              </Row>
            )}
            {user?.country?.code === countryCodeOptions.INDONESIA && (
              <Row style={{ marginTop: '12px' }}>
                <Col>
                  <Item label={t('employmentContract')}>
                    {getFieldDecorator('contract_type', {
                      initialValue: staffRequestTemplate?.contract_type,
                      rules: [{ required: true, message: t('selectEmploymentContractError') }],
                    })(
                      <Radio.Group
                        className="v2-radio-wrapper"
                        style={{ width: '100%' }}
                        onChange={e => this.handleTypeChange(e, 'contract_type')}
                      >
                        {indoContractTypeSelection.map((option, index) => (
                          <Radio key={index} value={option.value}>
                            {t(option.label)}
                          </Radio>
                        ))}
                      </Radio.Group>,
                    )}
                  </Item>
                </Col>
              </Row>
            )}
          </section>
        )}

        {currentStep === 2 && (
          <>
            <section style={{ marginBottom: '48px' }}>
              <Row style={{ marginBottom: 16, marginTop: 24 }}>
                <Switch
                  checked={applicationFormToggle || isGigType}
                  disabled={(!isDraftOrPendingReview && !!isEdit) || isGigType}
                  style={{ marginRight: 12 }}
                  onClick={checked => {
                    this.setState(prevState => {
                      // eslint-disable-next-line no-shadow
                      const { staffRequest } = prevState;
                      if (!checked) {
                        staffRequestTemplate.form_id = null;
                      }

                      return {
                        applicationFormToggle: checked,
                        staffRequest,
                        selectedApplicationForm: checked ? selectedApplicationForm : undefined,
                      };
                    });
                  }}
                />
                <Text strong>{t('applicationFormOptional')}</Text>
              </Row>
              <Row type="flex">
                <Col sm={24} md={colSpan}>
                  <Row style={{ marginBottom: 16 }}>
                    <Text style={{ color: colors.primaryText }}>{t('turnOnApplicationFormDescription')}</Text>
                  </Row>
                </Col>
                <Col sm={24}>
                  {(applicationFormToggle || isGigType) && (
                    <ApplicationForms
                      rowSelection={{
                        type: 'radio',
                        onSelect: this.handleSelectForm,
                        selectedRowKeys: [...(selectedApplicationForm ? [selectedApplicationForm.id] : [])],
                        getCheckboxProps: () => ({ disabled: !isDraftOrPendingReview && !!isEdit }),
                      }}
                      onRefresh={this.handleRefresh}
                      showCreateButton={isWorkmateAccount}
                    />
                  )}
                </Col>
              </Row>
            </section>

            <Row>
              <Col sm={24} md={8}>
                <Alert
                  message={t('changesNotAllowedOnceJobIsPosted')}
                  type="info"
                  showIcon
                  style={{ marginBottom: 20 }}
                />
              </Col>
            </Row>
          </>
        )}

        {currentStep === 3 && (
          <section style={{ marginBottom: '48px' }}>
            <Row style={{ marginBottom: 16, marginTop: 24 }}>
              <Switch
                checked={firstDayMessageToggle}
                disabled={!isDraftOrPendingReview && !!isEdit}
                style={{ marginRight: 12 }}
                onClick={checked => {
                  this.setState(prevState => {
                    // eslint-disable-next-line no-shadow
                    const { staffRequest } = prevState;
                    if (!checked) {
                      staffRequestTemplate.first_day_message = null;
                    }

                    return { firstDayMessageToggle: checked, staffRequest };
                  });
                }}
              />
              <Text strong>{t('firstDayMessageOptional')}</Text>
            </Row>
            <Row type="flex">
              <Col sm={24} md={colSpan}>
                <Row style={{ marginBottom: 16 }}>
                  <Text style={{ color: colors.primaryText }}>{t('turnOnFirstDayMessageDescription')}</Text>
                </Row>
                <Row>
                  <Text style={{ color: colors.primaryText }}>{t('firstDayMessageInclude')}</Text>
                </Row>
                <Row style={{ marginBottom: 16 }}>
                  <ul style={{ paddingLeft: '24px', color: colors.primaryText }}>
                    <li>{t('firstDayMessageHint1')}</li>
                    <li>{t('firstDayMessageHint2')}</li>
                    <li>{t('firstDayMessageHint3')}</li>
                    <li>{t('firstDayMessageHint4')}</li>
                  </ul>
                </Row>
                {firstDayMessageToggle && (
                  <>
                    <Row>
                      <Item style={{ width: '40%' }} label={t('messageTitle')}>
                        <Input disabled value={t('firstDayMessageDefaultTitle')} />
                      </Item>
                    </Row>
                    <Row style={{ marginBottom: 16 }}>
                      <Item label={t('messageBody')} style={{ marginBottom: 0, width: '100%' }}>
                        {getFieldDecorator('first_day_message', {
                          validate: [
                            {
                              trigger: 'onBlur',
                              rules: [
                                {
                                  required: true,
                                  min: 50,
                                  message: t('firstDayMessageMinimumCharacterError'),
                                },
                                {
                                  max: 1000,
                                  message: t('firstDayMessageMaximumCharacterError'),
                                },
                              ],
                            },
                          ],
                          initialValue: !isDuplicate ? staffRequestTemplate?.first_day_message : undefined,
                        })(
                          <TextArea
                            placeholder={t('firstDayMessagePlaceholder')}
                            autoSize={{ minRows: 7, maxRows: 8 }}
                            style={{ width: '100%', marginBottom: 0 }}
                            onChange={debounce(
                              text => this.handleAddSrDetails(text, 'first_day_message'),
                              textInputDelay,
                            )}
                          />,
                        )}
                      </Item>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
              <Alert
                type="info"
                showIcon
                message={
                  firstDayMessageToggle ? t('firstDayMessageWarningToggleOn') : t('firstDayMessageWarningToggleOff')
                }
              />
            </Row>
          </section>
        )}

        {currentStep === 4 && (
          <section style={{ marginBottom: '48px' }}>
            <Row style={{ marginBottom: '16px', marginTop: '24px' }}>
              <Text strong style={{ fontSize: '16px' }}>
                {t('jobDetails')}
              </Text>
            </Row>
            {(() => {
              const rows = [
                { label: t('templateName'), value: staffRequestTemplate?.template_name },
                { label: t('Job position'), value: translatedPosition?.name || undefined },
                { label: t('Business location'), value: staffRequestTemplate?.location?.name },
                { label: t('jobTitle'), value: staffRequestTemplate?.title },
                {
                  label: t('responsibilities'),
                  value: this.newlineToLineBreak(staffRequestTemplate?.responsibilities),
                },
                {
                  label: t('requirementsQualifications'),
                  value: this.newlineToLineBreak(staffRequestTemplate?.requirements),
                },
                {
                  label: t('relevantWorkExperience'),
                  value: staffRequestTemplate?.require_experience ? t('needed') : t('notNeeded'),
                },
              ];
              return rows;
            })().map(field => (
              <Row key={field.label} style={{ marginBottom: '16px' }}>
                <Col xs={24} sm={24} md={8}>
                  <Text type="secondary">{field.label}</Text>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <Text>{field.value}</Text>
                </Col>
              </Row>
            ))}

            <Row style={{ marginBottom: '16px', marginTop: '24px' }}>
              <Text strong style={{ fontSize: '16px' }}>
                {t(isFreelanceContract ? 'shiftDetails' : 'employmentDetails')}
              </Text>
            </Row>
            {this.getEmploymentDetailsReviewData().map(field => (
              <Row key={field.label} style={{ marginBottom: '16px' }}>
                <Col xs={24} sm={24} md={8}>
                  <Text type="secondary">{field.label}</Text>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <Row>{field.value}</Row>
                  {field.subtext && <Row>{field.subtext}</Row>}
                </Col>
              </Row>
            ))}
            {applicationFormToggle && selectedApplicationForm && (
              <>
                <Row style={{ marginBottom: '16px', marginTop: '24px' }}>
                  <Text strong style={{ fontSize: '16px' }}>
                    {t('applicationForm')}
                  </Text>
                </Row>
                <Row key={t('applicationForm')} style={{ marginBottom: '16px' }}>
                  <Col xs={24} sm={24} md={8}>
                    <Text type="secondary">{t('formName')}</Text>
                  </Col>
                  <Col xs={24} sm={24} md={16}>
                    <Text>
                      {selectedApplicationForm.title} (
                      <a
                        target="_blank"
                        href={routes.formPreview.replace(':id', selectedApplicationForm.id)}
                        style={{ color: colors.functionalLink }}
                        rel="noreferrer"
                      >
                        {t('preview')}
                        <img src={previewIcon} alt="preview" style={{ marginLeft: 6, width: 16, height: 16 }} />
                      </a>
                      )
                    </Text>
                  </Col>
                </Row>
              </>
            )}
            {firstDayMessageToggle && (
              <>
                <Row style={{ marginBottom: '16px', marginTop: '24px' }}>
                  <Text strong style={{ fontSize: '16px' }}>
                    {t('firstDayMessage')}
                  </Text>
                </Row>
                <Row key={t('firstDayMessage')} style={{ marginBottom: '16px' }}>
                  <Col xs={24} sm={24} md={8}>
                    <Text type="secondary">{t('messageTitle')}</Text>
                  </Col>
                  <Col xs={24} sm={24} md={16}>
                    <Text>{t('firstDayMessageDefaultTitle')}</Text>
                  </Col>
                </Row>
                <Row key={t('firstDayMessage')} style={{ marginBottom: '16px' }}>
                  <Col xs={24} sm={24} md={8}>
                    <Text type="secondary">{t('messageContent')}</Text>
                  </Col>
                  <Col xs={24} sm={24} md={16}>
                    <Text>{this.newlineToLineBreak(staffRequestTemplate?.first_day_message)}</Text>
                  </Col>
                </Row>
              </>
            )}
          </section>
        )}
        {/* Footer action button */}
        {/* TODO: These buttons are exactly the same as the header. Should be refactored for consistency */}
        <Row style={{ marginBottom: '16px', maxWidth: '728px' }}>
          {currentStep > 0 && (
            <Button
              style={{ padding: '0 32px', marginRight: '10px', margin: '8px 10px 8px 0px' }}
              type="v2-secondary"
              name="submit"
              onClick={e => {
                e.preventDefault();
                form.validateFieldsAndScroll(async err => {
                  if (!err) {
                    this.setState(prevState => {
                      const nextStep = prevState.currentStep;
                      return { currentStep: nextStep - 1 };
                    });
                  }
                });
              }}
            >
              {t('back')}
            </Button>
          )}
          {currentStep <= 3 &&
            (() => {
              const nextButton = (
                <Button
                  {...(currentStep === 2 && applicationFormToggle && !selectedApplicationForm
                    ? { disabled: true }
                    : {})}
                  style={{ padding: '0 32px', marginRight: '10px', margin: '8px 10px 8px 0px' }}
                  type="v2-primary"
                  name="submit"
                  onClick={e => {
                    e.preventDefault();
                    form.validateFieldsAndScroll(async err => {
                      if (!err) {
                        this.setState(prevState => {
                          const nextStep = prevState.currentStep;

                          return { currentStep: nextStep + 1 };
                        });
                      }
                    });
                  }}
                >
                  {t('Next')}
                </Button>
              );

              if (currentStep === 2 && applicationFormToggle) {
                return (
                  <Tooltip placement="top" title={t('pleaseSelectAForm')}>
                    {nextButton}
                  </Tooltip>
                );
              }

              return nextButton;
            })()}
          {currentStep === 4 && !(isEdit && staffRequestTemplate.status !== staffRequestStatuses.DRAFT) && (
            <Button
              style={{ padding: '0 32px', marginRight: '10px', margin: '8px 10px 8px 0px' }}
              type="v2-primary"
              name="submit"
              onClick={e => {
                this.handleSubmit(e, 'submit');
              }}
            >
              {t('createTemplateBtn')}
            </Button>
          )}
          {(isEdit || isDuplicate || currentStep > 0) && (
            <Button
              style={{
                padding: '0 16px',
                margin: '8px 10px 8px 0px',
                color: colors.functionalSuccess,
              }}
              className="saveDraftBtn"
              type="link"
              name="save"
              onClick={e => {
                e.preventDefault();
                form.validateFieldsAndScroll(async err => {
                  if (!err) {
                    this.handleSubmit(e, 'save');
                  }
                });
              }}
            >
              {isEdit ? t('Save') : t('saveDraft')}
            </Button>
          )}
        </Row>
        {/* Modal */}
        {locationModalOpen && (
          <LocationFormModal
            simplified
            clientId={user.clientId}
            country={user.country}
            onUpdate={fetchClientAndManager}
            location={null} // Does not have to be explicitly set in "this.state". Handled by Antd.
            visible={locationModalOpen}
            onClose={this.toggleLocationModal}
          />
        )}
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  positions: state.global.positions,
});

const mapDispatchToProps = dispatch => {
  const { fetchClientAndManager } = userActions;
  const { fetchPositions } = globalActions;
  return {
    fetchClientAndManager: async () => {
      await dispatch(fetchClientAndManager());
    },
    fetchPositions: async () => {
      await dispatch(fetchPositions());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Form.create({ name: 'Job Template' })(StaffRequestTemplateFormView)));
