import { Col, Row, Button, Typography, Grid } from 'antd';
import React, { useState } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { colors } from '../../../../styles/colors';
import { DATE_KEY_FORMAT } from '../../../../constants';

const { useBreakpoint } = Grid;

const StyledButton = styled(Button)`
  &:hover {
    border-color: ${colors.functionalLink} !important;
  }
`;

const DateButton = ({ selected, date, handleChange }) => {
  const weekDay = moment(date).format('d');
  const isWeekend = [0, 6].includes(Number(weekDay));
  return (
    <StyledButton
      style={{
        width: '100%',
        height: 'auto',
        padding: 10,
        backgroundColor: selected ? colors.infoBackgroundBlue : colors.white,
        borderColor: selected ? colors.functionalLink : colors.componentBorder,
      }}
      onClick={() => handleChange(date)}
    >
      <Typography.Paragraph
        style={{
          fontWeight: '600',
          marginBottom: 4,
          color: isWeekend ? colors.functionalApproved : colors.primaryText,
        }}
      >
        {moment(date).format('ddd')}
      </Typography.Paragraph>
      <Typography.Paragraph
        style={{
          marginBottom: 0,
          fontWeight: '600',
          color: isWeekend ? colors.functionalApproved : colors.primaryText,
        }}
      >
        {moment(date).format('D')}
      </Typography.Paragraph>
    </StyledButton>
  );
};

const DateSelector = ({ dates, onChange }) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const { xs } = useBreakpoint();

  const isDateSelected = _selectedDate => {
    const selectedDate = moment(_selectedDate).format(DATE_KEY_FORMAT);
    return selectedDates.some(date => date === selectedDate);
  };

  const handleChange = _selectedDate => {
    const selectedDate = moment(_selectedDate).format(DATE_KEY_FORMAT);

    let currentSelectedDates;
    if (isDateSelected(selectedDate)) {
      currentSelectedDates = selectedDates.filter(date => date !== selectedDate);
      setSelectedDates(currentSelectedDates);
    } else {
      currentSelectedDates = [...selectedDates, selectedDate];
      setSelectedDates(currentSelectedDates);
    }
    onChange(currentSelectedDates);
  };

  return (
    <Row gutter={[8, 8]} style={{ marginBottom: 6 }}>
      {dates.map(date => (
        <Col key={date} style={{ width: xs ? '20%' : '14%' }}>
          <DateButton date={date} selected={isDateSelected(date)} handleChange={handleChange} />
        </Col>
      ))}
    </Row>
  );
};

export default DateSelector;
