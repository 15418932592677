import React from 'react';
import { Alert } from 'antd';
import { zendeskLinks } from '../../constants';

const GlobalError = ({ onClickLink, afterClose }) => {
  return (
    <Alert
      showIcon
      closable
      type="error"
      message={
        <>
          <span>Sorry, there is an error with our network. Please </span>
          <a href={zendeskLinks.CONTACT_US_EN} target="_blank" rel="noopener noreferrer" onClick={onClickLink}>
            contact us
          </a>
          <span> if you need help</span>
        </>
      }
      style={{ position: 'fixed', bottom: '16px', width: '346px', left: '216px' }}
      afterClose={afterClose}
    />
  );
};

export default GlobalError;
