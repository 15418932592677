import React from 'react';
import * as Sentry from '@sentry/browser';
import { EllipsisOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Typography, Button, Modal, Table, Select, Spin, Row, message, Dropdown, Menu, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { colors } from '../../../styles/colors';

import ConfirmModals from '../../../shared/components/ConfirmModals';
import timesheetApi from '../../../services/timesheetApi';
import EditTimesheetFormModal from './EditTimesheetFormModal';
import { PAGE_SIZE_OPTIONS, timesheetEntryStatuses } from '../../../constants';
import { prepParams } from '../../../utilities/urlUtils';

const { Text } = Typography;

const DisputedTimesheetsTableModal = withTranslation()(
  ({
    t,
    loading,
    count,
    visible,
    onCancel,
    timesheets,
    pageSize,
    currentPage,
    onPageChange,
    onEditTimesheetEntry,
    selectedTimesheetsCount,
    handleSelectedRows,
    selectedRowKeys,
    onConfirmTimesheetEntry,
    onVoidTimesheetEntry,
    onBulkConfirmTimesheetEntries,
    selectedTimesheets,
  }) => {
    const tableColumns = [
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('Worker')}</Typography.Text>,
        dataIndex: 'workerName',
        width: 200,
        render: workerName => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text style={{ fontWeight: 800 }}>{workerName}</Typography.Text>
          </div>
        ),
      },
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('Date')}</Typography.Text>,
        dataIndex: 'date',
        width: 100,
        render: date => (
          <div style={{ whiteSpace: 'normal' }}>
            <Typography.Text style={{ lineHeight: 1 }}>{date}</Typography.Text>
          </div>
        ),
      },
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('disputeReason')}</Typography.Text>,
        dataIndex: 'disputeReason',
        render: disputeReason => (
          <div style={{ whiteSpace: 'normal' }}>
            <Typography.Text style={{ lineHeight: 1 }}>{disputeReason}</Typography.Text>
          </div>
        ),
      },
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('location')}</Typography.Text>,
        dataIndex: 'locationName',
        render: locationName => (
          <div style={{ whiteSpace: 'normal' }}>
            <Typography.Text style={{ lineHeight: 1 }}>{locationName}</Typography.Text>
          </div>
        ),
      },
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('Actions')}</Typography.Text>,
        dataIndex: 'buttons',
        width: 100,
        render: (_, record) => (
          <div style={{ whiteSpace: 'normal' }}>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="edit"
                    onClick={() => {
                      const timesheetEntry = timesheets.find(entry => entry.id === record.id);
                      onEditTimesheetEntry(timesheetEntry);
                    }}
                  >
                    {t('Edit')}
                  </Menu.Item>
                  <Menu.Item
                    key="confirm"
                    onClick={() => {
                      ConfirmModals.confirmTimesheetEntries(1, t, () => {
                        onConfirmTimesheetEntry(record.id);
                      });
                    }}
                  >
                    {t('Confirm')}
                  </Menu.Item>
                  <Menu.Item
                    key="void"
                    onClick={() => {
                      const timesheetEntry = timesheets.find(entry => entry.id === record.id);
                      onVoidTimesheetEntry(timesheetEntry.id);
                    }}
                  >
                    <Text style={{ color: colors.functionalError }}>{t('Void')}</Text>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomLeft"
            >
              <Button>
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </div>
        ),
      },
    ];

    const tableRows = timesheets.map(timesheetEntry => {
      const { id, partner, disputed_reason, clock_in_time, location } = timesheetEntry;
      const workerName = `${partner.first_name} ${partner.last_name}`;
      const date = moment(clock_in_time).format('DD MMM');
      const locationName = location?.name;

      return {
        id,
        workerName,
        date,
        disputeReason: disputed_reason?.description || '-',
        locationName,
        key: id,
      };
    });

    const btnStyle = { fontWeight: 600, marginBottom: 5 };

    return (
      <Modal
        title={
          <Typography.Text style={{ fontWeight: 600, fontSize: 24, color: colors.black }}>
            {`${count} ${t('disputedTimesheets')}`}
          </Typography.Text>
        }
        visible={visible}
        onCancel={onCancel}
        footer={null}
        style={{ minWidth: 1000 }}
      >
        <Button
          type="secondary"
          style={
            selectedTimesheetsCount
              ? { color: colors.workmateGreen, borderColor: colors.workmateGreen, ...btnStyle }
              : { color: colors.darkGrey, ...btnStyle }
          }
          disabled={!selectedTimesheetsCount}
          onClick={() =>
            ConfirmModals.confirmTimesheetEntries(selectedTimesheets.length, t, onBulkConfirmTimesheetEntries)
          }
        >
          {t('Confirm')} {selectedTimesheetsCount && <span style={{ marginLeft: 5 }}>({selectedTimesheetsCount})</span>}
        </Button>
        <Button
          type="secondary"
          style={
            selectedTimesheetsCount
              ? { color: colors.red, ...btnStyle, marginLeft: 10 }
              : { color: colors.darkGrey, ...btnStyle, marginLeft: 10 }
          }
          disabled={!selectedTimesheetsCount}
          onClick={() => onVoidTimesheetEntry()}
        >
          {t('Void')} {selectedTimesheetsCount && <span style={{ marginLeft: 5 }}>({selectedTimesheetsCount})</span>}
        </Button>
        <Table
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys,
            onChange: handleSelectedRows,
          }}
          indentSize={0}
          loading={loading}
          columns={tableColumns}
          dataSource={tableRows}
          rowKey="key"
          pagination={{
            pageSize,
            current: currentPage,
            showSizeChanger: true,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            total: count,
            showTotal: (total, range) => `${range[0]}-${range[1]} ${t('of')} ${total} ${t('items')}`,
          }}
          onChange={onPageChange}
        />
      </Modal>
    );
  },
);

const ConfirmVoidTimesheetsModal = Form.create({ name: 'bulk-void-timesheet-entry-form' })(
  withTranslation()(
    ({ t, timesheetsCount, visible, handleVoidCancel, onBulkVoidTimesheetEntries, voidReasons, form }) => {
      const { getFieldDecorator, validateFields } = form;
      return (
        <Modal
          title={
            <Typography.Text style={{ fontWeight: 600, fontSize: 24, color: colors.black }}>
              {t('confirmVoidModalTitle')}
            </Typography.Text>
          }
          centered
          visible={visible}
          onCancel={handleVoidCancel}
          onOk={e => {
            e.preventDefault();
            validateFields(async (err, values) => {
              if (!err) {
                onBulkVoidTimesheetEntries(values.voided_reason);
              }
            });
          }}
          okText={t('confirmVoid')}
          okType="danger"
        >
          <Typography.Text style={{ fontSize: 16, color: colors.black }}>
            {t('confirmVoidModalDescription', { timesheetsCount })}
          </Typography.Text>
          <Form hideRequiredMark>
            <Form.Item label={t('Reason for voiding (required)')}>
              {getFieldDecorator('voided_reason', {
                rules: [{ required: true, message: t('Reason is required') }],
              })(
                <Select
                  autoFocus
                  placeholder={t('Please give your reason and some details...')}
                  optionLabelProp="label"
                >
                  {voidReasons?.map(({ id, name, description }) => (
                    <Select.Option key={id} label={name}>
                      <Typography.Text>{name}</Typography.Text>
                      <br />
                      <Typography.Text type="secondary" style={{ whiteSpace: 'normal' }}>
                        {description}
                      </Typography.Text>
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    },
  ),
);

class DisputedTimesheetsSection extends React.Component {
  state = {
    loading: false,
    currentPage: 1,
    showTimesheetEntryModal: false,
    showDisputedTimesheetsModal: false,
    showVoidConfirmationModal: false,
    disputedTimesheets: [],
    selectedTimesheetsCount: undefined,
    selectedTimesheets: [],
    voidReasons: undefined,
    selectedRowKeys: undefined,
    disputedTablePageSize: 10,
    selectedTimesheetEntry: undefined,
  };

  async componentDidMount() {
    await this.fetchData();
    this.fetchVoidReasons();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.locations !== this.props.locations || prevProps.timesheetEntries !== this.props.timesheetEntries) {
      this.resetPageData(this.fetchData);
    }
  }

  fetchVoidReasons = async () => {
    this.setState({ loading: true });
    try {
      const response = await timesheetApi.fetchVoidReasons();
      this.setState({ loading: false, voidReasons: response });
    } catch (error) {
      this.setState({ loading: false });
      Sentry.captureException(error);
    }
  };

  resetPageData = then => {
    return this.setState({ currentPage: 1 }, then);
  };

  resetVoidSelection = () => {
    this.setState({
      selectedTimesheets: [],
      selectedTimesheetsCount: undefined,
      selectedRowKeys: undefined,
    });
  };

  fetchData = async () => {
    await this.fetchDisputedTimesheets();
  };

  fetchDisputedTimesheets = async () => {
    const { locations, selectedWeekStart, filters } = this.props;
    const { disputedTablePageSize, currentPage } = this.state;
    const startDate = selectedWeekStart.clone().toISOString();
    const endDate = selectedWeekStart
      .clone()
      .endOf('isoWeek')
      .toISOString();
    this.setState({ loading: true });
    const response = await timesheetApi.attendanceList({
      location: locations.join(','),
      status: timesheetEntryStatuses.DISPUTED,
      page: currentPage,
      page_size: disputedTablePageSize,
      clock_in_time_after: startDate,
      clock_in_time_before: endDate,
      ...prepParams(filters),
    });
    this.setState({
      loading: false,
      disputedTimesheets: response.results,
    });
  };

  handleViewDisputedTimesheets = () => {
    this.setState({ showDisputedTimesheetsModal: true });
  };

  handleCloseDisputedTimesheets = () => {
    this.setState({ showDisputedTimesheetsModal: false });
  };

  handleVoidCancel = () => {
    this.setState({ showVoidConfirmationModal: false });
  };

  onBulkVoidTimesheetEntries = async voidedReason => {
    this.setState({ loading: true, showVoidConfirmationModal: false });
    const { t, refreshAttendancePageData } = this.props;

    const { selectedTimesheets } = this.state;
    const timesheetsToVoid =
      selectedTimesheets.length > 0 ? selectedTimesheets.join(',') : selectedTimesheets[0].toString();
    try {
      await timesheetApi.bulkVoid(timesheetsToVoid, { voided_reason: voidedReason });
      this.resetPageData(this.fetchData);
      this.resetVoidSelection();
      refreshAttendancePageData();
      message.success(t('bulkVoidSuccess', { number: selectedTimesheets.length }));
    } catch (error) {
      message.error(t('Something went wrong. No changes were saved'));
      Sentry.captureException(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleSelectedRows = (selectedRowKeys, selectedRows) => {
    const selectedTimesheets = selectedRows.map(timesheet => timesheet.id);
    this.setState({
      selectedTimesheets,
      selectedRowKeys,
      selectedTimesheetsCount: selectedTimesheets.length,
    });
  };

  onVoidTimesheetEntry = (timesheetEntry = null) => {
    if (timesheetEntry) {
      this.setState({ showVoidConfirmationModal: true, selectedTimesheets: [timesheetEntry] });
    } else {
      this.setState({ showVoidConfirmationModal: true });
    }
  };

  onDisputedTimesheetsTablePageChange = ({ current: page, pageSize }) => {
    this.setState({ currentPage: page, disputedTablePageSize: pageSize }, this.fetchDisputedTimesheets);
    this.resetVoidSelection();
  };

  onEditTimesheetEntry = async timesheetEntry => {
    this.setState({ showTimesheetEntryModal: true, selectedTimesheetEntry: timesheetEntry });
  };

  onConfirmTimesheetEntry = async timesheetEntryId => {
    const { t, refreshAttendancePageData } = this.props;
    try {
      await timesheetApi.confirm(timesheetEntryId);
      this.resetVoidSelection();
      this.resetPageData(this.fetchData);
      refreshAttendancePageData();
      message.success(`${t('confirmTimesheetSuccess')} #${timesheetEntryId}`);
    } catch (error) {
      // TODO change old translation keys to new format
      // TODO add more descriptive error message
      message.error(t('Something went wrong. No changes were saved'));
      Sentry.captureException(error);
    }
  };

  onBulkConfirmTimesheetEntries = async () => {
    const { t, refreshAttendancePageData } = this.props;
    const { selectedTimesheets } = this.state;
    try {
      await timesheetApi.bulkConfirm({ id: selectedTimesheets.join(',') });
      this.resetPageData(this.fetchData);
      this.resetVoidSelection();
      refreshAttendancePageData();
      message.success(t('bulkConfirmSuccess', { number: selectedTimesheets.length }));
    } catch (error) {
      message.error(t('Something went wrong. No changes were saved'));
      Sentry.captureException(error);
    }
  };

  render() {
    const { t, refreshAttendancePageData, disputedTimesheetsCount } = this.props;
    const {
      loading,
      currentPage,
      showTimesheetEntryModal,
      showDisputedTimesheetsModal,
      showVoidConfirmationModal,
      disputedTimesheets,
      disputedTablePageSize,
      selectedTimesheetsCount,
      selectedTimesheets,
      selectedRowKeys,
      selectedTimesheetEntry,
      voidReasons,
    } = this.state;

    return (
      <>
        <Typography.Text type="secondary" style={{ fontSize: 12, fontWeight: 600 }}>
          {t('disputedTimesheets').toUpperCase()}
          <Tooltip title={t('disputedTimesheetTooltip')} placement="bottom">
            <QuestionCircleOutlined style={{ marginLeft: '5px', color: colors.darkGrey }} />
          </Tooltip>
        </Typography.Text>
        <Row type="flex" style={{ alignItems: 'center' }}>
          <Typography.Title level={3} style={{ color: colors.red }}>
            {loading ? <Spin data-testid="stats-card-spinner" /> : disputedTimesheetsCount}
          </Typography.Title>
          <Button onClick={this.handleViewDisputedTimesheets} size="small" style={{ margin: '0px 0px 12px 8px' }}>
            {t('viewAll')}
          </Button>
        </Row>
        <DisputedTimesheetsTableModal
          loading={loading}
          count={disputedTimesheetsCount}
          visible={showDisputedTimesheetsModal}
          timesheets={disputedTimesheets}
          onCancel={this.handleCloseDisputedTimesheets}
          pageSize={disputedTablePageSize}
          currentPage={currentPage}
          onPageChange={this.onDisputedTimesheetsTablePageChange}
          onEditTimesheetEntry={this.onEditTimesheetEntry}
          onConfirmTimesheetEntry={this.onConfirmTimesheetEntry}
          onBulkConfirmTimesheetEntries={this.onBulkConfirmTimesheetEntries}
          onVoidTimesheetEntry={this.onVoidTimesheetEntry}
          handleSelectedRows={this.handleSelectedRows}
          selectedTimesheetsCount={selectedTimesheetsCount || undefined}
          selectedRowKeys={selectedRowKeys}
          selectedTimesheets={selectedTimesheets}
          onComplete={() => this.resetPageData(this.fetchData)}
        />
        <ConfirmVoidTimesheetsModal
          visible={showVoidConfirmationModal}
          handleVoidCancel={this.handleVoidCancel}
          onBulkVoidTimesheetEntries={reason => this.onBulkVoidTimesheetEntries(reason)}
          timesheetsCount={selectedTimesheetsCount}
          voidReasons={voidReasons}
        />
        {showTimesheetEntryModal && (
          <EditTimesheetFormModal
            onClose={() => {
              this.setState({
                showTimesheetEntryModal: false,
                selectedTimesheetEntry: null,
              });
            }}
            visible={showTimesheetEntryModal}
            selectedTimesheetEntry={selectedTimesheetEntry}
            onUpdateTimesheet={() => {
              this.resetPageData(this.fetchData);
              refreshAttendancePageData();
              this.setState({
                showTimesheetEntryModal: false,
                selectedTimesheetEntry: null,
              });
            }}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(DisputedTimesheetsSection);
