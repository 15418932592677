import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, List, Row, Typography, Avatar, Empty, Input, ConfigProvider, Dropdown, Menu, Grid } from 'antd';
import { UserOutlined, PhoneFilled, WhatsAppOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../styles/colors';
import phoneMessageImg from '../../../../assets/images/phone-message.svg';
import routes from '../../../../routes';
import LoadingSpinner from '../../../../shared/components/LoadingSpinner';
import StatisticsTags from './StatisticsTags';
import NoApplicantsIcon from '../../../../assets/images/no_applicants_icon.svg';
import EmptyCard from '../../../../shared/components/EmptyCard';

const { useBreakpoint } = Grid;

const WorkerItem = ({ worker, openWhatsAppLink, callWorker, isOnWebView }) => {
  const { t } = useTranslation();
  const { xs } = useBreakpoint();

  const menu = (
    <Menu>
      {xs && (
        <Menu.Item key="phoneCall" onClick={() => callWorker(worker.mobile)} style={{ padding: 12 }}>
          <PhoneFilled style={{ marginRight: 6, color: colors.primaryText }} />
          <Typography.Text style={{ color: colors.primaryText }}> {t('phoneCall')}</Typography.Text>
        </Menu.Item>
      )}

      <Menu.Item
        key="whatsApp"
        onClick={() => openWhatsAppLink(`${worker.firstName} ${worker.lastName || ''}`, worker.mobile)}
        style={{ padding: 12 }}
      >
        <WhatsAppOutlined style={{ marginRight: 6, color: colors.primaryText }} />
        <Typography.Text style={{ color: colors.primaryText }}> {t('whatsApp')}</Typography.Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Link
      to={routes.partnerDetail.replace(':id', worker.id)}
      style={{ display: 'block', width: '100%' }}
      {...(!isOnWebView && { target: '_blank' })}
    >
      <Row>
        <Col xs={20}>
          <Row>
            <Avatar shape="square" size={64} src={worker.image} icon={<UserOutlined />} style={{ marginRight: 16 }} />
            <Row style={{ flexDirection: 'column' }}>
              <Typography.Paragraph style={{ fontWeight: '600', marginBottom: 6 }}>
                {worker.firstName} {worker.lastName || ''}
              </Typography.Paragraph>
              <StatisticsTags statistics={worker.statistics} />
            </Row>
          </Row>
        </Col>
        <Col xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Dropdown trigger={['click']} overlay={menu}>
            <Button
              style={{
                width: 40,
                height: 40,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={phoneMessageImg} alt="phone message" style={{ marginTop: 3 }} />
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </Link>
  );
};

const WorkerList = ({
  workers = [],
  shift,
  shiftTime,
  user,
  loading = false,
  emptyTitle,
  emptyDescription,
  isOnWebView,
}) => {
  const [searchText, setSearchText] = useState('');

  const { t } = useTranslation();

  const callWorker = mobile => {
    const link = `tel:${mobile}`;
    window.open(link, '_blank');
  };

  const openWhatsAppLink = (workerName, mobile) => {
    const link = t('whatsAppMessageToWorker', {
      mobile,
      workerName,
      shiftTime,
      clientName: user.name,
      locationName: shift?.location?.name,
    });
    window.open(link, '_blank');
  };

  const handleSearchChange = e => {
    const { value } = e.target;
    setSearchText(value);
  };

  const searchWorkers = searchText
    ? workers.filter(worker =>
        `${worker.firstName} ${worker.lastName}`
          .trim()
          .toLowerCase()
          .includes(searchText.toLowerCase()),
      )
    : workers;

  const getEmptyCard = () => {
    if (searchText && !searchWorkers.length) {
      return <Empty description={t('noResultsFound')} image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  return (
    <>
      <Row style={{ marginBottom: 24 }}>
        {/* Search*/}
        <Col xs={24} md={7}>
          <Input.Search placeholder={t('searchWorkers')} onChange={handleSearchChange} />
        </Col>
      </Row>
      {/* Worker list */}
      <ConfigProvider renderEmpty={getEmptyCard}>
        <List
          loading={{ spinning: loading, indicator: <LoadingSpinner width="50px" style={{ marginTop: 30 }} /> }}
          dataSource={searchWorkers}
          locale={{
            emptyText: <EmptyCard imgSrc={NoApplicantsIcon} title={emptyTitle} description={emptyDescription} />,
          }}
          renderItem={worker => (
            <List.Item style={{ borderBottom: `1px solid ${colors.componentBorder}` }}>
              <WorkerItem
                worker={worker}
                openWhatsAppLink={openWhatsAppLink}
                callWorker={callWorker}
                isOnWebView={isOnWebView}
              />
            </List.Item>
          )}
        />
      </ConfigProvider>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  isOnWebView: state.global.isOnWebView,
});

export default connect(mapStateToProps)(WorkerList);
