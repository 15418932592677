import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

const LeavePagePrompt = ({ when }) => {
  const { t } = useTranslation();
  const message = `${t('leavePagePromptTitleMessage')}\n${t('leavePagePromptDescMessage')}`;

  useEffect(() => {
    if (!when) return () => {};

    const beforeUnloadCallback = event => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', beforeUnloadCallback);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
    };
  }, [when]);

  return <Prompt when={when} message={message} />;
};

export default LeavePagePrompt;
