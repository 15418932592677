import React, { Component } from 'react';
import { Row, Col, Typography, Descriptions, Alert, Button } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

import routes from '../../../routes';
import staffRequestApi from '../../../services/staffRequestApi';
import billingApi from '../../../services/billingApi';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import {
  seenModals,
  indoBankAccountDetail,
  PREPAID_SERVICE_RATE,
  PREPAID_TAX_RATE,
  sourceOptions,
} from '../../../constants';
import PaymentHelp from '../../../shared/components/PaymentHelp';
import NumberedListInfo from '../../../shared/components/NumberedListInfo';
import PaymentBreakDown from './components/PaymentBreakDown';

const { Title, Text } = Typography;
const SERVICE_RATE = PREPAID_SERVICE_RATE / 100;
const TAX_RATE = PREPAID_TAX_RATE / 100;
const DISCOUNT_THRESHOLD = 10;
const DISCOUNT_DAYS = 2;

class PrepaidDetailView extends Component {
  state = { staffRequest: undefined, paymentSummary: [], amountDue: undefined, wageFee: undefined };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.fetchStaffRequestInfo(id);
  }

  fetchStaffRequestInfo = async id => {
    const staffRequest = await staffRequestApi.fetchStaffRequestById(id);
    this.setState({ staffRequest }, () => this.getPaymentDetail());
  };

  getPaymentDetail = () => {
    const { t } = this.props;
    const {
      wage_amount: wageAmount,
      staff_required: staffRequired,
      start_time: startTime,
      end_time: endTime,
      client,
    } = this.state.staffRequest;
    const paymentSummary = [];

    // TODO Write Unit test
    const currency = client.country.currency_code;
    const durationDays = moment(endTime).diff(moment(startTime), 'days');
    const totalWorkDays = durationDays * staffRequired;
    const discount =
      this.props.user.source === sourceOptions.GRAB && totalWorkDays >= DISCOUNT_THRESHOLD
        ? DISCOUNT_DAYS * wageAmount
        : 0;
    const totalWageAmount = wageAmount * totalWorkDays;
    const wageFee = totalWageAmount - discount;
    const serviceFee = wageFee * SERVICE_RATE;
    const taxFee = serviceFee * TAX_RATE;
    const amountDue = wageFee + serviceFee + taxFee;
    this.setState({ amountDue, wageFee });

    paymentSummary.push({
      description: t('{{wageAmount}} {{currency}} per day × {{staffRequired}} workmates × {{durationDays}} days', {
        wageAmount: numeral(wageAmount).format('0,0.00'),
        currency,
        staffRequired,
        durationDays,
      }),
      total: `${numeral(totalWageAmount).format('0,0.00')} ${currency}`,
    });

    if (discount > 0) {
      paymentSummary.push({
        description: t('Discount ({{wageAmount}} {{currency}} x {{discountDays}})', {
          wageAmount: numeral(wageAmount).format('0,0.00'),
          currency,
          discountDays: DISCOUNT_DAYS,
        }),
        total: `- ${numeral(discount).format('0,0.00')} ${currency}`,
      });
    }
    paymentSummary.push({
      description: t('Platform fee ({{serviceRate}}%)', { serviceRate: PREPAID_SERVICE_RATE }),
      total: `${numeral(serviceFee).format('0,0.00')} ${currency}`,
    });
    paymentSummary.push({
      description: t('VAT ({{taxRate}}% of Platform fee)', { taxRate: PREPAID_TAX_RATE }),
      total: `${numeral(taxFee).format('0,0.00')} ${currency}`,
    });
    paymentSummary.push({
      description: <Text strong>{this.props.t('Amount due')}</Text>,
      total: (
        <Text strong>
          {numeral(amountDue).format('0,0.00')} {currency}
        </Text>
      ),
    });
    this.setState({ paymentSummary });
  };

  // Submit SR to pending_review status & move to next page
  submitStaffRequestAndReroute = async () => {
    this.setState({ isSubmitting: true });
    const { staffRequest, wageFee } = this.state;
    const response = await staffRequestApi.submitStaffRequest(staffRequest.id);
    await billingApi.generatePrepaidBillingReport({
      wage_fee: wageFee,
      staff_request: staffRequest.id,
    });

    if (response) {
      localStorage.setItem(seenModals.SHOW_SUBMITTED_SR, true);
      this.props.fetchClientAndManager();
      this.props.history.push(routes.staffRequests);
    }
    this.setState({ isSubmitting: false });
  };

  render() {
    const { staffRequest, paymentSummary, isSubmitting, amountDue } = this.state;
    const { t } = this.props;
    if (!staffRequest) return <LoadingSpinner />;
    const currency = staffRequest.client.country.currency_code;

    return (
      // eslint-disable-next-line no-return-assign
      <Row type="flex" justify="space-around" ref={el => (this.componentRef = el)}>
        <Col span={24} style={{ maxWidth: '572px' }}>
          <Row style={{ marginBottom: '48px' }}>
            <Title level={3} style={{ marginBottom: '8px' }}>
              {t('Prepaid bank transfer')}
            </Title>

            <NumberedListInfo
              title={t('You need to do this step manually. Tell your bank to make a payment using the details below.')}
              list={[
                t('Pay the amount due via online banking or ATM within 24 hours.'),
                t('You can send us proof of payment to speed up the verification process.'),
                t('Your request will be posted once we’ve verified your payment.'),
              ]}
            />
          </Row>

          <section style={{ marginBottom: 64 }}>
            <PaymentBreakDown
              t={t}
              staffRequest={staffRequest}
              amountDue={numeral(amountDue).format('0,0.00')}
              paymentSummary={paymentSummary}
            />
          </section>

          <section style={{ marginBottom: '48px' }}>
            <Row style={{ marginBottom: '24px' }}>
              <Title level={4}>{t('Bank transfer details')}</Title>
            </Row>
            <Row style={{ marginBottom: '24px' }}>
              <Alert
                message={<Text strong>{t('Include your reference number')}</Text>}
                description={t(
                  'Make sure you include the reference {{ staffRequestId }} when transferring via online banking. It helps us know that the money came from you.',
                  { staffRequestId: staffRequest.id },
                )}
                type="info"
                showIcon
              />
            </Row>
            <Row style={{ marginBottom: '24px' }}>
              <Descriptions layout="vertical" colon={false} column={{ md: 2, sm: 1, xs: 1 }} size="small">
                <Descriptions.Item label={t('Payee name')}>{indoBankAccountDetail.payeeName}</Descriptions.Item>
                <Descriptions.Item label={t('Reference number')}>{staffRequest.id}</Descriptions.Item>
                <Descriptions.Item label={t('Account number')}>{indoBankAccountDetail.accountNumber}</Descriptions.Item>
                <Descriptions.Item label={t('Bank name')}>{indoBankAccountDetail.bankName}</Descriptions.Item>
                <Descriptions.Item label={t('Amount to pay')} span={2}>
                  {numeral(amountDue).format('0,0.00')} {currency}
                </Descriptions.Item>
                <Descriptions.Item label={t('Bank address')} span={2}>
                  <Text>{indoBankAccountDetail.bankAddress}</Text>
                </Descriptions.Item>
              </Descriptions>
            </Row>
          </section>

          <section style={{ marginBottom: '48px' }}>
            <Row gutter={[0, 16]}>
              <Col xs={24} sm={10}>
                <Button
                  loading={isSubmitting}
                  type="v2-primary"
                  style={{ marginRight: 8 }}
                  onClick={this.submitStaffRequestAndReroute}
                >
                  {t('I’ve made my bank transfer')}
                </Button>
              </Col>
              <Col xs={24} sm={5}>
                <ReactToPrint content={() => this.componentRef}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <Button type="v2-secondary" onClick={handlePrint}>
                        {t('Export as PDF')}
                      </Button>
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </Col>
            </Row>
          </section>
          <section>
            <PaymentHelp />
          </section>
        </Col>
      </Row>
    );
  }
}

export default PrepaidDetailView;
