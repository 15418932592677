import React, { useState } from 'react';
import { Row } from 'antd';
import { connect } from 'react-redux';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  MeasuringStrategy,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import * as applicationFormActions from '../../../redux/applicationForm';
import QuestionListItemSortable from './QuestionListItemSortable';
import QuestionListItem from './QuestionListItem';

const QuestionList = ({ questions = [], editable = true, sortable = true, reorderSelectedQuestions }) => {
  const [activeDragQuestion, setActiveDragQuestion] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = ({ active }) => {
    if (!active) {
      return;
    }
    setActiveDragQuestion(active.data.current.question);
  };

  const handleDragEnd = ({ active, over }) => {
    setActiveDragQuestion(null);
    if (over && active.id !== over?.id) {
      const activeIndex = active.data.current.index;
      const overIndex = over.data.current.index;
      reorderSelectedQuestions({ activeIndex, overIndex });
    }
  };

  const items = questions.map(question => question.key);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      modifiers={[restrictToFirstScrollableAncestor, restrictToVerticalAxis]}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy} disabled={!sortable}>
        <Row>
          {questions.map((question, index) => (
            <QuestionListItemSortable
              key={question.key}
              sortableId={question.key}
              index={index}
              question={question}
              editable={editable}
              sortable={sortable}
              style={{ marginBottom: 16, width: '100%' }}
            />
          ))}
        </Row>
      </SortableContext>
      <DragOverlay modifiers={[restrictToFirstScrollableAncestor, restrictToVerticalAxis]}>
        {activeDragQuestion ? (
          <QuestionListItem question={activeDragQuestion} sortable style={{ width: '100%' }} />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

const mapDispatchToProps = dispatch => {
  const { reorderSelectedQuestions } = applicationFormActions;
  return {
    reorderSelectedQuestions: payload => {
      dispatch(reorderSelectedQuestions(payload));
    },
  };
};

export default connect(undefined, mapDispatchToProps)(QuestionList);
