import React, { useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Row, Col, Typography, Card, Avatar, Tooltip, List, Button } from 'antd';
import { ProfileOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import { colors } from '../../../styles/colors';

const { Text, Title } = Typography;

const MINIMUM_LIST_LENGTH = 3;

const ExperienceCard = ({ workmateExperience, otherWorkExperience, t }) => {
  const allExperiences = [...workmateExperience, ...otherWorkExperience];
  const [expanded, setExpansion] = useState(false);
  const [list, setList] = useState(allExperiences.slice(0, MINIMUM_LIST_LENGTH));

  const totalExperiences = allExperiences.length;
  const totalWorkmateExperiences = workmateExperience.length;
  const totalOtherWorkExperiences = otherWorkExperience.length;

  return (
    <Card bodyStyle={{ padding: 0 }} bordered={false}>
      <Row type="flex" justify="start" align="middle" style={{ marginBottom: '24px' }}>
        <Title
          style={{
            fontSize: '20px',
            lineHeight: '28px',
            fontWeight: 600,
            color: colors.textTitle,
            marginBottom: 0,
            marginRight: 8,
          }}
        >
          {t('experience')}
        </Title>
      </Row>
      <Row>
        <Col span={24}>
          <List
            itemLayout="horizontal"
            dataSource={list}
            loadMore={
              totalExperiences > MINIMUM_LIST_LENGTH && (
                <Row type="flex" justify="space-between">
                  <Col>
                    <Button
                      type="link"
                      style={{ marginTop: 0, padding: 0, fontWeight: 600, color: colors.infoHighlightBlue }}
                      onClick={() => {
                        setExpansion(!expanded);
                        setList(expanded ? allExperiences.slice(0, MINIMUM_LIST_LENGTH) : allExperiences);
                      }}
                    >
                      {expanded ? t('showLessWorkExperience') : t('showMoreWorkExperience')}
                      <LegacyIcon type={expanded ? 'up' : 'down'} />
                    </Button>
                  </Col>
                </Row>
              )
            }
            renderItem={(item, index) => {
              return (
                <>
                  {/* header for workmate verified experiences */}
                  {totalWorkmateExperiences > 0 && index === 0 && (
                    <Row type="flex" align="middle" style={{ marginBottom: '8px' }}>
                      <Tooltip title={t('verifiedExperienceTooltip')}>
                        <SafetyCertificateOutlined
                          style={{ color: colors.disabled, fontSize: '14px', marginRight: 8 }}
                        />
                      </Tooltip>
                      <Title
                        style={{
                          fontSize: '14px',
                          lineHeight: '22px',
                          fontWeight: 600,
                          color: colors.textTitle,
                          marginBottom: 0,
                        }}
                      >
                        {t('verifiedWorkmateExperience').toUpperCase()}
                      </Title>
                    </Row>
                  )}
                  {/* header for worker provided experiences */}
                  {totalOtherWorkExperiences > 0 && index === totalWorkmateExperiences && (
                    <Row
                      type="flex"
                      align="middle"
                      style={{ marginBottom: '8px', marginTop: workmateExperience.length > 0 ? '24px' : 0 }}
                    >
                      <Tooltip title={t('providedExperienceTooltip')}>
                        <ProfileOutlined style={{ color: colors.disabled, fontSize: '14px', marginRight: 8 }} />
                      </Tooltip>
                      <Title
                        style={{
                          fontSize: '14px',
                          lineHeight: '22px',
                          fontWeight: 600,
                          color: colors.textTitle,
                          marginBottom: 0,
                        }}
                      >
                        {t('providedWorkExperience').toUpperCase()}
                      </Title>
                    </Row>
                  )}
                  {/* experience item */}
                  <List.Item key={item.id} className="no-border" style={{ padding: '16px 0' }}>
                    <List.Item.Meta
                      avatar={<Avatar shape="square" size={48} style={{ height: '100%' }} src={item.imgSrc} />}
                      title={
                        <Text strong style={{ color: colors.textTitle }}>
                          {item.title}
                        </Text>
                      }
                      description={
                        <>
                          <Text strong style={{ color: colors.textTitle }}>
                            {item.subTitle}
                          </Text>
                          <br />
                          <Text style={{ color: colors.primaryText }}>{item.period}</Text>
                          <br />
                          <Text style={{ color: colors.secondaryText, fontSize: '12px' }}>{item?.description}</Text>
                        </>
                      }
                    />
                  </List.Item>
                </>
              );
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(ExperienceCard);
