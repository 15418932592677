import React from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Card, Empty, List, Typography, Row, Col, Dropdown, Menu } from 'antd';
import { sortBy } from 'lodash';
import LocationFormModal from './components/LocationFormModal';

// Taken from V1 folder
import Address from '../../../shared/components/Address';
import Maps from '../../../shared/components/Maps';

const { Title, Text } = Typography;

class LocationSection extends React.Component {
  state = {
    locationModalOpen: false,
  };

  render() {
    const { client, fetchClientAndManager, isAdmin, t } = this.props;
    const { locationModalOpen, location } = this.state;
    const { locations } = client;

    // Sort locations alphabetically
    const locationOptions = sortBy(locations, option => option.name);

    return (
      <>
        <Row style={{ marginTop: '14px', marginBottom: '16px' }}>
          <Col>
            <Title level={3}>{t('Business locations')}</Title>
            <Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
              {t('Add business locations to create staff requests for each location.')}
            </Text>
          </Col>
        </Row>
        <Row style={{ marginBottom: '32px' }}>
          <Button
            disabled={!isAdmin}
            onClick={() => {
              this.setState({ locationModalOpen: true, location: null });
            }}
          >
            {t('Add location')}
          </Button>
        </Row>
        {locationOptions && locationOptions.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={<span>{t('Please add a location')}</span>} />
        ) : (
          <List
            itemLayout="horizontal"
            loading={!locationOptions}
            dataSource={locationOptions || []}
            renderItem={locationItem => (
              <Row
                type="flex"
                justify="space-between"
                style={{ paddingBottom: '24px', paddingTop: '16px', borderBottom: '1px solid #00000017' }}
              >
                <Col xs={24} md={22}>
                  <Card bordered={false} style={{ width: '100%' }} bodyStyle={{ padding: 0 }}>
                    <Row type="flex" gutter={24}>
                      <Col span={6}>
                        <Maps
                          centerPosition={{
                            lat: parseFloat(locationItem.address?.latitude || 0),
                            lng: parseFloat(locationItem.address?.longitude || 0),
                          }}
                          markerPositions={[
                            {
                              lat: parseFloat(locationItem.address?.latitude || 0),
                              lng: parseFloat(locationItem.address?.longitude || 0),
                            },
                          ]}
                          zoom={15}
                          style={{ height: '128px', width: '100%', zIndex: 0 }}
                          scrollWheelZoom={false}
                        />
                      </Col>
                      <Col xs={24} md={18}>
                        <Row type="flex" style={{ marginBottom: '-4px' }}>
                          <Title strong level={4}>
                            {locationItem.name}
                          </Title>
                        </Row>
                        <Row
                          style={{ marginBottom: '8px', fontSize: '12px', wordWrap: 'break-word', maxWidth: '360px' }}
                          ellipsis={{ rows: 2, expandable: false }}
                        >
                          <Text>
                            {locationItem.landmark}
                            <Address address={locationItem.address} />
                          </Text>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={2} style={{ textAlign: 'right' }}>
                  <Dropdown
                    trigger={['click']}
                    overlay={
                      <Menu style={{ width: '120px' }}>
                        <Menu.Item
                          disabled={!isAdmin}
                          onClick={() => {
                            this.setState({ locationModalOpen: true, location: locationItem });
                          }}
                        >
                          {t('Edit')}
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button icon={<EllipsisOutlined />} />
                  </Dropdown>
                </Col>
              </Row>
            )}
          />
        )}
        {locationModalOpen && (
          <LocationFormModal
            clientId={client.clientId}
            country={client.country}
            onUpdate={fetchClientAndManager}
            location={location} // Does not have to be explicitly set in "this.state". Handled by Antd.
            visible={locationModalOpen}
            onClose={() => this.setState({ locationModalOpen: false })}
          />
        )}
      </>
    );
  }
}

export default LocationSection;
