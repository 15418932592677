import React from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';
import { intersectionBy, isEqual } from 'lodash';
import datetimeUtils from '../../../utilities/datetimeUtils';
import attendanceUtils from '../../../utilities/attendanceUtils';
import { getCellAttributes, getCellContent, getToolTipTitle } from '../utils/assignmentCellUtils';
import AssignShiftPopover from './AssignShiftPopover';
import { colors } from '../../../styles/colors';

class AssignmentTableCell extends React.Component {
  handleAssignmentChange = targetRoleId => {
    const { scheduleId } = this.props;
    const { date, attendance, shifts } = this.props.assignment;
    const targetShiftId = shifts[0].id;
    this.props.onAssignmentUpdate([
      {
        date,
        targetShiftId: attendanceUtils.isAssigned(attendance) ? undefined : targetShiftId,
        workerId: this.props.worker.id,
        scheduleId,
        targetRoleId,
      },
    ]);
  };

  handleRoleChange = roleId => {
    const { scheduleId } = this.props;
    const { date, shifts } = this.props.assignment;
    this.props.onRoleUpdate([
      {
        date,
        targetShiftId: shifts[0].id,
        workerId: this.props.worker.id,
        scheduleId,
        targetRoleId: roleId,
      },
    ]);
  };

  getAssignableRoles = () => {
    const { assignment, employmentRoles } = this.props;
    const { shifts } = assignment;
    const shiftRoles = shifts[0]?.shift_roles;
    // To get the roles a worker can be assigned to, we get the intersection of the active shift roles and assigned employment roles for the worker
    const activeShiftRoles = shiftRoles?.filter(shiftRole => shiftRole.role.is_active && shiftRole.staff_required > 0);
    const assignedEmploymentRoles = employmentRoles.filter(employmentRole => employmentRole.is_assigned);
    // If worker has no roles assigned to it, we return all the active shift roles
    const assignableRoles =
      assignedEmploymentRoles.length === 0
        ? activeShiftRoles
        : intersectionBy(activeShiftRoles, assignedEmploymentRoles, 'role.id');
    return assignableRoles;
  };

  render() {
    const { assignment, timezone, t, worker, isMonthlyView } = this.props;
    const { attendance, shifts, contractStatus, date, originalAttendance } = assignment;
    const isEndOfWeek = moment(date).format('ddd') === 'Sun';
    const updated = !isEqual(attendance, originalAttendance);
    const assignableRoles = this.getAssignableRoles();
    /*
      A worker can be assigned to a particular shift if:
      There are assignable roles if it is a shift with role or the shift has staff_required > 0
    */
    const isAssignable =
      shifts[0]?.shift_roles.length > 0 ? assignableRoles?.length > 0 : shifts[0]?.staff_required > 0;
    const dayStart = datetimeUtils.getDayStart(moment(), timezone);

    const assignmentDisabled =
      attendanceUtils.isAbsent(attendance) ||
      moment(assignment.date).isBefore(dayStart) ||
      !attendanceUtils.isValidWorkingDay(contractStatus) ||
      !shifts.length ||
      !isAssignable;
    const cellAttributes = getCellAttributes(assignment, timezone);
    const cellBackgroundStyle = {
      display: 'flex',
      height: 50,
      width: '100%',
      backgroundColor: cellAttributes.backgroundColor,
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: isEndOfWeek && `1px solid ${colors.grey}`,
      fontSize: isMonthlyView ? 12 : 14,
    };

    if (assignmentDisabled) {
      const tooltipTitle = getToolTipTitle(assignment, timezone, t);
      return (
        <>
          {tooltipTitle ? (
            <Tooltip title={tooltipTitle}>
              <div style={{ ...cellBackgroundStyle, cursor: 'initial' }}>
                {getCellContent(assignment, timezone, t, isMonthlyView)}
              </div>
            </Tooltip>
          ) : (
            <div style={{ ...cellBackgroundStyle, cursor: 'initial' }}>
              {getCellContent(assignment, timezone, t, isMonthlyView)}
            </div>
          )}
        </>
      );
    }

    return (
      <AssignShiftPopover
        worker={worker}
        date={date}
        onAssignmentChange={this.handleAssignmentChange}
        onRoleChange={this.handleRoleChange}
        shiftRoles={shifts[0].shift_roles}
        checked={attendanceUtils.isAssigned(attendance)}
        roleId={attendance?.role_id}
        assignableRoles={assignableRoles}
      >
        <div className={updated ? 'unpublished-cell-border' : undefined}>
          <div style={cellBackgroundStyle}>{getCellContent(assignment, timezone, t, isMonthlyView)}</div>
        </div>
      </AssignShiftPopover>
    );
  }
}

export default withTranslation()(AssignmentTableCell);
