import React from 'react';
import { Tag, Tooltip, Typography } from 'antd';
import { camelCase } from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { applicationStatuses, autoRejectStatuses, autoWithdrawnStatuses, employmentStatuses } from '../../../constants';

const { Text } = Typography;
const statusCallbacks = {};
const statusTextCallbacks = {};

statusCallbacks[applicationStatuses.APPLIED] = (t, application) => {
  let statusText = t(application.status);

  if (application.interviewed) {
    statusText = t('interview');
  }

  if (application.shortlisted) {
    statusText = t('waitlisted');
  }

  return <Tag color="gold">{statusText}</Tag>;
};

statusCallbacks[applicationStatuses.PENDING_CONTRACT] = t => {
  return <Tag color="blue">{t('offerSent')}</Tag>;
};

statusCallbacks[applicationStatuses.HIRED] = (t, application) => {
  if (application.employmentStatus === employmentStatuses.ACTIVE) {
    return <Tag color="orange">{t('currentlyEmployed')}</Tag>;
  }

  return <Tag color="green">{t(application.status)}</Tag>;
};

statusCallbacks[applicationStatuses.REJECTED] = (t, application) => {
  const rejectionReasonCode = application?.reject_reason?.code || application?.rejection_reason;
  if ([autoRejectStatuses.failedToSignContract, autoRejectStatuses.applicationExpired].includes(rejectionReasonCode)) {
    return <Tag>{t('withdrawn')}</Tag>;
  }
  return <Tag color="red">{t(application.status)}</Tag>;
};

statusCallbacks[applicationStatuses.WITHDRAWN] = (t, application) => {
  return <Tag>{t(application.status)}</Tag>;
};

statusTextCallbacks[applicationStatuses.REJECTED] = (t, application) => {
  let reason = '';
  const rejectionReasonCode = application?.reject_reason?.code || application?.rejection_reason;
  if (rejectionReasonCode) {
    if (autoRejectStatuses.failedToSignContract === rejectionReasonCode) {
      reason = t('workerDidNotAcceptOffer');
    } else if (autoRejectStatuses.applicationExpired === rejectionReasonCode) {
      reason = t('noResponseFromClient', { clientName: application.staff_request.client.name });
    } else {
      reason = t(camelCase(rejectionReasonCode));
    }

    return (
      <Text type="secondary">
        {reason}
        {application.notes && ` · ${application.notes}`}
      </Text>
    );
  }
};

statusTextCallbacks[applicationStatuses.WITHDRAWN] = (t, application) => {
  if (application.withdrawn_reason) {
    if (autoWithdrawnStatuses.jobPostClosedByClient === application.withdrawn_reason.code) {
      return (
        <Text type="secondary">
          {t('jobPostClosedByClient', { clientName: application.staff_request.client.name })}
        </Text>
      );
    }
    return <Text type="secondary">{application.withdrawn_reason.name}</Text>;
  }
};

const ApplicationStatusTag = ({ t, application, style }) => {
  let reason;

  if (application.status in statusTextCallbacks) {
    reason = statusTextCallbacks[application.status](t, application);
  }

  return (
    <div style={{ ...style }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          {application.status in statusCallbacks && statusCallbacks[application.status](t, application)}
          <Tooltip placement="top" title={moment(application.modified_date).format('ddd, D MMM YYYY, h:mm a')}>
            <Text type="secondary">{moment(application.modified_date).fromNow()}</Text>
          </Tooltip>
        </div>
        <div style={{ marginTop: 4 }}>
          {reason && (
            <>
              {t('reason')}: {reason}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ApplicationStatusTag);
