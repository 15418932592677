import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form as FormCompatible } from '@ant-design/compatible';
import { Form, Row, Col, Radio, Typography, Card, Checkbox, InputNumber } from 'antd';
import { DEFAULT_CLOCKIN_RADIUS_IN_METERS, clockinMethods } from '../../constants';
import { colors } from '../../styles/colors';

const { Item } = Form;

const ClockinMethodFormItems = ({
  getFieldDecorator,
  getFieldValue,
  setFieldsValue,
  initialValues = {},
  showMaxClockinRadius = false,
  onChange = null,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Item style={{ marginBottom: '4px' }}>
        {getFieldDecorator('clockin_method', {
          initialValue: initialValues?.clockin_method,
        })(
          <Radio.Group
            style={{ width: '100%' }}
            onChange={e => {
              const selectedClockinMethod = e.target.value;
              // eslint-disable-next-line no-unused-expressions
              onChange && onChange(selectedClockinMethod, 'clockin_method');
              if (selectedClockinMethod === clockinMethods.QR_SCAN_ONLY) {
                setFieldsValue({ fixed_location: false });
                // eslint-disable-next-line no-unused-expressions
                onChange && onChange(false, 'fixed_location');
              }
            }}
          >
            <Card
              style={{
                marginBottom: 16,
                borderColor:
                  getFieldValue('clockin_method') === clockinMethods.QR_SCAN_ONLY
                    ? colors.functionalLink
                    : colors.backgroundGrey,
              }}
            >
              <Radio value={clockinMethods.QR_SCAN_ONLY}>
                <Typography.Text style={{ display: 'block', marginBottom: 8 }}>
                  {t('clockinMethodQrScanOnly')}
                </Typography.Text>
                <Typography.Text style={{ color: colors.secondaryText }}>
                  {t('clockinMethodQrScanOnlyDesc')}
                </Typography.Text>
              </Radio>
            </Card>
            <Card
              style={{
                borderColor:
                  getFieldValue('clockin_method') === clockinMethods.QR_SCAN_OR_SELFIE
                    ? colors.functionalLink
                    : colors.backgroundGrey,
              }}
            >
              <Radio value={clockinMethods.QR_SCAN_OR_SELFIE}>
                <Typography.Text style={{ display: 'block', marginBottom: 8 }}>
                  {t('clockinMethodQrScanWithSelfie')}
                </Typography.Text>
                <Typography.Text style={{ color: colors.secondaryText }}>
                  <Trans i18nKey="clockinMethodQrScanWithSelfieDesc" />
                </Typography.Text>
              </Radio>

              <Row style={{ marginLeft: 22, marginTop: 8 }}>
                <Item style={{ marginBottom: '4px' }}>
                  {getFieldDecorator('fixed_location', {
                    initialValue: Boolean(initialValues?.fixed_location),
                  })(
                    <Checkbox
                      checked={getFieldValue('fixed_location')}
                      disabled={getFieldValue('clockin_method') === clockinMethods.QR_SCAN_ONLY}
                      onChange={e => {
                        if (onChange) {
                          onChange(!e.target.value, 'fixed_location');
                        }
                      }}
                    >
                      {t(showMaxClockinRadius ? 'clockinMethodDisputeWithRadius' : 'clockinMethodDispute')}
                    </Checkbox>,
                  )}
                </Item>
              </Row>
              {showMaxClockinRadius && (
                <Row style={{ marginTop: 8 }}>
                  <Col offset={3}>
                    <FormCompatible.Item>
                      {getFieldDecorator('maximum_clock_in_radius_in_meters', {
                        initialValue:
                          initialValues?.maximum_clock_in_radius_in_meters || DEFAULT_CLOCKIN_RADIUS_IN_METERS,
                        rules: [
                          {
                            validator(rule, value, callback) {
                              const isActive =
                                getFieldValue('clockin_method') === clockinMethods.QR_SCAN_OR_SELFIE &&
                                getFieldValue('fixed_location');

                              if (isActive && (value < 50 || value > 1000)) {
                                callback(t('clockInRadiusMeterErrorMsg'));
                              } else {
                                callback();
                              }
                            },
                          },
                        ],
                      })(
                        <InputNumber
                          style={{ width: '70%' }}
                          addonAfter={t('meters')}
                          disabled={getFieldValue('clockin_method') === clockinMethods.QR_SCAN_ONLY}
                        />,
                      )}
                    </FormCompatible.Item>
                  </Col>
                </Row>
              )}
            </Card>
          </Radio.Group>,
        )}
      </Item>
    </>
  );
};

export default ClockinMethodFormItems;
