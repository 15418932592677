import React from 'react';
import { Button, Col, Divider, Popover, Row, Typography, InputNumber } from 'antd';
import { withTranslation } from 'react-i18next';
import { CaretDownFilled } from '@ant-design/icons';
import { colors } from '../../styles/colors';

const { Text } = Typography;

class DistancePopoverFilter extends React.Component {
  state = {
    selectedDistance: this.props.selectedDistance,
    visible: false,
  };

  componentDidUpdate(prevProps) {
    // if selectedDistance is empty, reset the filter component
    if (this.props.selectedDistance !== prevProps.selectedDistance) {
      if (!this.props.selectedDistance) {
        this.onReset();
      }
    }
  }

  onReset = () => {
    this.setState({
      selectedDistance: 0,
    });
  };

  onApply = () => {
    const { selectedDistance } = this.state;
    const { onApply } = this.props;
    this.setState(
      {
        visible: false,
      },
      () => onApply && onApply(selectedDistance),
    );
  };

  onVisibleChange = visible => {
    this.setState({ visible }, () => !visible && this.resetChanges());
  };

  resetChanges = () => {
    const { selectedDistance } = this.props;
    this.setState({ selectedDistance });
  };

  onDistanceFilterOnChange = selectedDistance => {
    this.setState({ selectedDistance });
  };

  render() {
    const { label, isDisabled = false, t } = this.props;
    const { visible, selectedDistance } = this.state;

    const popoverTrigger = isDisabled ? 'focus' : 'click';

    return (
      <Popover
        visible={visible}
        trigger={popoverTrigger}
        placement="bottomLeft"
        onVisibleChange={this.onVisibleChange}
        overlayStyle={{ zIndex: 2 }}
        overlayClassName="ant-popover-wrapper tree-checkbox-filter"
        style={{ width: '150px' }}
        content={
          <>
            <Row style={{ padding: '12px 8px' }}>
              <Text style={{ lineHeight: '32px', marginRight: '8px' }}>{t('Within')}</Text>{' '}
              <InputNumber
                min={0}
                max={1000}
                defaultValue={selectedDistance}
                value={selectedDistance}
                onChange={this.onDistanceFilterOnChange}
                placeholder={0}
              />{' '}
              <Text style={{ lineHeight: '32px', marginLeft: '8px' }}>{t('km')}</Text>
            </Row>
            <Row type="flex" justify="space-between" style={{ padding: '4px 8px' }}>
              <Divider style={{ margin: '12px 0' }} />
              <Col>
                <Button size="small" onClick={this.onReset}>
                  {t('Reset')}
                </Button>
              </Col>
              <Col>
                <Button size="small" className="ant-btn-v2-primary" onClick={this.onApply}>
                  {t('apply')}
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Button
          onClick={this.toggleVisiblity}
          disabled={isDisabled}
          style={
            selectedDistance > 0
              ? {
                  borderColor: colors.blue,
                  backgroundColor: colors.white,
                  color: colors.blue,
                  zIndex: 1,
                  marginRight: 12,
                }
              : { marginRight: 12 }
          }
        >
          {label}
          {selectedDistance > 0 ? ' (1)' : ''}
          <CaretDownFilled />
        </Button>
      </Popover>
    );
  }
}

export default withTranslation()(DistancePopoverFilter);
