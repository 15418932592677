import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Typography, Select, message, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import routes from '../../../routes';
import { settingsTabs } from '../../../constants';

class LocationPositionRoleUpdateFormModal extends React.Component {
  state = {
    loading: false,
    selectedPosition: undefined,
    positionRoles: [],
  };

  handlePositionSelect = positionId => {
    const { form, roles } = this.props;
    const positionRoles = roles.filter(role => role.position.id === parseInt(positionId, 10));
    if (positionId !== this.state.selectedPosition) {
      this.setState({
        selectedPosition: positionId,
        positionRoles,
      });
      form.setFieldsValue({
        roleId: undefined,
      });
    }
  };

  handleSubmit = () => {
    const { t, form, onConfirm } = this.props;
    form.validateFieldsAndScroll(async (formErrors, values) => {
      if (!formErrors) {
        this.setState({ loading: true });
        try {
          const selectedLocation = values.locationId;
          const selectedPosition = values.positionId;
          const selectedRoles = values.roleId;
          onConfirm(selectedLocation, selectedPosition, selectedRoles);
          this.handleClose();
        } catch (error) {
          message.error(t('commonErrorMessage'), [2]);
        } finally {
          this.setState({ loading: false });
        }
      }
    });
  };

  handleClose = () => {
    this.setState({
      selectedPosition: undefined,
      positionRoles: [],
    });
    this.props.form.resetFields();
    this.props.closeModal();
  };

  render() {
    const { t, visible, locations, positions } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { loading, selectedPosition, positionRoles } = this.state;
    const noPositionRoles = positionRoles?.length === 0;
    const noRolesSelected = !getFieldValue('roleId') || getFieldValue('roleId').length === 0;

    return (
      <Modal
        width={720}
        destroyOnClose
        visible={visible}
        okText={t('assign')}
        okType="v2-primary"
        cancelText={t('cancel')}
        title={t('setLocationPositionRoleModalTitle')}
        onOk={this.handleSubmit}
        onCancel={this.handleClose}
        confirmLoading={loading}
      >
        <Form onSubmit={this.handleSubmit} hideRequiredMark>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 16 }}>
            <Typography.Text style={{ fontWeight: 600 }}>{t('assignYourWorkers')}</Typography.Text>
            <Typography.Text>{t('assignYourWorkersDescription')}</Typography.Text>
          </div>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item label={t('location')} className="v2-form-wrapper" labelCol={{ span: 24 }}>
                {getFieldDecorator('locationId', {
                  initialValue: undefined,
                })(
                  <Select placeholder={t('selectLocationPlaceholder')}>
                    {locations.map(location => (
                      <Select.Option key={location.id}>{location.name}</Select.Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item label={t('position')} className="v2-form-wrapper" labelCol={{ span: 24 }}>
                {getFieldDecorator('positionId', {
                  initialValue: undefined,
                })(
                  <Select placeholder={t('selectPositionPlaceholder')} onSelect={this.handlePositionSelect}>
                    {positions.map(position => (
                      <Select.Option key={position.id}>{position.name}</Select.Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('role')} className="v2-form-wrapper" labelCol={{ span: 24 }}>
                {getFieldDecorator('roleId', {
                  rules: [],
                  initialValue: undefined,
                })(
                  <Select
                    mode="multiple"
                    disabled={noPositionRoles}
                    placeholder={t('selectRolesPlaceholder')}
                    optionFilterProp="label"
                  >
                    {positionRoles.map(role => (
                      <Select.Option key={role.id} label={role.name} value={role.id}>
                        {role.name}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
                {selectedPosition && noPositionRoles && (
                  <div style={{ marginTop: 8 }}>
                    <Typography.Text type="secondary">
                      <Trans i18nKey="noRolesCreatedForThisPositionNote">
                        You have no roles created for this position. If you want to assign workers into specific roles,
                        you can <Link to={`${routes.settings.replace(':tab', settingsTabs.ROLES)}`}>add</Link> them on
                        the settings page.
                      </Trans>
                    </Typography.Text>
                  </div>
                )}
                {selectedPosition && noRolesSelected && !noPositionRoles && (
                  <div style={{ marginTop: 8 }}>
                    <Typography.Text type="secondary">{t('rolesLeftBlankWillBeUnassignedNote')}</Typography.Text>
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  locations: state.user.locations || [],
  positions: state.global.positions,
  roles: state.user.roles,
});

export default connect(
  mapStateToProps,
  undefined,
)(Form.create()(withTranslation()(LocationPositionRoleUpdateFormModal)));
