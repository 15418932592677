import moment from 'moment-timezone';
import { groupBy } from 'lodash';
import { TIME_FORMAT_24_HOUR_WITH_SECONDS, TIME_FORMAT_24_HOUR, sourceTypes } from '../constants';
import { SERVER_DATE } from '../styles/dates';
import dateUtils from './dateUtils';

const getActualShiftStartAndEndTime = ({ shiftDates, startTime, endTime, isCrossShift }) => {
  const startDate = moment(shiftDates[0]);
  const endDate = isCrossShift
    ? moment(shiftDates[shiftDates.length - 1]).add(1, 'days')
    : moment(shiftDates[shiftDates.length - 1]);

  const startDateWithTime = `${startDate?.format(SERVER_DATE)} ${moment(startTime).format(
    TIME_FORMAT_24_HOUR_WITH_SECONDS,
  )}`;
  const endDateWithTime = `${endDate?.format(SERVER_DATE)} ${moment(endTime).format(TIME_FORMAT_24_HOUR_WITH_SECONDS)}`;
  const shift_start_time = moment(startDateWithTime).toISOString();
  const shift_end_time = moment(endDateWithTime).toISOString();
  return {
    shift_start_time,
    shift_end_time,
  };
};

const getGigParams = ({ fields, selectedDates, clientId, isCrossShift }) => {
  const shiftDates = dateUtils.sortDatesByEarliest(selectedDates);
  const { shift_start_time, shift_end_time } = getActualShiftStartAndEndTime({
    isCrossShift,
    shiftDates,
    startTime: fields.start_time,
    endTime: fields.end_time,
  });
  const params = {
    staff_request_template: fields.template?.id,
    staff_required: fields.staff_required,
    unpaid_break: fields.unpaid_break,
    break_duration_in_minutes: fields.break_duration_in_minutes,
    begin_time: moment(fields.start_time).format(TIME_FORMAT_24_HOUR),
    finish_time: moment(fields.end_time).format(TIME_FORMAT_24_HOUR),
    start_time: shift_start_time,
    end_time: shift_end_time,
    working_days: dateUtils.shiftDatesToWorkingDays(selectedDates),
    shift_dates: shiftDates.map(date =>
      moment(`${date} ${fields.start_time.format(TIME_FORMAT_24_HOUR)}`).toISOString(),
    ),
    source: sourceTypes.WEB_CLIENT_PORTAL,
    location_id: fields.location.id,
    client_id: clientId,
    wage_amount: fields.wage_amount,
    vip_workers_only: fields.vip_workers_only,
  };

  return params;
};

const groupShiftsByDate = shifts => {
  const getDateFromDatetime = time => moment(time).format('ddd, D MMM YYYY');
  const shiftListGroupedByDate = groupBy(shifts, shift => getDateFromDatetime(shift.startTime));
  return Object.entries(shiftListGroupedByDate).map(([date, data]) => ({
    title: date,
    data,
  }));
};

const getShiftDurationWithBreaks = ({ hours, minutes, breakDuration, unpaidBreak }) => {
  const totalShiftDurationInMinutes = hours * 60 + minutes || 0;
  let shiftDurationWithBreaks = totalShiftDurationInMinutes;
  if (breakDuration && unpaidBreak) {
    shiftDurationWithBreaks -= breakDuration;
  }
  return shiftDurationWithBreaks;
};

export default {
  getActualShiftStartAndEndTime,
  getGigParams,
  groupShiftsByDate,
  getShiftDurationWithBreaks,
};
