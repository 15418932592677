import React from 'react';

const SquareIcon = ({ color, ...props }) => (
  <span
    style={{
      display: 'inline-block',
      height: 12,
      width: 12,
      backgroundColor: color,
      border: `1 solid ${color}`,
      ...props,
    }}
  />
);

export default SquareIcon;
