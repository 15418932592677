import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, message, Typography } from 'antd';
import { withTranslation, Trans } from 'react-i18next';
import { size } from 'lodash';

// Taken from V1 folders
import clientApi from '../../../../services/clientApi';

const DeleteCategoryFormModal = ({ t, clientId, category, visible, onClose, onUpdate, form }) => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteCategory = async () => {
    // Removing roles by setting is_active to false, not deleting
    await clientApi.editTimesheetTagCategory(clientId, {
      id: category.id,
      is_active: false,
    });
    onUpdate();
    onClose();
    message.info(t('categoryRemovedSucessMessage'));
  };

  const onSubmit = () => {
    form.validateFieldsAndScroll(async formErrors => {
      if (!formErrors) {
        setIsLoading(true);
        try {
          await deleteCategory();
          setIsLoading(false);
        } catch (error) {
          message.warning(t('submitFailed'));
          setIsLoading(false);
        }
      }
    });
  };

  return (
    <Modal
      destroyOnClose
      visible={visible}
      style={{ top: '20px' }}
      okText={t('yesDelete')}
      okType="danger"
      cancelText={t('cancel')}
      title={t('deleteCategory')}
      onOk={onSubmit}
      onCancel={onClose}
      confirmLoading={isLoading}
    >
      <Form hideRequiredMark layout="vertical" onSubmit={onSubmit}>
        <Typography.Text>
          <Trans
            i18nKey="removeTagCategoryConfirmationMessage"
            values={{ categoryName: category.name, numberOfTags: size(category.tags) }}
          />{' '}
        </Typography.Text>
      </Form>
    </Modal>
  );
};

export default Form.create()(withTranslation()(DeleteCategoryFormModal));
