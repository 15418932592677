import queryString from 'query-string';
import base from './base';
import { endpoints, readonlyEndpoints } from '../config';

const fetchStaffRequestSummarysWithTasks = async params => {
  const response = await base.api.get(`${readonlyEndpoints.staffRequests}/summary-with-tasks/`, { params });
  return response;
};

const clockInPartner = async (srId, partnerId) => {
  const response = await base.api.post(`${endpoints.staffRequests}/${srId}/clock-in/`, {
    partner: partnerId,
  });
  return response;
};

const clockOutPartner = async (srId, partnerId) => {
  const response = await base.api.post(`${endpoints.staffRequests}/${srId}/clock-out/`, {
    partner: partnerId,
  });
  return response;
};

const fetchPotentialMatches = async params => {
  const response = await base.api.get(`${readonlyEndpoints.staffRequests}/potential-matches/`, { params });
  return response;
};

const fetchStaffRequests = async params => {
  const response = await base.api.get(`${endpoints.staffRequests}/`, { params });
  return response;
};

const fetchStaffRequestById = async id => {
  const response = await base.api.get(`${endpoints.staffRequests}/${id}/`);
  return response;
};

const fetchTimesheetEntriesByStaffRequestId = async (id, params) => {
  const query = queryString.stringify(params);
  const response = await base.api.get(`${endpoints.staffRequests}/${id}/timesheet-entries/?${query}`);
  return response;
};

const createStaffRequest = async staffRequest => {
  const response = await base.api.post(`${endpoints.staffRequests}/`, staffRequest);
  return response;
};

const submitStaffRequest = async id => {
  const response = await base.api.post(`${endpoints.staffRequests}/${id}/submit/`);
  return response;
};

const editStaffRequest = async staffRequest => {
  const response = await base.api.put(`${endpoints.staffRequests}/${staffRequest.id}/`, staffRequest);
  return response;
};

const updateStaffRequest = async (id, fieldsToUpdate) => {
  const response = await base.api.patch(`${endpoints.staffRequests}/${id}/`, fieldsToUpdate);
  return response;
};

const cancelStaffRequest = async id => {
  const response = await base.api.post(`${endpoints.staffRequests}/${id}/cancel/`);
  return response;
};

const exportContracts = async partnerList => {
  const response = await base.api.post(
    `${endpoints.staffRequestContracts}/download-list/`,
    {
      partner_ids: partnerList,
    },
    { responseType: 'blob' },
  );

  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'contracts.zip');
  document.body.appendChild(link);
  link.click();
};

const exportContractForWorker = async (staffRequestId, workerId, workerName) => {
  const response = await base.api.post(
    `${endpoints.staffRequests}/${staffRequestId}/contracts/download-list/`,
    {
      partner_ids: [workerId],
    },
    { responseType: 'blob' },
  );

  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${staffRequestId}_${workerName}__contract.zip`);
  document.body.appendChild(link);
  link.click();
};

const remindWorkersToSignContract = async partnerList => {
  const response = await base.api.post(`${endpoints.staffRequestContracts}/remind-partners/`, {
    partner_ids: partnerList,
  });
  return response;
};

const downloadStaffRequestFormSubmissions = async (staffRequestId, type = 'csv') => {
  const response = await base.api.get(`${endpoints.staffRequests}/screening-questions-answers/`, {
    params: { staff_request: staffRequestId, type },
  });
  return response;
};

const createGig = async params => {
  const response = await base.api.post(`${endpoints.staffRequests}/gigs/`, params);
  return response;
};

const getGigBreakDownCalculation = async params => {
  const response = await base.api.post(`${endpoints.staffRequests}/gig-breakdown-calculation/`, params);
  return response;
};

export default {
  fetchStaffRequestSummarysWithTasks,
  clockInPartner,
  clockOutPartner,
  fetchPotentialMatches,
  fetchStaffRequests,
  fetchStaffRequestById,
  fetchTimesheetEntriesByStaffRequestId,
  createStaffRequest,
  submitStaffRequest,
  editStaffRequest,
  updateStaffRequest,
  cancelStaffRequest,
  exportContracts,
  exportContractForWorker,
  remindWorkersToSignContract,
  downloadStaffRequestFormSubmissions,
  createGig,
  getGigBreakDownCalculation,
};
