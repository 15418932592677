/* eslint-disable no-use-before-define */
import { countryCodeOptions, cardTypes, paymentServices } from '../constants';
import { Omise, Xendit } from '../config';
import clientApi from '../services/clientApi';

// Checks user profile and determine if user has completed his billing info or not.
const hasCompleteBillingInfo = user => {
  // Check company info
  const { legalName, businessNumber, taxRegistrationNumber, billingAddress } = user;
  if (!legalName || !businessNumber || !taxRegistrationNumber || !billingAddress) {
    return false;
  }
  // Check billing contact info
  const { billingContactName, billingContactEmail, billingContactPhone } = user;
  if (!billingContactName || !billingContactEmail || !billingContactPhone) {
    return false;
  }
  // Check billing address info. "street_2" is not checked since it is optional
  const { street_1, province, zip, country } = billingAddress;
  if (!street_1 || !province || !zip || !country || !country.code) {
    return false;
  }
  return true;
};

const handleCreditCardSubmission = async ({
  handleCreditCardError,
  afterSubmitCallback,
  staffRequestId,
  clientId,
  countryCode,
  cardNumber,
  cardHolderName,
  expDate,
  cvc,
}) => {
  const paymentService = getPaymentService(countryCode);
  if (paymentService === paymentServices.OMISE) {
    const card = {
      name: cardHolderName,
      number: cardNumber.replace(/\s/g, ''), // replace all empty spaces
      expiration_month: parseInt(expDate.slice(0, 2), 10), // MM/YY >> 20/26
      expiration_year: parseInt(expDate.slice(3, 5), 10),
      security_code: cvc,
      country: countryCode,
    };
    // Create a card token with Omise server
    Omise.createToken('card', card, async (statusCode, response) => {
      const hasSubmissionError = response.object === 'error' || !response.card.security_code_check;
      if (hasSubmissionError) {
        let errorMessage = 'Security code check failed';
        if (response.object === 'error') {
          errorMessage = response.message;
        }
        handleCreditCardError(errorMessage);
        return;
      }

      if (statusCode !== 200) {
        handleCreditCardError('Omise server error');
        return;
      }

      const cardToken = response.id;
      // Save created card token from Omise in our Database for charging
      await saveCreditCard({
        clientId,
        cardToken,
        cardNumber,
        cardHolderName,
        expMonth: parseInt(expDate.slice(0, 2), 10),
        expYear: parseInt(expDate.slice(3, 5), 10),
      });
      const chargeResponse = await clientApi.createOTPCharge(clientId, staffRequestId);
      afterSubmitCallback(chargeResponse);
    });
    return;
  }

  // Xendit option (Indonesian clients)
  const card = {
    card_number: cardNumber.replace(/\s/g, ''),
    card_exp_month: expDate.slice(0, 2),
    card_exp_year: `20${expDate.slice(3, 5)}`,
    card_cvn: cvc,
    is_multiple_use: true, // For adhoc charging
    should_authenticate: false, // Temporary. Until next iteration of payments.
  };
  // Create a card token with Xendit server
  Xendit.card.createToken(card, async (error, response) => {
    if (error) {
      handleCreditCardError(error.message);
      return;
    }
    if (response.status === 'FAILED') {
      handleCreditCardError(`Xendit Error ${response.failure_reason || ''}`);
      return;
    }

    if (response.status === 'VERIFIED') {
      const cardToken = response.id;
      // Save created card token from Omise in our Database for charging
      await saveCreditCard({
        clientId,
        cardToken,
        cardNumber,
        cardHolderName,
        expMonth: parseInt(expDate.slice(0, 2), 10),
        expYear: parseInt(expDate.slice(3, 5), 10),
      });
      afterSubmitCallback();
    }
  });
};

const saveCreditCard = async ({ clientId, cardToken, cardNumber, cardHolderName, expMonth, expYear }) => {
  const cardBrand = getCardBrand(cardNumber[0]);
  const lastDigits = cardNumber.replace(/\s/g, '').slice(12);
  // Creates a Omise/Xendit customer & save card token to client model
  const paymentResponse = await clientApi.createPaymentCustomer({
    clientId,
    cardToken,
    lastDigits,
    cardHolderName,
    cardBrand,
    expMonth,
    expYear,
  });
  return paymentResponse;
};

const getPaymentService = countryCode => {
  if (countryCode !== countryCodeOptions.INDONESIA && countryCode !== countryCodeOptions.THAILAND) {
    throw new Error('User country code has no corresponding payment service');
  }
  if (countryCode === countryCodeOptions.INDONESIA) {
    return paymentServices.XENDIT;
  }
  return paymentServices.OMISE;
};

const getCardBrand = digit => {
  switch (digit) {
    case '3':
      return cardTypes.AMERICAN_EXPRESS;
    case '4':
      return cardTypes.VISA;
    case '5':
      return cardTypes.MASTERCARD;
    case '6':
      return cardTypes.DISCOVER;
    default:
      return 'Unknown';
  }
};

export default { hasCompleteBillingInfo, handleCreditCardSubmission, getPaymentService, getCardBrand };
