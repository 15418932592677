import React from 'react';
import { Row, Col } from 'antd';

import WorkerChurnBarChart from './components/WorkerChurnBarChart';
import WorkerChurnReasonsPieChart from './components/WorkerChurnReasonsPieChart';
import WorkerRecurringPieChart from './components/WorkerRecurringPieChart';
import WorkerLateBarChart from './components/WorkerLateBarChart';
import RetentionRateCountCard from './components/RetentionRateCountCard';
import RecurringWorkerCard from './components/RecurringWorkerCard';

const PerformanceTab = ({ timePeriod, clientId, locationIds, positionIds, granularity }) => {
  return (
    <Row align="top">
      <Col span={24}>
        {' '}
        <Row type="flex" style={{ height: '100%', marginBottom: '16px' }} gutter={16}>
          <Col xs={24} md={12}>
            <RetentionRateCountCard
              timePeriod={timePeriod}
              clientId={clientId}
              locationIds={locationIds}
              positionIds={positionIds}
            />
          </Col>
          <Col xs={24} md={12}>
            <RecurringWorkerCard
              timePeriod={timePeriod}
              clientId={clientId}
              locationIds={locationIds}
              positionIds={positionIds}
            />
          </Col>
        </Row>
        <Row type="flex" justify="center" align="middle" style={{ height: '100%', marginBottom: '34px' }} gutter={16}>
          <Col xs={24} md={12}>
            <WorkerChurnBarChart
              timePeriod={timePeriod}
              granularity={granularity}
              clientId={clientId}
              locationIds={locationIds}
              positionIds={positionIds}
            />
          </Col>
          <Col xs={24} md={12}>
            <WorkerChurnReasonsPieChart
              timePeriod={timePeriod}
              clientId={clientId}
              locationIds={locationIds}
              positionIds={positionIds}
            />
          </Col>
          <Col xs={24} md={12}>
            <WorkerLateBarChart
              timePeriod={timePeriod}
              granularity={granularity}
              clientId={clientId}
              locationIds={locationIds}
              positionIds={positionIds}
            />
          </Col>
          <Col xs={24} md={12}>
            <WorkerRecurringPieChart
              timePeriod={timePeriod}
              granularity={granularity}
              clientId={clientId}
              locationIds={locationIds}
              positionIds={positionIds}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PerformanceTab;
