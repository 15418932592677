import { Typography, Row, message, List, Grid, ConfigProvider, Col, Skeleton } from 'antd';
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { clientStatuses, employmentTypes, jobTypes, shiftStatuses } from '../../../constants';
import withQueryAndGraphQLClient from '../../../graphql/helpers/withQueryAndGraphQLClient';
import { GET_SHIFTS_LIST } from '../../../graphql/queries/shift';
import gigUtils from '../../../utilities/gigUtils';
import GigItem from './components/GigItem';
import { colors } from '../../../styles/colors';
import { retrieveParamsFromUrl, updateUrlWithParams } from '../../../utilities/urlUtils';
import OnboardingModal from '../../../shared/components/OnboardingModal';
import routes from '../../../routes';
import EmptyStaffRequestList from '../components/EmptyStaffRequestList';
import TreeCheckboxFilter from '../../../shared/components/TreeCheckboxFilter';
import EmptySearchResult from '../../../shared/components/EmptySearchResult';
import { userUpdate as userUpdateAction } from '../../../redux/user';
import FirstGigPostedModal from './components/FirstGigPostedModal';

const { Title } = Typography;

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 20;
const { useBreakpoint } = Grid;

const DEFAULT_STATUS_FILTER = [shiftStatuses.IN_PROGRESS, shiftStatuses.POSTED].join(',');

const StaffRequestGigListView = ({
  queryClient,
  graphQLClient,
  history,
  user,
  updateUser,
  onPostJobClick,
  isOnWebView,
  selectedLocationId,
}) => {
  const { location } = history;
  const params = retrieveParamsFromUrl(location.search);
  const initialPage = params.page ? Number(params.page) : DEFAULT_PAGE;
  const initialPageSize = params.page_size ? Number(params.page_size) : DEFAULT_PAGE_SIZE;
  const firstGigPosted = Boolean(params.firstGigPosted);
  const initialStatuses = params?.status ? params.status : DEFAULT_STATUS_FILTER;
  const [queryParams, setQueryParams] = useState({
    page: initialPage,
    pageSize: initialPageSize,
    status: initialStatuses,
  });
  const [shifts, setShifts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const { t } = useTranslation();
  const { xs } = useBreakpoint();

  const fetchGigs = async (currentPage, currentPageSize, variables = {}) => {
    try {
      setLoading(true);
      const results = await queryClient.fetchQuery(['shifts'], () =>
        graphQLClient.request({
          document: GET_SHIFTS_LIST,
          variables: {
            staffRequestEmploymentType: [employmentTypes.GIG.value],
            first: currentPageSize,
            orderBy: ['startTime'],
            offset: currentPageSize * (currentPage - 1),
            location: selectedLocationId ? String(selectedLocationId) : undefined,
            ...variables,
          },
        }),
      );
      if (results?.shifts?.edges) {
        const shiftList = results.shifts.edges.map(shift => ({ ...shift?.node }));
        setShifts(shiftList);
        setTotalCount(results?.shifts?.totalCount || 0);
      }
    } catch (error) {
      Sentry.captureException(error);
      message.error(t('Sorry there is an error'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGigs(initialPage, initialPageSize, { statusIn: initialStatuses.split(',') });
  }, []);

  const handlePaginationChange = (pageNumber, pageSizeNumber) => {
    const newParams = { ...params, tab: jobTypes.GIGS, page: pageNumber, page_size: pageSizeNumber };
    updateUrlWithParams(newParams, history);
    setQueryParams({ ...queryParams, ...newParams });
    const variables = { statusIn: queryParams.status ? queryParams.status.split(',') : [] };
    fetchGigs(pageNumber, pageSizeNumber, variables);
  };

  const handleCloseOnboardingModal = () => {
    updateUser({ status: clientStatuses.ACTIVE });
    history.push(`${routes.staffRequests}?tab=${jobTypes.GIGS}`);
  };

  const handleApplyStatusFilters = values => {
    const variables = { statusIn: values };
    const newParams = { ...params, page: DEFAULT_PAGE, status: values.join(',') };
    updateUrlWithParams(newParams, history);
    setQueryParams({ ...queryParams, ...newParams });
    fetchGigs(DEFAULT_PAGE, DEFAULT_PAGE_SIZE, variables);
  };

  const isSearchApplied = () => {
    const hasStatusFilter = 'status' in params;
    return hasStatusFilter;
  };

  const getEmptyCard = clientType => {
    if (loading) return <></>;

    if (isSearchApplied()) {
      return <EmptySearchResult />;
    }

    return <EmptyStaffRequestList clientType={clientType} onPostJobClick={onPostJobClick} isOnWebView={isOnWebView} />;
  };

  const shiftsByDate = gigUtils.groupShiftsByDate(shifts);

  const statusOptions = [
    { key: shiftStatuses.POSTED, title: t('Posted') },
    { key: shiftStatuses.IN_PROGRESS, title: t('In Progress') },
    { key: shiftStatuses.ENDED, title: t('Ended') },
    { key: shiftStatuses.CANCELLED, title: t('Cancelled') },
  ];

  const { page, pageSize, status } = queryParams;

  return (
    <>
      <Row style={{ margin: isOnWebView ? '0 0 20px 0' : '20px 0', alignItems: 'center' }}>
        <Col style={{ marginRight: 8 }}>{t('filters')}</Col>
        <Col>
          <TreeCheckboxFilter
            label={t('status')}
            placeholder={t('filterByStatusPlaceholder')}
            treeData={statusOptions}
            checkedKeys={status ? status.split(',') : []}
            showActionButtons
            expandable={false}
            fillWidth
            onApply={handleApplyStatusFilters}
          />
        </Col>
      </Row>
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <ConfigProvider renderEmpty={() => getEmptyCard(user.type)}>
          <List
            style={{ margin: xs ? '0px -20px 20px -20px' : 0 }}
            itemLayout="vertical"
            dataSource={shiftsByDate}
            pagination={{
              defaultPageSize: pageSize,
              current: page,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              total: totalCount,
              size: 'large',
              showQuickJumper: true,
              style: { textAlign: xs ? 'center' : 'right', marginTop: 40 },
              responsive: true,
              showLessItems: xs,
              onChange: handlePaginationChange,
            }}
            renderItem={shiftByDate => {
              return (
                <List.Item key={shiftByDate.title} style={{ padding: 0 }}>
                  <Row style={{ backgroundColor: colors.neutralBackground, padding: '8px 16px' }}>
                    <Typography.Text style={{ color: colors.secondaryText, fontWeight: '600' }}>
                      {shiftByDate.title.toUpperCase()}
                    </Typography.Text>
                  </Row>
                  {shiftByDate.data.map((shift, index) => (
                    <GigItem shift={shift} key={shift.id} hasDivider={index < shiftByDate.data.length - 1} />
                  ))}
                </List.Item>
              );
            }}
          />
        </ConfigProvider>
      )}

      {firstGigPosted && !isOnWebView && (
        <OnboardingModal
          showQr
          title={
            <Title level={3} style={{ marginBottom: 4 }}>
              <Trans i18nKey="firstGigPostedTitle">Congratulations, your first gig is posted!</Trans>
            </Title>
          }
          description={t('firstGigPostedDesc')}
          okText={t('done')}
          currentActiveStep={3}
          visible={firstGigPosted}
          onOk={handleCloseOnboardingModal}
          onClose={handleCloseOnboardingModal}
          hasSPVDownloadLink
          cancelButtonProps={{
            style: {
              display: 'none',
            },
          }}
        />
      )}
      {firstGigPosted && isOnWebView && (
        <FirstGigPostedModal visible={firstGigPosted} onClose={handleCloseOnboardingModal} />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    isOnWebView: state.global.isOnWebView,
    selectedLocationId: state.global.locationId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUser: payload => {
      dispatch(userUpdateAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withQueryAndGraphQLClient(StaffRequestGigListView));
