import React from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Row, Col, Typography, Card, Empty } from 'antd';

import CubeJsQueryRenderer from '../../../shared/components/CubeJsQueryRenderer';
import ToolTipWithInfo from '../../../shared/components/ToolTipWithInfo';
import { colors } from '../../../styles/colors';
import { getLabelColors } from '../../../utilities/analyticsUtils';

const { Text } = Typography;

class WorkerOtBarChart extends React.Component {
  formatQueryData = (timePeriod, clientId, positionIds, locationIds, granularity) => {
    const query = {
      filters: [
        {
          member: 'ClientsClient.id',
          operator: 'equals',
          values: [clientId.toString()],
        },
        {
          member: 'ClockingTimesheetentry.status',
          operator: 'notEquals',
          values: ['void'],
        },
      ],
      timeDimensions: [
        {
          dimension: 'ClockingTimesheetentry.clockInTime',
          dateRange: timePeriod,
          granularity,
        },
      ],
    };
    if (positionIds && positionIds.length > 0) {
      query.filters.push({
        member: 'PartnersPosition.id',
        operator: 'equals',
        values: positionIds,
      });
    }
    if (locationIds && locationIds.length > 0) {
      query.filters.push({
        member: 'ClientsLocation.id',
        operator: 'equals',
        values: locationIds,
      });
    }
    return query;
  };

  barRender = ({ resultSet }) => {
    const { t } = this.props;
    const labelColors = getLabelColors(resultSet.series().length);
    const data = {
      labels: resultSet.categories().map(c => moment(c.category).format('ddd, DD MMM')),
      datasets: resultSet.series().map((s, index) => ({
        label: s.title.split(',')[0],
        data: s.series.map(r => r.value),
        backgroundColor: labelColors[index],
        fill: false,
      })),
    };
    const options = {
      scales: {
        xAxes: [
          {
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: t('Date'),
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: t('totalHours'),
            },
          },
        ],
      },
      tooltips: {
        backgroundColor: colors.white,
        titleFontColor: colors.black,
        bodyFontColor: colors.black,
        borderColor: colors.grey,
        borderWidth: 0.5,
      },
    };
    const legend = {
      display: true,
      position: 'bottom',
      fullWidth: true,
      reverse: false,
    };
    return data.datasets.length > 0 ? <Bar data={data} options={options} legend={legend} /> : <Empty />;
  };

  render() {
    const { timePeriod, granularity, clientId, positionIds, locationIds, t } = this.props;
    const formattedProps = this.formatQueryData(timePeriod, clientId, positionIds, locationIds, granularity);

    return (
      <Card style={{ width: '100%', marginBottom: '16px' }}>
        <Row style={{ padding: '16px' }}>
          <Col span={24}>
            <Row type="flex" justify="space-between" style={{ alignItems: 'center', marginBottom: '24px' }}>
              <Col>
                <Text strong style={{ fontSize: '20px', paddingRight: '8px' }}>
                  {t('Overtime breakdown')}
                </Text>
                <ToolTipWithInfo infoText={t('overtimeBreakdownTooltip')} color={colors.blue} />
              </Col>
            </Row>
            <CubeJsQueryRenderer
              measures={['ClockingTimesheetentry.overtimeTotal']}
              timeDimensions={formattedProps.timeDimensions}
              filters={formattedProps.filters}
              dimensions={['ClientsLocation.name']}
              chartFunction={this.barRender}
              pivotConfig={{
                x: [],
                y: [],
                fillMissingDates: true,
                joinDateRange: false,
              }}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}

export default withTranslation()(WorkerOtBarChart);
