import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as userActions from '../../redux/user';
import * as globalActions from '../../redux/global';
import routes from '../../routes';
import RouteWithProps from '../../shared/components/RouteWithProps';
import SettingsView from './SettingsView';

const SettingsPage = props => {
  const { user, fetchClientAndManager, fetchPositions, t } = props;
  return (
    <Switch>
      <RouteWithProps
        exact
        path={routes.settings}
        extraProps={{ t, user, fetchClientAndManager, fetchPositions }}
        component={SettingsView}
      />
    </Switch>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  const { fetchClientAndManager } = userActions;
  const { fetchPositions } = globalActions;
  return {
    fetchClientAndManager: async () => {
      await dispatch(fetchClientAndManager());
    },
    fetchPositions: async () => {
      await dispatch(fetchPositions());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SettingsPage));
