import React from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Menu, Button, Dropdown } from 'antd';

const getButtons = buttons => {
  return (
    <Menu style={{ padding: 0 }}>
      {buttons.map((button, index) => {
        // Override color of button if it is disabled
        // eslint-disable-next-line no-param-reassign
        if (button.disabled) button.style = { color: '#00000040', ...button.style };
        return (
          <Menu.Item key={index} style={{ padding: '5px 12px', width: '100%' }}>
            <Button
              block
              disabled={button.disabled}
              onClick={button.onClick}
              style={{ borderWidth: 0, padding: 0, textAlign: 'left', backgroundColor: 'inherit', ...button.style }}
            >
              {button.title}
            </Button>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

const DropdownButtons = ({
  overlayClassName,
  buttons,
  width,
  size = 'default',
  placement = 'bottomLeft',
  ...props
}) => {
  const dropDownMenu = getButtons(buttons);
  const overlayClassNames = (overlayClassName || '').trim().split(' ');
  overlayClassNames.push('dropdown-buttons-overlay');
  return (
    <Dropdown
      overlayClassName={overlayClassNames.join(' ')}
      overlay={dropDownMenu}
      placement={placement}
      trigger={['click']}
      style={{ width }}
    >
      <Button {...props} size={size}>
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  );
};

export default DropdownButtons;
