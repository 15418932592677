import React from 'react';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col, Modal, Button, Typography, Divider } from 'antd';

import { colors } from '../../../styles/colors';
import WorkerHiringProfileView from '../WorkerHiringProfileView';
import ApplicationActions from './ApplicationActions';
import ApplicationStatus from './ApplicationStatus';
import { applicationStatuses } from '../../../constants';

const { Text } = Typography;

const ModalHeader = ({
  pageNum,
  selectedIndex,
  total,
  onChange,
  t,
  application,
  index,
  onReject,
  onHire,
  onChat,
  onHandleOfferExpiry,
}) => {
  const worker = application.partner;
  return (
    <>
      <Row type="flex" justify="space-between" align="middle" style={{ padding: '16px 24px' }}>
        <Button
          icon={<LeftOutlined />}
          onClick={() => {
            onChange(selectedIndex - 1);
          }}
          disabled={selectedIndex === 0}
        />
        <span style={{ color: '#8C8C8C' }}>
          {selectedIndex + 1} of {total} on page {pageNum}
        </span>
        <Button
          icon={<RightOutlined />}
          onClick={() => {
            onChange(selectedIndex + 1);
          }}
          disabled={selectedIndex + 1 === total}
        />
      </Row>
      <Divider style={{ margin: 0 }} />
      <Row type="flex" justify="space-between" align="middle" style={{ padding: '16px 24px' }}>
        <Col>
          <Row style={{ marginBottom: 4 }}>
            <Text type="secondary" style={{ fontSize: '14px', lineHeight: '22px', color: colors.primaryText }}>
              <ApplicationStatus
                t={t}
                client={application.staff_request.client}
                time={moment(application.modified_date).toNow(true)}
                application={application}
              />
            </Text>
          </Row>
          {application.status !== applicationStatuses.PENDING_CONTRACT && (
            <Row>
              <Text type="secondary" style={{ fontSize: '14px', lineHeight: '22px' }}>
                {t('lastActiveTimeAgo', { time: moment(worker.last_opened_app).toNow(true) })}
              </Text>
            </Row>
          )}
        </Col>
        <Col>
          <ApplicationActions
            t={t}
            applicationIndex={index}
            application={application}
            worker={worker}
            onHire={onHire}
            onReject={onReject}
            onChat={onChat}
            onChangeOfferExpiry={onHandleOfferExpiry}
          />
        </Col>
      </Row>
    </>
  );
};

class ApplicantToggleModal extends React.Component {
  state = {
    selectedApplicantIndex: this.props.selectedApplicantIndex || 0,
    applications: this.props.applications,
  };

  componentDidMount() {
    window.addEventListener('keyup', this.keyHandling);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.keyHandling);
  }

  keyHandling = event => {
    const { code } = event;
    if (code !== 'ArrowLeft' && code !== 'ArrowRight') {
      return;
    }
    const { selectedApplicantIndex, applications } = this.state;
    if (code === 'ArrowLeft' && selectedApplicantIndex !== 0) {
      this.setState({ selectedApplicantIndex: selectedApplicantIndex - 1 });
      return;
    }

    if (code === 'ArrowRight' && selectedApplicantIndex + 1 !== applications.length) {
      this.setState({ selectedApplicantIndex: selectedApplicantIndex + 1 });
    }
  };

  render() {
    const {
      t,
      visible,
      onCancel,
      onReject,
      onHire,
      onChat,
      pageNum,
      user,
      linkToApplicationsTab,
      singleApplicationForModal,
      onHandleOfferExpiry,
    } = this.props;
    const { applications, selectedApplicantIndex } = this.state;
    let application = null;
    if (selectedApplicantIndex >= 0) {
      application = applications[selectedApplicantIndex];
    } else if (singleApplicationForModal) {
      application = singleApplicationForModal;
    }

    return (
      <Modal
        centered
        destroyOnClose
        maskClosable
        zIndex={99998}
        visible={visible}
        closable={false}
        width="1032px"
        bodyStyle={{ height: '80vh', minHeight: '560px', maxHeight: '720px', overflowY: 'auto', overflowX: 'hidden' }}
        className="worker-profile-modal"
        title={
          <ModalHeader
            selectedIndex={selectedApplicantIndex}
            total={applications.length}
            pageNum={pageNum}
            onChange={nextIndex => this.setState({ selectedApplicantIndex: nextIndex })}
            index={selectedApplicantIndex}
            application={application}
            onReject={onReject}
            onHire={onHire}
            onChat={onChat}
            onHandleOfferExpiry={onHandleOfferExpiry}
            t={t}
          />
        }
        onCancel={onCancel}
        footer={null}
      >
        <WorkerHiringProfileView
          workerId={application.partner.id}
          key={application.partner.id}
          application={application}
          user={user}
          linkToApplicationsTab={linkToApplicationsTab}
        />
      </Modal>
    );
  }
}

export default withTranslation()(withRouter(ApplicantToggleModal));
