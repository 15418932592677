import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as chatActions from '../../../redux/chat';
import ChatListView from './ChatListView';
import { channelStatuses } from '../../../constants';

const ChatListViewWithStatus = (status = channelStatuses.ACTIVE) => {
  const component = ({ setTotalUnreadCount, browserHistory }) => {
    return (
      <ChatListView
        status={status}
        browserHistory={browserHistory}
        enableArchive={[channelStatuses.ACTIVE].includes(status)}
        setTotalUnreadCount={[channelStatuses.ACTIVE].includes(status) ? setTotalUnreadCount : null}
      />
    );
  };

  const mapDispatchToProps = dispatch => {
    const { setTotalUnreadCount } = chatActions;
    return {
      setTotalUnreadCount: count => {
        dispatch(setTotalUnreadCount(count));
      },
    };
  };

  return connect(null, mapDispatchToProps)(withTranslation()(component));
};

export default ChatListViewWithStatus;
