import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, message, Typography } from 'antd';
import { withTranslation, Trans } from 'react-i18next';

// Taken from V1 folders
import clientApi from '../../../../services/clientApi';

class DeleteRoleFormModal extends React.Component {
  state = {
    loading: false,
  };

  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll(async formErrors => {
      if (!formErrors) {
        this.setState({ loading: true });
        try {
          this.deleteRole();
        } catch (error) {
          this.setState({ loading: false });
        }
      }
    });
  };

  deleteRole = async () => {
    const { role, t, onUpdate, onClose, clientId } = this.props;
    // Removing roles by setting is_active to false, not deleting
    await clientApi.editRole(clientId, {
      id: role.id,
      is_active: false,
    });
    onUpdate();
    onClose();
    message.success(t('roleDeleteSuccessMessage'));
  };

  render() {
    const { role, visible, t, onClose } = this.props;
    return (
      <Modal
        destroyOnClose
        visible={visible}
        style={{ top: '20px' }}
        okText={t('yesDelete')}
        okType="danger"
        cancelText={t('cancel')}
        title={t('deleteRole')}
        onOk={this.handleSubmit}
        onCancel={onClose}
        confirmLoading={this.state.loading}
      >
        <Form hideRequiredMark layout="vertical" onSubmit={this.handleSubmit}>
          <Typography.Text>
            <Trans i18nKey="deleteRoleConfirmationMessage" values={{ role: role.name }} />{' '}
          </Typography.Text>
          {/* TODO add ID/TH translations */}
          <Typography.Text>
            <Trans i18nKey="deleteRoleConfirmationImpactMessage" values={{ role: role.name }} />
          </Typography.Text>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(withTranslation()(DeleteRoleFormModal));
