import React from 'react';
import { Modal, Row, Col, Typography, Dropdown, Button, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const { Text } = Typography;

const UpdateCurrentTemplate = ({
  t,
  isVisible,
  templateName,
  handleClose,
  handleOk,
  handleSaveAsNewTemplate,
  handleNoThanksPostJobBtn,
}) => {
  return (
    <Modal
      visible={isVisible}
      onCancel={handleClose}
      title={
        <Text style={{ fontSize: 20 }} strong>
          {t('updateCurrentTemplate')}
        </Text>
      }
      footer={[
        <Button onClick={() => handleNoThanksPostJobBtn()}>{t('noThanks')}</Button>,
        <Dropdown
          overlay={
            <Menu style={{ width: '85%' }}>
              <Menu.Item style={{ whiteSpace: 'normal', height: 'auto' }} key="1" onClick={() => handleOk()}>
                {t('updateCurrentTemplate')}
              </Menu.Item>
              <Menu.Item
                style={{ whiteSpace: 'normal', height: 'auto' }}
                key="2"
                onClick={() => handleSaveAsNewTemplate()}
              >
                {t('saveAsNewTemplate')}
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="v2-primary" style={{ marginLeft: 8 }}>
            {t('updateTemplate')} <DownOutlined />
          </Button>
        </Dropdown>,
      ]}
    >
      <Row>
        <Text>{t('updateCurrentTemplateWithChanges')}</Text>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Text strong>{t('template')}</Text>
      </Row>
      <Row>
        <Col>
          <Text>{templateName}</Text>
        </Col>
      </Row>
    </Modal>
  );
};

export default UpdateCurrentTemplate;
