import base from './base';
import { endpoints } from '../config';

const searchQuestions = async params => {
  const response = await base.api.get(`${endpoints.questions}/search/`, { params });
  return response;
};

const createQuestion = async payload => {
  const formData = new FormData();
  formData.append('question_data', JSON.stringify(payload.question_data));
  if (payload.image) {
    formData.append('image', payload.image);
  }
  const response = await base.api.post(`${endpoints.questions}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

export default {
  searchQuestions,
  createQuestion,
};
