import React, { useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Row, Col, Card, Typography, Modal, Avatar } from 'antd';
import { withTranslation } from 'react-i18next';

const { Text } = Typography;

const DetailCard = ({ headerTitle, contentList, inline, loading = false, t }) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const emptyState = (
    <Text type="secondary" style={{ color: '#BFBFBF' }}>
      {t('notProvided')}
    </Text>
  );
  return (
    <Card loading={loading} bordered={false} style={{ backgroundColor: 'inherit' }} bodyStyle={{ padding: 0 }}>
      <Row style={{ margin: '8px 0 16px 0' }}>
        <Col>
          <Text strong type="secondary" style={{ fontSize: '12px' }}>
            {t(headerTitle).toUpperCase()}
          </Text>
        </Col>
      </Row>

      {/* Vertical list of details */}
      {contentList.map(
        ({ title, value, isImage = false, hide = false }, index) =>
          !hide && (
            <Row justify="space-between" key={index} style={{ marginBottom: '16px' }}>
              <Col span={inline ? undefined : 24}>
                <Text strong>{t(title)}</Text>
              </Col>
              {isImage && value ? (
                <>
                  <Avatar
                    shape="square"
                    size={128}
                    icon={<UserOutlined />}
                    src={value}
                    style={{ padding: '8px', border: '1px solid #D9D9D9' }}
                    onClick={() => setShowImageModal(true)}
                  />
                  <Modal visible={showImageModal} footer={null} onCancel={() => setShowImageModal(false)}>
                    <img alt={title} style={{ width: '100%' }} src={value} />
                  </Modal>
                </>
              ) : (
                <Col>
                  <Text type="secondary">{value || emptyState}</Text>
                </Col>
              )}
            </Row>
          ),
      )}
    </Card>
  );
};

export default withTranslation()(DetailCard);
