import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { List, Avatar, Button, Typography, Row, Col } from 'antd';

import { colors } from '../../styles/colors';

const { Text, Title } = Typography;

const ListWithShowMore = ({ dataList, t, header = null, minimumLength = 1 }) => {
  const [list, setList] = useState(
    dataList.slice(0, dataList.length > minimumLength ? minimumLength : dataList.length),
  );
  const [expanded, setExpansion] = useState(false);
  if (dataList.length === 0) return <></>;
  return (
    <>
      {header && (
        <Title level={3} style={{ marginBottom: '24px' }}>
          {header}
        </Title>
      )}
      <List
        itemLayout="horizontal"
        loadMore={
          dataList.length > minimumLength && (
            <Row type="flex" justify="space-between" style={{ marginTop: '8px' }}>
              <Col>
                <Button
                  type="link"
                  style={{ marginTop: 0, padding: 0, fontWeight: 600, color: colors.infoHighlightBlue }}
                  onClick={() => {
                    setExpansion(!expanded);
                    setList(expanded ? dataList.slice(0, minimumLength) : dataList);
                  }}
                >
                  {expanded ? t('showLessWorkExperience') : t('showMoreWorkExperience')}
                  <LegacyIcon type={expanded ? 'up' : 'down'} />
                </Button>
              </Col>
            </Row>
          )
        }
        dataSource={list}
        renderItem={item => (
          <List.Item className="no-border" key={item.id} style={{ padding: '16px 0' }}>
            <List.Item.Meta
              avatar={<Avatar shape="square" size={48} style={{ height: '100%' }} src={item.imgSrc} />}
              title={
                <Text strong style={{ color: colors.primaryText }}>
                  {item.title}
                </Text>
              }
              description={
                <>
                  <Text strong style={{ color: colors.primaryText }}>
                    {item.subTitle}
                  </Text>
                  <br />
                  <Text style={{ color: colors.primaryText, fontSize: '14px' }}>{item.period}</Text>
                  <br />
                  <Text style={{ color: colors.secondaryText, fontSize: '12px' }}>{item?.description}</Text>
                </>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default withTranslation()(ListWithShowMore);
