/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from 'react';
import moment from 'moment-timezone';
import { uniq, uniqBy, sortBy, cloneDeep, isEqual, groupBy, max, minBy, maxBy } from 'lodash';
import * as Sentry from '@sentry/browser';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Button, Typography, Row, Col, Select, Divider, Empty, Modal, message } from 'antd';
import { withTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { rrulestr } from 'rrule';

import emptyState from '../../assets/images/no_location_or_schedule.png';
import {
  attendanceStatuses,
  DATE_FORMAT,
  DATE_KEY_FORMAT,
  derivedWorkingStatus,
  employmentStatuses,
  MONTHLY_DATE_RANGE,
  settingsTabs,
  WEEKLY_DATE_RANGE,
  localStorageKeys,
} from '../../constants';
import shiftApi from '../../services/shiftApi';
import employmentApi from '../../services/employmentApi';
import scheduleApi from '../../services/scheduleApi';
import fetchAll from '../../utilities/apiUtils';
import datetimeUtils from '../../utilities/datetimeUtils';
import employmentUtils from '../../utilities/employmentUtils';
import { updateUrlWithParams, retrieveParamsFromUrl } from '../../utilities/urlUtils';
import clientUtils from '../../utilities/clientUtils';
import routes from '../../routes';

import LoadingSpinner from '../../shared/components/LoadingSpinner';
import WeekMonthSelector from '../../shared/components/WeekMonthSelector';
import AddScheduleFormModal from './components/AddScheduleFormModal';
import ScheduleListView from './components/ScheduleListView';
import AutoAssignButtonModal from './components/AutoAssignButtonModal';
import CopyAssignmentButtonModal from './components/CopyAssignmentButtonModal';
import attendanceUtils from '../../utilities/attendanceUtils';

const { Title, Text } = Typography;
const { Option, OptGroup } = Select;

const LOCATION_KEY = 'locationId';
const COPY_SHIFTS = 'copyShifts';

class ShiftPage extends React.Component {
  state = {
    scheduleLoading: false,
    employmentsLoading: false,
    // selectedDateStart is a moment object with local timezone info, NOT user's browser timezone
    selectedDateStart: undefined,
    // weekdayDates are derived from selectedDateStart, each date is a moment object with local timezone info
    // Used to derive schedules and shifts table column and match corresponding shift and attendances of the day
    weekdayDates: [],
    shifts: [],
    originalShifts: [],
    employments: [],
    addScheduleModalVisible: false,
    locationId: undefined,
    timezone: undefined,
    allSchedules: [],
    publishCountMap: {},
    autoAssignLoading: false,
    scheduleTableExpandedRowKeys: [],
    selectedDateRange: WEEKLY_DATE_RANGE,
    previouslySelectedWeek: undefined,
  };

  async componentDidMount() {
    await this.updateStateFromParams();
    const { locations, clientCountry } = this.props;
    const { selectedDateRange } = this.state;

    const locationId = await this.getFilterValues();
    let selectedLocation = locations[0];
    let dateStart;
    if (locationId) {
      selectedLocation = locations.find(location => location.id === locationId);
      dateStart = this.initializeWeekdayDates(
        selectedLocation?.address?.area.city.timezone || clientUtils.getTimezoneFromClientCountry(clientCountry),
      );
    }
    if (!locationId && selectedLocation) {
      dateStart = this.initializeWeekdayDates(
        selectedLocation?.address?.area.city.timezone || clientUtils.getTimezoneFromClientCountry(clientCountry),
      );
    }
    if (locationId || selectedLocation) {
      await this.fetchSchedules(dateStart, selectedDateRange);
      this.setState(
        {
          locationId: locationId || selectedLocation.id,
        },
        () => this.handleRefresh(),
      );
    }
    if (!locationId && !selectedLocation) {
      dateStart = this.initializeWeekdayDates(clientUtils.getTimezoneFromClientCountry(clientCountry));
      this.setState({
        locationId: locationId || selectedLocation?.id,
      });
    }
  }

  updateStateFromParams = async () => {
    const { selectedDateRange } = this.state;
    const { location_id, selected_date_start, date_type } = retrieveParamsFromUrl(this.props.location.search);
    let selectedDateStartFromParams =
      selected_date_start && moment(selected_date_start, 'DD-MM-YYYY').startOf('isoWeek');

    if (date_type === MONTHLY_DATE_RANGE) {
      selectedDateStartFromParams = selected_date_start && moment(selected_date_start, 'DD-MM-YYYY').startOf('month');
    }
    this.setState({
      locationId: location_id && Number(location_id),
      selectedDateStart: selectedDateStartFromParams,
      selectedDateRange: date_type || selectedDateRange,
      previouslySelectedWeek: selectedDateStartFromParams,
    });
  };

  updateParamsFromState = () => {
    const { locationId, selectedDateStart, selectedDateRange } = this.state;
    updateUrlWithParams(
      {
        location_id: locationId || undefined,
        selected_date_start: selectedDateStart.format('DD-MM-YYYY') || undefined,
        date_type: selectedDateRange,
      },
      this.props.history,
    );
  };

  getFilterValues = async () => {
    const { location_id } = retrieveParamsFromUrl(this.props.location.search);
    let locationId = location_id && Number(location_id);

    // If no URL param, get local storage values if any
    if (!locationId) {
      if (localStorage.getItem(localStorageKeys.SHIFT_FILTERS)) {
        const shiftFilters = JSON.parse(localStorage.getItem(localStorageKeys.SHIFT_FILTERS));
        locationId = shiftFilters[LOCATION_KEY];
      }
    }
    return locationId;
  };

  initializeWeekdayDates = timezone => {
    let { selectedDateStart } = this.state;
    const { selectedDateRange } = this.state;
    // if selectedDateStart has already been initialized in state (via params), use specified selectedDateStart
    if (selectedDateRange === MONTHLY_DATE_RANGE) {
      selectedDateStart = datetimeUtils.getIsoMonthStart(selectedDateStart || moment(), timezone);
      const weekdayDates = [...Array(moment(selectedDateStart).daysInMonth()).keys()].map(day =>
        selectedDateStart.clone().add(day, 'days'),
      );
      this.setState({ selectedDateStart, weekdayDates, timezone });
    }
    if (selectedDateRange === WEEKLY_DATE_RANGE) {
      selectedDateStart = datetimeUtils.getIsoWeekStart(selectedDateStart || moment(), timezone);
      const weekdayDates = [...Array(7).keys()].map(day => selectedDateStart.clone().add(day, 'days'));
      this.setState({ selectedDateStart, weekdayDates, timezone });
    }
    return selectedDateStart;
  };

  handleLocationChange = async () => {
    const { locationId, selectedDateRange } = this.state;
    const { locations, clientCountry } = this.props;

    if (!locationId) return;

    localStorage.setItem(localStorageKeys.SHIFT_FILTERS, JSON.stringify({ locationId }));

    this.setState({ scheduleLoading: true });

    const selectedLocation = locations.find(location => location.id === locationId);
    const selectedDateStart = this.initializeWeekdayDates(
      selectedLocation?.address?.area.city.timezone || clientUtils.getTimezoneFromClientCountry(clientCountry),
    );
    await this.fetchSchedules(selectedDateStart, selectedDateRange);
    await this.fetchShifts(selectedDateStart, selectedDateRange);
    this.setState({ scheduleLoading: false });
    // Page can load without employments
    await this.fetchEmployments();

    // To prevent navigate away warning prompt from old publish count, update URL after new shifts are set
    this.updateParamsFromState();
  };

  handleRefresh = async () => {
    const { selectedDateRange } = this.state;
    this.updateParamsFromState();
    await Promise.all([this.fetchShifts(this.state.selectedDateStart, selectedDateRange), this.fetchEmployments()]);
  };

  // Fetch schedules for all locations and positions to split into has/no schedules
  fetchSchedules = async (weekStart, selectedDateRange = WEEKLY_DATE_RANGE) => {
    const { clientId } = this.props;
    this.setState({ locationLoading: true });

    const startDate = weekStart;
    const endDate = datetimeUtils.getEndDateFromStartDateAndDateRange(weekStart.clone(), selectedDateRange);

    const allSchedules = await fetchAll(scheduleApi.fetchSchedules, {
      client: clientId,
      start_date_before: endDate.toISOString(),
      end_date_after: startDate.toISOString(),
    });
    this.setState({ allSchedules, locationLoading: false });
  };

  fetchShifts = async (selectedDateStart, selectedDateRange = WEEKLY_DATE_RANGE) => {
    this.setState({ scheduleLoading: true });
    const { locationId } = this.state;
    const startDate = selectedDateStart;
    const endDate = datetimeUtils.getEndDateFromStartDateAndDateRange(selectedDateStart.clone(), selectedDateRange);
    const params = {
      start_time_after: startDate.toISOString(),
      start_time_before: endDate.toISOString(),
      location: locationId,
    };
    const shifts = await fetchAll(shiftApi.fetchShifts, params);
    this.setState({
      shifts,
      originalShifts: shifts,
      scheduleLoading: false,
    });
    return shifts;
  };

  fetchEmployments = async () => {
    const { locationId } = this.state;

    this.setState({ employmentsLoading: true });

    const params = {
      status: employmentStatuses.ACTIVE,
      location: locationId,
      lean: true, // fetch only needed employment data
    };
    const employments = sortBy(await fetchAll(employmentApi.list, params), 'partner.first_name');
    this.setState({ employments, employmentsLoading: false });
  };

  handleWeekSelect = async isoDate => {
    const { timezone, previouslySelectedWeek } = this.state;
    // If moving from monthly to weekly, check if we should use previouslySelectedWeek
    const shouldUsepreviouslySelectedWeek = this.handePreviouslySelectedWeekChange(isoDate, WEEKLY_DATE_RANGE);
    const selectedDateStart = datetimeUtils.getIsoWeekStart(isoDate, timezone);
    this.updateSelectDateState(
      (shouldUsepreviouslySelectedWeek && previouslySelectedWeek) || selectedDateStart,
      WEEKLY_DATE_RANGE,
    );
    await this.fetchSchedules(
      (shouldUsepreviouslySelectedWeek && previouslySelectedWeek) || selectedDateStart,
      WEEKLY_DATE_RANGE,
    );
    await this.fetchShifts(
      (shouldUsepreviouslySelectedWeek && previouslySelectedWeek) || selectedDateStart,
      WEEKLY_DATE_RANGE,
    );
  };

  handleMonthSelect = async isoDate => {
    const { timezone } = this.state;
    this.handePreviouslySelectedWeekChange(isoDate, MONTHLY_DATE_RANGE);
    const selectedMonthStart = datetimeUtils.getIsoMonthStart(isoDate, timezone);
    await this.updateSelectDateState(selectedMonthStart, MONTHLY_DATE_RANGE);
    await this.fetchSchedules(selectedMonthStart, MONTHLY_DATE_RANGE);
    await this.fetchShifts(selectedMonthStart, MONTHLY_DATE_RANGE);
  };

  handePreviouslySelectedWeekChange = (isoDate, dateRange) => {
    const { selected_date_start, date_type } = retrieveParamsFromUrl(this.props.location.search);
    const { previouslySelectedWeek, timezone } = this.state;
    const selectedDateStart = datetimeUtils.getIsoWeekStart(isoDate, timezone);
    let shouldUsepreviouslySelectedWeek = false;
    // previouslySelectedWeek should reset if month changed
    if (
      date_type === MONTHLY_DATE_RANGE &&
      dateRange === MONTHLY_DATE_RANGE &&
      moment(isoDate).startOf(MONTHLY_DATE_RANGE) !== moment(selected_date_start).startOf(MONTHLY_DATE_RANGE)
    ) {
      this.setState({ previouslySelectedWeek: undefined });
    }
    // previouslySelectedWeek should update if week change
    if (
      date_type === WEEKLY_DATE_RANGE &&
      dateRange === WEEKLY_DATE_RANGE &&
      moment(selectedDateStart) !== moment(previouslySelectedWeek)
    ) {
      this.setState({ previouslySelectedWeek: selectedDateStart });
    }
    // If moving from monthly to weekly, should use previouslySelectedWeek
    if (
      (date_type === MONTHLY_DATE_RANGE && dateRange === WEEKLY_DATE_RANGE) ||
      (date_type === WEEKLY_DATE_RANGE && dateRange === MONTHLY_DATE_RANGE)
    ) {
      shouldUsepreviouslySelectedWeek = true;
    }
    return shouldUsepreviouslySelectedWeek;
  };

  updateSelectDateState = (selectedDateStart, selectedDateRange) => {
    let dateRangeDates;
    if (selectedDateRange === MONTHLY_DATE_RANGE) {
      dateRangeDates = [...Array(moment(selectedDateStart).daysInMonth()).keys()].map(day =>
        selectedDateStart.clone().add(day, 'days'),
      );
    }
    if (selectedDateRange === WEEKLY_DATE_RANGE) {
      dateRangeDates = [...Array(7).keys()].map(day => selectedDateStart.clone().add(day, 'days'));
    }
    this.setState(
      {
        selectedDateStart,
        weekdayDates: dateRangeDates,
        selectedDateRange,
        publishCountMap: {},
      },
      this.updateParamsFromState,
    );
    return dateRangeDates;
  };

  // Inputs structure: [{targetShiftId, date, workerId, source }]
  // source is optional and used only by copy shift function
  handleAssignmentUpdate = updates => {
    const { shifts, originalShifts } = this.state;
    const draftShifts = cloneDeep(shifts);
    updates.forEach(update => {
      const { targetShiftId, date, workerId, targetRoleId, source } = update;
      const targetShiftIndex = draftShifts.findIndex(shiftItem => shiftItem.id === targetShiftId);
      // Unassignment logic:
      // Update same shift existing attendances to unassigned
      draftShifts.forEach(shift => {
        if (moment(date).isSame(shift.start_time, 'day')) {
          const attendanceIndex = shift.attendances.findIndex(
            attendance => attendance.partner_id === workerId && attendance.status === attendanceStatuses.ASSIGNED,
          );
          if (attendanceIndex >= 0) {
            if (shift.attendances[attendanceIndex].id) {
              // eslint-disable-next-line no-param-reassign
              shift.attendances[attendanceIndex] = {
                ...shift.attendances[attendanceIndex],
                status: attendanceStatuses.UNASSIGNED,
                confirmed: false,
                change_reason: undefined,
                role_id: null,
                unpublished: true,
              };
            } else shift.attendances.splice(attendanceIndex, 1);
          }
        }
      });

      // Update or insert attendance on the target shift
      if (targetShiftIndex >= 0) {
        const attendanceIndex = draftShifts[targetShiftIndex].attendances.findIndex(
          attendance => attendance.partner_id === workerId,
        );
        if (attendanceIndex >= 0) {
          const originalAttendance = originalShifts[targetShiftIndex].attendances.find(
            attendance =>
              attendance.id === draftShifts[targetShiftIndex].attendances[attendanceIndex].id &&
              attendance.status === attendanceStatuses.ASSIGNED,
          );
          // If original attendance exist and update source not copy shifts, revert back to original attendance state
          if (originalAttendance && !isEqual(source, COPY_SHIFTS)) {
            draftShifts[targetShiftIndex].attendances[attendanceIndex] = { ...originalAttendance, change_reason: null };
          } else {
            draftShifts[targetShiftIndex].attendances[attendanceIndex] = {
              ...draftShifts[targetShiftIndex].attendances[attendanceIndex],
              status: update?.status || attendanceStatuses.ASSIGNED,
              confirmed: false,
              change_reason: null,
              role_id: targetRoleId,
            };
          }
        } else {
          draftShifts[targetShiftIndex].attendances.push({
            shift_id: targetShiftId,
            partner_id: workerId,
            status: attendanceStatuses.ASSIGNED,
            confirmed: false,
            role_id: targetRoleId,
          });
        }
      }
    });

    this.setState(
      {
        shifts: draftShifts,
      },
      () => this.updatePublishCount(updates),
    );
  };

  handleCopyShift = async selectedCopyDateStart => {
    const { shifts, timezone, employments } = this.state;
    const fromShifts = cloneDeep(shifts);
    const selectedDateStart = datetimeUtils.getIsoWeekStart(selectedCopyDateStart, timezone);

    this.updateSelectDateState(selectedDateStart, WEEKLY_DATE_RANGE);
    const targetShifts = await this.fetchShifts(selectedCopyDateStart);

    this.setState({ originalShifts: targetShifts });
    const draftTargetShifts = cloneDeep(targetShifts);

    const updates = draftTargetShifts
      .flatMap(shift => {
        const fromShift = fromShifts.find(
          item =>
            moment(shift.start_time)
              .tz(timezone)
              .format('ddd') ===
              moment(item.start_time)
                .tz(timezone)
                .format('ddd') &&
            item.schedule.id === shift.schedule.id &&
            // only allow copying to shifts starting from today and future
            moment(shift.start_time) > datetimeUtils.getDayStart(moment(), timezone),
        );

        return fromShift?.attendances
          .filter(attendance => {
            const targetShiftDay = datetimeUtils.getDayStart(shift.start_time, timezone);
            const targetAttendance = shift.attendances.find(item => item.partner_id === attendance.partner_id);
            const hasConfirmedTargetAttendance = targetAttendance?.confirmed === true;
            const workerEmployment = employments.find(employment => employment.partner.id === attendance.partner_id);
            const contractStatus = workerEmployment
              ? employmentUtils.getDerivedWorkingStatus(targetShiftDay, workerEmployment)
              : derivedWorkingStatus.CONTRACT_NOT_SIGNED;
            // Do not copy shift to confirmed target shift
            return attendanceUtils.isValidWorkingDay(contractStatus) && !hasConfirmedTargetAttendance;
          })
          .map(attendance => {
            return {
              targetShiftId: shift.id,
              date: shift.start_time,
              workerId: attendance.partner_id,
              targetRoleId: attendance.role_id,
              status: attendance.status,
              source: COPY_SHIFTS,
            };
          });
      })
      .filter(update => update);

    this.handleAssignmentUpdate(updates);
  };

  handleRoleUpdate = updates => {
    const { shifts } = this.state;
    const draftShifts = cloneDeep(shifts);
    updates.forEach(update => {
      const { targetShiftId, workerId, targetRoleId } = update;
      const targetShiftIndex = draftShifts.findIndex(shiftItem => shiftItem.id === targetShiftId);
      const attendanceIndex = draftShifts[targetShiftIndex].attendances.findIndex(
        attendance => attendance.partner_id === workerId,
      );
      draftShifts[targetShiftIndex].attendances[attendanceIndex] = {
        ...draftShifts[targetShiftIndex].attendances[attendanceIndex],
        role_id: targetRoleId,
      };
    });

    this.setState(
      {
        shifts: draftShifts,
      },
      () => this.updatePublishCount(updates),
    );
  };

  // Compare current shift with original shift, if any attendance changed,
  // 1) update the shift, 2) notify the worker
  onClickPublish = async () => {
    const { weekdayDates, selectedDateRange } = this.state;
    const { t } = this.props;

    const [pendingUpdateShifts, workersToNotify] = this.getShiftsAndWorkers();
    const startDate = moment(weekdayDates[0]).format(DATE_FORMAT);
    let endDate = moment(weekdayDates[6]).format(DATE_FORMAT);
    if (selectedDateRange === MONTHLY_DATE_RANGE) {
      endDate = moment(weekdayDates[weekdayDates.length - 1]).format(DATE_FORMAT);
    }
    const workersCount = workersToNotify.length;

    Modal.confirm({
      centered: true,
      title: (
        <Text strong style={{ fontSize: '14px' }}>
          {t('publishShiftsTitle', { startDate, endDate })}
        </Text>
      ),
      content: (
        <>
          <Row style={{ marginBottom: '8px' }}>
            <Trans i18nKey="numAssignmentsPublished" values={{ numAssignments: this.getPublishCount() }} />
          </Row>
          <Row>
            <Trans i18nKey="numWorkersNotified" values={{ numWorkers: workersCount }} />
          </Row>
        </>
      ),
      okText: t('publish'),
      okType: 'v2-primary',
      cancelText: t('cancel'),
      icon: <QuestionCircleTwoTone style={{ fontSize: '20px' }} />,
      onOk: () => this.handlePublish(pendingUpdateShifts, workersToNotify),
    });
  };

  getShiftsAndWorkers = () => {
    const { shifts, originalShifts } = this.state;

    const pendingUpdateShifts = [];
    const workersToNotify = [];

    for (let i = 0; i < shifts.length; i += 1) {
      const newAttendances = shifts[i].attendances;
      const originalAttendances = originalShifts[i].attendances;

      if (!isEqual(newAttendances, originalAttendances)) {
        pendingUpdateShifts.push({
          id: shifts[i].id,
          attendances: shifts[i].attendances,
        });

        // A worker should be notified for three cases:
        // 1) New attendance (first time assigned to this shift)
        // 2) Attendance changed from assgined to unassigned (assigned off day or assigned to a different shift)
        // 3) Attendance changed from unassigned to assigned (previously assigned then unassigned, now back to assigned)
        newAttendances.forEach(attendance => {
          const matchingOriginalAttendance = originalAttendances.find(
            originalAtt => originalAtt.partner_id === attendance.partner_id,
          );
          // Case 1, new attendance
          if (!matchingOriginalAttendance) {
            workersToNotify.push(attendance.partner_id);
          }
          // Case 2 and Case 3, existing attendance has status or role changed
          else if (
            matchingOriginalAttendance.status !== attendance.status ||
            matchingOriginalAttendance.role_id !== attendance.role_id
          ) {
            workersToNotify.push(attendance.partner_id);
          }
        });
      }
    }
    const uniqueWorkersToNotify = [...new Set(workersToNotify)];
    return [pendingUpdateShifts, uniqueWorkersToNotify];
  };

  handlePublish = async (shifts, workers) => {
    const { t } = this.props;
    const publishPromise = shifts.map(pendingUpdateShift => shiftApi.editShift(pendingUpdateShift));
    try {
      await Promise.all(publishPromise);
      await shiftApi.notifyPartner({
        client_id: this.props.clientId,
        partner_id: workers.join(','),
      });
      message.success(t('shiftPublishSuccess', { workerCount: workers.length }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      this.setState({ publishCountMap: {} });
      this.handleRefresh();
    }
  };

  updatePublishCount = updates => {
    /*
      To keep track of the publish counts globally, we maintain a map with the following key-value format:
      key: (scheduleId-date-workerId), value: 1 if assignment is updated, 0 if no update
      The total publish counts will be derived by summing all the values in the map.
    */
    const { publishCountMap } = this.state;
    const newPublishCountMap = cloneDeep(publishCountMap);
    // eslint-disable-next-line no-restricted-syntax
    for (const update of updates) {
      const { scheduleId, date, workerId } = update;
      const formattedDate = moment(date).format(DATE_FORMAT);
      const key = `${scheduleId}-${formattedDate}-${workerId}`;
      newPublishCountMap[key] = this.isAssignmentUpdated(workerId, date) ? 1 : 0;
    }
    this.setState({ publishCountMap: newPublishCountMap });
  };

  getPublishCount = () => {
    const { publishCountMap } = this.state;
    return Object.values(publishCountMap).reduce((totalPublishCount, count) => (totalPublishCount += count), 0);
  };

  isAssignmentUpdated = (workerId, date) => {
    /*
      Utility method to compare a worker's attendance and original attendance given the workerId and date
    */
    const { shifts, originalShifts } = this.state;
    const dateKey = moment(date).format(DATE_KEY_FORMAT);
    // Group and get shifts by the specified date
    const shiftsByDay = groupBy(shifts, shift => {
      return moment(shift.start_time).format(DATE_KEY_FORMAT);
    });

    const originalShiftsByDay = groupBy(originalShifts, shift => {
      return moment(shift.start_time).format(DATE_KEY_FORMAT);
    });
    // Get attendance from those shifts and compare them
    const attendance = shiftsByDay[dateKey]
      ?.flatMap(shift => shift.attendances)
      .find(
        attendanceValue =>
          attendanceValue.partner_id === workerId && attendanceValue.status === attendanceStatuses.ASSIGNED,
      );
    const originalAttendance = originalShiftsByDay[dateKey]
      ?.flatMap(shift => shift.attendances)
      .find(
        attendanceValue =>
          attendanceValue.partner_id === workerId && attendanceValue.status === attendanceStatuses.ASSIGNED,
      );
    return !isEqual(attendance, originalAttendance);
  };

  handleAddWorkersToShift = (selectedWorkerIds, selectedSchedule, selectedScheduleRole) => {
    /*
      Assign selected workers to shifts under a schedule in the current week:
      - Worker will be assigned to all days in current week
      - If there is overlap in timing with worker's current assignments, we unassign their current shift and assign them to the new one
    */
    const { shifts, employments, weekdayDates, timezone, selectedDateRange } = this.state;
    const toBeUpdateDates = [];
    const updates = [];
    const rrule = rrulestr(selectedSchedule.recurrences);
    let scheduleDays = [];
    if (selectedDateRange === MONTHLY_DATE_RANGE) {
      const shiftsInWeek = rrule.options.byweekday;
      weekdayDates.forEach((date, index) => {
        if (shiftsInWeek.includes(moment(date).isoWeekday() - 1)) {
          scheduleDays.push(index);
        }
      });
    }
    if (selectedDateRange === WEEKLY_DATE_RANGE) {
      scheduleDays = rrule.options.byweekday;
    }

    // Using recurrenceOptions value number to match with weekdayDates (Mon-0, Tue-1 ... Sun-6)
    scheduleDays.forEach(dayIndex => {
      if (weekdayDates[dayIndex] >= datetimeUtils.getDayStart(moment(), timezone)) {
        toBeUpdateDates.push(weekdayDates[dayIndex]);
      }
    });

    toBeUpdateDates.forEach(date => {
      const sameDayCurrentShifts = shifts.filter(shift => date.isSame(shift.start_time, 'day'));
      const targetShift = sameDayCurrentShifts.find(shift => shift.schedule.id === selectedSchedule.id);
      selectedWorkerIds.forEach(workerId => {
        // Assign only if the worker is on valid working day and shift's staff_required > 0
        const workerEmployment = employments.find(employment => employment.partner.id === workerId);
        const contractStatus = employmentUtils.getDerivedWorkingStatus(date, workerEmployment);
        /*
         If assigning worker to shift with roles, check if staff_required for that role > 0
         If not, check if staff_required for that shift > 0
        */
        const isAssignableShift = selectedScheduleRole
          ? targetShift?.shift_roles.filter(
              shiftRole =>
                shiftRole.role.id === selectedScheduleRole.roleId &&
                shiftRole.role.is_active &&
                shiftRole.staff_required > 0,
            ).length > 0
          : targetShift?.staff_required > 0;
        if (contractStatus !== derivedWorkingStatus.ON_BREAK && isAssignableShift) {
          updates.push({
            date,
            workerId,
            targetShiftId: targetShift?.id,
            scheduleId: selectedSchedule.id,
            targetRoleId: selectedScheduleRole?.roleId,
          });
        }
      });
    });
    this.handleAssignmentUpdate(updates);
  };

  handleAutoAssign = async (maxWorkDays = null) => {
    const { originalShifts, weekdayDates, timezone, locationId, shifts } = this.state;
    const { clientId } = this.props;
    // special shifts with recommended_attendances
    this.setState({ autoAssignLoading: true });
    // Get list of unique position ids, loop through them and call recommended-shifts api
    const uniquePositionIds = [...new Set(originalShifts.map(shift => shift.position.id))];
    const requests = uniquePositionIds.map(positionId => {
      return shiftApi.getRecommendedShifts({
        shift_id: originalShifts.map(shift => shift.id).join(','),
        start_date: moment(minBy(weekdayDates, date => date))
          .tz(timezone)
          .toISOString(true),
        end_date: moment(maxBy(weekdayDates, date => date))
          .tz(timezone)
          .toISOString(true),
        max_work_days_per_worker: maxWorkDays,
        location: locationId,
        client: clientId,
        position: positionId,
      });
    });
    try {
      const responses = await Promise.all(requests);
      const futureAssignableShifts = responses.flat();
      this.setState({ autoAssignLoading: false });
      /*
        Update for future shifts only
        Call handleAssignmentUpdate, with special updates payload for parent -
        [{targetShiftId, date, workerId, targetRoleId}]
      */
      const updates = futureAssignableShifts.flatMap(shift => {
        return shift.recommended_attendances.map(att => ({
          targetShiftId: att.shift_id,
          date: shift.start_time,
          workerId: att.partner_id,
          targetRoleId: att.role_id,
          scheduleId: shifts.find(shiftValue => shiftValue.id === att.shift_id)?.schedule.id,
        }));
      });
      // Get unique list of schedule ids to expand the respective schedule rows where workers are assigned to
      const scheduleTableExpandedRowKeys = [...new Set(updates.map(update => update.scheduleId))];
      this.setState({ scheduleTableExpandedRowKeys });
      this.handleAssignmentUpdate(updates);
    } catch (error) {
      Sentry.captureException(error);
      this.setState({ autoAssignLoading: false });
      throw error; // throw up to caller
    }
  };

  shouldAllowAutoAssign = () => {
    const { originalShifts, weekdayDates } = this.state;
    // disable if no shifts to assign for
    if (originalShifts.length === 0) {
      return false;
    }

    // disable only if week has passed
    const earliestDay = max(weekdayDates);
    return moment().isBefore(earliestDay.clone().subtract(1, 'day'));
  };

  shouldNavigateAway = publishCount => {
    const { t } = this.props;
    if (publishCount > 0) {
      // eslint-disable-next-line no-alert
      const confirmNavigateAway = window.confirm(t('shiftsNavigateAwayWarning'));
      if (!confirmNavigateAway) {
        return false;
      }
    }
    return true;
  };

  render() {
    const {
      locationId,
      locationLoading,
      allSchedules,
      timezone,
      selectedDateStart,
      scheduleLoading,
      addScheduleModalVisible,
      shifts,
      originalShifts,
      weekdayDates,
      employments,
      autoAssignLoading,
      scheduleTableExpandedRowKeys,
      employmentsLoading,
      selectedDateRange,
    } = this.state;

    const { locations, t, clientId, clientCountry } = this.props;
    const schedules = uniqBy(
      shifts.map(shift => shift.schedule),
      'id',
    );

    if (locationLoading) {
      return <LoadingSpinner />;
    }

    const locationIdsWithSchedule = uniq(allSchedules.map(schedule => schedule.location.id));
    const orderedLocations = sortBy(locations, 'name');
    const publishCount = this.getPublishCount();

    // Prompt warning on close and refresh window if there is new attendance to publish
    window.onbeforeunload = () => (publishCount > 0 ? '' : null);

    return (
      <div style={{ paddingBottom: '50px' }}>
        <Row>
          <Title level={2} style={{ paddingRight: '24px', display: 'inline', marginBottom: '24px' }}>
            {t('shifts')}
          </Title>
        </Row>
        <Row>
          {/* Location filter */}
          <Col span={6}>
            <Select
              loading={locationLoading}
              placeholder={t('selectLocation')}
              style={{ width: '100%' }}
              filterOption={false}
              onChange={locationIdValue =>
                this.shouldNavigateAway(publishCount) &&
                this.setState(
                  {
                    locationId: locationIdValue,
                    timezone:
                      locations.find(location => location.id === locationIdValue)?.address?.area.city.timezone ||
                      clientUtils.getTimezoneFromClientCountry(clientCountry),
                  },
                  () => this.handleLocationChange(),
                )
              }
              value={locationId}
            >
              <OptGroup label={t('hasSchedules').toUpperCase()}>
                {orderedLocations
                  .filter(({ id }) => locationIdsWithSchedule.includes(id))
                  .map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
              </OptGroup>
              <OptGroup label={t('noSchedules').toUpperCase()}>
                {orderedLocations
                  .filter(({ id }) => !locationIdsWithSchedule.includes(id))
                  .map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
              </OptGroup>
            </Select>
          </Col>
        </Row>
        {!locationLoading && locationId && clientId && timezone && (
          <>
            <Row>
              <div style={{ display: 'flex', marginTop: '48px' }}>
                <Col span={20}>
                  <WeekMonthSelector
                    selectedDateRange={selectedDateRange}
                    selectedDateStart={selectedDateStart}
                    loading={scheduleLoading}
                    onWeekChange={isoDate => this.shouldNavigateAway(publishCount) && this.handleWeekSelect(isoDate)}
                    onMonthChange={isoDate => this.shouldNavigateAway(publishCount) && this.handleMonthSelect(isoDate)}
                    shouldNavigateAway={() => this.shouldNavigateAway(publishCount)}
                    pageName="Shift page"
                  />
                </Col>
                <Col span={4}>
                  <Row type="flex" justify="end">
                    <Button
                      disabled={scheduleLoading}
                      onClick={() => {
                        this.setState({ addScheduleModalVisible: true });
                      }}
                    >
                      {`+ ${t('addSchedule')}`}
                    </Button>
                  </Row>
                </Col>
              </div>
            </Row>
            <Row type="flex" justify="end" style={{ marginBottom: '8px' }}>
              <CopyAssignmentButtonModal
                weekdayDates={weekdayDates}
                disabled={publishCount || employmentsLoading}
                timezone={timezone}
                onCopy={this.handleCopyShift}
                selectedDateRange={selectedDateRange}
              />
              <AutoAssignButtonModal
                disabled={!this.shouldAllowAutoAssign || (schedules.length === 0 && shifts.length === 0)}
                onAutoAssign={this.handleAutoAssign}
                confirmLoading={autoAssignLoading}
                selectedDateRange={selectedDateRange}
              />
              <Button onClick={this.onClickPublish} type="v2-primary" disabled={!publishCount}>
                {t('publish')} ({publishCount})
              </Button>
            </Row>
            {/* Scheduling */}
            {!scheduleLoading && schedules.length === 0 && shifts.length === 0 && clientId ? (
              <Empty
                image={emptyState}
                imageStyle={{ height: 200, alignContent: 'center' }}
                description={
                  <>
                    <Row style={{ marginTop: '32px' }}>
                      <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{t('emptyScheduleTitleV2')}</Text>
                    </Row>
                    <Row style={{ marginTop: '8px' }}>
                      <Text>{t('emptyScheduleDescriptionV2')}</Text>
                    </Row>
                    <Row style={{ marginTop: '8px' }}>
                      <Button
                        onClick={() => {
                          this.setState({ addScheduleModalVisible: true });
                        }}
                      >
                        {`+ ${t('addSchedule')}`}
                      </Button>
                    </Row>
                  </>
                }
              />
            ) : (
              <ScheduleListView
                loading={scheduleLoading}
                schedules={schedules}
                shifts={shifts}
                originalShifts={originalShifts}
                weekdayDates={weekdayDates}
                onScheduleUpdate={this.handleRefresh}
                timezone={timezone}
                employments={employments}
                employmentsLoading={employmentsLoading}
                onRefresh={this.handleRefresh}
                onAssignmentUpdate={this.handleAssignmentUpdate}
                onRoleUpdate={this.handleRoleUpdate}
                onAddWorkersToShift={this.handleAddWorkersToShift}
                clientId={clientId}
                scheduleTableExpandedRowKeys={scheduleTableExpandedRowKeys}
                selectedDateRange={selectedDateRange}
              />
            )}
            {addScheduleModalVisible && (
              <AddScheduleFormModal
                visible={addScheduleModalVisible}
                onCancel={() => this.setState({ addScheduleModalVisible: false })}
                onUpdate={() => {
                  this.setState({ addScheduleModalVisible: false });
                  this.handleRefresh();
                }}
                selectedLocation={locations.find(location => location.id === locationId)}
                timezone={timezone}
                clientId={clientId}
              />
            )}
          </>
        )}
        {!scheduleLoading && !locationLoading && !locationId && clientId && (
          <div>
            <Divider style={{ marginBottom: '48px' }} />
            <Empty
              image={emptyState}
              imageStyle={{ height: 200, alignContent: 'center' }}
              description={
                <>
                  <Row style={{ marginTop: '32px' }}>
                    <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{t('emptyLocation')}</Text>
                  </Row>
                  <Row style={{ marginTop: '8px', marginBottom: '8px' }}>
                    <Text>{t('emptyLocationDescription')}</Text>
                  </Row>
                  <Row>
                    <Button
                      onClick={() => {
                        const route = routes.settings.replace(':tab', settingsTabs.LOCATIONS);
                        this.props.history.push(route);
                      }}
                    >
                      {t('emptyLocationSettingsRedirect')}
                    </Button>
                  </Row>
                </>
              }
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locations: state.user.locations,
  clientId: state.user.clientId,
  clientCountry: state.user.country.code,
});

export default withTranslation()(connect(mapStateToProps)(ShiftPage));
