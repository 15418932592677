import React from 'react';
import { Switch } from 'react-router-dom';
import routes from '../../routes';
import RouteWithProps from '../../shared/components/RouteWithProps';
import StaffRequestTemplateFormView from './StaffRequestTemplateFormView';
import StaffRequestTemplateListView from './StaffRequestTemplateListView';

const StaffRequestTemplatePage = () => {
  return (
    <Switch>
      <RouteWithProps exact path={routes.staffRequestTemplates} component={StaffRequestTemplateListView} />
      <RouteWithProps
        exact
        path={[routes.createJobTemplate, routes.editJobTemplate]}
        component={StaffRequestTemplateFormView}
      />
    </Switch>
  );
};

export default StaffRequestTemplatePage;
