import React from 'react';
import { CheckCircleFilled, MessageOutlined, MinusCircleFilled } from '@ant-design/icons';
import { Row, Col, Button, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';

import ConfirmModals from '../../../shared/components/ConfirmModals';
import workerUtils from '../../../utilities/workerUtils';
import { applicationStatuses, smsCopytextTranslations, inAppNotificationTranslations } from '../../../constants';
import { colors } from '../../../styles/colors';
import staffRequestUtils from '../../../utilities/staffRequestUtils';

const disabledOfferedButton = [
  applicationStatuses.PENDING_CONTRACT,
  applicationStatuses.REJECTED,
  applicationStatuses.WITHDRAWN,
];

const disabledRejectedButton = [applicationStatuses.REJECTED, applicationStatuses.WITHDRAWN];

const ApplicationActions = ({
  application,
  worker,
  onHire,
  onReject,
  onChat,
  t,
  alreadyHired = false,
  onRemindWorker = null,
  onChangeOfferExpiry,
}) => {
  const workerName = workerUtils.getWorkerName(worker);
  const countryCode = application?.staff_request?.client?.country?.code;
  const clientName = application?.staff_request?.client?.name;
  const jobTitle = application?.staff_request?.title;

  const translatedSmsMessage = smsCopytextTranslations[countryCode](clientName, application?.staff_request?.id);
  const translatedInAppNotification = inAppNotificationTranslations[countryCode](clientName, jobTitle);
  const ChatButton = () => (
    <Tooltip placement="top" title={t('privateChat')}>
      <Button
        disabled={
          [applicationStatuses.REJECTED, applicationStatuses.WITHDRAWN].includes(application.status) || alreadyHired
        }
        icon={<MessageOutlined />}
        onClick={e => {
          e.stopPropagation();
          onChat(application.partner);
        }}
      >
        {t('chatButton')}
      </Button>
    </Tooltip>
  );

  const OfferedButton = ({ staffRequestId }) => (
    <Tooltip placement="top" title={t('sendAnOfferTooltip')}>
      <Button
        disabled={disabledOfferedButton.includes(application.status) || alreadyHired}
        onClick={e => {
          e.stopPropagation();
          ConfirmModals.hireWorker(
            workerName,
            translatedSmsMessage,
            translatedInAppNotification,
            t,
            onChangeOfferExpiry,
            false,
            // eslint-disable-next-line no-undef
            sessionStorage.getItem(staffRequestUtils.generateOfferExpirySessionStorageKey(staffRequestId)),
            () => {
              onHire(application.id, workerName);
            },
          );
        }}
      >
        <CheckCircleFilled
          style={{
            color:
              alreadyHired || disabledOfferedButton.includes(application.status)
                ? colors.disabled
                : colors.functionalSuccess,
          }}
        />
      </Button>
    </Tooltip>
  );

  const RejectButton = () => (
    <Tooltip placement="top" title={t('reject')}>
      <Button
        disabled={disabledRejectedButton.includes(application.status) || alreadyHired}
        onClick={e => {
          e.stopPropagation();
          onReject(application.id, workerName);
        }}
      >
        <MinusCircleFilled
          style={{
            color:
              alreadyHired || disabledRejectedButton.includes(application.status)
                ? colors.disabled
                : colors.functionalError,
          }}
        />
      </Button>
    </Tooltip>
  );

  const RemindWorkerButton = () => (
    <Button
      disabled={application.last_remind_time}
      onClick={async e => {
        e.stopPropagation();
        onRemindWorker(application.id, workerName);
      }}
    >
      {t('remindWorker')}
    </Button>
  );

  if (!application) return null;

  return (
    <Row type="flex" gutter={8} style={{ marginLeft: 4 }}>
      {![applicationStatuses.REJECTED, applicationStatuses.WITHDRAWN].includes(application.status) && !alreadyHired && (
        <Col>
          <ChatButton />
        </Col>
      )}
      {!disabledOfferedButton.includes(application?.status) && !alreadyHired && !application.shortlisted && (
        <Col>
          <OfferedButton staffRequestId={application?.staff_request?.id} />
        </Col>
      )}
      {application.status === applicationStatuses.PENDING_CONTRACT && onRemindWorker && (
        <Col>
          <RemindWorkerButton />
        </Col>
      )}
      {!disabledRejectedButton.includes(application?.status) && !alreadyHired && (
        <Col>
          <RejectButton />
        </Col>
      )}
    </Row>
  );
};

export default withTranslation()(ApplicationActions);
