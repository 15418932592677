/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Modal, Typography, Steps } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { colors } from '../../styles/colors';
import spvQRCodeStaging from '../../assets/images/spv_qr_code_staging.jpeg';
import spvQRCodeProd from '../../assets/images/spv_qr_code_prod.jpeg';
import { CUSTOMER_SUPPORT_SG_EMAIL, WORKMATE_BUSINESS_APP_LINK } from '../../config';
import { environment, getEnvironment } from '../../utilities';

const { Title, Text, Paragraph } = Typography;
const { Step } = Steps;

const OnboardingModal = ({
  title,
  description,
  visible,
  currentActiveStep = 0,
  onClose,
  onOk,
  okText,
  cancelText,
  showQr = false,
  hasSPVDownloadLink = false,
  ...props
}) => {
  const { t } = useTranslation();

  const spvQrCode = getEnvironment() === environment.PRODUCTION ? spvQRCodeProd : spvQRCodeStaging;

  let steps = [
    {
      title: t('selfServeOnboardingStepOneTitle'),

      description: t('selfServeOnboardingStepOneDescription'),
    },
    {
      title: t('selfServeOnboardingStepTwoTitle'),
      description: t('selfServeOnboardingStepTwoDescription'),
    },
    {
      title: t('selfServeOnboardingStepThreeTitle'),
      description: t('selfServeOnboardingStepThreeDescription'),
    },
    {
      title: hasSPVDownloadLink ? (
        <Trans i18nKey="selfServeOnboardingStepFourTitleLink">
          Download our
          <a
            href={WORKMATE_BUSINESS_APP_LINK}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: colors.functionalLink, textDecoration: 'underline' }}
          >
            Workmate for Business App
          </a>
          App
        </Trans>
      ) : (
        t('selfServeOnboardingStepFourTitle')
      ),
      description: t('selfServeOnboardingStepFourDescription'),
    },
  ];

  steps = steps.map((step, index) => {
    return {
      title: (
        <Text
          type="secondary"
          strong
          style={{ color: currentActiveStep > index ? colors.secondaryText : colors.textTitle }}
        >
          {step.title}
        </Text>
      ),
      description: (
        <Text style={{ color: currentActiveStep > index ? colors.secondaryText : colors.textLightBlack }}>
          {step.description}
        </Text>
      ),
    };
  });

  return (
    <Modal
      width={534}
      visible={visible}
      onCancel={() => {
        onClose();
      }}
      okText={okText}
      cancelText={cancelText}
      okButtonProps={{ type: 'v2-primary' }}
      onOk={() => {
        onClose();
        onOk();
      }}
      closable={false}
      {...props}
    >
      <Row style={{ textAlign: 'center', marginBottom: 24 }}>
        <Col span={24}>
          <Title level={3} style={{ marginBottom: 4, fontSize: 24, fontWeight: '600' }}>
            {title}
          </Title>
          <Text style={{ color: colors.primaryText }}>{description}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Steps direction="vertical" size="small" current={currentActiveStep} className="v2-steps-wrapper" responsive>
            {steps.map(step => (
              <Step key={step.title} title={step.title} description={step.description} />
            ))}
          </Steps>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          {showQr && (
            <Row style={{ alignItems: 'center', justifyContent: 'center', marginBottom: 20, flexDirection: 'column' }}>
              <Paragraph style={{ color: colors.primaryText }}>{t('scanQRToDownload')}</Paragraph>
              <img src={spvQrCode} alt="spv qrcode" style={{ width: 120, height: 120, objectFit: 'contain' }} />
            </Row>
          )}
          <Text type="secondary">
            <Trans i18nKey="contactSuccessManagerHelpMessage">
              Need help? We have assigned a Success Manager to onboard you. You can contact them
              <a
                href={`mailto:${CUSTOMER_SUPPORT_SG_EMAIL}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline' }}
              >
                {' '}
                here.
              </a>
            </Trans>
          </Text>
        </Col>
      </Row>
    </Modal>
  );
};

export default OnboardingModal;
