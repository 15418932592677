import React from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, Typography } from 'antd';

import WagesForm from './WagesForm';

const WagesUpdateModal = ({ t, visible, confirmLoading, closeModal, onConfirm }) => (
  <Modal
    destroyOnClose
    style={{ top: 20 }}
    bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 10 }}
    title={<Typography.Text style={{ fontWeight: 600, fontSize: 24 }}>{t('setWages')}</Typography.Text>}
    footer={null}
    cancelText={t('cancel')}
    visible={visible}
    confirmLoading={confirmLoading}
    onCancel={closeModal}
  >
    <WagesForm onSubmit={onConfirm} onCancel={closeModal} />
  </Modal>
);

export default withTranslation()(WagesUpdateModal);
