import React from 'react';
import { Typography } from 'antd';
import { colors } from '../../../styles/colors';

const MCQTextDescription = ({ hasScore, knockout, description }) => {
  const getTextColor = () => {
    let color = '';
    if (hasScore) {
      color = colors.functionalSuccess;
    } else if (knockout) {
      color = colors.functionalError;
    } else {
      color = colors.primaryText;
    }
    return color;
  };

  return (
    <Typography.Text
      style={{
        color: getTextColor(),
        fontWeight: hasScore || knockout ? 600 : 400,
      }}
    >
      {description}
    </Typography.Text>
  );
};

export default MCQTextDescription;
