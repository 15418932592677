import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import { Form, Modal, Typography, Input, AutoComplete } from 'antd';
import { colors } from '../../../styles/colors';

const MAX_NOTES_LENGTH = 100;

const ApplicantRejectionModal = ({ visible, title, applicationRejectReasons = [], onReject, onClose, t }) => {
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  const handleOnFinish = async ({ reason, notes }) => {
    try {
      setSubmitting(true);
      const selectedReason = applicationRejectReasons.find(rejectionReason => rejectionReason.name === reason);
      await onReject(selectedReason?.code, notes);
      onClose();
    } catch (error) {
      setSubmitting(false);
      onClose();
    }
  };

  return (
    <Modal
      title={
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          {title}
        </Typography.Title>
      }
      visible={visible}
      onCancel={onClose}
      okText={t('reject')}
      okType="primary"
      confirmLoading={submitting}
      onOk={form.submit}
      okButtonProps={{ danger: true }}
      zIndex={99998}
      className="applicant-rejection-modal"
    >
      <Typography.Text style={{ color: colors.primaryText, marginBottom: 16, display: 'block' }}>
        {t('applicantRejectionModalDescription')}
      </Typography.Text>
      <Form hideRequiredMark form={form} onFinish={handleOnFinish}>
        <Form.Item
          name="reason"
          colon={false}
          label={t('applicantRejectSelectReasonLabel')}
          rules={[
            {
              required: true,
              message: t('applicantRejectSelectReasonLabel'),
            },
            {
              validator(rule, value, callback) {
                if (value && !applicationRejectReasons.find(reason => reason.name === value)) {
                  callback(t('chooseFromListError'));
                }
                callback();
              },
            },
          ]}
          style={{
            marginBottom: 16,
            display: 'block',
          }}
        >
          <AutoComplete
            style={{ width: '100%' }}
            filterOption
            options={applicationRejectReasons.map(reason => ({ value: reason.name }))}
            notFoundContent={t('searchNothingFoundMsg')}
            allowClear
            dropdownStyle={{ zIndex: 99998 }}
          >
            <Input placeholder={t('applicantRejectSelectReasonPlaceholder')} suffix={<DownOutlined />} />
          </AutoComplete>
        </Form.Item>

        <Form.Item
          colon={false}
          label={t('applicantRejectAddNotesLabel')}
          style={{
            marginBottom: 16,
            display: 'block',
          }}
          name="notes"
          rules={[
            {
              max: MAX_NOTES_LENGTH,
              message: t('notesMaximumCharacterError', { maxCount: MAX_NOTES_LENGTH }),
            },
          ]}
        >
          <Input.TextArea rows={5} placeholder={t('applicantRejectAddNotesPlaceholder')} maxLength={100} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default withTranslation()(ApplicantRejectionModal);
