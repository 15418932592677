import React from 'react';
import moment from 'moment';
import { isEqual, intersection, isEmpty } from 'lodash';
import { Row, message, Modal, Typography } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import shiftApi from '../../services/shiftApi';
import { attendanceStatuses, DATE_FORMAT, unsetCode, assignmentStatuses } from '../../constants';
import datetimeUtils from '../../utilities/datetimeUtils';
import attendanceUtils from '../../utilities/attendanceUtils';
import employmentUtils from '../../utilities/employmentUtils';
import AssignmentFilterAndAction from './components/AssignmentFilterAndAction';
import AssignmentTable from './components/AssignmentTable';

const { Text } = Typography;

class AssignmentListView extends React.Component {
  state = {
    submitting: false,
    selectedRowKeys: [],
    filteredPartnerIds: [], // Partner Id list pass into table as key filter
    searchFilter: [],
    scheduleFilterValue: [], // Default to select all schedule selection
    statusFilterValue: [], // Default to select all status selection
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.dayDates, this.props.dayDates)) {
      this.resetFilter();
    }
  }

  resetFilter = () => {
    this.setState({
      scheduleFilterValue: [],
      statusFilterValue: [],
      searchFilter: [],
      filteredPartnerIds: [],
    });
  };

  // Compare current shift with original shift, if any attendance changed,
  // 1) update the shift, 2) notify the worker
  onClickPublish = async () => {
    const { dayDates, t } = this.props;

    const [pendingUpdateShifts, workersToNotify] = this.getShiftsAndWorkers();
    const startDate = moment(dayDates[0]).format(DATE_FORMAT);
    const endDate = moment(dayDates[6]).format(DATE_FORMAT);
    const workersCount = workersToNotify.length;

    Modal.confirm({
      centered: true,
      title: <Text strong>{t('publishShiftsTitle', { startDate, endDate })}</Text>,
      content: <Text>{t('publishShiftsBody', { workersCount })}</Text>,
      okText: t('publish'),
      okType: 'v2-primary',
      cancelText: t('cancel'),
      icon: <QuestionCircleTwoTone style={{ fontSize: '20px' }} />,
      onOk: () => this.handlePublish(pendingUpdateShifts, workersToNotify),
    });
  };

  getShiftsAndWorkers = () => {
    const { shifts, originalShifts } = this.props;

    const pendingUpdateShifts = [];
    const workersToNotify = [];

    for (let i = 0; i < shifts.length; i += 1) {
      const newAttendances = shifts[i].attendances;
      const originalAttendances = originalShifts[i].attendances;

      if (!isEqual(newAttendances, originalAttendances)) {
        pendingUpdateShifts.push({
          id: shifts[i].id,
          attendances: shifts[i].attendances,
        });

        // A worker should be notified for three cases:
        // 1) New attendance (first time assigned to this shift)
        // 2) Attendance changed from assgined to unassigned (assigned off day or assigned to a different shift)
        // 3) Attendance changed from unassigned to assigned (previously assigned then unassigned, now back to assigned)
        newAttendances.forEach(attendance => {
          const matchingOriginalAttendance = originalAttendances.find(
            originalAtt => originalAtt.partner_id === attendance.partner_id,
          );
          // Case 1, new attendance
          if (!matchingOriginalAttendance) {
            workersToNotify.push(attendance.partner_id);
          } else {
            // Case 2 and Case 3, existing attendance has status changed
            // eslint-disable-next-line no-lonely-if
            if (matchingOriginalAttendance.status !== attendance.status) {
              workersToNotify.push(attendance.partner_id);
            }
          }
        });
      }
    }
    const uniqueWorkersToNotify = [...new Set(workersToNotify)];
    return [pendingUpdateShifts, uniqueWorkersToNotify];
  };

  handlePublish = async (shifts, workers) => {
    const { t, onRefresh } = this.props;
    this.setState({ submitting: true });

    const publishPromise = shifts.map(pendingUpdateShift => shiftApi.editShift(pendingUpdateShift));
    try {
      await Promise.all(publishPromise);
      await shiftApi.notifyPartner({
        client_id: this.props.clientId,
        partner_id: workers.join(','),
      });
      message.success(t('shiftPublishSuccess', { workerCount: workers.length }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      this.setState({ submitting: false });
      onRefresh();
    }
  };

  /***************** Bulk Assign Start ***********************/
  handleSelect = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  /* 1.Based on the selected date and schedules, find the target shifts need to update
     2.Check of each worker, find their original shift, current shift
     3.Reassign worker to the target shift
  */
  handleBulkAssign = (scheduleId, selectedScheduleDays) => {
    const selectedWorkerIds = this.state.selectedRowKeys;
    const { dayDates, shifts, timezone, employments } = this.props;
    const toBeUpdateDates = [];

    // Using recurrenceOptions value number to match with dayDates (Mon-0, Tue-1 ... Sun-6)
    selectedScheduleDays.forEach(dayIndex => {
      // only bulk assign future shifts
      if (dayDates[dayIndex] >= datetimeUtils.getDayStart(moment(), timezone)) {
        toBeUpdateDates.push(dayDates[dayIndex]);
      }
    });
    const updates = [];

    toBeUpdateDates.forEach(date => {
      const sameDayCurrentShifts = shifts.filter(shift => date.isSame(shift.start_time, 'day'));
      const targetShift = sameDayCurrentShifts.find(shift => shift.schedule.id === scheduleId);
      selectedWorkerIds.forEach(workerId => {
        // bulk assign only if the worker is on valid working day
        const workerEmployment = employments.find(employment => employment.partner.id === workerId);
        const contractStatus = employmentUtils.getDerivedWorkingStatus(date, workerEmployment);
        if (attendanceUtils.isValidWorkingDay(contractStatus)) {
          updates.push({
            date,
            workerId,
            targetShiftId: targetShift?.id,
          });
        }
      });
    });

    this.handleUpdates(updates);
    this.setState({ selectedRowKeys: [] });
  };

  /***************** Bulk Assign End ***********************/

  /***************** Filters Start ***********************/

  handleSearchChange = value => {
    this.setState({ searchFilter: value }, () => this.updateFilter());
  };

  handleScheduleChange = value => {
    this.setState({ scheduleFilterValue: value }, () => this.updateFilter());
  };

  handleStatusChange = value => {
    this.setState({ statusFilterValue: value }, () => this.updateFilter());
  };

  getFilteredAttendances = (shifts, isConfirmed, hasAbsenceReason, isNoShow) => {
    return shifts
      .flatMap(shift =>
        shift.attendances.filter(
          ({ status, confirmed, absence_reason, absence_detail, timesheet_entries }) =>
            status === attendanceStatuses.ASSIGNED &&
            (isConfirmed != null ? confirmed === isConfirmed : true) &&
            // for backward compatibility
            (hasAbsenceReason ? absence_reason || absence_detail : !absence_reason || !absence_detail) &&
            (isNoShow != null ? !attendanceUtils.hasNonVoidTimesheetEntry(timesheet_entries) : true),
        ),
      )
      .map(data => data.partner_id);
  };

  getStatusFilteredPartnerIds = () => {
    const { scheduleFilterValue, statusFilterValue } = this.state;
    const { shifts, assignmentData } = this.props;
    const statusFilteredPartnerIds = [];

    const filteredShifts = !isEmpty(scheduleFilterValue)
      ? shifts.filter(shift => scheduleFilterValue.includes(shift.schedule.id.toString()))
      : shifts;
    const shiftsAfter = filteredShifts.filter(shift => moment(shift.start_time).isAfter(moment()));

    if (statusFilterValue.includes(assignmentStatuses.CONFIRMED)) {
      const filterResults = this.getFilteredAttendances(shiftsAfter, true, false);
      statusFilteredPartnerIds.push(...filterResults);
    }

    if (statusFilterValue.includes(assignmentStatuses.UNCONFIRMED)) {
      const filterResults = this.getFilteredAttendances(shiftsAfter, false, false);
      statusFilteredPartnerIds.push(...filterResults);
    }

    if (statusFilterValue.includes(assignmentStatuses.LEAVE)) {
      const filterResults = this.getFilteredAttendances(filteredShifts, null, true);
      statusFilteredPartnerIds.push(...filterResults);
    }

    if (statusFilterValue.includes(assignmentStatuses.NO_SHOW)) {
      const shiftsBefore = filteredShifts.filter(shift => moment(shift.start_time).isBefore(moment(), 'day'));
      const filterResults = this.getFilteredAttendances(shiftsBefore, null, false, true);
      statusFilteredPartnerIds.push(...filterResults);
    }

    if (statusFilterValue.includes(assignmentStatuses.NO_SHIFT_THIS_WEEK)) {
      const filterResults = assignmentData.filter(data => data.assignedCount === 0).map(data => data.worker.id);
      statusFilteredPartnerIds.push(...filterResults);
    }

    return statusFilteredPartnerIds;
  };

  updateFilter = () => {
    const { searchFilter, scheduleFilterValue, statusFilterValue } = this.state;
    const { employments, shifts } = this.props;
    const allPartnerList = employments.map(employment => employment.partner.id);

    //name search filtering
    const searchFilterResults = isEmpty(searchFilter) ? allPartnerList : searchFilter;

    //schedule filtering
    const shiftsFilteredBySched = isEmpty(scheduleFilterValue)
      ? allPartnerList
      : shifts
          .filter(shift => scheduleFilterValue.includes(shift.schedule.id.toString()))
          .flatMap(shift => shift.attendances.filter(attendance => attendance.status === attendanceStatuses.ASSIGNED))
          .map(data => data.partner_id);

    //assignment status filtering
    const statusFilteredPartnerIds = isEmpty(statusFilterValue) ? allPartnerList : this.getStatusFilteredPartnerIds();

    const filteredPartnerIds = intersection(shiftsFilteredBySched, searchFilterResults, statusFilteredPartnerIds);
    this.setState({
      filteredPartnerIds: isEmpty(filteredPartnerIds) ? [unsetCode] : filteredPartnerIds,
    });
  };

  /***************** Filters End ***********************/

  handleCopyShift = fromDate => {
    this.props.onCopy(fromDate);
  };

  handleUpdates = updates => {
    this.props.onUpdate(updates);
  };

  render() {
    const {
      submitting,
      selectedRowKeys,
      filteredPartnerIds,
      scheduleFilterValue,
      statusFilterValue,
      searchFilter,
    } = this.state;
    const {
      dayDates,
      loading: parentLoading,
      schedules,
      employments,
      onRefresh,
      assignmentData,
      timezone,
      selectedDateRange,
    } = this.props;

    return (
      <Row>
        <Row style={{ paddingBottom: 24 }}>
          <AssignmentFilterAndAction
            loading={submitting || parentLoading}
            employments={employments}
            timezone={timezone}
            schedules={schedules}
            dayDates={dayDates}
            selectedWorkerCount={selectedRowKeys.length}
            scheduleFilterValue={scheduleFilterValue}
            statusFilterValue={statusFilterValue}
            searchFilter={searchFilter}
            onBulkAssign={this.handleBulkAssign}
            onScheduleChange={this.handleScheduleChange}
            onStatusChange={this.handleStatusChange}
            onSearchChange={this.handleSearchChange}
            selectedDateRange={selectedDateRange}
          />
        </Row>
        {/* Shifts Table */}
        <AssignmentTable
          loading={submitting || parentLoading}
          selectedRowKeys={selectedRowKeys}
          dataSource={assignmentData}
          dayDates={dayDates}
          filteredPartnerIds={filteredPartnerIds}
          onUpdate={this.handleUpdates}
          onSelect={this.handleSelect}
          onRefresh={onRefresh}
          timezone={timezone}
          selectedDateRange={selectedDateRange}
        />
      </Row>
    );
  }
}

export default withTranslation()(AssignmentListView);
