import React from 'react';
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Input, message, Modal, Row, Select, Switch, Tooltip, Typography } from 'antd';
import { debounce, sortBy, size, uniq } from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { withTranslation, Trans } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as globalActions from '../../redux/global';
import timesheetEntryApi from '../../services/timesheetApi';
import shiftApi from '../../services/shiftApi';
import absenceDetailsApi from '../../services/absenceDetailsApi';

import fetchAll from '../../utilities/apiUtils';
import AttendanceHeaderSummary from './components/AttendanceHeaderSummary';
import WeekSelectGroup from '../../shared/components/WeekSelectGroup';
import WeeklyAttendanceTable from './components/WeeklyAttendanceTable';
import AddEntryModal from './components/AddEntryModal';
import AttendanceDetail from './components/AttendanceDetail';
import WeeklySummaryTable from './components/WeeklySummaryTable';
import TreeCheckboxFilter from '../../shared/components/TreeCheckboxFilter';

import {
  timesheetEntryStatuses,
  derivedWorkingStatus,
  attendanceStatuses,
  paymentFrequency,
  roles as userRoles,
  settingsTabs,
  roundingTypesTranslation,
} from '../../constants';
import dateUtils from '../../utilities/dateUtils';
import employmentUtils from '../../utilities/employmentUtils';
import { colors } from '../../styles/colors';
import AttendanceCSVExportModal from './components/AttendanceCSVExportModal';
import { retrieveParamsFromUrl, prepParams, updateUrlWithParams } from '../../utilities/urlUtils';
import {
  convertPositionAndRoleToList,
  formatPositionAndRoleTag,
  getPositionAndRoleOptions,
} from '../../utilities/positionAndRoleUtils';
import SquareIcon from '../../shared/components/SquareIcon';
import { SERVER_DATE } from '../../styles/dates';
import withQueryAndGraphQLClient from '../../graphql/helpers/withQueryAndGraphQLClient';
import { GET_EMPLOYMENTS } from '../../graphql/queries/employment';
import approveAllAttendances from './utils/attendances';
import routes from '../../routes';
import leaveEntriesApi from '../../services/leaveEntriesApi';

const DEFAULT_PAGE_SIZE = 20;
const ALL_LOCATION_OPTION_KEY = 'all_locations';

const legendItems = [
  {
    icon: <SquareIcon color={colors.functionalWarning} />,
    labelKey: 'Unconfirmed',
  },
  {
    icon: <SquareIcon color={colors.functionalSuccess} />,
    labelKey: 'Confirmed',
  },
  {
    icon: <SquareIcon color={colors.functionalApproved} />,
    labelKey: 'approved',
  },
  {
    icon: <CheckCircleOutlined style={{ color: colors.functionalApproved }} />,
    labelKey: 'paid',
  },
  {
    icon: <SquareIcon color={colors.functionalError} />,
    labelKey: 'disputed',
  },
  {
    icon: <ExclamationCircleOutlined style={{ color: colors.functionalWarning }} />,
    labelKey: 'multipleTimesheets',
  },
];

class AttendancePage extends React.Component {
  state = {
    loading: false,
    shiftsLoading: false,
    employmentsLoading: false,
    timesheetStatisticsLoading: false,
    showAttendanceDetail: false,
    showAddEntryModal: false,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    totalEmploymentsCount: 0,
    employments: [],
    timesheetEntries: [],
    leaveEntries: [],
    shifts: [],
    confirmedTimesheetsCount: 0,
    disputedTimesheetsCount: 0,
    unconfirmedTimesheetsCount: 0,
    search: undefined,
    selectedDateStart: moment().startOf('isoWeek'),
    selectedLocations: this.props.locations.map(({ id }) => id), // single, or all locations
    selectedTimesheetEntries: undefined,
    timesheetEntryStatistics: undefined,
    selectedLeaveEntries: undefined,
    selectedEmployment: undefined,
    selectedTimesheetDate: undefined,
    selectedAttendance: undefined,
    showAttendanceCsvExportModal: false,
    selectedPositionAndRole: undefined,
    selectedPaymentFrequency: [],
    positionAndRoleOptions: [],
    absenceDetailsStatistics: undefined,
    showRoundedTime: true,
    selectedDates: [],
    isApproving: false,
    isConfirming: false,
  };

  handleLocationsSelectorChange = debounce(selectedLocation => {
    if (selectedLocation.key === ALL_LOCATION_OPTION_KEY) {
      const { locations } = this.props;
      this.setState({ page: 1, selectedLocations: locations.map(({ id }) => id) }, this.fetchData); // must reset page
    } else {
      this.setState({ page: 1, selectedLocations: [selectedLocation.key] }, this.fetchData); // must reset page
    }
  }, 400);

  async componentDidMount() {
    const { fetchVoidReasons, positions, roles, t, fetchLeaveEntryTypes, fetchLeaveEntryVoidReasons } = this.props;
    const positionAndRoleOptions = await getPositionAndRoleOptions(positions, roles, t);

    fetchVoidReasons();
    fetchLeaveEntryTypes();
    fetchLeaveEntryVoidReasons();
    await this.updateStateFromParams(); // Get params from url
    await this.fetchData();

    this.setState({
      positionAndRoleOptions,
    });
  }

  onSearchChange = debounce(search => {
    this.handleSearch(search);
  }, 600);

  handleDateSelect = value => {
    this.setState(
      {
        page: 1, // to prevent fetching non-existent page
        selectedDateStart: moment(value).startOf('isoWeek'),
        timesheetEntryStatistics: undefined,
        selectedDates: [],
      },
      this.fetchData,
    );
  };

  updateStateFromParams = async () => {
    const { location, selected_week_start } = retrieveParamsFromUrl(this.props.location.search);
    this.setState({
      selectedLocations:
        (location && location.split(',').map(locationItem => parseInt(locationItem, 10))) ||
        this.props.locations.map(({ id }) => id),
      selectedDateStart:
        (selected_week_start && moment(selected_week_start, 'DD-MM-YYYY').startOf('isoWeek')) ||
        moment().startOf('isoWeek'),
    });
  };

  updateParamsFromState = () => {
    const { selectedLocations, selectedPositionAndRole, selectedDateStart, selectedPaymentFrequency } = this.state;
    const { positions, roles, noRoles } = convertPositionAndRoleToList(selectedPositionAndRole);
    updateUrlWithParams(
      {
        location: (selectedLocations && selectedLocations.join(',')) || undefined,
        selected_week_start: selectedDateStart.format('DD-MM-YYYY') || undefined,
        position: positions.join(',') || undefined,
        role: roles.join(',') || undefined,
        no_role: noRoles.join(',') || undefined,
        payment_frequency: selectedPaymentFrequency?.join(',') || undefined,
      },
      this.props.history,
    );
  };

  fetchData = async () => {
    this.setState({ loading: true });
    this.updateParamsFromState();

    const { selectedDateStart } = this.state;
    await Promise.all([
      this.fetchShifts(selectedDateStart),
      this.fetchEmploymentsAndTimesheets(),
      this.fetchTimesheetStatistics(),
      this.fetchAbsenceDetailsStatistics(),
    ]);

    this.setState({ loading: false });
  };

  getEmploymentFetchParams = () => {
    const { selectedDateStart, search, selectedLocations, selectedPaymentFrequency } = this.state;
    const positionsAndRoles = this.getPositionAndRoleParams(true);

    const startDate = selectedDateStart.clone().toISOString();
    const endDate = selectedDateStart
      .clone()
      .endOf('isoWeek')
      .toISOString();

    return {
      endDateAfter: startDate,
      startDateBefore: endDate,
      location: selectedLocations.map(item => `${item}`),
      paymentFrequency: selectedPaymentFrequency,
      search,
      accepted: true,
      ...positionsAndRoles,
    };
  };

  fetchAbsenceDetailsStatistics = async () => {
    try {
      const { selectedDateStart, selectedLocations } = this.state;
      const positionsAndRoles = this.getPositionAndRoleParams();
      const dates = dateUtils.getWeekDatesFromSelectedDate(selectedDateStart);
      const startDate = dates[0];
      const endDate = dates[dates.length - 1];
      const absenceDateBefore = moment(endDate).format(SERVER_DATE);
      const absenceDateAfter = moment(startDate).format(SERVER_DATE);
      const filterParams = {
        absence_date_before: absenceDateBefore,
        absence_date_after: absenceDateAfter,
        location: selectedLocations?.join(','),
        ...positionsAndRoles,
      };
      const absenceDetailsStatistics = await absenceDetailsApi.getStatistics(filterParams);
      this.setState({ absenceDetailsStatistics });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  fetchEmploymentsAndTimesheets = async (updatedTimesheetEntryId = null) => {
    const { graphQLClient, queryClient } = this.props;
    const { page, pageSize } = this.state;
    this.setState({ employmentsLoading: true });
    try {
      const { employments } = await queryClient.fetchQuery(['employments'], () =>
        graphQLClient.request({
          document: GET_EMPLOYMENTS,
          variables: {
            ...this.getEmploymentFetchParams(),
            first: pageSize,
            orderBy: 'partner__first_name',
            offset: pageSize * (page - 1),
          },
        }),
      );

      const timesheetEntries = [];
      const leaveEntries = [];
      const flattenEmployments = [];
      employments?.edges?.forEach(item => {
        flattenEmployments.push(item.node);
        timesheetEntries.push(...item.node.timesheetEntries);
        leaveEntries.push(...item.node.leaveEntries);
      });
      this.setState(
        {
          timesheetEntries,
          leaveEntries,
          employments: flattenEmployments,
          totalEmploymentsCount: employments?.totalCount || 0,
        },
        () => (updatedTimesheetEntryId ? this.updateSelectedTimesheetEntries(updatedTimesheetEntryId) : null),
      );
    } catch (error) {
      this.setState({ employments: [], totalEmploymentsCount: 0 });
      Sentry.captureException(error);
    } finally {
      this.setState({ employmentsLoading: false });
    }
  };

  getUniqueEmploymentIds = () => {
    const { employments } = this.state;
    return uniq(employments.map(employment => employment.id));
  };

  handleApproveLeaveEntry = async leaveEntry => {
    const { t } = this.props;
    this.setState({ loading: true });
    try {
      await leaveEntriesApi.approve(leaveEntry.id);
      const workerName = `${leaveEntry?.partner?.firstName} ${leaveEntry?.partner?.lastName}`;
      message.success(`${t('approveLeaveEntrySuccess', { workerName })}`);
    } catch (err) {
      Sentry.captureException(err);
      message.error(t('An unexpected error has occured.'));
    } finally {
      this.setState({ loading: false });
    }
  };

  handleResolveLeaveEntry = async leaveEntry => {
    const { t } = this.props;
    this.setState({ loading: true });
    try {
      await leaveEntriesApi.resolve(leaveEntry.id);
      const workerName = `${leaveEntry?.partner?.firstName} ${leaveEntry?.partner?.lastName}`;
      // Though the action and api is called resolve, the btn copytext is confirm
      message.success(`${t('confirmLeaveEntrySuccess', { workerName })}`);
    } catch (err) {
      Sentry.captureException(err);
      message.error(t('An unexpected error has occured.'));
    } finally {
      this.setState({ loading: false });
    }
  };

  handleApproveTimesheet = async timesheetEntry => {
    const { t } = this.props;
    this.setState({ loading: true });
    try {
      await timesheetEntryApi.approve(timesheetEntry.id);
      const workerName = `${timesheetEntry?.partner?.firstName} ${timesheetEntry?.partner?.lastName}`;
      message.success(`${t('approveTimesheetEntrySuccess', { workerName })}`);
    } catch (err) {
      Sentry.captureException(err);
      message.error(t('An unexpected error has occured.'));
    } finally {
      this.setState({ loading: false });
    }
  };

  handleConfirmTimesheet = async timesheetEntry => {
    const { t } = this.props;
    this.setState({ loading: true });
    try {
      await timesheetEntryApi.confirm(timesheetEntry.id);
      const workerName = `${timesheetEntry?.partner?.firstName} ${timesheetEntry?.partner?.lastName}`;
      message.success(`${t('confirmTimesheetSuccess', { workerName })}`);
    } catch (err) {
      Sentry.captureException(err);
      message.error(t('An unexpected error has occured.'));
    }
    this.setState({ loading: false });
  };

  handleApproveAllUnconfirmedTimesheets = async () => {
    const { t } = this.props;
    const { selectedLocations, selectedDateStart, selectedPaymentFrequency } = this.state;
    const positionsAndRoles = this.getPositionAndRoleParams();
    const startDate = selectedDateStart.clone().toISOString();
    const endDate = selectedDateStart
      .clone()
      .endOf('isoWeek')
      .toISOString();
    try {
      this.setState({ loading: true });
      await timesheetEntryApi.bulkApprove({
        supervisor_confirmed: true,
        status: timesheetEntryStatuses.CLOCKED_OUT,
        location: selectedLocations.join(','),
        clock_in_time_after: startDate,
        clock_in_time_before: endDate,
        payment_frequency: selectedPaymentFrequency.join(','),
        ...positionsAndRoles,
      });
    } catch (err) {
      Sentry.captureException(err);
      message.error(t('An unexpected error has occured.'));
    } finally {
      this.setState({ loading: false });
      this.fetchData();
    }
  };

  handleApproveTimesheets = async () => {
    const { t } = this.props;
    const { selectedLocations, selectedPaymentFrequency, selectedDates } = this.state;
    const positionsAndRoles = this.getPositionAndRoleParams();
    try {
      this.setState({ loading: true });
      await Promise.all(
        selectedDates.map(selectedDate => {
          const startDate = moment(selectedDate).toISOString();
          const endDate = moment(selectedDate)
            .endOf('d')
            .toISOString();
          return timesheetEntryApi.bulkApprove({
            supervisor_confirmed: true,
            status: timesheetEntryStatuses.CLOCKED_OUT,
            location: selectedLocations.join(','),
            payment_frequency: selectedPaymentFrequency.join(','),
            clock_in_time_after: startDate,
            clock_in_time_before: endDate,
            ...positionsAndRoles,
          });
        }),
      );
      this.setState({ selectedDates: [] });
    } catch (err) {
      Sentry.captureException(err);
      message.error(t('An unexpected error has occured.'));
    } finally {
      this.setState({ loading: false });
      this.fetchData();
    }
  };

  fetchTimesheetStatistics = async () => {
    this.setState({ timesheetStatisticsLoading: true });
    const { selectedDateStart, selectedLocations, selectedPaymentFrequency } = this.state;
    const positionsAndRoles = this.getPositionAndRoleParams();

    const startDate = selectedDateStart.clone().toISOString();
    const endDate = selectedDateStart
      .clone()
      .endOf('isoWeek')
      .toISOString();

    const filterParams = {
      clock_in_time_after: startDate,
      clock_in_time_before: endDate,
      location: selectedLocations?.join(','),
      payment_frequency: selectedPaymentFrequency?.join(','),
      ...positionsAndRoles,
    };
    try {
      const response = await timesheetEntryApi.getStatisticsForDateRange(filterParams);
      const timesheetEntryStatistics = {};
      let confirmedTimesheetsCount = 0;
      let disputedTimesheetsCount = 0;
      let unconfirmedTimesheetsCount = 0;
      Object.entries(response).forEach(item => {
        const [key, value] = item;
        timesheetEntryStatistics[momentTimezone(key).format('ddd DD/MM')] = value;
        confirmedTimesheetsCount += value?.confirmed;
        disputedTimesheetsCount += value?.disputed;
        unconfirmedTimesheetsCount += value?.unconfirmed;
      });
      this.setState({
        timesheetEntryStatistics,
        confirmedTimesheetsCount,
        disputedTimesheetsCount,
        unconfirmedTimesheetsCount,
      });
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      this.setState({ timesheetStatisticsLoading: false });
    }
  };

  fetchShifts = async selectedDateStart => {
    this.setState({ shiftsLoading: true });
    const { locationId, positionId } = this.state;
    const startDate = selectedDateStart;
    const endDate = selectedDateStart.clone().endOf('isoWeek');

    const params = {
      start_time_after: startDate.toISOString(),
      start_time_before: endDate.toISOString(),
      location: locationId,
      position: positionId,
    };
    try {
      const shifts = await fetchAll(shiftApi.fetchShifts, params);
      this.setState({ shifts });
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      this.setState({ shiftsLoading: false });
    }
  };

  handleSearch = search => {
    this.setState({ page: 1, search }, this.fetchData); // must reset page
  };

  handlePageChange = ({ current, pageSize }) => {
    this.setState(
      {
        page: current,
        pageSize,
      },
      this.fetchData,
    );
  };

  // TODO might need to optimise as it is very costly per click
  findShiftAttendance = (employment, date) => {
    const { shifts } = this.state;
    const startOfDay = date.clone().startOf('day');
    const endOfDay = date.clone().endOf('day');

    const shift = shifts.find(
      shiftValue =>
        moment(shiftValue.start_time).isAfter(startOfDay) && moment(shiftValue.start_time).isBefore(endOfDay),
    );

    let attendance;
    if (shift && shift.attendances?.length > 0) {
      attendance = shift.attendances.find(
        attendanceValue =>
          attendanceValue.partner_id === employment.partner.id &&
          attendanceValue.status === attendanceStatuses.ASSIGNED,
      );
    }

    return attendance;
  };

  convertPositionAndRoleToOptions = () => {
    const { position, role, no_role } = retrieveParamsFromUrl(this.props.location.search);
    const positionAndRoleOptions = [];
    const getOptions = options => {
      return options
        .split(',')
        .filter(option => option)
        .map(option => (option !== 'null' ? +option : 'null'));
    };

    const positions = getOptions(position || '');
    const roles = getOptions(role || '');
    const no_roles = getOptions(no_role || '');

    if (positions.includes('null')) {
      positionAndRoleOptions.unshift('null');
    }

    this.props.positions.forEach(positionValue => {
      if (positions.includes(positionValue.id)) {
        positionAndRoleOptions.push(`${positionValue.id}`);
      }
      this.props.roles
        .filter(roleValue => roleValue.position.id === positionValue.id)
        .forEach(roleValue => {
          if (roles.includes(roleValue.id)) {
            positionAndRoleOptions.push(`${roleValue.position.id}-${roleValue.id}`);
          }
        });
      if (no_roles.includes(positionValue.id)) {
        positionAndRoleOptions.push(`${positionValue.id}-null`);
      }
    });
    return positionAndRoleOptions;
  };

  getPositionAndRoleParams = (toArrayString = false) => {
    const filters = convertPositionAndRoleToList(this.state.selectedPositionAndRole, true);
    return prepParams(filters, toArrayString);
  };

  handleApplyPositionAndRoleFilter = checkedKeys => {
    this.setState({ page: 1, selectedPositionAndRole: checkedKeys }, this.fetchData);
  };

  handleApplyPaymentFrequency = paymentFrequencyFilters => {
    this.setState({ selectedPaymentFrequency: paymentFrequencyFilters }, this.fetchData);
  };

  updateSelectedTimesheetEntries = updatedTimesheetEntryId => {
    const { selectedTimesheetEntries, timesheetEntries } = this.state;

    // get updated timesheet entry
    const updatedTimesheetEntry = timesheetEntries?.find(entry => parseInt(entry.id, 10) === updatedTimesheetEntryId);

    if (updatedTimesheetEntry) {
      const updatedSelectedTimesheetEntries = selectedTimesheetEntries.map(timesheetEntry =>
        timesheetEntry.id === updatedTimesheetEntry.id ? updatedTimesheetEntry : timesheetEntry,
      );
      this.setState({ selectedTimesheetEntries: updatedSelectedTimesheetEntries });
    }
  };

  updateSelectedLeaveEntry = updatedLeaveEntryId => {
    const { selectedLeaveEntries, leaveEntries } = this.state;

    // get updated leave entry
    const updatedLeaveEntry = leaveEntries?.find(entry => parseInt(entry.id, 10) === updatedLeaveEntryId);

    if (updatedLeaveEntry) {
      const updatedSelectedLeaveEntries = selectedLeaveEntries.map(leaveEntry =>
        leaveEntry.id === updatedLeaveEntry.id ? updatedLeaveEntry : leaveEntry,
      );
      this.setState({ selectedLeaveEntries: updatedSelectedLeaveEntries });
    }
  };

  handleSelectDate = (checked, date) => {
    const { selectedDates } = this.state;
    if (checked) {
      this.setState({ selectedDates: [...selectedDates, date] });
    } else {
      this.setState({ selectedDates: selectedDates.filter(item => item !== date) });
    }
  };

  handleClickApproveByDays = async (daysCount, timesheetsToApproveCount) => {
    const { t } = this.props;

    if (timesheetsToApproveCount === 0) {
      message.info(t('allTimesheetsConfirmed'));
      return;
    }
    try {
      this.setState({ isApproving: true });
      approveAllAttendances({
        t,
        handleApproveAll: this.handleApproveTimesheets,
        timesheetsToApproveCount,
        isApproveByDays: true,
        daysCount,
      });
    } catch (error) {
      Sentry.captureException(error);
      message.error(t('An unexpected error has occured.'));
    } finally {
      this.setState({ isApproving: false });
    }
  };

  handleBulkConfirmTimesheets = async () => {
    const { t } = this.props;

    try {
      this.setState({ isConfirming: true });
      const { selectedLocations, selectedPaymentFrequency, selectedDates } = this.state;
      const positionsAndRoles = this.getPositionAndRoleParams();
      this.setState({ loading: true });
      await timesheetEntryApi.bulkConfirm({
        location: selectedLocations.join(','),
        payment_frequency: selectedPaymentFrequency.join(','),
        clock_in_time__date: selectedDates.join(','),
        ...positionsAndRoles,
      });
      this.setState({ selectedDates: [] });
    } catch (error) {
      Sentry.captureException(error);
      message.error(t('An unexpected error has occured.'));
    } finally {
      this.setState({ isConfirming: false });
      this.fetchData();
    }
  };

  handleClickConfirmByDays = async (days, timesheetEntries) => {
    const { t } = this.props;

    if (timesheetEntries === 0) {
      message.info(t('allTimesheetsConfirmed'));
      return;
    }

    Modal.confirm({
      title: <strong>{t('confirmTimesheetsModalTitle')}</strong>,
      icon: <QuestionCircleOutlined style={{ color: colors.functionalLink }} />,
      content: (
        <Typography.Text>
          <Trans i18nKey="aboutToConfirmTimesheetEntries" values={{ days, timesheetEntries }} />
        </Typography.Text>
      ),
      okText: t('confirm'),
      okButtonProps: {
        style: { fontWeight: 'bold', backgroundColor: colors.workmateGreen },
      },
      onOk: async () => {
        await this.handleBulkConfirmTimesheets();
      },
      cancelText: t('cancel'),
    });
  };

  getTimesheetCounts = () => {
    const { selectedDateStart, timesheetEntryStatistics, selectedDates } = this.state;

    const dates = dateUtils.getWeekDatesFromSelectedDate(selectedDateStart);
    return dates.reduce(
      (count, date) => {
        const dateKey = dateUtils.dateKey(date);
        if (selectedDates.includes(date.format(SERVER_DATE))) {
          const timesheetEntry = timesheetEntryStatistics[dateKey];
          return {
            confirmed: count.confirmed + (timesheetEntry?.confirmed || 0),
            unconfirmed: count.unconfirmed + (timesheetEntry?.unconfirmed || 0),
          };
        }

        return count;
      },
      { confirmed: 0, unconfirmed: 0 },
    );
  };

  render() {
    const { t, locations, voidReasons, timeRoundingRules, role } = this.props;
    const {
      employments,
      timesheetEntries,
      leaveEntries,
      confirmedTimesheetsCount,
      disputedTimesheetsCount,
      unconfirmedTimesheetsCount,
      selectedDateStart,
      loading,
      totalEmploymentsCount,
      page,
      selectedTimesheetEntries,
      selectedLeaveEntries,
      showAttendanceDetail,
      showAddEntryModal,
      timesheetEntryStatistics,
      selectedLocations,
      selectedEmployment,
      selectedTimesheetDate,
      selectedAttendance,
      showAttendanceCsvExportModal,
      selectedPositionAndRole,
      selectedPaymentFrequency,
      positionAndRoleOptions,
      absenceDetailsStatistics,
      showRoundedTime,
      shiftsLoading,
      employmentsLoading,
      timesheetStatisticsLoading,
      selectedDates,
      isApproving,
      isConfirming,
    } = this.state;
    const locationOptions = sortBy(
      locations.map(({ id, name }) => ({ key: id, label: name })),
      option => option.label,
    );
    locationOptions.unshift({ key: ALL_LOCATION_OPTION_KEY, label: t('allLocations') });
    const filters = {
      ...convertPositionAndRoleToList(selectedPositionAndRole, true),
      payment_frequency: selectedPaymentFrequency?.join(','),
    };
    const {
      clock_in_rounding_type: clockInRoundingType,
      clock_out_rounding_type: clockOutRoundingType,
      rounding_quantum_in_minutes: roundingQuantumInMinutes,
      round_timesheets_to_shift: roundTimesheetsToShift,
    } = timeRoundingRules;
    const timesheetCounts = this.getTimesheetCounts();

    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Row justify="space-between">
            <Col span={21} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography.Title level={2} style={{ display: 'inline', marginBottom: 0, marginRight: 24 }}>
                {t('Attendance')}
              </Typography.Title>
              <Select
                disabled={loading}
                size="large"
                labelInValue
                optionFilterProp="filter"
                notFoundContent={t('Not Found')}
                defaultValue={[{ key: ALL_LOCATION_OPTION_KEY, label: t('allLocations') }]}
                placeholder={t('Location')}
                onChange={this.handleLocationsSelectorChange}
                dropdownStyle={{ minWidth: 200 }}
              >
                {locationOptions &&
                  locationOptions.map(option => (
                    <Select.Option key={option.key} filter={option.label}>
                      <Typography.Text strong>{t(option.label)}</Typography.Text>
                    </Select.Option>
                  ))}
              </Select>
            </Col>
            <Col span={3} style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                icon={<DownloadOutlined />}
                loading={loading}
                onClick={() => {
                  this.setState({ showAttendanceCsvExportModal: true });
                }}
              >
                {t('exportToCsv')}
              </Button>
              <AttendanceCSVExportModal
                locations={locations}
                locationOptions={locationOptions}
                selectedDateStart={selectedDateStart}
                defaultLocations={selectedLocations}
                visible={showAttendanceCsvExportModal}
                closeModal={() => {
                  this.setState({ showAttendanceCsvExportModal: false });
                }}
              />
            </Col>
          </Row>
        </div>
        <Divider style={{ marginBottom: 16, marginTop: 16 }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '32px',
          }}
        >
          <Row justify="space-between">
            <Col flex={23}>
              <Typography.Text level={3} style={{ marginRight: 12 }}>
                {t('filters')}
              </Typography.Text>
              <TreeCheckboxFilter
                label={t('positionRoles')}
                placeholder={t('filterByPositionAndRole')}
                treeData={positionAndRoleOptions}
                checkedKeys={selectedPositionAndRole}
                showActionButtons
                formatTag={formatPositionAndRoleTag}
                onApply={this.handleApplyPositionAndRoleFilter}
                containerWidth={400}
              />
              <TreeCheckboxFilter
                treeData={Object.values(paymentFrequency).map(value => ({ ...value, title: t(value.key) }))}
                checkedKeys={selectedPaymentFrequency}
                placeholder={t('paymentFrequencyPlaceholder')}
                label={t('paymentFrequency')}
                showActionButtons
                onApply={this.handleApplyPaymentFrequency}
                expandable={false}
              />
            </Col>
            <Col flex={1}>
              <WeekSelectGroup
                onChange={this.handleDateSelect}
                selectedWeekStart={selectedDateStart}
                loading={loading}
              />
            </Col>
          </Row>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 32 }}>
          <div style={{ flex: 1 }}>
            <AttendanceHeaderSummary
              loading={loading}
              locations={selectedLocations}
              timesheetEntries={timesheetEntries}
              refreshAttendancePageData={this.fetchData}
              confirmedTimesheetsCount={confirmedTimesheetsCount}
              disputedTimesheetsCount={disputedTimesheetsCount}
              unconfirmedTimesheetsCount={unconfirmedTimesheetsCount}
              selectedWeekStart={selectedDateStart}
              filters={filters}
              handleApproveAll={this.handleApproveAllUnconfirmedTimesheets}
            />
          </div>
        </div>
        <Typography.Title level={3} style={{ marginBottom: 24 }}>
          {t('Summary')}
        </Typography.Title>
        <WeeklySummaryTable
          startDate={selectedDateStart}
          timesheetEntryStatistics={timesheetEntryStatistics}
          absenceDetailsStatistics={absenceDetailsStatistics}
          loading={timesheetStatisticsLoading}
          style={{ marginBottom: '48px' }}
        />
        <Typography.Title level={3} style={{ marginBottom: 24 }}>
          {t('timesheets')}
        </Typography.Title>
        <div style={{ marginBottom: 24, display: 'flex', flexDirection: 'column' }}>
          <Row type="flex" style={{ flexDirection: 'row', justifyContent: 'space-between' }} align="bottom">
            <Col>
              <Input.Search
                loading={loading}
                placeholder={t('searchWorker')}
                style={{ width: 360 }}
                allowClear
                onSearch={this.handleSearch}
                onChange={event => this.onSearchChange(event.target.value)}
              />
            </Col>
            {roundingQuantumInMinutes > 1 && (
              <Col>
                <Switch
                  checked={showRoundedTime}
                  onClick={() => this.setState({ showRoundedTime: !showRoundedTime })}
                  style={{ marginRight: 8 }}
                />
                <Typography.Text
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: '22px',
                  }}
                >
                  {t('roundedTime')}
                  <Tooltip
                    title={
                      <>
                        <Row>
                          <Typography.Text strong>{t('paymentsCalculatedOnRoundedTime')}</Typography.Text>
                        </Row>
                        <Row>
                          <Typography.Text>
                            <Trans i18nKey="visitSettingsPage">
                              Visit
                              <Link to={routes.settings.replace(':tab', settingsTabs.TIMESHEET_TAGS)}>
                                Settings page
                              </Link>
                              for rounding configuration.
                            </Trans>
                          </Typography.Text>
                        </Row>
                        <Row style={{ marginTop: 16 }}>
                          <Typography.Text strong>{t('globalTimesheetRounding')}</Typography.Text>
                        </Row>
                        <Row>
                          <Typography.Text>
                            {t('clockInGlobalRounding', {
                              roundingType: t(roundingTypesTranslation[clockInRoundingType]),
                              minutes: roundingQuantumInMinutes,
                            })}
                          </Typography.Text>
                        </Row>
                        <Row>
                          <Typography.Text>
                            {t('clockOutGlobalRounding', {
                              roundingType: t(roundingTypesTranslation[clockOutRoundingType]),
                              minutes: roundingQuantumInMinutes,
                            })}
                          </Typography.Text>
                        </Row>
                        {roundTimesheetsToShift && (
                          <>
                            <Row style={{ marginTop: 16 }}>
                              <Typography.Text strong>{t('forTimesheetsWithShift')}</Typography.Text>
                            </Row>
                            <Row>
                              <Typography.Text>{t('clockInRoundingWithShift')}</Typography.Text>
                            </Row>
                            <Row>
                              <Typography.Text>{t('clockOutRoundingWithShift')}</Typography.Text>
                            </Row>
                          </>
                        )}
                      </>
                    }
                    placement="bottomLeft"
                    color={colors.white}
                    overlayStyle={{ maxWidth: '400px' }}
                  >
                    <QuestionCircleOutlined
                      style={{
                        color: colors.functionalLink,
                        fontSize: '16px',
                        marginLeft: 4,
                        verticalAlign: 'middle',
                      }}
                    />
                  </Tooltip>
                </Typography.Text>
              </Col>
            )}
          </Row>
        </div>
        <WeeklyAttendanceTable
          employments={employments}
          timesheetEntries={timesheetEntries}
          leaveEntries={leaveEntries}
          startDate={selectedDateStart}
          totalEmploymentsCount={totalEmploymentsCount}
          loading={shiftsLoading || employmentsLoading}
          page={page}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          showRoundedTime={showRoundedTime}
          selectedDates={selectedDates}
          onCellSelect={data => {
            // eslint-disable-next-line no-shadow
            const {
              timesheetEntries: cellSelectTimesheetEntries,
              leaveEntries: cellSelectLeaveEntries,
              employment,
              date,
              nonVoidTimesheetEntries,
              nonVoidLeaveEntries,
            } = data;
            if (size(nonVoidTimesheetEntries) > 0 || size(nonVoidLeaveEntries) > 0) {
              this.setState({
                selectedTimesheetEntries: cellSelectTimesheetEntries,
                selectedLeaveEntries: cellSelectLeaveEntries,
                showAttendanceDetail: true,
                selectedEmployment: employment,
                selectedTimesheetDate: date,
              });
            } else if (employmentUtils.getDerivedWorkingStatus(date, employment) === derivedWorkingStatus.WORKING) {
              const attendance = this.findShiftAttendance(employment, date);
              this.setState({
                selectedEmployment: employment,
                showAddEntryModal: true,
                selectedTimesheetDate: date,
                selectedAttendance: attendance,
              });
            }
          }}
          onPageChange={this.handlePageChange}
          onSelectDate={this.handleSelectDate}
          userRole={role}
        />
        {showAttendanceDetail && (
          <Drawer
            width="720px"
            placement="right"
            closable
            onClose={() =>
              this.setState({
                showAttendanceDetail: false,
                selectedTimesheetEntries: undefined,
                selectedEmployment: undefined,
              })
            }
            visible={showAttendanceDetail}
          >
            <AttendanceDetail
              loading={loading}
              timesheetEntries={selectedTimesheetEntries}
              leaveEntries={selectedLeaveEntries}
              employment={selectedEmployment}
              refreshTimesheetData={async updatedTimesheetEntry => {
                await this.fetchEmploymentsAndTimesheets(updatedTimesheetEntry?.id);
              }}
              handleApproveTimesheet={this.handleApproveTimesheet}
              handleConfirmTimesheet={this.handleConfirmTimesheet}
              onTimesheetActionComplete={async updatedTimesheetEntry => {
                // TODO: change updatedTimesheetEntry to updatedTimesheetEntryId
                if (updatedTimesheetEntry) {
                  this.updateSelectedTimesheetEntries(updatedTimesheetEntry);
                } else {
                  this.setState({ showAttendanceDetail: false });
                }
                this.fetchData();
              }}
              handleApproveLeaveEntry={this.handleApproveLeaveEntry}
              handleResolveLeaveEntry={this.handleResolveLeaveEntry}
              onLeaveActionComplete={async updatedLeaveEntryId => {
                if (updatedLeaveEntryId) {
                  await this.updateSelectedLeaveEntry(updatedLeaveEntryId);
                } else {
                  this.setState({ showAttendanceDetail: false });
                }
                await this.fetchData();
              }}
              voidReasons={voidReasons}
              timeRoundingRules={timeRoundingRules}
              onClickAddEntry={() =>
                this.setState({
                  showAttendanceDetail: false,
                  showAddEntryModal: true,
                })
              }
            />
          </Drawer>
        )}
        <AddEntryModal
          onClose={() => this.setState({ showAddEntryModal: false })}
          visible={showAddEntryModal}
          fetchEmploymentsAndTimesheets={this.fetchEmploymentsAndTimesheets}
          employment={selectedEmployment}
          attendance={selectedAttendance}
          date={selectedTimesheetDate}
        />

        {/* add sticky legend bar here */}
        <div
          style={{
            display: 'flex',
            position: 'fixed',
            justifyContent: 'space-between',
            alignItems: 'center',
            left: 0,
            bottom: 0,
            padding: '1em 4.5em 1em 4.5em',
            width: '100%',
            background: colors.white,
            boxShadow: '0 -5px 6px rgba(0,0,0,0.1)',
            WebkitBoxShadow: '0 -5px 6px rgba(0,0,0,0.1)',
            zIndex: 1,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {legendItems.map((legendItem, index) => (
              <div key={index} style={{ marginRight: 24 }}>
                {legendItem.icon} {t(legendItem.labelKey).toUpperCase()}
              </div>
            ))}
          </div>

          {role === userRoles.ADMIN && (
            <div>
              <Typography.Text type="secondary" style={{ marginRight: 16 }}>
                {t('numDaysSelected', { count: selectedDates.length })}
              </Typography.Text>
              <Button
                loading={loading || isApproving}
                disabled={selectedDates.length === 0 || isApproving}
                style={{
                  height: 37,
                  fontWeight: 600,
                  fontSize: 14,
                  color: colors.white,
                  backgroundColor: selectedDates.length > 0 || isApproving ? colors.workmateGreen : colors.grey,
                  borderColor: selectedDates.length > 0 || isApproving ? colors.workmateGreen : colors.grey,
                  paddingTop: 7,
                  paddingBottom: 7,
                  paddingLeft: 27,
                  paddingRight: 27,
                  marginLeft: 'auto',
                }}
                onClick={() => {
                  this.handleClickApproveByDays(selectedDates.length, timesheetCounts.confirmed);
                }}
              >
                {t('approveTimesheets')}
              </Button>
            </div>
          )}
          {role === userRoles.SUPERVISOR && (
            <div>
              <Typography.Text type="secondary" style={{ marginRight: 16 }}>
                {t('numDaysSelected', { count: selectedDates.length })}
              </Typography.Text>
              <Button
                loading={loading || isConfirming}
                disabled={selectedDates.length === 0 || isConfirming}
                style={{
                  height: 37,
                  fontWeight: 600,
                  fontSize: 14,
                  color: colors.white,
                  backgroundColor: selectedDates.length > 0 || isConfirming ? colors.workmateGreen : colors.grey,
                  borderColor: selectedDates.length > 0 || isConfirming ? colors.workmateGreen : colors.grey,
                  paddingTop: 7,
                  paddingBottom: 7,
                  paddingLeft: 27,
                  paddingRight: 27,
                  marginLeft: 'auto',
                }}
                onClick={async () => {
                  await this.handleClickConfirmByDays(selectedDates.length, timesheetCounts.unconfirmed);
                }}
              >
                {t('confirmTimesheets')}
              </Button>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  locations: state.user.locations,
  positions: state.global.positions,
  roles: state.user.roles,
  voidReasons: state.global.voidReasons,
  timeRoundingRules: state.user.timeRoundingRules,
  clientName: state.user.name,
  role: state.user.role,
});

const mapDispatchToProps = dispatch => {
  const { fetchVoidReasons, fetchLeaveEntryTypes, fetchLeaveEntryVoidReasons } = globalActions;
  return {
    fetchVoidReasons: async () => {
      await dispatch(fetchVoidReasons());
    },
    fetchLeaveEntryTypes: async () => {
      await dispatch(fetchLeaveEntryTypes());
    },
    fetchLeaveEntryVoidReasons: async () => {
      await dispatch(fetchLeaveEntryVoidReasons());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withQueryAndGraphQLClient(AttendancePage)));
