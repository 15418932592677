import React from 'react';
import { Form } from '@ant-design/compatible';

import {
  Row,
  Col,
  DatePicker,
  TimePicker,
  Checkbox,
  Tag,
  message,
  InputNumber,
  Input,
  Modal,
  Divider,
  Typography,
  Radio,
  Select,
  Tooltip,
} from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import RRule from 'rrule';
import { clockinMethods, recurrenceOptions, TIME_FORMAT_24_HOUR } from '../../../constants';
import scheduleApi from '../../../services/scheduleApi';
import datetimeUtils from '../../../utilities/datetimeUtils';
import ClockinMethodFormItems from '../../../shared/components/ClockinMethodFormItems';
import formUtils from '../../../utilities/formUtils';
import { DAY_DATE } from '../../../styles/dates';

const { Item } = Form;
const { Title, Text } = Typography;

class AddScheduleFormModal extends React.Component {
  state = {
    isSubmitting: false,
  };

  handleRecurrenceChange = (option, checked) => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    const recurrenceFieldLabel = 'recurrence';
    const selectedRecurrences = getFieldValue(recurrenceFieldLabel);

    if (checked) {
      selectedRecurrences.push(option.value);
      setFieldsValue({ [recurrenceFieldLabel]: selectedRecurrences });
      return;
    }
    const nextSelectedRecurrences = selectedRecurrences.filter(fieldValue => fieldValue !== option.value);
    setFieldsValue({ [recurrenceFieldLabel]: nextSelectedRecurrences });
  };

  formDataToCreatePayload = (values, timezone) => {
    const {
      name,
      staff_required,
      start_time,
      end_time,
      start_date,
      end_date,
      unpaid_break,
      break_duration_in_minutes,
    } = values;
    const qr_override_allowed = values.clockin_method === clockinMethods.QR_SCAN_OR_SELFIE;
    const fixed_location = !!values.fixed_location;

    const startTime = start_time;
    const endTime = end_time;
    const duration = datetimeUtils.getDurationFromStartTimeAndEndTime(startTime, endTime).format('HH:mm:00');

    // Get new start date from values.start_date
    const yearInt = moment(start_date).year();
    const monthInt = moment(start_date).month();
    const dayInt = moment(start_date).date();

    // Get old start time from schedule.start_date
    const hourInt = moment(start_time).hour();
    const minuteInt = moment(start_time).minute();
    const startDate = moment
      .tz(
        {
          year: yearInt,
          month: monthInt,
          day: dayInt,
          hour: hourInt,
          minute: minuteInt,
        },
        timezone,
      )
      .toISOString();

    const endDate = end_date;
    const recurrences = new RRule({
      freq: RRule.WEEKLY,
      byweekday: values.recurrence,
      until:
        endDate &&
        endDate
          .tz(timezone)
          .add(1, 'd')
          .startOf('d')
          .toISOString(),
    }).toString();

    return {
      name,
      staff_required,
      start_date: startDate,
      end_date: endDate,
      recurrences,
      duration,
      qr_override_allowed,
      fixed_location,
      unpaid_break,
      break_duration_in_minutes,
    };
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { form, timezone, onUpdate, t, location, position, clientId } = this.props;
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          this.setState({ isSubmitting: true });
          const payload = this.formDataToCreatePayload(values, timezone);
          await scheduleApi.createSchedule({
            ...payload,
            client: clientId,
            location: location.id,
            position: position.id,
          });
          onUpdate();
          message.success(t('scheduleSaved'));
        } catch (error) {
          message.warning(t('submitFailed'));
        } finally {
          this.setState({ isSubmitting: false });
        }
      }
    });
  };

  render() {
    const { visible, onCancel, t, position, location } = this.props;
    const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
    const { isSubmitting } = this.state;

    return (
      <Modal
        className="v2-shifts-modal"
        visible={visible}
        onCancel={() => {
          onCancel();
        }}
        title={<Title level={4}>{t('addNewSchedule')}</Title>}
        okText={t('create')}
        cancelText={t('cancel')}
        okButtonProps={{ type: 'v2-primary' }}
        confirmLoading={isSubmitting}
        onOk={e => {
          this.handleSubmit(e);
        }}
      >
        <Form hideRequiredMark colon={false} className="v2-shifts-form">
          <section key={0}>
            <Row type="flex" gutter={24}>
              <Col span={12}>
                <Item label={t('location')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                  {location.name}
                </Item>
              </Col>
              <Col span={12}>
                <Item label={t('Position')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                  {position.name}
                </Item>
              </Col>
              <Col span={24}>
                <Item label={t('scheduleTitle')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: t('fieldRequired.') }],
                  })(<Input style={{ width: '100%' }} placeholder={t('scheduleName')} />)}
                </Item>
              </Col>
              <Col xs={12}>
                <Item label={t('startDate')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                  {getFieldDecorator('start_date', {
                    rules: [{ required: true, message: t('fieldRequired.') }],
                  })(
                    <DatePicker
                      allowClear={false}
                      format="DD-MM-YYYY"
                      disabledDate={current => current < moment().startOf('day')}
                      style={{ width: '100%' }}
                      onChange={value => {
                        const startDateDay = value?.isoWeekday() - 1;
                        const selectedRecurrences = getFieldValue('recurrence');
                        if (!selectedRecurrences.includes(startDateDay)) {
                          const newRecurrences = [...selectedRecurrences, startDateDay];
                          setFieldsValue({ recurrence: newRecurrences });
                        }
                      }}
                    />,
                  )}
                </Item>
              </Col>
              <Col xs={12}>
                <Item label={t('endDate')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                  {getFieldDecorator('end_date', {
                    rules: [
                      {
                        validator: (rule, value, callback) => {
                          if (value && getFieldValue('start_date')) {
                            const daysApart = value.diff(getFieldValue('start_date'), 'days');
                            if (daysApart > 365) {
                              // Set error on form
                              callback('End date cannot be more than a year from start date.');
                            }
                            if (daysApart < 0) {
                              callback('End date cannot be earlier than start date.');
                            }
                          }
                          // Have to be called to validate the rest of the fields.
                          callback();
                        },
                      },
                      { required: true, message: t('fieldRequired.') },
                    ],
                  })(
                    <DatePicker
                      format="DD-MM-YYYY"
                      disabledDate={current => current < moment().endOf('day')}
                      style={{ width: '100%' }}
                      onChange={this.onEndDateChange}
                    />,
                  )}
                </Item>
              </Col>
              <Col xs={12}>
                <Item label={t('startTime')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                  {getFieldDecorator('start_time', {
                    rules: [{ required: true, message: t('fieldRequired.') }],
                  })(
                    <TimePicker
                      minuteStep={15}
                      format={TIME_FORMAT_24_HOUR}
                      defaultOpenValue={moment('00:00', TIME_FORMAT_24_HOUR)}
                      style={{ width: '100%' }}
                    />,
                  )}
                </Item>
              </Col>
              <Col xs={12}>
                <Item label={t('endTime')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                  {getFieldDecorator('end_time', {
                    rules: [{ required: true, message: t('fieldRequired.') }],
                  })(
                    <TimePicker
                      minuteStep={15}
                      format={TIME_FORMAT_24_HOUR}
                      defaultOpenValue={moment('00:00', TIME_FORMAT_24_HOUR)}
                      style={{ width: '100%' }}
                    />,
                  )}
                </Item>
              </Col>
              <Col span={12}>
                <Item label={t('totalBreakDuration')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                  {getFieldDecorator('break_duration_in_minutes', {
                    rules: [{ required: true, message: t('fieldIsRequired') }],
                    initialValue: 0,
                  })(
                    <Select
                      style={{ width: '100%' }}
                      options={formUtils.getBreakDurationChoices(t)}
                      onChange={selectedInput => setFieldsValue({ break_duration_in_minutes: selectedInput })}
                    />,
                  )}
                </Item>
              </Col>
              <Col span={12}>
                <Item label={t('breakType')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                  {getFieldDecorator('unpaid_break', {
                    rules: [{ required: true, message: t('fieldIsRequired') }],
                    initialValue: true,
                  })(
                    <Radio.Group
                      className="v2-radio-wrapper"
                      style={{ width: '100%' }}
                      onChange={e =>
                        setFieldsValue({
                          unpaid_break: e.target.value,
                        })
                      }
                    >
                      {[
                        { value: true, label: t('unpaidBreakType') },
                        { value: false, label: t('paidBreakType') },
                      ].map((option, index) => (
                        <Radio key={index} value={option.value}>
                          {option.label}
                        </Radio>
                      ))}
                    </Radio.Group>,
                  )}
                </Item>
              </Col>
              <Col span={24}>
                <Item label={t('whatDaysWorkersWork')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                  {getFieldDecorator('recurrence', {
                    initialValue: [0, 1, 2, 3, 4],
                    // TODO: validation must be triggered immediately
                    rules: [
                      {
                        type: 'array',
                        min: 1,
                        required: true,
                        message: t('Field is required.'),
                      },
                    ],
                  })(
                    <Checkbox.Group style={{ width: '100%' }}>
                      <Row type="flex" className="v2-checkable-tag-wrapper">
                        {[...recurrenceOptions]
                          .sort((a, b) => a.value - b.value)
                          .map((option, index) => {
                            const recurrenceFieldLabel = 'recurrence';
                            const selectedRecurrences = getFieldValue(recurrenceFieldLabel);
                            const startDate = getFieldValue('start_date');
                            const startDateName =
                              startDate?.format(DAY_DATE) ||
                              moment()
                                .startOf('week')
                                .format(DAY_DATE);
                            const startDateDay = startDate?.isoWeekday() - 1;
                            const isStartDateDaySelected = startDateDay === option.value;
                            if (isStartDateDaySelected) {
                              return (
                                <Tooltip title={t('schedStartsOn', { day: startDateName })}>
                                  <Tag.CheckableTag
                                    key={index}
                                    checked
                                    style={{
                                      padding: '5px 8px',
                                      marginBottom: '5px',
                                      width: '54px',
                                      textAlign: 'center',
                                      border: '1px solid #D9D9D9',
                                      cursor: 'not-allowed',
                                      opacity: '0.5',
                                    }}
                                  >
                                    {t(option.label)}
                                  </Tag.CheckableTag>
                                </Tooltip>
                              );
                            }
                            return (
                              <Tag.CheckableTag
                                key={index}
                                checked={selectedRecurrences.includes(option.value)}
                                onChange={checked => this.handleRecurrenceChange(option, checked)}
                                style={{
                                  padding: '5px 8px',
                                  marginBottom: '5px',
                                  width: '54px',
                                  textAlign: 'center',
                                  border: '1px solid #D9D9D9',
                                }}
                              >
                                {t(option.label)}
                              </Tag.CheckableTag>
                            );
                          })}
                      </Row>
                    </Checkbox.Group>,
                  )}
                </Item>
              </Col>

              <Divider />
              <Col span={24} style={{ marginBottom: '14px' }}>
                <Row style={{ marginBottom: '10px' }}>
                  <Text strong>{t('fulfillment')}</Text>
                </Row>
                <Row>
                  <Text type="secondary">{t('fulfillmentDescription')}</Text>
                </Row>
              </Col>
              <Col span={24}>
                <Item label={t('workerNeeded')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                  {getFieldDecorator('staff_required', {
                    rules: [{ required: true, message: t('fieldRequired.') }],
                  })(<InputNumber min={0} style={{ width: '50%' }} />)}
                </Item>
              </Col>
              <Divider />
              <Col span={24} style={{ marginBottom: '14px' }}>
                <Row style={{ marginBottom: '10px' }}>
                  <Text strong>{t('clockInMethodTitle')}</Text>
                </Row>
              </Col>
              <Col span={24}>
                <ClockinMethodFormItems
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  initialValues={{
                    clockin_method: clockinMethods.QR_SCAN_ONLY,
                    fixed_location: false,
                  }}
                />
              </Col>
            </Row>
          </section>
        </Form>
      </Modal>
    );
  }
}
export default Form.create()(withTranslation()(AddScheduleFormModal));
