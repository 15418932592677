import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Form, message, Typography, Row, Col, Modal, Avatar, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { DATE_FORMAT, leaveEntryVoidReasonCodes } from '../../../../constants';
import textUtils from '../../../../utilities/textUtils';
import leaveEntriesApi from '../../../../services/leaveEntriesApi';

const { Title, Text } = Typography;

const VoidLeaveEntryModal = ({ visible, leaveEntry, voidReasons, onCancel, onComplete, t }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState(undefined);
  const workerName = `${leaveEntry?.partner?.firstName} ${leaveEntry?.partner?.lastName}`;

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const values = await form.validateFields();
      await leaveEntriesApi.voidEntry(leaveEntry.id, values.voided_reason, values.notes);
      message.success(`${t('voidLeaveEntrySuccess', { workerName })}`);
      onComplete();
    } catch (error) {
      Sentry.captureEvent(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={<Title level={4}>{`${t('voidLeaveEntryModalTitle')}`}</Title>}
      centered
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
      width={480}
      okType="danger"
      okText={t('Void')}
      okButtonProps={{ loading }}
    >
      <Row type="flex" style={{ marginBottom: '24px' }} align="middle" gutter={16}>
        <Col>
          <Avatar shape="square" icon={<UserOutlined />} size={88} />
        </Col>
        <Col>
          <Title level={4}>{workerName}</Title>
          <Text type="secondary">{leaveEntry?.position?.name}</Text>
          <br />
          <Text type="secondary">{`#${leaveEntry?.partner?.id}`}</Text>
        </Col>
      </Row>
      <Row style={{ marginBottom: '24px' }} align="middle" gutter={16}>
        <Col>
          <Text>{`🌴 `}</Text>
          <Text type="secondary" style={{ fontWeight: 600 }}>
            {leaveEntry?.leaveEntryType?.name}
          </Text>
          <br />
          <Title level={4} style={{ marginBottom: 0 }}>
            {moment(leaveEntry?.date).format(DATE_FORMAT)}
          </Title>
        </Col>
      </Row>

      <Form form={form} requiredMark>
        <Form.Item
          name="voided_reason"
          label={t('formVoidReasonPrompt')}
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: t('Reason is required') }]}
          style={{ marginBottom: 0 }}
        >
          <Select
            autoFocus
            placeholder={t('Please give your reason and some details...')}
            optionLabelProp="label"
            onChange={value => {
              const reason = voidReasons.find(item => item.id === value);
              setSelectedReason(reason.code);
            }}
          >
            {voidReasons?.map(({ id, code, description }) => (
              <Select.Option key={id} label={t(textUtils.toCamelCase(code))} value={id}>
                <Row>
                  <Typography.Text>{t(textUtils.toCamelCase(code))}</Typography.Text>
                </Row>
                <Row>
                  <Typography.Text type="secondary" style={{ whiteSpace: 'normal' }}>
                    {description}
                  </Typography.Text>
                </Row>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {selectedReason === leaveEntryVoidReasonCodes.OTHER && (
          <Row>
            <Form.Item name="notes" style={{ marginTop: 8, marginBottom: 0, width: '100%' }}>
              <TextArea rows={4} placeholder={t('pleaseGiveAReason')} />
            </Form.Item>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  voidReasons: state.global.leaveEntryVoidReasons,
});

export default connect(mapStateToProps, null)(withTranslation()(VoidLeaveEntryModal));
