import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography, Card, Col, Row, Button, Form, Input, InputNumber, Divider, message, Space } from 'antd';
import { SaveFilled } from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import QuestionList from '../components/QuestionList';
import EmptyList from '../components/EmptyList';
import ImportFormModal from '../components/ImportFormModal';
import QuestionLibrary from '../components/QuestionLibrary';
import DeactivatedQuestionsModal from '../components/DeactivatedQuestionsModal';
import fileImportIcon from '../../../assets/images/import-outline.svg';
import formUtils from '../../../utilities/formUtils';
import formsApi from '../../../services/formsApi';
import { colors } from '../../../styles/colors';
import routes from '../../../routes';
import * as applicationFormActions from '../../../redux/applicationForm';
import LeavePagePrompt from '../../../shared/components/LeavePagePrompt';
import CreateQuestionModal from '../components/CreateQuestionModal';

const { Text } = Typography;

const FormCreateView = ({
  history,
  applicationForm,
  addSelectedQuestion,
  resetSelectedQuestions,
  getQuestions,
  clientId,
}) => {
  const initialValues = {
    name: '',
    passing_percentage: 0,
  };

  const { selectedQuestions } = applicationForm;

  const [saving, setSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isOpenDeactivatedQuestionsModal, setIsOpenDeactivatedQuestionsModal] = useState(false);
  const [isOpenImportFormModal, setIsOpenImportFormModal] = useState(false);
  const [isOpenCreateQuestionModal, setIsOpenCreateQuestionModal] = useState(false);
  const [hasSelectedQuestionsError, setHasSelectedQuetionsError] = useState(false);

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const totalScore = formUtils.getQuestionsTotalScore(selectedQuestions);

  const setPassingPercentageError = (errors = []) => {
    form.setFields([
      {
        name: 'passing_percentage',
        errors,
      },
    ]);
  };

  const setFormNameExistError = () => {
    form.setFields([
      {
        name: 'name',
        errors: [t('formNameExistsError')],
      },
    ]);
  };

  useEffect(() => {
    const { passing_percentage } = form.getFieldsValue();
    if (totalScore > 0 && passing_percentage > 0) {
      // remove passing percentage error
      setPassingPercentageError([]);
    } else if (totalScore === 0 && passing_percentage > 0) {
      // show passing percentage error
      setPassingPercentageError([t('passingScoreNotApplicableError')]);
    }
  }, [totalScore]);

  useEffect(() => {
    // reset selected questions on leave page
    return () => resetSelectedQuestions();
  }, []);

  const onCreateForm = async ({ name, passing_percentage }) => {
    const trimName = name.trim();
    const payload = formUtils.getCreateFormPayload({
      title: trimName,
      passing_score: passing_percentage,
      selectedQuestions,
    });
    const response = await formsApi.createForm(payload, clientId);
    setIsSuccess(true);
    message.success(t('newFormCreated'));
    history.push(routes.staffRequestForms);
    return response;
  };

  const onFinish = async values => {
    try {
      if (!selectedQuestions.length) {
        return;
      }
      setSaving(true);
      const isFormNameExists = await formUtils.checkFormNameIfExists(values.name);
      if (isFormNameExists) {
        setFormNameExistError();
        setSaving(false);
      } else {
        await onCreateForm(values);
      }
    } catch (error) {
      message.warning(t('Sorry! An error occured in submission, please contact support'));
      Sentry.captureException(error);
      setSaving(false);
    }
  };

  const handleFormSubmit = () => {
    form.submit();
    if (!selectedQuestions.length) {
      setHasSelectedQuetionsError(true);
    }
  };

  const onAddSelectedQuestion = question => {
    if (hasSelectedQuestionsError) {
      setHasSelectedQuetionsError(false);
    }
    addSelectedQuestion(question);
  };

  const handleSelectQuestion = question => {
    onAddSelectedQuestion(question);
    message.success(t('questionAdded'));
  };

  const openImportFormModal = () => {
    setIsOpenImportFormModal(true);
  };

  const closeImportFormModal = () => {
    setIsOpenImportFormModal(false);
  };

  const handleCancel = () => {
    history.push(routes.staffRequests);
  };

  const handleImportSuccess = ({ inactiveQuestions }) => {
    if (inactiveQuestions.length > 0) {
      setIsOpenDeactivatedQuestionsModal(true);
    }
    closeImportFormModal();
  };

  const handleCreateQuestionSuccess = question => {
    onAddSelectedQuestion(question);
    // refresh questions
    getQuestions();
    message.success(t('questionCreated'));
  };

  return (
    <Row style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 144px)' }}>
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
        {/* Leave Page Prompt */}
        <Form.Item shouldUpdate noStyle>
          {({ getFieldsValue }) => {
            const { name, passing_percentage } = getFieldsValue();
            const shouldShowLeavePrompt = !isSuccess && Boolean(name || passing_percentage || selectedQuestions.length);
            return <LeavePagePrompt when={shouldShowLeavePrompt} />;
          }}
        </Form.Item>
        {/* Form Heading and Action Buttons */}
        <Row align="center" style={{ marginBottom: 32 }}>
          <Typography.Title level={4}>{t('createNewForm')}</Typography.Title>
          <Col style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button style={{ marginRight: 10 }} onClick={handleCancel}>
              {t('cancel')}
            </Button>
            <Button
              onClick={handleFormSubmit}
              style={{
                borderColor: saving ? colors.componentBorder : colors.functionalSuccess,
                backgroundColor: saving ? colors.componentBackground : colors.functionalSuccess,
                color: saving ? colors.disabled : colors.white,
              }}
              icon={<SaveFilled />}
              disabled={saving}
              loading={saving}
            >
              {t('Save')}
            </Button>
          </Col>
        </Row>
        {/* Inputs */}
        <Row style={{ height: 95 }}>
          <Col span={9}>
            <Form.Item
              required={false}
              label={t('formNameLabel')}
              name="name"
              rules={[
                { required: true, message: t('formNameRequiredError') },
                { whitespace: true, message: t('formNameRequiredError') },
              ]}
            >
              <Input placeholder={t('formNamePlaceholder')} />
            </Form.Item>
          </Col>
          <Col span={15} style={{ paddingLeft: 24 }}>
            <Form.Item label={<Typography>{t('passingPercentageLabel')}</Typography>}>
              <Space>
                <Form.Item
                  name="passing_percentage"
                  noStyle
                  rules={[
                    {
                      validator(rule, value, callback) {
                        if (value > 0 && totalScore === 0) {
                          callback(t('passingScoreNotApplicableError'));
                        }
                        callback();
                      },
                    },
                  ]}
                >
                  <InputNumber
                    addonAfter="%"
                    min={0}
                    max={100}
                    style={{ width: 125 }}
                    type="number"
                    parser={val => (val ? parseInt(val, 10) : 0)}
                  />
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const passingPercentage = getFieldValue('passing_percentage') || 0;
                    const passingScore = formUtils.getPassingScore(passingPercentage, totalScore);
                    return (
                      <Typography style={{ marginLeft: 6, color: colors.secondaryText }}>
                        {t('passingScoreDesc', { passingScore, totalScore })}
                      </Typography>
                    );
                  }}
                </Form.Item>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider style={{ marginTop: 0 }} />
      <Row style={{ height: '100%', flex: 1, overflow: 'hidden' }} wrap={false}>
        {/* Question Library Section */}
        <Col span={9} style={{ height: '100%' }}>
          <Card
            title={<Typography.Title level={5}>{t('questionLibrary')}</Typography.Title>}
            style={{ height: '100%', overflow: 'hidden' }}
            bodyStyle={{ padding: 0, height: 'calc(100% - 48px)' }}
            headStyle={{ marginBottom: 0 }}
          >
            <QuestionLibrary
              onSelectQuestion={handleSelectQuestion}
              onClickCreateNewQuestion={() => setIsOpenCreateQuestionModal(true)}
            />
          </Card>
        </Col>
        {/* Selected Questions Section */}
        <Col span={15} style={{ height: '100%', paddingLeft: 24 }}>
          <Card
            title={<Typography.Title level={5}>{t('yourForm')}</Typography.Title>}
            extra={
              <Typography.Paragraph
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginBottom: 0,
                  color: colors.infoHighlightBlue,
                }}
                onClick={openImportFormModal}
              >
                <img src={fileImportIcon} alt="file import icon" style={{ marginRight: 4, width: 16, height: 16 }} />
                {t('import')}
              </Typography.Paragraph>
            }
            style={{
              height: '100%',
              overflow: 'hidden',
              width: '100%',
              border: `1px solid ${hasSelectedQuestionsError ? colors.functionalError : colors.backgroundGrey}`,
            }}
            bodyStyle={{ height: `calc(100% - 40px)`, overflowY: 'auto' }}
            headStyle={{ marginBottom: 0 }}
          >
            {selectedQuestions.length > 0 ? (
              <>
                {selectedQuestions.length > 0 && (
                  <Text style={{ display: 'block', marginBottom: 20, color: colors.secondaryText }}>
                    {selectedQuestions.length > 1
                      ? t('totalQuestions_other', { count: selectedQuestions.length })
                      : t('totalQuestions_one')}
                  </Text>
                )}
                <QuestionList questions={selectedQuestions} />
              </>
            ) : (
              <EmptyList description={t('emptySelectedQuestionsMessage')}>
                <Trans i18nKey="addOrImportQuestion">
                  <Typography.Paragraph style={{ color: colors.disabled }}>
                    Add question from question library or
                    <Typography.Text
                      onClick={openImportFormModal}
                      style={{ color: colors.functionalLink, cursor: 'pointer' }}
                    >
                      import from other form
                    </Typography.Text>
                  </Typography.Paragraph>
                </Trans>
              </EmptyList>
            )}
          </Card>
        </Col>
      </Row>
      {/* Modals */}
      {isOpenImportFormModal && (
        <ImportFormModal
          visible={isOpenImportFormModal}
          onCancel={closeImportFormModal}
          onImportSuccess={handleImportSuccess}
        />
      )}
      {isOpenDeactivatedQuestionsModal && (
        <DeactivatedQuestionsModal
          visible={isOpenDeactivatedQuestionsModal}
          onCancel={() => setIsOpenDeactivatedQuestionsModal(false)}
          onOkay={() => setIsOpenDeactivatedQuestionsModal(false)}
        />
      )}
      {isOpenCreateQuestionModal && (
        <CreateQuestionModal
          visible={isOpenCreateQuestionModal}
          onCreateSuccess={handleCreateQuestionSuccess}
          onCancel={() => setIsOpenCreateQuestionModal(false)}
        />
      )}
    </Row>
  );
};

const mapStateToProps = state => ({
  applicationForm: state.applicationForm,
  clientId: state.user.clientId,
});

const mapDispatchToProps = dispatch => {
  const { addSelectedQuestion, resetSelectedQuestions, getQuestions } = applicationFormActions;
  return {
    addSelectedQuestion: question => {
      dispatch(addSelectedQuestion(question));
    },
    resetSelectedQuestions: () => {
      dispatch(resetSelectedQuestions());
    },
    getQuestions: () => {
      dispatch(getQuestions());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormCreateView);
