import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography, Input, Row, Spin, Col } from 'antd';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { colors } from '../../../styles/colors';
import staffRequestUtils from '../../../utilities/staffRequestUtils';
import * as applicationFormActions from '../../../redux/applicationForm';
import QuestionLibraryItem from './QuestionLibraryItem';
import EmptyList from './EmptyList';

const StyledCreateNewQuestionLink = styled(Typography.Text)`
  &:hover {
    text-decoration: underline;
  }
`;

const QuestionLibrary = ({
  questionLibrary,
  username,
  onSelectQuestion,
  onClickCreateNewQuestion,
  getQuestions,
  resetQuestionLibrary,
}) => {
  const { t } = useTranslation();

  const { loading, questions } = questionLibrary;

  useEffect(() => {
    getQuestions();
    return () => {
      // reset question library on mount
      resetQuestionLibrary();
    };
  }, []);

  const handleSearch = async searchText => {
    getQuestions(searchText);
  };

  const showCreateNewQuestion = staffRequestUtils.isWorkmateAccount(username);

  const renderCreateNewQuestionElement = () => (
    <Trans i18nKey="cantFindCreateNewQuestion">
      <Typography.Paragraph
        style={{
          marginBottom: 0,
          fontWeight: 400,
          color: colors.secondaryText,
          margin: '0 auto 10px auto',
          padding: '0 10px',
        }}
      >
        Cant find what you want?
        <StyledCreateNewQuestionLink
          style={{ color: colors.functionalLink, cursor: 'pointer' }}
          onClick={onClickCreateNewQuestion}
        >
          Create new question
        </StyledCreateNewQuestionLink>
      </Typography.Paragraph>
    </Trans>
  );

  return (
    <Row style={{ flexDirection: 'column', height: '100%' }}>
      <Row style={{ padding: 24 }}>
        <Input.Search
          placeholder={t('findQuestionsOrAnswers')}
          allowClear
          style={{ marginBottom: 8 }}
          onSearch={handleSearch}
        />
        <Typography.Paragraph style={{ color: colors.disabled, marginBottom: 0 }}>
          {t('questionsResultsFound', { count: questions.length })}
        </Typography.Paragraph>
      </Row>

      {loading && <Spin />}

      {!loading && (
        <Col style={{ flex: 1, overflowY: 'auto', marginBottom: 14 }}>
          {questions.length > 0 ? (
            <>
              {questions.map(question => (
                <QuestionLibraryItem
                  key={question.id}
                  question={question}
                  onSelectQuestion={() => onSelectQuestion(question)}
                />
              ))}

              {showCreateNewQuestion && <Row style={{ margin: '40px auto' }}>{renderCreateNewQuestionElement()}</Row>}
            </>
          ) : (
            <EmptyList style={{ width: '100%' }} description={t('searchQuestionsNoResults')}>
              {showCreateNewQuestion && renderCreateNewQuestionElement()}
            </EmptyList>
          )}
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = state => ({
  username: state.user.username,
  questionLibrary: state.applicationForm.questionLibrary,
});

const mapDispatchToProps = dispatch => {
  const { getQuestions, resetQuestionLibrary } = applicationFormActions;
  return {
    getQuestions: search => {
      dispatch(getQuestions(search));
    },
    resetQuestionLibrary: () => {
      dispatch(resetQuestionLibrary());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionLibrary);
