/* eslint-disable no-param-reassign */
import axios from 'axios';
import { localStorageKeys, sessionStorageKeys } from '../constants';
import routes from '../routes';

const api = axios.create({});

// Whenever a request is made, we attach our token and accept language in header.
api.interceptors.request.use(config => {
  const token = localStorage.getItem(localStorageKeys.TOKEN);
  const currentLanguage = localStorage.getItem('i18nextLng') || 'en';
  const managerId = sessionStorage.getItem(sessionStorageKeys.MANAGER_ID);
  if (token) {
    config.headers.Authorization = `Token ${token}`;

    if (managerId) {
      config.params = config.params || {};
      config.params.manager = managerId;
    }
  }
  config.headers['Accept-Language'] = currentLanguage;
  return config;
});

// Whenever a response is received
api.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response.data;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    if (error?.response?.status === 401) {
      localStorage.removeItem('token');
      if (window?.location?.search?.includes('token')) {
        // this prevent infinite loop for automatically logging in user via token from url
        window.location.href = routes.login;
      } else {
        window.location.reload();
      }
    }

    return Promise.reject(error);
  },
);

export default {
  api,
};
