import { Divider, Row, Typography, Grid, Col } from 'antd';
import React from 'react';
import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { clientTypes, countryCodeOptions, wageTypes, pricingUnitTypes, gigContractTypes } from '../../../../constants';
import { colors } from '../../../../styles/colors';
import amountUtils from '../../../../utilities/amountUtils';

const { Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const TotalEstimatedCostSection = ({ total, currencyCode }) => {
  const { t } = useTranslation();
  return (
    <Row style={{ justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: 0, alignItems: 'center' }}>
      <Text style={{ fontWeight: '600', fontSize: 16, display: 'block', color: colors.primaryText, marginRight: 10 }}>
        {t('totalEstimatedCost')}
      </Text>
      <Text style={{ color: colors.primaryText, fontWeight: '600' }}>
        {amountUtils.formatAmount(total || 0, currencyCode)}
      </Text>
    </Row>
  );
};

const CostDetails = ({ title, total, calcDetails = false, showCalcDetails }) => {
  return (
    <Row style={{ marginBottom: 8 }}>
      <Col style={{ flex: 1 }}>
        <Paragraph style={{ color: colors.primaryText, marginBottom: 2 }}>{title}</Paragraph>
        {showCalcDetails && (
          <Paragraph style={{ color: colors.secondaryText, fontSize: 12, marginBottom: 0 }}>{calcDetails}</Paragraph>
        )}
      </Col>
      <Col>
        <Text style={{ color: colors.primaryText }}>{total}</Text>
      </Col>
    </Row>
  );
};

const EstimatedCost = ({
  visible,
  template,
  wageAmount,
  shiftDurationWithBreaks,
  selectedStaffRequired,
  selectedDates,
  pricing,
  countryCode,
  currencyCode,
  clientType,
  gigContractType,
  breakDownCalculation,
}) => {
  const { t } = useTranslation();

  const { xs, md } = useBreakpoint();

  const clientServiceFeeType = pricing?.unit_type;
  const clientServiceFee = pricing?.unit_cost || 0;
  const isClientSG = countryCode === countryCodeOptions.SINGAPORE;
  const isClientManagedService = clientType === clientTypes.MANAGED_SERVICE;
  const isHourlyGig = template?.wage_type === wageTypes.PER_HOUR.value;

  const govtBenefitsDetails = [
    { title: t('govtBenefitsCpf') },
    { title: t('govtBenefitsSdl') },
    { title: t('govtBenefitsInsurance') },
  ];

  if (!visible) {
    return null;
  }

  const stickyStyles = md ? { position: 'sticky', right: 0, top: 80 } : {};

  const renderEstimatedCost = wages => {
    return wages
      ? Object.entries(wages).map(([label, wage]) => {
          if (isHourlyGig) {
            return (
              <Text key={label}>
                {`${t('salaryCalculationHourly', {
                  salary: amountUtils.formatAmount(parseFloat(wage.wage_amount), currencyCode),
                  hours: parseFloat(parseFloat(wage.hours).toFixed(1)),
                  count: wage.staff_required,
                  numOfShifts: wage.num_of_shifts,
                })} (${t(camelCase(label))})`}
                <br />
              </Text>
            );
          }

          return (
            <Text key={label}>
              {`${t('salaryCalculationDaily', {
                salary: amountUtils.formatAmount(parseFloat(wage.wage_amount), currencyCode),
                count: wage.staff_required,
                numOfShifts: wage.num_of_shifts,
              })} (${t(camelCase(label))})`}
              <br />
            </Text>
          );
        })
      : null;
  };

  return (
    <div
      style={{
        backgroundColor: colors.whiteBackground,
        padding: '16px',
        marginTop: xs ? 20 : 0,
        ...stickyStyles,
      }}
    >
      {/* Estimated cost */}
      <Text style={{ fontWeight: '600', fontSize: 16, marginBottom: 10, display: 'block', color: colors.primaryText }}>
        {t('estimatedCost')}
      </Text>

      {gigContractType?.toLowerCase() === gigContractTypes.FREELANCE ? (
        <>
          {/* Freelancer fee */}
          <CostDetails
            title={t('freelancerFee')}
            calcDetails={t('freelancerFeeCalc', {
              salary: amountUtils.formatAmount(wageAmount, currencyCode),
              hours: (shiftDurationWithBreaks / 60).toFixed(1),
              count: selectedStaffRequired,
              numOfShifts: selectedDates?.length,
            })}
            showCalcDetails={Boolean(breakDownCalculation?.freelancer_fee)}
            total={amountUtils.formatAmount(breakDownCalculation?.freelancer_fee, currencyCode)}
          />
          {/* Workmate Fee */}
          <CostDetails
            title={t('workmateFee')}
            calcDetails={t('workmateFeeCalc', {
              fee: amountUtils.formatAmount(parseInt(pricing?.unit_cost, 10), currencyCode),
              count: selectedStaffRequired,
              numOfShifts: selectedDates?.length,
            })}
            showCalcDetails={Boolean(breakDownCalculation?.workmate_fee)}
            total={amountUtils.formatAmount(breakDownCalculation?.workmate_fee, currencyCode)}
          />
          {/* Discount Fee */}
          {breakDownCalculation?.discount > 0 && (
            <CostDetails
              title={t('discount')}
              showCalcDetails={Boolean(breakDownCalculation?.discount)}
              total={`${breakDownCalculation?.discount > 0 ? '- ' : ''}${amountUtils.formatAmount(
                breakDownCalculation?.discount,
                currencyCode,
              )}`}
            />
          )}
          {/* GST Fee */}
          <CostDetails
            title={t('gst')}
            calcDetails={t('gstFeeCalc', {
              fee: pricing?.tax_rate || 0,
              workmateFee: amountUtils.formatAmount(
                breakDownCalculation?.gst_fee > 0
                  ? breakDownCalculation?.workmate_fee - breakDownCalculation?.discount
                  : 0,
                currencyCode,
              ),
            })}
            showCalcDetails={breakDownCalculation?.workmate_fee}
            total={amountUtils.formatAmount(breakDownCalculation?.gst_fee, currencyCode)}
          />
          <Divider style={{ margin: '10px 0 10px 0' }} />
          {/* Total Estimated Cost */}
          <TotalEstimatedCostSection total={breakDownCalculation?.total_estimated_cost} currencyCode={currencyCode} />
        </>
      ) : (
        <>
          {/* Total wage */}
          <CostDetails
            title={t('totalWage')}
            calcDetails={renderEstimatedCost(breakDownCalculation?.total_wage_breakdown)}
            showCalcDetails
            total={amountUtils.formatAmount(breakDownCalculation?.total_salary, currencyCode)}
          />
          {/* SG Gov't benefits */}
          {isClientSG && (
            <CostDetails
              title={t('governmentBenefits')}
              calcDetails={govtBenefitsDetails.map(detail => (
                <Text
                  key={detail.title}
                  style={{ color: colors.secondaryText, display: 'block', fontSize: 12, marginBottom: 2 }}
                >
                  {detail.title}
                </Text>
              ))}
              showCalcDetails
              total={amountUtils.formatAmount(breakDownCalculation?.gov_benefits_fee, currencyCode)}
            />
          )}
          {/* Service Fee */}
          {isClientManagedService && pricing?.unit_cost && clientServiceFeeType === pricingUnitTypes.PERCENTAGE && (
            <CostDetails
              title={t('serviceFee')}
              calcDetails={t('serviceFeeDesc', {
                percentage: clientServiceFee,
              })}
              showCalcDetails
              total={amountUtils.formatAmount(breakDownCalculation?.client_service_fee, currencyCode)}
            />
          )}
          <Divider style={{ margin: '10px 0 10px 0' }} />
          {/* Total Estimated Cost */}
          <TotalEstimatedCostSection total={breakDownCalculation?.total_estimated_cost} currencyCode={currencyCode} />
        </>
      )}
    </div>
  );
};

export default EstimatedCost;
