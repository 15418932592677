import React from 'react';
import { uniq, debounce, clone, cloneDeep, groupBy } from 'lodash';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Typography, Button, Drawer, Table, Row, Col, Input, Divider, Tooltip, Card } from 'antd';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { colors } from '../../../styles/colors';

import textUtils from '../../../utilities/textUtils';
import routes from '../../../routes';
import { attendanceStatuses, DATE_KEY_FORMAT } from '../../../constants';

class AddWorkersDrawer extends React.Component {
  state = {
    loading: false,
    selectedRowKeys: undefined,
    workerData: [],
    originalWorkerData: [],
    fulfilmentDiff: undefined,
  };

  componentDidMount() {
    this.fetchData();
    this.getFulfilment();
  }

  // TODO check this is necessary
  onSearchChange = debounce(search => {
    this.handleSearch(search);
  }, 600);

  hasNoAssignedRoles = roles => {
    return roles.filter(role => role.is_assigned).length === 0;
  };

  isWorkerEligible = (employment, schedulePosition) => {
    const { scheduleRole } = this.props;
    const { roles, position_id } = employment;
    /*
      Checks if worker is eligible to work for a shift with role 
      Worker is eligible if:
        - Worker has an assigned and active role that matches the role of the schedule 
        - Worker has no roles assigned and worker has same position as schedule 
    */
    return (
      roles.find(
        employmentRole =>
          employmentRole.role.is_active && employmentRole.is_assigned && employmentRole.role.id === scheduleRole.roleId,
      ) ||
      (this.hasNoAssignedRoles(roles) && position_id === schedulePosition.id)
    );
  };

  fetchData = () => {
    // NO ROLES logic to display eligible workers in worker drawer
    // 1. Get all workers assigned correct position
    // 2. Get all workers assigned to this schedule
    // 3. Get all workers with correct postion, not assigned to this schedule

    // ROLES logic to display eligible workers in worker drawer
    // 1. Get all workers assigned correct role or no role
    // 2. Get all workers assigned to this schedule
    // 3. Get all workers with correct role or no role, not assigned to this schedule

    const { employments, shifts, schedule, scheduleRole } = this.props;

    const selectedScheduleShifts = shifts.filter(shift => shift.schedule.id === schedule.id);

    let availableWorkers;
    if (!scheduleRole) {
      // No roles
      const workersWithPosition = employments.filter(employment => employment.position_id === schedule.position.id);
      const assignedWorkerIds = uniq(
        selectedScheduleShifts
          .flatMap(shift => shift.attendances)
          .filter(attendance => attendance.status === attendanceStatuses.ASSIGNED)
          .map(attendance => attendance.partner_id),
      );
      const assignedWorkers = employments.filter(employment => assignedWorkerIds.includes(employment.partner.id));
      availableWorkers = workersWithPosition.filter(employment => !assignedWorkers.includes(employment));
    } else {
      // Roles
      const eligibleWorkers = employments.filter(employment => this.isWorkerEligible(employment, schedule.position));
      const assignedWorkerIds = uniq(
        selectedScheduleShifts
          .flatMap(shift => shift.attendances)
          .filter(attendance => attendance.status === attendanceStatuses.ASSIGNED)
          .map(attendance => attendance.partner_id),
      );
      const assignedWorkers = employments.filter(employment => assignedWorkerIds.includes(employment.partner.id));
      const availableWorkersWithoutRoleNames = eligibleWorkers.filter(
        employment => !assignedWorkers.includes(employment),
      );
      availableWorkersWithoutRoleNames.sort((a, b) => a.partner.first_name.localeCompare(b.partner.first_name));

      availableWorkers = availableWorkersWithoutRoleNames.map(worker => {
        const workerRoles = this.getRoles(worker);
        const workerDraft = cloneDeep(worker);
        workerDraft.roleNames = workerRoles;
        return workerDraft;
      });
    }
    availableWorkers = availableWorkers.map(worker => {
      return {
        ...worker,
        daysAssigned: this.getDaysAssigned(worker.partner.id),
      };
    });

    // Sort workers by days assigned
    availableWorkers.sort(this.sortDaysAssigned);

    this.setState({
      originalWorkerData: availableWorkers,
      workerData: availableWorkers,
    });
  };

  getAttendance = (workerId, shifts) => {
    const attendances = shifts.flatMap(shift => shift.attendances);
    return attendances.find(
      attendance => attendance.partner_id === workerId && attendance.status === attendanceStatuses.ASSIGNED,
    );
  };

  getDaysAssigned = partnerId => {
    const { weekdayDates, shifts } = this.props;
    const assignedDays = weekdayDates.reduce((totalDaysAssigned, currentDay) => {
      const dateKey = currentDay.format(DATE_KEY_FORMAT);
      const shiftsByDay = groupBy(shifts, shift => {
        return moment(shift.start_time).format(DATE_KEY_FORMAT);
      });
      const currentDayShifts = shiftsByDay[dateKey] || [];
      const attendance = this.getAttendance(partnerId, currentDayShifts);
      return totalDaysAssigned + (attendance?.status === attendanceStatuses.ASSIGNED ? 1 : 0);
    }, 0);
    return assignedDays;
  };

  sortDaysAssigned = (a, b) => {
    if (a.daysAssigned === b.daysAssigned) {
      return a.partner.first_name.localeCompare(b.partner.first_name);
    }
    return a.daysAssigned - b.daysAssigned;
  };

  sortRoleNames = (a, b) => {
    // If no roles, sort alphabetically
    if (!a.roleNames.length && !b.roleNames.length) {
      return a.partner.first_name.localeCompare(b.partner.first_name);
    }
    // If 1st worker has 1 role assigned then they should be at the top
    // If 1st worker has multiple roles and 2nd has no roles, then 1st worker should be top
    if (
      (a.roleNames.length === 1 && b.roleNames.length !== 1) ||
      (a.roleNames.length > 1 && b.roleNames.length === 0)
    ) {
      return -1;
    }
    // If 1st worker and 2nd worker have same num roles, then sort alphabetically
    if (
      (a.roleNames.length === 1 && b.roleNames.length === 1) ||
      (a.roleNames.length > 1 && b.roleNames.length > 1) ||
      (a.roleNames.length === 0 && b.roleNames.length === 1)
    ) {
      return a.partner.first_name.localeCompare(b.partner.first_name);
    }
    return 0;
  };

  getRoles = record => {
    const { scheduleRole } = this.props;
    const roleNames = record.roles
      .filter(item => item.is_assigned && item.role.is_active)
      .map(item => item.role.name)
      .filter(item => item !== scheduleRole.roleName);
    return this.hasNoAssignedRoles(record.roles) ? [] : [scheduleRole.roleName, ...roleNames];
  };

  handleSelectedRows = selectedRowKeys => {
    this.setState({
      selectedRowKeys,
    });
  };

  getFulfilment = () => {
    const { shifts, schedule, scheduleRole } = this.props;

    const selectedScheduleShifts = shifts.filter(shift => shift.schedule.id === schedule.id);
    let fulfilmentDiff = 0;
    if (scheduleRole) {
      selectedScheduleShifts.forEach(shift => {
        const attendances = shift.attendances.filter(
          attendance =>
            attendance.status === attendanceStatuses.ASSIGNED &&
            !attendance.absence_reason &&
            attendance?.role_id === scheduleRole.roleId,
        );
        const staffRequired = shift.shift_roles.find(role => role.role.id === scheduleRole.roleId).staff_required;
        if (staffRequired - attendances.length > fulfilmentDiff) {
          fulfilmentDiff = staffRequired - attendances.length;
        }
      });
    } else {
      selectedScheduleShifts.forEach(shift => {
        const attendances = shift.attendances.filter(
          attendance => attendance.status === attendanceStatuses.ASSIGNED && !attendance.absence_reason,
        );
        const staffRequired = shift.staff_required;
        if (staffRequired - attendances.length > fulfilmentDiff) {
          fulfilmentDiff = staffRequired - attendances.length;
        }
      });
    }
    this.setState({ fulfilmentDiff });
  };

  handleSearch = value => {
    const { originalWorkerData } = this.state;
    const value_lower = value.toLowerCase();

    if (!value) {
      return this.setState({ workerData: originalWorkerData });
    }

    const workerDataToFilter = originalWorkerData;
    const updatedWorkerData = workerDataToFilter.filter(worker =>
      `${worker.partner.first_name.toLowerCase()} ${worker.partner.last_name.toLowerCase()}`.includes(value_lower),
    );
    this.setState({ workerData: updatedWorkerData });
  };

  render() {
    const { t, schedule, onCancel, visible, onSubmit, scheduleRole } = this.props;
    const { loading, selectedRowKeys, workerData, fulfilmentDiff } = this.state;

    const tableColumns = [
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('Name')}</Typography.Text>,
        width: 180,
        dataIndex: 'partner',
        sorter: (a, b) => a.partner.first_name.localeCompare(b.partner.first_name),
        render: partner => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Link to={routes.partnerDetail.replace(':id', partner.id)} target="_blank">
                <Typography.Text>{textUtils.toTitleCase(`${partner.first_name} ${partner.last_name}`)}</Typography.Text>
              </Link>
            </div>
          );
        },
      },
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('daysAssigned')}</Typography.Text>,
        width: 120,
        dataIndex: 'daysAssigned',
        sorter: this.sortDaysAssigned,
        render: daysAssigned => {
          return <Typography.Text>{daysAssigned}</Typography.Text>;
        },
      },
    ];

    if (scheduleRole) {
      tableColumns.push({
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('assignedRoles')}</Typography.Text>,
        width: 180,
        dataIndex: 'roleNames',
        sorter: this.sortRoleNames,
        render: roleNames => {
          const roleNamesUpdated = roleNames?.length > 0 && clone(roleNames);
          const roleNamesTooltip = roleNames?.length > 0 && roleNamesUpdated.slice(1).toString();

          return (
            <Row type="flex" gutter={6}>
              <Col>
                {roleNames.length > 0 ? (
                  <Typography.Text>{roleNames[0]}</Typography.Text>
                ) : (
                  <Typography.Text type="secondary">{t('noRolesSet')}</Typography.Text>
                )}
              </Col>
              <Col>
                {roleNames.length > 1 && (
                  <Tooltip title={roleNamesTooltip}>
                    <Typography.Text type="secondary">{`+${roleNames.length - 1}`}</Typography.Text>
                  </Tooltip>
                )}
              </Col>
            </Row>
          );
        },
      });
    }

    return (
      <Drawer width="560" placement="right" closable onClose={onCancel} visible={visible}>
        <Row>
          <Typography.Text style={{ fontWeight: 600, fontSize: 24, color: colors.black }}>
            {t('addWorkerModalTitle')}
          </Typography.Text>
        </Row>
        <Row style={{ marginBottom: 16 }}>
          <Typography.Text type="secondary">{t('addWorkerModalDescription')}</Typography.Text>
        </Row>
        <Card
          style={{
            backgroundColor: colors.infoBackgroundBlue,
            border: `1px solid ${colors.infoHighlightBlue}`,
            borderRadius: 4,
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
            marginTop: 0,
            marginBottom: 24,
          }}
          bodyStyle={{ padding: 8 }}
        >
          <Row type="flex" gutter={4}>
            <Col>
              <ExclamationCircleFilled style={{ marginRight: '8px', color: colors.infoHighlightBlue }} />
            </Col>
            <Col>
              <Row>
                <Typography.Text strong>
                  {t('addWorkerDrawerPromptHeader', {
                    roleName: scheduleRole ? scheduleRole.roleName : schedule.position.name,
                    shiftName: schedule.name,
                  })}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text>
                  {t('addWorkerDrawerPromptDescription', {
                    roleName: scheduleRole ? scheduleRole.roleName : schedule.position.name,
                  })}
                </Typography.Text>
              </Row>
            </Col>
          </Row>
        </Card>
        <Row style={{ marginBottom: 16 }}>
          {/* Search Input */}
          <Input.Search
            loading={loading}
            placeholder={t('searchWorker')}
            style={{ width: 360 }}
            allowClear
            onSearch={this.handleSearch}
            onChange={event => this.onSearchChange(event.target.value)}
            ref={this.searchFilter}
          />
        </Row>
        <Table
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys,
            onChange: this.handleSelectedRows,
          }}
          indentSize={0}
          loading={loading}
          columns={tableColumns}
          dataSource={workerData}
          rowKey={record => record.partner.id}
          pagination={false}
          scroll={{ y: 280 }}
        />
        <Divider />
        <Row type="flex" justify="space-between">
          <Col>
            <Typography.Text>{scheduleRole ? scheduleRole.roleName : schedule.position.name}</Typography.Text>
          </Col>
          <Col>
            <Row>
              <Typography.Text
                strong
                style={{
                  color: selectedRowKeys?.length >= fulfilmentDiff ? colors.workmateGreen : colors.functionalWarning,
                }}
              >
                {`${selectedRowKeys?.length || 0} ${t('Selected')} `}
              </Typography.Text>
              <Typography.Text strong style={{ color: colors.black }}>
                {`/ ${fulfilmentDiff} ${t('needed')}`}
              </Typography.Text>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row type="flex" justify="end" gutter={8} style={{ marginBottom: 36 }}>
          <Col>
            <Button
              onClick={() => {
                this.setState({ selectedRowKeys: [] });
                onCancel();
              }}
            >
              {t('cancel')}
            </Button>
          </Col>
          <Col>
            <Button
              type="v2-primary"
              onClick={() => {
                onSubmit(selectedRowKeys, schedule);
                this.setState({ selectedRowKeys: [] });
              }}
            >
              {t('Add')}
            </Button>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

export default withTranslation()(AddWorkersDrawer);
