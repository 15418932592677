// TODO: Refactor out into helpster-components library
import React from 'react';
import { Divider, Checkbox, Popover, Button, Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import { CaretDownFilled } from '@ant-design/icons';

const CheckboxGroup = Checkbox.Group;

class CheckboxFilter extends React.Component {
  state = {
    visible: false,
  };

  toggleVisiblity = () => {
    this.setState(prevState => {
      return {
        visible: !prevState.visible,
      };
    });
  };

  onApply = () => {
    this.setState(
      {
        visible: false,
      },
      () => this.props.onApply(),
    );
  };

  render() {
    const {
      title,
      selectAllTitle,
      options,
      selectedOptions,
      optionLabelField,
      onChange,
      onCheckAllChange,
      indeterminate,
      allChecked,
      showActionButtons = false,
    } = this.props;
    const { visible } = this.state;
    const { t } = this.props;
    return (
      <Popover
        visible={visible}
        trigger={['click']}
        placement="bottomLeft"
        onVisibleChange={visibleValue => this.setState({ visible: visibleValue })}
        overlayStyle={{ zIndex: 2 }}
        overlayClassName="ant-popover-wrapper"
        content={
          <>
            <Row style={{ minWidth: '185px', borderBottom: '1px solid #E9E9E9', padding: '6px 0 6px 6px' }}>
              <Checkbox indeterminate={indeterminate} checked={allChecked} onChange={onCheckAllChange}>
                {selectAllTitle}
              </Checkbox>
            </Row>
            <CheckboxGroup
              value={selectedOptions}
              onChange={onChange}
              style={{ display: 'flex', flexDirection: 'column', paddingLeft: '6px' }}
            >
              {options.map(option => (
                <Row type="flex" align="middle" key={option.id} style={{ height: '32px' }}>
                  <Checkbox value={option.id}>{t(option[optionLabelField])}</Checkbox>
                </Row>
              ))}
              {showActionButtons && (
                <>
                  <Divider style={{ margin: '12px 0' }} />
                  <Row type="flex" justify="space-between" style={{ paddingBottom: '12px' }}>
                    <Col>
                      <Button size="small" onClick={this.toggleVisiblity}>
                        {t('cancel')}
                      </Button>
                    </Col>
                    <Col>
                      <Button size="small" className="ant-btn-v2-primary" onClick={this.onApply}>
                        {t('apply')}
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </CheckboxGroup>
          </>
        }
      >
        <Button block style={{ padding: '0' }} onClick={this.toggleVisiblity}>
          {t(title)}
          <CaretDownFilled />
        </Button>
      </Popover>
    );
  }
}

export default withTranslation()(CheckboxFilter);
