import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { Table, Row, Col, Typography, Avatar, Input } from 'antd';

import { DEFAULT_DATE_FORMAT } from '../constants';
import routes from '../routes';
import { getPaginationConfig } from '../utilities';
import textUtils from '../utilities/textUtils';

import billingApi from '../services/billingApi';

import StatusTag from '../shared/components/StatusTag';

const { Text } = Typography;
const { Search } = Input;
const PartnerSnippet = ({ partner }) => {
  if (!partner) return <></>;
  return (
    <Row type="flex" gutter={8}>
      <Col>
        <Avatar shape="square" size={36} src={partner.image} className="v2-avatar-wrapper" />
      </Col>
      <Col>
        <Text strong>{`${partner.first_name} ${partner.last_name}`}</Text>
        <br />
        <Text type="secondary">{partner.id}</Text>
      </Col>
    </Row>
  );
};

class BillingItemTable extends React.Component {
  state = {
    isLoading: false,
    total: 0,
    billingItems: [],
  };

  columns = [
    {
      title: 'Item',
      dataIndex: 'id',
    },
    {
      title: 'Workmate',
      dataIndex: 'partner',
      width: 320,
      render: partner => <PartnerSnippet partner={partner} />,
    },
    {
      title: 'SR',
      dataIndex: 'timesheet_entry',
      render: timesheetEntry =>
        timesheetEntry && (
          <Link to={`${routes.staffRequests}/${timesheetEntry.staff_request_id}/timesheet`}>
            {`#${timesheetEntry.staff_request_id}`}
          </Link>
        ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: date => <span>{moment(date).format(DEFAULT_DATE_FORMAT)}</span>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: type => <StatusTag status={type} />,
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      render: quantity => <span>{textUtils.makeFriendly(`${quantity}`)}</span>,
    },
    {
      title: 'Cost',
      dataIndex: 'unit_cost',
      render: cost => <span>{cost.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>,
    },
    {
      title: 'Amount',
      dataIndex: 'total',
      render: total => <Text strong>{`${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>,
    },
  ];

  async componentDidMount() {
    await this.fetchBillingItemsFromParams();
  }

  onPageChange = nextPage => {
    this.setState({ page: nextPage }, this.fetchBillingItemsFromParams);
  };

  onSearch = searchedWorkerName => {
    this.setState({ searchedWorkerName, page: 1 }, () => this.fetchBillingItemsFromParams());
  };

  fetchBillingItemsFromParams = async () => {
    this.setState({ isLoading: true });
    const { page, searchedWorkerName } = this.state;
    const billingItemsResponse = await billingApi.fetchBillingItemsWithParams({
      billing_report: this.props.billingReportId,
      page: page || 1,
      search: searchedWorkerName,
    });
    this.setState({ billingItems: billingItemsResponse.results, total: billingItemsResponse.count, isLoading: false });
  };

  render() {
    const { billingItems, isLoading, total } = this.state;
    return (
      <>
        <Row style={{ marginBottom: '32px' }} type="flex" justify="end">
          <Search placeholder="Search Workmates" onSearch={this.onSearch} style={{ width: '216px' }} />
        </Row>
        <Row style={{ marginBottom: '54px' }}>
          <Table
            rowKey="id"
            dataSource={billingItems}
            columns={this.columns}
            loading={isLoading}
            pagination={getPaginationConfig(total, this.onPageChange)}
          />
        </Row>
      </>
    );
  }
}

export default BillingItemTable;
