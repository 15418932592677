import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Typography, Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';

import AssignmentTableCell from './AssignmentTableCell';
import routes from '../../../routes';
import textUtils from '../../../utilities/textUtils';

const { Text } = Typography;

class AssignmentTable extends Component {
  handleSelectChange = selectedRowKeys => {
    this.props.onSelect(selectedRowKeys);
  };

  handleAssignmentUpdate = update => {
    this.props.onAssignmentUpdate(update);
  };

  handleRoleUpdate = update => {
    this.props.onRoleUpdate(update);
  };

  render() {
    const { loading, dataSource, selectedRowKeys, timezone, scheduleId, t, isMonthlyView } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChange,
    };

    const tableColumns = [
      {
        title: (
          <Row>
            <Col span={15} style={{ paddingTop: '4px' }}>
              <Text strong>{t('Worker')}</Text>
            </Col>
          </Row>
        ),
        dataIndex: 'worker',
        render: worker => ({
          props: { style: { padding: 0 } },
          children: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  width: isMonthlyView ? '314px' : '485px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Link to={routes.partnerDetail.replace(':id', worker.partner.id)} target="_blank">
                  <Text strong>
                    {textUtils.toTitleCase(`${worker.partner.first_name} ${worker.partner.last_name}`)}
                  </Text>
                </Link>
              </div>
              {worker.assignments.map(assignment => {
                return (
                  <div style={{ width: isMonthlyView ? '80px' : '150px' }}>
                    <AssignmentTableCell
                      worker={worker.partner}
                      employmentRoles={worker.employmentRoles}
                      assignment={assignment}
                      onAssignmentUpdate={this.handleAssignmentUpdate}
                      onRoleUpdate={this.handleRoleUpdate}
                      timezone={timezone}
                      scheduleId={scheduleId}
                      isMonthlyView={isMonthlyView}
                    />
                  </div>
                );
              })}
            </div>
          ),
        }),
      },
    ];

    return (
      <Table
        style={{ marginBottom: 20 }}
        rowSelection={rowSelection}
        pagination={false}
        loading={loading}
        columns={tableColumns}
        dataSource={dataSource}
        rowKey="id"
        size="middle"
        className="v2-assignment-table"
      />
    );
  }
}

export default withTranslation()(AssignmentTable);
