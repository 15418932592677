import React from 'react';
import moment from 'moment-timezone';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, message, InputNumber, Radio, Modal, Typography, Button, Col, TimePicker, Alert, Select } from 'antd';
import { withTranslation } from 'react-i18next';
import shiftApi from '../../../services/shiftApi';
import scheduleApi from '../../../services/scheduleApi';
import { colors } from '../../../styles/colors';
import { attendanceStatuses, TIME_FORMAT_24_HOUR } from '../../../constants';
import datetimeUtils from '../../../utilities/datetimeUtils';
import formUtils from '../../../utilities/formUtils';

const { Item } = Form;
const { Title, Text } = Typography;

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const shiftEditTypes = {
  SINGLE_EDIT: 1,
  BULK_EDIT: 2,
};

class EditShiftFormModal extends React.Component {
  state = {
    isSubmitting: false,
    applyChangeValue: shiftEditTypes.SINGLE_EDIT,
    showShiftCancelWarning: false,
  };

  onChange = e => {
    this.setState({
      applyChangeValue: e.target.value,
    });
  };

  notifyWorkersOfShiftTimeUpdate = async shift => {
    const partner_ids_to_notify = shift.attendances
      .filter(att => att.status === attendanceStatuses.ASSIGNED && att.confirmed)
      .map(att => att.partner_id)
      .join(',');
    if (partner_ids_to_notify.length > 0) {
      await shiftApi.notifyTimeUpdate(shift.id, { partner_id: partner_ids_to_notify });
    }
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { applyChangeValue } = this.state;
    const { form, shift, schedule, onUpdate, t } = this.props;

    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ isSubmitting: true });
        try {
          const startTime = values.start_time;
          const endTime = values.end_time;
          const duration = datetimeUtils.getDurationFromStartTimeAndEndTime(startTime, endTime).format('HH:mm:00');

          const qrOverrideAllowed = schedule.qr_override_allowed;
          const fixedLocation = schedule.fixed_location;

          if (applyChangeValue === shiftEditTypes.BULK_EDIT) {
            await scheduleApi.bulkEditShifts(schedule.id, shift.id, {
              staff_required: values.staff_required,
              start_time: startTime.toISOString(),
              duration,
              qr_override_allowed: qrOverrideAllowed,
              fixed_location: fixedLocation,
              qr_code_clock_in: schedule.qr_code_clock_in,
              unpaid_break: values.unpaid_break === 'true',
              break_duration_in_minutes: values.break_duration_in_minutes,
            });
          } else {
            const isShiftTimeChanged = !moment(shift.start_time).isSame(startTime) || shift.duration !== duration;
            if (isShiftTimeChanged) {
              await shiftApi.editShift({
                id: shift.id,
                staff_required: values.staff_required,
                qr_override_allowed: qrOverrideAllowed,
                qr_code_clock_in: schedule.qr_code_clock_in,
                fixed_location: fixedLocation,
                start_time: startTime.toISOString(),
                duration,
                unpaid_break: values.unpaid_break === 'true',
                break_duration_in_minutes: values.break_duration_in_minutes,
                attendances: shift.attendances.map(att => {
                  return { ...att, confirmed: false };
                }),
              });
              // TODO: trigger from BE like bulk edit
              this.notifyWorkersOfShiftTimeUpdate(shift);
            } else {
              await shiftApi.editShift({
                id: shift.id,
                staff_required: values.staff_required,
                qr_override_allowed: values.qr_override_allowed,
                fixed_location: values.fixed_location,
                qr_code_clock_in: schedule.qr_code_clock_in,
                unpaid_break: values.unpaid_break === 'true',
                break_duration_in_minutes: values.break_duration_in_minutes,
              });
            }
          }
          onUpdate();
          message.success(t('saveShiftsSuccess'));
        } catch (error) {
          message.warning(t('saveShiftsError'));
        } finally {
          this.setState({ isSubmitting: false, showShiftCancelWarning: false });
        }
      }
    });
  };

  handleCancel = () => {
    this.setState({ showShiftCancelWarning: false });
    this.props.form.resetFields();
    this.props.onCancel();
  };

  render() {
    const { schedule, shift, date, visible, t, openDeleteShiftModal, timezone } = this.props;
    const { getFieldDecorator, setFieldsValue } = this.props.form;
    const { isSubmitting, applyChangeValue, showShiftCancelWarning } = this.state;

    const startTime = moment.tz(shift.start_time, timezone);
    const endTime = datetimeUtils.getEndTimeFromStartTimeAndDuration(startTime, shift.duration);

    return (
      <Modal
        visible={visible}
        onCancel={() => this.handleCancel()}
        title={<Title level={4}>{moment(date).format('ddd, DD MMM YYYY')}</Title>}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              style={{ color: colors.red }}
              onClick={() => {
                openDeleteShiftModal();
              }}
            >
              {t('delete')}
            </Button>
            <div>
              <Button onClick={() => this.handleCancel()}>{t('cancel')}</Button>
              <Button
                type="v2-primary"
                loading={isSubmitting}
                onClick={e => {
                  this.handleSubmit(e);
                }}
              >
                {t('Save')}
              </Button>
            </div>
          </div>
        }
      >
        <Form hideRequiredMark colon={false} className="v2-shifts-form">
          <Row type="flex" gutter={24}>
            <Col span={24}>
              <Text strong>{`${schedule.name} • ${startTime.format('h:mmA')}-${endTime.format('h:mmA')}`}</Text>
            </Col>
            <Col span={24} style={{ marginBottom: '10px' }}>
              {t('editShiftDescription')}
            </Col>
            <Col xs={12}>
              <Item label={t('startTime')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                {getFieldDecorator('start_time', {
                  initialValue: startTime,
                  rules: [{ required: true, message: t('fieldRequired.') }],
                })(
                  <TimePicker
                    minuteStep={15}
                    format={TIME_FORMAT_24_HOUR}
                    defaultOpenValue={moment('00:00', TIME_FORMAT_24_HOUR)}
                    style={{ width: '100%' }}
                  />,
                )}
              </Item>
            </Col>
            <Col xs={12}>
              <Item label={t('endTime')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                {getFieldDecorator('end_time', {
                  initialValue: endTime,
                  rules: [{ required: true, message: t('fieldRequired.') }],
                })(
                  <TimePicker
                    minuteStep={15}
                    format={TIME_FORMAT_24_HOUR}
                    defaultOpenValue={moment('00:00', TIME_FORMAT_24_HOUR)}
                    style={{ width: '100%' }}
                  />,
                )}
              </Item>
            </Col>
            <Col span={24}>
              <Item label={t('workerNeeded')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                {getFieldDecorator('staff_required', {
                  rules: [{ required: true, message: t('fieldRequired.') }],
                  initialValue: shift?.staff_required,
                })(<InputNumber min={0} style={{ width: 'calc(50% - 12px)' }} />)}
              </Item>
            </Col>
            <Col span={12}>
              <Item label={t('totalBreakDuration')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                {getFieldDecorator('break_duration_in_minutes', {
                  rules: [{ required: true, message: t('fieldIsRequired') }],
                  initialValue: shift.break_duration_in_minutes || 0,
                })(
                  <Select
                    style={{ width: '100%' }}
                    options={formUtils.getBreakDurationChoices(t)}
                    onChange={selectedInput => setFieldsValue({ break_duration_in_minutes: selectedInput })}
                  />,
                )}
              </Item>
            </Col>
            <Col span={12}>
              <Item label={t('breakType')} style={{ marginBottom: '4px' }} labelCol={{ span: 24 }}>
                {getFieldDecorator('unpaid_break', {
                  rules: [{ required: true, message: t('fieldIsRequired') }],
                  initialValue: shift?.unpaid_break?.toString() || 'true',
                })(
                  <Radio.Group
                    className="v2-radio-wrapper"
                    style={{ width: '100%' }}
                    onChange={e =>
                      setFieldsValue({
                        unpaid_break: e.target.value,
                      })
                    }
                  >
                    {[
                      { value: 'true', label: t('unpaidBreakType') },
                      { value: 'false', label: t('paidBreakType') },
                    ].map((option, index) => (
                      <Radio key={index} value={option.value}>
                        {option.label}
                      </Radio>
                    ))}
                  </Radio.Group>,
                )}
              </Item>
            </Col>
          </Row>
        </Form>

        <Row style={{ marginTop: '10px' }}>
          <Text>{t('applyChangesTo')}</Text>
        </Row>
        <Radio.Group onChange={this.onChange} value={applyChangeValue}>
          <Radio style={radioStyle} value={shiftEditTypes.SINGLE_EDIT}>
            {t('thisShift')}
          </Radio>
          <Radio style={radioStyle} value={shiftEditTypes.BULK_EDIT}>
            {t('allShifts')}
          </Radio>
        </Radio.Group>
        {showShiftCancelWarning && (
          <Alert
            style={{ margin: '12px 0' }}
            showIcon
            type="warning"
            message={
              <>
                <Row>{t('reduceScheduleWarningTitle')}:</Row>
                <Row>• {t('reduceScheduleWarningPoint1')}</Row>
                <Row>• {t('reduceScheduleWarningPoint2')}</Row>
              </>
            }
          />
        )}
      </Modal>
    );
  }
}
export default Form.create()(withTranslation()(EditShiftFormModal));
