import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import { DEFAULT_DATE_FORMAT } from '../../../../constants';
import { getPaginationConfig } from '../../../../utilities';
import routes from '../../../../routes';

import billingApi from '../../../../services/billingApi';

import StatusTag from '../../../../shared/components/StatusTag';

const ColumnTitleWithTooltip = ({ title, toolTipMessage }) => (
  <span>
    {title}
    <Tooltip placement="top" title={toolTipMessage}>
      <InfoCircleTwoTone style={{ marginLeft: '6px' }} />
    </Tooltip>
  </span>
);

const InvoiceUrls = ({ invoices, t }) => {
  if (!invoices || invoices.length === 0) {
    return <> </>;
  }

  if (invoices.length === 1) {
    return <a href={invoices[0].url}>View receipt</a>;
  }

  return (
    <>
      {invoices.map((invoice, index) => (
        <section key={index}>
          <a href={invoice.url}>{`${t('Receipt')} (${invoice.name})`}</a>
          <br />
        </section>
      ))}
    </>
  );
};

class BillingReportTable extends React.Component {
  state = {
    isLoading: false,
    billingReports: [],
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const billingReportsResponse = await billingApi.fetchBillingReports({ page: 1 }, {});
    this.setState({
      billingReports: billingReportsResponse.results,
      total: billingReportsResponse.count,
      isLoading: false,
    });
  }

  onPageChange = async nextPage => {
    this.setState({ isLoading: true });
    const nextBillingReportsResponse = await billingApi.fetchBillingReports({ page: nextPage }, {});
    this.setState({
      billingReports: nextBillingReportsResponse.results,
      isLoading: false,
    });
  };

  render() {
    const { isLoading, billingReports, total } = this.state;
    const { t } = this.props;
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        width: 75,
        render: id => <Link to={routes.billingReportDetail.replace(':id', id)}>{id}</Link>,
      },
      {
        title: t('Billing date'),
        dataIndex: 'billing_date',
        render: billing_date => moment(billing_date).format(DEFAULT_DATE_FORMAT),
      },
      {
        title: t('Items'),
        width: 75,
        dataIndex: 'items_count',
        render: items_count => <span>{items_count}</span>,
      },
      {
        title: (
          <ColumnTitleWithTooltip
            title={t('Amount')}
            toolTipMessage={t('Total amount includes total wages approved, service fees and VAT')}
          />
        ),
        dataIndex: 'billing_amount',
        render: billing_amount => <span>{`${billing_amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>,
      },
      {
        title: t('Status'),
        dataIndex: 'status',
        render: status => <StatusTag status={status} />,
      },
      {
        title: t('Receipt'),
        dataIndex: 'invoices',
        render: invoices => <InvoiceUrls invoices={invoices} t={t} />,
      },
    ];
    return (
      <Table
        rowKey="id"
        loading={isLoading}
        dataSource={billingReports}
        columns={columns}
        pagination={getPaginationConfig(total, this.onPageChange)}
        scroll={{ x: 700, y: 300 }}
      />
    );
  }
}

export default withTranslation()(BillingReportTable);
