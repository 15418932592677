import React from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { connect } from 'react-redux';
import DeleteButton from './DeleteButton';
import * as applicationFormActions from '../../../redux/applicationForm';

const QuestionDeleteButton = ({ question, index, deleteSelectedQuestion }) => {
  const { t } = useTranslation();

  const handleDeleteQuestion = () => {
    deleteSelectedQuestion({ id: question.id, index });
    message.success(t('questionDeleted'));
  };

  return <DeleteButton type="button" aria-label="Remove question" onClick={handleDeleteQuestion} />;
};

const mapDispatchToProps = dispatch => {
  const { deleteSelectedQuestion } = applicationFormActions;
  return {
    deleteSelectedQuestion: payload => {
      dispatch(deleteSelectedQuestion(payload));
    },
  };
};

export default connect(undefined, mapDispatchToProps)(QuestionDeleteButton);
