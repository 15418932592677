import React from 'react';
import { Row, Typography, Col, Card } from 'antd';
import { withTranslation } from 'react-i18next';
import CubeJsQueryRenderer from '../../../shared/components/CubeJsQueryRenderer';

const { Text } = Typography;

class ReducedDemandCountCard extends React.Component {
  numberRender = ({ resultSet }) => {
    const { t } = this.props;

    const count =
      resultSet.loadResponse.results[0].data && Math.round(Object.values(resultSet.loadResponse.results[0].data[0])[0]);
    return (
      <Card style={{ width: '100%' }} bodyStyle={{ margin: '24px 16px', padding: 0 }}>
        <Row justify="center">
          <Col>
            <Text strong style={{ fontSize: '38px' }}>
              {count}
            </Text>
          </Col>
        </Row>
        <Row justify="center" style={{ marginBottom: '4px', textAlign: 'center', alignItems: 'center' }}>
          <Col>
            <Text strong type="secondary" style={{ fontSize: '12px', paddingRight: '8px' }}>
              {t('workersYouCancelled').toUpperCase()}
            </Text>
          </Col>
        </Row>
      </Card>
    );
  };

  formatQueryData = (timePeriod, clientId, positionIds, locationIds) => {
    const query = {
      filters: [
        {
          member: 'ClientsClient.id',
          operator: 'equals',
          values: [clientId.toString()],
        },
        {
          dimension: 'RecruitmentEmployment.cancellationReason',
          operator: 'equals',
          values: ['reduced_demand'],
        },
      ],
      timeDimensions: [
        {
          dimension: 'RecruitmentEmployment.endDate',
          dateRange: timePeriod,
        },
      ],
    };
    if (positionIds && positionIds.length > 0) {
      query.filters.push({
        member: 'PartnersPosition.id',
        operator: 'equals',
        values: positionIds,
      });
    }
    if (locationIds && locationIds.length > 0) {
      query.filters.push({
        member: 'ClientsLocation.id',
        operator: 'equals',
        values: locationIds,
      });
    }
    return query;
  };

  render() {
    const { timePeriod, clientId, positionIds, locationIds } = this.props;
    const formattedProps = this.formatQueryData(timePeriod, clientId, positionIds, locationIds);

    return (
      <CubeJsQueryRenderer
        measures={['PartnersPartner.count']}
        timeDimensions={formattedProps.timeDimensions}
        filters={formattedProps.filters}
        chartFunction={this.numberRender}
      />
    );
  }
}

export default withTranslation()(ReducedDemandCountCard);
