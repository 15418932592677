import React from 'react';
import { withTranslation } from 'react-i18next';
import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Col, Modal, Button, Typography, Tag } from 'antd';

import { colors } from '../../../styles/colors';

const { Text, Title } = Typography;

const BulkHireFailModal = ({ visible, onCancel, t, selectedIdsCount, failedApprovalIdsCount }) => {
  const allFail = selectedIdsCount - failedApprovalIdsCount === 0;
  return (
    <Modal
      centered
      destroyOnClose
      zIndex={99998}
      visible={visible}
      closable
      title={null}
      onCancel={onCancel}
      footer={
        <Row justify="center">
          <Button type="primary" onClick={onCancel}>
            {t('done')}
          </Button>
        </Row>
      }
    >
      <Row justify="center" style={{ marginBottom: 28, marginTop: 28 }}>
        {allFail ? (
          <CloseCircleFilled style={{ color: colors.functionalError, fontSize: 68 }} />
        ) : (
          <CheckCircleFilled style={{ color: colors.functionalSuccess, fontSize: 68 }} />
        )}
      </Row>
      <Row justify="center">
        <Title level={3}>
          {selectedIdsCount - failedApprovalIdsCount} {t('bulkHireFailTitle')}
        </Title>
      </Row>
      <Row justify="center" style={{ textAlign: 'center' }}>
        <Text>{t('bulkHireApplicantsNotified')}</Text>
      </Row>
      {failedApprovalIdsCount > 0 && (
        <Tag
          color={colors.tagYellow}
          style={{ borderRadius: 2, borderColor: colors.yellow, padding: 16, width: '100%', marginTop: 24 }}
        >
          <Row gutter={8} justify="start">
            <Col span={2} align="center">
              <ExclamationCircleOutlined style={{ color: colors.functionalWarning, fontSize: 21 }} />
            </Col>
            <Col span={22}>
              <Row>
                <Typography.Text strong style={{ color: colors.primary, marginLeft: 8, marginBottom: 8, fontSize: 14 }}>
                  {t('bulkHireFailModalPromptTitle', { count: failedApprovalIdsCount })}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text style={{ color: colors.primary, marginLeft: 8, fontSize: 14, whiteSpace: 'pre-line' }}>
                  {t('bulkHireFailModalPromptDescription1')}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text style={{ color: colors.primary, marginLeft: 8, fontSize: 14 }}>
                  {t('bulkHireFailModalPromptDescription2')}
                </Typography.Text>
              </Row>
            </Col>
          </Row>
        </Tag>
      )}
    </Modal>
  );
};

export default withTranslation()(BulkHireFailModal);
