import React from 'react';
import { withTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import { Row, Col, Typography, Card, Empty } from 'antd';
import moment from 'moment';

import ToolTipWithInfo from '../../../shared/components/ToolTipWithInfo';
import CubeJsQueryRenderer from '../../../shared/components/CubeJsQueryRenderer';
import { colors } from '../../../styles/colors';
import { getNumberAbbreviation, COLORS_SERIES } from '../../../utilities/analyticsUtils';

const { Text } = Typography;

class FulfilmentTrendChart extends React.Component {
  formatQueryData = () => {
    const { positionIds, locationIds, timePeriod, clientId, granularity } = this.props;
    const query = {
      dimensions: [],
      timeDimensions: [
        {
          dimension: 'SchedulingShift.startTime',
          dateRange: timePeriod,
          granularity,
        },
      ],
      filters: [
        {
          member: 'ClientsClient.id',
          operator: 'equals',
          values: [clientId.toString()],
        },
      ],
      measures: ['FulfilmentRate.fulfilmentRate'],
    };
    if (positionIds && positionIds.length > 0) {
      query.filters.push({
        member: 'PartnersPosition.id',
        operator: 'equals',
        values: positionIds,
      });
    }
    if (locationIds && locationIds.length > 0) {
      query.filters.push({
        member: 'ClientsLocation.id',
        operator: 'equals',
        values: locationIds,
      });
    }
    return query;
  };

  lineRender = ({ resultSet }) => {
    const { t } = this.props;

    const data = {
      labels: resultSet.categories().map(c => moment(c.category).format('ddd, DD MMM')),
      datasets: resultSet.series().map(s => ({
        label: 'fulfilmentRate',
        data: s.series.map(r => Math.round(r.value)),
        backgroundColor: COLORS_SERIES[0],
        borderColor: COLORS_SERIES[0],
        fill: false,
      })),
    };

    const options = {
      elements: {
        line: {
          tension: 0,
        },
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: t('Date'),
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: value => {
                const tickMark = getNumberAbbreviation(value);
                return tickMark;
              },
            },
            scaleLabel: {
              display: true,
              labelString: t('fulfilmentRate'),
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          title: tooltipItem => tooltipItem[0].label,
          label: tooltipItem => {
            return `${tooltipItem.value}% fulfilment rate`;
          },
        },
        backgroundColor: colors.white,
        titleFontColor: colors.black,
        bodyFontColor: colors.black,
        borderColor: colors.grey,
        borderWidth: 0.5,
      },
    };
    const legend = {
      display: false,
      position: 'right',
      fullWidth: true,
      reverse: false,
    };

    return data?.datasets[0]?.data?.some(item => item > 0) ? (
      <Line data={data} options={options} legend={legend} />
    ) : (
      <Empty />
    );
  };

  render() {
    const { t } = this.props;

    const fulfilmentQuery = this.formatQueryData();
    return (
      <Card style={{ width: '100%', marginBottom: '32px' }}>
        <Row style={{ padding: '16px' }}>
          <Col span={24}>
            <Row type="flex" justify="space-between" style={{ alignItems: 'center', marginBottom: '24px' }}>
              <Col>
                <Text strong style={{ fontSize: '20px', paddingRight: '8px' }}>
                  {t('Fulfilment Rate Trend')}
                </Text>
                <ToolTipWithInfo infoText={t('fulfilmentRateTooltip')} color={colors.blue} />
              </Col>
            </Row>
            <CubeJsQueryRenderer
              measures={fulfilmentQuery.measures}
              timeDimensions={fulfilmentQuery.timeDimensions}
              filters={fulfilmentQuery.filters}
              chartFunction={this.lineRender}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}

export default withTranslation()(FulfilmentTrendChart);
