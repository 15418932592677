import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import i18n from '../../i18n';
import { GRAPHQL_ENDPOINT } from '../../config';
import { localStorageKeys } from '../../constants';

const withQueryAndGraphQLClient = Component => props => {
  const graphqlEndpoint = `${GRAPHQL_ENDPOINT}/?manager=${localStorage.getItem(localStorageKeys.MANAGER_ID)}`;
  const graphQLClient = new GraphQLClient(graphqlEndpoint, {
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
      'Accept-Language': i18n.language,
    },
  });
  const queryClient = useQueryClient();

  return <Component queryClient={queryClient} graphQLClient={graphQLClient} {...props} />;
};

export default withQueryAndGraphQLClient;
