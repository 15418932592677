import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import numeral from 'numeral';
import { v4 as uuidv4 } from 'uuid';
import { employmentTypes, TIME_FORMAT_24_HOUR, wageTypes } from '../../../../constants';
import { colors } from '../../../../styles/colors';
import routes from '../../../../routes';
import previewIcon from '../../../../assets/images/preview-outline.svg';
import dateUtils from '../../../../utilities/dateUtils';
import textUtils from '../../../../utilities/textUtils';

const { Text } = Typography;

const ReviewGigDetails = ({ fields, selectedDates = [], currencyCode, isFreelanceContract, isOnWebView }) => {
  const [showFullDetail, setShowFullDetail] = useState(false);

  const { t } = useTranslation();

  const shiftStartTime = moment(fields.start_time).format(TIME_FORMAT_24_HOUR);
  const shiftEndTime = moment(fields.end_time).format(TIME_FORMAT_24_HOUR);
  const shiftTime = `${shiftStartTime} - ${shiftEndTime}`;

  const formatEmploymentType = employmentType => {
    return t(employmentTypes[employmentType.toUpperCase()]?.label);
  };

  const formatWageType = wageType => {
    return t(wageTypes[wageType.toUpperCase()]?.label?.toLowerCase());
  };

  const newlineToLineBreak = text => {
    return typeof text === 'string'
      ? text.split('\n').map(item => {
          return (
            <Fragment key={uuidv4()}>
              {item}
              <br />
            </Fragment>
          );
        })
      : '';
  };

  const getGigDetailsReviewData = () => {
    const formartedDates = dateUtils.sortDatesByEarliest(
      selectedDates.map(date => moment(date).format('ddd, D MMM YYYY')),
    );
    let rows = [
      { label: t('jobTitle'), value: fields?.template?.title },
      { label: t('Job position'), value: fields?.template?.position?.name },
      { label: t('gigLocation'), value: fields?.location?.name },
      { label: isFreelanceContract ? t('freelancersNeeded') : t('staffNeeded'), value: fields.staff_required },
      {
        label: t('dateAndTime'),
        value: shiftTime,
        children: formartedDates,
      },
      {
        label: t('totalBreakDuration'),
        value: fields.break_duration_in_minutes ? `${fields.break_duration_in_minutes} ${t('minutes')}` : t('none'),
      },
      { label: t('paidBreak'), value: fields.unpaid_break ? t('No') : t('Yes') },
      {
        label: t('applicants'),
        value: fields?.vip_workers_only ? t('vipWorkersOnly') : t('openToAnyone'),
      },
    ];

    if (showFullDetail) {
      rows = [
        ...rows,
        { label: t('responsibilities'), value: newlineToLineBreak(fields?.template?.responsibilities) },
        { label: t('requirementsQualifications'), value: newlineToLineBreak(fields?.template?.requirements) },
        {
          label: t('relevantWorkExperience'),
          value: fields?.template?.require_experience ? t('needed') : t('notNeeded'),
        },
      ];
    }
    return rows;
  };

  const getEmploymentDetailsReviewData = () => {
    const employmentType = formatEmploymentType(fields?.template?.employment_type);
    const employmentDetailsReviewData = [
      {
        label: t(isFreelanceContract ? 'engagementType' : 'employmentType'),
        value: employmentType,
      },
      {
        label: t(isFreelanceContract ? 'paymentStructure' : 'wageType'),
        value: formatWageType(fields?.template?.wage_type),
      },
      {
        label: t(isFreelanceContract ? 'freelanceFee' : 'wageAmount'),
        value: `${currencyCode} ${numeral(fields.wage_amount).format('0,0.00')}`,
      },
    ];
    const allowances = [
      { label: t('Transport allowance'), value: fields?.template?.transport_allowance || 0 },
      { label: t('Meal allowance'), value: fields?.template?.meal_allowance || 0 },
      { label: t('Other allowance'), value: fields?.template?.other_allowance || 0 },
    ];
    if (allowances.some(item => item.value > 0)) {
      employmentDetailsReviewData.push({
        label: t('Allowances'),
        value: (
          <Col>
            {allowances.map(item => (
              <Row key={item.label}>
                <Text>{`${currencyCode} ${numeral(item.value).format('0,0.00')} (${item.label})`}</Text>
              </Row>
            ))}
          </Col>
        ),
      });
    }
    if (fields?.template?.payment_frequency) {
      employmentDetailsReviewData.push({
        label: t('Payment frequency'),
        value: t(fields?.template?.payment_frequency),
      });
    }
    if (fields?.template?.benefits) {
      employmentDetailsReviewData.push({
        label: t('Benefits'),
        value: <Text>{newlineToLineBreak(fields?.template?.benefits)}</Text>,
      });
    }
    employmentDetailsReviewData.push({
      label: t('clockInMethodTitle'),
      value: (
        <Text>
          {newlineToLineBreak(
            fields?.template?.qr_override_allowed ? t('clockinMethodQrScanWithSelfie') : t('clockinMethodQrScanOnly'),
          )}
        </Text>
      ),
    });
    return employmentDetailsReviewData;
  };

  return (
    <>
      <Row style={{ marginBottom: '16px', marginTop: '24px' }}>
        <Text strong style={{ fontSize: '16px' }}>
          {t('gigDetails')}
        </Text>
      </Row>
      {getGigDetailsReviewData().map(field => (
        <Row key={field.label} style={{ marginBottom: '16px' }}>
          <Col xs={24} sm={24} md={8}>
            <Text type="secondary">{field.label}</Text>
          </Col>
          <Col xs={24} sm={24} md={16}>
            <Text style={{ color: colors.primaryText }}>{field.value}</Text>
            <ul style={{ paddingLeft: 20 }}>
              {field?.children?.map(child => (
                <li key={child}>
                  <Text style={{ color: colors.primaryText }}>{child}</Text>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      ))}

      {showFullDetail && (
        <>
          <Row style={{ marginBottom: '16px', marginTop: '24px' }}>
            <Text strong style={{ fontSize: '16px' }}>
              {textUtils.toSentence(t(isFreelanceContract ? 'shiftDetails' : 'employmentDetails'))}
            </Text>
          </Row>
          {getEmploymentDetailsReviewData().map(field => (
            <Row key={field.label} style={{ marginBottom: '16px' }}>
              <Col xs={24} sm={24} md={8}>
                <Text type="secondary">{field.label}</Text>
              </Col>
              <Col xs={24} sm={24} md={16}>
                <Row>{field.value}</Row>
                {field.subtext && <Row>{field.subtext}</Row>}
              </Col>
            </Row>
          ))}

          {fields?.template?.form_id && (
            <>
              <Row style={{ marginBottom: '16px', marginTop: '24px' }}>
                <Text strong style={{ fontSize: '16px' }}>
                  {textUtils.toSentence(t('applicationForm'))}
                </Text>
              </Row>
              <Row key={t('applicationForm')} style={{ marginBottom: '16px' }}>
                <Col xs={24} sm={24} md={8}>
                  <Text type="secondary">{t('formName')}</Text>
                </Col>

                <Col xs={24} sm={24} md={16}>
                  <Text>
                    {fields?.template?.form_title}
                    {!isOnWebView && (
                      <a
                        target="_blank"
                        href={routes.formPreview.replace(':id', fields?.template?.form_id)}
                        style={{ color: colors.functionalLink, marginLeft: 6 }}
                        rel="noreferrer"
                      >
                        {t('preview')}
                        <img src={previewIcon} alt="preview" style={{ marginLeft: 6, width: 16, height: 16 }} />
                      </a>
                    )}
                  </Text>
                </Col>
              </Row>
            </>
          )}

          {fields?.template?.first_day_message && (
            <>
              <Row style={{ marginBottom: '16px', marginTop: '24px' }}>
                <Text strong style={{ fontSize: '16px' }}>
                  {textUtils.toSentence(t('firstDayMessage'))}
                </Text>
              </Row>
              <Row style={{ marginBottom: '16px' }}>
                <Col xs={24} sm={24} md={8}>
                  <Text type="secondary">{t('messageTitle')}</Text>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <Text>{t('firstDayMessageDefaultTitle')}</Text>
                </Col>
              </Row>
              <Row style={{ marginBottom: '16px' }}>
                <Col xs={24} sm={24} md={8}>
                  <Text type="secondary">{t('messageContent')}</Text>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <Text>{newlineToLineBreak(fields?.template?.first_day_message)}</Text>
                </Col>
              </Row>
            </>
          )}
        </>
      )}

      <Button type="link" style={{ padding: 0 }} onClick={() => setShowFullDetail(!showFullDetail)}>
        {showFullDetail ? t('showLessDetail') : t('showFullDetail')}
      </Button>
    </>
  );
};

const mapStateToProps = state => {
  return {
    isOnWebView: state.global.isOnWebView,
  };
};

export default connect(mapStateToProps)(ReviewGigDetails);
