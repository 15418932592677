import React, { createContext, useContext, useMemo } from 'react';

export const UTMContext = createContext();

export const useUTM = () => {
  const context = useContext(UTMContext);
  if (context === undefined) {
    throw new Error('useUTM must be used within a UTMProvider');
  }
  return context;
};

export const UTMProvider = ({ children }) => {
  const params = new URLSearchParams(window.location.search);

  const utmParameters = useMemo(
    () => ({
      source: params.get('utm_source'),
      medium: params.get('utm_medium'),
      campaign: params.get('utm_campaign'),
    }),
    [],
  );

  return <UTMContext.Provider value={utmParameters}>{children}</UTMContext.Provider>;
};
