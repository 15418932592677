import React, { useState } from 'react';
import { Row, Col, Button, Tabs, Typography, Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { clientTypes, jobTypes, queryKeys, userStatuses } from '../../constants';
import StaffRequestListView from './StaffRequestListView';
import StaffRequestGigListView from './StaffRequestGigListView';
import SelectJobTypeModal from './components/SelectJobTypeModal';
import routes from '../../routes';
import { retrieveParamsFromUrl } from '../../utilities/urlUtils';
import { colors } from '../../styles/colors';
import UnpaidOnlinePaymentModal from './components/UnpaidOnlinePaymentModal';
import billingApi from '../../services/billingApi';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const StaffRequestsView = props => {
  const { user, history, isOnWebView } = props;
  const { location } = history;
  const { data } = useQuery(queryKeys.UNPAID_ONLINE_PAYMENT_INVOICE, () =>
    billingApi.unbilledExist({
      client: user.clientId,
    }),
  );
  const hasUnpaidOnlinePayment = data?.has_unbilled_online_payments || false;
  const [showJobTypeModal, setShowJobTypeModal] = useState(false);
  const [showUnpaidOnlinePaymentModal, setShowUnpaidOnlinePaymentModal] = useState(false);

  const { t } = useTranslation();

  const handleTabChange = activeKey => {
    const route =
      activeKey === jobTypes.REGULAR_JOBS ? routes.staffRequests : `${routes.staffRequests}?tab=${jobTypes.GIGS}`;
    history.push(route);
  };

  const { tab } = retrieveParamsFromUrl(location.search);
  const activeTab = tab || jobTypes.REGULAR_JOBS;
  // TODO remove this line after SG office is closed
  const isCreateSrDisabled = user.status === userStatuses.LEAD || user.country.id === 3;

  const { md, xs } = useBreakpoint();

  if (user.type === clientTypes.SELF_SERVE || isOnWebView) {
    return (
      <>
        {!isOnWebView && (
          <Row type="flex" justify="space-between" style={{ marginTop: xs ? -54 : 0 }}>
            <Col xs={24} md={8} lg={5}>
              <Title
                level={2}
                style={{
                  color: activeTab === jobTypes.GIGS ? colors.functionalLink : colors.primaryText,
                  marginBottom: 0,
                }}
              >
                {t('gigs')}
              </Title>
            </Col>
            <Col>
              {md && (
                <Button
                  disabled={isCreateSrDisabled}
                  type="v2-primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    if (hasUnpaidOnlinePayment) {
                      setShowUnpaidOnlinePaymentModal(true);
                    } else {
                      history.push(routes.createGig);
                    }
                  }}
                >
                  {t('postAGig')}
                </Button>
              )}
            </Col>
          </Row>
        )}

        <section style={{ marginBottom: 32 }}>
          <StaffRequestGigListView {...props} />
        </section>

        {/* Floating button for showing job post button on mobile */}
        {xs && (
          <div style={{ position: 'fixed', bottom: 46, zIndex: 2, right: 16 }}>
            <Button
              type="v2-primary"
              shape="circle"
              icon={<PlusOutlined />}
              size="large"
              onClick={() => {
                history.push(routes.createGig);
              }}
            />
          </div>
        )}

        {showUnpaidOnlinePaymentModal && (
          <UnpaidOnlinePaymentModal
            visible={showUnpaidOnlinePaymentModal}
            onCancel={() => setShowUnpaidOnlinePaymentModal(false)}
            email={user.billingContactEmail}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Row type="flex" justify="space-between" style={{ marginTop: xs ? -64 : 0 }}>
        <Col xs={24} md={8} lg={5}>
          <Tabs defaultActiveKey={activeTab} onChange={handleTabChange} centered={xs} tabBarStyle={{ marginBottom: 0 }}>
            <Tabs.TabPane
              tab={
                <Title
                  level={4}
                  style={{
                    color: activeTab === jobTypes.REGULAR_JOBS ? colors.functionalLink : colors.primaryText,
                    marginBottom: 0,
                  }}
                >
                  {t('regularJobs')}
                </Title>
              }
              key={jobTypes.REGULAR_JOBS}
            />
            <Tabs.TabPane
              tab={
                <Title
                  level={4}
                  style={{
                    color: activeTab === jobTypes.GIGS ? colors.functionalLink : colors.primaryText,
                    marginBottom: 0,
                  }}
                >
                  {t('gigs')}
                </Title>
              }
              key={jobTypes.GIGS}
            />
          </Tabs>
        </Col>
        <Col>
          {md && (
            <Button
              disabled={isCreateSrDisabled}
              type="v2-primary"
              icon={<PlusOutlined />}
              onClick={() => setShowJobTypeModal(true)}
            >
              {t('Request workers')}
            </Button>
          )}
        </Col>
      </Row>

      {activeTab === jobTypes.GIGS ? (
        <section style={{ marginBottom: 32 }}>
          <StaffRequestGigListView {...props} onPostJobClick={() => setShowJobTypeModal(true)} />
        </section>
      ) : (
        <section style={{ marginTop: 32 }}>
          <StaffRequestListView {...props} onPostJobClick={() => setShowJobTypeModal(true)} />
        </section>
      )}

      {/* Floating button for showing job post types modal on mobile */}
      {xs && (
        <div style={{ position: 'fixed', bottom: 40, zIndex: 2, right: 16 }}>
          <Button
            type="v2-primary"
            shape="circle"
            icon={<PlusOutlined />}
            size="large"
            onClick={() => setShowJobTypeModal(true)}
          />
        </div>
      )}
      {/* Modals */}
      {showJobTypeModal && <SelectJobTypeModal visible history={history} onCancel={() => setShowJobTypeModal(false)} />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    isOnWebView: state.global.isOnWebView,
  };
};

export default connect(mapStateToProps)(StaffRequestsView);
