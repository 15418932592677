import React from 'react';
import { connect } from 'react-redux';
import { Row, Typography, Col, Card } from 'antd';
import ToolTipWithInfo from '../../../shared/components/ToolTipWithInfo';
import CubeJsQueryRenderer from '../../../shared/components/CubeJsQueryRenderer';
import PercentageDiffIcon from '../../../shared/components/PercentageDiffIcon';
import { colors } from '../../../styles/colors';
import { getNumberAbbreviation, calculateComparisonTime } from '../../../utilities/analyticsUtils';
import { MONTHLY_DATE_RANGE } from '../../../constants';

const { Text } = Typography;

const ComparisonCard = ({
  title,
  tooltipText,
  filterMeasure,
  filterDimension,
  timePeriod,
  clientId,
  positionIds,
  locationIds,
  currency,
}) => {
  const formatQueryData = () => {
    const query = {
      filters: [
        {
          member: 'ClientsClient.id',
          operator: 'equals',
          values: [clientId.toString()],
        },
      ],
      timeDimensions: [
        {
          dimension: filterDimension,
          compareDateRange: [timePeriod, calculateComparisonTime(timePeriod)],
        },
      ],
    };
    if (positionIds && positionIds.length > 0) {
      query.filters.push({
        member: 'PartnersPosition.id',
        operator: 'equals',
        values: positionIds,
      });
    }
    if (locationIds && locationIds.length > 0) {
      query.filters.push({
        member: 'ClientsLocation.id',
        operator: 'equals',
        values: locationIds,
      });
    }
    return query;
  };

  const numberRender = ({ resultSet }) => {
    let percentageDiff;
    const selectedTimePeriod = resultSet.loadResponses[0].data && Object.values(resultSet.loadResponses[0].data[0])[0];
    const comparedTimePeriod = resultSet.loadResponses[1].data && Object.values(resultSet.loadResponses[1].data[0])[0];

    if (comparedTimePeriod > 0) {
      percentageDiff = Math.round(((selectedTimePeriod - comparedTimePeriod) / comparedTimePeriod) * 100);
    } else if (comparedTimePeriod === 0 && selectedTimePeriod === 0) {
      percentageDiff = 0;
    } else {
      percentageDiff = 100;
    }

    const selectedTimePeriodAbbreviated = getNumberAbbreviation(selectedTimePeriod);
    return (
      <Card style={{ width: '100%' }} bodyStyle={{ margin: '24px 16px', padding: 0 }}>
        <Row justify="center">
          <Col>
            <Text strong style={{ fontSize: '38px' }}>
              {selectedTimePeriodAbbreviated}
            </Text>
            <Text strong style={{ fontSize: '16px' }}>
              {' '}
              {currency}
            </Text>
          </Col>
        </Row>
        <Row style={{ marginBottom: '8px', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
          <Text strong type="secondary" style={{ fontSize: '12px', paddingRight: '8px' }}>
            {title.toUpperCase()}
          </Text>
          <ToolTipWithInfo infoText={tooltipText} color={colors.blue} />
        </Row>
        <Row justify="center">
          <PercentageDiffIcon percentageDiff={percentageDiff} lastTimePeriod={MONTHLY_DATE_RANGE} />
        </Row>
      </Card>
    );
  };

  const formattedQuery = formatQueryData();
  return (
    <CubeJsQueryRenderer
      measures={[filterMeasure]}
      timeDimensions={formattedQuery.timeDimensions}
      filters={formattedQuery.filters}
      chartFunction={numberRender}
    />
  );
};

const mapStateToProps = state => ({
  currency: state.user.country.currency_code,
});

export default connect(mapStateToProps)(ComparisonCard);
