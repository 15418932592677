import React from 'react';
import { Modal, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Trans } from 'react-i18next';
import { colors } from '../../../styles/colors';

const approveAllAttendances = ({ t, timesheetsToApproveCount, handleApproveAll, isApproveByDays, daysCount }) => {
  Modal.confirm({
    title: t('approveTimesheetsModalTitle'),
    icon: <QuestionCircleOutlined style={{ color: colors.functionalLink }} />,
    content: (
      <>
        {isApproveByDays ? (
          <Typography.Text>
            <Trans i18nKey="approveTimesheetsDescription" values={{ timesheetsToApproveCount, daysCount }} />
          </Typography.Text>
        ) : (
          <Typography.Text>
            {t('You are about to approve all')} <span style={{ fontWeight: 'bold' }}>{timesheetsToApproveCount}</span>{' '}
            {t('timesheetEntries')}
          </Typography.Text>
        )}
      </>
    ),
    okText: t('approve'),
    okButtonProps: {
      style: { fontWeight: 'bold', backgroundColor: colors.workmateGreen, borderColor: colors.workmateGreen },
    },
    onOk: async () => {
      handleApproveAll();
    },
    cancelText: t('cancel'),
    onCancel: () => {},
  });
};

export default approveAllAttendances;
