import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Row, Col, Typography, InputNumber, message } from 'antd';
import clientApi from '../../services/clientApi';
import { colors } from '../../styles/colors';

const { Paragraph, Title, Text } = Typography;
class LegalSection extends React.Component {
  state = {
    isLoading: false,
  };

  handleSubmit = async e => {
    e.preventDefault();
    const {
      client,
      fetchClientAndManager,
      t,
      form: { validateFieldsAndScroll },
    } = this.props;
    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        this.setState({ isLoading: true });
        try {
          await clientApi.editClient({
            id: client.clientId,
            legal_break_duration: values.legalBreakDuration,
          });
          message.success(t('Changes saved'));
          await fetchClientAndManager();
        } catch (error) {
          message.warn(t('Something went wrong. No changes were saved'));
        } finally {
          this.setState({ isLoading: false });
        }
      }
    });
  };

  render() {
    const { isLoading } = this.state;
    const {
      isAdmin,
      t,
      form: { getFieldDecorator, setFieldsValue, getFieldValue },
    } = this.props;

    return (
      <>
        <Row style={{ marginBottom: '48px' }}>
          <Col xs={24} md={18}>
            <Title level={3} style={{ marginTop: '14px', marginBottom: '22px' }}>
              {t('Legal compliance (PKHL)')}
            </Title>
            <Paragraph>
              {t(
                `In compliance with Indonesia’s employment laws, all workers are registered under PKHL. As such, workers can work a maximum of 90 days per contract. Workers can renew their contract with you after a break period.`,
              )}
            </Paragraph>
            <Paragraph style={{ marginBottom: '24px' }}>
              {t(
                `By setting the number of break days you want your workers to have, we can help you generate renewed contracts with the right dates, and find replacement workers during the break periods.`,
              )}
            </Paragraph>
            <Form hideRequiredMark>
              <Row style={{ marginBottom: '48px' }}>
                <Form.Item
                  colon={false}
                  label={t('Number of break days')}
                  style={{ marginBottom: '16px' }}
                  extra={
                    // Warning for 0 break day is not in validator because it should not be regarded as field error
                    getFieldValue('legalBreakDuration') === 0 && (
                      <span style={{ color: colors.red }}>
                        {t(
                          'We note you have selected 0 days, as per PKHL regulation you should have at least 1 day. You acknowledge that choosing 0 days is at your discretion',
                        )}
                      </span>
                    )
                  }
                >
                  {getFieldDecorator('legalBreakDuration', {
                    initialValue: this.props.client.legalBreakDuration,
                    rules: [{ required: true, message: t('This field is required.') }],
                  })(
                    <InputNumber
                      min={0}
                      disabled={!isAdmin}
                      onChange={value => setFieldsValue({ legalBreakDuration: value })}
                      style={{ width: '100%' }}
                    />,
                  )}
                </Form.Item>
                <Text type="secondary" style={{ fontSize: '12px' }}>
                  {t(
                    `By setting the break period, you acknowledge that this is in accordance with your company’s applicable HR regulations.`,
                  )}
                </Text>
              </Row>
              <Form.Item>
                <Button
                  type="v2-primary"
                  htmlType="submit"
                  disabled={!isAdmin}
                  loading={isLoading}
                  onClick={this.handleSubmit}
                  style={{ width: '120px' }}
                >
                  {t('Save')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

export default Form.create()(LegalSection);
