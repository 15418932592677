import React, { Component } from 'react';
import { Card, message, Row, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { debounce, intersectionWith, isEqual } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';

import * as chatActions from '../../../redux/chat';
import { getChatListRoute } from '../routes';
import Header from './Header';
import Footer from './Footer';
import Context from '../context';
import ChatInfo from './ChatInfo';
import ChatMessageList from './ChatMessageList';
import ChatReplyBox from './ChatReplyBox';
import ChatLoadingErrorPrompt from './ChatLoadingErrorPrompt';
import channelApi from '../../../services/channelApi';
import fetchChatChannel from '../../../utilities/chatUtils';
import { PAGE_SIZE, twilioConnectionStates } from '../constants';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import { colors } from '../../../styles/colors';
import { errorCodes, channelStatuses, httpResponseStatuses } from '../../../constants';

const { Grid } = Card;
const { Text } = Typography;

class ChatDetailView extends Component {
  messagePaginator = null;

  twilioChatChannel = null;

  state = {
    selectedChannel: undefined,
    hasMoreMessages: false,
    messages: [],
    isLoading: false,
    messagesReadCounts: undefined,
    chatRepliesEnabled: false,
    unsentMessage: undefined,
    channelId: null,
  };

  async componentDidMount() {
    await this.fetchChatChannel();
  }

  componentWillUnmount() {
    this.twilioChatChannel?.off('messageAdded', this.messageAdded);
    this.twilioChatChannel?.off('participantUpdated', this.participantUpdated);
    this.twilioChatChannel?.off('updated', this.channelUpdated);
  }

  fetchChatChannel = async () => {
    const { location, match, t } = this.props;
    const { twilioChatClient } = this.context;
    const { createChannel, channelAttributes, notificationMessage } = location.state;
    let { channelId } = match.params;

    this.setState({ isLoading: true });

    try {
      let { channel } = location.state;
      // Create the channel, if needed
      if (createChannel && channelAttributes) {
        channel = await channelApi.createChannel(channelAttributes);
        channelId = channel.id;
        if (notificationMessage) {
          channelApi.notifyChannel(channel.id, {
            message: notificationMessage,
          });
        }
      }

      // Initialize the channel
      this.setState({ channelId }, async () => {
        try {
          const response = await fetchChatChannel(twilioChatClient, channelId, channel);
          this.fetchChatChannelOnSuccess(...response);
        } catch (error) {
          this.fetchChatChannelOnFail(error);
        }
      });
    } catch (error) {
      Sentry.captureException(error);

      let errorMessage = t('submitFailed');
      if (
        error.response?.status === httpResponseStatuses.BAD_REQUEST_400 &&
        [errorCodes.CHANNEL_NAME_EMPTY, errorCodes.CHANNEL_DOES_NOT_HAVE_MEMBERS].includes(error.response.data.code)
      ) {
        errorMessage = error.response.data.code;
      }
      message.error(errorMessage);
    }
  };

  fetchChatChannelOnSuccess = (selectedChannel, twilioChatChannel) => {
    const { totalUnreadCount, setTotalUnreadCount } = this.props;

    this.updateMemberLastReadMessageIndex(selectedChannel.id, twilioChatChannel.lastReadMessageIndex);
    if (selectedChannel.unread_count > 0 && totalUnreadCount > 0) {
      setTotalUnreadCount(totalUnreadCount - 1);
    }

    let chatRepliesEnabled = false;
    if (twilioChatChannel?.channelState?.attributes?.enable_reply && selectedChannel?.enable_reply) {
      chatRepliesEnabled = true;
    }

    this.twilioChatChannel = twilioChatChannel;
    this.setState(
      {
        selectedChannel,
        chatRepliesEnabled,
      },
      async () => {
        this.twilioChatChannel?.on('messageAdded', this.messageAdded);
        this.twilioChatChannel?.on('participantUpdated', this.participantUpdated);
        this.twilioChatChannel?.on('updated', this.channelUpdated);
        await this.loadMessages();
      },
    );
  };

  fetchChatChannelOnFail = error => {
    const { t } = this.props;
    this.setState({ isLoading: false }, () => Sentry.captureException(error));
    message.warning(t('An unexpected error occurred'));
  };

  loadMessages = async () => {
    if (!this.twilioChatChannel) {
      return;
    }

    try {
      this.messagePaginator = await this.twilioChatChannel.getMessages(PAGE_SIZE);
    } catch (error) {
      Sentry.captureException(error);
      this.setState({ messages: [] });
    } finally {
      this.setState({ isLoading: false }, () => {
        this.processMessages(this.messagePaginator, true);
      });
    }
  };

  mapMessagesToUser = attributes => {
    const { selectedChannel } = this.state;
    const member = selectedChannel?.members.find(memberItem => memberItem.id === attributes.user_id);
    let memberData;
    if (!member || member?.manager?.id) {
      memberData = { userType: 'manager' };
    }
    if (member && !member.manager?.id && member.partner?.id) {
      memberData = {
        userType: 'worker',
        data: { name: `${member.partner.first_name} ${member.partner.last_name}`, image: member.partner.image },
      };
    }
    return memberData;
  };

  getMessageSeenCounts = async () => {
    const { selectedChannel } = this.state;
    try {
      const [response, numMessages] = await Promise.all([
        channelApi.fetchMembers(selectedChannel.id),
        this.twilioChatChannel.getMessagesCount(),
      ]);
      const members = response.filter(member => member.partner);
      // messagesReadMembersInfo is 2D array. i.E [ [member, member], [], [member], [], [], []]
      const messagesReadMembersInfo = Array.from({ length: numMessages }, () => []);
      const messagesReadCounts = Array.from({ length: numMessages }, () => 0);
      members.forEach(member => {
        const { last_consumed_message_index, id } = member;
        if (last_consumed_message_index >= 0) {
          messagesReadMembersInfo[last_consumed_message_index].push({ id });
        }
      });
      // Use info of messagesReadMemberInfo to messagesReadCount
      // [ [member, member], [],  [member], [], [], []]
      // Becomes
      // [ 3, 1, 1, 0, 0, 0]
      let readCount = 0;
      for (let i = numMessages - 1; i >= 0; i -= 1) {
        readCount += messagesReadMembersInfo[i].length;
        // remove the sender from the seen count
        messagesReadCounts[i] = readCount;
      }
      this.setState({ messagesReadCounts });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  processMessages = async (messagePaginator, scrollToBottom = false) => {
    const { messages } = this.state;
    const newMessages = [];
    for (let i = 0; i < messagePaginator?.items.length; i += 1) {
      const messageInChat = messagePaginator?.items[i];
      const { author, body, type, timestamp, index, attributes } = messageInChat.state;
      // Normal plain text
      if (type !== 'media') {
        const memberData = this.mapMessagesToUser(attributes);
        newMessages.push({ author, body, timestamp, index, memberData });
        // eslint-disable-next-line no-continue
        continue;
      }
      // eslint-disable-next-line no-await-in-loop
      const imageUrl = await messageInChat.state.media.getContentTemporaryUrl();
      const memberData = this.mapMessagesToUser(attributes);
      newMessages.push({ author, body, timestamp, imageUrl, index, memberData });
    }
    this.setState(
      { hasMoreMessages: messagePaginator?.hasPrevPage, messages: [...newMessages, ...messages] },
      async () => {
        await this.twilioChatChannel.setAllMessagesRead();
        if (scrollToBottom) {
          this.scrollChatToBottom();
        }
      },
    );
    this.getMessageSeenCounts();
  };

  // eslint-disable-next-line react/sort-comp
  handleLoadMoreMessages = debounce(async () => {
    if (this.messagePaginator.hasPrevPage) {
      const paginator = await this.messagePaginator.prevPage();
      this.messagePaginator = paginator;
      this.processMessages(this.messagePaginator);
    }
  }, 600);

  scrollChatToBottom = () => {
    const chatBox = window.document.getElementById('chat-box');
    if (chatBox) {
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  };

  setChannelLastMessage = async (index, userId, lastMessage, timestamp) => {
    const { channelId } = this.state;
    if (channelId) {
      const { user, t } = this.props;
      const lastMessageContents =
        (lastMessage && `${lastMessage.trim().slice(0, 30)}`) || `${user?.name} ${t('sentAnImage')}`;
      await channelApi.patchChannel(channelId, {
        last_message_index: index,
        last_message_author: userId,
        last_message: lastMessageContents,
        last_message_timestamp: timestamp,
      });
    }
  };

  notifyChannelParticipants = async lastMessage => {
    const { channelId } = this.state;
    if (channelId) {
      const { user, t } = this.props;
      const notificationMessage = (lastMessage && lastMessage) || `${user?.name} ${t('sentAnImage')}`;
      await channelApi.notifyChannel(channelId, {
        message: notificationMessage,
      });
    }
  };

  // Append the new message into an array of messages & scroll to bottom
  messageAdded = async event => {
    const { author, body, attributes, timestamp, type, media, index } = event.state;
    // Some event might be an image. Fetch the URL if it's an image.
    let newMessage;
    const memberData = this.mapMessagesToUser(attributes);
    if (type !== 'media') {
      newMessage = { author, body, timestamp, index, memberData };
    } else {
      const imageUrl = await media.getContentTemporaryUrl();
      newMessage = { author, body, timestamp, imageUrl, index, memberData };
    }

    this.setState(
      state => {
        const messages = state.messages.concat(newMessage);
        return { messages, isLoading: false };
      },
      async () => {
        this.scrollChatToBottom();
        this.getMessageSeenCounts();
        const promises = [
          this.twilioChatChannel.setAllMessagesRead(),
          this.setChannelLastMessage(index, attributes.user_id, body, timestamp),
        ];
        // Ensure that we only send a notification if the admin manager is the one who sent the message.
        // This condition is added to prevent workers from being notified by their own message.
        if (attributes.user_id === this.props.user.userId) {
          promises.push(this.notifyChannelParticipants(body));
        }
        await Promise.all(promises);
      },
    );
  };

  participantUpdated = async event => {
    const { channelId } = this.state;
    if (channelId) {
      if (intersectionWith(['lastReadMessageIndex', 'lastReadTimestamp'], event.updateReasons, isEqual).length > 0) {
        this.updateMemberLastReadMessageIndex(channelId, event.participant.lastReadMessageIndex);
        this.getMessageSeenCounts();
      }
    }
  };

  channelUpdated = event => {
    const { selectedChannel } = this.state;
    if (event.updateReasons.includes('attributes') && event.conversation.sid === selectedChannel?.sid) {
      if (event.conversation.attributes?.status === channelStatuses.ARCHIVED) {
        this.setState({
          selectedChannel: {
            ...selectedChannel,
            enable_reply: false,
            status: channelStatuses.ARCHIVED,
          },
          chatRepliesEnabled: false,
        });
      }
    }
  };

  updateMemberLastReadMessageIndex = async (channelId, lastReadMessageIndex) => {
    const index = +lastReadMessageIndex;
    if (index > -1) {
      await channelApi.updateMemberAttributes(channelId, {
        last_consumed_message_index: index,
      });
    }
  };

  // Placed here to avoid eslint error when placed at top
  static contextType = Context;

  render() {
    const { twilioChatClient } = this.context;
    const { location, match, t } = this.props;
    const { channelId } = match.params;
    const { channel } = location.state;
    const {
      selectedChannel,
      hasMoreMessages,
      messages,
      isLoading,
      messagesReadCounts,
      chatRepliesEnabled,
      unsentMessage,
    } = this.state;

    const chatReadyToUse =
      this.context.twilioConnectionState === twilioConnectionStates.CONNECTED &&
      this.twilioChatChannel &&
      selectedChannel;

    const chatListParams = location?.state?.chatListParams || {};
    const chatListRoute = getChatListRoute(chatListParams.status);

    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Header>
          <Link
            to={{
              pathname: chatListRoute,
              state: {
                ...location?.state,
              },
            }}
          >
            <ArrowLeftOutlined style={{ fontSize: '14px', marginRight: '10px' }} />
            <Text style={{ color: colors.functionalLink, fontSize: '14px' }}>{t('backToChats')}</Text>
          </Link>
        </Header>
        {isLoading ? (
          <LoadingSpinner width="50px" />
        ) : (
          <>
            <Card
              style={{
                flex: 1,
                overflow: 'hidden',
                width: '100%',
              }}
              bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%', padding: 0 }}
              bordered={false}
            >
              {chatReadyToUse ? (
                <>
                  <ChatInfo
                    twilioChatChannel={this.twilioChatChannel}
                    selectedChannel={selectedChannel}
                    hoverable={false}
                    style={{ width: '100%', padding: '16px' }}
                  />
                  <Grid
                    id="chat-box"
                    hoverable={false}
                    style={{
                      width: '100%',
                      overflowY: 'auto',
                      padding: 0,
                    }}
                  >
                    <InfiniteScroll
                      hasMore={hasMoreMessages}
                      initialLoad={false}
                      isReverse
                      loadMore={this.handleLoadMoreMessages}
                      useWindow={false}
                      style={{
                        padding: '0 24px',
                      }}
                    >
                      <ChatMessageList
                        twilioChatChannel={this.twilioChatChannel}
                        selectedChannel={selectedChannel}
                        messages={messages}
                        messagesReadCounts={messagesReadCounts}
                      />
                    </InfiniteScroll>
                  </Grid>
                </>
              ) : (
                <div style={{ height: '100%', display: 'flex', alignItems: 'center', padding: '16px' }}>
                  <div>
                    <ChatLoadingErrorPrompt
                      additionalFunctions={async () => {
                        try {
                          const response = await fetchChatChannel(twilioChatClient, channelId, channel);
                          this.fetchChatChannelOnSuccess(...response);
                        } catch (error) {
                          this.fetchChatChannelOnFail(error);
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </Card>
            {chatReadyToUse && (
              <Row
                justify="center"
                align="bottom"
                style={{
                  background: colors.white,
                  padding: '4px 0 8px 0',
                }}
              >
                <Text type="secondary" style={{ marginRight: '4px' }}>
                  {t('workerReplies')}
                </Text>
                {chatRepliesEnabled === true ? (
                  <Text style={{ color: colors.brandGreen }}>{t('enabled')}</Text>
                ) : (
                  <Text type="secondary">{t('disabled')}</Text>
                )}
              </Row>
            )}
          </>
        )}

        <Footer>
          <ChatReplyBox
            twilioChatChannel={this.twilioChatChannel}
            selectedChannel={selectedChannel}
            chatRepliesEnabled={chatRepliesEnabled}
            updateChatRepliesEnabled={state => this.setState({ chatRepliesEnabled: state })}
            updateUnsentMessage={state => this.setState({ unsentMessage: state })}
            unsentMessage={unsentMessage}
            chatReadyToUse={chatReadyToUse && ![channelStatuses.ARCHIVED].includes(selectedChannel.status)}
          />
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  totalUnreadCount: state.chat.totalUnreadCount,
});

const mapDispatchToProps = dispatch => {
  const { setTotalUnreadCount } = chatActions;
  return {
    setTotalUnreadCount: count => {
      dispatch(setTotalUnreadCount(count));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatDetailView));
