import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import ApplicationForms from '../../../containers/ApplicationForms';
import formUtils from '../../../utilities/formUtils';
import formsApi from '../../../services/formsApi';
import * as applicationFormActions from '../../../redux/applicationForm';
import { colors } from '../../../styles/colors';
import { questionStatuses } from '../../../constants';

const ImportFormModal = ({ selectForm, visible, onCancel, importSelectedQuestions, onImportSuccess }) => {
  const [selectedForm, setSelectedForm] = useState(null);
  const [isImporting, setIsImporting] = useState(false);

  const { t } = useTranslation();

  const handleSelectForm = form => {
    setSelectedForm(form);
  };

  const handleImport = async () => {
    try {
      setIsImporting(true);
      const results = await formsApi.importForm(selectedForm.id);
      const formattedQuestions = formUtils.formatQuestions(results);
      const activeQuestions = formattedQuestions.filter(question => question.status === questionStatuses.ACTIVE);
      const inactiveQuestions = formattedQuestions.filter(question => question.status === questionStatuses.INACTIVE);
      setIsImporting(false);
      importSelectedQuestions({ activeQuestions, inactiveQuestions });
      selectForm(selectedForm);
      onImportSuccess({ activeQuestions, inactiveQuestions });
      message.success(t('questionsImportedMessage', { count: activeQuestions.length }));
    } catch (error) {
      Sentry.captureException(error);
      message.warning(t('Sorry! An error occured in submission, please contact support'));
      setIsImporting(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={
        <Typography.Title style={{ marginBottom: 0 }} level={5}>
          {t('importExistingForm')}
        </Typography.Title>
      }
      width="965px"
      okText={t('import')}
      okButtonProps={{
        disabled: !selectedForm || isImporting,
        onClick: handleImport,
        loading: isImporting,
        style: {
          borderColor: !selectedForm ? colors.componentBorder : colors.functionalSuccess,
          backgroundColor: !selectedForm ? colors.componentBackground : colors.functionalSuccess,
          color: !selectedForm ? colors.disabled : colors.white,
        },
      }}
      cancelText={t('cancel')}
    >
      <div style={{ maxHeight: 300, overflowX: 'hidden', overflowY: 'auto' }}>
        <ApplicationForms
          hasHeaderButtons={false}
          rowSelection={{
            type: 'radio',
            onSelect: handleSelectForm,
          }}
        />
      </div>
    </Modal>
  );
};

const mapDispatchToProps = dispatch => {
  const { importSelectedQuestions, selectForm } = applicationFormActions;
  return {
    importSelectedQuestions: questions => {
      dispatch(importSelectedQuestions(questions));
    },
    selectForm: form => {
      dispatch(selectForm(form));
    },
  };
};

export default connect(undefined, mapDispatchToProps)(ImportFormModal);
