import React from 'react';
import { Empty, Row, Typography } from 'antd';
import { colors } from '../../../styles/colors';

const EmptyList = ({ children, description, style = {} }) => {
  return (
    <Row align="center" style={{ flexDirection: 'column', alignItems: 'center', ...style }}>
      <Empty
        style={{ margin: '6px 0' }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<Typography.Text style={{ color: colors.primaryText }}>{description}</Typography.Text>}
      />
      {children}
    </Row>
  );
};

export default EmptyList;
