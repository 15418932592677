import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { WarningOutlined } from '@ant-design/icons';
import { message, Modal, Typography } from 'antd';

import channelApi from '../../../services/channelApi';

const { Text, Paragraph } = Typography;

class ArchiveChatModal extends Component {
  open = (channelId, onArchive) => {
    const { t } = this.props;

    Modal.confirm({
      icon: <WarningOutlined />,
      content: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text strong style={{ marginBottom: '16px' }}>
            {t('archiveChatModalTitle')}
          </Text>
          <Paragraph>{t('archiveChatModalMessage')}</Paragraph>
        </div>
      ),
      okButtonProps: {
        danger: true,
      },
      okText: t('yesArchive'),
      okType: 'primary',
      onOk: async () => {
        await channelApi.archiveChannel(channelId);
        message.info(t('chatHasBeenArchived'));
        onArchive();
      },
      zIndex: 99999,
    });
  };

  render() {
    return <></>;
  }
}

export default withTranslation(null, { withRef: true })(ArchiveChatModal);
