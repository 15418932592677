import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { message, Typography, Row, Col, Modal, Avatar } from 'antd';
import { DATE_FORMAT } from '../../../../constants';
import leaveEntriesApi from '../../../../services/leaveEntriesApi';
import { colors } from '../../../../styles/colors';

const { Title, Text } = Typography;

const RejectLeaveEntryModal = ({ visible, leaveEntry, onCancel, onComplete, t }) => {
  const [loading, setLoading] = useState(false);
  const workerName = `${leaveEntry?.partner?.firstName} ${leaveEntry?.partner?.lastName}`;

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await leaveEntriesApi.rejectEntry(leaveEntry.id);
      message.success(`${t('rejectLeaveEntrySuccess', { workerName })}`);
      onComplete();
    } catch (error) {
      Sentry.captureEvent(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={<Title level={4}>{`${t('rejectLeaveEntryModalTitle')}`}</Title>}
      centered
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
      width={480}
      okText={t('Reject')}
      okButtonProps={{
        danger: true,
        loading,
        style: {
          backgroundColor: colors.functionalError,
          color: colors.white,
        },
      }}
    >
      <Row type="flex" style={{ marginBottom: '24px' }} align="middle" gutter={16}>
        <Col>
          <Avatar shape="square" icon={<UserOutlined />} size={88} />
        </Col>
        <Col>
          <Title level={4}>{workerName}</Title>
          <Text type="secondary">{leaveEntry?.position?.name}</Text>
          <br />
          <Text type="secondary">{`#${leaveEntry?.partner?.id}`}</Text>
        </Col>
      </Row>
      <Row style={{ marginBottom: '24px' }} align="middle" gutter={16}>
        <Col>
          <Text>{`🌴 `}</Text>
          <Text type="secondary" style={{ fontWeight: 600 }}>
            {leaveEntry?.leaveEntryType?.name}
          </Text>
          <br />
          <Title level={4} style={{ marginBottom: 0 }}>
            {moment(leaveEntry?.date).format(DATE_FORMAT)}
          </Title>
        </Col>
      </Row>
    </Modal>
  );
};

export default connect(undefined, null)(withTranslation()(RejectLeaveEntryModal));
