import { STRIPE_ENDPOINT } from '../config';
import base from './base';

const createConfirmIntent = data => {
  return base.api.post(`${STRIPE_ENDPOINT}/create-confirm-intent/`, data);
};

const getPaymentMethods = () => {
  return base.api.post(`${STRIPE_ENDPOINT}/payment-methods/`);
};

const updatePaymentIntent = data => {
  return base.api.post(`${STRIPE_ENDPOINT}/update-payment-intent/`, data);
};

export default {
  createConfirmIntent,
  getPaymentMethods,
  updatePaymentIntent,
};
