import React, { useState, useRef } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Modal, Button, Checkbox, Typography, Row, DatePicker, Select, message } from 'antd';
import { CSVLink } from 'react-csv';
import { colors } from '../../../styles/colors';
import { DATE_FORMAT, AttendanceStatus } from '../../../constants';
import timesheetApi from '../../../services/timesheetApi';
import EmptyCard from '../../../shared/components/EmptyCard';
import EmailSuccessLogo from '../../../assets/images/email_success.png';
import SquareIcon from '../../../shared/components/SquareIcon';

const { Title, Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;
const ALL_LOCATION_OPTION_KEY = 'all_locations';

const EmailModal = ({ visible, onOk, t }) => {
  return (
    <Modal
      visible={visible}
      title={null}
      closable={false}
      bodyStyle={{ padding: '40px 10px 24px 30px' }}
      footer={[
        <Button
          key="done"
          style={{
            backgroundColor: colors.brandGreen,
            color: colors.white,
            fontWeight: 600,
          }}
          onClick={onOk}
        >
          {t('done')}
        </Button>,
      ]}
    >
      <EmptyCard
        title={
          <Text strong style={{ color: colors.black }}>
            {t('emailSuccessTitle')}
          </Text>
        }
        description={
          <Row type="flex" justify="center">
            <Text>{t('emailSuccessDescription')}</Text>
          </Row>
        }
        imgSrc={EmailSuccessLogo}
      />
    </Modal>
  );
};

const AttendanceCSVExportModal = ({
  selectedDateStart,
  defaultLocations,
  locations,
  locationOptions,
  visible,
  closeModal,
  t,
}) => {
  const startDate = selectedDateStart.clone().startOf('isoWeek');
  const endDate = selectedDateStart.clone().endOf('isoWeek');
  const attendanceStatusOptions = [
    { label: t(AttendanceStatus.UNCONFIRMED), value: AttendanceStatus.UNCONFIRMED },
    { label: t(AttendanceStatus.CONFIRMED), value: AttendanceStatus.CONFIRMED },
    { label: t(AttendanceStatus.APPROVED), value: AttendanceStatus.APPROVED },
  ];

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedDateRange, setDateRange] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState(undefined);
  const [csvFilename, setCsvFilename] = useState(undefined);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState(attendanceStatusOptions.map(status => status.value));
  const exportToCsvLink = useRef(null);

  const allLocationOption = { key: ALL_LOCATION_OPTION_KEY, label: t('allLocations') };
  // Get the default selected option
  const defaultLocationOption =
    defaultLocations.length === locationOptions.length - 1
      ? allLocationOption
      : locationOptions.find(option => option.key === Number(defaultLocations[0]));

  const handleLocationsSelectorChange = selectedLocation => {
    if (selectedLocation.key === ALL_LOCATION_OPTION_KEY) {
      setSelectedLocations(locations.map(({ id }) => id));
    } else {
      setSelectedLocations([selectedLocation.key]);
    }
  };

  const handleCloseModal = () => {
    setSelectedStatuses(attendanceStatusOptions.map(status => status.value));
    closeModal();
  };

  const handleDateRangeSelectorChange = dates => {
    setDateRange(dates);
  };

  const handleExportCSV = async () => {
    setLoading(true);
    const [exportStartDate = startDate, exportEndDate = endDate] = selectedDateRange;

    const dateRange = `${exportStartDate.format('DDMMYYYY')}-${exportEndDate.clone().format('DDMMYYYY')}`;
    const filename = `Workmate_${dateRange}.csv`;

    try {
      const data = await timesheetApi.downloadAttendanceSummary({
        status: selectedStatuses.join(',') || undefined,
        clock_in_time_after: exportStartDate.toISOString(),
        clock_in_time_before: exportEndDate.endOf('day').toISOString(),
        location: selectedLocations.length > 0 ? selectedLocations.join(',') : defaultLocations.join(','),
      });
      /*
        If no data is returned, it indicates that the number of records has exceeded the threshold.
        We show the success email modal instead.
      */
      if (!data) {
        handleCloseModal();
        setShowEmailModal(true);
        return;
      }
      // If data is returned, we let the user download the csv file directly
      setCsvData(data);
      setCsvFilename(filename);
      exportToCsvLink.current.link.click();
    } catch (error) {
      message.error({
        content: t('exportCSVErrorMessage'),
        duration: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  const onChange = checkedValues => {
    setSelectedStatuses(checkedValues);
  };

  return (
    <>
      <Modal
        destroyOnClose
        visible={visible}
        onCancel={() => {
          handleCloseModal();
        }}
        confirmLoading={loading}
        title={<Title level={4}>{t('exportCsv')}</Title>}
        bodyStyle={{ paddingLeft: 24 }}
        footer={[
          <Button
            key="close"
            onClick={() => {
              handleCloseModal();
            }}
          >
            {t('cancel')}
          </Button>,
          <Button
            key="submit"
            disabled={selectedStatuses.length === 0}
            loading={loading}
            style={{
              backgroundColor: selectedStatuses.length > 0 ? colors.brandGreen : colors.disabled,
              color: colors.white,
              fontWeight: 600,
            }}
            onClick={async () => {
              await handleExportCSV();
            }}
          >
            {t('export')}
          </Button>,
          <CSVLink
            target="_self"
            ref={exportToCsvLink}
            asyncOnClick
            data={csvData || []}
            filename={csvFilename}
            key="csv-link"
          />,
        ]}
      >
        <Row>
          <Typography>
            <Paragraph>
              <Trans i18nKey="exportCsvMessage">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Select Location and Date range of attendances you want to export. Only
                <SquareIcon color={colors.functionalApproved} />
                Approved and
                <SquareIcon color={colors.functionalSuccess} />
                Confirmed attendances will be exported.
              </Trans>
            </Paragraph>
          </Typography>
        </Row>
        <Row style={{ margin: '10px 0px 2px 0px' }}>
          <Text>{t('location')}</Text>
        </Row>
        <Row>
          <Select
            labelInValue
            optionFilterProp="filter"
            defaultValue={[defaultLocationOption]}
            placeholder={t('Location')}
            onChange={handleLocationsSelectorChange}
            style={{ width: '260px' }}
          >
            {locationOptions &&
              locationOptions.map(option => (
                <Select.Option key={option.key} filter={option.label}>
                  <Text>{t(option.label)}</Text>
                </Select.Option>
              ))}
          </Select>
        </Row>
        <Row style={{ margin: '10px 0px 2px 0px' }}>
          <Text>{t('selectDateRange')}</Text>
        </Row>
        <Row>
          <RangePicker
            style={{ width: '260px' }}
            format={DATE_FORMAT}
            defaultValue={[startDate, endDate]}
            onChange={handleDateRangeSelectorChange}
            allowClear={false}
          />
        </Row>
        <Row style={{ margin: '10px 0px 5px 0px' }}>
          <Text>{t('attendanceStatus')}</Text>
        </Row>
        <Row>
          <Checkbox.Group
            options={attendanceStatusOptions}
            defaultValue={selectedStatuses}
            onChange={onChange}
            style={{ marginBottom: 5 }}
          />
          {selectedStatuses.length === 0 && <Text type="danger">{t('pleaseSelectAttendanceStatus')}</Text>}
        </Row>
      </Modal>
      <EmailModal
        visible={showEmailModal}
        onOk={() => {
          setShowEmailModal(false);
        }}
        t={t}
      />
    </>
  );
};

export default withTranslation()(AttendanceCSVExportModal);
