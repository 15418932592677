import { camelCase } from 'lodash';
import moment from 'moment';
import { attendanceStatuses, timesheetEntryStatuses, derivedWorkingStatus, absenceDetailStatuses } from '../constants';

const isAssigned = attendance => {
  return attendance?.status === attendanceStatuses.ASSIGNED;
};

const isAbsent = attendance => {
  return (
    attendance &&
    // for backward compatibility
    (attendance.absence_reason ||
      (attendance.absence_detail && attendance.absence_detail.status !== absenceDetailStatuses.CANCELLED))
  );
};
const isChangeRequest = attendance => {
  return (
    attendance && attendance.change_reason && !attendance.confirmed && attendance.status === attendanceStatuses.ASSIGNED
  );
};

const hasNonVoidTimesheetEntry = timesheetEntries => {
  return timesheetEntries?.length > 0 && !timesheetEntries?.find(ts => ts.status === timesheetEntryStatuses.VOID);
};

const isValidWorkingDay = contractStatus => {
  return [derivedWorkingStatus.WORKING].includes(contractStatus);
};

const formatAbsenceDatesStats = (absenceDates = {}) => {
  const stats = {};
  Object.entries(absenceDates).forEach(absenceDate => {
    const [absenceDateKey, absenceDateValue] = absenceDate;
    const currentDateKey = moment(absenceDateKey).format('ddd DD/MM');
    stats[currentDateKey] = absenceDateValue;
  });
  return stats;
};

const getAbsenceDetailStatsRows = ({ absenceDetailsStatistics, rowKey, t }) => {
  const absenceDetailsStatsKeys = absenceDetailsStatistics ? Object.keys(absenceDetailsStatistics) : [];
  return absenceDetailsStatsKeys.map(absenceTypeKey => {
    const stats = formatAbsenceDatesStats(absenceDetailsStatistics[absenceTypeKey]);
    return {
      key: absenceTypeKey,
      rowKey,
      rowTitle: t(camelCase(absenceTypeKey)),
      ...stats,
    };
  });
};

export default {
  isAssigned,
  isAbsent,
  isChangeRequest,
  hasNonVoidTimesheetEntry,
  isValidWorkingDay,
  getAbsenceDetailStatsRows,
};
