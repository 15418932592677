import React from 'react';
import { withTranslation } from 'react-i18next';
import { Badge, Tabs, Row, Typography, Tooltip, Col } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { toLower } from 'lodash';
import StaffRequestEmployeesList from '../../../containers/StaffRequestEmployeesList';
import StaffRequestApplicantsList from '../../../containers/StaffRequestApplicantsList';
import StaffRequestContractsList from '../../../containers/StaffRequestContractsList';
import StaffRequestInactiveList from '../../../containers/StaffRequestInactiveList';
import { staffRequestTabs, employmentStatuses, applicationStatuses, inactiveStatusOptions } from '../../../constants';
import { colors } from '../../../styles/colors';

const { TabPane } = Tabs;
const { Text } = Typography;

const StyledTabs = styled(Tabs)`
  margin-right: -10px;
  padding-right: 10px;

  .ant-tabs-nav {
    margin-right: -20px;
    padding-right: 10px;
  }
  .ant-tabs-nav-wrap {
    padding-right: 10px;
  }
`;

const AutoRejectedRibbon = styled(Badge.Ribbon)`
  margin-top: 45px;

  .ant-ribbon-text {
    color: ${colors.secondaryText};
    font-size: 12px;
  }
`;

const FunnelTabs = ({
  staffRequest,
  funnelTabCounts,
  onChangeTab,
  onUpdateStatusCounts,
  currentActiveTabKey,
  selectedWorker,
  handleMoveTab,
  onUpdateParentSelectedWorker,
  partnerIdFromParams,
  applicationRejectReasons,
  t,
}) => {
  const { id, client, staff_required } = staffRequest;

  const handleFunnelTabCount = (worker, tab) => {
    // returns 1 if worker belongs to the tab, else 0
    const { status, to } = worker;
    let count;

    if (Object.values(employmentStatuses).includes(status) && tab === staffRequestTabs.EMPLOYEES) {
      count = 1;
    } else if (
      Object.keys(inactiveStatusOptions)
        .map(option => toLower(option))
        .includes(status) &&
      tab === staffRequestTabs.INACTIVE
    ) {
      count = 1;
    } else if (status === applicationStatuses.PENDING_CONTRACT && tab === staffRequestTabs.OFFERS) {
      count = 1;
    } else if (to === tab) {
      count = 1;
    } else {
      count = 0;
    }
    return count;
  };

  const tabs = [
    {
      tabTitle: t('interviewFunnelTitle').toUpperCase(),
      countDisplay:
        selectedWorker.length > 0
          ? handleFunnelTabCount(selectedWorker[0], staffRequestTabs.INTERVIEW)
          : `${funnelTabCounts.status_counts.interview}`,
      key: staffRequestTabs.INTERVIEW,
      content: (
        <StaffRequestApplicantsList
          staffRequest={staffRequest}
          staffRequestId={id}
          updateParentStatusCounts={onUpdateStatusCounts}
          t={t}
          applicantCounts={funnelTabCounts.status_counts.interviewed}
          onChangeUrl={onChangeTab}
          tabKey={staffRequestTabs.INTERVIEW}
          currentActiveTabKey={currentActiveTabKey}
          selectedWorker={selectedWorker}
          moveToTabWithSearchedWorker={handleMoveTab}
          updateParentSelectedWorker={onUpdateParentSelectedWorker}
          partnerIdFromParams={partnerIdFromParams}
          applicationRejectReasons={applicationRejectReasons}
        />
      ),
      toolTipContent: t('interviewToolTip'),
      show: staffRequest?.client_interview_required === true,
    },
    {
      tabTitle: t('waitlisted').toUpperCase(),
      countDisplay:
        selectedWorker.length > 0
          ? handleFunnelTabCount(selectedWorker[0], staffRequestTabs.WAITLISTED)
          : `${funnelTabCounts.status_counts.shortlisted}`,
      key: staffRequestTabs.WAITLISTED,
      content: (
        <StaffRequestApplicantsList
          staffRequest={staffRequest}
          staffRequestId={id}
          updateParentStatusCounts={onUpdateStatusCounts}
          t={t}
          applicantCounts={funnelTabCounts.status_counts.shortlisted}
          onChangeUrl={onChangeTab}
          tabKey={staffRequestTabs.WAITLISTED}
          currentActiveTabKey={currentActiveTabKey}
          selectedWorker={selectedWorker}
          moveToTabWithSearchedWorker={handleMoveTab}
          updateParentSelectedWorker={onUpdateParentSelectedWorker}
          partnerIdFromParams={partnerIdFromParams}
          applicationRejectReasons={applicationRejectReasons}
        />
      ),
      toolTipContent: t('waitlistedTooltip'),
      show: true,
    },
    {
      tabTitle: t('Offered').toUpperCase(),
      countDisplay:
        selectedWorker.length > 0
          ? handleFunnelTabCount(selectedWorker[0], staffRequestTabs.OFFERS)
          : `${funnelTabCounts.status_counts.offered}`,
      key: staffRequestTabs.OFFERS,
      content: (
        <StaffRequestContractsList
          staffRequest={staffRequest}
          staffRequestId={id}
          contractCounts={funnelTabCounts.status_counts.offered}
          updateParentStatusCounts={onUpdateStatusCounts}
          t={t}
          contractRequired={client.contract_required}
          onChangeUrl={onChangeTab}
          tabKey={staffRequestTabs.OFFERS}
          currentActiveTabKey={currentActiveTabKey}
          selectedWorker={selectedWorker}
          moveToTabWithSearchedWorker={handleMoveTab}
          updateParentSelectedWorker={onUpdateParentSelectedWorker}
          partnerIdFromParams={partnerIdFromParams}
          applicationRejectReasons={applicationRejectReasons}
        />
      ),
      toolTipContent: t('offeredToolTip'),
      show: true,
    },
    {
      tabTitle: t('accepted').toUpperCase(),
      countDisplay:
        selectedWorker.length > 0 ? (
          handleFunnelTabCount(selectedWorker[0], staffRequestTabs.EMPLOYEES)
        ) : (
          <Text>
            {`${staffRequest?.statuses_counts?.active}`}
            <Text style={{ fontSize: '16px', marginLeft: '5px' }} type="secondary">
              / {staff_required} {toLower(t('needed'))}
            </Text>
          </Text>
        ),
      key: staffRequestTabs.EMPLOYEES,
      content: (
        <StaffRequestEmployeesList
          staffRequest={staffRequest}
          client={client}
          staffRequestId={id}
          updateParentStatusCounts={onUpdateStatusCounts}
          t={t}
          tabKey={staffRequestTabs.EMPLOYEES}
          currentActiveTabKey={currentActiveTabKey}
          selectedWorker={selectedWorker}
          moveToTabWithSearchedWorker={handleMoveTab}
          updateParentSelectedWorker={onUpdateParentSelectedWorker}
        />
      ),
      toolTipContent: t('acceptedToolTip'),
      show: true,
    },
    {
      tabTitle: t('Inactive').toUpperCase(),
      countDisplay:
        selectedWorker.length > 0
          ? handleFunnelTabCount(selectedWorker[0], staffRequestTabs.INACTIVE)
          : `${funnelTabCounts.status_counts.inactive}`,
      key: staffRequestTabs.INACTIVE,
      autoRejectedCount: funnelTabCounts.status_counts.auto_rejected,
      content: (
        <StaffRequestInactiveList
          staffRequest={staffRequest}
          staffRequestId={id}
          inactiveCounts={funnelTabCounts.status_counts.inactive}
          t={t}
          tabKey={staffRequestTabs.INACTIVE}
          currentActiveTabKey={currentActiveTabKey}
          selectedWorker={selectedWorker}
          moveToTabWithSearchedWorker={handleMoveTab}
          partnerIdFromParams={partnerIdFromParams}
          updateParentStatusCounts={onUpdateStatusCounts}
          updateParentSelectedWorker={onUpdateParentSelectedWorker}
        />
      ),
      toolTipContent: t('inactiveToolTip'),
      show: true,
    },
  ].filter(tab => tab.show);

  return (
    <>
      <StyledTabs activeKey={currentActiveTabKey} className="v2-funnel-tabs" onChange={onChangeTab}>
        {tabs.map((tab, index) => {
          const { tabTitle, countDisplay, key, content, toolTipContent, autoRejectedCount } = tab;
          const isActive = key === currentActiveTabKey;
          const isLastTabBeforeInactive = index >= tabs.length - 2;
          const isInactiveTab = index === tabs.length - 1;

          const classes = [];
          if (!isLastTabBeforeInactive) classes.push('arrow_box');
          if (isActive) classes.push('selected_funnel');

          let backgroundColor;
          if (isActive && !isInactiveTab) {
            backgroundColor = colors.tagGreen;
          } else if (isInactiveTab) {
            backgroundColor = colors.lightGrey;
          }

          let tabPaneContent = (
            <div
              className={classes.join(' ')}
              style={{
                borderColor: colors.borderGrey,
                borderRight: '1px solid #d9d9d9',
                borderTop: '1px solid #d9d9d9',
                borderBottom: '1px solid #d9d9d9',
                borderLeft: index === 0 || isInactiveTab ? '1px solid #d9d9d9' : null,
                padding: '12px 0px 12px 32px',
                flex: 1,
                backgroundColor,
                zIndex: isActive ? 0 : 1,
              }}
            >
              <Row>
                <Col>
                  <Text
                    strong
                    style={{
                      color: isActive && !isInactiveTab ? colors.functionalSuccess : colors.secondaryText,
                      fontSize: '12px',
                    }}
                  >
                    {tabTitle}
                  </Text>
                  {toolTipContent && (
                    <Tooltip title={toolTipContent}>
                      <QuestionCircleOutlined style={{ marginLeft: '5px', color: colors.darkGrey }} />
                    </Tooltip>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Text
                    strong
                    type="secondary"
                    style={{
                      color: isActive && !isInactiveTab ? colors.functionalSuccess : colors.black,
                      fontSize: '24px',
                    }}
                  >
                    {countDisplay}
                  </Text>
                </Col>
              </Row>
            </div>
          );

          if (isInactiveTab && staffRequest.form_id) {
            tabPaneContent = (
              <AutoRejectedRibbon
                color={colors.componentBorder}
                placement="end"
                text={t('autoRejectedCount', { count: autoRejectedCount })}
              >
                {tabPaneContent}
              </AutoRejectedRibbon>
            );
          }

          return (
            <TabPane tab={tabPaneContent} key={key}>
              <Row style={{ marginTop: '28px' }}>
                <Col span={24}>{content}</Col>
              </Row>
            </TabPane>
          );
        })}
      </StyledTabs>
    </>
  );
};

export default withTranslation()(FunnelTabs);
