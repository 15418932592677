/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import moment from 'moment';
import { sortBy } from 'lodash';
import { Table, Typography, Checkbox, Row } from 'antd';
import { withTranslation } from 'react-i18next';

import WeeklyAttendanceTableCell from './WeeklyAttendanceTableCell';

import routes from '../../../routes';
import { employmentTypes, timesheetEntryStatuses, leaveEntryStatuses } from '../../../constants';

import dateUtils from '../../../utilities/dateUtils';
import { colors } from '../../../styles/colors';
import textUtils from '../../../utilities/textUtils';
import { SERVER_DATE } from '../../../styles/dates';

export default withTranslation()(
  ({
    t,
    employments,
    totalEmploymentsCount,
    startDate,
    loading,
    page,
    defaultPageSize,
    onPageChange,
    onCellSelect,
    showRoundedTime,
    onSelectDate,
    selectedDates,
  }) => {
    const dates = dateUtils.getWeekDatesFromSelectedDate(startDate);
    let tableColumns = [
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('Worker')}</Typography.Text>,
        dataIndex: 'worker',
        width: 170,
        render: worker => (
          <div style={{ display: 'flex', flexDirection: 'column', width: 170 }}>
            <a
              href={`${routes.partners}/${worker.id}`}
              style={{ fontWeight: 800, color: colors.black }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {textUtils.toTitleCase(worker.name)}
            </a>
            {worker?.employmentType?.toLowerCase() === employmentTypes.GIG.value && (
              <Typography.Text style={{ color: colors.secondaryText }}>{t('employmentTypeGig')}</Typography.Text>
            )}
          </div>
        ),
      },
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('Location')}</Typography.Text>,
        width: 120,
        dataIndex: 'location',
        render: location => (
          <div style={{ width: 115, whiteSpace: 'normal' }}>
            <Typography.Text style={{ lineHeight: 1 }}>{location}</Typography.Text>
          </div>
        ),
      },
    ];
    tableColumns = [
      ...tableColumns,
      ...dates.map(date => {
        const dateKey = date.format('ddd DD/MM');
        return {
          title: (
            <Row>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography.Text>{date.format('ddd')}</Typography.Text>
                <Typography.Text style={{ fontWeight: 800 }}>{date.format('DD/MM')}</Typography.Text>
              </div>
              <Row style={{ flex: 1, justifyContent: 'flex-end' }}>
                <Checkbox
                  checked={selectedDates.find(selectedDate => selectedDate === date.format(SERVER_DATE))}
                  onChange={e => onSelectDate(e.target.checked, date.format(SERVER_DATE))}
                />
              </Row>
            </Row>
          ),
          dataIndex: dateKey,
          width: 100,
          render: (record, params) => ({
            props: { style: { padding: 0 }, width: '100' },
            children: (
              <div style={{ height: '100%' }}>
                <WeeklyAttendanceTableCell
                  {...record}
                  showRoundedTime={showRoundedTime}
                  maxNonVoidTsEntryRowCount={params?.maxNonVoidTsEntryRowCount}
                  onCellSelect={() =>
                    onCellSelect({
                      timesheetEntries: record.timesheetEntries,
                      nonVoidTimesheetEntries: record.nonVoidTimesheetEntries,
                      leaveEntries: record.leaveEntries,
                      nonVoidLeaveEntries: record.nonVoidLeaveEntries,
                      employment: record.employment,
                      date: record.date,
                    })
                  }
                />
              </div>
            ),
          }),
        };
      }),
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('Days worked')}</Typography.Text>,
        dataIndex: 'daysWorked',
        width: 100,
      },
    ];

    let tableRows = [];
    employments.map(employment => {
      const { partner } = employment;
      const location = employment.location?.name;

      const row = {
        key: employment.id,
        location,
        worker: {
          id: partner.id,
          name: `${partner.firstName} ${partner.lastName}`,
          employmentType: employment.type,
        },
        daysWorked: 0,
        maxNonVoidTsEntryRowCount: 0,
      };

      dates.map(date => {
        const data = { date, employment };
        const sortedTimesheetEntries = sortBy(
          employment?.timesheetEntries.filter(entry => moment(entry.clockInTime).isSame(date, 'day')),
          'clockInTime',
        );

        const sortedLeaveEntries = sortBy(
          employment?.leaveEntries.filter(entry => moment(entry.date).isSame(date, 'day')),
          'date',
        );

        if (sortedTimesheetEntries.length > 0) {
          const nonVoidTimesheetEntries = sortedTimesheetEntries.filter(
            timesheet => timesheet.status !== timesheetEntryStatuses.VOID,
          );
          data.timesheetEntries = sortedTimesheetEntries;
          data.nonVoidTimesheetEntries = nonVoidTimesheetEntries;

          if (nonVoidTimesheetEntries.length > 0) row.daysWorked += 1;

          if (nonVoidTimesheetEntries.length > row.maxNonVoidTsEntryRowCount) {
            row.maxNonVoidTsEntryRowCount = nonVoidTimesheetEntries.length;
          }
        }

        if (sortedLeaveEntries.length > 0) {
          const nonVoidLeaveEntries = sortedLeaveEntries.filter(leave => leave.status !== leaveEntryStatuses.VOID);

          data.leaveEntries = sortedLeaveEntries;
          data.nonVoidLeaveEntries = nonVoidLeaveEntries;
        }

        const hasMultipleEntries =
          data.nonVoidTimesheetEntries?.length > 1 ||
          data.nonVoidLeaveEntries?.length > 1 ||
          (data.nonVoidTimesheetEntries?.length >= 1 && data.nonVoidLeaveEntries?.length >= 1);

        if (hasMultipleEntries) {
          data.hasMultipleEntries = true;
        }

        row[date.format('ddd DD/MM')] = data;
        return null;
      });

      tableRows = [...tableRows, row];
      return null;
    });

    return (
      <Table
        className="v2-attendance-table"
        style={{ marginBottom: 80 }}
        indentSize={0}
        loading={loading}
        columns={tableColumns}
        dataSource={tableRows}
        rowKey="key"
        pagination={{
          defaultPageSize,
          current: page,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
          total: totalEmploymentsCount,
        }}
        onChange={onPageChange}
      />
    );
  },
);
