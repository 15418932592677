import React from 'react';
import { Tabs, Row, Typography, Col, Grid } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../styles/colors';
import { staffRequestTabs } from '../../../../constants';
import WorkerList from './WorkerList';
import WaitlistedWorkerList from './WaitlistedWorkerList';

const { TabPane } = Tabs;
const { Text } = Typography;
const { useBreakpoint } = Grid;

const StyledTabs = styled(Tabs)`
  margin-right: -10px;
  padding-right: 10px;

  .ant-tabs-nav {
    margin-right: -20px;
    padding-right: 10px;
    margin-bottom: 0;
  }
  .ant-tabs-nav-wrap {
    padding-right: 10px;
  }
`;

const FunnelTabs = ({
  waitlistedCount,
  acceptedCount,
  cancelledCount,
  currentActiveTabKey = staffRequestTabs.ACCEPTED,
  onTabChange,
  shift,
  shiftTime,
  acceptedWorkers,
  cancelledWorkers,
}) => {
  const { t } = useTranslation();
  const { xs } = useBreakpoint();

  const tabs = [
    {
      tabTitle: t('waitlisted').toUpperCase(),
      countDisplay: waitlistedCount,
      key: staffRequestTabs.WAITLISTED,
      content: <WaitlistedWorkerList shift={shift} shiftTime={shiftTime} workers={[]} />,
    },
    {
      tabTitle: t('accepted').toUpperCase(),
      countDisplay: (
        <Text>
          {acceptedCount}
          <Text style={{ fontSize: '16px', marginLeft: '5px' }} type="secondary">
            / {shift.staffRequired}
          </Text>
        </Text>
      ),
      key: staffRequestTabs.ACCEPTED,
      content: (
        <WorkerList
          shift={shift}
          shiftTime={shiftTime}
          workers={acceptedWorkers}
          emptyTitle={t('noActiveWorkers')}
          emptyDescription={t('activeWorkersWillShowUp')}
        />
      ),
    },
    {
      tabTitle: t('cancelled').toUpperCase(),
      countDisplay: cancelledCount,
      key: staffRequestTabs.CANCELLED,
      content: (
        <WorkerList
          shift={shift}
          shiftTime={shiftTime}
          workers={cancelledWorkers}
          emptyTitle={t('noInactiveApplications')}
          emptyDescription={t('inactiveWithdrawnRejectedApplications')}
        />
      ),
    },
  ];

  return (
    <StyledTabs className="v2-funnel-tabs" activeKey={currentActiveTabKey} onChange={onTabChange}>
      {tabs.map((tab, index) => {
        const { tabTitle, countDisplay, key, content } = tab;
        const isActive = key === currentActiveTabKey;
        const isLastTabBeforeInactive = index >= tabs.length - 2;
        const isInactiveTab = index === tabs.length - 1;

        const classes = [];
        if (!isLastTabBeforeInactive) classes.push('arrow_box');
        if (isActive) classes.push('selected_funnel');

        let backgroundColor;
        if (isActive && !isInactiveTab) {
          backgroundColor = colors.tagGreen;
        } else if (isInactiveTab) {
          backgroundColor = colors.lightGrey;
        }

        const tabPaneContent = (
          <div
            className={classes.join(' ')}
            style={{
              borderColor: colors.borderGrey,
              borderRight: '1px solid #d9d9d9',
              borderTop: '1px solid #d9d9d9',
              borderBottom: '1px solid #d9d9d9',
              borderLeft: index === 0 || isInactiveTab ? '1px solid #d9d9d9' : null,
              padding: xs ? '12px 0px 12px 0px' : '12px 0px 12px 32px',
              flex: 1,
              backgroundColor,
              zIndex: isActive ? 0 : 1,
            }}
          >
            <Row>
              <Col xs={24}>
                <Text
                  strong
                  style={{
                    color: isActive && !isInactiveTab ? colors.functionalSuccess : colors.secondaryText,
                    fontSize: '12px',
                    display: 'block',
                    textAlign: xs ? 'center' : 'left',
                  }}
                >
                  {tabTitle}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Text
                  strong
                  type="secondary"
                  style={{
                    color: colors.black,
                    fontSize: '24px',
                    display: 'block',
                    textAlign: xs ? 'center' : 'left',
                  }}
                >
                  {countDisplay}
                </Text>
              </Col>
            </Row>
          </div>
        );

        return (
          <TabPane tab={tabPaneContent} key={key}>
            <div style={{ margin: '24px 0' }}>{content}</div>
          </TabPane>
        );
      })}
    </StyledTabs>
  );
};

export default FunnelTabs;
