import React, { useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Button, Typography, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';

import { DATE_FORMAT, WEEKLY_DATE_RANGE } from '../../../constants';
import WeekSelect from '../../../shared/components/WeekSelect';

const { Text } = Typography;

const CopyAssignmentButtonModal = ({ t, onCopy, weekdayDates, disabled, selectedDateRange }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCopyWeekStart, setSelectedCopyWeekStart] = useState(weekdayDates[0]);
  const isWeekly = selectedDateRange === WEEKLY_DATE_RANGE;

  const handleClickCopyShift = () => {
    setShowModal(true);
  };

  const handleCancelCopyShift = () => {
    setShowModal(false);
    setSelectedCopyWeekStart(weekdayDates[0]);
  };

  const handleCopyShift = () => {
    onCopy(selectedCopyWeekStart);
    handleCancelCopyShift();
  };

  const handleShiftCopyWeekSelect = value => {
    const weekStart = value.clone().startOf('isoWeek');
    setSelectedCopyWeekStart(weekStart);
  };

  return (
    <>
      <Tooltip arrowPointAtCenter title={isWeekly ? t('copyShiftTooltip') : t('disabledCopyShiftTooltip')}>
        <Button onClick={handleClickCopyShift} disabled={!isWeekly || disabled} style={{ marginRight: 8 }}>
          {t('copy')}
        </Button>
      </Tooltip>
      {showModal && (
        <Modal
          title={t('copyShifts')}
          visible={showModal}
          onOk={handleCopyShift}
          okButtonProps={{ disabled: !selectedCopyWeekStart }}
          okType="v2-primary"
          okText={t('copy')}
          cancelText={t('cancel')}
          onCancel={handleCancelCopyShift}
          destroyOnClose
        >
          {/* TODO should not allow select history */}
          <div style={{ paddingBottom: 8 }}>
            {t('copyShiftsFrom', {
              weekStart: weekdayDates[0].format(DATE_FORMAT),
              weekEnd: weekdayDates[6].format(DATE_FORMAT),
            })}
          </div>
          <WeekSelect onChange={handleShiftCopyWeekSelect} selectedWeekStart={selectedCopyWeekStart} disablePastDates />
          <Text type="warning">
            <ExclamationCircleOutlined style={{ paddingRight: 5 }} />
            <Text type="warning">{t('copyWarning')}</Text>
          </Text>
        </Modal>
      )}
    </>
  );
};
export default withTranslation()(CopyAssignmentButtonModal);
