import React from 'react';
import * as Sentry from '@sentry/browser';
import { EllipsisOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Typography, Button, Modal, Table, Dropdown, Menu, message, Row, Spin, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { colors } from '../../../styles/colors';

import timesheetApi from '../../../services/timesheetApi';
import { timesheetEntryStatuses, TIME_FORMAT_24_HOUR, PAGE_SIZE_OPTIONS } from '../../../constants';
import { getFormattedDurationInHours } from '../../../utilities/durationUtils';

import { checkAccess } from '../../../shared/access/Access';
import { permissions } from '../../../shared/access/accessConfig';
import ConfirmModals from '../../../shared/components/ConfirmModals';
// TODO move to shared components
import VoidTimesheetEntryModal from '../../../containers/TimesheetList/components/VoidTimesheetEntryModal';
import EditTimesheetFormModal from './EditTimesheetFormModal';
import { prepParams } from '../../../utilities/urlUtils';

const { Text } = Typography;

const UnconfirmedTimesheetsTableModal = withTranslation()(
  ({
    t,
    loading,
    count,
    visible,
    onCancel,
    timesheets,
    pageSize,
    currentPage,
    onPageChange,
    onEditTimesheetEntry,
    onApproveTimesheetEntry,
    onConfirmTimesheetEntry,
    onVoidTimesheetEntry,
    selectedTimesheetsCount,
    handleSelectedRows,
    selectedRowKeys,
    selectedTimesheets,
    onBulkApproveTimesheetEntries,
    onBulkConfirmTimesheetEntries,
  }) => {
    const tableColumns = [
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('Worker')}</Typography.Text>,
        dataIndex: 'workerName',
        width: 180,
        render: workerName => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text style={{ fontWeight: 800 }}>{workerName}</Typography.Text>
          </div>
        ),
      },
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('Date')}</Typography.Text>,
        dataIndex: 'date',
        width: 100,
        render: date => (
          <div style={{ whiteSpace: 'normal' }}>
            <Typography.Text style={{ lineHeight: 1 }}>{date}</Typography.Text>
          </div>
        ),
      },
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('clockIn')}</Typography.Text>,
        dataIndex: 'clockInTime',
        width: 100,
        render: clockInTime => (
          <div style={{ whiteSpace: 'normal' }}>
            <Typography.Text style={{ lineHeight: 1 }}>{clockInTime}</Typography.Text>
          </div>
        ),
      },
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('clockOut')}</Typography.Text>,
        dataIndex: 'clockOutTime',
        width: 100,
        render: clockOutTime => (
          <div style={{ whiteSpace: 'normal' }}>
            <Typography.Text style={{ lineHeight: 1 }}>{clockOutTime}</Typography.Text>
          </div>
        ),
      },
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('late')}</Typography.Text>,
        dataIndex: 'late',
        width: 75,
        render: late => (
          <div style={{ whiteSpace: 'normal' }}>
            <Typography.Text style={{ lineHeight: 1 }}>{late ? t('Yes') : t('No')}</Typography.Text>
          </div>
        ),
      },
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('overtime')}</Typography.Text>,
        dataIndex: 'overtimeDuration',
        width: 120,
        render: overtimeDuration => (
          <div style={{ whiteSpace: 'normal' }}>
            <Typography.Text style={{ lineHeight: 1 }}>{overtimeDuration}</Typography.Text>
          </div>
        ),
      },
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('location')}</Typography.Text>,
        dataIndex: 'locationName',
        render: locationName => (
          <div style={{ whiteSpace: 'normal' }}>
            <Typography.Text style={{ lineHeight: 1 }}>{locationName}</Typography.Text>
          </div>
        ),
      },
      {
        title: <Typography.Text style={{ fontWeight: 800 }}>{t('Actions')}</Typography.Text>,
        dataIndex: 'buttons',
        width: 120,
        render: (_, record) => (
          <div style={{ whiteSpace: 'normal' }}>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="edit"
                    onClick={() => {
                      const timesheetEntry = timesheets.find(entry => entry.id === record.timesheetId);
                      onEditTimesheetEntry(timesheetEntry);
                    }}
                  >
                    {t('Edit')}
                  </Menu.Item>
                  <Menu.Item
                    key="confirm"
                    onClick={() => {
                      ConfirmModals.confirmTimesheetEntries(1, t, () => {
                        onConfirmTimesheetEntry(record.timesheetId);
                      });
                    }}
                  >
                    {t('Confirm')}
                  </Menu.Item>
                  {checkAccess(permissions.attendanceApproveButton) && (
                    <Menu.Item
                      key="approve"
                      onClick={() => {
                        ConfirmModals.approveTimesheetEntries(1, t, () => {
                          onApproveTimesheetEntry(record.timesheetId);
                        });
                      }}
                    >
                      {t('approve')}
                    </Menu.Item>
                  )}
                  <Menu.Item
                    key="void"
                    onClick={() => {
                      const timesheetEntry = timesheets.find(entry => entry.id === record.timesheetId);
                      onVoidTimesheetEntry(timesheetEntry);
                    }}
                  >
                    <Text style={{ color: colors.functionalError }}>{t('Void')}</Text>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomLeft"
            >
              <Button>
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </div>
        ),
      },
    ];

    const tableRows = timesheets.map(timesheetEntry => {
      const { partner, late, clock_in_time, clock_out_time, overtime_duration, location, id } = timesheetEntry;
      const timesheetId = id;
      const workerName = `${partner.first_name} ${partner.last_name}`;
      const date = moment(clock_in_time).format('DD MMM');
      const clockInTime = moment(clock_in_time).format(TIME_FORMAT_24_HOUR);
      const clockOutTime = clock_out_time ? moment(clock_out_time).format(TIME_FORMAT_24_HOUR) : '-';
      const overtimeDuration = getFormattedDurationInHours(overtime_duration);
      const locationName = location?.name;

      return {
        timesheetId,
        workerName,
        date,
        clockInTime,
        clockOutTime,
        late,
        overtimeDuration,
        locationName,
        key: timesheetId,
      };
    });

    const btnStyle = { fontWeight: 600, marginBottom: 5 };

    return (
      <Modal
        title={
          <Typography.Text style={{ fontWeight: 600, fontSize: 24, color: colors.black }}>
            {`${count} ${t('unconfirmedAttendance')}`}
          </Typography.Text>
        }
        visible={visible}
        onCancel={onCancel}
        footer={null}
        style={{ minWidth: 1000 }}
      >
        <Button
          type="secondary"
          style={
            selectedTimesheetsCount
              ? { color: colors.workmateGreen, borderColor: colors.workmateGreen, ...btnStyle }
              : { color: colors.darkGrey, ...btnStyle }
          }
          disabled={!selectedTimesheetsCount}
          onClick={() =>
            ConfirmModals.confirmTimesheetEntries(selectedTimesheets.length, t, onBulkConfirmTimesheetEntries)
          }
        >
          {t('Confirm')} {selectedTimesheetsCount && <span style={{ marginLeft: 5 }}>({selectedTimesheetsCount})</span>}
        </Button>
        {checkAccess(permissions.attendanceApproveButton) && (
          <Button
            type="secondary"
            style={
              selectedTimesheetsCount
                ? {
                    color: colors.white,
                    borderColor: colors.white,
                    backgroundColor: colors.workmateGreen,
                    ...btnStyle,
                    marginLeft: 10,
                  }
                : { color: colors.darkGrey, ...btnStyle, marginLeft: 10 }
            }
            disabled={!selectedTimesheetsCount}
            onClick={() =>
              ConfirmModals.approveTimesheetEntries(selectedTimesheets.length, t, onBulkApproveTimesheetEntries)
            }
          >
            {t('approve')}{' '}
            {selectedTimesheetsCount && <span style={{ marginLeft: 5 }}>({selectedTimesheetsCount})</span>}
          </Button>
        )}
        <Table
          rowSelection={{
            selectedRowKeys,
            onChange: handleSelectedRows,
          }}
          indentSize={0}
          loading={loading}
          columns={tableColumns}
          dataSource={tableRows}
          rowKey="key"
          pagination={{
            pageSize,
            current: currentPage,
            showSizeChanger: true,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            total: count,
            showTotal: (total, range) => `${range[0]}-${range[1]} ${t('of')} ${total} ${t('items')}`,
          }}
          onChange={onPageChange}
        />
      </Modal>
    );
  },
);

class UnconfirmedTimesheetsSection extends React.Component {
  state = {
    loading: false,
    currentPage: 1,
    showUnconfirmedTimesheetsModal: false,
    showTimesheetEntryModal: false,
    unconfirmedTimesheets: [],
    unconfirmedTablePageSize: 10,
    selectedTimesheetsCount: undefined,
    selectedTimesheets: [],
    selectedRowKeys: undefined,
    showVoidTimesheetEntryModal: false,
  };

  async componentDidMount() {
    await this.fetchData();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.locations !== this.props.locations || prevProps.timesheetEntries !== this.props.timesheetEntries) {
      this.resetPageData(this.fetchData);
    }
  }

  resetPageData = then => {
    return this.setState(
      { currentPage: 1, selectedTimesheetsCount: undefined, selectedTimesheets: [], selectedRowKeys: undefined },
      then,
    );
  };

  fetchData = async () => {
    await this.fetchUnconfirmedTimesheets();
  };

  fetchUnconfirmedTimesheets = async () => {
    const { locations, selectedWeekStart, filters } = this.props;
    const { unconfirmedTablePageSize, currentPage } = this.state;
    const startDate = selectedWeekStart.clone().toISOString();
    const endDate = selectedWeekStart
      .clone()
      .endOf('isoWeek')
      .toISOString();
    this.setState({ loading: true });
    const response = await timesheetApi.attendanceList({
      location: locations.join(','),
      supervisor_confirmed: false,
      status: timesheetEntryStatuses.CLOCKED_OUT,
      page: currentPage,
      page_size: unconfirmedTablePageSize,
      clock_in_time_after: startDate,
      clock_in_time_before: endDate,
      ...prepParams(filters),
    });
    this.setState({
      loading: false,
      unconfirmedTimesheets: response.results,
    });
  };

  // unconfirmed table
  handleViewUnconfirmedTimesheets = () => {
    this.setState({ showUnconfirmedTimesheetsModal: true });
  };

  handleCloseUnconfirmedTimesheets = () => {
    this.setState({ showUnconfirmedTimesheetsModal: false });
  };

  onUnconfirmedTimesheetsTablePageChange = ({ current: page, pageSize }) => {
    this.setState({ currentPage: page, unconfirmedTablePageSize: pageSize }, this.fetchUnconfirmedTimesheets);
  };

  onApproveTimesheetEntry = async timesheetEntryId => {
    const { t, refreshAttendancePageData } = this.props;
    try {
      await timesheetApi.approve(timesheetEntryId);
      this.resetPageData(this.fetchData);
      refreshAttendancePageData();
      message.success(`${t('Successfully approved timesheet entry')} #${timesheetEntryId}`);
    } catch (error) {
      // TODO add more descriptive error message
      message.error(t('Something went wrong. No changes were saved'));
      Sentry.captureException(error);
    }
  };

  onVoidTimesheetEntry = timesheet => {
    this.setState({ showVoidTimesheetEntryModal: true, selectedTimesheetEntry: timesheet });
  };

  onConfirmTimesheetEntry = async timesheetEntryId => {
    const { t, refreshAttendancePageData } = this.props;
    try {
      await timesheetApi.confirm(timesheetEntryId);
      this.resetPageData(this.fetchData);
      refreshAttendancePageData();
      message.success(`${t('confirmTimesheetSuccess')} #${timesheetEntryId}`);
    } catch (error) {
      // TODO change old translation keys to new format
      // TODO add more descriptive error message
      message.error(t('Something went wrong. No changes were saved'));
      Sentry.captureException(error);
    }
  };

  onEditTimesheetEntry = async timesheetEntry => {
    this.setState({ showTimesheetEntryModal: true, selectedTimesheetEntry: timesheetEntry });
  };

  onBulkApproveTimesheetEntries = async () => {
    const { t, refreshAttendancePageData } = this.props;

    const { selectedTimesheets } = this.state;
    try {
      await timesheetApi.bulkApprove({ id: selectedTimesheets.join(',') });
      this.resetPageData(this.fetchData);
      refreshAttendancePageData();
      message.success(`${t('Successfully approved')} ${selectedTimesheets.length} ${t('timesheet entries')}`);
    } catch (error) {
      message.error(t('Something went wrong. No changes were saved'));
      Sentry.captureException(error);
    }
  };

  onBulkConfirmTimesheetEntries = async () => {
    const { t, refreshAttendancePageData } = this.props;

    const { selectedTimesheets } = this.state;
    try {
      await timesheetApi.bulkConfirm({ id: selectedTimesheets.join(',') });
      this.resetPageData(this.fetchData);
      refreshAttendancePageData();
      message.success(t('bulkConfirmSuccess', { number: selectedTimesheets.length }));
    } catch (error) {
      message.error(t('Something went wrong. No changes were saved'));
      Sentry.captureException(error);
    }
  };

  handleSelectedRows = (selectedRowKeys, selectedRows) => {
    const selectedTimesheets = selectedRows.map(entry => entry.timesheetId);
    this.setState({
      selectedTimesheets,
      selectedRowKeys,
      selectedTimesheetsCount: selectedTimesheets.length,
    });
  };

  render() {
    const { t, refreshAttendancePageData, unconfirmedTimesheetsCount } = this.props;
    const {
      loading,
      currentPage,
      showUnconfirmedTimesheetsModal,
      showTimesheetEntryModal,
      unconfirmedTimesheets,
      unconfirmedTablePageSize,
      showVoidTimesheetEntryModal,
      selectedTimesheetsCount,
      selectedRowKeys,
      selectedTimesheets,
      selectedTimesheetEntry,
    } = this.state;

    return (
      <>
        <Typography.Text type="secondary" style={{ fontSize: 12, fontWeight: 600 }}>
          {t('unconfirmedTimesheets').toUpperCase()}
          <Tooltip title={t('unconfirmedTimesheetTooltip')} placement="bottom">
            <QuestionCircleOutlined style={{ marginLeft: '5px', color: colors.darkGrey }} />
          </Tooltip>
        </Typography.Text>
        <Row type="flex" style={{ alignItems: 'center' }}>
          <Typography.Title level={3} style={{ color: colors.yellow }}>
            {loading ? <Spin data-testid="stats-card-spinner" /> : unconfirmedTimesheetsCount}
          </Typography.Title>
          <Button onClick={this.handleViewUnconfirmedTimesheets} size="small" style={{ margin: '0px 0px 12px 8px' }}>
            {t('viewAll')}
          </Button>
        </Row>
        <UnconfirmedTimesheetsTableModal
          loading={loading}
          count={unconfirmedTimesheetsCount}
          visible={showUnconfirmedTimesheetsModal}
          timesheets={unconfirmedTimesheets}
          onCancel={this.handleCloseUnconfirmedTimesheets}
          pageSize={unconfirmedTablePageSize}
          currentPage={currentPage}
          onPageChange={this.onUnconfirmedTimesheetsTablePageChange}
          onEditTimesheetEntry={this.onEditTimesheetEntry}
          onApproveTimesheetEntry={this.onApproveTimesheetEntry}
          onConfirmTimesheetEntry={this.onConfirmTimesheetEntry}
          onVoidTimesheetEntry={this.onVoidTimesheetEntry}
          handleSelectedRows={this.handleSelectedRows}
          selectedTimesheetsCount={selectedTimesheetsCount || undefined}
          selectedRowKeys={selectedRowKeys}
          selectedTimesheets={selectedTimesheets}
          onBulkApproveTimesheetEntries={this.onBulkApproveTimesheetEntries}
          onBulkConfirmTimesheetEntries={this.onBulkConfirmTimesheetEntries}
        />
        {showVoidTimesheetEntryModal && (
          <VoidTimesheetEntryModal
            visible={showVoidTimesheetEntryModal}
            timesheet={selectedTimesheetEntry}
            onCancel={() => {
              this.setState({ showVoidTimesheetEntryModal: false, selectedTimesheetEntry: null });
            }}
            onComplete={() => {
              this.resetPageData(this.fetchData);
              refreshAttendancePageData();
              this.setState({ showVoidTimesheetEntryModal: false, selectedTimesheetEntry: null });
            }}
          />
        )}
        {showTimesheetEntryModal && (
          <EditTimesheetFormModal
            onClose={() => {
              this.setState({
                showTimesheetEntryModal: false,
                selectedTimesheetEntry: null,
              });
            }}
            visible={showTimesheetEntryModal}
            selectedTimesheetEntry={selectedTimesheetEntry}
            onUpdateTimesheet={() => {
              this.resetPageData(this.fetchData);
              refreshAttendancePageData();
              this.setState({
                showTimesheetEntryModal: false,
                selectedTimesheetEntry: null,
              });
            }}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(UnconfirmedTimesheetsSection);
