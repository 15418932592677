import React from 'react';
import { withTranslation } from 'react-i18next';
import background from '../../assets/images/background.svg';

const PublicLayout = props => {
  window.zE('webWidget', 'hide');

  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', height: '100vh' }}>
      {props.children}
    </div>
  );
};

export default withTranslation()(PublicLayout);
