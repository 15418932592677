import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as userActions from '../../redux/user';
import LoginPageView from './LoginPageView';

const LoginPage = props => {
  return <LoginPageView {...props} />;
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  const { login, userUpdateAction } = userActions;
  return {
    handleLogin: (username, password) => {
      dispatch(login(username, password));
    },
    clearError: () => {
      dispatch(userUpdateAction({ error: '' }));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginPage)));
