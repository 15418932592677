import * as Sentry from '@sentry/browser';
import { Row, Typography, Grid, message, Divider, Col, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Icon } from '@ant-design/compatible';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import withQueryAndGraphQLClient from '../../../graphql/helpers/withQueryAndGraphQLClient';
import { GET_SHIFT } from '../../../graphql/queries/shift';
import { attendanceStatuses, staffRequestTabs, TIME_FORMAT_24_HOUR, shiftStatuses } from '../../../constants';
import { colors } from '../../../styles/colors';
import FunnelTabs from './components/FunnelTabs';
import routes from '../../../routes';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import BackButton from '../../../shared/components/BackButton';
import StatusTag from '../../../shared/components/StatusTag';
import DateSelector from './components/DateSelector';
import GigDetailActions from './components/GigDetailActions';
import ShiftCancellationModal from './components/ShiftCancellationModal';
import shiftApi from '../../../services/shiftApi';
import VipWorkersOnlyTooltip from '../components/VipWorkersOnlyTooltip';

const { Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const StaffRequestGigDetailView = ({ queryClient, graphQLClient, match, history, isOnWebView }) => {
  const { id, tab } = match.params;
  const [loading, setLoading] = useState(true);
  const [shift, setShift] = useState(undefined);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [showShiftCancellationModal, setShowShiftCancellationModal] = useState(false);
  const [activeTab, setActiveTab] = useState(tab || staffRequestTabs.ACCEPTED);
  const [shiftIds, setShiftIds] = useState([]);

  const { t } = useTranslation();
  const { xs } = useBreakpoint();

  const fetchGig = async () => {
    try {
      setLoading(true);
      const result = await queryClient.fetchQuery(['shifts'], () =>
        graphQLClient.request({
          document: GET_SHIFT,
          variables: {
            shiftId: Number(id),
          },
        }),
      );
      if (result?.shift) {
        const response = await shiftApi.fetchShifts({ staff_request: result.shift?.staffRequestId });
        setShiftIds(response.results.map(shiftItem => shiftItem.id));
        setShift(result.shift);
      }
    } catch (error) {
      Sentry.captureException(error);
      message.error(t('Sorry there is an error'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGig();
  }, [id]);

  const formatWorkerStatistics = (workers = []) => {
    return workers.map(worker => ({
      ...worker,
      statistics: worker.statistics.length > 0 ? { ...worker.statistics[0], totalRating: worker.shiftsRating } : {},
    }));
  };

  const handleTabChange = selectedTab => {
    setActiveTab(selectedTab);
    if (!isOnWebView) {
      history.push(routes.gigDetail.replace(':id', id).replace(':tab', selectedTab));
    }
  };

  const showWorkerAlreadyAcceptModal = () => {
    Modal.confirm({
      title: <strong>{t('wereUnableToCancelShift')}</strong>,
      icon: <InfoCircleOutlined style={{ color: colors.functionalLink }} />,
      content: t('workerAlreadyAcceptShift'),
      okButtonProps: { style: { display: 'none' } },
      cancelText: t('back'),
    });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const getShiftStatus = () => {
    if (shift.status) {
      return shift.status.toLowerCase();
    }
    const isAlreadyStarted = moment(shift.startTime).isBefore(moment());
    if (isAlreadyStarted) {
      return shiftStatuses.IN_PROGRESS;
    }
    return shiftStatuses.POSTED;
  };

  const shiftStatus = getShiftStatus();

  const shiftTime = `${moment(shift.startTime).format(TIME_FORMAT_24_HOUR)} - ${moment(shift.endTime).format(
    TIME_FORMAT_24_HOUR,
  )}`;

  const workers = shift?.attendances.map(attendance => ({
    ...attendance.partner,
    acceptedDate: attendance.createdDate,
    status: attendance.status,
  }));

  const acceptedWorkers = formatWorkerStatistics(
    sortBy(
      workers.filter(worker => worker.status.toLowerCase() === attendanceStatuses.ASSIGNED),
      'acceptedDate',
    ),
  );
  const cancelledWorkers = formatWorkerStatistics(
    workers?.filter(worker =>
      [attendanceStatuses.UNASSIGNED, attendanceStatuses.CANCELLED].includes(worker.status.toLowerCase()),
    ),
  );

  return (
    <>
      {/* Shift details section */}
      <section
        style={{
          boxShadow: '0 4px 2px -2px rgba(0, 0, 0, 0.15)',
          padding: xs ? 16 : '16px 0',
          margin: (isOnWebView && '-30px -18px 20px -18px') || (xs && '-60px -18px 20px -18px') || '0 0 20px 0',
        }}
      >
        {!isOnWebView && (
          <BackButton title={t('backToJobs')} onClick={() => history.push(`${routes.staffRequests}?tab=gigs`)} />
        )}
        <Row>
          <Col xs={24} lg={18}>
            <Row style={{ alignItems: 'center', marginBottom: 8 }}>
              {shift?.staffRequest?.vipWorkersOnly && (
                <VipWorkersOnlyTooltip
                  staffRequest={shift?.staffRequest}
                  iconContainerStyle={{ marginRight: 6 }}
                  tooltipProps={{ align: { offset: [10, 5] } }}
                />
              )}
              <Paragraph style={{ fontSize: 20, fontWeight: '600', marginBottom: 0 }}>
                {shift?.staffRequest?.title}
              </Paragraph>
            </Row>
            <Row style={{ alignItems: 'center', marginBottom: 6 }}>
              <Icon type="bars" style={{ color: '#BFBFBF', marginRight: '8px' }} />
              <Text style={{ color: colors.primaryText }}>{shift?.position?.name}</Text>
              <Text style={{ padding: '0px 4px', color: colors.primaryText }}> · </Text>
              <Text style={{ color: colors.primaryText }}>
                {t('jobId')}: {shift?.staffRequestId}
              </Text>
            </Row>
            <Row style={{ marginBottom: 6 }}>
              <Col>
                <Icon type="environment" style={{ color: '#BFBFBF', marginRight: '8px' }} />
              </Col>
              <Col style={{ flex: 1 }}>
                <Text style={{ color: colors.primaryText }}>{shift?.location?.name}</Text>
                <Text style={{ padding: '0px 4px', color: colors.primaryText }}> · </Text>
                <Text style={{ color: colors.primaryText }}>{shiftTime}</Text>
              </Col>
            </Row>

            <StatusTag status={shiftStatus} style={{ marginRight: '8px' }} />
            <Text type="secondary" style={{ fontSize: '12px' }}>
              {t('Created on')} {moment(shift?.staffRequest?.createdDate).format('DD MMM YYYY')}
            </Text>
          </Col>
          <Col xs={24} lg={6}>
            <GigDetailActions
              acceptedWorkersCount={acceptedWorkers.length}
              shift={shift}
              onClickShowDetails={() => setShowDescriptionModal(true)}
              onClickCancel={() =>
                acceptedWorkers.length > 0 ? showWorkerAlreadyAcceptModal() : setShowShiftCancellationModal(true)
              }
            />
          </Col>
        </Row>
      </section>
      {/* Date selector section */}
      <section style={{ margin: '16px 0' }}>
        <DateSelector shiftDate={shift.startTime} shiftIds={shiftIds} currentShiftId={shift?.id} history={history} />
      </section>
      <Divider style={{ marginTop: 0 }} />
      {/* Funnel tabs section */}
      <section style={{ marginBottom: 24 }}>
        <FunnelTabs
          currentActiveTabKey={activeTab}
          waitlistedCount={shift?.waitlistedCount}
          acceptedCount={acceptedWorkers.length}
          cancelledCount={cancelledWorkers.length}
          onTabChange={handleTabChange}
          acceptedWorkers={acceptedWorkers}
          cancelledWorkers={cancelledWorkers}
          shift={shift}
          shiftTime={shiftTime}
        />
      </section>
      {/* Modals */}
      {showDescriptionModal && (
        <Modal
          centered
          title={shift?.staffRequest?.title}
          visible={showDescriptionModal}
          onCancel={() => setShowDescriptionModal(false)}
          footer={null}
          style={{ whiteSpace: 'pre-line' }}
        >
          {shift?.staffRequest?.description}
        </Modal>
      )}
      {showShiftCancellationModal && (
        <ShiftCancellationModal
          shift={shift}
          visible={showShiftCancellationModal}
          onClose={() => setShowShiftCancellationModal(false)}
          onSuccess={fetchGig}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    isOnWebView: state.global.isOnWebView,
  };
};

export default connect(mapStateToProps)(withQueryAndGraphQLClient(StaffRequestGigDetailView));
