import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const ToolTipWithInfo = ({ infoText, color = '#8C8C8C' }) => {
  return (
    <Tooltip title={infoText}>
      <InfoCircleOutlined style={{ fontSize: '18px', cursor: 'auto', color }} />
    </Tooltip>
  );
};

export default ToolTipWithInfo;
