import React from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Row } from 'antd';

import BrandLogo from '../components/BrandLogo';
import routes from '../../routes';

const { Header, Content } = Layout;

const PublicContentLayout = props => {
  // Hide zendesk widget which is displayed in every page by default
  window.zE('webWidget', 'hide');
  return (
    <Layout style={{ backgroundColor: 'white', height: '100%' }}>
      <Header>
        <Row type="flex" style={{ height: '64px' }}>
          <BrandLogo style={{ marginRight: '16px', width: '120px' }} onClick={() => props.history.push(routes.home)} />
        </Row>
      </Header>

      <Content style={{ padding: '86px 0 250px 0', width: '570px', margin: 'auto' }}>{props.children}</Content>
    </Layout>
  );
};

export default withRouter(PublicContentLayout);
