import moment from 'moment';
import { orderBy } from 'lodash';

import { staffRequestContractStatus } from '../constants';

const getCurrentActiveContract = contracts => {
  // Get current active latest end contract
  const activeContracts = contracts.filter(
    contract =>
      moment().isBetween(moment(contract.start_date), moment(contract.end_date), undefined, '[]') &&
      contract.status === staffRequestContractStatus.SIGNED,
  );
  return orderBy(activeContracts, ['end_date'], ['desc'])[0];
};

const getLatestExpiredContract = contracts => {
  // Get last signed contract that has expired
  const signedContracts = contracts.filter(
    contract => contract.status === staffRequestContractStatus.SIGNED && moment(contract.end_date).isBefore(moment()),
  );
  return orderBy(signedContracts, ['end_date'], ['desc'])[0];
};

const getNextSignedContract = contracts => {
  // Get earliest start future signed contract
  const signedContracts = contracts.filter(
    contract => moment().isBefore(contract.start_date) && contract.status === staffRequestContractStatus.SIGNED,
  );
  return orderBy(signedContracts, ['start_date'])[0];
};

const getNextContract = contracts => {
  // Get earliest start future contract
  const nextContracts = contracts.filter(contract => moment().isBefore(contract.start_date));
  return orderBy(nextContracts, ['start_date'])[0];
};

const getUnsignedContract = contracts => {
  // Get earliest start unsigned contract
  const unsignedContracts = contracts.filter(
    contract => contract.status === staffRequestContractStatus.PENDING_SIGNATURE,
  );
  return orderBy(unsignedContracts, ['start_date'])[0];
};

const getUnsignedContracts = contracts => {
  const unsignedContracts = contracts.filter(
    contract => contract.status === staffRequestContractStatus.PENDING_SIGNATURE,
  );
  return orderBy(unsignedContracts, ['start_date']);
};

export default {
  getUnsignedContract,
  getUnsignedContracts,
  getCurrentActiveContract,
  getLatestExpiredContract,
  getNextSignedContract,
  getNextContract,
};
