import React from 'react';
import { withTranslation } from 'react-i18next';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Row, Col, Typography, Button } from 'antd';

import { colors } from '../../../../styles/colors';

const { Text } = Typography;

const CompletedPrepaidDisplay = ({ t }) => {
  const InfoIcon = <CheckCircleTwoTone twoToneColor={colors.brandGreen} style={{ fontSize: '20px' }} />;

  return (
    <Row style={{ display: 'flex' }}>
      <Col flex="21px" style={{ paddingTop: '4px' }}>
        {InfoIcon}
      </Col>
      <Col flex="auto" style={{ marginLeft: 8 }}>
        <Row style={{ paddingTop: 2 }}>
          <Text strong>{t('Prepaid bank transfer')}</Text>
        </Row>
        <Row>
          {t(
            'Your account is approved for prepaid bank transfers. Make payments through your preferred online banking method or ATM.',
          )}
        </Row>
        <Row style={{ marginTop: 13 }}>
          {t('To change your payment method, please call our')}{' '}
          <a target="_blank" rel="noopener noreferrer" href={`${window.location.origin}/help/support/contact-us`}>
            {t('Customer Success team')}
          </a>{' '}
          {t('or')}{' '}
          <Button
            type="link"
            style={{ padding: 0, margin: 0, height: 0 }}
            onClick={() => {
              window.zE.activate({ hideOnClose: true });
            }}
          >
            {t('chat with us')}
          </Button>
          .
        </Row>
      </Col>
    </Row>
  );
};

export default withTranslation()(CompletedPrepaidDisplay);
