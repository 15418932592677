import authApi from '../services/authApi';
import clientApi from '../services/clientApi';
import { roles, LARGE_DATASET_PAGESIZE, sessionStorageKeys, localStorageKeys } from '../constants';
import managersApi from '../services/managersApi';

// Actions
const LOGOUT = 'USER_LOGOUT_ACTION'; // Has to be consistent with ./index.js
const UPDATE = 'USER_UPDATE_ACTION';

// Initial State
const userInitialState = {
  hasLoaded: false,
  isLoading: false,
  userId: '',
  uuid: '',
  username: '',
  error: '',
  source: '',
  userManagers: [], // all managers that belong to user
  // Client object fields
  clientId: '',
  country: {},
  description: '',
  locations: [],
  roles: [],
  logo: '',
  managers: [],
  name: '',
  status: '',
  website: '',
  type: '',
  // Client billing/legal fields
  legalBreakDuration: undefined,
  legalName: '',
  billingAddress: {},
  businessNumber: '',
  taxRegistrationNumber: '',
  billingContactName: '',
  billingContactEmail: '',
  billingContactPhone: '',
  signatureRequestId: '',
  // Client payment fields
  paymentMethod: '',
  paymentService: '',
  paymentCustomerId: '',
  cardLastDigits: '',
  cardExpMonth: '',
  cardExpYear: '',
  cardHolderName: '',
  cardBrand: '',
  // TODO: The below fields are repeated from the manager object in 'managers' above.
  // Manager object fields
  managerId: '',
  managerName: '',
  role: '',
  chatEnabled: false,
};

// Reducer
export default (state = userInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGOUT:
      return Object.assign({}, state, { isLoggedIn: false });
    case UPDATE:
      return Object.assign({}, state, { ...payload });
    default:
      return state;
  }
};

// Action Creators
export const logoutAction = () => {
  return { type: LOGOUT };
};

export const userUpdateAction = user => {
  return { type: UPDATE, payload: user };
};

// Helper functions
const getManagerAndLocations = (managers, userId, clientLocationList) => {
  const manager = managers.find(({ user }) => user === userId);
  if (!manager) {
    return { manager: undefined, locations: undefined };
  }
  // If user is a supervisor, he can only view locations he has permissions to.
  let locations = [];
  if (manager.role === roles.SUPERVISOR) {
    locations = clientLocationList.filter(location => {
      if (manager.locations.includes(location.id)) {
        return true;
      }
      return false;
    });
  } else {
    locations = clientLocationList;
  }
  return { manager, locations };
};

// Heavy-Weight Logic
export const userUpdate = updatedUser => {
  return dispatch => {
    dispatch(userUpdateAction(updatedUser));
  };
};

export const fetchClientAndManager = () => {
  return async (dispatch, getState) => {
    try {
      // Parsing data from API
      dispatch(userUpdateAction({ isLoading: true }));
      const user = await authApi.fetchUser();

      // TODO: move manager fetching into seperate redux action
      let { userManagers } = getState().user;
      let managerId;
      managerId = sessionStorage.getItem(sessionStorageKeys.MANAGER_ID);

      if (!managerId) {
        // to persist manager sessions when opening in a new tab,
        // we can take the manager_id from localstorage, which is set
        // upon app refocus from the current session storage in authLayout.js.
        managerId = localStorage.getItem(localStorageKeys.MANAGER_ID);
        if (managerId) sessionStorage.setItem(sessionStorageKeys.MANAGER_ID, managerId);
      }
      if (userManagers.length === 0) {
        userManagers = (
          await managersApi.fetchManagers({
            ordering: 'client__name',
            page_size: LARGE_DATASET_PAGESIZE,
          })
        ).results;
        // if no managerId from session storage or previous tab session, we default to first manager
        if (!managerId) {
          sessionStorage.setItem(sessionStorageKeys.MANAGER_ID, userManagers[0].id);
          localStorage.setItem(localStorageKeys.MANAGER_ID, userManagers[0].id);
        }
      }

      const clientResponse = await clientApi.fetchClient({
        ordering: 'name',
      });
      if (clientResponse.results.length === 0) {
        dispatch(
          userUpdateAction({
            uuid: user.uuid,
            username: user.username,
            hasLoaded: true,
            isLoading: false,
            userId: user.id,
          }),
        );
      } else {
        const {
          id,
          created_date,
          country,
          description,
          logo,
          managers,
          name,
          legal_name,
          status,
          website,
          source,
          // Billing/Legal fields
          legal_break_duration, // Only applicable to ID clients
          billing_address,
          business_number,
          tax_registration_number,
          billing_contact_name,
          billing_contact_email,
          billing_contact_phone,
          // Payment fields
          payment_method,
          payment_service,
          payment_customer_id,
          card_last_digits,
          card_exp_month,
          card_exp_year,
          card_holder_name,
          card_brand,
          // Contract fields
          signature_request_id,
          chat_enabled,
          leave_enabled,
          time_rounding_rules,
          pricing,
          type,
          stripe_customer_id,
          gig_contract_type,
          enable_vip_worker_job_posting,
        } = clientResponse.results[0];
        const { manager, locations } = getManagerAndLocations(managers, user.id, clientResponse.results[0].locations);

        if (!manager) {
          dispatch(
            userUpdateAction({
              isLoading: false,
              hasLoaded: true,
              userId: user.id,
              uuid: user.uuid,
              username: user.username,
              clientId: id,
              managerId: undefined,
            }),
          );
          return;
        }

        const rolesResponse = await clientApi.fetchRoles(id, { page_size: LARGE_DATASET_PAGESIZE });
        const timesheetTagCategoriesResponse = await clientApi.fetchTimesheetTagCategories(id);

        // this is appended to our base api calls as a query_param to identify requesting manager
        sessionStorage.setItem(sessionStorageKeys.MANAGER_ID, manager.id);

        // Update redux store with parsed data
        dispatch(
          userUpdateAction({
            userManagers,
            uuid: user.uuid,
            country,
            description,
            locations,
            roles: rolesResponse.results,
            timesheetTagCategories: timesheetTagCategoriesResponse.results,
            logo,
            managers,
            name,
            status,
            website,
            clientId: id, // Id of client (Business) e,g Taco bell
            username: user.username,
            clientCreatedDate: created_date,
            isLoading: false,
            hasLoaded: true,
            error: '',
            source,
            // Billing/Legal fields
            legalBreakDuration: legal_break_duration,
            legalName: legal_name,
            billingAddress: billing_address,
            businessNumber: business_number,
            taxRegistrationNumber: tax_registration_number,
            billingContactName: billing_contact_name,
            billingContactEmail: billing_contact_email,
            billingContactPhone: billing_contact_phone,
            // Payment fields
            paymentMethod: payment_method,
            paymentService: payment_service,
            paymentCustomerId: payment_customer_id,
            cardLastDigits: card_last_digits,
            cardExpMonth: card_exp_month,
            cardExpYear: card_exp_year,
            cardHolderName: card_holder_name,
            cardBrand: card_brand,
            // Contract fields
            signatureRequestId: signature_request_id,
            // Manager fields
            managerName: manager.name,
            managerId: manager.id,
            role: manager.role,
            accessAllowed: manager.access_client_dashboard,
            userId: user.id,
            chatEnabled: chat_enabled,
            leaveEnabled: leave_enabled,
            timeRoundingRules: time_rounding_rules,
            pricing,
            type,
            stripe_customer_id,
            gig_contract_type,
            vipWorkerJobPosting: enable_vip_worker_job_posting,
          }),
        );
      }
    } catch (error) {
      dispatch(userUpdateAction({ isLoading: false, error: error.message }));
    }
  };
};

export const login = (username, password) => {
  return async dispatch => {
    dispatch(userUpdateAction({ isLoading: true }));
    try {
      // Parsing data from API
      const authResponse = await authApi.login(username, password);
      dispatch(userUpdateAction({ username, userId: authResponse.id }));
      dispatch(fetchClientAndManager());
    } catch (error) {
      dispatch(userUpdateAction({ isLoading: false, error: error.message }));
    }
  };
};

export const logout = () => {
  return async dispatch => {
    try {
      await authApi.logout();
      dispatch(logoutAction());
    } catch (error) {
      dispatch(userUpdateAction({ error: error.message }));
    }
  };
};
