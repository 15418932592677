import { endpoints } from '../config';
import base from './base';

const fetchManagers = async (params = {}) => {
  const response = await base.api.get(`${endpoints.managers}/`, { params });
  return response;
};

const createManager = async manager => {
  const response = await base.api.post(`${endpoints.managers}/`, manager);
  return response;
};

const updateManager = async manager => {
  const response = await base.api.put(`${endpoints.managers}/${manager.id}/`, manager);
  return response;
};

const deleteManager = async manager => {
  const response = await base.api.delete(`${endpoints.managers}/${manager.id}/`, manager);
  return response;
};

const reactivateManager = async manager => {
  const response = await base.api.post(`${endpoints.managers}/reactivate/`, manager);
  return response;
};

const setPassword = async (managerId, password) => {
  const response = await base.api.post(`${endpoints.managers}/${managerId}/set-password/`, { password });
  return response;
};

const checkExists = async username => {
  const response = await base.api.post(`${endpoints.user}/check-exists/`, { username });
  return response;
};

export default {
  fetchManagers,
  createManager,
  updateManager,
  deleteManager,
  reactivateManager,
  setPassword,
  checkExists,
};
