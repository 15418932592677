import React from 'react';
import { withTranslation } from 'react-i18next';
import { Typography } from 'antd';

import EmptyCard from './EmptyCard';
import NoSearchLogo from '../../assets/images/no_search_result.svg';

const { Text } = Typography;
const EmptySearchResult = ({ t }) => {
  return (
    <EmptyCard
      imgSrc={NoSearchLogo}
      title={<Text type="secondary">{t('searchReturnedNoResult')}</Text>}
      description={t('changeSearchCriteria')}
    />
  );
};

export default withTranslation()(EmptySearchResult);
