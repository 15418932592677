import React from 'react';
import moment from 'moment-timezone';
import { Button, Row, Col, Typography, message, Select } from 'antd';

import i18n from '../../i18n';

const { Option } = Select;

const { Title, Text } = Typography;
class LanguageSection extends React.Component {
  state = {
    isLoading: false,
    language: localStorage.getItem('i18nextLng'),
  };

  handleSave = () => {
    this.setState({ isLoading: true });
    const { fetchPositions, t } = this.props;
    const { language } = this.state;

    i18n.changeLanguage(language, () => moment.locale(language));
    window.location.reload(true);
    fetchPositions();
    message.success(t('Language updated'));
    this.setState({ isLoading: false });
  };

  render() {
    const { isLoading, language } = this.state;
    const { t } = this.props;
    return (
      <>
        <Row style={{ marginTop: '14px', marginBottom: '24px' }}>
          <Col span={18}>
            <Title level={3}>{t('Language')}</Title>
            <Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
              {t(`Choose the default language you’d like to use with Workmate.`)}
            </Text>
          </Col>
        </Row>
        <Row style={{ marginBottom: '4px' }}>
          <Text>{t('Language')}</Text>
        </Row>
        <Row style={{ marginBottom: '48px' }}>
          <Select
            defaultValue={language}
            style={{ width: '220px' }}
            onChange={value => this.setState({ language: value })}
            loading={isLoading}
          >
            <Option value="en">English</Option>
            <Option value="th">ไทย</Option>
            <Option value="id">Bahasa Indonesia</Option>
          </Select>
        </Row>
        <Button
          type="v2-primary"
          htmlType="submit"
          loading={isLoading}
          onClick={this.handleSave}
          style={{ width: '120px', marginBottom: '24px' }}
        >
          {t('Save')}
        </Button>
      </>
    );
  }
}

export default LanguageSection;
