import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as userActions from '../../redux/user';
import routes from '../../routes';

const SignUpPage = props => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializePage = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        props.history.push(routes.staffRequests);
        return;
      }
      setLoading(false);
    };
    initializePage();
  }, []);

  if (loading) return null;

  window.location.replace('https://business.workmate.asia/en/lets-talk');
  return null;
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  const { fetchClientAndManager } = userActions;
  return {
    fetchClientAndManager: () => {
      dispatch(fetchClientAndManager());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SignUpPage));
