import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Select } from 'antd';
import { withTranslation } from 'react-i18next';

import locationApi from '../../services/locationApi';
import ExtensionPrefixSelector from './ExtensionPrefixSelector';

const { Option } = Select;

class BillingForm extends React.Component {
  state = {
    countries: [],
    prefixes: [],
  };

  async componentDidMount() {
    const countries = await locationApi.fetchCountries();
    this.setState({
      countries,
      prefixes: countries.map(country => country.dial_code),
    });
    this.props.setBillingForm(this.props.form);
  }

  render() {
    const { isAdmin, client, t } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { countries, prefixes } = this.state;
    const prefixInitialValue = client.billingContactPhone && client.billingContactPhone.slice(0, 3);
    const prefixSelector = ExtensionPrefixSelector(getFieldDecorator, prefixes, prefixInitialValue, 'prefix');
    return (
      <Form layout="vertical" hideRequiredMark className="v2-form-wrapper">
        {/* Company legal section */}
        <section style={{ marginBottom: '32px' }}>
          <Form.Item hasFeedback label={t('Registered business name')} colon={false} style={{ width: '100' }}>
            {getFieldDecorator('legal_name', {
              rules: [{ required: true, message: t('fieldRequired.') }],
              initialValue: client.legalName,
            })(<Input disabled={!isAdmin} />)}
          </Form.Item>

          <Row type="flex" gutter={24}>
            <Col span={12}>
              <Form.Item hasFeedback label={t('Registered business number')} colon={false} style={{ width: '100%' }}>
                {getFieldDecorator('business_number', {
                  rules: [{ required: true, message: t('fieldRequired.') }],
                  initialValue: client.businessNumber,
                })(<Input disabled={!isAdmin} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item hasFeedback label={t('Tax (VAT) registration number')} colon={false} style={{ width: '100%' }}>
                {getFieldDecorator('tax_registration_number', {
                  rules: [{ required: true, message: t('fieldRequired.') }],
                  initialValue: client.taxRegistrationNumber,
                })(<Input disabled={!isAdmin} />)}
              </Form.Item>
            </Col>
          </Row>
        </section>

        {/* Billing contact */}
        <section style={{ marginBottom: '32px' }}>
          <Form.Item hasFeedback label={t('Billing contact name')} colon={false} style={{ width: '100%' }}>
            {getFieldDecorator('billing_contact_name', {
              rules: [{ required: true, message: t('fieldRequired.') }],
              initialValue: client.billingContactName,
            })(<Input disabled={!isAdmin} />)}
          </Form.Item>

          <Row type="flex" gutter={24}>
            <Col span={12}>
              <Form.Item hasFeedback label={t('Billing contact email')} colon={false} style={{ width: '100%' }}>
                {getFieldDecorator('billing_contact_email', {
                  rules: [
                    { required: true, message: t('fieldRequired.') },
                    { type: 'email', message: t('This is not valid email.') },
                  ],
                  initialValue: client.billingContactEmail,
                })(<Input disabled={!isAdmin} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item hasFeedback label={t('Phone number')} colon={false} style={{ width: '100%' }}>
                {getFieldDecorator('billing_contact_phone', {
                  rules: [{ required: true, message: t('fieldRequired.') }],
                  initialValue: client.billingContactPhone && client.billingContactPhone.slice(3),
                })(<Input addonBefore={prefixSelector} disabled={!isAdmin} />)}
              </Form.Item>
            </Col>
          </Row>
        </section>

        {/* Billing address */}
        <section>
          <Form.Item
            hasFeedback
            label={t('Billing address line 1')}
            colon={false}
            style={{ width: '100%', marginBottom: '16px' }}
          >
            {getFieldDecorator('street_1', {
              rules: [{ required: true, message: t('fieldRequired.') }],
              initialValue: client.billingAddress && client.billingAddress.street_1,
            })(<Input disabled={!isAdmin} placeholder="Street address and number" />)}
          </Form.Item>

          <Form.Item
            hasFeedback
            label={t('Billing address line 2 (optional)')}
            colon={false}
            style={{ width: '100%', marginBottom: '16px' }}
          >
            {getFieldDecorator('street_2', {
              initialValue: client.billingAddress && client.billingAddress.street_2,
            })(<Input disabled={!isAdmin} placeholder="Unit, building, floor etc." />)}
          </Form.Item>

          <Row style={{ marginBottom: '16px' }} gutter={24}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                label={t('Province')}
                colon={false}
                style={{ marginBottom: '16px', width: '100%' }}
              >
                {getFieldDecorator('province', {
                  rules: [{ required: true, message: t('fieldRequired.') }],
                  initialValue: client.billingAddress && client.billingAddress.province,
                })(<Input disabled={!isAdmin} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hasFeedback
                label={t('Postcode')}
                colon={false}
                style={{ marginBottom: '16px', width: '100%' }}
              >
                {getFieldDecorator('zip', {
                  rules: [{ required: true, message: t('fieldRequired.') }],
                  initialValue: client.billingAddress && client.billingAddress.zip,
                })(<Input disabled={!isAdmin} />)}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item hasFeedback label={t('Country')} required={false} colon={false} style={{ width: '100%' }}>
                {getFieldDecorator('country', {
                  rules: [{ required: true, message: t('fieldRequired.') }],
                  initialValue:
                    client.billingAddress && client.billingAddress.country && client.billingAddress.country.id,
                })(
                  <Select placeholder={t('Select one...')} disabled={!isAdmin}>
                    {countries.map(country => (
                      <Option key={country.id} value={country.id}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>
        </section>
      </Form>
    );
  }
}

export default Form.create({ name: 'Billing form' })(withTranslation()(BillingForm));
