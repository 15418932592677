import React from 'react';
import { Row, Typography } from 'antd';
import { withTranslation } from 'react-i18next';

const TimesheetItemTooltip = ({ t, roundingMinutes, recordedClockInOut }) => (
  <>
    <Row>
      <Typography.Text style={{ fontWeight: 'bold' }}>{t('clockInPolicy')}</Typography.Text>
    </Row>
    <Row style={{ marginTop: 8, flexDirection: 'column' }}>
      <Typography.Text>{t('clockIn')}</Typography.Text>
      <Typography.Text>{t('clockInNearestMinute', { minutes: roundingMinutes })}</Typography.Text>
    </Row>
    <Row style={{ flexDirection: 'column' }}>
      <Typography.Text>{t('clockOut')}</Typography.Text>
      <Typography.Text>{t('clockOutNearestMinute', { minutes: roundingMinutes })}</Typography.Text>
    </Row>
    <Row style={{ marginTop: 8 }}>
      <Typography.Text>
        <Typography.Text>{recordedClockInOut}</Typography.Text>
      </Typography.Text>
    </Row>
  </>
);

export default withTranslation()(TimesheetItemTooltip);
