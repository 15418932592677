/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { Col, ConfigProvider, Input, Row, Select, Typography, Divider } from 'antd';
import { toInteger, cloneDeep, sortBy } from 'lodash';
import queryString from 'query-string';
import * as chatActions from '../../redux/chat';
import chatRoutes from '../../containers/Chat/routes';
import staffRequestApi from '../../services/staffRequestApi';
import getPaginationConfig from '../../utilities/getPaginationConfig';
import {
  staffRequestStatuses,
  seenModals,
  NEW_MESSAGES_EMAIL,
  TOTAL_UNREAD_CHATS,
  settingsTabs,
  userStatuses,
  employmentTypes,
} from '../../constants';
import routes from '../../routes';
import SubmittedSRModal from './components/SubmittedSRModal';
import EmptyStaffRequestList from './components/EmptyStaffRequestList';
import StaffRequestList from './components/StaffRequestList';
import TreeCheckboxFilter from '../../shared/components/TreeCheckboxFilter';
import EmptySearchResult from '../../shared/components/EmptySearchResult';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import CheckboxFilter from '../../shared/components/CheckboxFilter';
import CardWithCount from '../../shared/components/CardWithCount';
import FilterWithSearch from '../../shared/components/FilterWithSearch';
import { retrieveParamsFromUrl, updateUrlWithParams } from '../../utilities/urlUtils';
import staffRequestUtils from '../../utilities/staffRequestUtils';

const { Text } = Typography;
const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_STATUS_FILTER = Object.values(staffRequestStatuses)
  .filter(value => ![staffRequestStatuses.ENDED, staffRequestStatuses.CANCELLED].includes(value))
  .join(',');

class StaffRequestListView extends React.Component {
  state = {
    isLoading: false,
    hasFetchedStaffRequests: false,
    staffRequests: undefined,
    showSubmittedSrModal: Boolean(localStorage.getItem(seenModals.SHOW_SUBMITTED_SR)),
    numRecords: 0, // Total SR with current search params - for pagination
    numDraft: 0,
    numPending: 0,
    numPosted: 0,
    numInProgress: 0,
    searchParams: {
      ordering: '-created_date',
      page: 1,
      page_size: DEFAULT_PAGE_SIZE,
      position_name: undefined,
      search: undefined,
      status: DEFAULT_STATUS_FILTER,
      location: [],
      employment_type: [],
    },
  };

  async componentDidMount() {
    const pageParams = queryString.parse(this.props.location.search);

    if (NEW_MESSAGES_EMAIL in pageParams && TOTAL_UNREAD_CHATS in pageParams) {
      this.props.openChatList();
    }

    this.setState({ isLoading: true });
    this.updateStateFromParams(async () => {
      this.updateParamsFromState();
      try {
        const updatedSearchParams = this.updateSearchParamStructure();
        const staffRequestResponse = await staffRequestApi.fetchStaffRequestSummarysWithTasks({
          ...updatedSearchParams,
          include_counts: true,
        });
        const { status_counts, results, count } = staffRequestResponse;
        this.setState({
          staffRequests: results,
          numTotal: count,
          numRecords: count,
          numDraft: status_counts.draft,
          numPending: status_counts.pending_review,
          numPosted: status_counts.posted,
          numInProgress: status_counts.in_progress,
          numEnded: status_counts.ended,
          numCancelled: status_counts.cancelled,
          isLoading: false,
          hasFetchedStaffRequests: true,
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    });
  }

  updateStateFromParams = async (callback = () => {}) => {
    const { searchParams } = this.state;
    const {
      ordering,
      page,
      page_size,
      position_name,
      location,
      search,
      status,
      employment_type,
      total_new_applications,
    } = retrieveParamsFromUrl(this.props.location.search);
    const newStatus = total_new_applications
      ? `${staffRequestStatuses.IN_PROGRESS},${staffRequestStatuses.POSTED}`
      : status;

    this.setState(
      {
        searchParams: {
          ...searchParams,
          ordering: ordering || searchParams.ordering,
          page: toInteger(page || searchParams.page),
          page_size: toInteger(page_size || searchParams.page_size),
          position_name: position_name || '',
          search: search || '',
          status: newStatus || searchParams.status,
          location: (location && location.split(',')) || searchParams.location,
          employment_type: (employment_type && employment_type.split(',')) || searchParams.employment_type,
          total_new_applications,
        },
      },
      callback,
    );
  };

  updateParamsFromState = () => {
    const { searchParams } = this.state;
    const { location, employment_type } = searchParams;
    const updatedSearchParams = cloneDeep(this.state.searchParams);
    updatedSearchParams.location = (location && location.join(',')) || undefined;
    updatedSearchParams.employment_type = (employment_type && employment_type.join(',')) || undefined;
    updateUrlWithParams({ ...updatedSearchParams }, this.props.history);
  };

  goToCreateSr = status => {
    // TODO remove this line after SG office is closed
    if (status !== userStatuses.LEAD || this.props.user.country.id !== 3) {
      this.props.history.push(routes.createStaffRequest);
    }
  };

  handleCloseSubmittedSRModal = () => {
    localStorage.removeItem(seenModals.SHOW_SUBMITTED_SR);
    this.setState({ showSubmittedSrModal: false });
  };

  handleAllFilterSelectChange = (field, checkedValues, allOptions) => {
    const allOptionsSelected = checkedValues.length === allOptions.length;
    const { searchParams } = this.state;
    const nextSearchParams = searchParams;
    // De-select all
    if (allOptionsSelected) {
      nextSearchParams[field] = undefined;
    } else {
      // Select all available options
      nextSearchParams[field] = allOptions.map(({ id }) => id).join(',');
    }
    this.setState({ searchParams: nextSearchParams });
  };

  getStatusFilter = () => {
    const { t } = this.props;
    const partnerStatusOptions = [
      { id: staffRequestStatuses.DRAFT, name: t('Draft') },
      { id: staffRequestStatuses.PENDING_REVIEW, name: t('reviewing') },
      { id: staffRequestStatuses.POSTED, name: t('Posted') },
      { id: staffRequestStatuses.IN_PROGRESS, name: t('In progress') },
      { id: staffRequestStatuses.ENDED, name: t('Ended') },
      { id: staffRequestStatuses.CANCELLED, name: t('Cancelled') },
    ];
    const { searchParams } = this.state;
    const { status } = searchParams;
    const statusCheckedList = status ? status.split(',') : [];
    return {
      title: t('status'),
      selectAllTitle: t('allStatus'),
      selectedOptions: statusCheckedList,
      options: partnerStatusOptions,
      FilterComponent: CheckboxFilter,
      onChange: values => {
        const joinedValues = values.join(',');
        this.setState(prevState => {
          const nextSearchParams = prevState.searchParams;
          nextSearchParams.status = joinedValues;
          return { searchParams: nextSearchParams };
        });
      },
      onCheckAllChange: () => this.handleAllFilterSelectChange('status', statusCheckedList, partnerStatusOptions),
      onApply: () => {
        this.setState(
          prevState => {
            const nextSearchParams = prevState.searchParams;
            nextSearchParams.page = 1;
            return { searchParams: nextSearchParams };
          },
          () => this.fetchStaffRequestWithParams(),
        );
      },
    };
  };

  getStatusCounts = () => {
    const { numDraft, numPending, numPosted, numInProgress } = this.state;
    const { t } = this.props;
    return [
      {
        title: t('Draft'),
        num: numDraft,
        hasBadge: false,
        onClick: () => {
          this.onSearchParamChange('status', staffRequestStatuses.DRAFT);
        },
      },
      {
        title: t('reviewing'),
        num: numPending,
        hasBadge: false,
        onClick: () => {
          this.onSearchParamChange('status', staffRequestStatuses.PENDING_REVIEW);
        },
      },
      {
        title: t('Posted'),
        num: numPosted,
        hasBadge: false,
        onClick: () => {
          this.onSearchParamChange('status', staffRequestStatuses.POSTED);
        },
      },
      {
        title: t('In Progress'),
        num: numInProgress,
        hasBadge: false,
        onClick: () => {
          this.onSearchParamChange('status', staffRequestStatuses.IN_PROGRESS);
        },
      },
    ];
  };

  onSearchParamChange = (field, value) => {
    this.setState(
      prevState => {
        const nextSearchParams = { ...prevState.searchParams };
        nextSearchParams[field] = value;
        nextSearchParams.page = 1;
        return { searchParams: nextSearchParams };
      },
      () => this.fetchStaffRequestWithParams(),
    );
  };

  onPageChange = (page, pageSize) => {
    let currentPage = page;
    this.setState(
      prevState => {
        const nextSearchParams = { ...prevState.searchParams };
        if (prevState.searchParams.page_size !== pageSize) {
          currentPage = 1;
        }
        nextSearchParams.page = currentPage;
        nextSearchParams.page_size = pageSize;
        return { ...prevState, searchParams: nextSearchParams };
      },
      () => this.fetchStaffRequestWithParams(),
    );
  };

  updateSearchParamStructure = () => {
    const { searchParams } = this.state;
    const { employment_type, location } = searchParams;
    const updatedSearchParams = cloneDeep(searchParams);
    updatedSearchParams.location = location?.join(',');
    if (!employment_type.length) {
      updatedSearchParams.employment_type = staffRequestUtils.getEmploymentTypesWithoutGig();
    } else {
      updatedSearchParams.employment_type = employment_type?.join(',');
    }
    return updatedSearchParams;
  };

  fetchStaffRequestWithParams = async () => {
    this.setState({ isLoading: true });
    this.updateParamsFromState();
    const updatedSearchParams = this.updateSearchParamStructure();
    const response = await staffRequestApi.fetchStaffRequestSummarysWithTasks({
      ...updatedSearchParams,
    });
    this.setState({
      isLoading: false,
      staffRequests: response.results,
      numRecords: response.count,
    });
  };

  getPositionFilter = () => {
    const { positions, t } = this.props;
    const { searchParams } = this.state;
    const positionOptions = positions.map(pos => ({ id: pos.name, name: pos.name }));
    const totalPositionsSearched = (searchParams.position_name && searchParams.position_name.split(';').length) || 0;
    const selectedPositions = searchParams.position_name
      ? searchParams.position_name.split(';').map(position => ({ value: position }))
      : [];
    return {
      title: `${t('Position')} ${totalPositionsSearched > 0 ? `(${totalPositionsSearched})` : ''}`,
      options: positionOptions,
      onSelectChange: values => {
        const selectedPositionNames = values.map(({ key }) => key);
        const semiColonSeparatedValues = selectedPositionNames.join(';');
        this.onSearchParamChange('position_name', semiColonSeparatedValues);
      },
      loading: false,
      placeholder: t('searchByPositionName'),
      selectedPositions,
    };
  };

  isSearchApplied = () => {
    const { searchParams } = this.state;
    const statusFilter = searchParams.status?.split(',');
    const locationFilterApplied = searchParams.location?.length > 0;
    const employmentTypeFilterApplied = searchParams.employment_type?.length > 0;
    const numStatusFilterApplied = searchParams.status ? statusFilter.length : 0;
    const numDefaultStatusFilter = DEFAULT_STATUS_FILTER.split(',');
    const statusFilterApplied =
      numStatusFilterApplied > 0 &&
      statusFilter.every(status => numDefaultStatusFilter.includes(status)) &&
      statusFilter.length === numDefaultStatusFilter.length;
    return !!(
      searchParams.position_name ||
      !statusFilterApplied ||
      searchParams.search ||
      locationFilterApplied ||
      employmentTypeFilterApplied
    );
  };

  getEmptyCard = clientType => {
    const { isLoading } = this.state;
    const { onPostJobClick } = this.props;
    if (isLoading) return <></>;

    if (this.isSearchApplied()) {
      return <EmptySearchResult />;
    }

    return <EmptyStaffRequestList clientType={clientType} onPostJobClick={onPostJobClick} />;
  };

  handleApplyLocationFilter = checkedKeys => {
    const searchParams = cloneDeep(this.state.searchParams);
    searchParams.location = checkedKeys;
    searchParams.page = 1;
    this.setState({ searchParams }, () => this.fetchStaffRequestWithParams());
  };

  handleApplyEmploymentTypeFilter = checkedKeys => {
    const searchParams = cloneDeep(this.state.searchParams);
    searchParams.employment_type = checkedKeys;
    searchParams.page = 1;
    this.setState({ searchParams }, () => this.fetchStaffRequestWithParams());
  };

  goToSettings = () => {
    localStorage.removeItem(seenModals.SHOW_SUBMITTED_SR);
    const route = routes.settings.replace(':tab', settingsTabs.COMPANY);
    this.props.history.push(route);
  };

  render() {
    const {
      hasFetchedStaffRequests,
      numRecords,
      staffRequests,
      searchParams,
      isLoading,
      showSubmittedSrModal,
    } = this.state;
    const { t, locations, user, positions } = this.props;
    const statusCounts = this.getStatusCounts();
    const statusFilter = this.getStatusFilter();
    const positionFilter = this.getPositionFilter();

    // Sort locations alphabetically
    const locationOptions = sortBy(
      locations.map(({ id, name }) => ({ key: String(id), title: name })),
      option => option.title,
    );

    const employmentTypeOptions = staffRequestUtils
      .getEmploymentTypeOptions(t, user)
      .map(item => {
        return {
          disabled: item.disabled,
          // Value key is renamed to work with TreeFilter
          key: item.value,
          // Label key is renamed to work with TreeFilter
          title: item.label,
        };
      })
      .filter(employmentType => employmentType.key !== employmentTypes.GIG.value);

    return (
      <Row type="flex" gutter={50}>
        <Col xs={24} style={{ marginBottom: '24px' }}>
          {/* Count card */}
          <CardWithCount loading={!hasFetchedStaffRequests} countList={statusCounts} />

          {/* Filters, search & order by*/}
          <Row type="flex" justify="space-between" align="bottom">
            <Col xs={24} md={16}>
              <Row gutter={8}>
                <Col xs={12} md={6}>
                  <CheckboxFilter
                    showActionButtons
                    title={`${t(statusFilter.title)} ${
                      statusFilter.selectedOptions.length > 0 ? `(${statusFilter.selectedOptions.length})` : ''
                    }`}
                    options={statusFilter.options}
                    selectedOptions={statusFilter.selectedOptions}
                    selectAllTitle={statusFilter.selectAllTitle}
                    onChange={statusFilter.onChange}
                    onCheckAllChange={statusFilter.onCheckAllChange}
                    indeterminate={
                      statusFilter.selectedOptions.length > 0 &&
                      statusFilter.selectedOptions.length < statusFilter.options.length
                    }
                    allChecked={statusFilter.selectedOptions.length === statusFilter.options.length}
                    onApply={statusFilter.onApply}
                    optionLabelField="name"
                  />
                </Col>
                <Col xs={12} md={6}>
                  {typeof searchParams.position_name !== 'undefined' && (
                    <FilterWithSearch
                      loading={positionFilter.loading}
                      onSearch={positionFilter.onSearch}
                      title={positionFilter.title}
                      options={positionFilter.options}
                      onSelectChange={positionFilter.onSelectChange}
                      selectedOptions={positionFilter.selectedPositions}
                      placeholder={positionFilter.placeholder}
                      optionLabelField="name"
                    />
                  )}
                </Col>
                <Col xs={12} md={6}>
                  <TreeCheckboxFilter
                    label={t('locations')}
                    placeholder={t('filterByLocationPlaceholder')}
                    treeData={locationOptions}
                    checkedKeys={searchParams.location || []}
                    showActionButtons
                    expandable={false}
                    onApply={this.handleApplyLocationFilter}
                    fillWidth
                  />
                </Col>
                <Col xs={12} md={6}>
                  <TreeCheckboxFilter
                    label={t('employmentType')}
                    placeholder={t('filterByEmploymentTypePlaceholder')}
                    treeData={employmentTypeOptions}
                    checkedKeys={searchParams.employment_type || []}
                    showActionButtons
                    expandable={false}
                    onApply={this.handleApplyEmploymentTypeFilter}
                    fillWidth
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                {typeof searchParams.search !== 'undefined' && (
                  <Input.Search
                    style={{ zIndex: 1 }}
                    allowClear
                    placeholder={t('Search')}
                    onSearch={searchValue => this.onSearchParamChange('search', searchValue)}
                    defaultValue={searchParams.search}
                  />
                )}
              </Row>
            </Col>
          </Row>
          <Divider style={{ margin: '16px 0' }} />
          <Row type="flex" justify="end" align="bottom">
            {/* Sort by filters */}
            <Col>
              <Text type="secondary">{t('sortBy')}</Text>
              <Select
                value={searchParams.ordering}
                style={{ marginLeft: '8px', width: '148px' }}
                onSelect={orderByValue => this.onSearchParamChange('ordering', orderByValue)}
              >
                <Select.Option value="-created_date">{t('mostRecent')}</Select.Option>
                <Select.Option value="-start_time">{t('Start date')}</Select.Option>
                <Select.Option value="-end_time">{t('End date')}</Select.Option>
              </Select>
            </Col>
          </Row>

          {/* Actual List */}
          <ConfigProvider renderEmpty={() => this.getEmptyCard(user.type)}>
            <StaffRequestList
              pagination={{
                ...getPaginationConfig(numRecords, this.onPageChange),
                defaultCurrent: searchParams.page,
                defaultPageSize: searchParams.page_size,
                current: searchParams.page,
                pageSize: searchParams.page_size,
              }}
              t={t}
              staffRequests={staffRequests}
              loading={isLoading && { indicator: <LoadingSpinner width="50px" /> }}
              onUpdate={() => this.fetchStaffRequestWithParams()}
              employmentTypeOptions={employmentTypeOptions}
              positions={positions}
            />
          </ConfigProvider>
        </Col>

        {/* Modal */}
        {/* TODO: For displaying recently created SR */}
        {showSubmittedSrModal && (
          <SubmittedSRModal
            visible={showSubmittedSrModal}
            onOk={this.handleCloseSubmittedSRModal}
            onGoToSettings={this.goToSettings}
            clientStatus={this.props.user.status}
            clientLogo={this.props.user.logo}
            clientDesc={this.props.user.description}
          />
        )}
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  locations: state.user.locations,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  const { openChat } = chatActions;
  return {
    openChatList: () => {
      dispatch(openChat(chatRoutes.openChatList));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffRequestListView);
