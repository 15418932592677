import React from 'react';
import { ContactsOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Typography, Tooltip } from 'antd';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import moment from 'moment';
import { rgba } from 'polished';
import { attendanceStatuses, shiftFullfillments, TIME_FORMAT_24_HOUR } from '../../../constants';
import { colors } from '../../../styles/colors';
import datetimeUtils from '../../../utilities/datetimeUtils';

const { Text, Paragraph } = Typography;

const ShiftsTableCell = ({ shift, isPastShift, timezone, onClick }) => {
  const needed = shift.staff_required;

  const assignedAttendances = shift.attendances.filter(
    attendance =>
      attendance.status === attendanceStatuses.ASSIGNED && (!attendance.absence_reason || !attendance.absence_detail),
  );
  const assigned = assignedAttendances.length;

  const isAssignedLessThanNeeded = needed > assigned;

  const CellContainer = styled.div`
    background-color: ${isPastShift ? colors.componentBackground : ''};
    display: flex;
    margin: 4px;
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: ${colors.componentBorder};

    &:hover {
      cursor: ${isPastShift ? 'not-allowed' : 'pointer'};
      ${isPastShift} {
        box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 2px;
      }
    }
  `;

  const cellLeftBorder = {
    borderLeftStyle: 'solid',
    borderTopLeftRadius: 1,
    borderBottomLeftRadius: 1,
    borderWidth: 4,
    borderColor: colors.functionalWarning,
  };
  const innerCellStyle = {
    display: 'inline-block',
    width: 50,
    padding: 8,
  };
  const fullFillmentContainerStyle = {
    display: 'flex',
    fontSize: 14,
    justifyContent: 'flex-start',
  };
  const iconContainerStyle = {
    marginRight: 8,
  };
  const iconStyle = {
    marginRight: 4,
    fontSize: 16,
  };
  const countStyle = {
    fontWeight: 600,
  };

  const getIconStyle = () => {
    return isPastShift ? { ...iconStyle, color: rgba(colors.absoluteBlack, 0.25) } : iconStyle;
  };

  const getTextStyle = type => {
    if (isPastShift) {
      return { ...countStyle, color: rgba(colors.absoluteBlack, 0.25) };
    }

    if (type === shiftFullfillments.ASSIGNED) {
      return isAssignedLessThanNeeded ? { ...countStyle, color: colors.red } : countStyle;
    }
    return countStyle;
  };

  const startTime = moment.tz(shift.start_time, timezone);
  const endTime = datetimeUtils.getEndTimeFromStartTimeAndDuration(startTime, shift.duration);

  return (
    <CellContainer onClick={onClick}>
      <div style={isAssignedLessThanNeeded && !isPastShift ? { ...innerCellStyle, ...cellLeftBorder } : innerCellStyle}>
        <Paragraph type="secondary" style={isPastShift && { color: rgba(colors.absoluteBlack, 0.25) }}>
          {moment(startTime).format(TIME_FORMAT_24_HOUR)}-{moment(endTime).format(TIME_FORMAT_24_HOUR)}
        </Paragraph>
        <div style={fullFillmentContainerStyle}>
          <Tooltip title={shiftFullfillments.NEEDED}>
            <div style={iconContainerStyle} key={shiftFullfillments.NEEDED}>
              <UsergroupAddOutlined style={getIconStyle()} />
              <Text style={getTextStyle(shiftFullfillments.NEEDED)}>{needed}</Text>
            </div>
          </Tooltip>
          <Tooltip title={shiftFullfillments.ASSIGNED}>
            <div style={iconContainerStyle} key={shiftFullfillments.ASSIGNED}>
              <ContactsOutlined style={getIconStyle()} />
              <Text style={getTextStyle(shiftFullfillments.ASSIGNED)}>{assigned}</Text>
            </div>
          </Tooltip>
        </div>
      </div>
    </CellContainer>
  );
};

export default React.memo(ShiftsTableCell, (prevProps, nextProps) => isEqual(prevProps.shift, nextProps.shift));
