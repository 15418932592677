import React from 'react';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { Alert, Button, Checkbox, Col, ConfigProvider, Divider, List, message, Row, Select, Typography } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import { Trans } from 'react-i18next';
import { capitalize, findIndex, camelCase, isEqual, isEmpty } from 'lodash';
import { connect } from 'react-redux';

import * as chatActions from '../../redux/chat';
import getPaginationConfig from '../../utilities/getPaginationConfig';
import { applicationStatuses, genderOptions, audiences, staffRequestTabs, countryCodeOptions } from '../../constants';
import staffRequestUtils from '../../utilities/staffRequestUtils';
import workerUtils from '../../utilities/workerUtils';

import HiringBulkActions from '../../pages/StaffRequestsPage/components/HiringBulkActions';
import NoApplicantsIcon from '../../assets/images/no_applicants_icon.svg';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import FunnelEmptySearch from '../../shared/components/FunnelEmptySearch';
import EmptyCard from '../../shared/components/EmptyCard';
import TreeCheckboxFilter from '../../shared/components/TreeCheckboxFilter';
import AgePopoverFilter from '../../shared/components/AgePopoverFilter';
import DistancePopoverFilter from '../../shared/components/DistancePopoverFilter';
import MoreFilters from '../../pages/StaffRequestsPage/components/MoreFilters';

import applicationApi from '../../services/applicationApi';

import ApplicantListItem from './components/ApplicantListItem';
import ApplicantToggleModal from './components/ApplicantToggleModal';

import chatRoutes from '../Chat/routes';
import { colors } from '../../styles/colors';
import { channelTypes } from '../Chat/constants';
import PlaceholderItem from './components/PlaceholderItem';
import ApplicantRejectionModal from './components/ApplicantRejectionModal';

const { Text } = Typography;
const { Option } = Select;

const getStatusFilter = tabKey => {
  switch (tabKey) {
    case staffRequestTabs.INTERVIEW:
      return `${applicationStatuses.INTERVIEW}`;
    case staffRequestTabs.WAITLISTED:
      return `${applicationStatuses.SHORTLISTED}`;
    default:
      return applicationStatuses.APPLIED;
  }
};

class StaffRequestApplicantsList extends React.Component {
  state = {
    isLoading: false,
    searchParams: {
      page: 1,
      ordering: 'quality,sorting_score',
      status: getStatusFilter(this.props.tabKey),
      search: undefined,
      include_counts: true,
      page_size: 20,
    },
    numRecords: 0,
    applications: undefined,
    selectedApplicantIndex: undefined,
    showApplicantToggleModal: false,
    selectedApplicationsList: [],
    indeterminate: false,
    checkAll: false,
    selectAllApplications: false,
    applicationCounts: {},
    appliedStatusFilter: [],
    appliedSearchParams: {},
    searchApplied: false,
    movedApplicationIds: [],
    failedHireIds: [],
    showSearchedList: false,
    linkToApplicationsTab: false,
    offerExpiryHour: undefined,
    selectedApplicant: undefined,
    showApplicantRejectionModal: false,
  };

  async componentDidMount() {
    await this.fetchApplications();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.applicantCounts > prevProps.applicantCounts) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ movedApplicationIds: [] });
      this.resetCheckboxes();
      await this.fetchApplications({ isUpdating: true });

      if (this.props.selectedWorker && this.props.selectedWorker.length > 0) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          showSearchedList: true,
        });
      }
    }

    // if tab is changed and search is applied in current tab, reset the filters
    if (
      this.props.currentActiveTabKey !== prevProps.currentActiveTabKey &&
      this.props.currentActiveTabKey !== this.props.tabKey &&
      this.state.searchApplied
    ) {
      // eslint-disable-next-line no-unused-vars, react/no-did-update-set-state
      this.setState(prevState => {
        const newSearchParams = {
          page: 1,
          ordering: prevState.searchParams.ordering,
          status: getStatusFilter(this.props.tabKey),
          search: undefined,
          include_counts: true,
          page_size: 20,
          selectedDistance: undefined,
        };

        return { searchParams: newSearchParams, searchApplied: false };
      }, this.fetchApplicationsWithParams);
    }

    if (
      this.props.currentActiveTabKey === this.props.tabKey &&
      !isEqual(this.props.selectedWorker[0]?.id, prevProps.selectedWorker[0]?.id)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showSearchedList: true });
    } else if (
      this.props.selectedWorker.length > 0 &&
      !isEqual(this.props.currentActiveTabKey, this.props.tabKey) &&
      !isEqual(this.props.selectedWorker[0]?.id, prevProps.selectedWorker[0]?.id)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showSearchedList: false });
    }
  }

  fetchApplications = async ({ isUpdating } = { isUpdating: false }) => {
    this.setState({ isLoading: true });
    const { searchParams } = this.state;
    const { selectedWorker, tabKey, clientId, staffRequestId, partnerIdFromParams } = this.props;
    try {
      const [responseWithCount, response] = await Promise.all([
        applicationApi.getStatistics({ staff_request: staffRequestId }),
        applicationApi.list({
          staff_request: staffRequestId,
          ...searchParams,
        }),
      ]);
      if (responseWithCount && response) {
        let appendedApplications = [];
        if (response.count > 0) {
          const partner_ids = response.results.map(application => application.partner.id).join(',');
          const applicationHistoryCountResponse = await applicationApi.applicationHistoryCount({
            partner_ids,
            client_id: clientId,
            staff_request_id: staffRequestId,
          });
          // TODO: handle workmate experiences in backend
          // Before adding more utils to append, this needs to be moved to BE to avoid further maintenance issues
          const applicationsWitExp = await workerUtils.appendStatisticsAndExperienceToApplications(response.results);
          appendedApplications = workerUtils.appendApplicationsCount(
            applicationsWitExp,
            applicationHistoryCountResponse.results,
          );
        }

        const applicationCounts = {};
        if (typeof response.status_counts !== 'undefined') {
          Object.entries(response.status_counts).forEach(([status, count]) => {
            applicationCounts[status] = count;
          });
        }

        let index = -1;
        let singleApplicationForModal = {};
        if (partnerIdFromParams && appendedApplications?.length > 0) {
          const partnerIndex = appendedApplications?.findIndex(
            application => application?.partner?.id === partnerIdFromParams,
          );

          if (partnerIndex >= 0) {
            index = partnerIndex;
          } else {
            const singleApplication = await applicationApi.list({
              staff_request: staffRequestId,
              partner: partnerIdFromParams,
              ...searchParams,
            });

            // TODO: can probably move this to a util since it is used repetitively
            if (singleApplication.count > 0) {
              const partner_ids = singleApplication.results.map(application => application.partner.id).join(',');
              const applicationHistoryCountResponse = await applicationApi.applicationHistoryCount({
                partner_ids,
                client_id: clientId,
                staff_request_id: staffRequestId,
              });
              // TODO: handle workmate experiences in backend
              // Before adding more utils to append, this needs to be moved to BE to avoid further maintenance issues
              const applicationsWitExp = await workerUtils.appendStatisticsAndExperienceToApplications(
                singleApplication.results,
              );
              singleApplicationForModal = workerUtils.appendApplicationsCount(
                applicationsWitExp,
                applicationHistoryCountResponse.results,
              );
            }
          }
        }

        let selectedWorkerTab = '';
        if (selectedWorker && selectedWorker.length > 0) {
          selectedWorkerTab = await workerUtils.getApplicationTab(selectedWorker[0]);
        }

        const appliedStatusFilter = searchParams.status?.split(',');
        const { showApplicantToggleModal } = this.state;
        this.setState({
          numRecords: response.count,
          applications: appendedApplications,
          applicationCounts,
          appliedStatusFilter,
          appliedSearchParams: searchParams,
          showSearchedList: selectedWorker && selectedWorker.length > 0 && isEqual(selectedWorkerTab, tabKey),
          // persist current state for showApplicantToggleModal upon updating
          showApplicantToggleModal: isUpdating
            ? showApplicantToggleModal
            : !isEmpty(singleApplicationForModal) || index >= 0,
          selectedApplicantIndex: index,
          linkToApplicationsTab: !!partnerIdFromParams,
          singleApplicationForModal: singleApplicationForModal[0], // get single application
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      message.error(error.message);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchApplicationsWithParams = async () => {
    this.setState({ isLoading: true });
    const { searchParams } = this.state;
    const { clientId, staffRequestId } = this.props;
    const response = await applicationApi.list({
      staff_request: this.props.staffRequestId,
      ...searchParams,
    });
    let appendedApplications = [];
    if (response.count > 0) {
      const partner_ids = response.results.map(application => application.partner.id).join(',');
      const applicationHistoryCountResponse = await applicationApi.applicationHistoryCount({
        partner_ids,
        client_id: clientId,
        staff_request_id: staffRequestId,
      });
      // TODO: handle workmate experiences in backend
      // Before adding more utils to append, this needs to be moved to BE to avoid further maintenance issues
      const applicationsWitExp = await workerUtils.appendStatisticsAndExperienceToApplications(response.results);
      appendedApplications = workerUtils.appendApplicationsCount(
        applicationsWitExp,
        applicationHistoryCountResponse.results,
      );
    }

    this.setState({
      isLoading: false,
      applications: appendedApplications,
      numRecords: response.count,
    });
  };

  handleSearchParamChange = (field, searchValue) => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams[field] = searchValue;
        nextSearchParams.page = 1;
        return { searchParams: nextSearchParams, searchApplied: !!searchValue };
      },
      () => this.fetchApplicationsWithParams(),
    );
  };

  onPageChange = (pageNum, pageSize) => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams.page = pageNum;
        nextSearchParams.page_size = pageSize;
        return { searchParams: nextSearchParams };
      },
      () => this.fetchApplicationsWithParams(),
    );
  };

  // On{action}s updates counts of CountCard & also parent's secondary content
  onHire = async (applicationId, workerName) => {
    const { t, updateParentStatusCounts, selectedWorker, updateParentSelectedWorker, staffRequestId } = this.props;
    const { offerExpiryHour } = this.state;
    this.setState({ isLoading: true });

    try {
      const response = await applicationApi.approve(applicationId, offerExpiryHour);
      const offerExpiry =
        offerExpiryHour ||
        // eslint-disable-next-line no-undef
        sessionStorage.getItem(staffRequestUtils.generateOfferExpirySessionStorageKey(staffRequestId));
      // eslint-disable-next-line no-undef
      sessionStorage.setItem(staffRequestUtils.generateOfferExpirySessionStorageKey(staffRequestId), offerExpiry);
      if (response) {
        const [appendedApplication] = await workerUtils.appendStatisticsAndExperienceToApplications([response]);
        await updateParentStatusCounts();
        this.setState(
          prevState => {
            const newmovedApplicationIds = prevState.movedApplicationIds;
            const newApplications = prevState.applications;
            newmovedApplicationIds.push(applicationId);

            const matchedApplicationIndex = findIndex(newApplications, application => {
              return application.id === appendedApplication.id;
            });

            if (matchedApplicationIndex >= 0) {
              newApplications[matchedApplicationIndex] = appendedApplication;
            }
            return {
              movedApplicationIds: newmovedApplicationIds,
              applications: newApplications,
              selectedApplicationsList: [],
              indeterminate: false,
              checkAll: false,
              selectAllApplications: false,
            };
          },
          () => {
            if (selectedWorker && selectedWorker.length > 0) {
              updateParentSelectedWorker(appendedApplication);
            }
          },
        );
        message.success(`${t('Successfully sent contract to')} ${workerName}`);
      }
    } catch (error) {
      const { failedHireIds } = this.state;
      this.setState({ failedHireIds: [...failedHireIds, applicationId] });
      const errorMessageCode = camelCase(error.response.data.code);
      message.error(t(errorMessageCode) || error.message);
    }
    this.setState({ isLoading: false });
  };

  onReject = async (applicationId, workerName, reason, notes) => {
    const { t, updateParentStatusCounts, selectedWorker, updateParentSelectedWorker } = this.props;

    try {
      const response = await applicationApi.reject(applicationId, reason, notes);
      if (response) {
        const [appendedApplication] = await workerUtils.appendStatisticsAndExperienceToApplications([response]);
        await updateParentStatusCounts();
        this.setState(
          prevState => {
            const newmovedApplicationIds = prevState.movedApplicationIds;
            const newApplications = prevState.applications;
            newmovedApplicationIds.push(applicationId);

            const matchedApplicationIndex = findIndex(newApplications, application => {
              return application.id === appendedApplication.id;
            });

            if (matchedApplicationIndex >= 0) {
              newApplications[matchedApplicationIndex] = appendedApplication;
            }
            return {
              movedApplicationIds: newmovedApplicationIds,
              applications: newApplications,
              selectedApplicationsList: [],
              indeterminate: false,
              checkAll: false,
              selectAllApplications: false,
            };
          },
          () => {
            if (selectedWorker && selectedWorker.length > 0) {
              updateParentSelectedWorker(appendedApplication);
            }
          },
        );
        message.success(t('applicantRejectSuccessMessage', { workerName }));
      }
    } catch (error) {
      message.error(error.response.data.detail || error.message);
    }
  };

  getEmptyCard = () => {
    const { isLoading, searchApplied } = this.state;
    const { t, tabKey } = this.props;
    let emptyCardDetails = {
      title: t('noApplicantsYet'),
      description: t('checkBackAgain'),
    };
    if (isLoading) return <></>;

    if (searchApplied) {
      return <FunnelEmptySearch clearFilters={this.handleClearFilters} />;
    }

    if (tabKey === staffRequestTabs.WAITLISTED) {
      emptyCardDetails = {
        title: t('waitlistTabEmptyStateTitle'),
        description: t('waitlistTabEmptyStateText'),
      };
    } else if (tabKey === staffRequestTabs.INTERVIEW) {
      emptyCardDetails = {
        title: t('interviewTabEmptyStateTitle'),
        description: t('interviewTabEmptyStateText'),
      };
    }

    return <EmptyCard {...emptyCardDetails} imgSrc={NoApplicantsIcon} />;
  };

  handleOpenApplicantModal = (index, linkToApplicationsTab = false) => {
    this.setState({
      selectedApplicantIndex: index,
      showApplicantToggleModal: true,
      linkToApplicationsTab,
    });
  };

  handleNewGroupChat = async () => {
    const { appliedSearchParams, selectedApplicationsList, selectAllApplications, movedApplicationIds } = this.state;
    const { staffRequestId, createGroupChat, preloadChat } = this.props;
    let membersList = [...selectedApplicationsList];
    if (movedApplicationIds.length > 0) {
      membersList = selectedApplicationsList.filter(application => !movedApplicationIds.includes(application.id));
    }

    preloadChat();

    try {
      if (selectAllApplications) {
        const response = await applicationApi.applicantsList({
          staff_request: staffRequestId,
          ...appliedSearchParams,
        });

        membersList = response.map(application => ({
          ...application,
          status: application.status,
        }));
      }

      createGroupChat({
        staffRequestId,
        audience: audiences.SELECTED_APPLICANTS,
        membersList,
      });
      preloadChat(false);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  handleStartChat = async partner => {
    const { openChat, staffRequest } = this.props;
    this.setState(
      {
        showApplicantToggleModal: false,
      },
      () => {
        openChat({
          pathname: chatRoutes.createChat,
          state: {
            createChannel: true,
            channelAttributes: {
              staffRequestId: staffRequest.id,
              audience: audiences.SELECTED_APPLICANTS,
              members: partner.user.id,
              name: `${staffRequest.client?.name} - ${staffRequest.title}`,
              type: channelTypes.PRIVATE,
            },
          },
        });
      },
    );
  };

  handleCheck = e => {
    const { applications, selectedApplicationsList } = this.state;

    let newSelectedApplicationsList = [];
    if (e.target.checked) {
      const index = selectedApplicationsList.findIndex(item => item.partner.id === e.target.value.partner.id);
      if (index === -1) {
        newSelectedApplicationsList = [...selectedApplicationsList, e.target.value];
      }
    } else {
      newSelectedApplicationsList = selectedApplicationsList.filter(
        item => item.partner.id !== e.target.value.partner.id,
      );
    }

    this.setState({
      selectedApplicationsList: newSelectedApplicationsList,
      indeterminate: !!newSelectedApplicationsList.length && newSelectedApplicationsList.length < applications.length,
      checkAll: applications.length === newSelectedApplicationsList.length,
      selectAllApplications: false,
    });
  };

  handleCheckAllChange = e => {
    const { applications } = this.state;
    const { selectedWorker } = this.props;
    const applicationList = selectedWorker && selectedWorker.length > 0 ? selectedWorker : applications;
    this.setState({
      selectedApplicationsList: e.target.checked
        ? applicationList.map(application => ({ ...application, status: application.status }))
        : [],
      indeterminate: false,
      checkAll: e.target.checked,
      selectAllApplications: false,
    });
  };

  handleSelectAllApplicants = selectAllApplications => {
    let clearSelection = {};
    if (!selectAllApplications) {
      clearSelection = {
        selectedApplicationsList: [],
        indeterminate: false,
        checkAll: false,
      };
    }

    this.setState({
      selectAllApplications,
      ...clearSelection,
    });
  };

  resetCheckboxes = () => {
    this.setState({
      selectedApplicationsList: [],
      indeterminate: false,
      checkAll: false,
      selectAllApplications: false,
    });
  };

  getApplicationCounts = statuses => {
    let totalApplicationCount = 0;

    const { applicationCounts } = this.state;
    Object.entries(applicationCounts).forEach(([status, count]) => {
      if (statuses.length < 1 || statuses.includes(status)) {
        totalApplicationCount += count;
      }
    });

    return totalApplicationCount;
  };

  handleApplyGenderFilter = checkedKeys => {
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      nextSearchParams.gender = checkedKeys.join(',');
      nextSearchParams.page = 1;
      return { searchParams: nextSearchParams, searchApplied: checkedKeys.length > 0 };
    }, this.fetchApplicationsWithParams);
  };

  handleApplyDistanceFilter = distance => {
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      if (distance > 0) {
        nextSearchParams.distance = distance;
      } else {
        delete nextSearchParams.distance;
      }
      nextSearchParams.page = 1;
      return { searchParams: nextSearchParams, searchApplied: distance > 0 };
    }, this.fetchApplicationsWithParams);
  };

  handleApplyAgeRangeFilter = selectedAgeRange => {
    const [minimum, maximum] = selectedAgeRange;
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      if (minimum) {
        const date_of_birth_before = moment()
          .endOf('year')
          .subtract(minimum, 'years')
          .toISOString();
        nextSearchParams.date_of_birth_before = date_of_birth_before;
        nextSearchParams.min_age = minimum;
      } else {
        delete nextSearchParams.date_of_birth_before;
        delete nextSearchParams.min_age;
      }

      if (maximum) {
        const date_of_birth_after = moment()
          .startOf('year')
          .subtract(maximum, 'years')
          .toISOString();
        nextSearchParams.date_of_birth_after = date_of_birth_after;
        nextSearchParams.max_age = maximum;
      } else {
        delete nextSearchParams.date_of_birth_after;
        delete nextSearchParams.max_age;
      }
      nextSearchParams.page = 1;
      return { searchParams: nextSearchParams, searchApplied: minimum && maximum };
    }, this.fetchApplicationsWithParams);
  };

  handleApplyCovidCertificationFilter = checkedKeys => {
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      nextSearchParams.covid_certification_status = checkedKeys.join(',');
      nextSearchParams.page = 1;
      return { searchParams: nextSearchParams, searchApplied: checkedKeys.length > 0 };
    }, this.fetchApplicationsWithParams);
  };

  handleApplyFilters = params => {
    this.setState(() => params, this.fetchApplicationsWithParams);
  };

  updateUrl = selectedTab => {
    const { onChangeUrl } = this.props;
    onChangeUrl(selectedTab);
  };

  onBulkActionSuccess = async (selectedApplicationIds, movedToNewTab, succeedApprovals) => {
    const { selectedWorker, updateParentSelectedWorker } = this.props;
    await this.props.updateParentStatusCounts();
    this.setState(
      prevState => {
        const newMovedApplicationIds = [...prevState.movedApplicationIds, ...selectedApplicationIds];
        const newApplications = prevState.applications;

        selectedApplicationIds.forEach(selectedApplicationId => {
          const matchedApplicationIndex = findIndex(newApplications, application => {
            return application.id === selectedApplicationId;
          });
          if (matchedApplicationIndex >= 0) {
            if (movedToNewTab === staffRequestTabs.OFFERS && succeedApprovals) {
              const updatedApplicationRecord = succeedApprovals.find(
                succeedApproval => succeedApproval.application_id === selectedApplicationId,
              );
              newApplications[matchedApplicationIndex] = {
                ...newApplications[matchedApplicationIndex],
                ...updatedApplicationRecord,
              };
            } else {
              newApplications[matchedApplicationIndex] = staffRequestUtils.updateApplicationStatus(
                newApplications[matchedApplicationIndex],
                movedToNewTab,
              );
            }
          }
        });
        return {
          movedApplicationIds: newMovedApplicationIds,
          applications: newApplications,
          selectedApplicationsList: [],
          indeterminate: false,
          checkAll: false,
          selectAllApplications: false,
        };
      },
      () => {
        if (selectedWorker && selectedWorker.length > 0) {
          const updatedSelectedWorker = this.state.applications.find(
            application => application.partner.id === selectedWorker[0].partner.id,
          );
          updateParentSelectedWorker(updatedSelectedWorker);
        }
      },
    );
  };

  handleSetOfferExpiry = hour => {
    this.setState({ offerExpiryHour: hour });
  };

  render() {
    const {
      searchParams,
      numRecords,
      isLoading,
      applications,
      selectedApplicantIndex,
      showApplicantToggleModal,
      selectedApplicationsList,
      indeterminate,
      checkAll,
      selectAllApplications,
      appliedStatusFilter,
      movedApplicationIds,
      failedHireIds,
      showSearchedList,
      linkToApplicationsTab,
      singleApplicationForModal,
      showApplicantRejectionModal,
      selectedApplicant,
    } = this.state;
    const { t, user, staffRequest, selectedWorker, applicationRejectReasons, tabKey } = this.props;
    const applicantCounts = this.getApplicationCounts(appliedStatusFilter);
    let totalCounts = applicantCounts;
    if (selectedWorker && selectedWorker.length > 0) {
      totalCounts = selectedWorker.length;
    } else if (applicantCounts >= movedApplicationIds.length) {
      totalCounts = applicantCounts - movedApplicationIds.length;
    }
    let totalSelected = (selectAllApplications && totalCounts) || selectedApplicationsList.length;
    if (selectedApplicationsList.length >= movedApplicationIds.length) {
      totalSelected = selectAllApplications
        ? totalCounts
        : selectedApplicationsList.length - movedApplicationIds.length;
    }

    let applicationList = applications || [];
    let numRecordList = numRecords - movedApplicationIds.length || 0;

    if (selectedWorker.length > 0) {
      if (showSearchedList && this.props.currentActiveTabKey === this.props.tabKey) {
        applicationList = selectedWorker;
        numRecordList = movedApplicationIds.includes(selectedWorker[0].id) ? 0 : 1;
      } else {
        applicationList = [];
        numRecordList = 0;
      }
    }

    return (
      <>
        {/* Filters */}
        <Row type="flex" justify="space-between" style={{ marginBottom: '16px' }}>
          <Col>
            <Row>
              <Col flex>
                <Typography.Text level={3} style={{ marginRight: 12 }}>
                  {t('filters')}
                </Typography.Text>
                <DistancePopoverFilter
                  label={t('Distance')}
                  selectedDistance={searchParams?.distance}
                  onApply={this.handleApplyDistanceFilter}
                  isDisabled={selectedWorker && selectedWorker.length > 0}
                />
                {user?.country?.code !== countryCodeOptions.SINGAPORE && (
                  <>
                    <AgePopoverFilter
                      label={t('Age')}
                      selectedAgeRange={[searchParams?.min_age, searchParams?.max_age]}
                      onApply={this.handleApplyAgeRangeFilter}
                      isDisabled={selectedWorker && selectedWorker.length > 0}
                    />
                    <TreeCheckboxFilter
                      treeData={Object.values(genderOptions).map(value => ({
                        ...value,
                        title: capitalize(t(value.key)),
                      }))}
                      checkedKeys={searchParams?.gender?.split(',')}
                      placeholder={t('filterByGenderPlaceholder')}
                      label={t('Gender')}
                      showActionButtons
                      showSearch={false}
                      onApply={this.handleApplyGenderFilter}
                      expandable={false}
                      isDisabled={selectedWorker && selectedWorker.length > 0}
                    />
                  </>
                )}

                <MoreFilters
                  searchParams={searchParams}
                  selectedWorker={selectedWorker}
                  onApplyMoreFilters={this.handleApplyFilters}
                />
                <Text type="secondary">{t('applicationResults', { numRecords: numRecordList })}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider style={{ marginBottom: '16px', marginTop: '0px' }} />
        {/* Sorting and recommendation */}
        <Row style={{ marginBottom: checkAll ? '16px' : '0' }}>
          {user?.chatEnabled && (
            <>
              <Col>
                <Checkbox
                  className="chat-checkbox"
                  indeterminate={indeterminate}
                  onChange={this.handleCheckAllChange}
                  checked={checkAll}
                  disabled={
                    !applications || applications.length < 1 || movedApplicationIds.length === applications.length
                  }
                  style={{
                    borderRight: totalSelected ? `1px solid ${colors.greyBorder}` : '0 none',
                    marginRight: totalSelected ? '16px' : '0',
                    padding: '4px 6px 4px 0',
                  }}
                >
                  {totalSelected ? t('numSelected', { num: totalSelected }) : ''}
                </Checkbox>
                <Button onClick={this.handleNewGroupChat} disabled={selectedApplicationsList.length < 1}>
                  <TeamOutlined /> {t('newGroupChat')}
                </Button>
              </Col>
              <Col>
                <HiringBulkActions
                  tab={
                    tabKey === staffRequestTabs.WAITLISTED ? staffRequestTabs.WAITLISTED : staffRequestTabs.INTERVIEW
                  }
                  disabled={selectedApplicationsList.length < 1}
                  disableShortlist={tabKey === staffRequestTabs.WAITLISTED}
                  disableInterview={tabKey === staffRequestTabs.INTERVIEW || tabKey === staffRequestTabs.WAITLISTED}
                  disableHire={tabKey === staffRequestTabs.WAITLISTED}
                  selectedIds={selectedApplicationsList.map(item => item.id)}
                  onFailedBulkHire={failedIds => this.setState({ failedHireIds: failedIds })}
                  onBulkActionSuccess={(selectedApplicationIds, movedToNewTab, succeedApprovals) =>
                    this.onBulkActionSuccess(selectedApplicationIds, movedToNewTab, succeedApprovals)
                  }
                  selectAllApplications={selectAllApplications}
                  totalSelected={totalSelected}
                  staffRequest={staffRequest}
                  status={searchParams.status}
                  applicationRejectReasons={applicationRejectReasons}
                />
              </Col>
            </>
          )}
          <Col style={{ marginLeft: 'auto' }}>
            <Text type="secondary">{t('sortBy')}</Text>
            <Select
              defaultValue="quality,reliability_score"
              style={{ marginLeft: '8px', width: '156px' }}
              onChange={value => this.handleSearchParamChange('ordering', value)}
            >
              <Option value="quality,sorting_score">{t('recommended')}</Option>
              <Option value="created_date">{t('firstApplied')}</Option>
              <Option value="-created_date">{t('latestApplied')}</Option>
            </Select>
          </Col>
        </Row>
        {user?.chatEnabled && checkAll && (
          <Row>
            <Col span={24}>
              <Row style={{ marginBottom: '16px' }}>
                <Col span={24}>
                  <Alert
                    message={
                      <>
                        {selectAllApplications ? (
                          <>
                            <Trans
                              i18nKey="allWorkersSelected"
                              values={{ workers: totalCounts }}
                              components={{ strong: <strong /> }}
                            />
                            {selectedApplicationsList.length < totalCounts && (
                              <Text
                                strong
                                style={{ cursor: 'pointer', color: '#1890FF', marginLeft: '5px' }}
                                onClick={() => this.handleSelectAllApplicants(false)}
                              >
                                {t('clearSelection')}
                              </Text>
                            )}
                          </>
                        ) : (
                          <>
                            <Trans
                              i18nKey="allWorkersSelectedOnPage"
                              values={{ workers: totalSelected }}
                              components={{ strong: <strong /> }}
                            />
                            {selectedApplicationsList.length < totalCounts && (
                              <Text
                                strong
                                style={{ cursor: 'pointer', color: '#1890FF', marginLeft: '5px' }}
                                onClick={() => this.handleSelectAllApplicants(true)}
                              >
                                {t('selectAllWorkers', { workers: totalCounts })}
                              </Text>
                            )}
                          </>
                        )}
                      </>
                    }
                    type="info"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {/* Actual Worker list */}
        <ConfigProvider renderEmpty={this.getEmptyCard}>
          <List
            itemLayout="vertical"
            style={{ padding: '0', marginBottom: '24px' }}
            pagination={{
              ...getPaginationConfig(numRecordList, this.onPageChange),
              current: searchParams.page || 1,
              // overwriting pagination config
              pageSize: searchParams.page_size,
              pageSizeOptions: [20, 40, 60, 100],
              showSizeChanger: true,
              showQuickJumper: false,
            }}
            loading={isLoading && { indicator: <LoadingSpinner width="50px" /> }}
            dataSource={applicationList || []}
            renderItem={(application, index) => {
              const checked =
                selectedApplicationsList.findIndex(member => member.partner.id === application.partner.id) !== -1;

              if (movedApplicationIds.includes(application.id) && !failedHireIds.includes(application.id)) {
                return <PlaceholderItem t={t} application={application} updateUrl={this.updateUrl} />;
              }

              return (
                <ApplicantListItem
                  key={index}
                  application={application}
                  showCheckbox={user?.chatEnabled}
                  checked={checked}
                  onCheck={this.handleCheck}
                  onHire={this.onHire}
                  onReject={(id, workerName) =>
                    this.setState({ showApplicantRejectionModal: true, selectedApplicant: { id, workerName } })
                  }
                  onOpenApplicantModal={this.handleOpenApplicantModal}
                  onChat={this.handleStartChat}
                  index={index}
                  alreadyHired={failedHireIds.includes(application.id)}
                  user={user}
                  positionId={staffRequest.position?.id}
                  onHandleOfferExpiry={this.handleSetOfferExpiry}
                />
              );
            }}
          />
        </ConfigProvider>
        {/* Modals */}
        {showApplicantToggleModal && (
          <ApplicantToggleModal
            visible={showApplicantToggleModal}
            onHire={this.onHire}
            onReject={(id, workerName) =>
              this.setState({ showApplicantRejectionModal: true, selectedApplicant: { id, workerName } })
            }
            onCancel={() => this.setState({ showApplicantToggleModal: false })}
            onChat={this.handleStartChat}
            selectedApplicantIndex={selectedApplicantIndex}
            applications={applicationList}
            pageNum={searchParams.page}
            user={user}
            staffRequestId={staffRequest.id}
            linkToApplicationsTab={linkToApplicationsTab}
            singleApplicationForModal={singleApplicationForModal}
            onHandleOfferExpiry={this.handleSetOfferExpiry}
          />
        )}
        {showApplicantRejectionModal && (
          <ApplicantRejectionModal
            title={t('applicantRejectionModalTitle', { workerName: selectedApplicant.workerName })}
            applicationRejectReasons={applicationRejectReasons}
            visible={showApplicantRejectionModal}
            onClose={() => this.setState({ showApplicantRejectionModal: false, selectedApplicant: undefined })}
            onReject={(reason, notes) =>
              this.onReject(selectedApplicant.id, selectedApplicant.workerName, reason, notes)
            }
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  clientId: state.user.clientId,
});

const mapDispatchToProps = dispatch => {
  const { openChat, preload } = chatActions;
  return {
    openChat: (route, state) => {
      dispatch(openChat(route, state, true));
    },
    createGroupChat: state => {
      dispatch(openChat(chatRoutes.createGroupChat, state, true));
    },
    preloadChat: (isLoading = true) => {
      dispatch(preload(isLoading));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffRequestApplicantsList);
