import React from 'react';
import { Typography, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const TimesheetTitleWithTooltip = ({ title, tooltipText }) => (
  <Typography.Title level={5}>
    {title}
    {tooltipText && (
      <Tooltip title={tooltipText} placement="right" style={{ maxWidth: 500 }}>
        <QuestionCircleOutlined size={12} style={{ marginLeft: 8 }} />
      </Tooltip>
    )}
  </Typography.Title>
);

export default TimesheetTitleWithTooltip;
