import React from 'react';
import { Row, Typography, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../styles/colors';

const Crown = ({ width = 12, height = 12 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12" fill="none">
      <path
        d="M1.33333 8.6665L0 1.33317L3.66667 4.6665L6 0.666504L8.33333 4.6665L12 1.33317L10.6667 8.6665H1.33333ZM10.6667 10.6665C10.6667 11.0665 10.4 11.3332 10 11.3332H2C1.6 11.3332 1.33333 11.0665 1.33333 10.6665V9.99984H10.6667V10.6665Z"
        fill="#1890FF"
      />
    </svg>
  );
};

const VipWorkersOnlyTooltip = ({ staffRequest, iconContainerStyle = {}, tooltipProps = {}, iconProps = {} }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      placement="topRight"
      {...tooltipProps}
      title={
        <Row>
          <Typography style={{ fontWeight: '700', color: colors.white, fontSize: 14 }}>
            {t('vipWorkersOnly')}
          </Typography>
          <Typography style={{ color: colors.white, fontSize: 13 }}>
            {t('vipWorkersOnlyDescription', { position: staffRequest?.position?.name })}
          </Typography>
        </Row>
      }
    >
      <span style={iconContainerStyle}>
        <Crown {...iconProps} />
      </span>
    </Tooltip>
  );
};

export default VipWorkersOnlyTooltip;
