import React from 'react';
import { withTranslation } from 'react-i18next';
import { MessageOutlined, MinusCircleFilled } from '@ant-design/icons';
import { Button, Tooltip, Row, Col } from 'antd';
import { employmentStatuses } from '../../constants';

import { colors } from '../../styles/colors';

const WorkerRatingButtons = ({ t, employment, onReject, onChat, index }) => {
  const ChatButton = () => (
    <Tooltip placement="top" title={t('privateChat')}>
      <Button
        icon={<MessageOutlined />}
        onClick={e => {
          e.stopPropagation();
          onChat(employment.partner);
        }}
      >
        {t('chatButton')}
      </Button>
    </Tooltip>
  );

  const RejectButton = () => (
    <Tooltip placement="top" title={t('endEmploymentTitle')}>
      <Button
        style={{ color: employment.status === employmentStatuses.ACTIVE ? colors.functionalError : undefined }}
        disabled={employment.status !== employmentStatuses.ACTIVE}
        onClick={e => {
          e.stopPropagation();
          onReject(index);
        }}
      >
        <MinusCircleFilled />
      </Button>
    </Tooltip>
  );

  return (
    <Row type="flex" gutter={8} style={{ marginLeft: 4 }}>
      <Col>
        <ChatButton />
      </Col>
      <Col>
        <RejectButton />
      </Col>
    </Row>
  );
};

export default withTranslation()(WorkerRatingButtons);
