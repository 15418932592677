import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Empty, List, Typography, Row, Col, Dropdown, Tag, Menu, Divider } from 'antd';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';

import { colors } from '../../../styles/colors';
import TimesheetTagFormModal from './components/TimesheetTagFormModal';
import DeleteCategoryFormModal from './components/DeleteCategoryFormModal';
import TimesheetRoundingForm from './components/TimesheetRoundingForm';

const { Title, Text } = Typography;

const filterCategoryTags = category => {
  const tags = category.tags.filter(tag => tag.is_active);
  return { ...category, tags };
};

const TimesheetTagSection = ({ t, isAdmin, timesheetTagCategories, isLoading, client, fetchClientAndManager }) => {
  const [isOpenTimesheetTagModal, setIsOpenTimesheetTagModal] = useState(false);
  const [isOpenDeleteCategoryModal, setIsOpenDeleteCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const onAddCategory = () => {
    setIsOpenTimesheetTagModal(true);
  };
  const onCloseTimesheetTagModal = () => {
    setIsOpenTimesheetTagModal(false);
    setSelectedCategory(null);
  };
  const onCloseDeleteCategoryModal = () => {
    setIsOpenDeleteCategoryModal(false);
    setSelectedCategory(null);
  };
  const onEdit = category => {
    setIsOpenTimesheetTagModal(true);
    setSelectedCategory(filterCategoryTags(category));
  };
  const onDelete = category => {
    setIsOpenDeleteCategoryModal(true);
    setSelectedCategory(filterCategoryTags(category));
  };

  return (
    <>
      <TimesheetRoundingForm client={client} />

      <Divider />

      <Row style={{ marginTop: '14px', marginBottom: '16px' }}>
        <Title level={3}>{t('timesheetTags')}</Title>
        <Text type="secondary">{t('timesheetTagsContent')}</Text>
      </Row>
      <Row style={{ marginBottom: '32px' }}>
        <Button disabled={!isAdmin} onClick={onAddCategory}>
          {t('createNewTagCategory')}
        </Button>
      </Row>
      {timesheetTagCategories && timesheetTagCategories.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={<span>{t('pleaseCreateATagCategory')}</span>} />
      ) : (
        <List
          itemLayout="horizontal"
          loading={isLoading && { indicator: <LoadingSpinner width="50px" /> }}
          dataSource={timesheetTagCategories || []}
          renderItem={category => (
            <Row
              type="flex"
              justify="space-between"
              style={{ paddingBottom: '24px', paddingTop: '16px', borderBottom: '1px solid #00000017' }}
            >
              <Row>
                <Title strong level={4}>
                  {category.name}
                </Title>
                {category.tags &&
                  category.tags.map(tag => {
                    if (tag.is_active) {
                      return (
                        <Tag key={`${category.name}-${tag.id}`} style={{ marginTop: 8 }}>
                          {tag.name}
                        </Tag>
                      );
                    }
                    return null;
                  })}
              </Row>
              <Col span={2} style={{ textAlign: 'right', position: 'absolute', left: 713 }}>
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu style={{ width: '120px' }}>
                      <Menu.Item disabled={!isAdmin} onClick={() => onEdit(category)}>
                        {t('edit')}
                      </Menu.Item>
                      <Menu.Item style={{ color: colors.red }} disabled={!isAdmin} onClick={() => onDelete(category)}>
                        {t('remove')}
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button icon={<EllipsisOutlined />} />
                </Dropdown>
              </Col>
            </Row>
          )}
        />
      )}
      {isOpenTimesheetTagModal && (
        <TimesheetTagFormModal
          clientId={client.clientId}
          category={selectedCategory} // Does not have to be explicitly set in "this.state". Handled by Antd.
          visible={isOpenTimesheetTagModal}
          onUpdate={fetchClientAndManager}
          onClose={onCloseTimesheetTagModal}
        />
      )}
      {isOpenDeleteCategoryModal && (
        <DeleteCategoryFormModal
          clientId={client.clientId}
          category={selectedCategory}
          visible={isOpenDeleteCategoryModal}
          onUpdate={fetchClientAndManager}
          onClose={onCloseDeleteCategoryModal}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  isLoading: state.user.isLoading,
  timesheetTagCategories: state.user.timesheetTagCategories,
});

export default connect(mapStateToProps, null)(withTranslation()(TimesheetTagSection));
