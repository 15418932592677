import { THAILAND_TIMEZONE, INDONESIA_TIMEZONE } from '../constants';

const getTimezoneFromClientCountry = countryCode => {
  if (countryCode === 'TH') {
    return THAILAND_TIMEZONE;
  }
  if (countryCode === 'ID') {
    return INDONESIA_TIMEZONE;
  }
};

export default {
  getTimezoneFromClientCountry,
};
