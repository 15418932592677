import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { EnvironmentOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Card } from 'antd';

import { withTranslation, Trans } from 'react-i18next';

import { colors } from '../../../styles/colors';
import WorkerTag from '../../../shared/components/WorkerTag';
import textUtils from '../../../utilities/textUtils';
import workerUtils from '../../../utilities/workerUtils';
import { workerCovidCertificationStatuses, workerTags, userStatuses } from '../../../constants';

const { Text, Title, Paragraph } = Typography;

const ProfileHeader = ({ worker, covidCertificationStatus, daysWorked, application, user, isDirectJobInvite, t }) => {
  const { age, gender, location, name, phone, birthday } = worker;
  const validBirthday = birthday && birthday !== 'Invalid date';
  const hasAddress = location.address !== 'Not provided';
  const workerGeneralInfo = [
    {
      iconType: 'user',
      description: `${gender ? textUtils.capitalizeWord(t(gender)) : `[${t('Gender')}]`} · ${
        age ? `${age}yo` : `[${t('Age')}]`
      } · ${validBirthday ? birthday : `[${t('DOB')}]`}`,
    },
    { iconType: 'phone', description: phone },
  ];

  const workerStatistics = application.partnerStatistics;

  let distanceBetween;
  let staffRequestLocation;
  let isRecommended = false;
  let clientExperience = false;
  let verifiedDate = false;
  let rating;
  if (application) {
    staffRequestLocation = application.staff_request.location.name;
    distanceBetween = workerUtils.getDistanceBetween(application.staff_request.location.address, location);
    isRecommended = workerUtils.isRecommendedWorker(application.reliability_score, application.recommended);
    clientExperience = workerUtils.getWorkerClientHistory(user?.clientId, application.workmateExperiences);
    verifiedDate = workerUtils.getVerifiedDate(userStatuses.ACTIVE, worker.approval_date, t);
    rating = workerStatistics?.avg_rating_from_client;
  }

  const skillQuizPosition = application.staff_request.position;
  const skillQuizStatistics =
    workerStatistics && workerStatistics.skill_quiz_statistics && JSON.parse(workerStatistics.skill_quiz_statistics);
  const skillQuizScore = skillQuizStatistics
    ? workerUtils.getApplicationPositionSkillQuizScore(skillQuizPosition.code, skillQuizStatistics)
    : undefined;

  const isVaccinated = covidCertificationStatus === workerCovidCertificationStatuses.VERIFIED;

  const workerTagInfo = [
    { type: workerTags.NEW_JOINER, isActive: !clientExperience && workerUtils.isNewWorker(daysWorked) },
    { type: workerTags.RECOMMENDED, isActive: isRecommended },
    { type: workerTags.PAST_WORKER, isActive: clientExperience },
    {
      type: workerTags.EXPERIENCED,
      isActive: application.partnerStatistics.total_days_worked >= 240,
    },
    { type: workerTags.VERIFIED, isActive: verifiedDate && !isDirectJobInvite && false },
    { type: workerTags.JOB_INVITATION, isActive: isDirectJobInvite },
    { type: workerTags.SKILL_SCORE, isActive: skillQuizScore },
  ];

  const workerExperienceInfo = [
    { type: workerTags.RATING, isActive: rating },
    { type: workerTags.DAYS_WORKED, isActive: daysWorked },
    { type: workerTags.COVID_VACCINATED, isActive: isVaccinated },
  ];

  return (
    <Card bodyStyle={{ padding: 0 }} bordered={false}>
      <Row type="flex" justify="start" align="middle" style={{ marginBottom: '16px' }}>
        <Col>
          <Title
            style={{
              fontSize: '30px',
              lineHeight: '38px',
              fontWeight: 600,
              color: colors.textTitle,
              marginBottom: 0,
              marginRight: '16px',
            }}
          >
            {name}
          </Title>
        </Col>
        <Col style={{ width: 'auto' }}>
          <Row type="flex" justify="space-between">
            {workerExperienceInfo
              .filter(({ isActive }) => isActive)
              .map(({ type }) => (
                <Col key={type} style={{ marginRight: '16px' }}>
                  <WorkerTag
                    type={type}
                    daysWorked={daysWorked}
                    rating={rating}
                    companyName={application.staff_request.client?.name}
                  />
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="start" align="middle" style={{ marginBottom: '16px' }}>
        <Col style={{ width: 'auto' }}>
          <Row type="flex">
            {workerTagInfo
              .filter(({ isActive }) => isActive)
              .map(({ type }) => (
                <Col key={type} style={{ marginRight: '8px' }}>
                  <WorkerTag
                    type={type}
                    positionName={application.staff_request.position.name}
                    companyName={application.staff_request.client?.name}
                    skillScore={skillQuizScore}
                  />
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
      <section>
        {workerGeneralInfo.map(({ iconType, description }, index) => (
          <Row key={index} style={{ marginBottom: '8px', alignItems: 'center' }}>
            <LegacyIcon type={iconType} style={{ color: '#BFBFBF', marginRight: '8px' }} />
            {description}
          </Row>
        ))}
        {application ? (
          <>
            <Row style={{ marginBottom: '4px', alignItems: 'center' }}>
              <EnvironmentOutlined style={{ color: '#BFBFBF', marginRight: '8px' }} />
              {hasAddress ? (
                <Text strong style={{ color: colors.primaryText }}>
                  <Trans
                    i18nKey="distanceAwayFromLocation"
                    values={{ distance: `${distanceBetween} km`, location: staffRequestLocation }}
                  />
                </Text>
              ) : (
                `[${t('Location')}]`
              )}
            </Row>
          </>
        ) : (
          <Row style={{ marginBottom: '4px' }}>
            <Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: 0 }}>
              <EnvironmentOutlined style={{ color: '#BFBFBF', marginRight: '8px' }} />
              <Text style={{ color: colors.primaryText }}>{`${location.address}`}</Text>
            </Paragraph>
          </Row>
        )}
        <Row style={{ paddingLeft: 20 }}>
          {location.latitude && location.longitude && (
            <a
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`}
            >
              {t('openInGoogleMaps')}
            </a>
          )}
        </Row>
      </section>
    </Card>
  );
};

export default withTranslation()(ProfileHeader);
