import React from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { FileOutlined, ShopOutlined } from '@ant-design/icons';
import { Row, Col, Typography } from 'antd';

import { DEFAULT_DATE_FORMAT } from '../../constants';

import billingApi from '../../services/billingApi';

import BillingItemTable from '../../containers/BillingItemTable';
import BillingCostSummary from './components/BillingCostSummary';
import StatusTag from '../../shared/components/StatusTag';
import LoadingSpinner from '../../shared/components/LoadingSpinner';

const { Text, Title } = Typography;

const InvoiceInfo = ({ invoices }) => {
  if (!invoices || invoices.length === 0) {
    return <> </>;
  }

  // If we have more than 2 invoices it is important to differentiate them
  let addNameAsPrefix = false;
  if (invoices.length > 1) {
    addNameAsPrefix = true;
  }

  return invoices.map((invoice, index) => (
    <Row key={index}>
      <FileOutlined style={{ marginRight: '8px' }} />
      {addNameAsPrefix && invoice.name} Invoice number: {invoice.number} · <a href={invoice.url}>View receipt</a>
    </Row>
  ));
};

class BillingPage extends React.Component {
  state = {
    isLoading: false,
    billingReport: undefined,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const billingReport = await billingApi.fetchBillingReportFromId(this.props.match.params.id);
    this.setState({ billingReport, isLoading: false });
  }

  render() {
    const { isLoading, billingReport } = this.state;
    const { match, client } = this.props;
    if (isLoading || !billingReport) return <LoadingSpinner />;

    return (
      <>
        <section style={{ marginBottom: '32px' }}>
          <Title level={2} style={{ marginBottom: '16px' }}>{`Invoice: ${match.params.id}`}</Title>

          <Row style={{ marginBottom: '4px' }}>
            <ShopOutlined style={{ marginRight: '8px' }} /> {client.legalName}
          </Row>
          <InvoiceInfo invoices={billingReport.invoices} />

          <Row style={{ marginTop: '16px' }}>
            <StatusTag status={billingReport.status} />
            <Text type="secondary">{moment(billingReport.billing_date).format(DEFAULT_DATE_FORMAT)}</Text>
          </Row>
        </section>

        <section style={{ marginBottom: '54px' }}>
          <BillingItemTable billingReportId={billingReport.id} />
        </section>

        <Row type="flex" justify="end">
          <Col span={6}>
            <BillingCostSummary billingReport={billingReport} client={client} />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => ({
  client: state.user,
});

export default connect(mapStateToProps, undefined)(withTranslation()(BillingPage));
