import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { withTranslation } from 'react-i18next';
import { Row, Col, Tooltip, Button, Input, Switch, Typography, message } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import channelApi from '../../../services/channelApi';

import ChatUploadImageModal from './ChatUploadImageModal';
import { messageDisplayDuration } from '../../../constants';

const { TextArea } = Input;
const { Text } = Typography;
const MESSAGE_TYPE = 'message'; // default to message type
const MEDIA_TYPE = 'media';
class ChatReplyBox extends Component {
  state = {
    newMessage: '',
    showUploadImageModal: false,
    chatRepliesEnabled: false,
    sendingMessage: false,
  };

  async componentDidMount() {
    const { chatReadyToUse, unsentMessage } = this.props;
    if (chatReadyToUse && unsentMessage) {
      this.sendMessage(unsentMessage);
    }
  }

  componentDidUpdate(prevProps) {
    const { chatRepliesEnabled } = this.props;
    if (chatRepliesEnabled !== prevProps.chatRepliesEnabled) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ chatRepliesEnabled });
    }
  }

  typeMessage = e => this.setState({ newMessage: e.target.value });

  sendMessage = async newMessage => {
    const { twilioChatChannel, user, selectedChannel, chatReadyToUse, t, updateUnsentMessage } = this.props;
    if (chatReadyToUse) {
      try {
        this.setState({ sendingMessage: true });
        await twilioChatChannel.sendMessage(newMessage, {
          user_id: user.userId,
          staff_request_id: selectedChannel?.staff_request.id,
          message_type: MESSAGE_TYPE,
        });
        this.setState({ newMessage: '', sendingMessage: false });
        updateUnsentMessage(undefined);
      } catch (error) {
        Sentry.captureException(error);
        updateUnsentMessage(newMessage);
        this.setState({ sendingMessage: false });
        message.warning(t('sendMessageErrorWithResend'), [messageDisplayDuration]);
      }
    } else {
      updateUnsentMessage(newMessage);
      message.warning(t('sendMessageConnectionError'), [messageDisplayDuration]);
    }
  };

  onSendImage = async (fileObj, fileType) => {
    const { twilioChatChannel, user, selectedChannel, chatReadyToUse, t } = this.props;
    if (chatReadyToUse) {
      try {
        this.setState({ sendingMessage: true });
        await twilioChatChannel.sendMessage(
          {
            type: MEDIA_TYPE,
            contentType: fileType,
            media: fileObj,
          },
          { user_id: user.userId, staff_request_id: selectedChannel?.staff_request.id, message_type: MESSAGE_TYPE },
        );
        this.setState({ showUploadImageModal: false, sendingMessage: false });
      } catch (error) {
        Sentry.captureException(error);
        this.setState({ sendingMessage: false });
        message.warning(t('sendPictureMessageError'), [messageDisplayDuration]);
      }
    } else {
      message.warning(t('chatConnectionError'), [messageDisplayDuration]);
    }
  };

  handleToggleChatReplies = async state => {
    const { twilioChatChannel, selectedChannel, updateChatRepliesEnabled, chatReadyToUse, t } = this.props;
    if (chatReadyToUse) {
      try {
        if (twilioChatChannel?.channelState) {
          await Promise.all([
            twilioChatChannel?.updateAttributes({
              ...twilioChatChannel?.channelState?.attributes,
              enable_reply: state,
            }),
            channelApi.patchChannel(selectedChannel.id, {
              enable_reply: state,
            }),
          ]);
          updateChatRepliesEnabled(state);
        }
      } catch (error) {
        Sentry.captureException(error);
        message.warning(t('toggleRepliesFailed'), [messageDisplayDuration]);
      }
    } else {
      message.warning(t('chatConnectionError'), [messageDisplayDuration]);
    }
  };

  render() {
    const { newMessage, showUploadImageModal, chatRepliesEnabled, sendingMessage } = this.state;
    const { user, t, chatReadyToUse } = this.props;

    return (
      <>
        <Row style={{ marginRight: '12px', padding: '14px 8px 0px 8px' }}>
          <Switch
            disabled={!chatReadyToUse}
            checked={chatRepliesEnabled}
            onChange={state => {
              this.handleToggleChatReplies(state);
            }}
            style={{ marginRight: '12px' }}
          />
          <Text>{t('toggleChatReplies')}</Text>
        </Row>
        <Row type="flex" justify="space-between" align="bottom" gutter={4} style={{ padding: '14px 8px' }}>
          <Col>
            <Tooltip title="Upload image" zIndex={99999}>
              <Button
                icon={<PictureOutlined />}
                disabled={!chatReadyToUse}
                onClick={() => {
                  this.setState({ showUploadImageModal: true });
                }}
              />
            </Tooltip>
          </Col>
          <Col span={18}>
            <TextArea
              autoSize={{ minRows: 1, maxRows: 4 }}
              rows={1}
              onChange={this.typeMessage}
              value={newMessage}
              placeholder={t('chatReplyBoxPlaceholder')}
              disabled={!chatReadyToUse && newMessage.trim().length < 1}
            />
          </Col>
          <Col>
            <Button
              type="v2-primary"
              loading={sendingMessage}
              disabled={newMessage.trim().length === 0 || !user || !chatReadyToUse}
              onClick={() => {
                this.sendMessage(newMessage);
              }}
              style={{ minWidth: '38px', maxWidth: '68px' }}
            >
              {!sendingMessage && t('send')}
            </Button>
          </Col>
          {showUploadImageModal && (
            <ChatUploadImageModal
              visible={showUploadImageModal}
              onSendLoading={sendingMessage}
              onCancel={() => this.setState({ showUploadImageModal: false })}
              onSend={(image, fileType) => {
                this.onSendImage(image, fileType);
              }}
            />
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(withTranslation()(ChatReplyBox));
