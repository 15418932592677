import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { withTranslation } from 'react-i18next';

import { colors } from '../../../../styles/colors';
import LeaveItemHeader from './LeaveItemHeader';
import VoidLeaveEntryModal from './VoidLeaveEntryModal';
import RejectLeaveEntryModal from './RejectLeaveEntryModal';
import LeaveItemBody from './LeaveItemBody';

const { Panel } = Collapse;

export default withTranslation()(
  ({
    loading,
    leaveEntry,
    selectedLeaveEntry,
    setSelectedLeaveEntry,
    showVoidModal,
    setShowVoidModal,
    showRejectModal,
    setShowRejectModal,
    handleApproveLeaveEntry,
    handleResolveLeaveEntry,
    onActionComplete,
  }) => (
    <Collapse
      style={{ marginTop: 24, width: 616, borderColor: colors.greyBorder }}
      className="site-collapse-custom-collapse"
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{
            fontSize: 16,
            position: 'absolute',
            left: 22,
            top: 'calc(50% - 8px)',
          }}
          rotate={isActive ? 90 : 0}
        />
      )}
      defaultActiveKey={['0']}
    >
      <Panel
        header={
          <LeaveItemHeader
            leaveEntry={leaveEntry}
            loading={loading}
            setShowVoidModal={setShowVoidModal}
            setShowRejectModal={setShowRejectModal}
            setSelectedLeaveEntry={setSelectedLeaveEntry}
            handleApproveLeaveEntry={handleApproveLeaveEntry}
            handleResolveLeaveEntry={handleResolveLeaveEntry}
            onActionComplete={onActionComplete}
          />
        }
        className="site-collapse-custom-panel"
        style={{ paddingBottom: 0, background: colors.whiteBackground, borderColor: colors.greyBorder }}
      >
        <div style={{ marginLeft: 44, padding: '8px 8px 8px 0px', width: '100%' }}>
          <LeaveItemBody leaveEntry={leaveEntry} />
        </div>
        {leaveEntry && showVoidModal && (
          <VoidLeaveEntryModal
            leaveEntry={selectedLeaveEntry}
            visible={showVoidModal}
            onCancel={() => {
              setShowVoidModal(false);
              setSelectedLeaveEntry({});
            }}
            onComplete={() => {
              setShowVoidModal(false);
              setSelectedLeaveEntry({});
              onActionComplete();
            }}
          />
        )}

        {leaveEntry && showRejectModal && (
          <RejectLeaveEntryModal
            leaveEntry={selectedLeaveEntry}
            visible={showRejectModal}
            onCancel={() => {
              setShowRejectModal(false);
              setSelectedLeaveEntry({});
            }}
            onComplete={() => {
              setShowRejectModal(false);
              setSelectedLeaveEntry({});
              onActionComplete();
            }}
          />
        )}
      </Panel>
    </Collapse>
  ),
);
