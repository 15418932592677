import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form } from '@ant-design/compatible';
import styled from 'styled-components';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Typography, Alert } from 'antd';
import authApi from '../../services/authApi';
import routes from '../../routes';
import { colors } from '../../styles/colors';
import logo from '../../assets/images/brand_logo_dark.svg';

const { Text } = Typography;

const MIN_PASSWORD_LENGTH = 12;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 24px;
`;

const Card = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 5px 20px 0px #4d4d4d26;
  max-width: 432px;
  padding: 48px 24px 24px 24px;
`;

class ResetPasswordPage extends React.Component {
  state = {
    submitted: false,
    isLoading: false,
    error: '',
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        this.setState({ isLoading: true });
        try {
          await authApi.resetPassword(
            this.props.match.params.uid,
            this.props.match.params.token,
            values.new_password1,
            values.new_password2,
          );
          this.setState({ isLoading: false, submitted: true });
        } catch (error) {
          const errorMessages = Object.values(error.response.data);
          const message = errorMessages.reduce((acc, curr) => acc.concat(curr.join(' ')), '');
          this.setState({
            isLoading: false,
            error: message.length > 0 ? message : 'Unable to reset password - please try a different password.',
          });
        }
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form, t } = this.props;
    if (value && value !== form.getFieldValue('new_password1')) {
      callback(t('passwordDontMatch'));
    } else {
      callback();
    }
  };

  // TODO: use passwordUtils.isValidPassword instead
  checkPasswordValidations = (rule, value, callback) => {
    const { t } = this.props;

    const uppercaseRegExp = /(?=.*?[A-Z])/; // contains atleast 1 uppercase
    const digitsRegExp = /(?=.*?[0-9])/; // contains atleast 1 digits
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/; // contains atleast 1 special characters

    // we don't want to check the other validations if min password value length is not yet met
    if (value.length < MIN_PASSWORD_LENGTH) {
      // calling callback empty argument will not show error message
      callback();
      return;
    }

    const validations = [
      {
        regexRule: uppercaseRegExp,
        transKey: 'validationAtleastOneUppercaseChar',
      },
      {
        regexRule: digitsRegExp,
        transKey: 'validationAtleastOneNum',
      },
      {
        regexRule: specialCharRegExp,
        transKey: 'validationAtleastOneSymbol',
      },
    ];

    // find validation if there's a regex validation rule that is not met
    const validationError = validations.find(({ regexRule }) => !regexRule.test(value));
    if (validationError) {
      // show error message
      const errorMessage = t(validationError.transKey);
      callback(errorMessage);
    } else {
      // don't show error message
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isLoading, error, submitted } = this.state;
    const { t } = this.props;
    return (
      <Layout>
        <img src={logo} alt="Brand Logo" style={{ height: '24px', marginBottom: '24px' }} />

        <Card>
          <TitleWrapper>
            <Text style={{ fontSize: '20px', fontWeight: '600' }}>{t('createNewPassword')}</Text>
            <Text>{t('enterPasswordHint', { count: MIN_PASSWORD_LENGTH })}</Text>
          </TitleWrapper>

          {submitted ? (
            <Row type="flex">
              <Text style={{ fontSize: '14px', color: colors.primaryText, paddingBottom: '24px' }}>
                {t('passwordResetSuccess')}
              </Text>
              <Button
                block
                size="large"
                type="primary"
                style={{
                  backgroundColor: colors.workmateGreen,
                  border: `1px solid ${colors.borderGrey}`,
                  marginBottom: '24px',
                }}
                onClick={() => this.props.history.push(routes.login)}
              >
                {t('backToLogIn').toUpperCase()}
              </Button>
            </Row>
          ) : (
            <Form hideRequiredMark onSubmit={this.handleSubmit} colon={false}>
              {/* Reset password error message */}
              {error.length > 0 && (
                <Row style={{ marginTop: '1rem' }}>
                  <Alert showIcon closable type="error" message={error} onClose={() => this.setState({ error: '' })} />
                </Row>
              )}

              {/* Reset Password Form */}
              <Form.Item hasFeedback>
                {getFieldDecorator('new_password1', {
                  rules: [
                    {
                      required: true,
                      min: MIN_PASSWORD_LENGTH,
                      message: t('Minimum characters.', { count: MIN_PASSWORD_LENGTH }),
                    },
                    {
                      validator: this.checkPasswordValidations,
                    },
                  ],
                })(<Input.Password placeholder={t('newPassword')} />)}
              </Form.Item>
              <Form.Item hasFeedback style={{ paddingBottom: '12px' }}>
                {getFieldDecorator('new_password2', {
                  rules: [
                    { required: true, min: MIN_PASSWORD_LENGTH, message: t('Please confirm your password.') },
                    { validator: this.compareToFirstPassword },
                  ],
                })(<Input.Password placeholder={t('confirmPassword')} />)}
              </Form.Item>
              <Button
                block
                size="large"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                style={{
                  backgroundColor: colors.workmateGreen,
                  border: `1px solid ${colors.borderGrey}`,
                  marginBottom: '24px',
                }}
              >
                {t('createNewPassword').toUpperCase()}
              </Button>
            </Form>
          )}
        </Card>
      </Layout>
    );
  }
}
export default Form.create()(withTranslation()(ResetPasswordPage));
