import { Card, Col, Radio, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../styles/colors';
import textUtils from '../../../../utilities/textUtils';
import visaImg from '../../../../assets/images/visa_logo.svg';
import mastecardImg from '../../../../assets/images/mastercard_logo.svg';
import amexImg from '../../../../assets/images/amex_logo.svg';
import { cardBrandTypes } from '../../../../constants';

const CardBrandImage = ({ brand }) => {
  let img = visaImg;
  if (brand === cardBrandTypes.VISA) {
    img = visaImg;
  } else if (brand === cardBrandTypes.MASTERCARD) {
    img = mastecardImg;
  } else if (brand === cardBrandTypes.AMERICAN_EXPRESS) {
    img = amexImg;
  }
  return <img src={img} alt="card brand" style={{ marginRight: 6, width: 24, height: 24, objectFit: 'contain' }} />;
};

const PaymentMethodCard = ({ paymentMethod }) => {
  const { t } = useTranslation();

  return (
    <Card
      style={{
        border: `1px solid ${colors.functionalLink}`,
        boxShadow: ' 0px 2px 6px rgba(0, 0, 0, 0.15)',
      }}
      bodyStyle={{ padding: 16 }}
    >
      <Row>
        <Col>
          <Radio checked disabled />
        </Col>
        <Col style={{ flex: 1 }}>
          <Typography.Paragraph style={{ marginBottom: 4, color: colors.textTitle }}>
            {Object.values(cardBrandTypes).includes(paymentMethod.card.brand) && (
              <CardBrandImage brand={paymentMethod.card.brand} />
            )}
            {t('endingIn', {
              ending: paymentMethod.card.last4,
              brand: textUtils.capitalizeWord(paymentMethod.card.brand),
            })}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ marginBottom: 0, color: colors.secondaryText }}>
            {t('expiresIn')} {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}
          </Typography.Paragraph>
        </Col>
      </Row>
    </Card>
  );
};

export default PaymentMethodCard;
