import { endpoints } from '../config';
import base from './base';

const fetchShifts = async params => {
  const response = await base.api.get(`${endpoints.shifts}/`, { params });
  return response;
};

const editShift = async shift => {
  const response = await base.api.patch(`${endpoints.shifts}/${shift.id}/`, shift);
  return response;
};

const notifyPartner = async params => {
  const response = await base.api.post(`${endpoints.shifts}/notify-partners/`, params);
  return response;
};

const notifyTimeUpdate = async (shiftId, params) => {
  const response = await base.api.post(`${endpoints.shifts}/${shiftId}/notify-time-update/`, params);
  return response;
};

/**
 * @param {params} {shift_id: [], start_date: LOCAL_DATE, end_date: LOCAL_DATE}
 * MUST localize dates as backend won't know how to localize the dates
 */
const getRecommendedShifts = async params => {
  const response = await base.api.post(`${endpoints.shifts}/recommended-shifts-v2/`, params);
  return response;
};

const cancel = async shiftId => {
  const response = await base.api.post(`${endpoints.shifts}/${shiftId}/cancel/`);
  return response;
};

export default {
  fetchShifts,
  editShift,
  notifyPartner,
  notifyTimeUpdate,
  getRecommendedShifts,
  cancel,
};
