import React from 'react';
import { withTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { ThunderboltFilled } from '@ant-design/icons';

import { colors } from '../../styles/colors';

const SparkTag = ({ t }) => {
  return (
    <Tooltip title={t('processedByAutoHire')}>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: 24,
          height: 24,
          backgroundColor: colors.tagGreen,
          color: colors.functionalSuccess,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          borderTopLeftRadius: 16,
          zIndex: 1,
          padding: 3,
        }}
      >
        <ThunderboltFilled />
      </div>
    </Tooltip>
  );
};

export default withTranslation()(SparkTag);
