import React, { useState } from 'react';
import { Button, Typography, Modal, InputNumber, Alert, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';

import { MONTHLY_DATE_RANGE } from '../../../constants';

const { Text } = Typography;

const DEFAULT_WORKER_MAX_WORK_DAYS = 6; // default to 6 days per week

const AutoAssignButtonModal = ({ t, disabled, onAutoAssign, confirmLoading, selectedDateRange }) => {
  const [showModal, setShowModal] = useState(false);
  const [maxWorkDays, setMaxWorkDays] = useState(DEFAULT_WORKER_MAX_WORK_DAYS);
  const [hasError, setHasError] = useState(false);

  return (
    <>
      {selectedDateRange === MONTHLY_DATE_RANGE ? (
        <Tooltip title={t('autoAssignTooltip')}>
          <Button disabled style={{ marginRight: 8 }}>
            {t('autoAssign')}
          </Button>
        </Tooltip>
      ) : (
        <Button
          disabled={disabled}
          onClick={() => {
            setShowModal(true);
          }}
          style={{ marginRight: 8 }}
        >
          {t('autoAssign')}
        </Button>
      )}
      <Modal
        title={<Text strong>{t('autoAssignShifts')}</Text>}
        visible={showModal}
        onOk={async () => {
          try {
            await onAutoAssign(maxWorkDays);
            setShowModal(false);
          } catch (error) {
            setHasError(true);
          }
        }}
        okButtonProps={{ disabled: hasError, loading: confirmLoading }}
        okType="v2-primary"
        okText={t('assign')}
        cancelText={t('cancel')}
        onCancel={() => {
          setHasError(false);
          setShowModal(false);
        }}
        destroyOnClose
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text style={{ whiteSpace: 'pre-wrap' }}>{t('autoAssignDescription')}</Text>

          <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}>
            <Text style={{ fontWeight: 400 }}>{t('maxWorkingDaysPerWeek')}</Text>
            <InputNumber
              min={1}
              max={7}
              value={maxWorkDays}
              onChange={value => {
                setMaxWorkDays(value);
                setHasError(false);
              }}
              style={{ minWidth: 256 }}
            />
            <Text type="secondary" style={{ marginTop: 10 }}>
              {t('maxWorkingDaysPerWeekHint')}
            </Text>
          </div>
          {hasError && <Alert message={t('autoAssignError')} type="error" showIcon />}
        </div>
      </Modal>
    </>
  );
};

export default withTranslation()(AutoAssignButtonModal);
