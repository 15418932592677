import React from 'react';
import { Col, Row } from 'antd';

import WorkerReportTable from './components/WorkerReportTable';

const ReportTab = ({ timePeriod, clientId, locationIds, positionIds }) => {
  return (
    <Row style={{ maxWidth: '100%' }}>
      <Col>
        <WorkerReportTable
          timePeriod={timePeriod}
          clientId={clientId}
          locationIds={locationIds}
          positionIds={positionIds}
        />
      </Col>
    </Row>
  );
};

export default ReportTab;
