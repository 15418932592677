import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col, Button, Typography } from 'antd';
import { toUpper } from 'lodash';
import { offerStatuses, staffRequestTabs } from '../../../constants';

const SearchResult = ({ worker, handleClick, t }) => {
  let { status } = worker;
  if (status === staffRequestTabs.APPLIED) {
    if (worker.shortlisted) {
      status = staffRequestTabs.WAITLISTED;
    } else if (worker.interviewed) {
      status = staffRequestTabs.INTERVIEW;
    }
  } else if (status === offerStatuses.PENDING_CONTRACT) {
    status = staffRequestTabs.OFFERS;
  }

  return (
    <Button
      type="text"
      block
      onClick={() => {
        handleClick({ ...worker, to: status });
      }}
    >
      <Row type="flex" justify="space-between">
        <Col span={12} style={{ textAlign: 'left' }}>
          <Typography.Text type="secondary">
            {worker.partner.first_name} {worker.partner.last_name}
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Text type="secondary" style={{ textAlign: 'right' }} strong>
            {toUpper(t(status))}
          </Typography.Text>
        </Col>
      </Row>
    </Button>
  );
};

export default withTranslation()(SearchResult);
