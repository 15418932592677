import base from './base';
import { endpoints } from '../config';

const fetchRatings = async params => {
  const response = await base.api.get(`${endpoints.ratings}/`, {
    params,
  });
  return response;
};

// Partner and offer are Ids
const createRating = async ({ partnerId, employmentId, score }) => {
  const response = await base.api.post(`${endpoints.ratings}/`, {
    score,
    partner: partnerId,
    employment: employmentId,
  });
  return response;
};

export default {
  fetchRatings,
  createRating,
};
