import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Typography, Button } from 'antd';

import { zendeskLinks } from '../../constants';

const { Text } = Typography;

const PaymentHelp = ({ t }) => {
  return (
    <Row>
      <Row>
        <Text type="secondary">
          {t('Need help with payments?')}
          <Button type="link" onClick={() => window.zE.activate({ hideOnClose: true })} style={{ padding: 0 }}>
            {t('Chat with us')}
          </Button>
        </Text>
      </Row>
      <Row>
        <Text type="secondary">
          {t('Looking for other payment methods or enterprise solutions?')}
          <a href={zendeskLinks.CONTACT_US_EN} target="_blank" rel="noopener noreferrer" style={{ padding: '5px' }}>
            {t('Call our Customer Success team')}
          </a>
        </Text>
      </Row>
    </Row>
  );
};

export default withTranslation()(PaymentHelp);
