import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const ExtensionPrefixSelector = (getFieldDecorator, prefixes, initialValue, label) => {
  return getFieldDecorator(label, {
    initialValue: initialValue || '+65',
  })(
    <Select style={{ width: 70 }}>
      {prefixes.map((extension, index) => (
        <Option key={index} value={extension}>
          {extension}
        </Option>
      ))}
    </Select>,
  );
};

export default ExtensionPrefixSelector;
