import moment from 'moment-timezone';
import { range } from 'lodash';
import { SERVER_DATE } from '../styles/dates';

const FRIENDLY_FORMAT = 'D MMM YYYY';
const FRIENDLY_FORMAT_WITH_HOURS = `${FRIENDLY_FORMAT}, h:mm a`;

const toFriendly = (date, timezone, withHours) => {
  // TODO: Timezone should be read from location of user.
  if (!date || !timezone) {
    return '-';
  }
  const friendlyDate = moment(date).tz(timezone);
  if (withHours) {
    return friendlyDate.format(FRIENDLY_FORMAT_WITH_HOURS);
  }
  return friendlyDate.format(FRIENDLY_FORMAT);
};

const toFriendlyPeriodWithMonthDiff = (startDate, endDate) => {
  const start = startDate ? moment(startDate).format('MMM YYYY') : 'No Start Date';
  const end = endDate ? moment(endDate).format('MMM YYYY') : 'No End Date';
  let duration = '-';
  if (endDate && startDate) {
    duration = Math.round(moment(endDate).diff(moment(startDate), 'months', true) + 1);
  }
  return `${start} – ${end} ${duration && `(${duration} months)`}`;
};

const toFriendlyPeriod = (start, end, startTz, endTz, withHours) => {
  if (!start && !end) {
    return '–';
  }

  if (!start) {
    const friendlyEnd = toFriendly(end, endTz, withHours);
    return `No start date – ${friendlyEnd}`;
  }

  if (!end) {
    const friendlyStart = toFriendly(start, startTz, withHours);
    return `${friendlyStart} – No end date`;
  }

  const friendlyStart = toFriendly(start, startTz, withHours);
  const friendlyEnd = toFriendly(end, endTz, withHours);
  return `${friendlyStart} – ${friendlyEnd}`;
};

// TODO: Rename or add comments or remove
const getDurationHours = (startTime, endTime) => {
  return moment.duration(moment(endTime).diff(moment(startTime))).asHours();
};

// This is to map RRule's recurrences which are 0 indexed to days of the week
const getDayOfWeek = val => {
  switch (val) {
    case 0:
      return 'Mon';
    case 1:
      return 'Tue';
    case 2:
      return 'Wed';
    case 3:
      return 'Thu';
    case 4:
      return 'Fri';
    case 5:
      return 'Sat';
    case 6:
      return 'Sun';
    default:
      return '';
  }
};

// TODO: Rename or add comments or remove
const formatMonth = months => {
  let year = 0;
  let month = 0;

  if (months < 12) {
    month = months;
  } else {
    year = Math.floor(months / 12);
    month = months - year * 12;
  }
  let monthString = `${month} months`;
  let yearString = `${year} years `;

  if (year === 0) yearString = '';
  if (year === 1) yearString = `${year} year `;
  if (month === 0) monthString = '';
  if (month === 1) monthString = `${month} month`;
  return yearString + monthString;
};

const getWeekDatesFromSelectedDate = selectedDate => {
  return range(1, 8).map(day => moment(selectedDate).day(day));
};

const getWeekStartOptions = (currentWeekStart, numWeeksBefore, numWeeksAfter) => {
  const weeksBefore = [...Array(numWeeksBefore).keys()].map(weekOffset => {
    return moment(currentWeekStart)
      .subtract(weekOffset + 1, 'weeks')
      .toISOString();
  });
  const weeksAfter = [...Array(numWeeksAfter).keys()].map(weekOffset => {
    return moment(currentWeekStart)
      .add(weekOffset + 1, 'weeks')
      .toISOString();
  });
  const sortedWeekStartOptions = [...weeksBefore, currentWeekStart, ...weeksAfter].sort((a, b) => {
    if (a > b) {
      return 1;
    }
    return -1;
  });
  return sortedWeekStartOptions;
};

const generateTwoWeeks = timezone => {
  const days = [];
  const dateStart = moment().tz(timezone);
  const dateEnd = moment()
    .tz(timezone)
    .add(2, 'weeks')
    .subtract(1, 'days'); // 2 weeks returns 15 days so subtract 1 day (15 days probably because today is counted)
  const dateRange = `${dateStart.format('D MMM ')} - ${dateEnd.format('D MMM')}`;

  while (dateEnd.diff(dateStart, 'days') >= 0) {
    days.push(dateStart.format('YYYY-MM-DD HH:mm:ss'));
    dateStart.add(1, 'days');
  }

  return {
    days: days.slice(0, 14),
    dateRange,
  };
};

const getDurationHoursAndMinutes = (startTime, endTime) => {
  let hours;
  let minutes;

  if (!startTime || !endTime) {
    hours = 0;
    minutes = 0;
    return [hours, minutes];
  }
  const momentDate = moment();
  const start = moment(`${momentDate.format('YYYY-MM-DD')} ${moment(startTime).format('HH:mm:00')}`);
  const end = moment(`${momentDate.format('YYYY-MM-DD')} ${moment(endTime).format('HH:mm:00')}`);

  if (end?.isBefore(start)) {
    end.add(1, 'days');
  }

  hours = end.diff(start, 'hours');
  minutes = end.diff(start, 'minutes') - hours * 60;

  return [hours, minutes];
};

const shiftDatesToWorkingDays = shiftDates => {
  const orderedDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const workingDays = [];
  shiftDates.forEach(shiftDate => workingDays.push(moment(shiftDate).isoWeekday()));
  const uniqueWorkingDays = [...new Set(workingDays)].sort((a, b) => a - b);
  return uniqueWorkingDays.map(dayInteger => orderedDays[dayInteger - 1]);
};

const isTodaySelected = date => {
  const startDate = moment(date).format(SERVER_DATE);
  const today = moment().format(SERVER_DATE);
  return startDate === today;
};

const sortDatesByEarliest = dates => {
  return [...dates].sort((a, b) => {
    return new Date(a) - new Date(b);
  });
};

const dateKey = date => date.format('ddd DD/MM');

export default {
  toFriendlyPeriodWithMonthDiff,
  toFriendlyPeriod,
  toFriendly,
  getDurationHours,
  getDayOfWeek,
  FRIENDLY_FORMAT,
  FRIENDLY_FORMAT_WITH_HOURS,
  formatMonth,
  getWeekDatesFromSelectedDate,
  getWeekStartOptions,
  generateTwoWeeks,
  getDurationHoursAndMinutes,
  shiftDatesToWorkingDays,
  isTodaySelected,
  sortDatesByEarliest,
  dateKey,
};
