import base from './base';
import { endpoints } from '../config';

const submitStaffRequestTemplate = async staffRequestTemplate => {
  const response = await base.api.post(`${endpoints.staffRequestTemplates}/`, staffRequestTemplate);
  return response;
};

const fetchStaffRequestTemplates = async params => {
  const response = await base.api.get(`${endpoints.staffRequestTemplates}/`, { params });
  return response;
};

const fetchStaffRequestTemplateById = async id => {
  const response = await base.api.get(`${endpoints.staffRequestTemplates}/${id}/`);
  return response;
};

const editStaffRequestTemplate = async staffRequestTemplate => {
  const resposne = await base.api.put(
    `${endpoints.staffRequestTemplates}/${staffRequestTemplate?.id}/`,
    staffRequestTemplate,
  );
  return resposne;
};

const deleteStaffRequestTemplate = async staffRequestTemplate => {
  const response = await base.api.delete(`${endpoints.staffRequestTemplates}/${staffRequestTemplate?.id}/`);
  return response;
};

const validateTemplateName = async params => {
  const response = await base.api.post(`${endpoints.staffRequestTemplates}/validate-template-name/`, params);
  return response;
};

export default {
  submitStaffRequestTemplate,
  fetchStaffRequestTemplates,
  fetchStaffRequestTemplateById,
  editStaffRequestTemplate,
  deleteStaffRequestTemplate,
  validateTemplateName,
};
