import React from 'react';
import { withTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { Card, Typography, Button } from 'antd';
import { FolderOpenOutlined } from '@ant-design/icons';
import { colors } from '../../../styles/colors';
import { applicationStatuses, staffRequestTabs } from '../../../constants';

const { Text } = Typography;

const PlaceholderItem = ({ t, application, updateUrl }) => {
  let movedToStatus = '';
  let movedToStatusTransText = '';
  switch (application.status) {
    case applicationStatuses.APPLIED:
      if (application.shortlisted && !application.interviewed) {
        movedToStatus = staffRequestTabs.WAITLISTED;
        movedToStatusTransText = t('waitlisted');
      } else if (!application.shortlisted && application.interviewed) {
        movedToStatus = staffRequestTabs.INTERVIEW;
        movedToStatusTransText = t('interview');
      }
      break;
    case applicationStatuses.PENDING_CONTRACT:
      movedToStatus = staffRequestTabs.OFFERS;
      movedToStatusTransText = t('Offered');
      break;
    case applicationStatuses.REJECTED || applicationStatuses.WITHDRAWN:
      movedToStatus = staffRequestTabs.INACTIVE;
      movedToStatusTransText = t('Inactive');
      break;
    default:
      movedToStatus = staffRequestTabs.WAITLISTED;
      movedToStatusTransText = t('waitlisted');
      break;
  }

  return (
    <div style={{ color: colors.blue }}>
      <Card style={{ backgroundColor: colors.lightGrey, marginTop: 20, marginBottom: 20 }}>
        <Text strong>
          <FolderOpenOutlined style={{ fontSize: 15 }} /> {application.partner.first_name}{' '}
          {application.partner.last_name}
        </Text>
        <Text>
          {t('wasMovedTo')}
          <Button
            type="link"
            style={{ paddingLeft: 5 }}
            onClick={() => {
              updateUrl(movedToStatus);
            }}
          >
            {capitalize(movedToStatusTransText)}
          </Button>
        </Text>
      </Card>
    </div>
  );
};

export default withTranslation()(PlaceholderItem);
