import React from 'react';
import { CameraFilled, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Row, Typography } from 'antd';
import { withTranslation } from 'react-i18next';
import { colors } from '../../../../styles/colors';

const iconStyle = { fontSize: 20, color: colors.disabled };
const iconRowStyle = {
  display: 'flex',
  height: '40%',
  justifyContent: 'center',
  alignItems: 'center',
  width: 125,
  marginTop: 25,
};

const selfieUploadStatus = {
  UPLOADING: 'uploading',
  UPLOAD_FAILED: 'upload_failed',
};

const getStatusAndIcon = selfieStatus => {
  let statusAndIcon = {
    icon: {
      icon: <CameraFilled style={iconStyle} />,
      style: iconRowStyle,
    },
    status: 'selfieNotSubmitted',
  };
  if (selfieStatus === selfieUploadStatus.UPLOADING) {
    statusAndIcon = {
      icon: {
        icon: <LoadingOutlined style={iconStyle} />,
        style: iconRowStyle,
      },
      status: 'selfieUploading',
    };
  } else if (selfieStatus === selfieUploadStatus.UPLOAD_FAILED) {
    statusAndIcon = {
      icon: {
        icon: <CloseCircleOutlined style={{ ...iconStyle, color: colors.red }} />,
        style: { ...iconRowStyle, height: '40%', left: 100, marginTop: 0 },
      },
      status: 'selfieUploadUnsuccessful',
    };
  }

  return statusAndIcon;
};

export default withTranslation()(({ t, selfieStatus }) => {
  const statusAndIcon = getStatusAndIcon(selfieStatus);
  return (
    <div style={{ width: 70, height: 77 }}>
      <Row style={statusAndIcon.icon.style}>{statusAndIcon.icon.icon}</Row>
      <Row
        style={{
          display: 'flex',
          height: '80%',
          justifyContent: 'center',
          width: 105,
          marginLeft: 10,
        }}
      >
        <Typography.Text style={{ fontSize: 14, color: colors.disabled, lineHeight: '22px', textAlign: 'center' }}>
          {t(statusAndIcon.status)}
        </Typography.Text>
      </Row>
    </div>
  );
});
