/* eslint-disable no-nested-ternary */
import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, message, Button, Typography } from 'antd';

import clientApi from '../../../services/clientApi';
import billingUtils from '../../../utilities/billingUtils';
import { paymentMethods, userStatuses } from '../../../constants';

import BillingInfo from './components/BillingInfo';
import BillingReportTable from './components/BillingReportTable';
import CompletedInvoiceDisplay from './components/CompletedInvoiceDisplay';
import CompletedCreditDisplay from './components/CompletedCreditDisplay';
import CompletedPrepaidDisplay from './components/CompletedPrepaidDisplay';
import BillingForm from '../../../shared/components/BillingForm';
import BillingFormModal from '../../../containers/BillingFormModal';

const { Title } = Typography;
class BillingSection extends React.Component {
  state = {
    isLoading: false,
    hasCompleteBillingInfo: false,
    hasCompletePaymentInfo: false,
    isPayingByCredit: false,
    editBillingModalOpen: false,
  };

  componentDidMount() {
    const { client } = this.props;
    const hasCompleteBillingInfo = billingUtils.hasCompleteBillingInfo(client);
    const hasCompletePaymentInfo = Boolean(client.paymentMethod);
    let isPayingByCredit = false;
    if (hasCompletePaymentInfo && client.paymentMethod === paymentMethods.CREDIT) {
      isPayingByCredit = true;
    }
    this.setState({ hasCompleteBillingInfo, hasCompletePaymentInfo, isPayingByCredit });
  }

  setBillingForm = form => {
    this.setState({ billingForm: form });
  };

  submitBillingForm = async () => {
    const { client, fetchClientAndManager, t } = this.props;
    const { billingForm } = this.state;
    this.setState({ isLoading: true });
    billingForm.validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { prefix, street_1, street_2, province, zip, country, ...payload } = values;
        payload.billing_address = { street_1, street_2, province, zip, country };
        payload.billing_contact_phone = `${prefix}${payload.billing_contact_phone}`;
        payload.id = client.clientId;
        const data = await clientApi.editClient(payload);
        if (data) {
          await fetchClientAndManager();
          message.success(t('Changes saved'));
          this.setState({ isLoading: false });
        } else {
          message.warn(t('Something went wrong. No changes were saved'));
          this.setState({ isLoading: false });
        }
      }
    });
  };

  render() {
    const { client, isAdmin, fetchClientAndManager, t } = this.props;
    const {
      isLoading,
      hasCompleteBillingInfo,
      hasCompletePaymentInfo,
      isPayingByCredit,
      editBillingModalOpen,
    } = this.state;
    return (
      <Row>
        <Col xs={24} md={18}>
          {/* Payment method */}
          {hasCompletePaymentInfo && (
            <Row style={{ marginTop: '14px', marginBottom: '48px' }}>
              <Title level={3} style={{ marginBottom: '24px' }}>
                {t('Payment method')}
              </Title>
              {client.paymentMethod === paymentMethods.INVOICE && (
                <CompletedInvoiceDisplay inReview={client.status === userStatuses.LEAD} />
              )}

              {client.paymentMethod === paymentMethods.CREDIT && (
                <CompletedCreditDisplay isAdmin={isAdmin} client={client} t={t} />
              )}

              {client.paymentMethod === paymentMethods.PREPAID && <CompletedPrepaidDisplay />}
            </Row>
          )}

          {/* Billing information */}
          <Row style={{ marginTop: '14px' }}>
            <Title level={3} style={{ marginBottom: '24px' }}>
              {t('Billing information')}
            </Title>
            {hasCompleteBillingInfo ? (
              <>
                <BillingInfo client={client} />
                {isAdmin && (
                  <Button
                    disabled={!isAdmin}
                    type="v2-secondary"
                    loading={isLoading}
                    onClick={() => this.setState({ editBillingModalOpen: true })}
                  >
                    {t('Edit billing info')}
                  </Button>
                )}
              </>
            ) : (
              <>
                <BillingForm isAdmin={isAdmin} client={client} setBillingForm={this.setBillingForm} />
                <Button
                  disabled={!isAdmin}
                  loading={isLoading}
                  onClick={this.submitBillingForm}
                  type="v2-primary"
                  htmlType="submit"
                  style={{ width: '120px', marginTop: '16px' }}
                >
                  {t('Save')}
                </Button>
              </>
            )}
          </Row>
        </Col>

        {/* Recent invoices (Only for clients paying by credit) */}
        {isPayingByCredit && (
          <Col span={24} style={{ marginTop: '48px' }}>
            <Title level={3} style={{ marginBottom: '24px' }}>
              {t('Recent invoices')}
            </Title>
            <BillingReportTable client={client} />
          </Col>
        )}

        {/* Modals */}
        <BillingFormModal
          isAdmin={isAdmin}
          client={client}
          fetchClientAndManager={fetchClientAndManager}
          visible={editBillingModalOpen}
          closeModal={() => this.setState({ editBillingModalOpen: false })}
        />
      </Row>
    );
  }
}

export default Form.create({ name: 'Billing Section' })(BillingSection);
