import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { UserOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Card, Avatar } from 'antd';
import { withTranslation } from 'react-i18next';
import HighlightInfo from '../../../shared/components/HighlightInfo';
import { colors } from '../../../styles/colors';
import textUtils from '../../../utilities/textUtils';

const { Text, Title } = Typography;

const ProfileCard = ({ worker, daysWorked, isDirectJobInvite, t, shouldHideDetails = false }) => {
  const { verifiedDate, phone, noShows, ratings, lastActive, age, gender, location, name } = worker;
  const workerGeneralInfo = [
    {
      iconType: 'user',
      description: `${gender ? textUtils.capitalizeWord(t(gender)) : `[${t('Gender')}]`} · ${
        age ? `${age}yo` : `[${t('Age')}]`
      }`,
      show: true,
    },
    {
      iconType: 'environment',
      description: location.address === 'Not provided' ? '[Location]' : location.address,
      show: !shouldHideDetails,
    },
    { iconType: 'phone', description: phone, show: true },
    { iconType: 'mobile', description: lastActive, show: true },
  ];
  let workerHighlightInfo = [
    { iconType: 'schedule', title: t('daysWorked'), description: daysWorked },
    { iconType: 'warning', title: 'No shows', description: noShows },
    { iconType: 'like', title: t('recommended'), description: ratings },
    {
      iconType: 'safety-certificate',
      title: 'Verified workmate',
      description: (
        <Text strong style={{ color: colors.functionalSuccess, fontSize: '14px', lineHeight: '22px' }}>
          {verifiedDate}
        </Text>
      ),
    },
  ];

  // hide verified text and icon for direct job invite worker
  if (isDirectJobInvite) {
    workerHighlightInfo = workerHighlightInfo.filter(info => info.title !== 'Verified workmate');
  }

  return (
    <Card
      cover={
        <Avatar
          icon={<UserOutlined />}
          shape="square"
          src={worker.imgSrc}
          style={{ width: '100%', height: '324px' }}
          size={324}
        />
      }
      bordered={false}
      bodyStyle={{ padding: '0 24px 0 0' }}
    >
      <Title level={3} style={{ marginBottom: '8px', marginTop: '16px' }}>
        {name}
      </Title>

      {/* Map partner data e.g. age */}
      <section style={{ marginBottom: '24px' }}>
        {workerGeneralInfo.map(
          ({ iconType, description, show }, index) =>
            show && (
              <Row key={index} wrap={false} align="top" style={{ marginBottom: '8px' }}>
                <Col flex="16px">
                  <LegacyIcon type={iconType} style={{ color: '#BFBFBF', marginRight: '4px' }} />
                </Col>
                <Col flex="auto">{description}</Col>
              </Row>
            ),
        )}
      </section>

      <Row type="flex" gutter={[0, 16]}>
        {workerHighlightInfo.map(({ iconType, title, description }, index) => (
          <Col key={index} xs={12}>
            <HighlightInfo iconType={iconType} title={title} description={description} />
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default withTranslation()(ProfileCard);
