// Ducks separates state to different entities, e.g User, Partners.. etc
// Refer to './user' for comments on structure. More on: https://github.com/erikras/ducks-modular-redux
import { combineReducers } from 'redux';
import globalReducer from './global';
import userReducer from './user';
import chatReducer from './chat';
import applicationFormReducer from './applicationForm';
import { defaultPageSizeKeys } from '../constants';

const appReducer = combineReducers({
  user: userReducer,
  global: globalReducer,
  chat: chatReducer,
  applicationForm: applicationFormReducer,
});

const rootReducer = (state, action) => {
  // Intercepts logout action. Remove & reset redux store in local storage.
  if (action.type === 'USER_LOGOUT_ACTION') {
    const lang = localStorage.getItem('i18nextLng');

    // Persist page size settings
    const timesheet_list_page_size = localStorage.getItem(defaultPageSizeKeys.TIMESHEET_LIST);

    // Clear local and session storage & rehydrate persisted keys
    localStorage.clear();
    sessionStorage.clear();
    if (lang) {
      localStorage.setItem('i18nextLng', lang);
    }
    if (timesheet_list_page_size) {
      localStorage.setItem(defaultPageSizeKeys.TIMESHEET_LIST, timesheet_list_page_size);
    }

    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
