import React, { useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, Typography, Input, Button, message, Row, Col } from 'antd';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { colors } from '../../../styles/colors';

const InviteLinkModal = ({ inviteLink, visible, closeModal, t }) => {
  const [copied, setCopied] = useState(false);
  const inputTextRef = useRef(null);

  const checkedIcon = <CheckOutlined style={{ color: colors.green, fontSize: '14px' }} />;
  const copyIcon = <CopyOutlined style={{ fontSize: '14px' }} />;

  return (
    <Modal
      className="v2-share-invite-link-modal"
      visible={visible}
      title={<Typography.Title level={4}>{t('shareInviteLink')}</Typography.Title>}
      onCancel={closeModal}
      footer={null}
    >
      <Row>
        <Col>
          <Typography.Text>{t('shareInviteLinkDescription')}</Typography.Text>
        </Col>
      </Row>
      <Row style={{ marginTop: '12px' }}>
        <Typography.Text>{t('copyInviteLink')}</Typography.Text>
      </Row>
      <Row style={{ marginTop: '8px', marginBottom: '12px' }}>
        <Col span={20}>
          <Input readOnly ref={inputTextRef} value={inviteLink} />
        </Col>
        <Col span={4}>
          <Button
            style={{ marginLeft: 4 }}
            onClick={() => {
              inputTextRef.current.select();
              document.execCommand('copy');
              setCopied(true);

              message.success(t('linkCopied'));
            }}
          >
            {copied ? checkedIcon : copyIcon} {t('copy')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default withTranslation()(InviteLinkModal);
