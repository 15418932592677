import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Typography, Tooltip } from 'antd';

import { colors } from '../../styles/colors';

const { Text } = Typography;

const HighlightInfo = ({ title, iconType, description, tooltip, t, disabled = false }) => {
  const textIconColor = disabled ? colors.disabled : colors.functionalSuccess;
  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <Text
            type="secondary"
            strong
            style={{
              color: textIconColor,
              fontSize: '12px',
              lineHeight: '20px',
              display: 'block',
            }}
          >
            {t(title.toUpperCase())}
          </Text>
          <Text
            strong
            style={{
              color: textIconColor,
              fontSize: '20px',
              lineHeight: '22px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <LegacyIcon type={iconType} theme="twoTone" twoToneColor={textIconColor} style={{ marginRight: '4px' }} />
            {description}
          </Text>
        </Tooltip>
      ) : (
        <>
          <Text
            type="secondary"
            strong
            style={{
              color: textIconColor,
              fontSize: '12px',
              lineHeight: '20px',
              display: 'block',
            }}
          >
            {t(title.toUpperCase())}
          </Text>
          <Text
            strong
            style={{
              color: textIconColor,
              fontSize: '20px',
              lineHeight: '22px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {iconType && (
              <LegacyIcon type={iconType} theme="twoTone" twoToneColor={textIconColor} style={{ marginRight: '4px' }} />
            )}
            {description}
          </Text>
        </>
      )}
    </>
  );
};

export default withTranslation()(HighlightInfo);
